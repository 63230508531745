import React, { useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { useMediaQuery, useTheme } from '@mui/material';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { ContractEmailLog } from './Email';
import { ContractPublicAuditLog } from './Public';
import { ContractAudit as GeneralContractAudit } from './General';

const { colors } = capitalDesignTokens.foundations;

export const ContractAudit = () => {
    const auditEnabled = useFlags(FLAGS.ENABLE_CONTRACT_AUDIT_LOG);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const indexOffset = auditEnabled ? 0 : 1;
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item lg={1.5} md={12}>
                    <Tabs
                        aria-label="Logs menu"
                        onChange={handleChange}
                        orientation={isMd ? 'horizontal' : 'vertical'}
                        sx={{
                            borderLeft: 0,

                            '& .MuiTab-root': {
                                borderBottom: 1,
                                borderColor: 'divider',
                                paddingY: 1,
                                paddingX: 2,

                                '&.Mui-selected': {
                                    backgroundColor: colors.brand50,
                                },
                            },

                            '& .MuiTabs-indicator': {
                                left: 0,
                                right: 'auto',
                                width: '4px',
                            },
                        }}
                        value={value}
                        variant="scrollable"
                    >
                        {auditEnabled && (
                            <Tab
                                label="Contract Audit Log"
                                sx={{ textAlign: 'left', alignItems: 'flex-start' }}
                            />
                        )}

                        <Tab
                            label="Email Log"
                            sx={{ textAlign: 'left', alignItems: 'flex-start' }}
                        />
                        <Tab
                            label="Public Audit Log"
                            sx={{ textAlign: 'left', alignItems: 'flex-start' }}
                        />
                    </Tabs>
                </Grid>
                <Grid item lg={10.5} md={12}>
                    {value === 0 - indexOffset && <GeneralContractAudit />}
                    {value === 1 - indexOffset && <ContractEmailLog />}
                    {value === 2 - indexOffset && <ContractPublicAuditLog />}
                </Grid>
            </Grid>
        </Box>
    );
};
