import React, { PureComponent } from 'react';

import { INPUT_DATA } from '@og-pro/shared-config/questionnaires';

import { pseudoSectionTypeNames } from '@og-pro/shared-config/sections';

import { fieldNames, projectFormProps } from '../constants';
import { Label, Main } from '../../../../components';
import { UpfrontQuestionsResponseForm } from '../../../../components/forms';

const { UPFRONT_QUESTIONS } = fieldNames;
const { DOCUMENT_SETUP } = pseudoSectionTypeNames;

export class UpfrontQuestions extends PureComponent {
    static propTypes = projectFormProps;

    formName = DOCUMENT_SETUP;

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { change, disabled, showFormErrors } = this.props;

        return (
            <Main className="row">
                <div className="col-xs-12">
                    <Label
                        className={this.styles.label}
                        helpIcon
                        helpIconNode="The information you provide will be used to determine the language and options to include in your document."
                        label="Provide the information below to configure your document"
                    />
                    <UpfrontQuestionsResponseForm
                        change={change}
                        disabled={disabled}
                        formKey={UPFRONT_QUESTIONS}
                        responsePath={INPUT_DATA}
                        showValidation={showFormErrors}
                    />
                </div>
            </Main>
        );
    }
}
