import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Box, IconButton, Popover, Typography } from '@og-pro/ui';
import { Close as CloseIcon, InfoOutlined as InfoIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { DOCUSIGN_SIGNER_STATUSES } from '@og-pro/shared-config/electronicSignatures';

import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { synchronizeElectronicSignatureEvents } from '../../../../actions/project/electronicSignatures';

const { colors } = capitalDesignTokens.foundations;
const { COMPLETED, SENT, DELIVERED, DECLINED } = DOCUSIGN_SIGNER_STATUSES;

export const SignaturesPopover = ({ electronicSignature, projectId }) => {
    const [opened, setOpened] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const {
        data,
        mutate: synchronizeEvents,
        isLoading,
    } = useMutation({
        mutationFn: () =>
            dispatch(
                synchronizeElectronicSignatureEvents({
                    projectId,
                    electronicSignatureId: electronicSignature.id,
                })
            ),
    });

    useEffect(() => {
        if (opened) {
            synchronizeEvents();
        }
    }, [opened, synchronizeEvents]);

    const handlePopoverOpen = useCallback((event, toggleOpened) => {
        setAnchorEl(event.currentTarget);

        if (toggleOpened) {
            event.stopPropagation();
            setOpened(true);
        }
    }, []);

    const handlePopoverClose = useCallback(() => {
        if (opened) {
            return false;
        }

        setAnchorEl(null);
    }, [opened]);

    const handlePopoverCloseClick = useCallback(() => {
        setOpened(false);
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    const lastEventWithSigners = (data?.length ? data : electronicSignature.events).findLast(
        (e) => e.data?.signers?.length
    );

    if (!lastEventWithSigners) {
        return null;
    }

    return (
        <>
            <InfoIcon
                fontSize="small"
                onDoubleClick={(e) => handlePopoverOpen(e, true)}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ color: colors.gray700, cursor: 'pointer' }}
            />
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableRestoreFocus
                id={`popover-signatures-${electronicSignature.id}`}
                onClose={handlePopoverClose}
                open={open}
                sx={{ pointerEvents: opened ? 'auto' : 'none' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{
                        color: colors.white,
                        backgroundColor: colors.gray700,
                        p: 1,
                        width: '400px',
                    }}
                >
                    <Box alignItems="center" display="flex" pb={1}>
                        <Box alignItems="center" display="flex" flex={1}>
                            <Typography variant="h5">Signatures</Typography>
                            {isLoading && (
                                <Box ml={1}>
                                    <LoadingSpinner noPadding size="extraSmall" useOpenGovStyle />
                                </Box>
                            )}
                        </Box>
                        {opened && (
                            <Box textAlign="right">
                                <IconButton
                                    color="inherit"
                                    onClick={handlePopoverCloseClick}
                                    qaTag="signatures-popoverClose"
                                    size="small"
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        )}
                    </Box>

                    {lastEventWithSigners.data.signers
                        .sort((a, b) => a.routingOrder - b.routingOrder)
                        .map((signer, i) => {
                            const newRoutingOrder =
                                i === 0 ||
                                signer.routingOrder !==
                                    lastEventWithSigners.data.signers[i - 1].routingOrder;
                            let date = null;

                            if (signer.status === COMPLETED && signer.signedDateTime) {
                                date = `Signed on ${moment(signer.signedDateTime).format('MM/DD/YYYY [at] hh:mm A')}`;
                            }

                            if (signer.status === DELIVERED && signer.deliveredDateTime) {
                                date = `Opened on ${moment(signer.deliveredDateTime).format('MM/DD/YYYY [at] hh:mm A')}`;
                            }

                            if (signer.status === SENT && signer.sentDateTime) {
                                date = `Sent on ${moment(signer.sentDateTime).format('MM/DD/YYYY [at] hh:mm A')}`;
                            }

                            return (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    key={i}
                                    sx={{
                                        borderTop: newRoutingOrder
                                            ? `1px solid ${colors.gray300}`
                                            : 0,
                                        py: 0.5,
                                    }}
                                >
                                    <Box alignItems="center" display="flex" flex={1.5}>
                                        <Box mr={1}>
                                            <Box
                                                sx={{
                                                    backgroundColor: colors.white,
                                                    color: colors.gray700,
                                                    borderRadius: '50%',
                                                    px: 1,
                                                }}
                                            >
                                                <Typography fontWeight={600} variant="bodyXSmall">
                                                    {signer.routingOrder || 1}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography fontWeight={500} variant="bodySmall">
                                                {signer.name}
                                            </Typography>
                                            <Typography variant="bodyXSmall">{date}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flex={1} justifyContent="flex-end">
                                        {signer.status === COMPLETED && (
                                            <Box
                                                sx={{
                                                    color: colors.pear700,
                                                    backgroundColor: colors.pear100,
                                                    borderRadius: '4px',
                                                    px: 1,
                                                    py: 0.5,
                                                }}
                                            >
                                                <Typography fontWeight={600} variant="bodyXSmall">
                                                    Signed
                                                </Typography>
                                            </Box>
                                        )}
                                        {[DELIVERED, SENT].includes(signer.status) && (
                                            <Box
                                                sx={{
                                                    color: colors.gray800,
                                                    backgroundColor: colors.white,
                                                    borderRadius: '4px',
                                                    px: 1,
                                                    py: 0.5,
                                                }}
                                            >
                                                <Typography fontWeight={600} variant="bodyXSmall">
                                                    {signer.status === DELIVERED
                                                        ? 'Opened'
                                                        : 'Sent'}
                                                </Typography>
                                            </Box>
                                        )}
                                        {signer.status === DECLINED && (
                                            <Box
                                                sx={{
                                                    color: colors.white,
                                                    backgroundColor: colors.red700,
                                                    borderRadius: '4px',
                                                    px: 1,
                                                    py: 0.5,
                                                }}
                                            >
                                                <Typography fontWeight={600} variant="bodyXSmall">
                                                    Declined
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            </Popover>
        </>
    );
};

SignaturesPopover.propTypes = {
    electronicSignature: PropTypes.shape({
        id: PropTypes.number,
        currentSigners: PropTypes.number.isRequired,
        totalSigners: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    projectId: PropTypes.number.isRequired,
};
