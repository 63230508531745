import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    Close as CloseIcon,
    Output as OutputIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { CertificationsMenu } from './CertificationsMenu';
import { retrieveVendors } from './helpers';
import { CategorySelectButton } from '../../../containers/CategorySelect';
import { FiltersMenu } from './FiltersMenu';
import {
    loadProcuratedRatingsClient,
    loadPublicVendorLists,
    loadVendorLists,
} from '../../../actions/vendorList';
import Columns from './Columns';
import { TakeActionsMenu } from './TakeActionsMenu';
import { getOpenSearchObject } from '../../../helpers/vendorSearch';
import {
    ArrayCellRenderer,
    VendorNameCellRenderer,
    WebsiteCellRenderer,
} from '../../AgGridReactLegacy/renderers';
import { TextCellRenderer } from './cellRenderers/TextCellRenderer';
import { downloadVendorSearch } from '../../../actions/vendorSearch';
import { showVendorProfileModal } from '../../../actions/vendorProfile';
import { CategoriesCellRenderer } from './cellRenderers/CategoriesCellRenderer';
import { getUserJS } from '../../../containers/selectors';
import { ExportModal } from './ExportModal';
import { SubscribersTable } from './SubscribersTable';
import { VendorsTable } from './VendorsTable';

const DEFAULT_SEARCH_PARAMS = {
    categories: '',
    certifications: '',
    ethnicities: [],
    languages: [],
    searchText: '',
    vendorLists: [],
};

export const VendorSearchTable = ({
    addVendorsToList,
    addVendorsToListBySearch,
    blockVendors,
    emailVendors,
    emailVendorsBySearch,
    governmentId,
    inviteVendorsToProject,
    inviteVendorsToProjectBySearch,
    searchVendors,
    isPublic,
}) => {
    const dispatch = useDispatch();

    const [vendorsView, setVendorsView] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [exportModalOpen, setExportModalOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams(DEFAULT_SEARCH_PARAMS);

    const user = useSelector(getUserJS);

    const combineListColumns = isPublic || !user?.government?.displayPublicVendorInformation;

    const categories = searchParams.get('categories');
    const categoryIds = categories ? categories.split(',') : [];

    const certifications = searchParams.get('certifications');
    const certificationIds = certifications
        ? certifications.split(',').map((certification) => parseInt(certification, 10))
        : [];

    const ethnicities = searchParams.get('ethnicities')
        ? searchParams.get('ethnicities').split(',')
        : [];

    const vendorLists = searchParams.get('vendorLists')
        ? searchParams.get('vendorLists').split(',')
        : [];

    const languages = searchParams.get('languages') ? searchParams.get('languages').split(',') : [];

    const searchText = searchParams.get('searchText');

    const downloadingVendorSearch = useSelector((state) => state.vendorSearch.toJS().downloading);

    const enableInternalVendorManagementTable = useFlags(
        FLAGS.ENABLE_NEW_INTERNAL_VENDOR_MANAGEMENT_TABLE
    );

    const searchObject = {
        searchText,
        categoryIds,
        certificationIds,
        ethnicities,
        vendorLists,
        languages,
    };

    const [filterFormData, setFilterFormData] = useState({
        ethnicities,
        languages,
        vendorLists,
    });

    const hasFilter =
        categoryIds.length > 0 ||
        certificationIds.length > 0 ||
        ethnicities.length > 0 ||
        vendorLists.length > 0 ||
        languages.length > 0 ||
        !!searchText;

    const handleSelectionChanged = () => {
        const newSelectedUsers = gridApi.getSelectedRows().map((row) => {
            return row;
        });

        setSelectedUsers(newSelectedUsers);
    };

    const loadVendors = (api) => {
        const dataSource = retrieveVendors(
            searchVendors,
            governmentId,
            searchObject,
            loadProcuratedRatingsClient,
            dispatch,
            isPublic,
            vendorsView
        );

        api?.setGridOption('serverSideDatasource', dataSource);
    };

    useEffect(() => {
        if (isPublic) {
            dispatch(loadPublicVendorLists(governmentId));
        } else {
            dispatch(loadVendorLists(governmentId));
        }
    }, []);

    useEffect(() => {
        const debounceLoadVendors = debounce(() => {
            loadVendors(gridApi);
        }, 300);

        debounceLoadVendors();

        return () => {
            debounceLoadVendors.cancel();
        };
    }, [searchParams]);

    const onGridReady = (params) => {
        loadVendors(params.api);
        setGridApi(params.api);
    };

    const onCategorySelect = (selectedCategories, hasChanged) => {
        if (hasChanged) {
            searchParams.set(
                'categories',
                selectedCategories.map((selectedCategory) => selectedCategory.id).join(',')
            );

            setSearchParams(searchParams);
        }
    };

    const onCertificationSelect = (selectedCertification) => {
        const currentIndex = certificationIds.indexOf(selectedCertification);
        const newSelected = [...certificationIds];
        if (currentIndex === -1) {
            newSelected.push(selectedCertification);
        } else {
            newSelected.splice(currentIndex, 1);
        }
        searchParams.set('certifications', newSelected.join(','));
        setSearchParams(searchParams);
    };

    const onClearAll = () => {
        setSearchParams(DEFAULT_SEARCH_PARAMS);
        setFilterFormData({
            ethnicities: [],
            languages: [],
            vendorLists: [],
        });
    };

    const onExportClick = (email, cloudflareToken) => {
        dispatch(
            downloadVendorSearch(
                governmentId,
                {
                    searchObject,
                    sortModel: [],
                },
                isPublic,
                email,
                cloudflareToken
            )
        );
    };

    const onVendorCellClick = (params) => {
        dispatch(showVendorProfileModal(params.data.id));
    };

    const columnList = [
        {
            headerName: isPublic
                ? 'Vendor Legal Name'
                : 'Vendor Legal Name & Rating (Powered by Procurated)',
            field: 'legalName',
            pinned: 'left',
            onCellClicked: onVendorCellClick,
            cellRenderer: VendorNameCellRenderer,
            width: 310,
            sortable: true,
            isPublic: true,
        },
        {
            headerName: 'Doing Business As (DBA) Name',
            field: 'doingBusinessAs',
            width: 245,
            sortable: true,
            cellRenderer: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Subscriber Name',
            field: 'subscriberName',
            cellRenderer: TextCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Job Title',
            field: 'subscriberJobTitle',
            cellRenderer: TextCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Email',
            field: 'subscriberEmail',
            cellRenderer: TextCellRenderer,
            width: 260,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Phone',
            field: 'subscriberPhone',
            cellRenderer: TextCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Status',
            field: 'subscriberStatus',
            cellRenderer: TextCellRenderer,
            isPublic: false,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Website',
            field: 'website',
            cellRenderer: WebsiteCellRenderer,
            width: 300,
            isPublic: true,
        },
        {
            headerName: 'Company Phone',
            field: 'companyPhone',
            cellRenderer: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Address',
            field: 'address',
            cellRenderer: ArrayCellRenderer,
            width: 350,
            isPublic: true,
        },
        {
            headerName: 'Languages',
            field: 'languages',
            cellRenderer: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Company Description',
            field: 'companyDescription',
            cellRenderer: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Verified Certifications',
            field: 'verifiedCertifications',
            cellRenderer: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Self-Reported Certifications',
            field: 'selfReportedCertifications',
            cellRenderer: ArrayCellRenderer,
            width: 225,
            isPublic: true,
        },
        {
            headerName: 'NIGP Category Codes',
            field: 'nigpCategoryCodes',
            cellRenderer: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'NAICS Category Codes',
            field: 'naicsCategoryCodes',
            cellRenderer: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'UNSPSC Category Codes',
            field: 'unspscCategoryCodes',
            cellRenderer: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'Ethnicity',
            field: 'ethnicity',
            cellRenderer: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: combineListColumns ? 'Lists' : 'Public Lists',
            field: 'vendorLists',
            cellRenderer: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Private Lists',
            field: 'vendorListsPrivate',
            cellRenderer: ArrayCellRenderer,
            isPublic: false,
            defaultHidden: combineListColumns,
        },
        {
            headerName: 'Business Type',
            field: 'businessType',
            cellRenderer: TextCellRenderer,
            isPublic: false,
            defaultHidden: combineListColumns,
        },
        {
            headerName: 'Billing Address',
            field: 'billingAddress',
            cellRenderer: ArrayCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'State of Incorporation',
            field: 'stateOfIncorporation',
            cellRenderer: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'EIN',
            field: 'ein',
            cellRenderer: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'UEI',
            field: 'uei',
            cellRenderer: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'DUNS',
            field: 'duns',
            cellRenderer: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'OpenGov Supplier ID',
            field: 'openGovSupplierId',
            cellRenderer: TextCellRenderer,
            isPublic: false,
        },
    ];

    const [columns, setColumns] = useState(
        columnList.map((column) => {
            if (
                (isPublic && !column.isPublic) ||
                column.subscriberViewOnly ||
                column.defaultHidden
            ) {
                return {
                    ...column,
                    hide: true,
                };
            }
            return column;
        })
    );

    const toggleFilterColumns = (field) => {
        setColumns(
            columns.map((column) => {
                if (column.field === field) {
                    return { ...column, hide: !column.hide };
                }
                return column;
            })
        );
    };

    useEffect(() => {
        loadVendors(gridApi);
        setColumns((prevColumns) =>
            prevColumns.map((column) => {
                if (!vendorsView && column.subscriberViewOnly && (!isPublic || column.isPublic)) {
                    return { ...column, hide: false };
                }
                if (vendorsView && column.subscriberViewOnly) return { ...column, hide: true };

                return column;
            })
        );
    }, [vendorsView]);

    return (
        <Box pb={7} pt={3}>
            <Box display="flex" justifyContent="space-between" pb={2}>
                <Box display="flex" flexDirection="column">
                    <form title="Search vendors by name or address">
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment aria-label="search icon" position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props -- Props are different.
                            inputProps={{
                                title: 'Search vendors by name or address',
                                'aria-label': 'Search vendors by name or address',
                            }}
                            onChange={(e) => {
                                searchParams.set('searchText', e.target.value);
                                setSearchParams(searchParams);
                            }}
                            placeholder="Search names or address"
                            qaTag="vendorSearch-search"
                            sx={{
                                padding: 0,
                                marginBottom: '12px',
                                maxWidth: '421px',
                                width: '100%',
                                '& .MuiInputBase-root': {
                                    margin: 0,
                                },
                            }}
                            value={searchText}
                        />
                        <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    fontWeight: tokens.typography.fontWeightMedium,
                                    fontSize: tokens.typography.fontSizeSmall,
                                }}
                                variant="h3"
                            >
                                Filter By:
                            </Typography>
                            <CategorySelectButton
                                categoryIds={categoryIds}
                                onSelect={onCategorySelect}
                            />
                            <CertificationsMenu
                                handleToggle={onCertificationSelect}
                                selected={certificationIds}
                            />
                            <FiltersMenu
                                formData={filterFormData}
                                isPublic={isPublic}
                                searchParams={searchParams}
                                setFormData={setFilterFormData}
                                setSearchParams={setSearchParams}
                            />
                            {hasFilter ? (
                                <Button
                                    onClick={onClearAll}
                                    size="small"
                                    startIcon={<CloseIcon />}
                                    sx={{ fontSize: tokens.typography.fontSizeDefault }}
                                >
                                    Clear All
                                </Button>
                            ) : null}
                        </Box>
                    </form>
                </Box>
                <Box display="flex" flexDirection="column" gap={1} justifyContent="flex-end">
                    <Box display="flex" gap={1} justifyContent="flex-end">
                        {!isPublic && !vendorsView && (
                            <TakeActionsMenu
                                addVendorsToList={addVendorsToList}
                                addVendorsToListBySearch={addVendorsToListBySearch}
                                blockVendors={blockVendors}
                                emailVendors={emailVendors}
                                emailVendorsBySearch={emailVendorsBySearch}
                                gridApi={gridApi}
                                inviteVendorsToProject={inviteVendorsToProject}
                                inviteVendorsToProjectBySearch={inviteVendorsToProjectBySearch}
                                searchParams={getOpenSearchObject({
                                    searchObject,
                                })}
                                selectedUsers={selectedUsers}
                            />
                        )}
                        <Columns
                            columns={columns.filter(
                                (column) =>
                                    (!vendorsView || !column.subscriberViewOnly) &&
                                    (!isPublic || column.isPublic) &&
                                    (!combineListColumns ||
                                        !column.field?.includes('vendorListsPrivate'))
                            )}
                            onClickCheckBox={toggleFilterColumns}
                        />
                        <Button
                            color="secondary"
                            disabled={downloadingVendorSearch}
                            onClick={() => setExportModalOpen(true)}
                            qaTag="vendorSearch-export"
                            startIcon={
                                downloadingVendorSearch ? (
                                    <CircularProgress color="inherit" size="extraSmall" />
                                ) : (
                                    <OutputIcon />
                                )
                            }
                            variant="text"
                        >
                            Export
                        </Button>
                    </Box>
                    {enableInternalVendorManagementTable ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={1}
                            justifyContent="flex-end"
                            pr={1.5}
                        >
                            <Box>
                                <Typography
                                    for="switch"
                                    id="switchLabel"
                                    sx={{
                                        color: tokens.colors.colorGray800,
                                        fontSize: tokens.typography.fontSizeSmall,
                                        fontWeight: tokens.typography.fontWeightRegular,
                                    }}
                                >
                                    Viewing:
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" gap={0.5}>
                                <Typography
                                    sx={{
                                        color: tokens.colors.colorGray1000,
                                        fontWeight: !vendorsView
                                            ? tokens.typography.fontWeightMedium
                                            : tokens.typography.fontWeightRegular,
                                    }}
                                    variant="bodySmall"
                                >
                                    Subscribers
                                </Typography>
                                <Switch
                                    checked={vendorsView}
                                    inputProps={{
                                        id: 'switch',
                                        'aria-label': 'Switch to show or hide subscriber details',
                                        'aria-labelledby': 'switchLabel',
                                    }}
                                    onChange={(event) => {
                                        setVendorsView(event.target.checked);
                                    }}
                                    qaTag="vendorSearch-showHideSubscribers"
                                    size="small"
                                />
                                <Typography
                                    sx={{
                                        color: tokens.colors.colorGray700,
                                        fontWeight: vendorsView
                                            ? tokens.typography.fontWeightMedium
                                            : tokens.typography.fontWeightRegular,
                                    }}
                                    variant="bodySmall"
                                >
                                    Vendors
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Box pb={2} sx={{ height: '634px' }}>
                {!vendorsView && (
                    <SubscribersTable
                        columns={columns}
                        handleSelectionChanged={handleSelectionChanged}
                        isPublic={isPublic}
                        onGridReady={onGridReady}
                    />
                )}
                {vendorsView && <VendorsTable columns={columns} onGridReady={onGridReady} />}
            </Box>
            <ExportModal
                isPublic={isPublic}
                onClose={() => setExportModalOpen(false)}
                onSubmit={(email, token) => {
                    onExportClick(email, token);
                    setExportModalOpen(false);
                }}
                open={exportModalOpen}
            />
        </Box>
    );
};

VendorSearchTable.propTypes = {
    addVendorsToList: PropTypes.func,
    addVendorsToListBySearch: PropTypes.func,
    blockVendors: PropTypes.func,
    emailVendors: PropTypes.func,
    emailVendorsBySearch: PropTypes.func,
    governmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    inviteVendorsToProject: PropTypes.func,
    inviteVendorsToProjectBySearch: PropTypes.func,
    isPublic: PropTypes.bool,
    searchVendors: PropTypes.func.isRequired,
};
