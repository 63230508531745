import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../../../../../components';
import { ColumnOptions } from './ColumnOptions';
import { ReverseAuctionToggles } from './ToggleOptions/ReverseAuctionToggles';
import { StandardToggles } from './ToggleOptions/StandardToggles';
import { TitleOptions } from './TitleOptions';

export class OptionsModal extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        auctionMaxFractionDigits: PropTypes.number.isRequired,
        change: PropTypes.func.isRequired,
        columnChangeHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        isReverseAuction: PropTypes.bool,
        optionsToggleHandler: PropTypes.func,
        priceTable: PropTypes.shape({
            percentageAdjustmentType: PropTypes.string,
            hasPercentage: PropTypes.bool,
            hasQuantity: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
        }).isRequired,
        salesTax: PropTypes.number,
        showOptionsModal: PropTypes.bool.isRequired,
        showTableSettingsPage: PropTypes.bool.isRequired,
        showValidation: PropTypes.bool,
        toggleShowTableSettingsPage: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        showValidation: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            arrayName,
            auctionMaxFractionDigits,
            change,
            columnChangeHandler,
            disabled,
            fields,
            isReverseAuction,
            optionsToggleHandler,
            priceTable,
            salesTax,
            showOptionsModal,
            showTableSettingsPage,
            showValidation,
            toggleShowTableSettingsPage,
        } = this.props;

        return (
            <Modal
                className={this.styles.pricingOptions}
                onHide={() => optionsToggleHandler()}
                show={showOptionsModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {`Edit ${showTableSettingsPage ? 'Table' : 'Column'} Settings`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {showTableSettingsPage ? (
                            <>
                                <div className="col-sm-9 col-xs-12">
                                    <TitleOptions
                                        arrayName={arrayName}
                                        disabled={disabled}
                                        showValidation={showValidation}
                                    />
                                </div>
                                <div className="col-xs-12">
                                    {isReverseAuction ? (
                                        <ReverseAuctionToggles
                                            arrayName={arrayName}
                                            auctionMaxFractionDigits={auctionMaxFractionDigits}
                                            change={change}
                                            columnChangeHandler={columnChangeHandler}
                                            disabled={disabled}
                                            fields={fields}
                                            showValidation={showValidation}
                                        />
                                    ) : (
                                        <StandardToggles
                                            arrayName={arrayName}
                                            change={change}
                                            columnChangeHandler={columnChangeHandler}
                                            disabled={disabled}
                                            fields={fields}
                                            hasPercentage={priceTable.hasPercentage}
                                            hasQuantity={priceTable.hasQuantity}
                                            hasTotalRow={priceTable.hasTotalRow}
                                            isReverseAuction={isReverseAuction}
                                            salesTax={salesTax}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="col-xs-12">
                                <ColumnOptions
                                    arrayName={arrayName}
                                    change={change}
                                    columnChangeHandler={columnChangeHandler}
                                    disabled={disabled}
                                    priceTable={priceTable}
                                />
                            </div>
                        )}
                        <div className={`col-xs-12 text-right ${this.styles.btnContainer}`}>
                            {!isReverseAuction && (
                                <Button
                                    bsSize="sm"
                                    disabled={disabled}
                                    onClick={() => toggleShowTableSettingsPage()}
                                    qaTag="priceTableOptionsModal-toggleShowTableSettingsPage"
                                >
                                    <i
                                        className={
                                            showTableSettingsPage
                                                ? 'fa fa-arrow-right'
                                                : 'fa fa-arrow-left'
                                        }
                                    />
                                    &nbsp;
                                    {showTableSettingsPage ? 'Column Settings' : 'Table Settings'}
                                </Button>
                            )}
                            <Button
                                bsSize="sm"
                                className={this.styles.doneBtn}
                                disabled={disabled}
                                onClick={() => optionsToggleHandler()}
                                qaTag="priceTableOptionsModal-done"
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
