const { listToDict } = require('../helpers');
const {
    getCompositeEntityQueryKey,
    getCompositeKafkaQueryKey,
    getCompositeUserQueryKey,
} = require('./helpers');

exports.inboxNotificationStatuses = ['new', 'inProgress', 'completed'];

exports.inboxNotificationStatusesDict = listToDict(exports.inboxNotificationStatuses);

const inboxNotificationCategorySets = {
    REQUISITIONS: 5,
    INTAKE: 10,
    BUILDER: 20,
    SOURCING: 30,
    EVALUATIONS: 40,
    CONTRACTS: 50,
    UNCATEGORIZED: 60,
};

exports.inboxNotificationCategorySets = inboxNotificationCategorySets;

exports.inboxNotificationCategorySetsArray = [
    inboxNotificationCategorySets.REQUISITIONS,
    inboxNotificationCategorySets.INTAKE,
    inboxNotificationCategorySets.BUILDER,
    inboxNotificationCategorySets.SOURCING,
    inboxNotificationCategorySets.EVALUATIONS,
    inboxNotificationCategorySets.CONTRACTS,
    inboxNotificationCategorySets.UNCATEGORIZED,
];

exports.inboxNotificationCategoryNames = {
    [inboxNotificationCategorySets.REQUISITIONS]: 'REQUISITIONS',
    [inboxNotificationCategorySets.INTAKE]: 'INTAKE',
    [inboxNotificationCategorySets.BUILDER]: 'BUILDER',
    [inboxNotificationCategorySets.SOURCING]: 'SOURCING',
    [inboxNotificationCategorySets.EVALUATIONS]: 'EVALUATIONS',
    [inboxNotificationCategorySets.CONTRACTS]: 'CONTRACTS',
    [inboxNotificationCategorySets.UNCATEGORIZED]: 'UNCATEGORIZED',
};

exports.auditTypes = {
    EMAIL_AUDIT: 'EmailAudit',
    PRIORITY_EMAIL_AUDIT: 'PriorityEmailAudit',
    RECORD_DELETION_AUDIT: 'RecordDeletionAudit',
    RECORD_AUDIT: 'RecordAudit',
};
exports.userTypes = { REGISTERED_USER: 'RegisteredUser', PENDING_USER: 'PendingUser' };
exports.entityTypes = { PROJECT: 'Project', REQUISITION: 'Requisition', CONTRACT: 'Contract' };
exports.getCompositeEntityQueryKey = getCompositeEntityQueryKey;
exports.getCompositeKafkaQueryKey = getCompositeKafkaQueryKey;
exports.getCompositeUserQueryKey = getCompositeUserQueryKey;
