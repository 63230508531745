import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { TemplateEditV2FunctionsContext } from '../context';

export const TemplateEditV2FunctionsContextProvider = ({
    addConditionalLogicHandler,
    addingSectionData,
    array,
    buildRouteFunction,
    change,
    children,
    createProjectSection,
    createProjectSectionError,
    createTemplateSection,
    createTemplateQuestion,
    createUpfrontQuestion,
    createUpfrontQuestionError,
    disabled,
    isDirty,
    getParentDividerOptions,
    govCode,
    importedSections,
    isDocBuilder,
    isIntake,
    isSpecial,
    manageSectionsDrawerOpened,
    toggleManageSectionsDrawerOpened,
    isSolicitation,
    showConfirmationSimpleModal,
    projectId,
    removeQuestionLogicHandler,
    reloadTemplate,
    router,
    setAddingSection,
    showFormValidation,
    sortSections,
    unsetAddingSection,
    updateTemplate,
    updateTemplateIfDirty,
    upfrontQuestionsUsedInQuestionLogicSet,
    tagOptions,
    templateVariableOptions,
    templateProject,
    toggleUseSectionDividers,
    type,
}) => {
    const memoizedContextValues = useMemo(() => {
        return {
            addConditionalLogicHandler,
            addingSectionData,
            array,
            buildRouteFunction,
            change,
            createProjectSection,
            createProjectSectionError,
            createTemplateSection,
            createTemplateQuestion,
            createUpfrontQuestion,
            createUpfrontQuestionError,
            disabled,
            isDirty,
            getParentDividerOptions,
            govCode,
            importedSections,
            isDocBuilder,
            isIntake,
            isSpecial,
            manageSectionsDrawerOpened,
            toggleManageSectionsDrawerOpened,
            isSolicitation,
            showConfirmationSimpleModal,
            projectId,
            removeQuestionLogicHandler,
            reloadTemplate,
            router,
            setAddingSection,
            showFormValidation,
            sortSections,
            unsetAddingSection,
            updateTemplate,
            updateTemplateIfDirty,
            upfrontQuestionsUsedInQuestionLogicSet,
            tagOptions,
            templateVariableOptions,
            templateProject,
            toggleUseSectionDividers,
            type,
        };
    }, [
        addConditionalLogicHandler,
        addingSectionData,
        array,
        buildRouteFunction,
        change,
        createProjectSection,
        createProjectSectionError,
        createTemplateSection,
        createTemplateQuestion,
        createUpfrontQuestion,
        createUpfrontQuestionError,
        disabled,
        isDirty,
        getParentDividerOptions,
        govCode,
        importedSections,
        isDocBuilder,
        isIntake,
        isSpecial,
        manageSectionsDrawerOpened,
        toggleManageSectionsDrawerOpened,
        isSolicitation,
        showConfirmationSimpleModal,
        projectId,
        removeQuestionLogicHandler,
        reloadTemplate,
        router,
        setAddingSection,
        showFormValidation,
        sortSections,
        unsetAddingSection,
        updateTemplate,
        updateTemplateIfDirty,
        upfrontQuestionsUsedInQuestionLogicSet,
        tagOptions,
        templateVariableOptions,
        templateProject,
        toggleUseSectionDividers,
        type,
    ]);

    return (
        <TemplateEditV2FunctionsContext.Provider value={memoizedContextValues}>
            {children}
        </TemplateEditV2FunctionsContext.Provider>
    );
};

TemplateEditV2FunctionsContextProvider.propTypes = {
    addConditionalLogicHandler: PropTypes.func.isRequired,
    addingSectionData: PropTypes.object,
    array: PropTypes.object.isRequired,
    buildRouteFunction: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    createProjectSection: PropTypes.func.isRequired,
    createProjectSectionError: PropTypes.string,
    createTemplateSection: PropTypes.func.isRequired,
    createTemplateQuestion: PropTypes.func.isRequired,
    createUpfrontQuestion: PropTypes.func.isRequired,
    createUpfrontQuestionError: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDirty: PropTypes.bool.isRequired,
    getParentDividerOptions: PropTypes.func.isRequired,
    govCode: PropTypes.string.isRequired,
    importedSections: PropTypes.array.isRequired,
    isDocBuilder: PropTypes.bool.isRequired,
    isIntake: PropTypes.bool.isRequired,
    isSpecial: PropTypes.bool.isRequired,
    manageSectionsDrawerOpened: PropTypes.bool.isRequired,
    toggleManageSectionsDrawerOpened: PropTypes.func.isRequired,
    isSolicitation: PropTypes.bool.isRequired,
    showConfirmationSimpleModal: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    removeQuestionLogicHandler: PropTypes.func.isRequired,
    reloadTemplate: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    setAddingSection: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    sortSections: PropTypes.func.isRequired,
    unsetAddingSection: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    updateTemplateIfDirty: PropTypes.func.isRequired,
    upfrontQuestionsUsedInQuestionLogicSet: PropTypes.instanceOf(Set).isRequired,
    tagOptions: PropTypes.array.isRequired,
    templateVariableOptions: PropTypes.array.isRequired,
    templateProject: PropTypes.object.isRequired,
    toggleUseSectionDividers: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};
