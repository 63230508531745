import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { filterRecords, handleFilterChange } from '../helper';

import { RecordTable } from '..';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { EXPIRED } from '../../constants';
import { getExpiredJS } from '../../selectors';
import { getGovernmentRetentionCodesJS } from '../../../selectors';
import { expiredTableColumnDef } from '../constants';

const mapStateToProps = (state) => {
    return {
        deleteExpiredError: state.projects.get('deleteExpiredError'),
        deletingExpired: state.projects.get('deletingExpired'),
        expired: getExpiredJS(state),
        loadingExpired: state.projects.get('loadingExpired'),
        loadExpiredError: state.projects.get('loadExpiredError'),
        policies: getGovernmentRetentionCodesJS(state),
    };
};

const ConnectedRecordTableExpired = ({ deletingExpired, expired, loadingExpired, policies }) => {
    const [filter, setFilter] = useState();
    const [recordTypeFilter, setRecordTypeFilter] = useState();
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        setSelectedRows([]);
    }, [expired]);

    const policyWarning =
        "Based on the set policy, these records' retention expiration dates have passed and can be deleted";

    const setFilters = (newFilter) => handleFilterChange(newFilter, setFilter, setRecordTypeFilter);

    const rows = expired
        .filter((record) => {
            return filterRecords(record, filter, recordTypeFilter);
        })
        .map((record) => {
            const availableRenewals = record.contractRenewals
                ? record.contractRenewals.reduce(
                      (acc, renewal) => acc + (renewal.selected === false ? 1 : 0),
                      0
                  )
                : 0;
            const closeDate = record.recordCloseDate || record.endDate;
            return {
                id: record.id,
                title: {
                    title: record.title,
                    projectId: record.id,
                    financialId: record.financialId || record.contractId,
                    governmentId: record.government_id,
                    recordType: record.recordType,
                    openRenewals: availableRenewals,
                },
                department: record.departmentName,
                contact: record.contactDisplayName,
                closedDate: {
                    date: moment(closeDate).format('ll'),
                    rawDate: closeDate,
                    reason: record.closeOutReason || record.status,
                    recordType: record.recordType,
                },
                expirationDate: moment(record.expirationDate).format('ll'),
                recordType: record.recordType,
            };
        });

    if (loadingExpired && !deletingExpired) {
        return <LoadingSpinner />;
    }

    return (
        <RecordTable
            columnDef={expiredTableColumnDef}
            filter={filter}
            loading={deletingExpired}
            policies={policies}
            policyWarning={policyWarning}
            rows={rows}
            selectedRows={selectedRows}
            setFilter={setFilters}
            setSelectedRows={setSelectedRows}
            showDelete
            storeLocation={EXPIRED}
        />
    );
};

ConnectedRecordTableExpired.propTypes = {
    deletingExpired: PropTypes.bool,
    expired: PropTypes.array.isRequired,
    loadingExpired: PropTypes.bool,
    policies: PropTypes.array.isRequired,
};

export const RecordTableExpired = connect(mapStateToProps)(ConnectedRecordTableExpired);
