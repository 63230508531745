import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { StepSectionLayout } from './StepSectionLayout';
import { QuestionLogicFields } from './QuestionLogicFields';
import { hasQuestionLogicOptions } from '../constants';
import {
    reviewSequenceStepFieldNames,
    reviewSequenceStepPseudoFieldNames,
} from '../../../constants';
import { generateReviewSequenceStepQuestionLogic } from '../../../helper';
import { SearchSelect } from '../../../../../../../../components';

const { QUESTION_LOGIC } = reviewSequenceStepFieldNames;
const { HAS_QUESTION_LOGIC } = reviewSequenceStepPseudoFieldNames;

export const ConditionalLogicSection = ({
    change,
    disabled,
    fieldName,
    operator,
    questionLogic,
    reviewSequenceStepId,
    showFormValidation,
}) => {
    const hasQuestionLogicHandler = (value) => {
        const questionLogicValue = value
            ? generateReviewSequenceStepQuestionLogic(reviewSequenceStepId)
            : null;
        change(`${fieldName}.${QUESTION_LOGIC}`, questionLogicValue);
    };

    return (
        <StepSectionLayout title="When to Include Step">
            <Field
                backspaceRemovesValue={false}
                component={SearchSelect}
                disabled={disabled}
                help="Specify when this step should be included in the sequence"
                isSearchable={false}
                name={`${fieldName}.${HAS_QUESTION_LOGIC}`}
                onChange={hasQuestionLogicHandler}
                options={hasQuestionLogicOptions}
                placeholder="Select an option"
                showValidation={showFormValidation}
            />
            {questionLogic && (
                <QuestionLogicFields
                    change={change}
                    disabled={disabled}
                    fieldName={`${fieldName}.${QUESTION_LOGIC}`}
                    operator={operator}
                    questionLogic={questionLogic}
                    showFormValidation={showFormValidation}
                />
            )}
        </StepSectionLayout>
    );
};

ConditionalLogicSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    operator: PropTypes.string,
    questionLogic: PropTypes.object,
    reviewSequenceStepId: PropTypes.number,
    showFormValidation: PropTypes.bool,
};
