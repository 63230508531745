import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { LOWEST_PRICE, LINE_ITEM_AWARD, SCORED } from '@og-pro/shared-config/evaluations';

import { generateVendorAwardData } from '@og-pro/shared-config/priceTables/utils';

import { VendorLineItemAwardsTable } from '../../../../../components/LineItemAwardTable/VendorLineItemAwardsTable';
import { BidTabulationTable, LineItemAwardTable } from '../../../../../components';
import { evaluationReportOptions } from '../../ReportsModal/constants';
import { BestValueReport } from './components/BestValueReport';
import { PassFailReport } from '../PassFailReport';
import { addAgreementsToEvaluators } from './helpers';

export class EvaluationTabulationReport extends PureComponent {
    static propTypes = {
        agreementData: PropTypes.shape({
            agreements: PropTypes.array,
        }),
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isRenderedBySummaryReport: PropTypes.bool,
        options: PropTypes.object,
        project: PropTypes.object.isRequired,
        proposalsWithQuestionnaireResponses: PropTypes.array,
    };

    get headingStyle() {
        return { fontSize: 16, fontWeight: 'bold', marginBottom: 10 };
    }

    get auctionMaxFractionDigits() {
        const {
            project: {
                auctionMaxFractionDigits,
                template: { isReverseAuction },
            },
        } = this.props;
        return isReverseAuction ? auctionMaxFractionDigits : null;
    }

    renderBidTabulationsReport(data, project, isSummary, useLandscape) {
        const { proposalsWithQuestionnaireResponses } = this.props;
        const { bidTabulations, proposalsData, selectedBidTabulationPriceItemIds } = data;

        let docxSize;

        if (isSummary) {
            docxSize = 'summary';
        } else if (useLandscape) {
            docxSize = 'landscape';
        } else {
            docxSize = 'portrait';
        }

        const selectedVendorTotalsData = bidTabulations.reduce(
            (dataForTotals, bidTabulation) => {
                dataForTotals.rows = [...dataForTotals.rows, ...bidTabulation.rows];
                return dataForTotals;
            },
            { priceTable: {}, rows: [] }
        ); // Need the empty priceTable to prevent errors

        return (
            <>
                <BidTabulationTable // Table of vendor totals across all tables
                    auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                    bidTabulation={selectedVendorTotalsData}
                    defaultTitle="Selected Vendor Totals"
                    docxSize="summary"
                    initiallySelectedPriceItemIds={selectedBidTabulationPriceItemIds}
                    isDocx
                    key="Selected Vendor Totals"
                    proposalsData={proposalsData}
                    salesTax={project.government.salesTax}
                    showTotalOnly
                    sortAscending={project.evaluation.bidTabulationSortAsc}
                    useLandscape={useLandscape}
                />
                {bidTabulations.map((bidTabulation, i) => {
                    return (
                        <BidTabulationTable
                            auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                            bidTabulation={bidTabulation}
                            defaultTitle={`Table ${i + 1}`}
                            docxSize={docxSize}
                            initiallySelectedPriceItemIds={selectedBidTabulationPriceItemIds}
                            isDocx
                            key={i + 1}
                            proposalsData={proposalsData}
                            salesTax={project.government.salesTax}
                            showCustomColumns={!isSummary}
                            showTotalOnly={isSummary}
                            sortAscending={project.evaluation.bidTabulationSortAsc}
                            useLandscape={useLandscape}
                        />
                    );
                })}
                {proposalsWithQuestionnaireResponses && (
                    <PassFailReport
                        headingStyle={this.headingStyle}
                        project={project}
                        proposals={proposalsWithQuestionnaireResponses}
                        useLandscape={useLandscape}
                    />
                )}
            </>
        );
    }

    renderLineItemAwardsReport(data, project, isSummary, useLandscape) {
        const { proposalsWithQuestionnaireResponses } = this.props;
        const proposals = data.proposalsData;
        const lineItemAwardsData = data.lineItemAwards;
        const vendorAwardData = generateVendorAwardData(proposals, lineItemAwardsData);

        if (isSummary) {
            return (
                <>
                    {vendorAwardData.map((vendorDataItem, k) => {
                        const { priceTables, vendorCity, vendorName, vendorState } = vendorDataItem;
                        const vendorLocation =
                            vendorCity && vendorState
                                ? `${vendorCity}, ${vendorState}`
                                : 'No Location Provided';

                        return (
                            <React.Fragment key={`vendorTable ${k}`}>
                                <h4>
                                    {vendorName} - {vendorLocation}
                                </h4>
                                {priceTables.map((priceTable) => {
                                    return (
                                        <VendorLineItemAwardsTable
                                            auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                                            isDocx
                                            key={priceTable.id}
                                            omitLineItem={false}
                                            priceTable={priceTable}
                                            salesTax={project.government.salesTax}
                                            useLandscape={useLandscape}
                                            vendorAwardData={vendorDataItem}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {proposalsWithQuestionnaireResponses && (
                        <PassFailReport
                            headingStyle={this.headingStyle}
                            project={project}
                            proposals={proposalsWithQuestionnaireResponses}
                            useLandscape={useLandscape}
                        />
                    )}
                </>
            );
        }

        return (
            <>
                {lineItemAwardsData.map((lineItemAward, i) => {
                    return (
                        <LineItemAwardTable
                            auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                            defaultTitle={`Table ${i + 1}`}
                            isDocx
                            key={i}
                            lineItemAward={lineItemAward}
                            proposalsData={proposals}
                            showCustomColumns
                            useLandscape={useLandscape}
                        />
                    );
                })}
                {proposalsWithQuestionnaireResponses && (
                    <PassFailReport
                        headingStyle={this.headingStyle}
                        project={project}
                        proposals={proposalsWithQuestionnaireResponses}
                        useLandscape={useLandscape}
                    />
                )}
            </>
        );
    }

    render() {
        const {
            agreementData,
            data,
            isRenderedBySummaryReport,
            options,
            project,
            proposalsWithQuestionnaireResponses,
        } = this.props;

        const isSummary = options.reportOption === evaluationReportOptions.SUMMARY;
        const useLandscape = options.useLandscape;
        const evaluationType = options.evaluation.type;
        const agreements = get(agreementData, 'agreements');
        const evalWithAgreementData = addAgreementsToEvaluators(project.evaluation, agreements);

        return (
            <>
                {evaluationType === LOWEST_PRICE &&
                    this.renderBidTabulationsReport(data, project, isSummary, useLandscape)}
                {evaluationType === LINE_ITEM_AWARD &&
                    this.renderLineItemAwardsReport(data, project, isSummary, useLandscape)}
                {evaluationType === SCORED && (
                    <BestValueReport
                        isRenderedBySummaryReport={isRenderedBySummaryReport}
                        isSummary={isSummary}
                        options={options}
                        project={{ ...project, evaluation: evalWithAgreementData }}
                        proposalsWithQuestionnaireResponses={proposalsWithQuestionnaireResponses}
                    />
                )}
            </>
        );
    }
}
