import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { tagTypesDict } from '@og-pro/shared-config/tags';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { ContractInsurancesForm } from './ContractInsurancesForm';
import { fieldNamesDict } from './ContractInsurancesForm/constants';
import { getTagsManagementPath, isContractEditor } from '../selectors';
import { getContractJS, isSystemAdminUser } from '../../selectors';
import { updateContractInsurances } from '../../../actions/contracts';
import { Main, SectionTitle } from '../../../components';
import { AdminUserListButton } from '../../../components/connected';

const { CONTRACT_INSURANCES } = fieldNamesDict;

const { CONTRACT_INSURANCE } = tagTypesDict;

const { IS_SYSTEM_ADMIN } = userRolePermissions;

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        isEditor: isContractEditor(state),
        isSystemAdmin: isSystemAdminUser(state),
        managingInsurances: state.contracts.get('managingInsurances'),
        tagsManagementPath: getTagsManagementPath(state),
    };
};

const mapDispatchToProps = {
    updateContractInsurances,
};

// @connect
class ConnectedContractInsurances extends Component {
    static propTypes = {
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        isEditor: PropTypes.bool,
        isSystemAdmin: PropTypes.bool,
        managingInsurances: PropTypes.bool,
        tagsManagementPath: PropTypes.string.isRequired,
        updateContractInsurances: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditor: false,
        isSystemAdmin: false,
        managingInsurances: false,
    };

    handleSubmit = (data) => {
        const { contract } = this.props;

        if (!data || !data[CONTRACT_INSURANCES]) {
            return;
        }

        this.props.updateContractInsurances(contract.id, data[CONTRACT_INSURANCES]);
    };

    renderInfo() {
        const { isSystemAdmin, tagsManagementPath } = this.props;

        return (
            <>
                Add details about contract insurance here.
                <br />
                {isSystemAdmin ? (
                    <>
                        You can add more insurance types in the&nbsp;
                        <Link to={`${tagsManagementPath}?type=${CONTRACT_INSURANCE}`}>
                            Tags Management dashboard
                        </Link>
                        .
                    </>
                ) : (
                    <>
                        If an insurance type is missing,&nbsp;
                        <AdminUserListButton
                            permissionType={IS_SYSTEM_ADMIN}
                            text="ask a system admin"
                        />
                        &nbsp;to add it in the Tags Management dashboard
                    </>
                )}
            </>
        );
    }

    render() {
        const { isEditor, managingInsurances } = this.props;

        return (
            <Main>
                <SectionTitle info={this.renderInfo()} title="Insurance" />
                <ContractInsurancesForm
                    disabled={managingInsurances || !isEditor}
                    onSubmit={this.handleSubmit}
                />
            </Main>
        );
    }
}

export const ContractInsurances = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractInsurances);
