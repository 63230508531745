import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const modalFooterStyle = {
    alignItems: 'center',
    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
    color: capitalDesignTokens.semanticColors.foreground.secondary,
    border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
    display: 'flex',
    gap: 1,
    justifyContent: 'center',
    paddingY: 1.5,
};

export const formSectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    paddingBottom: 1.5,
    paddingTop: 1,
};

export const modalContentStyle = {
    display: 'flex',
    flexDirection: 'column',
};

export const alertStyle = {
    paddingBottom: 1.5,
    paddingTop: 1,
};

export const buttonsStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
    paddingBottom: 0.5,
    paddingTop: 3,
};
