import { connect, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { showConfirmationModal } from '../../actions/confirmation';
import { loadRetentionCode } from '../../actions/retention';
import { getProjectJS, getRetentionCodeJS } from '../../containers/GovApp/selectors';
import { RetentionBadge } from './RetentionBadge';
import { UPDATE_RETENTION_POLICY } from '../../constants/menuActions';

const mapStateToProps = (state) => {
    return {
        loading: state.retention.get('loadingRetentionCode'),
        project: getProjectJS(state),
        retentionCode: getRetentionCodeJS(state),
    };
};

export const ConnectedProjectRetentionPolicyButton = ({
    canEdit,
    loading,
    project,
    retentionCode,
    retentionCodeId,
}) => {
    const styles = require('./index.scss');
    const intakeOnly = project.isIntake && !project.isIntakeConverted;
    const editable = !intakeOnly && canEdit;
    const dispatch = useDispatch();

    useEffect(() => {
        if (retentionCodeId) {
            dispatch(loadRetentionCode({ retentionCodeId }));
        }
    }, [retentionCodeId]);

    const showRetentionPolicyModal = () => {
        return dispatch(
            showConfirmationModal(UPDATE_RETENTION_POLICY, {
                closeDate: project.recordCloseDate,
                isProject: true,
                project,
                retentionCodeId,
            })
        );
    };

    return (
        <span
            className={editable ? styles.clickableText : undefined}
            onClick={editable ? showRetentionPolicyModal : undefined}
        >
            <RetentionBadge
                loading={loading}
                retentionCode={retentionCode}
                retentionCodeId={retentionCodeId}
            />
            {editable && <i className={`fa fa-edit ${styles.icon}`} title="edit" />}
        </span>
    );
};

ConnectedProjectRetentionPolicyButton.propTypes = {
    canEdit: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    retentionCode: PropTypes.object,
    retentionCodeId: PropTypes.number,
};

export const ProjectRetentionPolicyButton = connect(mapStateToProps)(
    ConnectedProjectRetentionPolicyButton
);
