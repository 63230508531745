import { isNil } from 'lodash';

import { fieldNames } from './constants';

const {
    CUSTOM_FORM_ID,
    CREATE_NEW_CUSTOM_FORM,
    DELETE_CUSTOM_FORM,
    DESIRED_DELIVERY_DATE,
    HIDE_SECTION_ATTACHMENT,
    HIDE_SECTION_PURCHASE,
    HIDE_SECTION_VENDOR,
    PURCHASE_DETAILS_MODE,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_CUSTOM_FORM,
    SECTION_NAME_GENERAL,
    SECTION_NAME_PURCHASE,
    SECTION_NAME_VENDOR,
    SHOW_ACCOUNT_INFORMATION,
} = fieldNames;

export const ERROR_MESSAGES = {
    DUPLICATE_NAME:
        'This section name is already in use and cannot be duplicated. Please change the name.',
    REQUIRED: 'Section name is required',
    TOO_LONG: 'Section name is too long',
};

const safeTrim = (str) => (str || '').trim();

const checkDuplicateName = (values, currentField) => {
    const sectionFields = [
        SECTION_NAME_ADDITIONAL,
        SECTION_NAME_ATTACHMENT,
        SECTION_NAME_CUSTOM_FORM,
        SECTION_NAME_GENERAL,
        SECTION_NAME_PURCHASE,
        SECTION_NAME_VENDOR,
    ];

    // Safely get the current value and trim it
    const currentValue = safeTrim(values[currentField]);
    if (!currentValue) return false;

    return sectionFields.some((field) => {
        if (field === currentField) return false;

        if (field === SECTION_NAME_CUSTOM_FORM && !values[CREATE_NEW_CUSTOM_FORM]) {
            return false;
        }

        if (currentField === SECTION_NAME_CUSTOM_FORM && !values[CREATE_NEW_CUSTOM_FORM]) {
            return false;
        }

        const value = safeTrim(values[field]);
        return value && value.toLowerCase() === currentValue.toLowerCase();
    });
};

export const validate = (values) => {
    const errors = {};

    // Required field validations
    [SECTION_NAME_ADDITIONAL, SECTION_NAME_GENERAL].forEach((sectionName) => {
        if (!safeTrim(values[sectionName])) {
            errors[sectionName] = ERROR_MESSAGES.REQUIRED;
        } else if (checkDuplicateName(values, sectionName)) {
            errors[sectionName] = ERROR_MESSAGES.DUPLICATE_NAME;
        }
    });

    [
        [SECTION_NAME_ATTACHMENT, HIDE_SECTION_ATTACHMENT],
        [SECTION_NAME_PURCHASE, HIDE_SECTION_PURCHASE],
        [SECTION_NAME_VENDOR, HIDE_SECTION_VENDOR],
    ].forEach(([sectionName, isSectionHiddenField]) => {
        if (!values[isSectionHiddenField] && !safeTrim(values[sectionName])) {
            errors[sectionName] = ERROR_MESSAGES.REQUIRED;
        } else if (!values[isSectionHiddenField] && checkDuplicateName(values, sectionName)) {
            errors[sectionName] = ERROR_MESSAGES.DUPLICATE_NAME;
        }
    });

    // Custom form validation
    if (
        !values[DELETE_CUSTOM_FORM] &&
        (!!values[CUSTOM_FORM_ID] || values[CREATE_NEW_CUSTOM_FORM])
    ) {
        if (!safeTrim(values[SECTION_NAME_CUSTOM_FORM])) {
            errors[SECTION_NAME_CUSTOM_FORM] = ERROR_MESSAGES.REQUIRED;
        } else if (checkDuplicateName(values, SECTION_NAME_CUSTOM_FORM)) {
            errors[SECTION_NAME_CUSTOM_FORM] = ERROR_MESSAGES.DUPLICATE_NAME;
        }
    }

    // Length validation
    [
        SECTION_NAME_ADDITIONAL,
        SECTION_NAME_ATTACHMENT,
        SECTION_NAME_CUSTOM_FORM,
        SECTION_NAME_GENERAL,
        SECTION_NAME_PURCHASE,
        SECTION_NAME_VENDOR,
    ].forEach((sectionName) => {
        if (values[sectionName]?.length > 256) {
            errors[sectionName] = ERROR_MESSAGES.TOO_LONG;
        }
    });

    if (isNil(values[PURCHASE_DETAILS_MODE])) {
        errors[PURCHASE_DETAILS_MODE] = 'Collection requirements are required';
    }

    if (isNil(values[SHOW_ACCOUNT_INFORMATION])) {
        errors[SHOW_ACCOUNT_INFORMATION] = 'Account information is required';
    }

    if (isNil(values[DESIRED_DELIVERY_DATE])) {
        errors[DESIRED_DELIVERY_DATE] = 'Desired delivery date is required';
    }

    return errors;
};
