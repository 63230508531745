import { REQUIRED_TEXT } from './constants';
import { title } from './title';

/**
 * Validates evaluation scoring criteria items
 * @param {object} criteria Criteria item to validate
 * @param {object} [opts={}] Options
 * @param {boolean} [opts.skipWeight] Allows the weight field to be omitted
 * @return {object} Object with keys added for each error field
 */
export function evaluationCriteriaItem(criteria, opts = {}) {
    // Don't validate `isHiddenByLogic` items
    if (criteria.isHiddenByLogic) {
        return undefined;
    }

    const criteriaErrors = {
        ...title(criteria),
    };

    if (criteria.description && criteria.description.length >= 9000) {
        criteriaErrors.description = 'This field is too long';
    }

    if (criteria.rawDescription && criteria.rawDescription.length >= 9000) {
        criteriaErrors.rawDescription = 'This field is too long';
    }

    if (!criteria.scoringMethod) {
        criteriaErrors.scoringMethod = REQUIRED_TEXT;
    }

    if (!criteria.weight) {
        if (!opts.skipWeight) {
            criteriaErrors.weight = REQUIRED_TEXT;
        }
    } else if (Number.isNaN(Number.parseFloat(criteria.weight)) || criteria.weight < 1) {
        criteriaErrors.weight = 'Weight must be a number greater than 0';
    }

    return criteriaErrors;
}
