import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { CheckCircle } from '@mui/icons-material';
import { Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { CreatedDate } from './CreatedDate';
import { PurchaseOrder } from './PurchaseOrder';
import { getRequisitionEndsInPurchaseOrder } from '../../../../../selectors/govApp';
import { getPurchaseOrderUrls } from '../../helpers';

export const PurchaseOrderPanelBody = ({ requisition: { closedAt, purchaseOrderObject } }) => {
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    if (!endsInPurchaseOrder || !purchaseOrderObject?.length) {
        return (
            <>
                <Typography
                    alignItems="center"
                    color={capitalDesignTokens.foundations.colors.pear700}
                    display="flex"
                    gap={1}
                    variant="h3"
                >
                    <CheckCircle fontSize="24px" />
                    <span>Fully Approved</span>
                </Typography>
                <CreatedDate date={closedAt} label="Approved On" />
            </>
        );
    }

    const purchaseOrders = getPurchaseOrderUrls(purchaseOrderObject);

    const createdAt = purchaseOrderObject[0].date_created || '';

    return (
        <>
            {purchaseOrders.map((purchaseOrder, index) => (
                <PurchaseOrder
                    indexNumber={index + 1}
                    key={purchaseOrder.number}
                    purchaseOrder={purchaseOrder}
                />
            ))}
            <CreatedDate date={createdAt} label="PO Created" />
        </>
    );
};

PurchaseOrderPanelBody.propTypes = {
    requisition: PropTypes.shape({
        closedAt: PropTypes.string.isRequired,
        purchaseOrderObject: PropTypes.arrayOf(
            PropTypes.shape({
                po_number: PropTypes.number.isRequired,
                date_created: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
};
