import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
    getLoadError,
    getLoading,
    getNavItems,
    getSelectedNavItem,
    getSelectedTemplateQuestions,
} from './selectors';
import { TemplateQuestionCreateButton } from './TemplateQuestionCreateButton';
import { TemplateQuestionsList as TemplateQuestionsListComponent } from './TemplateQuestionsList';
import { getTemplatesAdminPath } from '../selectors';
import connectData from '../../../ConnectData';
import {
    loadTemplateQuestionsList,
    resetTemplateQuestionsList,
} from '../../../../actions/templateQuestions';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    TemplateListNav,
} from '../../../../components';
import { getTemplateQuestions } from '../../../../selectors/govApp';
import { useQueryParam } from '../../../../hooks';

function fetchData(getState, dispatch) {
    return dispatch(loadTemplateQuestionsList());
}

// @connectData
const ConnectedTemplateQuestionsList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const location = { ...baseLocation, query };

    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const loadError = useSelector(getLoadError);
    const templatesAdminPath = useSelector((state) =>
        getTemplatesAdminPath(state, { location, params })
    );
    const allTemplateQuestions = useSelector(getTemplateQuestions);
    const navItems = useSelector((state) => getNavItems(state, { location, params }));
    const selectedNavItem = useSelector((state) => getSelectedNavItem(state, { location, params }));
    const selectedTemplateQuestions = useSelector((state) =>
        getSelectedTemplateQuestions(state, { location, params })
    );

    const [showModal, setShowModal] = React.useState(false);
    const [templateQuestionId, setTemplateQuestionId] = React.useState(null);
    const [, setShowQuestionQueryParam] = useQueryParam('showQuestion');

    useEffect(() => {
        return () => dispatch(resetTemplateQuestionsList());
    }, []);

    useEffect(() => {
        const questionId = query.showQuestion;
        if (questionId && !loading && !loadError) {
            setTemplateQuestionId(Number(questionId));
            setShowModal(true);
            setShowQuestionQueryParam(undefined);
        }
    }, [query.showQuestion, loading, loadError]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    const hasNoCreatedQuestions = allTemplateQuestions.length === 0;

    return (
        <Main>
            <PageTitle title="Shared Questions" />
            <TemplateListNav
                TemplateCreateButton={TemplateQuestionCreateButton}
                location={location}
                navItems={navItems}
                selectedNavItem={selectedNavItem}
                title="Shared Questions"
            />
            <TemplateQuestionsListComponent
                TemplateCreateButton={TemplateQuestionCreateButton}
                hasNoCreatedQuestions={hasNoCreatedQuestions}
                isModalOpen={showModal}
                selectedQuestionId={templateQuestionId}
                setTemplateQuestionId={setTemplateQuestionId}
                showModal={setShowModal}
                templateQuestions={selectedTemplateQuestions}
                templatesAdminPath={templatesAdminPath}
            />
        </Main>
    );
};

export const TemplateQuestionsList = connectData(fetchData)(ConnectedTemplateQuestionsList);
