import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { ProjectCreateV2FunctionsContext } from '../../context';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { SolicitationsAttachments } from './Solicitations';
import { IntakeAttachments } from './Intake';
import { ContractDocumentAttachments } from './ContractDocument';

export const ProjectCreateV2Attachments = () => {
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const { buildRouteFunction, initiateSneakyUpdate, project, showContractCompiler } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const styles = require('./index.scss');

    const showingSignaturesTab =
        project.type === projectTypesDict.CONTRACT &&
        project.signatures.filter((s) => !s.isHiddenByLogic).length > 0;
    const isExhibit = project.type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled;
    const title = isExhibit ? 'Exhibits' : 'Attachments';

    return (
        <Box
            className={styles.container}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Fill available height
                flex: 1, // Take up remaining space in parent container
            }}
        >
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description={
                        project.type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled
                            ? 'Upload and manage all documents that should be included in this contract packet. In addition, include any related internal files here, although they are best included in the document tab of the contract record.'
                            : 'Manage all documents that should be included with this project.'
                    }
                    title={title}
                />
            </Box>
            <Box className={styles.content}>
                <Box
                    sx={{
                        py: 4,
                        px: 3,
                        flex: 1, // This will make the content area expand
                        overflow: 'auto', // In case content overflows
                    }}
                >
                    {project.type === projectTypesDict.PURCHASE && !project.isIntake && (
                        <SolicitationsAttachments />
                    )}
                    {project.isIntake && <IntakeAttachments />}
                    {project.type === projectTypesDict.CONTRACT && <ContractDocumentAttachments />}
                </Box>
            </Box>
            <Box
                className={styles.footer}
                sx={{
                    mt: 'auto', // This ensures the box sticks to the bottom
                }}
            >
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() =>
                                initiateSneakyUpdate(
                                    buildRouteFunction(
                                        project.useExternalDocument
                                            ? 'upload-document'
                                            : 'document-editor'
                                    )
                                )
                            }
                        >
                            {project.useExternalDocument ? 'Upload Document' : 'Document Editor'}
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        showingSignaturesTab ? (
                            <BaseSectionsNavNextButton
                                onClick={() =>
                                    initiateSneakyUpdate(buildRouteFunction('signatures'))
                                }
                            >
                                Signatures
                            </BaseSectionsNavNextButton>
                        ) : (
                            <BaseSectionsNavNextButton
                                onClick={() =>
                                    initiateSneakyUpdate(buildRouteFunction('review-checklist'))
                                }
                            >
                                {`${showContractCompiler ? '' : 'Final '}Review Checklist`}
                            </BaseSectionsNavNextButton>
                        )
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
