import React from 'react';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { Box } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, getFormSyncErrors, FieldArray } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';
import { v4 as UUIDv4 } from 'uuid';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import { CDSDropdownButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { Button } from '../../Button';
import { MenuItem } from '../../MenuItem/MenuItem';
import { QuestionLogicIcon } from '../../../containers/GovApp/TemplateAdmin/components/QuestionLogicIcon';

import {
    blockFieldNames,
    defaultBlock,
    defaultSignatureHeaderBlock,
    fieldNames,
} from './constants';
import { SignatureField } from './Field';
import { SignatureHeader } from './Header';

const { SIGNATURE_BLOCK: SIGNATURE_BLOCK_LINKABLE } = questionLogicLinkableModelNames;
const { SIGNATURES_BLOCKS } = fieldNames;
const { FIELDS, PAGE_BREAK_AFTER, STYLING, SIGNING_ORDER } = blockFieldNames;

const FieldsMap = ({
    countOffset = 0,
    fields,
    form,
    disabled,
    enableQuestionLogics,
    projectSection,
    questionLogicsConfig = null,
    showValidation,
    move,
}) => {
    const errors = useSelector((state) => get(getFormSyncErrors(form)(state), fields.name));
    const copyBlock = (index) => {
        const values = pick(fields.get(index), [FIELDS, PAGE_BREAK_AFTER, STYLING, SIGNING_ORDER]);

        fields.push(values);
    };

    const notHiddenFieldsIndexes = fields
        .map((field, i) => {
            return fields.get(i).isHiddenByLogic ? null : i;
        })
        .filter((i) => i !== null);

    return fields.map((field, index) => {
        const block = fields.get(index);

        if (block.isHiddenByLogic) {
            return null;
        }

        return (
            <Draggable draggableId={field} index={index} key={field}>
                {(draggableProvided) => {
                    const { styling } = block;
                    // The number of a signature block is determined by the position within the column
                    // not counting signature headers + the offset from the numbers already in the other column
                    // (only for the right column since the left column is the one that starts at 1)
                    const number =
                        countOffset +
                        1 +
                        fields
                            .map((f, i) => {
                                const hidden = fields.get(i).isHiddenByLogic;
                                const fStyling = fields.get(i).styling;

                                return i < index && !hidden ? fStyling : null;
                            })
                            .filter((s) => s === 'default').length;
                    const canMoveUp = notHiddenFieldsIndexes.filter((i) => i < index).length > 0;
                    const canMoveDown = notHiddenFieldsIndexes.filter((i) => i > index).length > 0;

                    return (
                        <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            <ContentBlock error={get(errors, `${index}._error`)} withActions>
                                <ContentBlock.Main disabled={disabled}>
                                    {styling === 'default' ? (
                                        <FieldArray
                                            aria-label={`Signature Block ${number}`}
                                            blockFieldName={field}
                                            component={SignatureField}
                                            disabled={disabled}
                                            form={form}
                                            name={`${field}.${FIELDS}`}
                                            number={number}
                                            showValidation={showValidation}
                                        />
                                    ) : (
                                        <SignatureHeader
                                            disabled={disabled}
                                            name={`${field}.${FIELDS}`}
                                            showValidation={showValidation}
                                        />
                                    )}
                                </ContentBlock.Main>
                                <ContentBlock.ActionSidebar>
                                    <ContentBlock.ButtonGroup>
                                        {canMoveUp && (
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => move(index, index - 1)}
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-arrow-up"
                                                    />
                                                    <span className="visually-hidden">Move up</span>
                                                </Button>
                                            </ContentBlock.Button>
                                        )}
                                        <ContentBlock.Button>
                                            <DragIcon
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                            />
                                            <span className="visually-hidden">Drag</span>
                                        </ContentBlock.Button>
                                        {canMoveDown && (
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => move(index, index + 1)}
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-arrow-down"
                                                    />
                                                    <span className="visually-hidden">
                                                        Move down
                                                    </span>
                                                </Button>
                                            </ContentBlock.Button>
                                        )}
                                    </ContentBlock.ButtonGroup>
                                    {enableQuestionLogics && (
                                        <ContentBlock.ButtonGroup>
                                            <ContentBlock.Button>
                                                <QuestionLogicIcon
                                                    linkable={SIGNATURE_BLOCK_LINKABLE}
                                                    linkableItem={block}
                                                    projectSection={projectSection}
                                                    {...questionLogicsConfig}
                                                />
                                                <span className="visually-hidden">
                                                    Question Logic
                                                </span>
                                            </ContentBlock.Button>
                                        </ContentBlock.ButtonGroup>
                                    )}
                                    <ContentBlock.ButtonGroup>
                                        <ContentBlock.Button>
                                            <Button bsStyle="link" onClick={() => copyBlock(index)}>
                                                <i aria-hidden="true" className="fa fa-copy" />
                                                <span className="visually-hidden">Copy</span>
                                            </Button>
                                        </ContentBlock.Button>
                                    </ContentBlock.ButtonGroup>
                                    <ContentBlock.ButtonGroup>
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => fields.remove(index)}
                                            >
                                                <i aria-hidden="true" className="fa fa-trash" />
                                                <span className="visually-hidden">Trash</span>
                                            </Button>
                                        </ContentBlock.Button>
                                    </ContentBlock.ButtonGroup>
                                </ContentBlock.ActionSidebar>
                            </ContentBlock>
                        </Box>
                    );
                }}
            </Draggable>
        );
    });
};
FieldsMap.propTypes = {
    countOffset: PropTypes.number,
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    move: PropTypes.func.isRequired,
    projectSection: PropTypes.object,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
};

export const SignatureBlock = ({
    countOffset = 0,
    disabled,
    enableQuestionLogics,
    fields,
    form,
    projectSection,
    questionLogicsConfig = null,
    showValidation,
}) => {
    const dispatch = useDispatch();

    const move = (originLocation, newLocation) => {
        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        move(originLocation, newLocation);
    };

    return (
        <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockList"
                    isDropDisabled={disabled}
                    type={SIGNATURES_BLOCKS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap
                                fields={fields}
                                move={move}
                                {...{
                                    countOffset,
                                    disabled,
                                    enableQuestionLogics,
                                    questionLogicsConfig,
                                    showValidation,
                                    projectSection,
                                    form,
                                }}
                            />

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box pb={2}>
                <CDSDropdownButton
                    aria-label="Add Signature Block"
                    disabled={disabled}
                    qaTag="signatureForm-addSignatureBlockMain"
                    size="small"
                    title={
                        <>
                            <i className="fa fa-plus" /> Add Signature Block
                        </>
                    }
                    variant="secondary"
                >
                    <MenuItem
                        onClick={() => fields.push({ ...defaultBlock, sharedId: UUIDv4() })}
                        qaTag="signatureForm-addBlock"
                    >
                        Signature Block
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            fields.push({ ...defaultSignatureHeaderBlock, sharedId: UUIDv4() })
                        }
                        qaTag="timelineForm-addHeader"
                    >
                        Signature Header
                    </MenuItem>
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

SignatureBlock.propTypes = {
    countOffset: PropTypes.number,
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    projectSection: PropTypes.object,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
};
