const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

// Map month names to numbers
const monthNumbers = monthNames.reduce((obj, name, index) => {
    obj[name.toLowerCase()] = index;
    return obj;
}, {});

const getMonthNumber = (monthName) => monthNumbers[monthName.toLowerCase()];

const getCalendarYear = (period, month, year) =>
    period.fiscal_period_code > month ? year : year - 1;

exports.getPeriodBounds = (periods) => {
    if (!periods) {
        throw new Error('Periods must be defined to get period bounds');
    }

    // Convert each period to a Date
    const dates = periods.map((period) => {
        const month = getMonthNumber(period.calendar_month);
        const year = Number(period.year);

        const calendarYear = getCalendarYear(period, month, year);

        return new Date(calendarYear, month, 1); // Dates in JavaScript are 0-indexed, so we no need to subtract 1 from the month
    });

    // Find min and max dates
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(0); // This makes the date to be set to the last day of the previous month

    return { start: minDate, end: maxDate };
};

exports.getFiscalPeriodsWithCalendarYear = (periods) => {
    if (!periods) {
        return [];
    }

    return periods.map((period) => {
        const month = getMonthNumber(period.calendar_month);
        const year = Number(period.year);

        const calendarYear = getCalendarYear(period, month, year);
        const description = period.description || `Fiscal Year ${calendarYear}`;

        const date = new Date(calendarYear, month, 1);

        return {
            ...period,
            calendarYear,
            description,
            date,
        };
    });
};
