import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import { loadEmailAudit, loadEmailAudits } from '../../../../../actions/contracts';
import { VendorEmailAuditsTable } from '../../../../../components/vendors';
import { getContractJS } from '../../../../selectors';

export const ContractEmailLog = () => {
    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);
    const vendorEmailAudits = useSelector((state) => {
        const vendorEmailAuditData = state.contracts.get('vendorEmailAuditData');

        if (vendorEmailAuditData) {
            return vendorEmailAuditData.get('emailAudits').toJS();
        }

        return [];
    });

    const loadEmailAuditHandler = useCallback(
        (emailUuid) => {
            return dispatch(loadEmailAudit(contract.id, emailUuid));
        },
        [contract.id, dispatch]
    );

    const loadEmailAuditsHandler = useCallback(() => {
        return dispatch(loadEmailAudits(contract.id));
    }, [contract.id, dispatch]);

    return (
        <Box>
            <Typography variant="h2">Email Activity Log</Typography>
            <VendorEmailAuditsTable
                emailAudits={vendorEmailAudits}
                hideTitle
                loadEmailAudit={loadEmailAuditHandler}
                loadEmailAudits={loadEmailAuditsHandler}
                loaded={false}
            />
        </Box>
    );
};
