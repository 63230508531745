import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { ProjectContentDisplay } from './ProjectContentDisplay';
import { ProjectContentForm } from './ProjectContentForm';
import { PROJECT_SECTION_ERRORS } from '../../constants';
import { OGThemeConsumer } from '../../../../../ogThemeProvider';

const { DIVIDER } = sectionTypeNames;

export class ProjectContentSection extends PureComponent {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func.isRequired,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string,
        formKey: PropTypes.string,
        isIntake: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        showForm: PropTypes.bool.isRequired,
        showFormHandler: PropTypes.func.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get isDivider() {
        const {
            projectSection: { section_type: sectionType },
            useSectionDividers,
        } = this.props;

        return useSectionDividers && sectionType === DIVIDER;
    }

    toggleFormHandler = () => {
        const { projectSection, showFormHandler } = this.props;

        // Dividers have no editable content
        if (this.isDivider) {
            return null;
        }

        showFormHandler(projectSection.id);
    };

    render() {
        const {
            addConditionalLogicHandler,
            array,
            change,
            disabled,
            form,
            formKey,
            isIntake,
            isReverseAuction,
            projectId,
            projectSection,
            showForm,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        return (
            <OGThemeConsumer>
                {({ isOGThemeEnabledForComponents }) => (
                    <div
                        className={classnames({
                            'list-group-item': !isOGThemeEnabledForComponents,
                            [this.styles.listItem]: !showForm && !this.isDivider,
                        })}
                        disabled={disabled}
                        onClick={showForm ? undefined : this.toggleFormHandler}
                    >
                        {showForm ? (
                            <ProjectContentForm
                                addConditionalLogicHandler={addConditionalLogicHandler}
                                array={array}
                                change={change}
                                disabled={disabled}
                                form={form}
                                formKey={formKey}
                                isIntake={isIntake}
                                isReverseAuction={isReverseAuction}
                                projectId={projectId}
                                projectSection={projectSection}
                                showFormValidation={showFormValidation}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                toggleFormHandler={this.toggleFormHandler}
                                useManualNumbering={useManualNumbering}
                            />
                        ) : (
                            <Field
                                component={ProjectContentDisplay}
                                isDivider={this.isDivider}
                                name={`${PROJECT_SECTION_ERRORS}.${projectSection.id}`}
                                projectSection={projectSection}
                                showFormValidation={showFormValidation}
                                useManualNumbering={useManualNumbering}
                                useSectionDividers={useSectionDividers}
                            />
                        )}
                    </div>
                )}
            </OGThemeConsumer>
        );
    }
}
