import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { InternalAttachments } from '../../components/Attachments/Internal';
import { SupportingAttachments } from '../../components/Attachments/Supporting';
import { MainAttachments } from '../../components/Attachments/Main';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const SolicitationsAttachments = () => {
    const { disabled, form, initiateSneakyUpdate, project } = useContext(
        ProjectCreateV2FunctionsContext
    );

    return (
        <Box>
            {project.template.omitDocx && (
                <Box sx={{ mb: 3 }}>
                    <MainAttachments
                        allowedReassignmentTypes={[
                            attachmentTypesDict.OTHER,
                            attachmentTypesDict.INTERNAL,
                        ]}
                        disabled={disabled}
                        form={form}
                        project={project}
                    />
                </Box>
            )}
            <Box sx={{ mb: 3 }}>
                <SupportingAttachments
                    allowedReassignmentTypes={[
                        attachmentTypesDict.PROJECT_DOCUMENT,
                        attachmentTypesDict.INTERNAL,
                    ]}
                    disabled={disabled}
                    form={form}
                    project={project}
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
            <Box sx={{ mb: 3 }}>
                <InternalAttachments
                    allowedReassignmentTypes={[
                        attachmentTypesDict.PROJECT_DOCUMENT,
                        attachmentTypesDict.OTHER,
                    ]}
                    disabled={disabled}
                    form={form}
                    project={project}
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
        </Box>
    );
};
