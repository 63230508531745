import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reset } from 'redux-form';

import { ContractMilestones } from './ContractMilestones';
import { ContractSubscribers } from './ContractSubscribers';
import { getContractMilestonesJS } from './selectors';
import { isContractEditor } from '../selectors';
import connectData from '../../ConnectData';
import { getContractJS, getUserOrganizationTimezone, isContractAdminUser } from '../../selectors';
import {
    loadContractNotifications,
    updateContractMilestoneNotifications,
} from '../../../actions/contracts';
import { LoadingError, LoadingSpinner, Main, SectionTitle } from '../../../components';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    return dispatch(loadContractNotifications(contractId));
}

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        contractMilestones: getContractMilestonesJS(state),
        isEditor: isContractEditor(state),
        isContractAdmin: isContractAdminUser(state),
        loadError: state.contracts.get('loadContractNotificationsError'),
        loading: state.contracts.get('loadingContractNotifications'),
        timezone: getUserOrganizationTimezone(state),
    };
};

const mapDispatchToProps = {
    resetForm: reset,
    updateNotifications: updateContractMilestoneNotifications,
};

// @connectData
// @connect
class ConnectedContractNotifications extends Component {
    static propTypes = {
        contract: PropTypes.object.isRequired,
        contractMilestones: PropTypes.array.isRequired,
        isContractAdmin: PropTypes.bool,
        isEditor: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        resetForm: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        updateNotifications: PropTypes.func.isRequired,
    };

    render() {
        const {
            contract,
            contractMilestones,
            isContractAdmin,
            isEditor,
            loadError,
            loading,
            resetForm,
            timezone,
            updateNotifications,
        } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        // Sort the milestones by date, but put the start date first, then the annual evaluation, then the end date
        const order = ['Start Date', 'Annual Evaluation', 'End Date'];

        contractMilestones.sort((a, b) => {
            const indexA = order.indexOf(a.name);
            const indexB = order.indexOf(b.name);

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });

        return (
            <Main className="row">
                <div className="col-xs-12">
                    <SectionTitle
                        info="Set notifications for milestone dates"
                        title="Notifications"
                    />
                </div>
                <div className="col-sm-6">
                    <ContractMilestones
                        contract={contract}
                        contractMilestones={contractMilestones}
                        isEditor={isEditor}
                        reset={resetForm}
                        timezone={timezone}
                        updateContractMilestoneNotifications={updateNotifications}
                    />
                </div>
                <div className="col-sm-6">
                    <ContractSubscribers
                        contract={contract}
                        isContractAdmin={isContractAdmin}
                        isEditor={isEditor}
                        resetForm={resetForm}
                    />
                </div>
            </Main>
        );
    }
}

export const ContractNotifications = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractNotifications);
