import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { ProjectAttachmentBlock } from '../../../../../../components/forms/ProjectAttachmentBlock';
import { useReassignment } from './useReassignment';

const { colors } = capitalDesignTokens.foundations;

const { PROJECT_DOCUMENT } = attachmentTypesDict;

export const MainAttachments = ({
    allowedReassignmentTypes = [],
    disabled,
    form,
    project,
    triggerUpdate,
}) => {
    const reassigmentType = useReassignment({
        allowedReassignmentTypes,
        project,
    });

    return (
        <Box>
            <Box
                sx={{ backgroundColor: 'white', border: `1px solid ${colors.gray200}`, padding: 3 }}
            >
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h3">Main Documents</Typography>
                    <Typography color="text.secondary">
                        Add the primary project documents below.
                    </Typography>
                </Box>
                <ProjectAttachmentBlock
                    allowedReassignmentTypes={reassigmentType}
                    attachmentListLabel="Uploaded Documents"
                    disabled={disabled}
                    emptyListSubtitle="Add a document above to see it here"
                    emptyListTitle="No Documents"
                    form={form}
                    formKey={PROJECT_DOCUMENT}
                    hideAppendixLetter
                    hideNoAttachments
                    isOGThemeEnabledForComponents
                    label="Project Documents"
                    listAttachmentType={PROJECT_DOCUMENT}
                    projectId={project.id}
                    triggerUpdate={triggerUpdate}
                    uploadAttachmentType={PROJECT_DOCUMENT}
                />
            </Box>
        </Box>
    );
};

MainAttachments.propTypes = {
    allowedReassignmentTypes: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    form: PropTypes.object,
    project: PropTypes.shape({
        id: PropTypes.string,
    }),
    triggerUpdate: PropTypes.func,
};
