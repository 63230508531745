import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { sortItemsByManualNumber } from './manualNumbering';
import { itemsToHierarchy } from '../components/SDv2/helpers';
import { isPurchase } from './project';

/**
 * Sorts a project / template sections taking into account the different
 * variations
 *
 * @param {object[]} sections Array of project / template sections
 * @param {boolean} useManualNumbering
 * @param {boolean} useSectionDividers
 * @returns {object[]}
 */
export const sortSections = ({ sections, useManualNumbering, useSectionDividers }) => {
    if (!useManualNumbering) {
        return sections.map((section, index) => ({
            ...section,
            orderById: index + 1,
        }));
    }

    const parentSectionsToSort = useSectionDividers ? itemsToHierarchy(sections) : sections;

    const sortedSections = sortItemsByManualNumber(parentSectionsToSort);

    if (!useSectionDividers) {
        return sortedSections.map((section, idx) => ({
            ...section,
            orderById: idx + 1,
        }));
    }

    const sortedParentAndChildSections = sortedSections.map((parent) => ({
        ...parent,
        children: sortItemsByManualNumber(parent.children || []),
    }));

    const flattenedSortedSections = sortedParentAndChildSections
        .reduce((allSections, section) => {
            const { children, ...updatedSection } = section;
            return [...allSections, { ...updatedSection }, ...children];
        }, [])
        .map((section, idx) => ({
            ...section,
            orderById: idx + 1,
        }));

    return flattenedSortedSections;
};

export const moveArrayElement = (array, originIndex, destinationIndex) => {
    if (array.length < originIndex + 1) {
        return array;
    }

    const movedRecord = array[originIndex];

    const filtered = array.filter((item, index) => index !== originIndex);

    const swapped = [
        ...filtered.slice(0, destinationIndex),
        movedRecord,
        ...filtered.slice(destinationIndex),
    ];

    return swapped;
};

/**
 * Returns whether or not there is an introduction section
 *
 * @param {string} type Type of project
 * @param {array<object>} sections  Array of sections
 * @param {boolean} override Override the value, used when we have a feature flag enabled
 * @returns {boolean}
 */
export const isPurchaseWithoutIntroduction = (type, sections = [], override = false) => {
    if (!isPurchase(type)) {
        return false;
    }

    if (!sections.length) {
        return true;
    }

    const section = sections.find((s) => s.section_type === sectionTypeNames.INTRODUCTION);

    return !section || section?.isHidden || override;
};
