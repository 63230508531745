import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContractSubscribersForm } from './ContractSubscribersForm';
import { form } from './ContractSubscribersForm/constants';
import { ContractSubscriber } from './ContractSubscriber';
import { getUsersSelectOptions } from './selectors';
import { getUserJS } from '../../../selectors';
import { Well } from '../../../../components';
import { addContractSubscribers, removeContractSubscriber } from '../../../../actions/contracts';

export const ContractSubscribers = ({ contract, resetForm, isContractAdmin, isEditor }) => {
    const dispatch = useDispatch();
    const addingSubscribers = useSelector((state) => state.contracts.get('addingSubscribers'));
    const addSubscriberErrors = useSelector((state) => state.contracts.get('addSubscribersError'));
    const user = useSelector(getUserJS);
    const usersList = useSelector(getUsersSelectOptions);

    const deleteHandler = useCallback(
        (contractSubscriberId) => {
            dispatch(removeContractSubscriber(contract.id, contractSubscriberId));
        },
        [contract, dispatch]
    );

    const handleSubmit = useCallback(
        (data) => {
            if (!data || !data.users) {
                return;
            }

            dispatch(
                addContractSubscribers(
                    contract.id,
                    data.users.map((u) => u.value), // `value` is the `user.id`
                    () => resetForm(form)
                )
            );
        },
        [contract, dispatch, resetForm]
    );

    const { contractSubscribers } = contract;

    return (
        <Well>
            <label htmlFor="searchSelect">Who to Notify</label>
            <ContractSubscribersForm
                addError={addSubscriberErrors}
                disabled={addingSubscribers || (!isContractAdmin && !isEditor)}
                onSubmit={handleSubmit}
                usersList={usersList}
            />
            {contractSubscribers.length > 0 && (
                <ul className="list-unstyled">
                    {contractSubscribers.map((subscriber) => (
                        <ContractSubscriber
                            contractSubscriber={subscriber}
                            isEditor={isEditor || isContractAdmin}
                            key={subscriber.id}
                            onDelete={deleteHandler}
                            user={user}
                        />
                    ))}
                </ul>
            )}
            <div className="text-muted text-center">
                Add any users that should receive notifications.
                <br />
                Users may remove themselves from notifications and subscribe others.
            </div>
        </Well>
    );
};

ContractSubscribers.propTypes = {
    contract: PropTypes.shape({
        contractSubscribers: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    isContractAdmin: PropTypes.bool,
    isEditor: PropTypes.bool,
    resetForm: PropTypes.func.isRequired,
};
