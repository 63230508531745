import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { RetentionActions } from '../RetentionActions';
import { AgGridReactLegacy } from '../../../../components/AgGridReactLegacy';
import { NoProjects } from '../../../../components/GovApp';
import { Alert, Main, PageTitle } from '../../../../components';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export const RecordTable = ({
    columnDef,
    disableApplyPolicies,
    errors,
    filter,
    handleApplyPolicies,
    handleCellValueChanged,
    handleSelectPolicy,
    loading,
    location,
    policies,
    policyWarning,
    rows,
    selectedRows,
    setSelectedRows,
    setFilter,
    showApplyPolicies,
    showDelete,
    showPolicies,
    storeLocation,
    ...props
}) => {
    const styles = require('./index.scss');
    const [gridApi, setGridApi] = useState();
    const [showError, setShowError] = useState();
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        if (errors && errors.length > 0) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [errors]);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [height, width]);

    const isEmpty = rows.length === 0;

    const handleGridReady = (params) => {
        setGridApi(params.api);

        params.api.sizeColumnsToFit();
    };

    const handleSelectionChanged = () => {
        if (gridApi) {
            setSelectedRows(gridApi.getSelectedRows());
        }
    };

    const renderError = () => {
        return (
            <Alert bsStyle="danger" className={styles.alert} onDismiss={() => setShowError(false)}>
                <div className={styles.icon}>
                    <i className="fa fa-2x fa-warning" />
                </div>
                <div className={styles.errorText}>
                    <strong>Something went wrong.</strong>
                    <div>The policies below were unable to be applied. Please try again later.</div>
                </div>
            </Alert>
        );
    };

    const getRowNodeId = (data) => {
        return data.id;
    };

    return (
        <Main>
            <PageTitle title="Records Retention Administration" />
            <span className={styles.policyWarning}>{policyWarning}</span>
            {showError && renderError()}
            <div>
                <RetentionActions
                    disableApplyPolicies={disableApplyPolicies}
                    filter={filter}
                    handleApplyPolicies={handleApplyPolicies}
                    handleSelectPolicy={handleSelectPolicy}
                    loading={loading}
                    location={location}
                    policies={policies}
                    selectedRows={selectedRows}
                    setFilter={setFilter}
                    showApplyPolicies={showApplyPolicies}
                    showDelete={showDelete}
                    showPolicies={showPolicies}
                    storeLocation={storeLocation}
                />
                {!isEmpty && (
                    <AgGridReactLegacy
                        columns={columnDef}
                        domLayout="autoHeight"
                        getRowNodeId={getRowNodeId}
                        headerHeight={40}
                        hideSideBar
                        onCellValueChanged={handleCellValueChanged}
                        onGridReady={handleGridReady}
                        onSelectionChanged={handleSelectionChanged}
                        pagination
                        paginationPageSize={100}
                        rowHeight={120}
                        rows={rows}
                        suppressContextMenu
                        {...props}
                    />
                )}
                {isEmpty && (
                    <>
                        <hr />
                        <NoProjects filtered isDocBuilder isIntake />
                    </>
                )}
            </div>
        </Main>
    );
};

RecordTable.propTypes = {
    columnDef: PropTypes.array.isRequired,
    disableApplyPolicies: PropTypes.bool,
    errors: PropTypes.array,
    filter: PropTypes.string,
    handleApplyPolicies: PropTypes.func,
    handleCellValueChanged: PropTypes.func,
    handleSelectPolicy: PropTypes.func,
    loading: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    policies: PropTypes.array,
    policyWarning: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    showApplyPolicies: PropTypes.bool,
    showDelete: PropTypes.bool,
    showPolicies: PropTypes.bool,
    storeLocation: PropTypes.string,
};
