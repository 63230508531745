import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { compose } from 'redux';

import { calculatePriceItemTotalCost } from '@og-pro/shared-config/priceTables/utils';

import {
    getBidTabulationsJS,
    getBidTabulationProposalsDataJS,
    getIsViewOnly,
    getSelectedBidTabulationPriceItemIdsJS,
} from './selectors';
import { getProjectJS, isEvaluationEditor } from '../../selectors';
import connectData from '../../../ConnectData';
import { menuActionHandler, showInstructionsModal } from '../../../../actions/govProjects';
import {
    loadBidTabulations,
    resetBidTabulations,
    setSelectedBidTabulationPriceItems,
    showProposalSelect,
} from '../../../../actions/proposalEvaluations';
import { updateEvaluation } from '../../../../actions/evaluations';
import {
    BidTabulationTable,
    Button,
    LoadingError,
    LoadingSpinner,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { VendorBidTabulationTotalsTable } from '../../../../components/vendors';
import { ReportsModalButton } from '../../ReportsModal/ReportsModalButton';
import { UNSEAL_BIDS } from '../../../../constants/menuActions';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadBidTabulations(projectId));
}

const mapStateToProps = (state) => {
    return {
        bidTabulations: getBidTabulationsJS(state),
        error: state.proposalEvaluations.get('loadBidTabulationsError'),
        isEditor: isEvaluationEditor(state),
        isPricingSealed: state.proposalEvaluations.get('bidTabulationsPricingSealed'),
        isViewOnly: getIsViewOnly(state),
        loading: state.proposalEvaluations.get('loadingBidTabulations'),
        project: getProjectJS(state),
        proposalsData: getBidTabulationProposalsDataJS(state),
        selectedBidTabulationPriceItemIds: getSelectedBidTabulationPriceItemIdsJS(state),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
    resetBidTabulations,
    setSelectedBidTabulationPriceItems,
    showInstructionsModal,
    showProposalSelect,
    updateEvaluation,
};

// @connectData
// @connect
class ConnectedBidTabulations extends Component {
    static propTypes = {
        bidTabulations: PropTypes.array,
        error: PropTypes.string,
        isEditor: PropTypes.bool,
        isPricingSealed: PropTypes.bool.isRequired,
        isViewOnly: PropTypes.bool,
        loading: PropTypes.bool,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            evaluation: PropTypes.shape({
                bidTabulationSortAsc: PropTypes.bool.isRequired,
            }).isRequired,
            government: PropTypes.shape({
                salesTax: PropTypes.number,
            }).isRequired,
            id: PropTypes.number.isRequired,
            showBids: PropTypes.bool.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        proposalsData: PropTypes.arrayOf(
            PropTypes.shape({
                proposalId: PropTypes.number.isRequired,
                vendorName: PropTypes.string.isRequired,
            })
        ),
        resetBidTabulations: PropTypes.func.isRequired,
        selectedBidTabulationPriceItemIds: PropTypes.array,
        setSelectedBidTabulationPriceItems: PropTypes.func.isRequired,
        showInstructionsModal: PropTypes.func.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
        updateEvaluation: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            bidTabulationTablesData: {},
        };

        this.generatedChangeHandlersMap = {};

        this.debouncedSaveSelections = debounce(this.saveSelections, 750);

        this.stickyRef = React.createRef();
    }

    componentWillUnmount() {
        this.props.resetBidTabulations();
    }

    get styles() {
        return require('./index.scss');
    }

    generateSelectionChangeHandler = (bidTabulation, i) => {
        // We save the references to each table's change handler to prevent needless re-renders
        // each time this runs (this component is impure, but BidTabulationTable is pure).
        if (this.generatedChangeHandlersMap[i]) {
            return this.generatedChangeHandlersMap[i];
        }

        const percentageAdjustmentType = bidTabulation.priceTable.percentageAdjustmentType;

        // NOTE: this callback runs on a per-bid-tabulation table basis. Each table is its own
        // implementation of an ag-Grid, and so does not know about other tables on the page. Thus
        // changing the selection in one table only gives us the selected rows of that table, not
        // the selected rows of all tables.
        const handler = (rows, preventSave) => {
            const {
                project: {
                    government: { salesTax },
                },
                proposalsData,
            } = this.props;

            const selectedBidTabulationPriceItemIds = rows.map((row) => row.priceItemId);

            const zerosBidTabulationVendorTotalsMap = proposalsData.reduce((map, proposalData) => {
                map[proposalData.proposalId] = {
                    value: 0,
                    noBid: true, // defaulting to true allows us to change if any row isn't "no bid"
                };
                return map;
            }, {});

            // if there is nothing selected, don't return vendor totals
            const bidTabulationVendorTotalsMap =
                selectedBidTabulationPriceItemIds.length > 0
                    ? rows.reduce((map, row) => {
                          const { taxable } = row;
                          row.vendorResponses.forEach((vendorResponse, index) => {
                              const currentProposalId = proposalsData[index].proposalId;
                              const total = calculatePriceItemTotalCost(
                                  {
                                      vendorResponse,
                                  },
                                  percentageAdjustmentType
                              );
                              const totalWithTaxes = taxable
                                  ? total * (salesTax / 100) + total
                                  : total;

                              map[currentProposalId] = {
                                  value: map[currentProposalId].value + totalWithTaxes,
                                  noBid: !vendorResponse.noBid
                                      ? false
                                      : map[currentProposalId].noBid, // ends up being false if any proposal is not "no bid"
                              };
                          });
                          return map;
                      }, zerosBidTabulationVendorTotalsMap)
                    : [];

            this.setState(
                (state) => {
                    const bidTabulationTablesData = {
                        ...state.bidTabulationTablesData,
                        [i]: {
                            selectedPriceItemIds: selectedBidTabulationPriceItemIds,
                            vendorTotals: bidTabulationVendorTotalsMap,
                        },
                    };

                    return { bidTabulationTablesData };
                },
                () => {
                    if (!preventSave) {
                        this.debouncedSaveSelections();
                    }
                }
            );
        };

        this.generatedChangeHandlersMap[i] = handler;
        return this.generatedChangeHandlersMap[i];
    };

    getTotalsByVendor = () => {
        const {
            project: {
                evaluation: { bidTabulationSortAsc },
            },
            proposalsData,
        } = this.props;

        const { bidTabulationTablesData } = this.state;

        const bidTabulationTableDataArray = Object.values(bidTabulationTablesData);

        const rowsSelected = bidTabulationTableDataArray.reduce((acc, cur) => {
            if (!cur.selectedPriceItemIds) {
                return acc;
            }
            return acc + cur.selectedPriceItemIds.length;
        }, 0);

        return proposalsData
            .map((proposalData) => {
                return {
                    city: proposalData.vendorCity,
                    name: proposalData.vendorName,
                    proposalId: proposalData.proposalId,
                    state: proposalData.vendorState,
                    total: bidTabulationTableDataArray.reduce(
                        (acc, bidTabulationTableData) => {
                            const vendorTotals =
                                bidTabulationTableData.vendorTotals[proposalData.proposalId];

                            // if no rows are selected, this would return noBid === true, so we force it to be false
                            if (rowsSelected === 0) {
                                return {
                                    value: 0,
                                    noBid: false,
                                };
                            }

                            if (!vendorTotals) {
                                return acc;
                            }

                            return {
                                value: acc.value + vendorTotals.value,
                                noBid: !vendorTotals.noBid ? false : acc.noBid, // ends up being false if any proposal is not "no bid"
                            };
                        },
                        {
                            value: 0,
                            noBid: true, // initialize to true to enable next step
                        }
                    ),
                };
            })
            .sort((vendor1, vendor2) => {
                // noBid should always go to the end
                if (vendor1.total.noBid) {
                    return 1;
                }

                if (vendor2.total.noBid) {
                    return -1;
                }

                if (
                    bidTabulationSortAsc
                        ? vendor1.total.value > vendor2.total.value
                        : vendor1.total.value < vendor2.total.value
                ) {
                    return 1;
                }

                if (
                    bidTabulationSortAsc
                        ? vendor1.total.value < vendor2.total.value
                        : vendor1.total.value > vendor2.total.value
                ) {
                    return -1;
                }

                return 0;
            });
    };

    handleCompleteEvaluation = () => {
        this.props.showProposalSelect();
    };

    handleShowInstructionsClick = () => {
        this.props.showInstructionsModal('bidTabulationHelp');
    };

    saveSelections = () => {
        const { project } = this.props;

        const { bidTabulationTablesData } = this.state;

        const bidTabulationTableDataData = Object.values(bidTabulationTablesData);

        const allSelectedPriceItemIds = bidTabulationTableDataData.reduce((priceItemsIds, data) => {
            return priceItemsIds.concat(data.selectedPriceItemIds);
        }, []);

        this.props.setSelectedBidTabulationPriceItems(project.id, allSelectedPriceItemIds);
    };

    toggleSortAscending = () => {
        const {
            project: {
                evaluation: { bidTabulationSortAsc },
                id: projectId,
            },
        } = this.props;

        return this.props.updateEvaluation(
            projectId,
            {
                evaluation: {
                    bidTabulationSortAsc: !bidTabulationSortAsc,
                },
            },
            { notify: true }
        );
    };

    renderBidTabulation = (bidTabulation, i) => {
        const {
            isViewOnly,
            project: {
                auctionMaxFractionDigits,
                evaluation: { bidTabulationSortAsc },
                government: { salesTax },
                template: { isReverseAuction },
            },
            proposalsData,
            selectedBidTabulationPriceItemIds,
        } = this.props;

        return (
            <BidTabulationTable
                auctionMaxFractionDigits={isReverseAuction ? auctionMaxFractionDigits : null}
                bidTabulation={bidTabulation}
                defaultTitle={`Table ${i + 1}`}
                initiallySelectedPriceItemIds={selectedBidTabulationPriceItemIds}
                isViewOnly={isViewOnly}
                key={i}
                onSelectionChange={this.generateSelectionChangeHandler(bidTabulation, i)}
                proposalsData={proposalsData}
                salesTax={salesTax}
                showCustomColumns
                sortAscending={bidTabulationSortAsc}
                stickyHeaderOffset={
                    this.stickyRef.current
                        ? this.stickyRef.current.outerElement.clientTop +
                          this.stickyRef.current.outerElement.clientHeight
                        : 0
                }
            />
        );
    };

    renderSealedBids() {
        const { isEditor, project } = this.props;

        const title = project.showBids ? 'Bid Pricing is Sealed' : 'Bids are Sealed';
        const buttonText = (
            <span>
                <i className="fa fa-envelope-open" /> Unseal {project.showBids ? 'Pricing' : 'Bids'}
            </span>
        );

        return (
            <ZeroState
                buttonClickHandler={() => this.props.menuActionHandler(UNSEAL_BIDS, project)}
                buttonProps={{
                    disabled: !isEditor,
                    tooltip: isEditor
                        ? undefined
                        : 'Only evaluation editors and admins can unseal bids',
                }}
                buttonText={buttonText}
                info="Click the button above to unseal the pricing information and start bid tabulation"
                title={title}
            />
        );
    }

    renderZeroState() {
        const { noProposals, noProposalsHeader } = this.styles;

        return (
            <div className={`row text-muted ${noProposals}`}>
                <div className={`col-sm-8 col-sm-offset-2 ${noProposalsHeader}`}>
                    No response have been submitted yet.
                    <br />
                    <br />
                    Once there are submitted responses they will appear on this page in bid
                    tabulation tables. You can manually add responses using the &quot;Responses
                    &quot; tab.
                </div>
            </div>
        );
    }

    render() {
        const {
            bidTabulations,
            error,
            isPricingSealed,
            isViewOnly,
            loading,
            project: {
                auctionMaxFractionDigits,
                evaluation: { bidTabulationSortAsc },
                template: { isReverseAuction },
            },
            proposalsData,
        } = this.props;

        // NOTE: there is a loading edge case where if we come to this page via a route change then
        // it's possible that we render before the redux action has been completed digested by our
        // store. This puts us in a state where all expected store values are `undefined`, and can
        // cause rendering bugs where children of this component attempt to set state but have
        // unmounted due to this component re-rendering when the redux action finally catches up.
        if ((loading || !bidTabulations) && !error) {
            return <LoadingSpinner />;
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        if (proposalsData.length === 0) {
            return this.renderZeroState();
        }

        if (isPricingSealed) {
            return this.renderSealedBids();
        }

        return (
            <>
                <SectionTitle
                    onHelpClick={this.handleShowInstructionsClick}
                    title="Bid Tabulations"
                />
                <div className="text-right">
                    <ReportsModalButton type="evaluationTabulationReport" />
                </div>
                <Sticky innerZ={100} ref={this.stickyRef}>
                    <VendorBidTabulationTotalsTable
                        auctionMaxFractionDigits={
                            isReverseAuction ? auctionMaxFractionDigits : null
                        }
                        sortAscending={bidTabulationSortAsc}
                        toggleSortAscending={this.toggleSortAscending}
                        vendors={this.getTotalsByVendor()}
                    />
                </Sticky>
                <div className={this.styles.bidTabulations}>
                    {bidTabulations.map(this.renderBidTabulation)}
                </div>
                {!isViewOnly && (
                    <div className={this.styles.completeEvaluationButtonWrapper}>
                        <Button
                            bsSize="lg"
                            bsStyle="success"
                            onClick={this.handleCompleteEvaluation}
                            qaTag="connectedBidTabulations-awardProject"
                        >
                            <i className="fa fa-trophy" /> Award Project
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

export const BidTabulations = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedBidTabulations);
