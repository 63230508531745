import { Box, Typography } from '@og-pro/ui';
import React from 'react';
import { useSelector } from 'react-redux';

import { useRequestTypeFilters } from './hooks';
import { RequestTypesMenu } from './RequestTypesMenu';
import { RequestTypesTable } from './RequestTypesTable';
import connectData from '../../../../ConnectData';
import { loadRequestTypes } from '../../../../../actions/requestTypes';
import { LoadingError, LoadingSpinner, Main, PageTitle } from '../../../../../components';
import { getRequestTypes } from '../../../../../selectors/govApp';

const fetchData = (getState, dispatch) => {
    return dispatch(loadRequestTypes());
};

const ConnectedRequestTypesList = () => {
    const requestTypes = useSelector(getRequestTypes);
    const isLoading = useSelector((state) => state.requestTypes.get('loadingList'));
    const loadingError = useSelector((state) => state.requestTypes.get('loadListError'));

    const { filteredRequestTypes, isFilteredView, setSearchValue, setShowArchived, showArchived } =
        useRequestTypeFilters({ requestTypes });

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadingError) {
        return <LoadingError error={loadingError} useOpenGovStyle />;
    }

    return (
        <Main>
            <PageTitle title="All Request Types" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography component="h1" variant="h2">
                    All Request Types
                </Typography>
                <RequestTypesMenu
                    setSearchValue={setSearchValue}
                    setShowArchived={setShowArchived}
                    showArchived={showArchived}
                />
                <RequestTypesTable
                    isFilteredView={isFilteredView}
                    requestTypes={filteredRequestTypes}
                />
            </Box>
        </Main>
    );
};

export const RequestTypesList = connectData(fetchData)(ConnectedRequestTypesList);
