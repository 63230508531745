import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { useImportSectionHook } from './importSectionHook';
import { validate } from './validate';
import { ProjectContentSection } from '../../../../forms/TemplateProjectContentForm/components/index';
import { ADD_SECTION_TEMP_FIELDNAME, ADD_SECTION_TEMP_FORM } from '../../../../forms/constants';

const addSectionFormSelector = formValueSelector(ADD_SECTION_TEMP_FORM);

const ConnectedImportSectionForm = ({
    array,
    change,
    form,
    projectSection,
    projectContentSectionProps,
}) => {
    // This hook is used to handle changes to the project section type by reshaping the form data
    // based on the new section type.
    useImportSectionHook({ projectSection, form, change });

    return (
        <ProjectContentSection
            {...projectContentSectionProps}
            array={array}
            change={change}
            form={form}
            formKey="items"
            projectSection={projectSection}
            showFormValidation
        />
    );
};

ConnectedImportSectionForm.propTypes = {
    array: PropTypes.array.isRequired,
    change: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    projectContentSectionProps: PropTypes.object.isRequired,
    projectSection: PropTypes.object.isRequired,
};

const ImportSectionReduxForm = reduxForm({ validate })(ConnectedImportSectionForm);

// This component serves purely as a wrapper for getting the project section and passing it into the
// reduxForm. This is necessary so that the reduxForm `validate` function has access to the
// `projectSection` via props. It is needed for validation logic.
export const ImportSectionForm = ({ projectSection, ...props }) => {
    const projectSectionTypeFromForm = useSelector((state) =>
        addSectionFormSelector(state, ADD_SECTION_TEMP_FIELDNAME)
    )?.section_type;

    // Use project section from form if it exists, otherwise use the project section from the props
    // This is necessary because the value can be changed from the form, but the prop does not get
    // updated. We want to use the value from the form.
    const projectSectionFromForm = useMemo(() => {
        if (projectSectionTypeFromForm) {
            return {
                ...projectSection,
                section_type: projectSectionTypeFromForm,
            };
        }
        return projectSection;
    }, [projectSection, projectSectionTypeFromForm]);

    return <ImportSectionReduxForm {...props} projectSection={projectSectionFromForm} />;
};

ImportSectionForm.propTypes = {
    projectSection: PropTypes.object.isRequired,
};
