import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@og-pro/ui';
import { Panel } from 'react-bootstrap';

import { isRequisitionDraft, statusTypes } from '@og-pro/shared-config/requisitions';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { DoDisturb } from '@mui/icons-material';

import { CreatedDate } from '../PurchaseOrderPanel/CreatedDate';

import { ResubmitActionPanel } from './ResubmitActionPanel';
import { ReviewerActionPanel } from './ReviewerActionPanel';
import { getRequisitionCurrentStep } from '../../../../../selectors/govApp';

export const ActionPanel = ({ disabled, requisition, reviewSequenceSteps }) => {
    const currentStep = useSelector(getRequisitionCurrentStep);
    const sharedStyles = require('../shared.scss');

    const sharedProps = {
        disabled,
        requisition,
    };

    if (isRequisitionDraft(requisition.status)) {
        return <ResubmitActionPanel {...sharedProps} />;
    }

    if (requisition.status === statusTypes.CLOSED_CANCELLED) {
        return (
            <Panel className={sharedStyles.panel}>
                <Panel.Body className={sharedStyles.panelContainer}>
                    <div className={sharedStyles.panelBodyContainer}>
                        <Typography
                            alignItems="center"
                            color={capitalDesignTokens.foundations.colors.ruby700}
                            display="flex"
                            gap={1}
                            variant="h3"
                        >
                            <DoDisturb fontSize="24px" />
                            <span>Cancelled</span>
                        </Typography>
                        <CreatedDate date={requisition.closedAt} label="Cancelled On" />
                    </div>
                </Panel.Body>
            </Panel>
        );
    }

    if (currentStep) {
        return (
            <ReviewerActionPanel
                {...sharedProps}
                currentStep={currentStep}
                reviewSequenceSteps={reviewSequenceSteps}
            />
        );
    }

    return null;
};

ActionPanel.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        status: PropTypes.number.isRequired,
        closedAt: PropTypes.string.isRequired,
    }).isRequired,
    reviewSequenceSteps: PropTypes.array.isRequired,
};
