import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

import { useScrollImportedSectionsIntoView } from './importSectionHook';
import { fieldNames, form } from '../../../../forms/constants';
import { TemplateDocumentBuilderNav } from '../../components/DocumentBuilderNav';
import { TemplateSectionsBackNextButtons } from '../../components/BackNextButtons';
import { TemplateSectionHeader } from '../../components/SectionHeader';
import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { TemplateEditV2AddSectionMenu } from '../../components/ManageSections/AddSectionMenu';
import { ImportSectionMenuFooter } from '../../components/ManageSections/ImportSectionMenuFooter';
import { TemplateEditV2AddSection } from '../../components/ManageSections/AddSection';
import { TemplateEditV2ManageSectionsActionHeader } from '../../components/ManageSections/ActionHeader';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';
import { TemplateEditV2DocumentBuilderSDv2SectionsAdapter } from './SDv2SectionsAdapter';
import { NavScrollContainer } from '../../../../../../../components/SDv2';

const selector = formValueSelector(form);
const { PROJECT_SECTIONS } = fieldNames;

const Header = ({
    active,
    addingSectionData,
    unsetAddingSection,
    projectSection,
    useManualNumbering,
}) => {
    const isImported = projectSection?.isImported;
    if (active || isImported) {
        return (
            <TemplateEditV2AddSection
                addingSectionData={addingSectionData}
                importedSection={isImported ? projectSection : undefined}
                unsetAddingSection={unsetAddingSection}
                useManualNumbering={useManualNumbering}
            />
        );
    }

    return <TemplateSectionHeader />;
};

Header.propTypes = {
    active: PropTypes.bool.isRequired,
    addingSectionData: PropTypes.object.isRequired,
    unsetAddingSection: PropTypes.func.isRequired,
    projectSection: PropTypes.shape({
        isImported: PropTypes.bool,
    }),
    useManualNumbering: PropTypes.bool,
};

const Content = ({ active, projectSection }) => {
    const isImported = projectSection?.isImported;
    if (active && !isImported) {
        return null;
    }

    return <TemplateEditV2DocumentBuilderSDv2SectionsAdapter />;
};

Content.propTypes = {
    active: PropTypes.bool.isRequired,
    projectSection: PropTypes.shape({
        isImported: PropTypes.bool,
    }),
};

export const TemplateEditV2DocumentBuilder = () => {
    const {
        addingSectionData,
        disabled,
        importedSections,
        setAddingSection,
        templateProject,
        unsetAddingSection,
    } = useContext(TemplateEditV2FunctionsContext);
    const { activeSectionId } = useContext(TemplateEditV2NavContext);
    const projectSections = useSelector((state) => selector(state, PROJECT_SECTIONS)) || [];

    const allSections = [...projectSections, ...importedSections];
    const activeSection = allSections[activeSectionId];

    const importedSectionsCount = importedSections.length;

    const { navItemRef, scrollContainerRef } = useScrollImportedSectionsIntoView({
        importedSectionsCount,
    });

    // if we are adding a section, don't show content below
    const { active } = addingSectionData;

    return (
        <SDv2EditorLayout
            content={<Content active={active} projectSection={activeSection} />}
            footer={<TemplateSectionsBackNextButtons />}
            header={
                <Header
                    active={active}
                    addingSectionData={addingSectionData}
                    projectSection={activeSection}
                    unsetAddingSection={unsetAddingSection}
                    useManualNumbering={templateProject.useManualNumbering}
                />
            }
            navigation={
                <>
                    <TemplateEditV2ManageSectionsActionHeader disabled={disabled} />
                    <NavScrollContainer ref={scrollContainerRef}>
                        <TemplateDocumentBuilderNav navItemRef={navItemRef} />
                        <TemplateEditV2AddSectionMenu setAddingSection={setAddingSection} />
                    </NavScrollContainer>
                    {importedSectionsCount > 0 && <ImportSectionMenuFooter disabled={disabled} />}
                </>
            }
            /* TODO we may need to be a bit more dynamic with showing the shared toolbar.
             *
             * Ex: We really only want it to show for sections that have or will have text area instances
             * configured to use the shared toolbar. I do think this will be most of them but we'll have to keep
             * an eye on it.
             */
            showSharedTextareaToolbar={!active && allSections.length > 0}
        />
    );
};
