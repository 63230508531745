import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { defaultSectionConfigsMap } from '@og-pro/shared-config/sections';

import { TemplateSectionTitleMenuButtons } from './TemplateSectionTitleMenuButtons';
import { getTemplateSectionTypeDisplay } from '../helpers';
import { TemplateStatusLabel } from '../../../../components';
import { ConnectedClients } from '../../../../components/connected';

export const TemplateSectionTitleMenu = ({
    deleting,
    disabled,
    setShowFormValidation,
    showBroadcastModal,
    submitFailed,
    templateSection,
    templateSection: {
        isPublished,
        projectSection: { section_type: sectionType },
        type,
    },
    templateSectionsPath,
    title,
    toggleLive,
    updateTemplateSection,
    updating,
}) => {
    const deleteError = useSelector((state) => state.templateSections.get('deleteItemError'));
    const updateError = useSelector((state) => state.templateSections.get('updateItemError'));
    const copyError = useSelector((state) => state.templateSections.get('copyItemError'));
    const checkOutError = useSelector((state) => state.templateSections.get('checkOutItemError'));

    const styles = require('./TemplateSectionTitleMenu.scss');

    const { icon: sectionIcon, title: sectionTitle } = defaultSectionConfigsMap[sectionType];

    return (
        <div className={styles.titleContainer}>
            <div className="row">
                <div className="col-sm-7 col-md-8">
                    <h3 className={styles.title}>{title || 'Untitled'}</h3>
                    <TemplateStatusLabel className={styles.statusLabel} isPublished={isPublished} />
                    <div className={`text-muted ${styles.typeLabel}`}>
                        <i className={`fa fa-${sectionIcon}`} /> {sectionTitle} |{' '}
                        {startCase(getTemplateSectionTypeDisplay(type))}
                    </div>
                </div>
                <div className="col-sm-5 col-md-4 text-right">
                    <TemplateSectionTitleMenuButtons
                        deleting={deleting}
                        disabled={disabled}
                        setShowFormValidation={setShowFormValidation}
                        showBroadcastModal={showBroadcastModal}
                        templateSection={templateSection}
                        templateSectionsPath={templateSectionsPath}
                        toggleLive={toggleLive}
                        updateTemplateSection={updateTemplateSection}
                        updating={updating}
                    />
                    <div>
                        <ConnectedClients className={styles.connectedClients} isChecklist />
                    </div>
                </div>
            </div>
            {updateError && <div className="error-block text-right">{updateError}</div>}
            {submitFailed && (
                <div className="error-block text-right">
                    Please fix form errors before submitting
                </div>
            )}
            {deleteError && <div className="error-block text-right">{deleteError}</div>}
            {copyError && <div className="error-block text-right">{copyError}</div>}
            {checkOutError && <div className="error-block text-right">{checkOutError}</div>}
        </div>
    );
};

TemplateSectionTitleMenu.propTypes = {
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    showBroadcastModal: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    templateSection: PropTypes.shape({
        isPublished: PropTypes.bool.isRequired,
        projectSection: PropTypes.shape({
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    templateSectionsPath: PropTypes.string.isRequired,
    title: PropTypes.string,
    toggleLive: PropTypes.func.isRequired,
    updateTemplateSection: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};
