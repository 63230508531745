export const formConfig = {
    form: 'triageApprovalForm',
    validate: (values) => {
        const errors = {};
        if (!values.selectedApprover) {
            errors.selectedApprover = 'Selecting a reviewer is required before approval.';
        }
        return errors;
    },
    destroyOnUnmount: true,
};
