import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NoSsr } from '@mui/material';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { LoadingSpinner } from '../../../../components';
import { DocumentsList, ModuleManager, ModuleManagerSequence, ProjectModule } from '../components';
import {
    getContractModuleData,
    getDocBuilderModuleData,
    getIntakeModuleData,
    getSolicitationModuleData,
} from '../selectors';
import { getProjectJS } from '../../selectors';
import { menuActionHandler } from '../../../../actions/govProjects';

const mapStateToProps = (state, props) => {
    const documentsTabEnabled = props.getFlag(FLAGS.ENABLE_CONTRACT_DOCUMENTS_TAB);

    return {
        contractModuleData: getContractModuleData(state, props),
        docBuilderModuleData: getDocBuilderModuleData(state, { ...props, documentsTabEnabled }),
        project: getProjectJS(state),
        intakeModuleData: getIntakeModuleData(state, {
            ...props,
        }),
        solicitationModuleData: getSolicitationModuleData(state, props),
    };
};

const mapDispatchToProps = {
    menuActionHandler,
};

// @connect
class ConnectedProjectDashboard extends Component {
    static propTypes = {
        contractModuleData: PropTypes.object.isRequired,
        docBuilderModuleData: PropTypes.object,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            isDocBuilder: PropTypes.bool.isRequired,
        }).isRequired,
        intakeModuleData: PropTypes.object.isRequired,
        solicitationModuleData: PropTypes.object.isRequired,
    };

    actionHandler = (actionType) => {
        const { project } = this.props;
        return this.props.menuActionHandler(actionType, project);
    };

    render() {
        const {
            contractModuleData,
            docBuilderModuleData,
            project,
            intakeModuleData,
            solicitationModuleData,
        } = this.props;

        if (project.isDocBuilder) {
            return (
                <ProjectModule defaultOpen {...docBuilderModuleData}>
                    <ModuleManager
                        actionHandler={this.actionHandler}
                        moduleData={docBuilderModuleData}
                        project={project}
                    />
                </ProjectModule>
            );
        }

        return (
            <>
                <ProjectModule {...intakeModuleData}>
                    <NoSsr fallback={<LoadingSpinner noPadding />}>
                        <ModuleManager
                            actionHandler={this.actionHandler}
                            moduleData={intakeModuleData}
                            project={project}
                        />
                    </NoSsr>
                </ProjectModule>
                <ProjectModule {...solicitationModuleData} isPending={intakeModuleData.isCurrent}>
                    <NoSsr fallback={<LoadingSpinner noPadding />}>
                        {solicitationModuleData.moduleData.map((data) => (
                            <ModuleManagerSequence
                                actionHandler={this.actionHandler}
                                isPhaseComplete={solicitationModuleData.isComplete}
                                key={data.step}
                                moduleData={data}
                                project={project}
                            />
                        ))}
                    </NoSsr>
                </ProjectModule>
                <ProjectModule {...contractModuleData} isPending={intakeModuleData.isCurrent}>
                    <NoSsr fallback={<LoadingSpinner noPadding />}>
                        <ModuleManager
                            Pages={DocumentsList}
                            actionHandler={this.actionHandler}
                            moduleData={contractModuleData}
                            project={project}
                        />
                    </NoSsr>
                </ProjectModule>
            </>
        );
    }
}

export const ProjectDashboard = compose(
    withRouter,
    withFlags(),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectDashboard);
