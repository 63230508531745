import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import {
    form as attachmentUploadForm,
    fieldNames as attachmentUploadFieldNames,
} from '../../../components/forms/Attachable/AttachmentDropzone';

const { ADDENDUM } = attachmentTypesDict;

const getUploadFormAttachments = (state) => {
    return formValueSelector(attachmentUploadForm)(state, attachmentUploadFieldNames.FILES) || [];
};

const getFormAttachments = (state, props) => {
    return formValueSelector(props.form)(state, 'attachments') || [];
};

const getReviewAttachments = (state) => state.govProjects.getIn(['selectedProject', 'attachments']);

const getReviewAttachmentsJS = createSelector([getReviewAttachments], (rawAttachments) => {
    if (rawAttachments) {
        return rawAttachments.toJS();
    }
    return [];
});

const getAttachmentFormAppendixIds = createSelector([getUploadFormAttachments], (attachments) => {
    const appendixIds = {};
    attachments.forEach((attachment) => {
        appendixIds[attachment.appendixId] = true;
    });

    return appendixIds;
});

export const getReviewAppendixIdsMap = createSelector(
    [getReviewAttachmentsJS, getAttachmentFormAppendixIds],
    (attachments, formAppendixIds) => {
        const appendixIds = { ...formAppendixIds };
        attachments
            .filter((attachment) => attachment.type !== ADDENDUM)
            .forEach((attachment) => {
                appendixIds[attachment.appendixId] = true;
            });

        return appendixIds;
    }
);

const getFormAppendixIdsMap = createSelector(
    [getFormAttachments, getAttachmentFormAppendixIds],
    (attachments, formAppendixIds) => {
        const appendixIds = { ...formAppendixIds };
        attachments.forEach((attachment) => {
            appendixIds[attachment.appendixId] = true;
        });

        return appendixIds;
    }
);

// NOTE: This selector needs to have a `form` prop passed into it to work correctly
export function getAppendixIdsMap(state, props) {
    if (props.form) {
        return getFormAppendixIdsMap(state, props);
    }
    return getReviewAppendixIdsMap(state);
}
