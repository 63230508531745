import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';

import { VendorListSearch } from './VendorListSearch';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';
import { DataTable } from '../../DataTable/DataTable';
import { ProcuratedBadge } from '../../Procurated/ProcuratedBadge';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { ZeroState } from '../../ZeroState';

export class VendorList extends React.Component {
    static propTypes = {
        addOrRemoveVendors: PropTypes.func.isRequired,
        government_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        isVendorListSearch: PropTypes.bool.isRequired,
        listedUsers: PropTypes.arrayOf(
            PropTypes.shape({
                phoneComplete: PropTypes.string,
                displayName: PropTypes.string,
                fullName: PropTypes.string,
                initials: PropTypes.string,
                id: PropTypes.number,
                avatarUrl: PropTypes.string,
                email: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                organization: PropTypes.shape({
                    logo: PropTypes.string,
                    id: PropTypes.number,
                    name: PropTypes.string,
                    vendor: PropTypes.shape({
                        stateOfIncorporation: PropTypes.string,
                        id: PropTypes.number,
                        ein: PropTypes.string,
                        duns: PropTypes.string,
                        businessType: PropTypes.string,
                    }),
                }),
            })
        ),
        name: PropTypes.string.isRequired,
        // searchParams: PropTypes.any,
        showCustomEmailModal: PropTypes.func,
        showInviteToProjectModal: PropTypes.func,
        showAddVendorsToListModal: PropTypes.func.isRequired,
        showVendorProfileModal: PropTypes.func,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showCategorySearch: false,
        };
    }

    get styles() {
        return require('./styles.scss');
    }

    formatDataForCSVExport = (data) => {
        const headers = ['Vendor', 'User', 'Email', 'Phone'];

        const rows = data.map((dataRow) => {
            return [
                dataRow['organization.name'],
                dataRow.fullName,
                dataRow.email,
                dataRow.phoneComplete,
            ];
        });

        return [headers].concat(rows);
    };

    toggleCategorySearch = () =>
        this.setState((prevState) => ({ showCategorySearch: !prevState.showCategorySearch }));

    body() {
        const {
            government_id: govId,
            id: vendorListId,
            isVendorListSearch,
            listedUsers,
            name,
            showVendorProfileModal,
        } = this.props;
        const { showCategorySearch } = this.state;

        if (listedUsers.length === 0 && !isVendorListSearch) {
            return <ZeroState title="No vendors on this list." />;
        }

        const columns = [
            {
                Cell: (props) => {
                    const logo = get(props.original.organization, 'logo');
                    const displayName = get(props.original.organization, 'name');
                    const id = get(props.original.organization, 'vendor.id');

                    const vendorRating = get(props.original.organization, 'vendor.rating');

                    if (!displayName) {
                        return null;
                    }

                    if (process.env.SERVER) {
                        return displayName;
                    }

                    return (
                        <div className={this.styles.vendorDataCell} key={id}>
                            <img
                                alt={`${displayName} logo`}
                                className={this.styles.logo}
                                src={
                                    logo ||
                                    'https://assets.procurement.opengov.com/assets/no-logo.png'
                                }
                            />
                            <span
                                className={`pseudoLink ${this.styles.vendorName}`}
                                onClick={() => {
                                    if (showVendorProfileModal && id) {
                                        showVendorProfileModal(id);
                                    }
                                }}
                            >
                                {displayName}
                            </span>
                            <ProcuratedBadge badgeInHover ratingFromParent={vendorRating || null} />
                        </div>
                    );
                },
                Header: 'Vendor & Rating (Powered by Procurated)',
                minWidth: 200,
                accessor: 'organization.name',
            },
            {
                Header: 'User',
                accessor: 'fullName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Phone',
                accessor: 'phoneComplete',
            },
            {
                id: 'remove',
                maxWidth: 150,
                minWidth: 80,
                Cell: (props) => {
                    const {
                        original: { id }, // eslint-disable-line react/prop-types
                    } = props;
                    return (
                        <div className={this.styles.removeVendor}>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    this.props.addOrRemoveVendors({
                                        actionType: 'remove',
                                        governmentId: this.props.government_id,
                                        userIds: [id],
                                        vendorListId: this.props.id,
                                    });
                                }}
                                qaTag="vendorList-remove"
                                zeroPadding
                            >
                                Remove
                            </Button>
                        </div>
                    );
                },
            },
        ];

        const buttons = [];
        if (!showCategorySearch) {
            buttons.push(
                <Button onClick={this.toggleCategorySearch} qaTag="vendorList-searchByCategory">
                    <i className="fa fa-search" /> Search by Category
                </Button>
            );
        }

        let categorySearchElement;
        if (showCategorySearch) {
            categorySearchElement = (
                <VendorListSearch
                    governmentId={govId}
                    toggleCategorySearch={this.toggleCategorySearch}
                    vendorListId={vendorListId}
                />
            );
        }

        return (
            <>
                <div className="panel-title pull-left">Vendors ({listedUsers.length || 0})</div>
                <DataTable
                    buttons={buttons}
                    columns={columns}
                    componentsInlineWithButtons={categorySearchElement}
                    csvExportOptions={{
                        fileName: `${name}`,
                        getFormattedCSVData: this.formatDataForCSVExport,
                        headers: true,
                    }}
                    data={listedUsers}
                    defaultPageSize={10}
                    noDataText="No Vendors Found"
                    showCSVExport
                    showPagination
                />
            </>
        );
    }

    render() {
        const {
            // eslint-disable-next-line camelcase
            government_id,
            isVendorListSearch,
            name,
            id,
            listedUsers,
            showCustomEmailModal,
            showInviteToProjectModal,
            showAddVendorsToListModal,
            user,
        } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-6">
                        <h3>{`Vendor List: ${name}`}</h3>
                        <div className={this.styles.center}>
                            <UserProfilePicture
                                className={this.styles.userProfilePicture}
                                user={user}
                            />
                            {user.displayName}
                        </div>
                    </div>
                    <div
                        className={classnames(
                            'col-xs-12 col-sm-6 col-lg-6',
                            this.styles.newVendorButton
                        )}
                    >
                        <ButtonGroup>
                            {listedUsers.length > 0 && (
                                <>
                                    <Button
                                        bsSize="small"
                                        bsStyle="default"
                                        onClick={() => showCustomEmailModal()}
                                        qaTag="vendorList-emailVendors"
                                    >
                                        <i className="fa fa-envelope" />{' '}
                                        {isVendorListSearch
                                            ? 'Email Selected Vendors'
                                            : 'Email Vendor List'}
                                    </Button>
                                    <Button
                                        bsSize="small"
                                        bsStyle="default"
                                        onClick={() => showInviteToProjectModal()}
                                        qaTag="vendorList-inviteToProject"
                                    >
                                        <i className="fa fa-plus" />{' '}
                                        {isVendorListSearch
                                            ? 'Invite Selected Vendors to Project'
                                            : 'Invite List to Project'}
                                    </Button>
                                </>
                            )}
                            <Button
                                bsSize="small"
                                bsStyle="default"
                                onClick={() => showAddVendorsToListModal(government_id, id)}
                                qaTag="vendorList-addVendorsToList"
                            >
                                <i className="fa fa-user-plus" /> Add Vendors to List
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className={this.styles.tableList}>{this.body()}</div>
            </div>
        );
    }
}
