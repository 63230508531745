import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

export const ReviewChecklistSectionStatus = ({ incomplete, incompleteFields }) => {
    const styles = require('./index.scss');

    return (
        <Box
            className={classnames({
                [styles.complete]: !incomplete && !incompleteFields,
                [styles.incomplete]: incomplete || incompleteFields,
            })}
        >
            {!incomplete && !incompleteFields && (
                <Typography variant="h4">
                    Complete <i className="fa fa-check-circle" />
                </Typography>
            )}
            {incomplete && (
                <Typography variant="h4">
                    Incomplete <i className="fa fa-exclamation-triangle" />
                </Typography>
            )}
            {!!incompleteFields && (
                <Typography variant="h4">
                    {incompleteFields} Error{incompleteFields > 1 ? 's' : ''}&nbsp;
                    <i className="fa fa-exclamation-triangle" />
                </Typography>
            )}
        </Box>
    );
};

ReviewChecklistSectionStatus.propTypes = {
    incomplete: PropTypes.bool,
    incompleteFields: PropTypes.number,
};
