import React, { useEffect } from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { Box } from '@og-pro/ui';

import {
    getDashboardPath,
    getProjectJS,
    isGlobalEditorForProject,
    isSubscribed,
} from '../selectors';
import { getUserOrganizationTimezone } from '../../selectors';
import { menuActionHandler } from '../../../actions/govProjects';
import {
    Button,
    LoadingError,
    ProjectMenuDropdown,
    ProjectProfile,
    SubscribeButton,
} from '../../../components';
import { ConnectedClients } from '../../../components/connected';

export const ProjectContractsNav = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const dashboardPath = useSelector((state) => getDashboardPath(state, { params }));
    const isGlobalEditor = useSelector(isGlobalEditorForProject);
    const project = useSelector(getProjectJS);
    const subscribed = useSelector(isSubscribed);
    const timezone = useSelector(getUserOrganizationTimezone);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    if (!project) {
        return <LoadingError />;
    }

    const {
        government: {
            organization: { logo },
        },
        status,
        title,
    } = project;

    return (
        <div>
            <div className="text-right">
                <ConnectedClients />
            </div>
            <ProjectProfile
                dashboardPath={dashboardPath}
                imageUrl={logo}
                menuComponent={
                    <ProjectMenuDropdown
                        actionHandler={(type) => {
                            dispatch(menuActionHandler(type, project));
                        }}
                        isGlobalEditor={isGlobalEditor}
                        project={project}
                        subscribed={subscribed}
                    />
                }
                project={project}
                showBidDetails
                status={status}
                subscribeButton={<SubscribeButton isGovernment project={project} />}
                subscribed={subscribed}
                timezone={timezone}
                title={title}
            />
            <Box component={Panel} sx={{ minHeight: '600px' }}>
                <Panel.Body>
                    <div>
                        <Button bsStyle="link" to={dashboardPath}>
                            <i className="fa fa-angle-left" /> Project Dashboard
                        </Button>
                    </div>
                    <Outlet />
                </Panel.Body>
            </Box>
        </div>
    );
};
