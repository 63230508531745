import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import { ResetGovProject } from '../../components';
import { ContractList } from '../../../components/contracts';
import { QuestionAnswer } from '../../../containers';
import * as Gov from '../../../containers/GovApp';
import EvaluationCreateRoutes from './EvaluationCreate';
import EvaluationReviewRoutes from './EvaluationReview';
import IntakeReviewRoutes from './IntakeReview';
import BuilderReviewRoutes from './BuilderReview';
import SourcingCreateRoutes from './SourcingCreate';
import SourcingReviewRoutes from './SourcingReview';
import CreateRoutes from './Create';
import { ContractSubroutes } from '../Contract';
import { legacyFetchData, requireContractSubscription } from '../../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: (
                <ResetGovProject>
                    <Gov.ProjectContainer />
                </ResetGovProject>
            ),
            loader: legacyFetchData(getState, dispatch, Gov.ProjectContainer),
            path: 'projects/:projectId',
            children: [
                {
                    element: <Gov.ProjectDashboardContainer />,
                    children: [
                        {
                            element: <Gov.ProjectDashboard />,
                            index: true,
                        },
                        {
                            element: <Gov.ProjectDashboard />,
                            path: 'manage',
                        },
                        {
                            element: <Gov.ProjectStatusHistory />,
                            loader: legacyFetchData(getState, dispatch, Gov.ProjectStatusHistory),
                            path: 'status-history',
                        },
                    ],
                },
                {
                    element: <Gov.ProjectActionToolbar />,
                    children: [
                        // /governments/:governmentId/projects/:projectId/intake/*
                        ...IntakeReviewRoutes(getState, dispatch),
                        // /governments/:governmentId/projects/:projectId/builder/*
                        ...BuilderReviewRoutes(getState, dispatch),
                        {
                            element: <Outlet />,
                            loader: requireContractSubscription(getState, dispatch),
                            path: 'contracts',
                            children: [
                                {
                                    element: <Gov.ProjectContractsNav />,
                                    children: [
                                        {
                                            element: <ContractList.Project />,
                                            index: true,
                                        },
                                        ...ContractSubroutes(getState, dispatch),
                                    ],
                                },
                            ],
                        },
                        // /governments/:governmentId/projects/:projectId/sourcing/create
                        ...SourcingCreateRoutes(getState, dispatch),
                        // /governments/:governmentId/projects/:projectId/sourcing/*
                        ...SourcingReviewRoutes(getState, dispatch),
                        // /governments/:governmentId/projects/:projectId/evaluation/create
                        ...EvaluationCreateRoutes(getState, dispatch),
                        // /governments/:governmentId/projects/:projectId/evaluation/*
                        ...EvaluationReviewRoutes(getState, dispatch),
                    ],
                },
                {
                    element: <Gov.ReverseAuctionContainer />,
                    loader: legacyFetchData(getState, dispatch, Gov.ReverseAuctionContainer),
                    path: 'reverse-auction',
                    children: [
                        {
                            element: <Gov.ReverseAuctionSummary />,
                            path: 'summary',
                        },
                        {
                            element: <Gov.ReverseAuctionLineItemDetails />,
                            path: 'line-item-details',
                        },
                        {
                            element: <QuestionAnswer.GovernmentReverseAuction />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                QuestionAnswer.GovernmentReverseAuction
                            ),
                            path: 'q-and-a',
                        },
                    ],
                },
                {
                    element: <Outlet />,
                    loader: ({ request: { url } }) => {
                        return redirect(url.replace(/\/requisition/i, '/intake'));
                    },
                    path: 'requisition*',
                },
                // /governments/:governmentId/projects/:projectId/builder/create
                // /governments/:governmentId/projects/:projectId/intake/create
                ...CreateRoutes(getState, dispatch),
            ],
        },
    ];
};
