import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusTypes } from '@og-pro/shared-config/requisitions';

import { Button, DropdownMenu, ListItem, ListItemButton, ListItemText } from '@og-pro/ui';

import { qaTagPageName } from '../constants';
import { copyRequisition, deleteRequisition } from '../../../../actions/requisitions';
import { getRequisitionJS } from '../../../../selectors/govApp';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const { DRAFT } = statusTypes;

export const RequisitionsCreateActionButtons = ({
    disabled,
    saveRequisition,
    showGlobalFormValidation,
}) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const { id, status } = useSelector(getRequisitionJS);

    const isDeletable = status === DRAFT;

    const deleteAction = () => dispatch(deleteRequisition(id));
    const copyAction = () => dispatch(copyRequisition(id));

    const deleteHandler = () =>
        dispatch(
            showConfirmationSimpleModal(deleteAction, {
                btnText: 'Delete Request',
                text: 'Are you sure you want to delete this request?\n\nThis action cannot be undone.',
                useOpenGovStyle: true,
                dialogProps: {
                    fullWidth: true,
                },
                dialogStyle: 'error',
                title: 'Delete Request',
            })
        );
    const copyHandler = () =>
        dispatch(
            showConfirmationSimpleModal(copyAction, {
                btnText: 'Copy Request',
                text: 'Are you sure you want to create a copy this request?',
                useOpenGovStyle: true,
                dialogProps: {
                    fullWidth: true,
                },
                dialogStyle: 'basic',
                title: 'Create Copy of Request',
            })
        );

    return (
        <div className={styles.actionButtons}>
            <DropdownMenu
                color="secondary"
                label="Actions"
                menuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }}
                qaTag={`${qaTagPageName}-moreActions`}
                size="large"
                variant="outlined"
            >
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={showGlobalFormValidation}
                        qaTag={`${qaTagPageName}-showFormErrors`}
                    >
                        <ListItemText primary="Show Form Errors" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={copyHandler} qaTag={`${qaTagPageName}-copy`}>
                        <ListItemText primary="Copy Request" />
                    </ListItemButton>
                </ListItem>
                {isDeletable && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={deleteHandler} qaTag={`${qaTagPageName}-delete`}>
                            <ListItemText primary="Delete" />
                        </ListItemButton>
                    </ListItem>
                )}
            </DropdownMenu>
            <Button
                aria-label="Save"
                color="secondary"
                disabled={disabled}
                onClick={() => saveRequisition()}
                qaTag={`${qaTagPageName}-save`}
                size="large"
                variant="outlined"
            >
                Save Draft
            </Button>
        </div>
    );
};

RequisitionsCreateActionButtons.propTypes = {
    disabled: PropTypes.bool,
    saveRequisition: PropTypes.func.isRequired,
    showGlobalFormValidation: PropTypes.func.isRequired,
};
