import { get } from 'lodash';
import moment from 'moment';
import React from 'react';

import { HtmlContent } from '../../../../../components/HtmlContent/HtmlContent';
import { roundNumber } from '../../../../../helpers';

export const getSelectedVendorsColumnDefs = () => {
    return [
        {
            Header: 'Vendor',
            accessor: 'companyName',
        },
        {
            Header: 'Location',
            accessor: (params) => {
                const { contactCity, contactState } = params;
                const location =
                    contactCity && contactState
                        ? `${contactCity}, ${contactState}`
                        : 'No Location Provided';
                return <div style={{ textAlign: 'center' }}>{location}</div>;
            },
            id: 'vendor-location',
        },
    ];
};

export const getEvaluationCriteriaListColumnDefs = (totalWeight) => {
    return [
        {
            Header: 'Criteria',
            accessor: 'title',
            tdStyle: { textAlign: 'left' }, // Used for docx table
        },
        {
            Header: 'Description',
            accessor: 'description',
            docxHtml: (params) => <HtmlContent content={params.value} />, // Used for docx table
            tdStyle: { textAlign: 'left' }, // Used for docx table
        },
        {
            Header: 'Scoring Method',
            accessor: 'scoringMethodText',
        },
        {
            Header: 'Weight (Points)',
            accessor: 'weight',
            docxHtml: (params) => (
                <div style={{ textAlign: 'center' }}>
                    {params.value}&nbsp;
                    <i>({roundNumber((params.value / totalWeight) * 100, 1)}% of Total)</i>
                </div>
            ), // Used for docx table
        },
    ];
};

export const getEvaluatorsListColumnDefs = (timezone, hasProposalViewerAgreement) => {
    const columnDefs = [
        {
            Header: 'Name',
            accessor: 'displayName',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
    ];

    if (hasProposalViewerAgreement) {
        columnDefs.push({
            Header: 'Agreement Accepted On',
            accessor: 'agreement.acceptedAt',
            formatValue: (params) =>
                params.value ? moment(params.value).tz(timezone).format('lll') : 'Not Accepted',
        });
    }
    return columnDefs;
};

export const getConsensusScorecardData = (project) => {
    return project.scoredProposals.map((proposal) => {
        const proposalEvaluation = proposal.consensusEvaluation;
        return {
            ...proposal,
            proposalEvaluation,
        };
    });
};

// We use a simple data table for the evaluator agreements display in reports.
// Adding the agreements data to the evaluator makes it easy to access this
// data when generating the column definitions.
export function addAgreementsToEvaluators(evaluation, agreements) {
    if (!agreements) {
        return evaluation;
    }

    const { evaluators } = evaluation;
    const updatedEvaluators = evaluators.map((evaluator) => {
        const agreement =
            agreements.find((projAgreement) => projAgreement.user_id === evaluator.id) || {};
        return { ...evaluator, agreement };
    });
    return {
        ...evaluation,
        evaluators: updatedEvaluators,
    };
}

export const getEvaluationsData = (project) => {
    const currentEvaluation = [{ ...project.evaluation, scoredProposals: project.scoredProposals }];

    return project.evaluationAudits.reduce((evaluations, audit) => {
        const { data } = audit;
        const agreements = get(data, 'agreements');
        const evalWithAgreements = addAgreementsToEvaluators(data.evaluation, agreements);
        evaluations.push({ ...evalWithAgreements, scoredProposals: data.scoredProposals });
        return evaluations;
    }, currentEvaluation);
};
