export const HIDE_MODAL = 'exportProject/HIDE_MODAL';
export const SHOW_MODAL = 'exportProject/SHOW_MODAL';
export const SET_EXPORT_DATA = 'exportProject/SET_EXPORT_DATA';
export const CONFIGURE_EXPORT = 'exportProject/CONFIGURE_EXPORT';
export const LOAD = 'exportProject/LOAD';
export const LOAD_SUCCESS = 'exportProject/LOAD_SUCCESS';
export const LOAD_FAIL = 'exportProject/LOAD_FAIL';
export const LOAD_DOCUMENT = 'exportProject/LOAD_DOCUMENT';
export const LOAD_DOCUMENT_SUCCESS = 'exportProject/LOAD_DOCUMENT_SUCCESS';
export const LOAD_DOCUMENT_FAIL = 'exportProject/LOAD_DOCUMENT_FAIL';
export const LOAD_DOCX_HEADING_FORMATTERS_SUCCESS =
    'exportProject/LOAD_DOCX_HEADING_FORMATTERS_SUCCESS';
