import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAddendumProposals, getAddendumsJS } from './selectors';
import { loadAddendumProposals, loadAddendums } from '../../../../../actions/addendums';
import { LoadingSpinner, LoadingError } from '../../../../../components';
import { AddendumsConfirmMatrix } from '../../../../../components/addendums';

const mapStateToProps = (state) => {
    return {
        addendums: getAddendumsJS(state),
        loadError: state.addendums.get('loadListError'),
        loading: state.addendums.get('loadingList') || state.addendums.get('loadingProposals'),
        proposals: getAddendumProposals(state),
    };
};

const mapDispatchToProps = {
    loadAddendumProposals,
    loadAddendums,
};

class ConnectedAddendumsAcknowledgements extends Component {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        loadAddendumProposals: PropTypes.func.isRequired,
        loadAddendums: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        projectId: PropTypes.number.isRequired,
        proposals: PropTypes.array.isRequired,
    };

    componentDidMount() {
        const { projectId } = this.props;

        this.props.loadAddendums(projectId);
        this.props.loadAddendumProposals(projectId, { view: 'government' });
    }

    render() {
        const { addendums, loadError, loading, proposals } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <AddendumsConfirmMatrix
                addendums={addendums}
                proposals={proposals}
                title="Addenda Acknowledgements"
            />
        );
    }
}

export const AddendumsAcknowledgements = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAddendumsAcknowledgements);
