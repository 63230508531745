import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { Field } from 'redux-form';
import { useDispatch } from 'react-redux';

import { InfoOutlined as InfoOutlinedIcon, Warning as WarningIcon } from '@mui/icons-material';
import { compilerSectionTypes } from '@og-pro/shared-config/projects';
import { Box, Button, Tooltip, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { ReviewChecklistSection } from './Section';
import { ReviewChecklistSectionsContainer } from './SectionsContainer';
import { DragIcon, Toggle } from '../../../../../../components';
import { ContentBlock } from '../../../../../../components/SDv2/ContentBlock';
import { showFormValidation } from '../../../../../../actions/project/create/projectCreate';

const { ATTACHMENTS, ATTACHMENTS_TABLE_OF_CONTENTS, SIGNATURES } = compilerSectionTypes;

const SignaturesSection = ({ buildRoute, formErrors }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <ReviewChecklistSection
            incomplete={/* eslint-disable-line */ formErrors?.signatures?._error}
            last
            onClick={() => {
                dispatch(showFormValidation());
                navigate(buildRoute('signatures'));
            }}
            section={{ title: 'Signatures' }}
        />
    );
};

SignaturesSection.propTypes = {
    buildRoute: PropTypes.func.isRequired,
    formErrors: PropTypes.object,
};

const ExhibitsSection = ({ attachments, buildRoute, fields }) => {
    const styles = require('./index.scss');

    const exhibitListIndex = fields
        .getAll()
        .findIndex((section) => section.type === ATTACHMENTS_TABLE_OF_CONTENTS);

    return (
        <ReviewChecklistSectionsContainer
            childSectionProps={{ pl: 1, pr: 0 }}
            hideContainer
            hideStatus
            route={buildRoute('attachments')}
            sections={attachments}
            title={
                <Box
                    alignItems="center"
                    className={styles.toggle}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box>Exhibits</Box>
                    <Field
                        component={Toggle}
                        inverseToggleDisplay
                        leftLabel=""
                        name={`compileSettings.section_settings[${exhibitListIndex}].disabled`}
                        rightLabel={
                            <Box alignItems="center" display="flex" gap={1}>
                                <Typography variant="bodySmall">Display Exhibit List</Typography>
                                <Tooltip title="When turned on, an Exhibit List will be displayed before all uploaded exhibits.">
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{
                                            color: tokens.colors.colorGray700,
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                        }
                        useOpenGovStyle
                    />
                </Box>
            }
        />
    );
};

ExhibitsSection.propTypes = {
    attachments: PropTypes.array.isRequired,
    buildRoute: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
};

const ExhibitListSection = ({ attachments, belowAttachments, buildRoute }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <ReviewChecklistSection
            customStatus={
                belowAttachments ? (
                    <Box
                        alignItems="flex-start"
                        alignSelf="flex-start"
                        display="flex"
                        sx={{ color: tokens.colors.colorWarning800 }}
                    >
                        <Box>
                            <Typography fontWeight={500}>
                                List will appear below the Exhibits
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 0.5, pt: '1px' }}>
                            <WarningIcon fontSize="small" />
                        </Box>
                    </Box>
                ) : null
            }
            hideStatus
            last
            onClick={() => {
                dispatch(showFormValidation());
                navigate(buildRoute('attachments'));
            }}
            section={{
                title: 'Exhibit List',
            }}
        >
            <Box display="flex" flexDirection="column" gap={1} sx={{ py: 2, pl: 1, pr: 4 }}>
                {attachments.map((section) => (
                    <Typography>{section.title}</Typography>
                ))}
            </Box>
        </ReviewChecklistSection>
    );
};

ExhibitListSection.propTypes = {
    attachments: PropTypes.array.isRequired,
    buildRoute: PropTypes.func.isRequired,
    belowAttachments: PropTypes.bool,
};

export const DraggableChecklistSection = ({
    attachments,
    buildRoute,
    canMoveDown,
    canMoveUp,
    draggable,
    fields,
    formErrors,
    index,
    move,
}) => {
    const styles = require('./index.scss');

    let exhibitsListIsBelow = false;

    if (draggable.type === ATTACHMENTS_TABLE_OF_CONTENTS) {
        const i = fields.getAll().findIndex((s) => s.type === ATTACHMENTS);

        if (i > -1 && i < index) {
            exhibitsListIsBelow = true;
        }
    }

    return (
        <Draggable draggableId={draggable.type} index={index} key={draggable.type}>
            {(draggableProvided) => {
                return (
                    <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <ContentBlock className={styles.contentBlock} withActions>
                            <ContentBlock.Main>
                                {draggable.type === SIGNATURES && (
                                    <SignaturesSection
                                        buildRoute={buildRoute}
                                        formErrors={formErrors}
                                    />
                                )}
                                {draggable.type === ATTACHMENTS && (
                                    <ExhibitsSection
                                        attachments={attachments}
                                        buildRoute={buildRoute}
                                        fields={fields}
                                    />
                                )}
                                {draggable.type === ATTACHMENTS_TABLE_OF_CONTENTS && (
                                    <ExhibitListSection
                                        attachments={attachments}
                                        belowAttachments={exhibitsListIsBelow}
                                        buildRoute={buildRoute}
                                        formErrors={formErrors}
                                    />
                                )}
                            </ContentBlock.Main>
                            <ContentBlock.ActionSidebar>
                                <ContentBlock.ButtonGroup>
                                    {canMoveUp && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => move(index, index - 1)}
                                            >
                                                <i aria-hidden="true" className="fa fa-arrow-up" />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                    <ContentBlock.Button>
                                        <DragIcon
                                            dragHandleProps={draggableProvided.dragHandleProps}
                                        />
                                    </ContentBlock.Button>
                                    {canMoveDown && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => move(index, index + 1)}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="fa fa-arrow-down"
                                                />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                </ContentBlock.ButtonGroup>
                            </ContentBlock.ActionSidebar>
                        </ContentBlock>
                    </Box>
                );
            }}
        </Draggable>
    );
};

DraggableChecklistSection.propTypes = {
    attachments: PropTypes.array.isRequired,
    buildRoute: PropTypes.func.isRequired,
    canMoveDown: PropTypes.bool,
    canMoveUp: PropTypes.bool,
    draggable: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    formErrors: PropTypes.object,
    index: PropTypes.number.isRequired,
    move: PropTypes.func.isRequired,
};
