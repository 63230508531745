import { get, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { projectTypesDict } from '@og-pro/shared-config/projects';
import { upfrontQuestionTypesDisplay } from '@og-pro/shared-config/questionnaires';
import { PROJECT_TIMELINE_HTML } from '@og-pro/shared-config/timelines';
import { PROJECT_VARIABLE_IDENTIFIER } from '@og-pro/shared-config/signatures';
import {
    questionLogicOperatorNames,
    questionLogicOperatorNamesDisplay,
} from '@og-pro/shared-config/questionLogics';
import { templateSectionTypesDict } from '@og-pro/shared-config/templateSections';

const { CONTRACT, PURCHASE } = projectTypesDict;

const upfrontQuestionTypes = upfrontQuestionTypesDisplay.map((question) => question.type);

const projectContactTemplateVariables = (model) => [
    {
        label: 'Contact Full Name',
        value: `${model}.contactFullName`,
    },
    {
        label: 'Contact First Name',
        value: `${model}.contactFirstName`,
    },
    {
        label: 'Contact Last Name',
        value: `${model}.contactLastName`,
    },
    {
        label: 'Contact Email',
        value: `${model}.contactEmail`,
    },
    {
        label: 'Contact Phone',
        value: `${model}.contactPhoneComplete`,
    },
    {
        label: 'Contact Title',
        value: `${model}.contactTitle`,
    },
    {
        label: 'Contact Address',
        value: `${model}.contactAddress1`,
    },
    {
        label: 'Contact City',
        value: `${model}.contactCity`,
    },
    {
        label: 'Contact State',
        value: `${model}.contactState`,
    },
    {
        label: 'Contact Zip Code',
        value: `${model}.contactZipCode`,
    },
];

const procurementContactTemplateVariables = (model) => [
    {
        label: 'Procurement Contact Full Name',
        value: `${model}.procurementFullName`,
    },
    {
        label: 'Procurement Contact First Name',
        value: `${model}.procurementFirstName`,
    },
    {
        label: 'Procurement Contact Last Name',
        value: `${model}.procurementLastName`,
    },
    {
        label: 'Procurement Contact Email',
        value: `${model}.procurementEmail`,
    },
    {
        label: 'Procurement Contact Phone',
        value: `${model}.procurementPhoneComplete`,
    },
    {
        label: 'Procurement Contact Title',
        value: `${model}.procurementTitle`,
    },
    {
        label: 'Procurement Contact Address',
        value: `${model}.procurementAddress1`,
    },
    {
        label: 'Procurement Contact City',
        value: `${model}.procurementCity`,
    },
    {
        label: 'Procurement Contact State',
        value: `${model}.procurementState`,
    },
    {
        label: 'Procurement Contact Zip Code',
        value: `${model}.procurementZipCode`,
    },
];

const departmentTemplateVariables = (model) => [
    {
        label: 'Department Name',
        value: `${model}.departmentName`,
    },
    {
        label: 'Department Head',
        value: `${model}.departmentHead`,
    },
    {
        label: 'Department Head Title',
        value: `${model}.departmentHeadTitle`,
    },
];

export const projectTemplateVariableOptions = [
    {
        label: 'Project Title',
        value: 'project.title',
    },
    {
        label: 'Project ID',
        value: 'project.financialId',
    },
    {
        label: 'Request ID',
        value: 'project.requisitionIdentifier',
    },
    {
        label: 'Project Release Date',
        value: 'project.releaseProjectDate',
    },
    {
        label: 'Question Submission Date',
        value: 'project.qaDeadline',
    },
    {
        label: 'Question Submission Time',
        value: 'project.qaDeadlineTime',
    },
    {
        label: 'Question Response Date',
        value: 'project.qaResponseDeadline',
    },
    {
        label: 'Question Response Time',
        value: 'project.qaResponseDeadlineTime',
    },
    {
        label: 'Response Submission Date',
        value: 'project.proposalDeadline',
    },
    {
        label: 'Response Submission Time',
        value: 'project.proposalDeadlineTime',
    },
    {
        label: 'Pre-Proposal Date',
        value: 'project.preProposalDate',
    },
    {
        label: 'Pre-Proposal Time',
        value: 'project.preProposalDateTime',
    },
    {
        label: 'Pre-Proposal Location',
        value: 'project.timelineConfig.preProposalLocation',
    },
    {
        label: 'Pre-Proposal Attendance Required',
        value: 'project.preProposalText',
    },
    {
        label: 'Reverse Auction Starting Date',
        value: 'project.auctionStartDate',
    },
    {
        label: 'Reverse Auction Starting Time',
        value: 'project.auctionStartDateTime',
    },
    {
        label: 'Reverse Auction Ending Date',
        value: 'project.auctionEndDate',
    },
    {
        label: 'Reverse Auction Ending Time',
        value: 'project.auctionEndDateTime',
    },
    ...departmentTemplateVariables('project'),
    ...projectContactTemplateVariables('project'),
    ...procurementContactTemplateVariables('project'),
    {
        label: 'Organization Name',
        value: 'project.government.organization.name',
    },
];

export const projectIntroTemplateVariableOptions = [
    {
        label: 'Summary',
        value: 'project.summary',
    },
    {
        label: 'Background',
        value: 'project.background',
    },
    {
        label: 'Timeline',
        value: PROJECT_TIMELINE_HTML,
    },
];

const contractDocBuilderTemplateVariableOptions = [
    {
        label: 'Contract Title',
        value: 'contract.title',
    },
    {
        label: 'Contract Amount',
        value: 'contract.budget.amount',
    },
    {
        label: 'Contract Duration Type',
        value: 'contract.durationType',
    },
    {
        label: 'Initial Term',
        value: 'contract.initialTerm',
    },
    {
        label: 'Start Date',
        value: 'contract.startDate',
    },
    {
        label: 'End Date',
        value: 'contract.endDate',
    },
    {
        label: 'Contract ID',
        value: 'contract.contractId',
    },
    {
        label: 'Project ID',
        value: 'project.financialId',
    },
    {
        label: 'Request ID',
        value: 'contract.requisitionIdentifier',
    },
    {
        label: 'Vendor Name',
        value: 'contract.contractParty.companyName',
    },
    ...departmentTemplateVariables('contract'),
    ...projectContactTemplateVariables('contract'),
    ...procurementContactTemplateVariables('contract'),
    {
        label: 'Organization Name',
        value: 'contract.government.organization.name',
    },
];

export const templateTemplateVariableOptions = [
    {
        label: 'Template Title',
        value: 'project.template.title',
    },
    {
        label: 'Template Procurement Classification',
        value: 'project.template.procurementClassification',
    },
    {
        label: 'Template Process Acronym',
        value: 'project.template.processAcronym',
    },
];

export const proposalTemplateVariableOptions = [
    {
        label: 'Company Name',
        value: 'proposal.companyName',
    },
    {
        label: 'Response Contact Full Name',
        value: 'proposal.contactFullName',
    },
    {
        label: 'Response Contact First Name',
        value: 'proposal.contactFirstName',
    },
    {
        label: 'Response Contact Last Name',
        value: 'proposal.contactLastName',
    },
    {
        label: 'Response Contact Email',
        value: 'proposal.contactEmail',
    },
    {
        label: 'Response Contact Phone',
        value: 'proposal.contactPhoneComplete',
    },
    {
        label: 'Response Contact Address',
        value: 'proposal.contactAddress1',
    },
    {
        label: 'Response Contact City',
        value: 'proposal.contactCity',
    },
    {
        label: 'Response Contact State',
        value: 'proposal.contactState',
    },
    {
        label: 'Response Contact Zip Code',
        value: 'proposal.contactZipCode',
    },
];

export const INTRODUCTION_VARIABLES = 'Introduction Variables';

export const getTemplateVariableOptions = createSelector([({ project }) => project], (project) => {
    if (!project) {
        return [];
    }

    const customTemplateVariables = (project.upfrontQuestions || [])
        .filter((upfrontQuestion) => upfrontQuestionTypes.includes(upfrontQuestion.type))
        .map((upfrontQuestion) => {
            return {
                label: upfrontQuestion.title,
                value: `customVariable.${upfrontQuestion.id}`,
            };
        });

    const standardTemplateVariables =
        project.type === CONTRACT
            ? contractDocBuilderTemplateVariableOptions
            : projectTemplateVariableOptions;

    const signaturesVariables = (project.signatures || [])
        .filter((s) => !s.isHiddenByLogic)
        .reduce((acc, signature) => {
            try {
                let blocksCount = 1;
                const options = (signature?.signaturesBlocks || []).reduce((innerAcc, column) => {
                    const mapBlock = (block) => {
                        let label = `Signature Header: ${get(block, 'fields.0.value') || 'Untitled'}`;

                        if (block.styling === 'default') {
                            label = `Signature Block #${blocksCount}`;

                            blocksCount += 1;
                        }

                        return {
                            value: `${PROJECT_VARIABLE_IDENTIFIER}.${block.sharedId}`,
                            label,
                        };
                    };

                    let opts = [];

                    // in the editor, signaturesBlocks are grouped into "columns" which are array inside the main array
                    // in the preview screen they are not grouped, so signaturesBlocks is just a single level array.
                    // this accounts for that
                    if (Array.isArray(column)) {
                        opts = column.filter((b) => !b.isHiddenByLogic).map(mapBlock);
                    } else {
                        opts = [mapBlock(column)];
                    }

                    return innerAcc.concat(opts);
                }, []);

                return acc.concat(options);
            } catch (e) {
                return acc;
            }
        }, []);

    return [
        {
            label: 'Custom Variables',
            options: customTemplateVariables,
        },
        ...(project.type === PURCHASE
            ? [
                  {
                      label: INTRODUCTION_VARIABLES,
                      options: projectIntroTemplateVariableOptions,
                  },
              ]
            : []),
        {
            label: 'Standard Variables',
            options: standardTemplateVariables,
        },
        ...(project.type === CONTRACT && project.signatures.length
            ? [{ label: 'Signatures', options: signaturesVariables }]
            : []),
    ];
});

const getTemplateQuestionVariableOptions = createSelector(
    [({ templateQuestions }) => templateQuestions],
    (templateQuestions) => {
        return sortBy(
            (templateQuestions || [])
                .filter((templateQuestion) => templateQuestion.isPublished)
                .map((templateQuestion) => {
                    return {
                        label: templateQuestion.upfrontQuestion.title,
                        value: `sharedVariable.${templateQuestion.upfrontQuestion.sharedId}`,
                    };
                }),
            'label'
        );
    }
);

export const getTemplateSectionVariableOptions = createSelector(
    [({ templateSection }) => templateSection, getTemplateQuestionVariableOptions],
    (templateSection, templateQuestionsVariablesOptions) => {
        if (!templateSection) {
            return [];
        }

        if (templateSection.type === templateSectionTypesDict.GENERAL) {
            return [
                {
                    label: 'Shared Question Variables',
                    options: templateQuestionsVariablesOptions,
                },
            ];
        }

        return [
            {
                label: 'Shared Question Variables',
                options: templateQuestionsVariablesOptions,
            },
            ...(templateSection.type === PURCHASE
                ? [
                      {
                          label: INTRODUCTION_VARIABLES,
                          options: projectIntroTemplateVariableOptions,
                      },
                  ]
                : []),
            {
                label: 'Standard Variables',
                options:
                    templateSection.type === CONTRACT
                        ? contractDocBuilderTemplateVariableOptions
                        : projectTemplateVariableOptions,
            },
        ];
    }
);

export const QUANTITY_REQUESTED = 'quantityRequested';
export const TIMELINE_CONFIG = 'timelineConfig';
export const TIMELINES = 'timelines';
export const TIMELINE_HAS_ERRORS = 'timelineHasErrors';

const { EQUAL, INCLUDE, NOT_EQUAL, NOT_INCLUDE } = questionLogicOperatorNames;

export const booleanOperatorSelectOptions = [
    {
        label: questionLogicOperatorNamesDisplay[EQUAL],
        value: EQUAL,
    },
    {
        label: questionLogicOperatorNamesDisplay[NOT_EQUAL],
        value: NOT_EQUAL,
    },
];

export const operatorSelectOptions = [
    ...booleanOperatorSelectOptions,
    {
        label: questionLogicOperatorNamesDisplay[INCLUDE],
        value: INCLUDE,
    },
    {
        label: questionLogicOperatorNamesDisplay[NOT_INCLUDE],
        value: NOT_INCLUDE,
    },
];

export const questionLogicConfirmExitMessage =
    'You have not completed this form, are you sure you want to exit?';

export const contractTooltipFileTypesAccepted =
    'A contract packet can only support PDF, RTF, or any type of Word file types. Other file types cannot be uploaded and compiled into the contract packet.';

// Every word document type can be uploaded to the contract packet except for dotm files, cloudconvert does not support them
export const contractFileTypesAccepted = {
    // Modern Word documents
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    // Legacy Word documents
    'application/msword': ['.doc', '.dot'],
    // Word templates
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
    // Macro-enabled Word documents
    'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
    // Plain text files
    'text/plain': ['.txt'],
    // PDF files
    'application/pdf': ['.pdf'],
    // OpenDocument Text
    'application/vnd.oasis.opendocument.text': ['.odt'],
    // Rich Text Format
    'application/rtf': ['.rtf'],
};

export const onlyWordAndPdfFileTypesAccepted = {
    // Modern Word documents
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    // Legacy Word documents
    'application/msword': ['.doc', '.dot'],
    // Word templates
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
    // Macro-enabled Word documents
    'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
    // PDF files
    'application/pdf': ['.pdf'],
};
