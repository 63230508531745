import React from 'react';

export const TemplateEditV2FunctionsContext = React.createContext({});

export const TemplateEditV2NavContext = React.createContext({
    activeSectionId: 0,
    setActiveSectionId: () => {},
    showAiImporterDialog: false,
    setShowAiImporterDialog: () => {},
});

export const TemplateEditV2ManageSectionContext = React.createContext({
    data: {
        active: false,
        editing: false,
        index: null,
        isTemplate: null,
        sectionType: null,
    },
    setData: () => {},
});
