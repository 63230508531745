import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector, getFormSyncErrors } from 'redux-form';

import { BaseProjectSectionsNav } from '../../../../../../components/SDv2/ProjectSectionsNav/BaseProjectSectionsNav';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { form, fieldNames } from '../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../forms/TemplateForm/constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';

const { USE_MANUAL_NUMBERING } = formFieldNames;
const selector = formValueSelector(form);

export const TemplateDocumentBuilderNav = ({ navItemRef }) => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const projectSections = useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { addingSectionData, importedSections, showFormValidation, updateTemplateIfDirty } =
        useContext(TemplateEditV2FunctionsContext);

    let rawSections = [...projectSections];

    // if we have imported sections, we add them to the bottom of the list
    if (importedSections.length > 0) {
        rawSections = [
            ...rawSections,
            ...importedSections.map((importedSection, index) => ({
                active: false,
                index: projectSections.length + index,
                insertImportedSectionDivider: rawSections.length > 0 && index === 0,
                navItemRef: index === 0 ? navItemRef : null,
                sectionType: importedSection.projectSectionType,
                title: importedSection.title,
                isImported: true,
            })),
        ];
    }

    // if we are adding a section
    // we fake an entry in the bottom of the list
    if (addingSectionData.active) {
        rawSections = rawSections.concat([
            {
                ...addingSectionData.sectionType,
                title: 'New Section',
            },
        ]);
    }

    const sections = projectSectionsToNavSections({
        projectSections: rawSections,
        active: activeSectionId,
        setActive: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        useManualNumbering,
        formErrors,
        showFormValidation,
    });

    return <BaseProjectSectionsNav sections={sections} showShortName />;
};

TemplateDocumentBuilderNav.propTypes = {
    navItemRef: PropTypes.object,
};
