import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import { CDSButton } from '../../CDSButtons';

export const BaseSectionsNavNextButton = ({ children, onClick }) => {
    const styles = require('./index.scss');

    return (
        <CDSButton
            className={styles.navButton}
            onClick={() => (onClick ? onClick() : null)}
            qaTag="navButtons-next"
            variant="text"
        >
            <span className={styles.sectionName}>{children}</span>
            <span>&nbsp;&ndash; Next</span>
            <ChevronRightIcon className={styles.chevron} />
        </CDSButton>
    );
};

BaseSectionsNavNextButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};
