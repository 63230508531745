import { SUBTYPES, CONTRACT_ASSOCIATIONS } from '@og-pro/shared-config/audits/record-audit';

export * from './fields';
export * from './columns';

export const ACTIONS = {
    [SUBTYPES.CREATE]: 'Added',
    [SUBTYPES.UPDATE]: 'Updated',
    [SUBTYPES.DELETE]: 'Removed',
};

export const ASSOCIATIONS = {
    [CONTRACT_ASSOCIATIONS.ATTACHMENT]: 'Attachment',
    [CONTRACT_ASSOCIATIONS.CHECKLIST]: 'Checklist',
    [CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE]: 'Checklist Questionnaire',
    [CONTRACT_ASSOCIATIONS.CONTACT]: 'Contact',
    [CONTRACT_ASSOCIATIONS.INSURANCE]: 'Insurance',
    [CONTRACT_ASSOCIATIONS.NOTIFICATION]: 'Notification',
    [CONTRACT_ASSOCIATIONS.MILESTONE]: 'Milestone',
    [CONTRACT_ASSOCIATIONS.REVIEW]: 'Review',
    [CONTRACT_ASSOCIATIONS.PURCHASE_ORDER]: 'Spend',
    [CONTRACT_ASSOCIATIONS.SUBSCRIBER]: 'Notification',
    [CONTRACT_ASSOCIATIONS.REVIEW_COMPLAINT]: 'Vendor Performance',
    [CONTRACT_ASSOCIATIONS.REVIEW]: 'Vendor Performance',
    [CONTRACT_ASSOCIATIONS.PURCHASE_ORDER]: 'Spend Management',
    [CONTRACT_ASSOCIATIONS.RENEWAL]: 'Contract details',
    [CONTRACT_ASSOCIATIONS.BUDGET]: 'Budget',
    [CONTRACT_ASSOCIATIONS.BUDGET_ALLOCATION]: 'Contract Amount by Fiscal Year',
};

export const DATES = [
    'dateOfEvent',
    'startDate',
    'endDate',
    'date',
    'expirationDate',
    'requestedDate',
    'approvedDate',
    'submittedAt',
    'resolvedAt',
    'releasedAt',
];

export const BOOLEANS = [
    'isEmergency',
    'hasClaim',
    'isPiggyback',
    'isCooperative',
    'rebid',
    'hasProtest',
    'selected',
    'isRequired',
    'isPublic',
];
