import PropTypes from 'prop-types';
import React from 'react';

import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const TriagedStepWithOnlyEntityPositionsApproval = ({ stepPositionApprovals }) => {
    const approvalWithPrimaryApprover = stepPositionApprovals.find(
        ({ stepPositionApprovers }) => stepPositionApprovers[0].isPrimary
    );
    const approvalsWithAlternateApprovers = stepPositionApprovals.filter(
        ({ stepPositionApprovers }) => !stepPositionApprovers[0].isPrimary
    );

    return (
        <>
            <StepApprovalGroup
                approvers={[approvalWithPrimaryApprover.stepPositionApprovers[0].user]}
                name={approvalWithPrimaryApprover.position.name}
            />
            <StepApprovalGroup
                approvers={approvalsWithAlternateApprovers.map(
                    // We can assume that there is only one stepPositionApprover per approval because we are in a triaged step with only entity positions.
                    ({ stepPositionApprovers }) => stepPositionApprovers[0].user
                )}
                name="Alternate Approvers"
            />
        </>
    );
};

TriagedStepWithOnlyEntityPositionsApproval.propTypes = {
    stepPositionApprovals: PropTypes.arrayOf(
        PropTypes.shape({
            position: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
            stepPositionApprovers: PropTypes.array.isRequired,
        })
    ).isRequired,
};
