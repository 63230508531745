import { List, fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { v4 as UUIDv4 } from 'uuid';

import * as vendProposalActionTypes from '../actions/vendProposals/types';
import * as vendProposalSocketActions from '../actions/vendProposalsSocket';

const proposalInitialState = {
    connectedClients: [],
    deleting: false,
    deleteError: null,
    loaded: false,
    loadError: null,
    loading: false,
    proposal: null,
    proposalSyncEvent: null,
    updatedProposal: false,
    updateProposalError: null,
    updatingProposal: false,
    useFullWidthView: false,
};

const awardsInitialState = {
    awardedContracts: null,
    awardedContractsError: null,
    awardedProjects: null,
    awardedProjectsError: null,
    loadingAwardedContracts: false,
    loadingAwardedProjects: false,
};

const initialState = fromJS({
    ...proposalInitialState,
    ...awardsInitialState,
    followedProjects: [],
    list: [],
    loadAllError: null,
    loadedAll: false,
    loadingAll: false,
});

function attachmentReducer(state, action) {
    if (state.get('id') !== action.attachmentId) return state;

    switch (action.type) {
        case vendProposalActionTypes.DELETE_ATTACHMENT:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case vendProposalActionTypes.DELETE_ATTACHMENT_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}

function uploadReducer(state, action) {
    if (state.get('id') !== action.uploadId) return state;

    switch (action.type) {
        case vendProposalActionTypes.UPLOAD_PROPOSAL:
        case vendProposalActionTypes.CREATE_ATTACHMENT:
            return state.merge(
                fromJS({
                    uploading: true,
                    uploadError: null,
                })
            );
        case vendProposalActionTypes.UPLOAD_PROPOSAL_PROGRESS:
            return state.set('uploadProgress', action.progress);
        case vendProposalActionTypes.UPLOAD_PROPOSAL_SUCCESS:
            return state.set('uploadProgress', 100);
        case vendProposalActionTypes.UPLOAD_PROPOSAL_FAIL:
        case vendProposalActionTypes.CREATE_ATTACHMENT_FAIL:
            return state.merge(
                fromJS({
                    uploading: false,
                    uploadError: action.error && action.error.message,
                    uploadProgress: null,
                })
            );
        default:
            return state;
    }
}

function awardsReducer(state, action = {}) {
    switch (action.type) {
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS:
            return state.merge(
                fromJS({
                    loadingAwardedContracts: true,
                    awardedContractsError: null,
                })
            );
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingAwardedContracts: false,
                    awardedContractsError: null,
                    awardedContracts: isEmpty(action.result) ? null : action.result,
                })
            );
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS_FAIL:
            return state.merge(
                fromJS({
                    loadingAwardedContracts: false,
                    awardedContractsError: action.error.message,
                })
            );
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS:
            return state.merge(
                fromJS({
                    loadingAwardedProjects: true,
                    awardedProjectsError: null,
                })
            );
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingAwardedProjects: false,
                    awardedProjectsError: null,
                    awardedProjects: isEmpty(action.result) ? null : action.result,
                })
            );
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS_FAIL:
            return state.merge(
                fromJS({
                    loadingAwardedProjects: false,
                    awardedProjectsError: action.error.message,
                })
            );
        default:
            return state;
    }
}

export default function vendProposalsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case vendProposalActionTypes.LOAD_ALL:
            return state.merge(
                fromJS({
                    loadingAll: true,
                    loadAllError: null,
                })
            );
        case vendProposalActionTypes.LOAD_ALL_SUCCESS:
            return state.merge(
                fromJS({
                    loadingAll: false,
                    loadedAll: true,
                    list: fromJS(action.result),
                })
            );
        case vendProposalActionTypes.LOAD_ALL_FAIL:
            return state.merge(
                fromJS({
                    loadingAll: false,
                    loadedAll: false,
                    loadAllError: action.error && action.error.message,
                })
            );
        case vendProposalActionTypes.LOAD_FOLLOWED_PROJECTS_SUCCESS:
            return state.merge(
                fromJS({
                    loadingAll: false,
                    loadedAll: true,
                    followedProjects: fromJS(action.result),
                })
            );
        case vendProposalActionTypes.LOAD:
            return state.merge(
                fromJS({
                    loading: true,
                    loadError: null,
                })
            );
        case vendProposalActionTypes.LOAD_SUCCESS:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: true,
                    proposal: fromJS(action.result),
                })
            );
        case vendProposalActionTypes.LOAD_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loaded: false,
                    loadError: action.error && action.error.message,
                })
            );
        case vendProposalActionTypes.RESET_PROPOSAL:
            return state.merge(fromJS(proposalInitialState));
        case vendProposalActionTypes.UPDATE_PROPOSAL:
            return state.merge(
                fromJS({
                    updatingProposal: true,
                    updatedProposal: false,
                    updateProposalError: null,
                })
            );
        case vendProposalActionTypes.UPDATE_PROPOSAL_SUCCESS:
            return state.merge(
                fromJS({
                    updatingProposal: false,
                    updatedProposal: true,
                    proposal: fromJS(action.result),
                })
            );
        case vendProposalActionTypes.UPDATE_PROPOSAL_FAIL:
            return state.merge(
                fromJS({
                    updatingProposal: false,
                    updateProposalError: action.error && action.error.message,
                })
            );
        case vendProposalActionTypes.START_UPLOAD:
            return state.setIn(
                ['proposal', 'uploads'],
                (state.getIn(['proposal', 'uploads']) || new List()).push(fromJS(action.result))
            );
        case vendProposalActionTypes.UPLOAD_PROPOSAL:
        case vendProposalActionTypes.UPLOAD_PROPOSAL_PROGRESS:
        case vendProposalActionTypes.UPLOAD_PROPOSAL_SUCCESS:
        case vendProposalActionTypes.UPLOAD_PROPOSAL_FAIL:
        case vendProposalActionTypes.CREATE_ATTACHMENT:
        case vendProposalActionTypes.CREATE_ATTACHMENT_FAIL:
            return state.setIn(
                ['proposal', 'uploads'],
                (state.getIn(['proposal', 'uploads']) || new List()).map((upload) =>
                    uploadReducer(upload, action)
                )
            );
        case vendProposalActionTypes.COMPLETE_UPLOAD:
            return state.setIn(
                ['proposal', 'uploads'],
                state
                    .getIn(['proposal', 'uploads'])
                    .filter((upload) => upload.get('id') !== action.uploadId)
            );
        case vendProposalActionTypes.CREATE_ATTACHMENT_SUCCESS:
            return state.setIn(
                ['proposal', 'attachments'],
                state.getIn(['proposal', 'attachments']).push(fromJS(action.result))
            );
        case vendProposalActionTypes.DELETE_ATTACHMENT:
        case vendProposalActionTypes.DELETE_ATTACHMENT_FAIL:
            return state.setIn(
                ['proposal', 'attachments'],
                state
                    .getIn(['proposal', 'attachments'])
                    .map((attachment) => attachmentReducer(attachment, action))
            );
        case vendProposalActionTypes.DELETE_ATTACHMENT_SUCCESS:
            return state.setIn(
                ['proposal', 'attachments'],
                state
                    .getIn(['proposal', 'attachments'])
                    .filter((attach) => attach.get('id') !== action.attachmentId)
            );
        case vendProposalActionTypes.CONFIRM_ADDENDUM_SUCCESS:
            return state.setIn(
                ['proposal', 'addendums'],
                state.getIn(['proposal', 'addendums']).map((addendum) => {
                    if (addendum.get('id') === action.addendum.id) {
                        return fromJS(action.addendum);
                    }
                    return addendum;
                })
            );
        case vendProposalActionTypes.DELETE_PROPOSAL:
            return state.merge(
                fromJS({
                    deleting: true,
                    deleteError: null,
                })
            );
        case vendProposalActionTypes.DELETE_PROPOSAL_SUCCESS:
            return state.merge(
                fromJS({
                    deleting: false,
                    proposal: null,
                    list: state
                        .get('list')
                        .filterNot((prop) => prop.get('id') === action.proposalId),
                })
            );
        case vendProposalActionTypes.DELETE_PROPOSAL_FAIL:
            return state.merge(
                fromJS({
                    deleting: false,
                    deleteError: action.error && action.error.message,
                })
            );
        case vendProposalActionTypes.TOGGLE_FULL_WIDTH_VIEW:
            return state.set('useFullWidthView', action.useFullWidthView);
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS:
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS_SUCCESS:
        case vendProposalActionTypes.LOAD_AWARDED_CONTRACTS_FAIL:
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS:
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS_SUCCESS:
        case vendProposalActionTypes.LOAD_AWARDED_PROJECTS_FAIL:
            return awardsReducer(state, action);
        case vendProposalSocketActions.CONNECTED_CLIENT_COUNT:
            return state.set('connectedClients', fromJS(action.data));
        case vendProposalSocketActions.LEAVE:
            return state.set('connectedClients', fromJS([]));
        case vendProposalSocketActions.SYNC_EVENT:
            return state.set('proposalSyncEvent', fromJS({ key: UUIDv4(), ...action.data }));
        default:
            return state;
    }
}
