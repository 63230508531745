import AddIcon from '@mui/icons-material/Add';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { purchaseDetailsModes } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../../../../constants';
import { fieldNames } from '../../../constants';
import { hasContractingSubscription } from '../../../../../../GovApp/selectors';
import { requisitionsCreateFormValueSelector } from '../../../selectors';

const { PURCHASE_DETAILS_MODE } = fieldNames;

export const AddVendor = ({ canAddVendor, onClick }) => {
    const hasContracting = useSelector(hasContractingSubscription);

    const purchaseDetailsMode = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PURCHASE_DETAILS_MODE)
    );

    const disabledButtonReason =
        purchaseDetailsMode === purchaseDetailsModes.LINE_ITEMS
            ? 'Add more line items before adding another vendor. There must be unassigned line items to add more vendors.'
            : 'Only one vendor can ba added.'; // for either AMOUNT_ONLY mode, or if there is no purchase details section

    return (
        <Box>
            <Button
                color="secondary"
                disabled={!canAddVendor}
                onClick={onClick}
                qaTag={`${qaTagPageName}-addVendorFromApprovalPage`}
                startIcon={<AddIcon />}
                tooltip={canAddVendor ? undefined : disabledButtonReason}
                variant="outlined"
            >
                Add Vendor
            </Button>
            <Typography
                color={capitalDesignTokens.semanticColors.foreground.secondary}
                marginLeft={1}
                variant="span"
            >
                by searching for a vendor{hasContracting && ' or contract'}
            </Typography>
        </Box>
    );
};

AddVendor.propTypes = {
    canAddVendor: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
