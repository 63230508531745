import { get, includes } from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { FLAGS } from '@og-pro/launch-darkly/client';

import {
    evaluationStatuses,
    LOWEST_PRICE,
    LINE_ITEM_AWARD,
    SCORED,
} from '@og-pro/shared-config/evaluations';
import { moduleTypesDict } from '@og-pro/shared-config/modules';
import { projectStatusesDict, projectTypesDict } from '@og-pro/shared-config/projects';
import { isAuctionLive } from '@og-pro/shared-config/reverseAuctions/util';

import {
    ASSOCIATED_PROJECT,
    AWARD_PROJECT_FROM_PENDING,
    CLOSE_PROJECT,
    CONSENSUS_EVALUATION,
    COPY_DOCUMENT,
    COPY_LINK,
    CREATE_INTAKE_FROM_PROJECT,
    DELETE,
    DELETE_EVALUATION,
    DELETE_POST,
    DELETE_INTAKE,
    END_AUCTION_REJECT_RESULTS,
    EVALUATE,
    EXPORT,
    FINALIZE_AWARD,
    INVITE_COLLABORATORS,
    CREATE_CONTRACT_FROM_PROJECT,
    PAUSE_PROJECT,
    POST,
    EDIT_PRE_INVITE_LIST,
    PREVIEW,
    PUBLIC_DISPLAY_OPTIONS,
    RELEASE_EVALUATION,
    REOPEN_BUILDER,
    REOPEN_CLOSED_PROJECT,
    REOPEN_CLOSED_INTAKE,
    REOPEN_DRAFT_STATUS,
    REOPEN_PROJECT_INTAKE,
    RESEAL_BIDS,
    RETRACT_POST,
    SKIP_TO_NEXT_STAGE,
    TOGGLE_EDIT_TIMELINES_MODAL,
    UNAWARD_PROJECT,
    UNFINALIZE,
    UNSEAL_BIDS,
    VENDOR_DISCOVERY,
    TOGGLE_SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL,
} from '../../../constants/menuActions';

const {
    AUCTION_PENDING,
    AWARD_PENDING,
    CLOSED,
    DRAFT,
    EVALUATION,
    FINAL,
    OPEN,
    PENDING,
    POST_PENDING,
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    REVERSE_AUCTION,
    REVIEW,
} = projectStatusesDict;

const { COMPLETE, DRAFT: EVALUATION_DRAFT, OPEN: EVALUATION_OPEN, RELEASED } = evaluationStatuses;

const {
    BUILDER: BUILDER_MODULE,
    EVALUATION: EVALUATION_MODULE,
    INTAKE: INTAKE_MODULE,
    SOURCING: SOURCING_MODULE,
} = moduleTypesDict;

const { ADDENDUM, CONTRACT, PURCHASE } = projectTypesDict;

const { VENDOR_DISCOVERY_AI } = FLAGS;

function getProjectRoutePrefix(project) {
    return `/governments/${project.government_id}/projects/${project.id}`;
}

const closeOutProjectAction = {
    name: 'Close Out Project',
    icon: 'fa-archive',
    action: CLOSE_PROJECT,
};

const createEvaluationAction = {
    name: 'Create Evaluation',
    icon: 'fa-star',
    action: EVALUATE,
};

const inviteCollaboratorsAction = {
    name: 'Invite Collaborators',
    icon: 'fa-user-plus',
    action: INVITE_COLLABORATORS,
};

const discoverVendorsAction = (project) => {
    return {
        name: 'Discover Vendors',
        icon: 'fa-lightbulb-o',
        action: VENDOR_DISCOVERY,
        hasPermission: true,
        isHidden: project.type !== PURCHASE,
        featureFlag: VENDOR_DISCOVERY_AI,
        requiresAiEnablement: true,
    };
};

const editPreInviteListAction = (isEditor, status, wasPosted, props = {}) => {
    return {
        name: !wasPosted && status !== CLOSED ? 'Edit Pre-Invite List' : 'Pre-Invite List',
        icon: 'fa-address-book',
        action: EDIT_PRE_INVITE_LIST,
        hasPermission: isEditor,
        ...props,
    };
};

const pauseProjectAction = (project, isEditor, isCurrent) => {
    return {
        name: project.isPaused ? 'Take Off Hold' : 'Put On Hold',
        icon: project.isPaused ? 'fa-play-circle' : 'fa-pause-circle',
        action: PAUSE_PROJECT,
        hasPermission: isEditor,
        isHidden: !isCurrent,
    };
};

const reopenClosedProjectAction = (project, hasPermission, isLastUsed, isHidden = false) => {
    return {
        name: 'Reopen Project',
        icon: 'fa-undo',
        action: REOPEN_CLOSED_PROJECT,
        hasPermission,
        isHidden: project.status !== CLOSED || !isLastUsed || isHidden,
    };
};

const resealBidsAction = (isOpenGovAdminUser, project) => {
    const isSpecialUser = isOpenGovAdminUser || __DEMO__ || __DEV__;
    const isSealedBidUnsealed = project.hasSealedBid && project.showBids;

    return {
        name: `[${__DEMO__ ? 'Demo' : 'PN Admin'} Only] Reseal Bids`,
        icon: 'fa-lock',
        action: RESEAL_BIDS,
        hasPermission: isSpecialUser,
        isHidden: !isSpecialUser || !isSealedBidUnsealed,
    };
};

function intakeModuleData(data, props) {
    const { isGlobalEditor, project } = data;

    const { status } = project;

    const { permissions } = props;

    const { isOwner, isEditor, isPermitted } = permissions;

    const projectRoute = getProjectRoutePrefix({
        ...project,
        id: project.intake_id || project.id,
    });
    const route = `${projectRoute}/intake`;

    const isComplete = props.isComplete;
    const isCurrent = props.isCurrent;
    const isOwnerOrGlobalEditor = isOwner || isGlobalEditor;

    const intakeFullName = 'Project Request';
    const intakeShortName = 'Request';

    return {
        ...props,
        isComplete,
        isCurrent,
        name: intakeFullName,
        step: null,
        pages: [
            {
                name: `Edit<br>${intakeShortName}`,
                icon: 'fa-pencil',
                link: `${route}/create/project-properties`,
                hasPermission: isEditor,
                disabled: !isCurrent,
                disabledText: `${intakeShortName} has been finalized and can no longer be edited.`,
            },
            {
                name: `View<br>${intakeShortName}`,
                icon: 'fa-file-text',
                link: route,
                hasPermission: isPermitted,
            },
            {
                name: isComplete
                    ? `View Processed<br>${intakeShortName}`
                    : `Process<br>${intakeShortName}`,
                icon: 'fa-exchange',
                link: `${route}/approvals`,
                hasPermission: isPermitted,
                disabled: status === REQUEST_DRAFT,
                disabledText: `The ${intakeShortName} draft must be completed before it can be processed`,
            },
            {
                name: 'Revision<br>History',
                icon: 'fa-archive',
                link: `${route}/revisions/versions`,
                hasPermission: isPermitted,
            },
        ],
        primaryActions: [
            {
                name: `Submit ${intakeShortName}`,
                icon: 'fa-check-square-o fa-lg',
                link: `${route}/create?section=confirmation`,
                hasPermission: isEditor,
                isActive: status === REQUEST_DRAFT,
            },
            {
                name: `Process ${intakeShortName}`,
                icon: 'fa-check-square-o fa-lg',
                link: `${route}/approvals`,
                hasPermission: isEditor,
                isActive: status === REQUEST_REVIEW,
            },
        ],
        secondaryActions: [
            {
                ...inviteCollaboratorsAction,
                hasPermission: true,
                isHidden: !isCurrent,
            },
            discoverVendorsAction(project),
            editPreInviteListAction(isEditor, status),
            {
                name: 'Copy Project Request',
                icon: 'fa-copy',
                action: CREATE_INTAKE_FROM_PROJECT,
                hasPermission: true,
            },
            pauseProjectAction(project, isEditor, isCurrent),
            {
                name: `Delete ${intakeShortName}`,
                icon: 'fa-trash',
                action: DELETE_INTAKE,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden:
                    !isOwnerOrGlobalEditor || !includes([REQUEST_DRAFT, REQUEST_REVIEW], status),
            },
            {
                name: `Reopen ${intakeShortName}`,
                icon: 'fa-undo',
                action: REOPEN_CLOSED_INTAKE,
                hasPermission: isEditor,
                isHidden: status !== CLOSED || !project.isIntake || project.isIntakeConverted,
            },
        ],
    };
}

function builderModule(data, props) {
    const {
        isBidPoster,
        isEditableStatus,
        isGlobalEditor,
        isProjectCreator,
        project,
        user: { government },
    } = data;

    const { isDocBuilder, status, type, wasPosted } = project;

    const { isLastUsed, permissions } = props;

    const { isEditor, isOwner, isPermitted } = permissions;

    const route = `${getProjectRoutePrefix(project)}/builder`;

    // For UI purposes module is considered complete when in FINAL state
    // (different for server purposes)
    const isComplete = props.isComplete || status === FINAL;
    const isCurrent = props.isCurrent && !isComplete;
    const isOwnerOrGlobalEditor = isOwner || isGlobalEditor;
    const isDeletableStatus = includes([DRAFT, REVIEW], status);
    const isContractDocument = type === CONTRACT;

    return {
        ...props,
        isComplete,
        isCurrent,
        name: 'Builder',
        step: 1,
        pages: [
            {
                name: 'Edit<br>Document',
                icon: 'fa-pencil',
                link: `${route}/create/project-properties`,
                hasPermission: isEditor,
                disabled: !isEditableStatus,
                disabledText:
                    status === FINAL
                        ? 'Document has been finalized and cannot be edited. To ' +
                          'edit the document, you need to unlock the project.'
                        : 'Document has been finalized and can no longer be edited.',
            },
            {
                name: 'View<br>Document',
                icon: 'fa-file-text',
                link: route,
                hasPermission: isPermitted,
            },
            {
                name: 'Approvals',
                icon: 'fa-search',
                link: `${route}/approvals`,
                hasPermission: isPermitted,
                disabledText: 'Complete the draft of your document to start the approval process',
            },
            {
                name: 'Revision<br>History',
                icon: 'fa-archive',
                link: `${route}/revisions/versions`,
                hasPermission: isPermitted,
            },
        ],
        primaryActions: [
            {
                name: 'Approval Setup',
                icon: 'fa-check-square-o fa-lg',
                link: `${route}/approvals`,
                hasPermission: isEditor,
                isActive: status === DRAFT,
            },
            {
                name: 'Finalize Document',
                icon: 'fa-check-square-o fa-lg',
                link: `${route}/approvals`,
                hasPermission: isEditor,
                isActive: status === REVIEW,
            },
            {
                name: 'Draft Posting',
                icon: 'fa-flag',
                action: POST,
                hasPermission: isEditor,
                isActive: status === FINAL && government.hasSourcing && !isDocBuilder,
            },
            {
                ...createEvaluationAction,
                hasPermission: isEditor,
                isActive: status === FINAL && government.hasEvaluation && !isDocBuilder,
            },
        ],
        secondaryActions: [
            {
                ...inviteCollaboratorsAction,
                hasPermission: true,
            },
            discoverVendorsAction(project),
            {
                action: TOGGLE_SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL,
                name: wasPosted ? 'Pre-Invite Vendors' : 'Invite Vendors',
                icon: 'fa-envelope',
                hasPermission: isEditor,
                isHidden: ![DRAFT, REVIEW, FINAL].includes(status) || type === CONTRACT,
            },
            {
                name: 'Export Document',
                icon: 'fa-cloud-download',
                action: EXPORT,
                hasPermission: isPermitted,
            },
            {
                name: 'Preview Document',
                icon: 'fa-search',
                action: PREVIEW,
                hasPermission: isPermitted,
            },
            {
                name: 'Go Back to Draft Stage',
                icon: 'fa-undo',
                action: REOPEN_DRAFT_STATUS,
                hasPermission: isEditor,
                isHidden: status !== REVIEW,
            },
            {
                name: 'Unlock Document',
                icon: 'fa-unlock-alt',
                action: UNFINALIZE,
                hasPermission: isEditor,
                isHidden: status !== FINAL,
            },
            {
                name: 'Copy Document',
                icon: 'fa-copy',
                action: COPY_DOCUMENT,
                hasPermission: isProjectCreator || isGlobalEditor || isBidPoster,
            },
            {
                name: 'Get Shareable Project Link',
                icon: 'fa-link',
                action: COPY_LINK,
                hasPermission: true,
                isHidden: !government.hasSourcing || isDocBuilder,
            },
            {
                name: 'Public Display Options',
                icon: 'fa-sliders',
                action: PUBLIC_DISPLAY_OPTIONS,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !government.hasSourcing || isDocBuilder,
            },
            {
                name: 'Create Associated Project',
                icon: 'fa-external-link',
                action: ASSOCIATED_PROJECT,
                hasPermission: isProjectCreator || isBidPoster,
            },
            ...(government.hasIntake && !isContractDocument
                ? [
                      {
                          name: 'Create Intake from Project',
                          icon: 'fa-rss',
                          action: CREATE_INTAKE_FROM_PROJECT,
                          hasPermission: isProjectCreator || isBidPoster,
                      },
                  ]
                : []),
            pauseProjectAction(project, isEditor, isCurrent),
            {
                ...closeOutProjectAction,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !isOwnerOrGlobalEditor || status !== FINAL,
            },
            {
                name: 'Delete Document',
                icon: 'fa-trash',
                action: project.intake_id ? REOPEN_PROJECT_INTAKE : DELETE,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !isOwnerOrGlobalEditor || !isDeletableStatus,
            },
            reopenClosedProjectAction(project, isOwnerOrGlobalEditor, isLastUsed),
        ],
    };
}

function sourcingModule(data, props) {
    const {
        isBidPoster,
        isGlobalEditor,
        isOpenGovAdminUser,
        isProjectCreator,
        project,
        user: { government },
    } = data;

    const {
        qaEnabled,
        showBids,
        showBidsWithPricing,
        status,
        template: { isReverseAuction },
        wasPosted,
    } = project;

    const { isLastUsed, permissions } = props;

    const { isEditor, isOwner, isPermitted, isViewer } = permissions;

    const baseProjectRoute = `${getProjectRoutePrefix(project)}`;
    const route = `${getProjectRoutePrefix(project)}/sourcing`;
    const isOpenOrPending = status === OPEN || status === PENDING;

    // For UI purposes module is considered complete when in PENDING state
    // (different for server purposes)
    const isComplete = props.isComplete || status === PENDING;
    const isCurrent = props.isCurrent && !isComplete;
    const isOwnerOrGlobalEditor = isOwner || isGlobalEditor;
    const isReverseAuctionPageEnabled = isAuctionLive(project) || isComplete;

    return {
        ...props,
        isComplete,
        name: 'Sourcing',
        step: 2,
        pages: [
            {
                name: 'Edit<br>Post',
                icon: 'fa-pencil',
                link: `${route}/create`,
                hasPermission: isEditor,
                disabled: !isCurrent,
                disabledText: 'Post has been finalized and can no longer be edited',
            },
            {
                // Route users without view permission to the public project page
                name: isViewer ? 'View<br>Post' : 'View<br>Public Post',
                icon: 'fa-flag',
                link: isViewer ? route : `/portal/${government.code}/projects/${project.id}`,
                hasPermission: isPermitted,
            },
            {
                name: 'Addenda &<br>Notices',
                icon: 'fa-paperclip',
                link: `${route}/addenda`,
                hasPermission: isViewer,
                disabled: !wasPosted,
                disabledText:
                    'Addenda & Notices will be available once the project has been posted',
            },
            {
                name: 'Question &<br>Answer',
                icon: 'fa-send',
                link: `${route}/q-and-a`,
                hasPermission: isViewer,
                disabled: !wasPosted || !qaEnabled,
                disabledText: !wasPosted
                    ? 'Question & Answer will be available once the project has been posted'
                    : 'Question & Answer has not been enabled for this project',
            },
            {
                name: 'RSVP<br>Manager',
                icon: 'fa-calendar-check-o',
                link: `${route}/rsvp-manager`,
                hasPermission: isViewer,
                disabled: !wasPosted,
                disabledText: 'RSVP Manager will be available once the project has been posted',
            },
            {
                name: 'Submitted<br>Responses',
                icon: 'fa-inbox',
                link: `${route}/proposals`,
                hasPermission: isViewer,
                disabled: !wasPosted,
                disabledText:
                    'Submitted Responses will be available once the project has been posted',
            },
            {
                name: 'Vendor<br>Analytics',
                icon: 'fa-line-chart',
                link: `${route}/vendor-analytics`,
                hasPermission: isViewer,
                disabled: !wasPosted,
                disabledText: 'Vendor Analytics will be available once the project has been posted',
            },
            ...(isReverseAuction
                ? [
                      {
                          name: 'Reverse<br>Auction',
                          icon: 'fa-gavel',
                          link: `${baseProjectRoute}/reverse-auction/summary`,
                          hasPermission: isViewer,
                          disabled: !isReverseAuctionPageEnabled,
                          disabledText:
                              'Reverse Auction will be available once the project ' +
                              'auction starting date passes',
                      },
                  ]
                : []),
        ],
        primaryActions: [
            {
                name: 'Schedule Post',
                icon: 'fa-flag fa-lg',
                link: `${route}/create`,
                hasPermission: isEditor,
                isActive: status === POST_PENDING,
            },
            {
                name: 'Share Project',
                icon: 'fa-share-square-o',
                action: COPY_LINK,
                hasPermission: true,
                isActive: includes([POST_PENDING, OPEN], status),
            },
            {
                name: 'View Live Auction',
                icon: 'fa-gavel',
                link: `${baseProjectRoute}/reverse-auction/summary`,
                hasPermission: true,
                isActive: status === REVERSE_AUCTION,
            },
            {
                ...createEvaluationAction,
                hasPermission: isEditor,
                isActive: status === PENDING && government.hasEvaluation,
            },
            {
                name: `Unseal ${showBids ? 'Pricing' : 'Bids'}`,
                icon: 'fa-envelope-open',
                action: UNSEAL_BIDS,
                hasPermission: isEditor,
                isActive: includes([PENDING, EVALUATION], status) && !showBidsWithPricing,
            },
        ],
        secondaryActions: [
            {
                name: 'End Auction',
                icon: 'fa-stop-circle',
                action: END_AUCTION_REJECT_RESULTS,
                hasPermission: isEditor,
                isHidden: status !== REVERSE_AUCTION,
            },
            {
                ...inviteCollaboratorsAction,
                hasPermission: true,
            },
            discoverVendorsAction(project),
            {
                name: 'Edit Timeline',
                isHidden: !isComplete,
                icon: 'fa-calendar-o',
                action: TOGGLE_EDIT_TIMELINES_MODAL,
                hasPermission: isEditor,
            },
            {
                name: 'Get Shareable Project Link',
                icon: 'fa-link',
                action: COPY_LINK,
                hasPermission: true,
                isHidden: status === OPEN || !wasPosted,
            },
            {
                action: TOGGLE_SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL,
                name: 'Invite Vendors',
                icon: 'fa-envelope',
                hasPermission: isEditor,
                isHidden: ![OPEN, POST_PENDING].includes(status),
            },
            {
                name: 'Public Display Options',
                icon: 'fa-sliders',
                action: PUBLIC_DISPLAY_OPTIONS,
                hasPermission: isEditor,
                isHidden: !wasPosted,
            },
            {
                name: 'Retract Post',
                icon: 'fa-undo',
                action: RETRACT_POST,
                hasPermission: isBidPoster,
                isHidden: !isBidPoster || (status !== OPEN && status !== PENDING),
            },
            {
                name: 'Create Associated Project',
                icon: 'fa-external-link',
                action: ASSOCIATED_PROJECT,
                hasPermission: isProjectCreator || isBidPoster,
            },
            pauseProjectAction(project, isEditor, isCurrent),
            {
                name: 'Award Project',
                icon: 'fa-trophy',
                action: AWARD_PROJECT_FROM_PENDING,
                hasPermission: isEditor,
                isHidden: status !== PENDING,
            },
            {
                ...closeOutProjectAction,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !isOwnerOrGlobalEditor || !isOpenOrPending,
            },
            {
                name: 'Reopen Project Builder',
                icon: 'fa-undo',
                action: REOPEN_BUILDER,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: project.isPostOnly || !isOwnerOrGlobalEditor || status !== POST_PENDING,
            },
            {
                name: 'Delete Post',
                icon: 'fa-trash',
                action: DELETE_POST,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !project.isPostOnly || !isOwnerOrGlobalEditor || status !== POST_PENDING,
            },
            reopenClosedProjectAction(project, isOwnerOrGlobalEditor, isLastUsed),
            {
                name: '[Demo Only] Skip to Next Stage',
                icon: 'fa-fast-forward',
                action: SKIP_TO_NEXT_STAGE,
                hasPermission: isEditor,
                isHidden:
                    (!__DEMO__ && !__DEV__) ||
                    (status !== OPEN && status !== AUCTION_PENDING && status !== REVERSE_AUCTION),
            },
            resealBidsAction(isOpenGovAdminUser, project),
        ],
    };
}

function evaluationModule(data, props) {
    const {
        isBidPoster,
        isEvaluator,
        isGlobalEditor,
        isOpenGovAdminUser,
        isProjectCreator,
        project,
        user: { government },
    } = data;

    const { isCurrent, isLastUsed, permissions } = props;

    const { isOwner, isEditor, isViewer } = permissions;

    const status = get(project, 'evaluation.status');
    const type = get(project, 'evaluation.type');
    const phaseNumber = get(project, 'evaluation.phaseNumber');
    const hasConsensusEvaluation = get(project, 'evaluation.hasConsensusEvaluation');
    const evaluationAudits = isViewer ? get(project, 'evaluationAudits') : undefined;
    const route = `${getProjectRoutePrefix(project)}/evaluation`;

    const isEvaluationStatus = project.status === EVALUATION;
    const isAwardPendingStatus = project.status === AWARD_PENDING;

    const isReleased = status === RELEASED || status === COMPLETE;
    const isComplete = status === COMPLETE;
    const isLowestPriceType = type === LOWEST_PRICE;
    const isScored = type === SCORED;
    const isLineItemAwardType = type === LINE_ITEM_AWARD;
    const isInitialPhase = phaseNumber === 1;
    const isOwnerOrGlobalEditor = isOwner || isGlobalEditor;

    return {
        ...props,
        links:
            evaluationAudits &&
            evaluationAudits.map((evaluationAudit) => {
                const { phaseNumber: auditPhaseNumber } = evaluationAudit.data.evaluation;
                return {
                    href: {
                        pathname: route,
                        search: createSearchParams({ phase: auditPhaseNumber }).toString(),
                    },
                    text: `Phase ${auditPhaseNumber}`,
                };
            }),
        linksLabel: 'Previous Evaluation Phases:',
        name: 'Evaluations',
        step: 3,
        pages: [
            {
                name: 'Edit<br>Evaluation',
                hidden: !isScored,
                icon: 'fa-pencil',
                link: `${route}/create`,
                hasPermission: isEditor,
                disabled: !isCurrent || isComplete,
                disabledText: 'Evaluation has been completed and can no longer be edited.',
            },
            {
                name: 'View<br>Evaluation',
                hidden: !isScored,
                icon: 'fa-star',
                link: route,
                hasPermission: isViewer,
            },
            {
                name: 'Manage<br>Responses',
                icon: 'fa-envelope',
                link: `${route}/proposals`,
                hasPermission: isViewer,
                disabled: status === EVALUATION_DRAFT && isInitialPhase,
                disabledText:
                    'Complete the set-up of the evaluation before adding and reviewing responses',
            },
            {
                name: 'Evaluators<br>Admin',
                hidden: !isScored,
                icon: 'fa-user-circle-o',
                link: `${route}/evaluators`,
                hasPermission: isViewer,
                disabled: status === EVALUATION_DRAFT,
                disabledText: 'Complete the set-up of the evaluation before managing evaluators',
            },
            {
                name: 'Score<br>Responses',
                hidden: !isScored,
                icon: 'fa-sliders',
                link: `${route}/proposal-evaluations`,
                hasPermission: isEvaluator,
                hasPermissionText:
                    'You were not added as an evaluator, so you have no scorecard to review.',
                disabled: !isReleased,
                disabledText:
                    'Your scorecard will be available once the evaluation has been released',
            },
            {
                name: 'Review All<br>Scorecards',
                hidden: !isScored,
                icon: 'fa-bar-chart',
                link: `${route}/aggregate-evaluations`,
                hasPermission: isEditor || (isViewer && isComplete),
                disabled: !isReleased,
                disabledText: 'Complete the set-up of your evaluation before scoring responses',
            },
            {
                name: 'Consensus<br>Scorecard',
                hidden: !isScored,
                icon: 'fa-sitemap',
                link: `${route}/consensus-scorecard`,
                hasPermission: isViewer,
                disabled: !isReleased || !hasConsensusEvaluation,
                disabledText: 'No consensus scorecard has been created',
            },
            {
                name: 'Bid<br>Tabulations',
                hidden: !isLowestPriceType,
                icon: 'fa-bar-chart',
                link: `${route}/bid-tabulations`,
                hasPermission: isViewer,
            },
            {
                name: 'Line Item Awards',
                hidden: !isLineItemAwardType,
                icon: 'fa-bar-chart',
                link: `${route}/line-item-award`,
                hasPermission: isViewer,
            },
            {
                name: 'Awards by Vendor',
                hidden: !isLineItemAwardType,
                icon: 'fa-star',
                link: `${route}/line-item-award/vendors`,
                hasPermission: isViewer,
            },
            {
                name: 'Selected<br>Vendor',
                icon: 'fa-trophy',
                link: `${route}/selected-proposal`,
                hasPermission: isEditor || (isViewer && isComplete),
                disabled: !isComplete,
                disabledText: 'Vendor has not been selected yet',
            },
        ],
        primaryActions: [
            {
                name: 'Complete Set-Up',
                icon: 'fa-check-square-o fa-lg',
                link: `${route}/create`,
                hasPermission: isEditor,
                isActive: isEvaluationStatus && status === EVALUATION_DRAFT,
            },
            {
                name: 'Release to Evaluators',
                icon: 'fa-send',
                action: RELEASE_EVALUATION,
                hasPermission: isEditor,
                isActive: isEvaluationStatus && status === EVALUATION_OPEN,
            },
            {
                name: 'Award Project',
                icon: 'fa-trophy fa-lg',
                link: (() => {
                    if (isLowestPriceType) return `${route}/bid-tabulations`;
                    if (isScored) return `${route}/aggregate-evaluations`;
                    if (isLineItemAwardType) return `${route}/line-item-award/vendors`;
                })(),
                hasPermission: isEditor,
                isActive: isEvaluationStatus && status === RELEASED,
            },
            {
                name: 'Finalize Award',
                icon: 'fa-check-square-o fa-lg',
                action: FINALIZE_AWARD,
                hasPermission: isEditor,
                isActive: isAwardPendingStatus,
            },
        ],
        secondaryActions: [
            {
                ...inviteCollaboratorsAction,
                hasPermission: true,
            },
            {
                name: 'Add Response',
                icon: 'fa-plus',
                link: `${route}/proposals`,
                hasPermission: isEditor,
                isHidden: !isEvaluationStatus || includes([EVALUATION_DRAFT, COMPLETE], status),
            },
            {
                name: `Unseal ${project.showBids ? 'Pricing' : 'Bids'}`,
                icon: 'fa-envelope-open',
                action: UNSEAL_BIDS,
                hasPermission: isEditor,
                isHidden: project.showBidsWithPricing || !isEvaluationStatus,
            },
            {
                name: 'New Evaluation Phase',
                icon: 'fa-external-link',
                link: `${route}/aggregate-evaluations`,
                hasPermission: isEditor,
                isHidden: !isScored || status !== RELEASED,
            },
            {
                name: 'Create Consensus Scorecard',
                icon: 'fa-sitemap',
                action: CONSENSUS_EVALUATION,
                hasPermission: isEditor,
                isHidden: hasConsensusEvaluation || !isScored || status !== RELEASED,
            },
            {
                name: 'Public Display Options',
                icon: 'fa-sliders',
                action: PUBLIC_DISPLAY_OPTIONS,
                hasPermission: isEditor,
                isHidden: !government.hasSourcing,
            },
            {
                name: 'Create Associated Project',
                icon: 'fa-external-link',
                action: ASSOCIATED_PROJECT,
                hasPermission: isProjectCreator || isBidPoster,
                isHidden: isScored,
            },
            {
                name: 'Edit Timeline',
                isHidden: isComplete,
                icon: 'fa-calendar-o',
                action: TOGGLE_EDIT_TIMELINES_MODAL,
                hasPermission: isEditor,
            },
            {
                name: 'Unaward Project',
                icon: 'fa-undo',
                action: UNAWARD_PROJECT,
                hasPermission: isEditor,
                isHidden: !isEditor || !isComplete,
            },
            pauseProjectAction(project, isEditor, isCurrent),
            {
                ...closeOutProjectAction,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden:
                    !isOwnerOrGlobalEditor || !isCurrent || (isComplete && !isAwardPendingStatus),
            },
            {
                name: 'Delete Evaluation',
                icon: 'fa-trash',
                action: DELETE_EVALUATION,
                hasPermission: isOwnerOrGlobalEditor,
                isHidden: !isOwnerOrGlobalEditor || !isCurrent || isComplete,
            },
            reopenClosedProjectAction(project, isOwnerOrGlobalEditor, isLastUsed, isComplete),
            resealBidsAction(isOpenGovAdminUser, project),
        ],
    };
}

export function intakeModule(data) {
    const {
        project: { moduleData },
    } = data;

    return intakeModuleData(data, moduleData[INTAKE_MODULE]);
}

export function solicitationModules(data) {
    const {
        project: { moduleData },
    } = data;

    const solicitationModuleData = [
        [builderModule, moduleData[BUILDER_MODULE]],
        [sourcingModule, moduleData[SOURCING_MODULE]],
        [evaluationModule, moduleData[EVALUATION_MODULE]],
    ].map(([fn, modData]) => fn(data, modData));

    const isEnabled = solicitationModuleData.some((modData) => modData.isEnabled);
    const isUsed = solicitationModuleData.some((modData) => modData.isUsed);
    const isComplete =
        isUsed &&
        solicitationModuleData.every(
            (modData) => !modData.isEnabled || modData.isSkipped || modData.isComplete
        );
    const isCurrent = isUsed && !isComplete;
    const isSkipped = solicitationModuleData.every((modData) => modData.isSkipped);

    return {
        isEnabled,
        isCurrent,
        isUsed,
        isComplete,
        isSkipped,
        moduleData: solicitationModuleData,
        name: 'Solicitation',
    };
}

export function docBuilderModule(data) {
    const {
        project,
        project: { contractRecords, docBuilderProject, moduleData, type },
        documentsTabEnabled,
    } = data;

    const docBuilderProjectRoute = getProjectRoutePrefix({
        ...project,
        id: docBuilderProject && docBuilderProject.id,
    });

    let name;
    if (type === ADDENDUM) {
        name = 'Addendum Document Builder';
    } else if (type === CONTRACT) {
        name = 'Contract Document Builder';
    } else {
        name = 'Document Builder';
    }

    const docBuilderSecondaryActions = [
        {
            name: 'Go to Project',
            icon: 'fa-folder-open',
            link: docBuilderProjectRoute,
            hasPermission: true,
            isHidden: !docBuilderProject,
        },
        {
            name: 'Go to Contract Record',
            icon: 'fa-file-text',
            link: `/governments/${project.government_id}/contracts/${get(
                contractRecords[0],
                'id'
            )}${documentsTabEnabled ? '/documents' : ''}`,
            hasPermission: true,
            isHidden: !contractRecords[0],
        },
    ];

    const builderModuleData = builderModule(data, moduleData[BUILDER_MODULE]);
    const builderSecondaryActions = builderModuleData.secondaryActions.filter((actionData) => {
        return ![ASSOCIATED_PROJECT, CLOSE_PROJECT].includes(actionData.action);
    });

    return {
        ...builderModuleData,
        secondaryActions: docBuilderSecondaryActions.concat(builderSecondaryActions),
        name,
        step: null,
    };
}

export function contractModule(data) {
    const { contractPath, contracts, hasContracting, isContractAdmin, project } = data;

    const isUsed = contracts.length > 0;
    const isCurrent = contracts.some(
        (contract) => contract.docBuilder && contract.docBuilder.status !== FINAL
    );

    return {
        isEnabled: hasContracting,
        isCurrent,
        isUsed,
        isComplete: isUsed && !isCurrent,
        isSkipped: project.isIntake && project.isIntakeConverted,
        name: 'Contract',
        primaryActions: [
            {
                name: 'Add Contract Record',
                icon: 'fa-plus',
                action: CREATE_CONTRACT_FROM_PROJECT,
                hasPermission: isContractAdmin,
                hasPermissionText: 'Only contract admins can add new contracts',
                isActive: hasContracting,
            },
        ],
        secondaryActions: [
            {
                name: 'View Contracts',
                icon: 'fa-list-ul',
                link: contractPath,
                hasPermission: true,
                isHidden: !hasContracting,
            },
        ],
    };
}
