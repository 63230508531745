import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { form } from './constants';
import { validate } from './validate';
import { setPassword } from '../../../actions/auth';
import { Button, PageTitle } from '../../../components';
import { PasswordFormSection } from '../../../components/forms';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedSetPasswordForm = ({ handleSubmit, invalid }) => {
    const settingPassword = useSelector((state) => state.auth.get('settingPassword'));
    const dispatch = useDispatch();

    const handleSetPassword = (data) => {
        const { newPassword1, newPassword2 } = data;

        dispatch(setPassword(newPassword1, newPassword2));
    };

    const styles = require('./SetPasswordForm.scss');

    return (
        <form onSubmit={handleSubmit(handleSetPassword)}>
            <PageTitle title="Set Password" />
            <div className={styles.passwordContainer}>
                <PasswordFormSection autoFocus disabled={settingPassword} />
            </div>
            <Button block bsStyle="primary" disabled={invalid || settingPassword} type="submit">
                {settingPassword ? 'Saving...' : 'Save New Password'}
            </Button>
        </form>
    );
};

ConnectedSetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
};

export const SetPasswordForm = reduxForm(formConfig)(ConnectedSetPasswordForm);
