import { projectClosedSubstatusesDict } from '@og-pro/shared-config/projects';

const { CANCELED, NO_RESPONSES, NO_VENDOR_SELECTED, OTHER, HANDLED_EXTERNALLY } =
    projectClosedSubstatusesDict;

export const CLOSED_SUBSTATUS_FIELD = 'closedSubstatus';
export const CLOSE_OUT_REASON_FIELD = 'closeOutReason';

export const closeProjectOptions = [
    {
        label: 'Canceled',
        value: CANCELED,
    },
    {
        label: 'No responses',
        value: NO_RESPONSES,
    },
    {
        label: 'No vendor selected',
        value: NO_VENDOR_SELECTED,
    },
    {
        label: 'Other',
        value: OTHER,
    },
];

export const closeIntakeOptions = [
    {
        label: 'Remainder of project handled externally of OpenGov Procurement',
        value: HANDLED_EXTERNALLY,
    },
    {
        label: 'Other',
        value: OTHER,
    },
];
