import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, getFormValues } from 'redux-form';

import { form } from './constants';
import { ProposalCreateSync } from './ProposalCreateSync';
import { getVendor, isUploading } from './selectors';
import { getMappedProposalJS, getProposalDocuments } from '../selectors';
import { getPublicProjectJS } from '../../selectors';
import { showConfirmationModal } from '../../../actions/confirmation';
import {
    confirmAddendum,
    createProposalAttachment,
    deleteProposalAttachment,
    menuActionHandler,
    submitProposal,
    updateProposal,
} from '../../../actions/vendProposals';
import { ProposalCreateForm } from '../../../components/forms';
import { DELETE_PROPOSAL, NO_BID_EXISTING_PROPOSAL } from '../../../constants/proposalActions';

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(form)(state) || {},
        project: getPublicProjectJS(state),
        proposal: getMappedProposalJS(state),
        proposalDocuments: getProposalDocuments(state),
        updateError: state.vendProposals.get('updateProposalError'),
        updating: state.vendProposals.get('updatingProposal'),
        uploading: isUploading(state),
        vendor: getVendor(state),
    };
};

const mapDispatchToProps = {
    confirmAddendum,
    createProposalAttachment,
    deleteProposalAttachment,
    destroy,
    menuActionHandler,
    showConfirmationModal,
    submitProposal,
    updateProposal,
};

// @connect
class ConnectedProposalCreate extends Component {
    static propTypes = {
        confirmAddendum: PropTypes.func.isRequired,
        createProposalAttachment: PropTypes.func.isRequired,
        deleteProposalAttachment: PropTypes.func.isRequired,
        destroy: PropTypes.func.isRequired,
        formValues: PropTypes.object.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            id: PropTypes.number.isRequired,
            vendor_id: PropTypes.number.isRequired,
        }),
        proposalDocuments: PropTypes.array.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        submitProposal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updateProposal: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        uploading: PropTypes.bool.isRequired,
        vendor: PropTypes.object.isRequired,
    };

    componentWillUnmount() {
        this.props.destroy(form);
    }

    attachmentDeleteHandler = (attachId, formKey, index) => {
        const data = { form, formKey, index };
        return this.props.deleteProposalAttachment(this.props.proposal.id, data, attachId);
    };

    attachmentUploadHandler = (proposalDocumentId, formKey, files) => {
        const data = { proposalDocumentId, formKey, form };
        files.forEach((file) =>
            this.props.createProposalAttachment(this.props.proposal.id, data, file)
        );
    };

    confirmAddendumHandler = (addendumId) => {
        const { proposal } = this.props;

        return this.props.confirmAddendum(proposal.id, addendumId);
    };

    deleteHandler = () => {
        const { proposal } = this.props;

        this.props.menuActionHandler(DELETE_PROPOSAL, proposal);
    };

    noBidHandler = () => {
        const { proposal } = this.props;

        const nextRoute = `/vendors/${proposal.vendor_id}/proposals/${proposal.id}`;
        this.props.showConfirmationModal(NO_BID_EXISTING_PROPOSAL, {
            nextRoute,
            proposal,
        });
    };

    saveHandler = () => {
        const { proposal, formValues } = this.props;

        // `ProposalCreateForm` requires a promise be returned
        return this.props.updateProposal(proposal.id, formValues, {
            notify: true,
        });
    };

    submitHandler = (data) => {
        return this.props.submitProposal(this.props.proposal.id, data);
    };

    render() {
        const { project, proposal, proposalDocuments, updateError, updating, uploading, vendor } =
            this.props;

        if (!proposal) return null;

        const companyName = proposal.companyName || 'Unnamed';

        return (
            <>
                <ProposalCreateSync proposalId={proposal.id} />
                <ProposalCreateForm
                    confirmAddendum={this.confirmAddendumHandler}
                    deleteAttachment={this.attachmentDeleteHandler}
                    deleteProposal={this.deleteHandler}
                    form={form}
                    noBidProposal={this.noBidHandler}
                    onSave={this.saveHandler}
                    onSubmit={this.submitHandler}
                    project={project}
                    proposalDocuments={proposalDocuments}
                    proposalFormData={proposal}
                    title={`${companyName} Response`}
                    updateError={updateError}
                    updating={updating}
                    uploadHandler={this.attachmentUploadHandler}
                    uploading={uploading}
                    vendor={vendor}
                />
            </>
        );
    }
}

export const ProposalCreate = connect(mapStateToProps, mapDispatchToProps)(ConnectedProposalCreate);
