import { Lock as LockIcon } from '@mui/icons-material';
import { Box, Chip, Typography } from '@og-pro/ui';
import React from 'react';
import { useSelector } from 'react-redux';

import { requisitionsCreateFormValueSelector } from '../../../helpers';
import { fieldNames } from '../../../constants';

const { ADDITIONAL_VENDOR_NOTES } = fieldNames;

export const AdditionalVendorNotes = () => {
    const additionalVendorNotes = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, ADDITIONAL_VENDOR_NOTES)
    );

    const sharedStyles = require('../../../shared.scss');

    return (
        additionalVendorNotes && (
            <div className={sharedStyles.approvalContainer}>
                <Box>
                    <Box alignItems="center" display="flex" justifyContent="space-between">
                        <Typography variant="h4">
                            Additional Vendor Notes{' '}
                            <span style={{ fontWeight: 'normal' }}>(Optional)</span>
                        </Typography>

                        <Chip
                            icon={<LockIcon />}
                            label="Internal"
                            size="small"
                            sx={{ fontWeight: 'normal' }}
                            variant="tag"
                        />
                    </Box>
                    <Typography>{additionalVendorNotes}</Typography>
                </Box>
            </div>
        )
    );
};
