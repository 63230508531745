import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const HeadingFormatterAllowedVariables = ({ title, variables }) => (
    <Box
        border={`1px solid ${capitalDesignTokens.foundations.colors.gray200}`}
        borderRadius={0.5}
        marginY={3}
        padding={2}
        sx={{
            backgroundColor: capitalDesignTokens.foundations.colors.gray100,
        }}
    >
        <Typography fontWeight={500} mb={1}>
            {title}
        </Typography>
        <Box display="flex" justifyContent="space-between">
            {variables.map(({ variable, subtitle }, i) => (
                <Box key={i}>
                    <Typography color="black" mb={0.5} textTransform="uppercase">
                        {variable || <>&nbsp;</>}
                    </Typography>
                    <Typography fontSize="small">{subtitle}(subtitle)</Typography>
                </Box>
            ))}
        </Box>
    </Box>
);

HeadingFormatterAllowedVariables.propTypes = {
    title: PropTypes.string.isRequired,
    variables: PropTypes.arrayOf(
        PropTypes.shape({
            variable: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        })
    ).isRequired,
};
