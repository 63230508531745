import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { TemplateQuestionsListItem } from './TemplateQuestionsListItem';

export const TemplateQuestionsListDisplay = ({
    onQuestionClick,
    templatesAdminPath,
    templateQuestions,
}) => {
    return (
        <ListGroup>
            {templateQuestions.map((templateQuestion) => {
                return (
                    <ListGroupItem
                        key={templateQuestion.id}
                        onClick={() => onQuestionClick(templateQuestion.id)}
                    >
                        <TemplateQuestionsListItem
                            templateQuestion={templateQuestion}
                            templatesAdminPath={templatesAdminPath}
                        />
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
};

TemplateQuestionsListDisplay.propTypes = {
    onQuestionClick: PropTypes.func.isRequired,
    templateQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
};
