import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Box, Button, Dialog, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { AiImporterDropzone } from './AiImporterDropzone';
import { AiImporterLoading } from './AiImporterLoading';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../../context';
import {
    uploadFileToGenerativeAi,
    setImportedSections,
} from '../../../../../../../../actions/templatesAdmin';
import { form, fieldNames } from '../../../../../forms/constants';

const { PROJECT_SECTIONS } = fieldNames;

export const AiImporterDialog = ({ onClose }) => {
    const { disabled, templateProject } = useContext(TemplateEditV2FunctionsContext);
    const { setActiveSectionId } = useContext(TemplateEditV2NavContext);

    const projectSectionsCount = (
        useSelector((state) => formValueSelector(form)(state, PROJECT_SECTIONS)) || []
    ).length;

    const [filename, setFilename] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);

    const dispatch = useDispatch();

    const handleDrop = async (files) => {
        const file = files[0];
        setLoading(true);
        setLoadingError(null);
        setFilename(file.name);

        try {
            const response = await dispatch(
                uploadFileToGenerativeAi(templateProject.government_id, file)
            );
            const sections = response?.sections;

            if (!sections) {
                throw new Error('Invalid response format');
            }

            dispatch(setImportedSections(sections));
            setActiveSectionId(projectSectionsCount); // Set the first imported section as active
            onClose();
        } catch (error) {
            setLoadingError(error?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            contentProps={{ dividers: true }}
            dialogActions={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                        <Typography component="span" sx={{ fontSize: '8px' }}>
                            Powered by
                        </Typography>
                        <AutoAwesomeIcon
                            sx={{
                                color: capitalDesignTokens.semanticColors.callToAction
                                    .primaryActionDark,
                                fontSize: '14px',
                            }}
                        />
                        <Typography
                            component="span"
                            sx={{
                                color: capitalDesignTokens.semanticColors.callToAction
                                    .primaryActionDark,
                                fontSize: '14px',
                                fontWeight: 700,
                            }}
                        >
                            OG Assist
                        </Typography>
                    </Box>
                    <Button color="secondary" onClick={onClose} variant="text">
                        Cancel
                    </Button>
                </Box>
            }
            dialogTitle={
                <>
                    <Typography sx={{ marginBottom: 1 }} variant="h3">
                        Import Template with AI
                    </Typography>
                    <Typography variant="bodySmall">
                        Format the Word document with proper headings (Header 1, Header 2, etc.) for
                        best results.
                        <br />
                        Documents over 60 pages may need to be split for optimal processing.
                    </Typography>
                </>
            }
            fullWidth
            maxWidth="md"
            onClose={onClose}
            open
        >
            <Box sx={{ paddingY: 2 }}>
                {loading && <AiImporterLoading filename={filename} />}
                {!loading && (
                    <>
                        <AiImporterDropzone disabled={disabled} onDropAccepted={handleDrop} />
                        {loadingError && (
                            <Box
                                component={Typography}
                                sx={(theme) => ({
                                    color: theme.palette.error.main,
                                    paddingTop: 1,
                                })}
                            >
                                {loadingError}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Dialog>
    );
};

AiImporterDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};
