import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle, Box, Typography } from '@og-pro/ui';
import { getFormSyncErrors } from 'redux-form';

import { SDv2Timeline } from '../../../../../../../components/GovApp';
import { ProjectCreateV2FunctionsContext } from '../../../context';

export const Timeline = () => {
    const timezone = useSelector((state) => state.auth.getIn(['user', 'organization', 'timezone']));
    const { array, change, disabled, form, project, showFormErrors } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const hasError = showFormErrors && formErrors.timelineChronologicError;

    return (
        <Box>
            {hasError && (
                <Box sx={{ mb: 2, '& > div': { boxShadow: 'none' } }}>
                    <Alert severity="error" variant="filled">
                        <AlertTitle>Dates are Out of Order</AlertTitle>
                        <Typography>
                            The dates on this page are not in chronological order. Please review and
                            update them so they follow the correct sequence from earliest to latest.
                        </Typography>
                    </Alert>
                </Box>
            )}
            <SDv2Timeline
                array={array}
                change={change}
                disabled={disabled}
                form={form}
                isReverseAuction={project.template.isReverseAuction}
                project={project}
                showDatesByDefault
                showValidation={showFormErrors}
                timezone={timezone}
            />
        </Box>
    );
};
