import { get } from 'lodash';
import { isURL } from 'validator';

import {
    ATTACHMENTS,
    BID_BOND,
    bidBondQuestionnaireFieldNamesDict,
    DATA,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    MULTIPLE_CHOICE,
    NOTARIZE,
    OPTIONS,
    RAW_PROMPT,
    TITLE,
    VALUE,
} from '@og-pro/shared-config/questionnaires';

import { arrayError } from './array';
import { MAX_TEXT_AREA_LENGTH, REQUIRED_TEXT } from './constants';

const { BID_BOND_PROJECT_ID, BID_BOND_SECURITY, BID_BOND_SECURITY_IS_PERCENTAGE } =
    bidBondQuestionnaireFieldNamesDict;

// Props get passed in automatically when the @reduxForm decorator is used.
// The spread syntax is being used here for transparency that those props may exist.
// eslint-disable-next-line no-unused-vars
export function questionnaire(question, { validateBidBond = true, ...props } = {}) {
    const questionErrors = {};

    // Don't validate `isHiddenByLogic` items
    if (question.isHiddenByLogic) {
        return questionErrors;
    }

    if (!question[TITLE]) {
        questionErrors[TITLE] = REQUIRED_TEXT;
    } else if (question[TITLE].length >= 500) {
        questionErrors[TITLE] = 'This field is too long.';
    }

    if (question[RAW_PROMPT] && question[RAW_PROMPT].length >= MAX_TEXT_AREA_LENGTH) {
        questionErrors[RAW_PROMPT] = 'This field is too long.';
    }

    if (question.type === EXTERNAL_FORM) {
        const value = get(question, [DATA, VALUE], '');

        if (
            !isURL(value, { require_protocol: true }) ||
            // NOTE: this covers the majority of DocuSign use cases, but not all. It's impossible to
            // cover all use cases because users can have on-prem solutions in which case the url is
            // determined by the user, not DocuSign. If/when we get a customer who has an on-prem
            // DocuSign installation, we will likely need to update this logic. This logic is also
            // imperfect because a url such as https://test.com/docusign.com would pass. A long-term
            // solution is likely to make a call to the URL and inspect the page for a DocuSign
            // specific element.
            !value.toLowerCase().includes('docusign.') ||
            !value.toLowerCase().includes('?')
        ) {
            questionErrors[DATA] = {
                [VALUE]: 'Must be a valid DocuSign URL, including HTTP(S) and query parameters.',
            };
        }
    } else if (question.type === DATA_VERIFY) {
        const value = get(question, [DATA, VALUE], '');

        if (!isURL(value)) {
            questionErrors[DATA] = {
                [VALUE]: 'Must be a valid URL.',
            };
        }
    } else if (question.type === DOWNLOAD || question.type === NOTARIZE) {
        const attachments = get(question, [DATA, ATTACHMENTS]) || [];

        if (attachments.length === 0) {
            questionErrors[DATA] = 'Must include at least one attachment to download.';
        }
    } else if (question.type === MULTIPLE_CHOICE) {
        const options = get(question, [DATA, OPTIONS]);

        if (!options || options.length < 2) {
            questionErrors[DATA] = {
                [OPTIONS]: arrayError('Must provide at least 2 options.'),
            };
        } else {
            questionErrors[DATA] = {
                [OPTIONS]: options.map((option) => {
                    if (!option || !option.trim()) {
                        return 'Please specify an option.';
                    }
                    return null;
                }),
            };
        }
    } else if (question.type === BID_BOND && validateBidBond) {
        const bidBondProjectId = get(question, [DATA, BID_BOND_PROJECT_ID]);
        const bidSecurity = parseInt(get(question, [DATA, BID_BOND_SECURITY]), 10);
        const bidSecurityIsPercentage = get(question, [DATA, BID_BOND_SECURITY_IS_PERCENTAGE]);

        if (!bidBondProjectId) {
            questionErrors[DATA] = {
                ...questionErrors[DATA],
                [BID_BOND_PROJECT_ID]: 'Must provide Project or Contract ID.',
            };
        }

        if (!bidSecurity || bidSecurity < 0) {
            questionErrors[DATA] = {
                ...questionErrors[DATA],
                [BID_BOND_SECURITY]: 'Bid Security cannot be blank, 0, or less than 0.',
            };
        }

        if (typeof bidSecurityIsPercentage !== 'boolean') {
            questionErrors[DATA] = {
                ...questionErrors[DATA],
                [BID_BOND_SECURITY_IS_PERCENTAGE]: 'Please select Percentage or Amount.',
            };
        }
    }

    return questionErrors;
}
