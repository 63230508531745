import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '../../../Button';
import { UserProfilePicture } from '../../../UserProfilePicture/UserProfilePicture';
import { sendChecklistReminderEmail } from '../../../../actions/checklists';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

export const LastUpdatedDisplay = ({
    checklistId,
    hasVendorAssignedUser,
    lastUpdatedAt,
    lastUpdater,
    questionnaireId,
    showReminderButton,
}) => {
    const dispatch = useDispatch();
    const sendReminderEmail = (e) => {
        e.stopPropagation();

        dispatch(
            showConfirmationSimpleModal(
                () => dispatch(sendChecklistReminderEmail(checklistId, questionnaireId)),
                {
                    btnText: 'Send Reminder Email',
                    bsStyle: 'primary',
                    icon: 'envelope',
                    text: 'Are you sure you want to send a reminder email to the vendor?',
                }
            )
        );
    };

    const styles = require('./index.scss');

    return (
        <>
            {lastUpdater ? (
                <>
                    <UserProfilePicture
                        className={styles.profilePicture}
                        horizontal
                        user={lastUpdater}
                    />
                    <div className={styles.userNameText}>
                        {lastUpdater.displayName}
                        <br />
                        <small>
                            <em>{moment(lastUpdatedAt).fromNow()}</em>
                        </small>
                    </div>
                </>
            ) : (
                <em>No updates yet</em>
            )}
            {showReminderButton && (
                <div>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        componentClass="a"
                        disabled={!hasVendorAssignedUser}
                        onClick={sendReminderEmail}
                        qaTag="checklistItem-sendReminderEmail"
                        tooltip={
                            hasVendorAssignedUser
                                ? undefined
                                : 'Vendor must be assigned to send reminder'
                        }
                        zeroPadding
                    >
                        Send Reminder Email
                    </Button>
                </div>
            )}
        </>
    );
};

LastUpdatedDisplay.propTypes = {
    checklistId: PropTypes.number.isRequired,
    hasVendorAssignedUser: PropTypes.bool,
    lastUpdater: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
    }),
    lastUpdatedAt: PropTypes.string,
    questionnaireId: PropTypes.number.isRequired,
    showReminderButton: PropTypes.bool,
};
