import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RejectionReasonModal } from './RejectionReasonModal';
import { getPendingContractAttachments } from '../selectors';
import { Button } from '../../../Button';
import { ButtonGroup } from '../../../ButtonGroup/ButtonGroup';
import { DisplaySection } from '../../../DisplaySection';
import { FileUploadAttachment } from '../../../FileUploadAttachment';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { contractAttachmentApproval } from '../../../../actions/contracts';
import { getContractJS } from '../../../../containers/selectors';

export const GovernmentPendingDocuments = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const dispatch = useDispatch();

    const contract = useSelector(getContractJS);
    const pendingGroupedContractAttachments = useSelector(getPendingContractAttachments);

    const approvalHandler = (attachmentId, isApproved, data) => {
        setIsSubmitting(true);
        setShowRejectModal(false);
        dispatch(
            contractAttachmentApproval(contract.id, attachmentId, { isApproved, ...data })
        ).finally(() => {
            setIsSubmitting(false);
        });
    };

    const approveAttachment = (attachmentId) => () => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    approvalHandler(attachmentId, true);
                },
                {
                    bsStyle: 'success',
                    btnText: 'Approve',
                    icon: 'check',
                    message: 'Are you sure you want to approve this document?',
                }
            )
        );
    };

    const styles = require('../index.scss');

    if (pendingGroupedContractAttachments.length === 0) {
        return null;
    }

    return (
        <DisplaySection
            header="PENDING DOCUMENTS"
            items={pendingGroupedContractAttachments.map((attachmentGroup) => {
                return {
                    icon: 'clock-o',
                    label: attachmentGroup[0].tags[0].name,
                    value: attachmentGroup.map((attachment, index) => {
                        return (
                            <div className="row" key={attachment.id}>
                                <FileUploadAttachment
                                    attachment={attachment}
                                    className="col-md-8 col-lg-9"
                                    disabled
                                    useListItemStyle
                                />
                                <div className="col-md-4 col-lg-3">
                                    <ButtonGroup bsSize="small">
                                        <Button
                                            className={styles.approvalButton}
                                            disabled={isSubmitting}
                                            onClick={approveAttachment(attachment.id)}
                                            qaTag={`contractDetails-approve${index}`}
                                        >
                                            <span className="text-success">
                                                <i className="fa fa-check-square-o " /> Approve
                                            </span>
                                        </Button>
                                        <Button
                                            className={styles.approvalButton}
                                            disabled={isSubmitting}
                                            onClick={() => setShowRejectModal(true)}
                                            qaTag={`contractDetails-reject${index}`}
                                        >
                                            <span className="text-danger">
                                                <i className="fa fa-ban" /> Reject
                                            </span>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                {showRejectModal && (
                                    <RejectionReasonModal
                                        onHide={() => setShowRejectModal(false)}
                                        onSubmit={(data) =>
                                            approvalHandler(attachment.id, false, data)
                                        }
                                    />
                                )}
                            </div>
                        );
                    }),
                };
            })}
            zeroState="No Pending Documents"
        />
    );
};
