import { projectClosedSubstatusesDict } from '@og-pro/shared-config/projects';

import { CLOSED_SUBSTATUS_FIELD, CLOSE_OUT_REASON_FIELD } from './constants';

const { OTHER } = projectClosedSubstatusesDict;

export function validate(values) {
    const errors = {};

    if (!values[CLOSED_SUBSTATUS_FIELD]) {
        errors[CLOSED_SUBSTATUS_FIELD] = 'Please select an option';
    } else if (values[CLOSED_SUBSTATUS_FIELD] === OTHER) {
        if (!values[CLOSE_OUT_REASON_FIELD]) {
            errors[CLOSE_OUT_REASON_FIELD] = 'Please provide a response';
        } else if (values[CLOSE_OUT_REASON_FIELD].length > 100) {
            errors[CLOSE_OUT_REASON_FIELD] = 'Response is too long (100 character limit)';
        }
    }

    return errors;
}
