import Highcharts from 'highcharts';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import { getVizColors } from '@opengov/capital-style';
import { Box, Button, Typography } from '@og-pro/ui';
import { Download as DownloadIcon } from '@mui/icons-material';

import { Legend } from './Legend';
import { exportArrayToCSV } from '../../../utils';
import { getVendorCertificationsTotalsChartData } from '../../../containers/GovApp/VendorManagement/selectors';
import { vendorClassificationsReport } from '../../../actions/vendorList';
import { getGovernmentId } from '../../../containers/GovApp/selectors';

const CHART_ID = 'subscribed-vendor-certification-chart';

export const SubscribedVendorCertificationChart = () => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const governmentId = useSelector(getGovernmentId);
    const vendorCertificationsTotalsChartData = useSelector(getVendorCertificationsTotalsChartData);

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const isXs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    useEffect(() => {
        if (!vendorCertificationsTotalsChartData?.length) {
            dispatch(vendorClassificationsReport(governmentId));
        }
    }, []);

    const renderChart = () => {
        Highcharts.chart(CHART_ID, {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                height: 364,
            },
            title: {
                text: null,
            },
            series: [
                {
                    type: 'pie',
                    name: null,
                    innerSize: '50%',
                    data: vendorCertificationsTotalsChartData,
                },
            ],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    borderWidth: 3,
                    colors: getVizColors(vendorCertificationsTotalsChartData.length).map(
                        (color) => color.base
                    ),
                    center: ['50%', '50%'],
                    size: '100%',
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            minWidth: 365,
                        },
                        chartOptions: {
                            chart: {
                                width: null,
                            },
                        },
                    },
                ],
            },
            tooltip: {
                pointFormatter() {
                    const { y } = this;
                    if (y > 0) {
                        return `${y} Certification${y > 1 ? 's' : ''}`;
                    }
                    return null;
                },
            },
            legend: {
                enabled: false,
            },
        });
    };

    useEffect(() => {
        if (vendorCertificationsTotalsChartData?.length) {
            renderChart();
        }
    }, [vendorCertificationsTotalsChartData]);

    const handleExportToCSVClick = () => {
        const rows = vendorCertificationsTotalsChartData.map((certification) => {
            return [certification.name, certification.y, certification.percent];
        });

        exportArrayToCSV(
            [['Certification Type', 'Number of Vendors', '% of Total Vendors']].concat(rows),
            { fileName: 'Vendor Certifications by Type', headers: true, timestamp: true }
        );
    };

    return (
        <Box
            className={styles.subscribedVendorCertificationChart}
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                className={styles.header}
                display="flex"
                flexDirection={isXs ? 'column' : 'row'}
                justifyContent="space-between"
            >
                <Box display="flex" flexDirection="column">
                    <Typography variant="h2">Vendor Certifications by Type</Typography>
                    <Typography className={styles.description}>
                        This data is based on vendors subscribed to sourcing opportunities from your
                        organization. This chart is only showing vendors who have identified one or
                        more certifications (verified and/or self reported).
                    </Typography>
                </Box>
                <Button
                    color="secondary"
                    onClick={handleExportToCSVClick}
                    qaTag="vendorCertifications-exportToCsv"
                    variant="outlined"
                >
                    <Box alignItems="center" display="flex" gap={0.5}>
                        <DownloadIcon fontSize="inherit" />
                        Export to CSV
                    </Box>
                </Button>
            </Box>
            <Box display="flex" flexDirection={isMd ? 'column' : 'row'} pb={1} pt={3}>
                <div id={CHART_ID} />
                <Legend
                    mobile={isXs}
                    vendorCertificationsTotalsChartData={vendorCertificationsTotalsChartData}
                />
            </Box>
        </Box>
    );
};
