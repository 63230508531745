import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { ProjectAttachmentBlock } from '../../../../../../components/forms/ProjectAttachmentBlock';
import { onlyWordAndPdfFileTypesAccepted } from '../../../../constants';

const { colors } = capitalDesignTokens.foundations;

const { EXTERNAL_DOCUMENT } = attachmentTypesDict;

export const UploadDocumentAttachments = ({
    disabled,
    disableDropzone,
    form,
    project,
    triggerUpdate,
}) => {
    return (
        <Box>
            <Box
                sx={{ backgroundColor: 'white', border: `1px solid ${colors.gray200}`, padding: 3 }}
            >
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h3">Upload and Manage Your Document</Typography>
                    <Typography color="text.secondary">
                        File type must be Word or PDF. Only one file can be added.
                    </Typography>
                </Box>
                <ProjectAttachmentBlock
                    acceptedFileTypes={onlyWordAndPdfFileTypesAccepted}
                    attachmentListLabel="Uploaded External Document"
                    disabled={disabled}
                    emptyListSubtitle="Add an external document above to see it here"
                    emptyListTitle="No Documents"
                    form={form}
                    formKey={EXTERNAL_DOCUMENT}
                    hideAppendixLetter
                    hideDropzone={disableDropzone}
                    hideNoAttachments
                    hideUpload
                    isOGThemeEnabledForComponents
                    label="Project External Document"
                    listAttachmentExcludeType={Object.values(attachmentTypesDict).filter(
                        (el) => el !== EXTERNAL_DOCUMENT
                    )}
                    listAttachmentType={EXTERNAL_DOCUMENT}
                    projectId={project.id}
                    triggerUpdate={triggerUpdate}
                    uploadAttachmentType={EXTERNAL_DOCUMENT}
                />
            </Box>
        </Box>
    );
};

UploadDocumentAttachments.propTypes = {
    disabled: PropTypes.bool,
    disableDropzone: PropTypes.bool,
    form: PropTypes.object,
    project: PropTypes.shape({
        id: PropTypes.string,
    }),
    triggerUpdate: PropTypes.func,
};
