import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import {
    DialogContent,
    DialogContentText,
    ReduxFormSelect,
    Box,
    DialogActions,
    Button,
} from '@og-pro/ui';

import { formConfig } from './constants';

const TriageApprovalModalForm = ({ handleSubmit, handleClose, nextApprovers, submitFailed }) => {
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <DialogContent sx={{ pt: 1, pb: 1.5 }}>
                <DialogContentText sx={{ p: 0, mb: 2 }}>
                    Before you approve, the next step requires you to pick who needs to review.
                </DialogContentText>
                <Field
                    aria-label="Select Next Primary Reviewer"
                    component={ReduxFormSelect}
                    fullWidth
                    label="Select Next Primary Reviewer"
                    name="selectedApprover"
                    options={nextApprovers}
                    showValidation={submitFailed}
                />
                <DialogContentText sx={{ mt: 3, p: 0 }}>
                    Ready to approve and assign this request?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose} variant="text">
                    Cancel
                </Button>
                <Button type="submit" variant="contained">
                    Approve & Assign
                </Button>
            </DialogActions>
        </Box>
    );
};

TriageApprovalModalForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    nextApprovers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ).isRequired,
    submitFailed: PropTypes.bool.isRequired,
};

export const ReduxTriageApprovalModalForm = reduxForm(formConfig)(TriageApprovalModalForm);
