import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { removeAdHocStep } from '../../../../../../actions/requisitions';
import { getRequisitionJS } from '../../../../../../selectors/govApp';
import { showConfirmationSimpleModal } from '../../../../../../actions/confirmation';
import { getUserJS } from '../../../../../selectors';

const { APPROVED, REJECTED } = approvalStatusTypes;

const shouldShowRemoveAdHocStep = (
    isAdHocStep,
    adHocStepCreator,
    userId,
    isEditing,
    stepStatus
) => {
    if (!isAdHocStep) {
        return false;
    }

    if (adHocStepCreator !== userId) {
        return false;
    }

    if (!isEditing) {
        return false;
    }

    return stepStatus !== APPROVED && stepStatus !== REJECTED;
};

export const StepStatus = ({
    StepStatusComponent,
    adHocStepCreator,
    stepStatus,
    hasToggle,
    isOpen,
    stepId,
    stepName,
    stepNameClassName,
    stepStatusIcon,
    stepStatusIconClassName,
    stepStatusIconText,
    stepStatusText,
    stepStatusTextClassName,
    isAdHocStep,
    isEditing,
}) => {
    const styles = require('./StepStatus.scss');
    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);
    const user = useSelector(getUserJS);

    const isShowRemoveAdHocStep = shouldShowRemoveAdHocStep(
        isAdHocStep,
        adHocStepCreator,
        user.id,
        isEditing,
        stepStatus
    );

    const handleRemoveAdHocStep = (event) => {
        event.stopPropagation();
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    dispatch(removeAdHocStep(requisition.id, stepId));
                },
                {
                    btnText: 'Delete Step',
                    text: `${stepName} will be deleted as a workflow step.\n\nThis action cannot be undone.`,
                    useOpenGovStyle: true,
                    dialogProps: {
                        fullWidth: true,
                    },
                    dialogStyle: 'error',
                    title: 'Are you sure you want to delete this step?',
                }
            )
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.stepName}>
                <div className={classNames(styles.stepNameIconContainer, stepStatusIconClassName)}>
                    {stepStatusIcon ? (
                        <i className={classNames('fa fa-inverse', stepStatusIcon)} />
                    ) : (
                        <span>{stepStatusIconText}</span>
                    )}
                </div>
                <div className={stepNameClassName}>{stepName}</div>
                {hasToggle && !isEditing && (
                    <i
                        className={classNames(
                            `fa fa-fw`,
                            isOpen ? 'fa-angle-up' : 'fa-angle-down',
                            styles.toggle
                        )}
                    />
                )}
                {isShowRemoveAdHocStep && (
                    <IconButton
                        onClick={handleRemoveAdHocStep}
                        size="small"
                        style={{ marginLeft: 'auto' }}
                    >
                        <Icon path={mdiTrashCan} size={1} />
                    </IconButton>
                )}
                {StepStatusComponent}
            </div>
            {stepStatusText && (
                <div className={classNames(styles.stepStatus, stepStatusTextClassName)}>
                    {stepStatusText}
                </div>
            )}
        </div>
    );
};

StepStatus.propTypes = {
    StepStatusComponent: PropTypes.node,
    adHocStepCreator: PropTypes.number,
    stepStatus: PropTypes.number,
    hasToggle: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    stepId: PropTypes.number.isRequired,
    stepName: PropTypes.string.isRequired,
    stepNameClassName: PropTypes.string,
    stepStatusIcon: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusIconClassName: PropTypes.string,
    stepStatusIconText: PropTypes.string, // Either `stepStatusIcon` or `stepStatusIconText` prop is required to be present
    stepStatusText: PropTypes.string,
    stepStatusTextClassName: PropTypes.string,
    isAdHocStep: PropTypes.bool,
    isEditing: PropTypes.bool,
};
