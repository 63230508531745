import { max, sortBy, times } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { reviewTypeOptions } from '@og-pro/shared-config/reviewSequences';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { ReviewSequenceStepPositionsDisplay } from './ReviewSequenceStepPositionsDisplay';
import { StepSectionLayout } from './StepSectionLayout';
import { getPositionOptions } from '../selectors';
import {
    reviewSequenceStepFieldNames,
    reviewSequenceStepPositionFieldNames,
} from '../../../constants';
import {
    HelpIcon,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../../../../components';
import { TRIAGE_BASED_MIN_POSITION_REVIEWS } from '../../../../constants';
import { GroupLabelWithTooltip } from '../../../../../../../../components/SearchSelect/GroupLabelWithTooltip';

const { MIN_POSITION_REVIEWS, POSITION_IDS, REVIEW_SEQUENCE_STEP_POSITIONS, REVIEW_TYPE } =
    reviewSequenceStepFieldNames;

const { POSITION_ID } = reviewSequenceStepPositionFieldNames;

export const ReviewerPositionsSection = ({
    change,
    disabled,
    fieldName,
    isFirstStep,
    isNotifyReviewType,
    minPositionReviews,
    reviewSequenceStepPositions,
    showFormValidation,
}) => {
    const positionOptions = useSelector(getPositionOptions);
    const enableTriageBasedSteps = useFlags(FLAGS.ENABLE_TRIAGE_BASED_STEPS);

    const updateMinPositionReviews = (positionsCount) => {
        if (positionsCount < minPositionReviews) {
            change(`${fieldName}.${MIN_POSITION_REVIEWS}`, max([positionsCount, 1]));
        }
    };

    const updateReviewSequenceStepPositions = (positionIds) => {
        // First attempt to find position in form values, then move to select options if new position
        const positionOptionsToUse = [
            ...reviewSequenceStepPositions,
            ...positionOptions[0].options,
            ...positionOptions[1].options,
        ];
        const reviewSequenceStepPositionsToStore = positionIds.map((positionId) => {
            const reviewSequenceStepPositionData = positionOptionsToUse.find(
                (option) => option.position.id === positionId
            );
            return {
                ...reviewSequenceStepPositionData,
                [POSITION_ID]: reviewSequenceStepPositionData.position.id,
            };
        });
        change(
            `${fieldName}.${REVIEW_SEQUENCE_STEP_POSITIONS}`,
            sortBy(reviewSequenceStepPositionsToStore, ['position.name'])
        );
    };

    const onReviewSequenceStepPositionSelect = (positionIds) => {
        // Update `MIN_POSITION_REVIEWS` form field
        updateMinPositionReviews(positionIds.length);

        // Update `REVIEW_SEQUENCE_STEP_POSITIONS` form field
        updateReviewSequenceStepPositions(positionIds);
    };

    const reviewPositionsCount = max([reviewSequenceStepPositions.length, 0]);
    const minPositionReviewOptions = times(reviewPositionsCount).map((index) => {
        const positionCount = index + 1;
        return {
            label: `${positionCount} of ${reviewPositionsCount} Positions`,
            value: positionCount,
        };
    });

    const positionReviewsBaseProps = {
        component: SearchSelect,
        disabled: reviewSequenceStepPositions.length === 0 || disabled,
        isSearchable: false,
        name: `${fieldName}.${MIN_POSITION_REVIEWS}`,
        placeholder: 'Select number of approvals',
        showValidation: showFormValidation,
    };

    const triageBasedOption = {
        options: [
            {
                label: 'Require Previous Step to Assign Next Primary Reviewer',
                value: TRIAGE_BASED_MIN_POSITION_REVIEWS,
            },
        ],
        label: 'Assign Next Reviewer',
        tooltipText:
            "If 'Require Previous Step to Assign Next Primary Approver' is selected, all Reviewer Positions must be entity positions. The owner of the previous step will be responsible for selecting a specific individual as the primary approver, while the remaining individuals will be designated as alternates.",
    };

    return (
        <StepSectionLayout title="Reviewer Positions">
            {/* `POSITION_IDS` field is for form input UI only and not used by API.
                API reads all data from `REVIEW_SEQUENCE_STEP_POSITIONS` array. */}
            <div className="row">
                <div className="col-md-8 col-lg-9">
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                closeMenuOnSelect={false}
                                component={SearchSelect}
                                disabled={disabled}
                                help="Select the positions that will review this step"
                                isMulti
                                isMultiSimpleValue
                                name={`${fieldName}.${POSITION_IDS}`}
                                onChange={onReviewSequenceStepPositionSelect}
                                options={positionOptions}
                                placeholder="Select review positions"
                                showValidation={showFormValidation}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                backspaceRemovesValue={false}
                                component={SearchSelect}
                                components={{
                                    Option: SearchSelectIconOption,
                                    SingleValue: SearchSelectIconValue,
                                }}
                                disabled={disabled}
                                help="Type of review to perform"
                                isSearchable={false}
                                name={`${fieldName}.${REVIEW_TYPE}`}
                                options={reviewTypeOptions}
                                placeholder="Select review type"
                                showValidation={showFormValidation}
                            />
                        </div>
                        {!isNotifyReviewType && (
                            <div className="col-sm-6">
                                {enableTriageBasedSteps ? (
                                    <Field
                                        {...positionReviewsBaseProps}
                                        formatGroupLabel={(data) => (
                                            <GroupLabelWithTooltip {...data} />
                                        )}
                                        help="Type of assignment"
                                        options={[
                                            {
                                                label: 'Number of Position Approvals',
                                                tooltipText:
                                                    'Determine how many of the positions listed as Reviewer Positions need to take action on this step before moving to the next step in the sequence.',
                                                options: minPositionReviewOptions,
                                            },
                                            ...(isFirstStep ? [] : [triageBasedOption]),
                                        ]}
                                    />
                                ) : (
                                    <Field
                                        {...positionReviewsBaseProps}
                                        help={
                                            <span>
                                                Number of position approvals
                                                <HelpIcon tooltip="Determine how many of the positions listed as Reviewer Positions need to take action on this step before moving to the next step in the sequence." />
                                            </span>
                                        }
                                        options={minPositionReviewOptions}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-4 col-lg-3">
                    <label>Assigned review positions</label>
                    <ReviewSequenceStepPositionsDisplay
                        reviewSequenceStepPositions={reviewSequenceStepPositions}
                    />
                </div>
            </div>
        </StepSectionLayout>
    );
};

ReviewerPositionsSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    isFirstStep: PropTypes.bool,
    isNotifyReviewType: PropTypes.bool,
    minPositionReviews: PropTypes.number.isRequired,
    reviewSequenceStepPositions: PropTypes.array.isRequired,
    showFormValidation: PropTypes.bool,
};
