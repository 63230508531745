import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { CRITERIA_ITEM, fieldNames } from './constants';
import { CriteriaBuilderNeedsReviewModal } from './CriteriaBuilderNeedsReviewModal';
import { CriteriaList } from './CriteriaList';
import { CriteriaNeedsReviewModal } from './CriteriaNeedsReviewModal';
import { getDndStyle } from '../../../constants/styles';
import { sortItemsByManualNumber } from '../../../helpers';

const { ORDER_BY_ID } = fieldNames;

export class CriteriaForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            forEach: PropTypes.func.isRequired,
            get: PropTypes.func.isRequired,
            getAll: PropTypes.func.isRequired,
            length: PropTypes.number,
            move: PropTypes.func.isRequired,
            name: PropTypes.string.isRequired,
            remove: PropTypes.func.isRequired,
            swap: PropTypes.func.isRequired,
        }).isRequired,
        form: PropTypes.string.isRequired,
        hasUpload: PropTypes.bool,
        insertItem: PropTypes.func,
        isCriteriaBuilder: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isSharedSectionForm: PropTypes.bool,
        isTextArea: PropTypes.bool,
        listItemRefs: PropTypes.array,
        meta: PropTypes.shape({
            error: PropTypes.string,
        }).isRequired,
        needsReviewOnly: PropTypes.bool,
        projectSection: PropTypes.object.isRequired,
        renderQuestionLogicIcon: PropTypes.func,
        showArrayError: PropTypes.bool,
        showComments: PropTypes.bool,
        showValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updateListItemRefs: PropTypes.func,
        useManualNumbering: PropTypes.bool,
        useRawDescription: PropTypes.bool,
    };

    static defaultProps = {
        useRawDescription: false,
    };

    constructor(props) {
        super(props);

        /**
         * `formKey` is used to force re-mounting of the form. This is
         * necessary because updating the RichInputText value does not
         * actually update the component. It needs to be unmounted and
         * remounted to render the new state. RichInputText should be
         * refactored to update when `value` changes.
         */
        this.state = { formKey: 0 };
    }

    get styles() {
        return require('./shared.scss');
    }

    handleDragEnd = (result) => {
        const {
            fields: { forEach, move },
            change,
        } = this.props;

        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            move(originLocation, newLocation);

            forEach((name, index) => {
                change(`${name}.${ORDER_BY_ID}`, index + 1);
            });

            // Update formKey to force re-mounting of form components
            this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
        }
    };

    moveField = (index, value) => {
        const { change, fields } = this.props;

        fields.swap(index, index + value);

        fields.forEach((name, i) => {
            change(`${name}.${ORDER_BY_ID}`, i + 1);
        });
    };

    moveFieldUp = (index) => {
        if (index === 0) {
            return;
        }

        this.moveField(index, -1);
    };

    moveFieldDown = (index) => {
        if (index === this.props.fields.length) {
            return;
        }

        this.moveField(index, 1);
    };

    handleInsert = (insertAfterOrderById) => {
        const { insertItem, updateListItemRefs } = this.props;

        insertItem(insertAfterOrderById);

        if (updateListItemRefs) {
            updateListItemRefs();
        }

        // Update formKey to force re-mounting of form components
        this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    sortUsingManualNumbering = (sharedId, originIndex) => {
        // Hack to get re-rendering to work properly
        // Also needed for imported sections to complete updating values before sorting logic runs
        setTimeout(() => {
            const {
                change,
                fields: { getAll, name },
                listItemRefs,
            } = this.props;

            const criteriaItems = getAll();

            const sortedItems = sortItemsByManualNumber([...criteriaItems]); // Sorting `criteriaItems` directly will mutate the redux state

            const newIndex = sortedItems.findIndex((item) => item.sharedId === sharedId);

            if (originIndex !== newIndex) {
                change(
                    name,
                    sortedItems.map((item, idx) => ({ ...item, orderById: idx + 1 }))
                );
                if (listItemRefs) {
                    return listItemRefs[newIndex]?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
        });
    };

    renderArrayError() {
        const {
            meta: { error },
            isOGThemeEnabledForComponents,
            showArrayError,
            showValidation,
        } = this.props;

        if (!showArrayError || !error) {
            return null;
        }

        if (isOGThemeEnabledForComponents) {
            return (
                <div
                    className={classnames(this.styles.emptyError, {
                        [this.styles.error]: showValidation,
                    })}
                >
                    <i className="fa fa-exclamation-triangle" /> One list item is required because
                    this section is set to Admin Only.
                </div>
            );
        }
        return <p className="text-danger text-center">{error}</p>;
    }

    removeField = (index) => {
        const { fields, updateListItemRefs } = this.props;

        fields.remove(index);

        if (updateListItemRefs) {
            updateListItemRefs();
        }

        // Update formKey to force re-mounting of form components
        this.setState((prevState) => ({ formKey: prevState.formKey + 1 }));
    };

    render() {
        const {
            change,
            disabled,
            fields,
            form,
            hasUpload,
            isCriteriaBuilder,
            isTextArea,
            isSharedSectionForm,
            listItemRefs,
            needsReviewOnly,
            projectSection,
            renderQuestionLogicIcon,
            showComments,
            showValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useRawDescription,
        } = this.props;

        const itemRefsProps = {
            listItemRefs,
            sortUsingManualNumbering: this.sortUsingManualNumbering,
        };

        return (
            <>
                {this.renderArrayError()}
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId={form} isDropDisabled={disabled} type={CRITERIA_ITEM}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                <CriteriaList
                                    {...itemRefsProps}
                                    disabled={disabled || snapshot.isDraggingOver}
                                    fields={fields}
                                    formKey={this.state.formKey}
                                    handleInsert={this.handleInsert}
                                    hasUpload={hasUpload}
                                    isCriteriaBuilder={isCriteriaBuilder}
                                    isSharedSectionForm={isSharedSectionForm}
                                    isTextArea={isTextArea}
                                    moveFieldDown={this.moveFieldDown}
                                    moveFieldUp={this.moveFieldUp}
                                    needsReviewOnly={needsReviewOnly}
                                    projectSection={projectSection}
                                    remove={this.removeField}
                                    renderQuestionLogicIcon={renderQuestionLogicIcon}
                                    showComments={showComments}
                                    showValidation={showValidation}
                                    tagOptions={tagOptions}
                                    templateVariableOptions={templateVariableOptions}
                                    useManualNumbering={useManualNumbering}
                                    useRawDescription={useRawDescription}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {isCriteriaBuilder ? (
                    <CriteriaBuilderNeedsReviewModal
                        change={change}
                        disabled={disabled}
                        isSharedSectionForm={isSharedSectionForm}
                        isTextArea={isTextArea}
                        showValidation={showValidation}
                        sortUsingManualNumbering={this.sortUsingManualNumbering}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useManualNumbering={useManualNumbering}
                    />
                ) : (
                    <CriteriaNeedsReviewModal
                        change={change}
                        disabled={disabled}
                        form={form}
                        isTextArea={isTextArea}
                        projectId={projectSection.project_id}
                        projectSection={projectSection}
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useRawDescription={useRawDescription}
                    />
                )}
            </>
        );
    }
}
