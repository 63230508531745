import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { form } from './constants';
import { validate } from './validate';
import * as securityAccountActions from '../../../actions/account/securityInvited';
import { PasswordFormSection } from '../../../components/forms';

const mapStateToProps = (state) => {
    return {
        saving: state.accountSecurity.get('saving'),
    };
};

const mapDispatchToProps = securityAccountActions;

const formConfig = {
    destroyOnUnmount: false,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedSecurityInvited extends Component {
    static propTypes = {
        saving: PropTypes.bool,
        showErrorFields: PropTypes.bool,
    };

    render() {
        const { saving } = this.props;
        return (
            <form>
                <div className="col-xs-12">
                    <PasswordFormSection
                        autoFocus
                        disabled={saving}
                        showErrorFields={this.props.showErrorFields}
                    />
                </div>
            </form>
        );
    }
}

export const SecurityInvited = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedSecurityInvited);
