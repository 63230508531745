import moment from 'moment';
import { orderBy } from 'lodash';
import React from 'react';

import { currencyFormatter } from '../../../../../helpers';
import { HtmlContent } from '../../../../../components/HtmlContent/HtmlContent';

export const getProjectSummaryOverviewData = (project) => {
    const timelineConfig = project.timelineConfig;
    return [
        {
            description: 'Project Title',
            data: project.title,
        },
        {
            description: 'Project ID',
            data: project.financialId,
        },
        {
            description: 'Project Type',
            data: project.template.procurementClassification,
        },
        {
            description: 'Release Date',
            data: moment(project.releaseProjectDate).format('lll'),
        },
        {
            description: 'Initial Bid Date',
            data: moment(project.proposalDeadline).format('lll'),
        },
        {
            description: 'Reverse Auction Start Date',
            data: moment(project.auctionStartDate).format('lll'),
        },
        {
            description: 'Reverse Auction End Date',
            data: moment(project.auctionEndDate).format('lll'),
        },
        {
            description: 'Grace Period',
            data: timelineConfig.auctionExtensionGracePeriod
                ? `${timelineConfig.auctionExtensionGracePeriod} minutes`
                : 'None',
        },
        {
            description: 'Extension Time',
            data: timelineConfig.auctionExtensionTime
                ? `${timelineConfig.auctionExtensionTime} minutes`
                : 'None',
        },
        {
            description: 'Extensions',
            data: project.auctionExtensionCount,
        },
        {
            description: 'Procurement Agent',
            data: project.procurementFullName,
        },
        {
            description: 'Project Description',
            data: <HtmlContent content={project.summary} />,
        },
    ];
};

export const getLumpSumSummaryRowData = (reverseAuctionItems) => {
    const vendorData = {};
    reverseAuctionItems.forEach((itemTable) => {
        itemTable.priceItems.forEach((item) => {
            item.vendorPriceItems.forEach((vendPriceItem) => {
                if (!vendorData[vendPriceItem.proposal.vendor_id]) {
                    vendorData[vendPriceItem.proposal.vendor_id] = {
                        auctionMaxFractionDigits: itemTable.auctionMaxFractionDigits,
                        companyName: vendPriceItem.proposal.companyName,
                        grandTotal: 0,
                    };
                }
                if (!vendPriceItem.noBid) {
                    vendorData[vendPriceItem.proposal.vendor_id].grandTotal +=
                        vendPriceItem.reverseAuctionBids[0].unitPrice * item.quantity;
                }
            });
        });
    });

    return orderBy(vendorData, ['grandTotal'], 'asc').map((datum, idx) => ({
        ...datum,
        rank: idx + 1,
    }));
};

export const lumpSumSummaryColumnDefs = [
    {
        Header: 'Overall Rank',
        id: 'overall-rank',
        tdStyle: { width: 80 },
        accessor: (row) => row.rank,
    },
    {
        Header: <p style={{ textAlign: 'left' }}>Supplier</p>,
        id: 'supplier',
        tdStyle: { textAlign: 'left', width: 410 },
        accessor: (row) => row.companyName,
    },
    {
        Header: <p style={{ textAlign: 'right' }}>Grand Total</p>,
        id: 'grand-total',
        tdStyle: { textAlign: 'right', width: 130 },
        accessor: (row) =>
            currencyFormatter(
                { value: row.grandTotal },
                { maximumFractionDigits: row.auctionMaxFractionDigits, useSameMinAndMax: true }
            ),
    },
];

export const getLineItemSummaryRowData = (priceItem, itemTable) => {
    const vendorData = {};
    priceItem.vendorPriceItems.forEach((vendPriceItem) => {
        const noBid = vendPriceItem.noBid;
        vendorData[vendPriceItem.proposal.vendor_id] = {
            auctionMaxFractionDigits: itemTable.auctionMaxFractionDigits,
            companyName: vendPriceItem.proposal.companyName,
            totalBid: noBid
                ? 'No Bid'
                : vendPriceItem.reverseAuctionBids[0].unitPrice * priceItem.quantity,
            totalBidsPlaced: vendPriceItem.reverseAuctionBids.length,
            unitPrice: noBid ? 'No Bid' : vendPriceItem.reverseAuctionBids[0].unitPrice,
        };
    });
    return orderBy(vendorData, ['unitPrice'], 'asc').map((datum, idx) => ({
        ...datum,
        rank: idx + 1,
    }));
};

const unitCostAndTotalColumnDefs = [
    {
        Header: <p style={{ textAlign: 'right' }}>Unit Cost</p>,
        id: 'unit-cost',
        tdStyle: { textAlign: 'right', width: 100 },
        accessor: (row) =>
            currencyFormatter(
                { value: row.unitPrice },
                { maximumFractionDigits: row.auctionMaxFractionDigits, useSameMinAndMax: true }
            ),
    },
    {
        Header: <p style={{ textAlign: 'right' }}>Total Bid</p>,
        id: 'total-bid',
        tdStyle: { textAlign: 'right', width: 100 },
        accessor: (row) =>
            currencyFormatter(
                { value: row.totalBid },
                { maximumFractionDigits: row.auctionMaxFractionDigits, useSameMinAndMax: true }
            ),
    },
];

export const lineItemSummaryColumnDefs = [
    {
        Header: 'Ranking',
        id: 'ranking',
        tdStyle: { width: 60 },
        accessor: (row) => row.rank,
    },
    {
        Header: <p style={{ textAlign: 'left' }}>Supplier</p>,
        id: 'supplier',
        tdStyle: { textAlign: 'left', width: 290 },
        accessor: (row) => row.companyName,
    },
    ...unitCostAndTotalColumnDefs,
    {
        Header: 'Bids Placed',
        id: 'bids-placed',
        tdStyle: { width: 70 },
        accessor: (row) => row.totalBidsPlaced,
    },
];

export const getLineItemTimelineRowData = (priceItem, priceTable) => {
    const companyNamesDict = {};
    priceItem.vendorPriceItems.forEach((vendPriceItem) => {
        companyNamesDict[vendPriceItem.proposal.vendor_id] = vendPriceItem.proposal.companyName;
    });

    return orderBy(priceItem.reverseAuctionBids, ['created_at'], 'desc').map((bid) => ({
        auctionMaxFractionDigits: priceTable.auctionMaxFractionDigits,
        companyName: companyNamesDict[bid.vendor_id],
        createdAt: bid.created_at,
        totalBid: bid.unitPrice * priceItem.quantity,
        unitPrice: bid.unitPrice,
    }));
};

export const lineItemTimelineColumnDefs = [
    {
        Header: <p style={{ textAlign: 'left' }}>Time</p>,
        id: 'time',
        tdStyle: { textAlign: 'left', width: 100 },
        accessor: (row) => moment(row.createdAt).format('LTS [on] L'),
    },
    {
        Header: <p style={{ textAlign: 'left' }}>Supplier</p>,
        id: 'supplier',
        tdStyle: { textAlign: 'left', width: 320 },
        accessor: (row) => row.companyName,
    },
    ...unitCostAndTotalColumnDefs,
];
