import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { LoadingError, TourButton } from '../../../components';
import { ConnectedClients } from '../../../components/connected';
import { ProjectNavbar } from '../../../components/GovApp';
import {
    getDashboardPath,
    getReviewProjectJS,
    getPostingPath,
    getPublicUrl,
    isGlobalEditorForProject,
    isSubscribed,
    isSourcingEditor,
    isSourcingOwner,
    isSourcingViewer,
} from '../selectors';
import { getUserOrganizationTimezone } from '../../selectors';
import { menuActionHandler as menuAction } from '../../../actions/govProjects';
import { isPurchase, scrollToHashAnchorElement } from '../../../helpers';

const mapStateToProps = (state, props) => {
    return {
        dashboardPath: getDashboardPath(state, props),
        isEditor: isSourcingEditor(state),
        isGlobalEditor: isGlobalEditorForProject(state),
        isOwner: isSourcingOwner(state),
        isViewer: isSourcingViewer(state),
        postingPath: getPostingPath(state, props),
        project: getReviewProjectJS(state),
        publicUrl: getPublicUrl(state, props),
        subscribed: isSubscribed(state),
        timezone: getUserOrganizationTimezone(state),
    };
};

const mapDispatchToProps = {
    menuActionHandler: menuAction,
};

// @connect
class ConnectedProjectPostNav extends Component {
    static propTypes = {
        dashboardPath: PropTypes.string.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isGlobalEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isViewer: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
        }).isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        postingPath: PropTypes.string.isRequired,
        project: PropTypes.object,
        publicUrl: PropTypes.string.isRequired,
        subscribed: PropTypes.bool,
        timezone: PropTypes.string.isRequired,
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    componentDidUpdate() {
        if (this.props.location.hash) {
            scrollToHashAnchorElement(this.props.location.hash);
        }
    }

    menuActionHandler = (type) => {
        return this.props.menuActionHandler(type, this.props.project);
    };

    get navItems() {
        const {
            isViewer,
            location: { pathname },
            postingPath,
            project,
        } = this.props;

        const displayOverviewTab = isPurchase(project.type);

        const navItems = [
            {
                title: 'Project Documents',
                link: `${postingPath}/document`,
                indexRoute: !displayOverviewTab,
                className: 'project-documents',
            },
        ];

        navItems.push({
            title: 'Attachments',
            link: `${postingPath}/attachments`,
            className: 'attachments',
        });

        if (displayOverviewTab) {
            navItems.unshift({
                title: 'Overview',
                link: postingPath,
                indexRoute: true,
            });
        }

        if (project.wasPosted && isViewer) {
            const proposalsPath = `${postingPath}/proposals`;
            navItems.push(
                {
                    title: 'Addenda & Notices',
                    link: `${postingPath}/addenda`,
                    className: 'addenda',
                },
                {
                    title: 'Question & Answer',
                    link: `${postingPath}/q-and-a`,
                    className: 'question-answer',
                },
                {
                    title: 'RSVP Manager',
                    link: `${postingPath}/rsvp-manager`,
                    className: 'rsvp-manager',
                },
                {
                    title: 'Responses',
                    link: proposalsPath,
                    active: !!pathname.match(new RegExp(`^${proposalsPath}`)),
                    className: 'proposals-submitted',
                },
                {
                    title: 'Vendor Analytics',
                    link: `${postingPath}/vendor-analytics`,
                    className: 'vendor-analytics',
                }
            );
        }

        return navItems;
    }

    renderTourButton() {
        const styles = require('./ProjectPostNav.scss');
        return (
            <TourButton
                className={`tutorial-btn ${styles.tourButton}`}
                qaTag="projectPostNav-tour"
                text="Show Tutorial"
            />
        );
    }

    render() {
        const {
            dashboardPath,
            isEditor,
            isGlobalEditor,
            isOwner,
            project,
            publicUrl,
            subscribed,
            timezone,
        } = this.props;

        if (!project) {
            return <LoadingError />;
        }

        const styles = require('./ProjectPostNav.scss');
        return (
            <div>
                <div className={styles.tourButtonContainer}>
                    <ConnectedClients />
                    {this.renderTourButton()}
                </div>
                <ProjectNavbar
                    actionHandler={this.menuActionHandler}
                    dashboardPath={dashboardPath}
                    isEditor={isEditor}
                    isGlobalEditor={isGlobalEditor}
                    isOwner={isOwner}
                    isSourcing
                    navItems={this.navItems}
                    project={project}
                    publicUrl={publicUrl}
                    showShareButtons={project.wasPosted}
                    subscribed={subscribed}
                    timezone={timezone}
                >
                    <Outlet />
                </ProjectNavbar>
            </div>
        );
    }
}

export const ProjectPostNav = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectPostNav);
