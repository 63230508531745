import PropTypes from 'prop-types';
import React from 'react';
import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';
import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { getPrimaryAndAlternateApprovers } from './helpers';
import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const MultiPositionApproval = ({
    minPositionReviews,
    reviewType,
    stepPositionApprovals,
}) => {
    const approvers = stepPositionApprovals.map((stepPositionApproval) => {
        const { position, stepPositionApprovers } = stepPositionApproval;
        const { primaryApprovers, alternateApprovers } =
            getPrimaryAndAlternateApprovers(stepPositionApprovers);

        return {
            ...(primaryApprovers[0] || alternateApprovers[0]).user,
            positionName: position.name,
        };
    });

    const approvalsReceived = stepPositionApprovals.filter(
        ({ status }) => status === approvalStatusTypes.APPROVED
    ).length;
    const approvalsNeeded = minPositionReviews - approvalsReceived;

    const name =
        reviewType === reviewTypes.NOTIFY
            ? `${approvalsReceived} Users Notified`
            : `${approvalsNeeded} of ${minPositionReviews} Approvals Needed`;

    return <StepApprovalGroup approvers={approvers} hasPosition name={name} />;
};

MultiPositionApproval.propTypes = {
    minPositionReviews: PropTypes.number.isRequired,
    reviewType: PropTypes.number.isRequired,
    stepPositionApprovals: PropTypes.arrayOf(
        PropTypes.shape({
            position: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
            stepPositionApprovers: PropTypes.array.isRequired,
        })
    ).isRequired,
};
