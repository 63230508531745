import React, { useCallback, useContext } from 'react';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { projectTypesDict } from '@og-pro/shared-config/projects';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    InputText,
    Label,
    ProjectRequisitionsDisplay,
    RadioGroup,
    SearchSelect,
    Toggle,
} from '../../../../../../../components';

import {
    auctionMaxFractionDigitsOptions,
    budgetFieldNames,
    emergencyOptions,
    fieldNames,
} from './constants';
import { ProjectCreateV2FunctionsContext } from '../../../context';
import { Contacts, Department, ProjectId } from './components';
import { dollarString, limitTextLength } from '../../../../../../../Forms/normalizers';
import { updateProjectCategories } from '../../../../../../../actions/project/create/projectCreate';
import { CategorySelectInput } from '../../../../../../CategorySelect';
import { maskNumberWithCommas } from '../../../../../../../Forms/maskers';
import { MaskedInputText } from '../../../../../../../hocs';
import {
    hasRequisitionSubscription,
    isBudgetUsed as isBudgetUsedSelector,
} from '../../../../../selectors';
import { getContractAttachmentTagSelectOptions } from '../../../../../../selectors';

const {
    AUCTION_MAX_FRACTION_DIGITS,
    ALLOWS_PROXY_BIDDING,
    BUDGET,
    CATEGORIES,
    INTERNAL_NOTES,
    IS_EMERGENCY,
    REQUISITION_IDENTIFIER,
    TITLE,
    TAG_ID,
} = fieldNames;

const { AMOUNT, DESCRIPTION, IDENTIFIER } = budgetFieldNames;

export const ProjectInformation = () => {
    const {
        change,
        disabled,
        form,
        isBudgetRequired,
        project,
        showFormErrors,
        showContractCompiler,
    } = useContext(ProjectCreateV2FunctionsContext);

    const attachmentTags = useSelector(getContractAttachmentTagSelectOptions);
    const isBudgetUsed = useSelector(isBudgetUsedSelector);
    const hasRequisitions = useSelector(hasRequisitionSubscription);

    const documentsTabEnabled = useFlags(FLAGS.ENABLE_CONTRACT_DOCUMENTS_TAB);

    const dispatch = useDispatch();
    const updateCategories = (e, categories) => {
        return dispatch(updateProjectCategories(project.id, categories));
    };

    const styles = require('../../shared.scss');
    const { container, row, section, sectionHelpText } = styles;

    const MaskedBudgetInput = useCallback(MaskedInputText(InputText), []);

    return (
        <Box className={container}>
            <Box className={section}>
                <h3>General Information</h3>
                <Box mb={3}>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Title"
                        name={TITLE}
                        qaTag={`projectInformationForm-${TITLE}`}
                        showValidation={showFormErrors}
                        type="text"
                        useOpenGovStyle
                    />
                </Box>
                <Box mb={3}>
                    <Department
                        disabled={disabled}
                        form={form}
                        project={project}
                        showValidation={showFormErrors}
                    />
                </Box>
                {project.type === projectTypesDict.CONTRACT && documentsTabEnabled && (
                    <Box mb={3}>
                        <Field
                            aria-label="Contract Document Type"
                            component={SearchSelect}
                            disabled={disabled}
                            label="Contract Document Type"
                            name={TAG_ID}
                            options={attachmentTags}
                            placeholder="Select"
                            qaTag="contract-document-type"
                            showValidation={showFormErrors}
                            useOpenGovStyle
                        />
                    </Box>
                )}
                <Box mb={1}>
                    <ProjectId
                        change={change}
                        disabled={disabled}
                        form={form}
                        project={project}
                        showValidation={showFormErrors}
                    />
                </Box>
                <Box mb={3}>
                    {hasRequisitions ? (
                        <ProjectRequisitionsDisplay
                            disabled={disabled}
                            isEditor
                            label={<Label label="Request(s)" />}
                            projectId={project.id}
                        />
                    ) : (
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label="Request ID (optional)"
                            name={REQUISITION_IDENTIFIER}
                            normalize={limitTextLength(128)}
                            qaTag={`projectInformationForm-${REQUISITION_IDENTIFIER}`}
                            showValidation={showFormErrors}
                            type="text"
                            useOpenGovStyle
                        />
                    )}
                </Box>
                <Box mb={3}>
                    <Field
                        component={CategorySelectInput}
                        disabled={disabled}
                        maxLength={110}
                        name={CATEGORIES}
                        onChange={updateCategories}
                        useOpenGovStyle
                        useSingleCodeSet
                    />
                </Box>
                <Box className={classnames(row, styles.inlineRadioInput)}>
                    <Field
                        component={RadioGroup}
                        disabled={disabled}
                        groupLabel={IS_EMERGENCY}
                        inline
                        label="Are you purchasing an emergency good or service?"
                        name={IS_EMERGENCY}
                        options={emergencyOptions}
                        showValidation={showFormErrors}
                        useOpenGovStyle
                    />
                </Box>
            </Box>
            <Box className={section}>
                <h3>{project?.type === projectTypesDict.CONTRACT ? '' : 'Project'} Contacts</h3>
                <Contacts disabled={disabled} form={form} showValidation={showFormErrors} />
            </Box>
            {project.template.isReverseAuction && (
                <Box className={section}>
                    <h3>Reverse Auction Settings</h3>
                    <Box>
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Set the amount all bids must be a multiple of."
                            isSearchable={false}
                            label="Bid Denomination"
                            name={AUCTION_MAX_FRACTION_DIGITS}
                            options={auctionMaxFractionDigitsOptions}
                            showValidation={showFormErrors}
                            useOpenGovStyle
                        />
                    </Box>
                    <Box>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Allow vendors to set automated bids during a reverse auction."
                            label="Proxy Bids"
                            leftLabel={null}
                            name={ALLOWS_PROXY_BIDDING}
                            qaTag={`projectInformationForm-${ALLOWS_PROXY_BIDDING}`}
                            rightLabel="Allow Proxy Bidding"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}

            {isBudgetUsed && project.type !== projectTypesDict.CONTRACT && (
                <Box className={section}>
                    <h3>Budget Information</h3>
                    <p className={sectionHelpText}>Internal Use Only (Not Displayed Publicly)</p>
                    <Box mb={3}>
                        <Field
                            component={MaskedBudgetInput}
                            disabled={disabled}
                            inputGroupPrefix="$"
                            label={`Budget Amount${isBudgetRequired ? '' : ' (optional)'}`}
                            mask={maskNumberWithCommas}
                            name={`${BUDGET}.${AMOUNT}`}
                            normalizer={dollarString}
                            qaTag="projectInformationForm-budgetAmount"
                            showValidation={showFormErrors}
                            type="text"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box mb={3}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Account String/Number of the budget item"
                            label={`Budget Account${isBudgetRequired ? '' : ' (optional)'}`}
                            name={`${BUDGET}.${IDENTIFIER}`}
                            normalize={limitTextLength(64)}
                            qaTag="projectInformationForm-budgetAccountNumber"
                            showValidation={showFormErrors}
                            type="text"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Description of the budget account"
                            label={`Budget Description${isBudgetRequired ? '' : ' (optional)'}`}
                            name={`${BUDGET}.${DESCRIPTION}`}
                            normalize={limitTextLength(256)}
                            qaTag="projectInformationForm-budgetAccountDescription"
                            showValidation={showFormErrors}
                            type="text"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
            {showContractCompiler && (
                <Box className={section}>
                    <h3>Notes</h3>
                    <Box mb={3}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            help="Notes are for internal use only. They will not be made public."
                            label={null}
                            minRows={3}
                            name={INTERNAL_NOTES}
                            normalize={limitTextLength(1000)}
                            qaTag="projectInformationForm-internalNotes"
                            showValidation
                            type="textarea"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};
