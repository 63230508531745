import PropTypes from 'prop-types';
import React from 'react';
import { Settings as SettingsIcon } from '@mui/icons-material';

import { Box } from '@og-pro/ui';
import { Field } from 'redux-form';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { SectionSettings, SectionTitleFormGroup, FormGroup, SwitchWithLabel } from '../components';
import { fieldNames } from '../../constants';
import { qaTagName } from '../../../constants';

const lineStyle = {
    borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
};

const { SECTION_INSTRUCTIONS_VENDOR, SECTION_NAME_VENDOR, INCLUDE_PAVILION_SUGGESTIONS } =
    fieldNames;

export const VendorSectionSettings = React.forwardRef(({ disabled, onClose, title }, ref) => {
    const isPavilionSearchEnabled = useFlags(FLAGS.ENABLE_EMBEDDED_PAVILION_SEARCH);

    return (
        <SectionSettings disabled={disabled} onClose={onClose} ref={ref} title={title}>
            <SectionTitleFormGroup
                disabled={disabled}
                sectionInstructionsField={SECTION_INSTRUCTIONS_VENDOR}
                sectionNameField={SECTION_NAME_VENDOR}
            />
            {isPavilionSearchEnabled && (
                <>
                    <Box sx={lineStyle} />
                    <FormGroup Icon={SettingsIcon} title="Settings">
                        <Box>
                            <Field
                                component={SwitchWithLabel}
                                disabled={disabled}
                                label="Include Pavilion Suggestions"
                                name={INCLUDE_PAVILION_SUGGESTIONS}
                                qaTag={`${qaTagName}-${INCLUDE_PAVILION_SUGGESTIONS}`}
                            />
                        </Box>
                    </FormGroup>
                </>
            )}
        </SectionSettings>
    );
});

VendorSectionSettings.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
