import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { ReduxFormAutocomplete } from '@og-pro/ui';

import { Typography } from '@mui/material';

import { updatePriceItemVendors } from '../helpers';
import { fieldNames } from '../../../constants';
import { getLineItemOptions, requisitionsCreateFormValueSelector } from '../../../selectors';
import { qaTagPageName } from '../../../../constants';

const { ASSOCIATED_LINE_ITEMS, PRICE_TABLE } = fieldNames;

export const AssociatedLineItems = ({
    change,
    disabled,
    fieldName,
    fields,
    index,
    showFormValidation,
}) => {
    const dispatch = useDispatch();

    const associatedLineItems = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${fieldName}.${ASSOCIATED_LINE_ITEMS}`)
    );
    const lineItemOptions = useSelector(getLineItemOptions);
    const priceTable = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PRICE_TABLE)
    );
    const vendor = fields.get(index);

    // keeps vendor's associated line items in sync with line item's associated vendors
    useEffect(() => {
        if (associatedLineItems) {
            updatePriceItemVendors(
                dispatch,
                change,
                priceTable.priceItems,
                associatedLineItems,
                vendor
            );
        }
    }, [associatedLineItems, change, dispatch, priceTable.priceItems, vendor]);

    return (
        <Field
            component={ReduxFormAutocomplete}
            disabled={disabled}
            isSearchable={false}
            label={<Typography variant="h5">Associated Line Items</Typography>}
            multiple
            name={`${fieldName}.${ASSOCIATED_LINE_ITEMS}`}
            options={lineItemOptions}
            placeholder="Search line items"
            qaTag={`${qaTagPageName}-associatedLineItems`}
            serializeAsString={false}
            showValidation={showFormValidation}
        />
    );
};

AssociatedLineItems.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showFormValidation: PropTypes.bool,
};
