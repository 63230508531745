import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Box, Button as MuiButton, Dialog, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getVendorLists } from '../selectors';
import { Button, InputText, SearchSelect } from '../../../../components';
import { VendorSearchInput } from '../../../../components/vendors';
import {
    addOrRemoveVendors,
    createOrUpdateVendorList,
    toggleAddVendorsToListModal,
} from '../../../../actions/vendorList';

const { VENDOR_LIST_ID, USER_IDS, NAME, SEARCH_PARAMS } = fieldNames;

const mapStateToProps = (state) => {
    return {
        addingVendorsToList: state.vendorList.get('addingVendorsToList'),
        vendorLists: getVendorLists(state),
    };
};

const mapDispatchToProps = {
    hideModal: toggleAddVendorsToListModal,
    addVendors: addOrRemoveVendors,
    createAndAddVendors: createOrUpdateVendorList,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedAddVendorsToListModal extends Component {
    static propTypes = {
        addVendors: PropTypes.func.isRequired,
        addingVendorsToList: PropTypes.bool.isRequired,
        change: PropTypes.func.isRequired,
        createAndAddVendors: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        initialValues: PropTypes.object,
        invalid: PropTypes.bool.isRequired,
        noUserSelection: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        useOpenGovStyle: PropTypes.bool,
        useOpenSearch: PropTypes.bool,
        vendorLists: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.number,
            })
        ),
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);
        this.state = {
            create: false,
            stateKey: 1,
        };
    }

    onMenuClose = () => {
        // Trigger a re-render to clear search text input after menu is closed
        this.setState((prevState) => ({ ...prevState, stateKey: prevState.stateKey + 1 }));
    };

    addVendors = (rawData) => {
        const {
            addVendors,
            createAndAddVendors,
            governmentId,
            hideModal,
            useOpenGovStyle,
            useOpenSearch,
        } = this.props;

        const { pendingUsers, users } = rawData[USER_IDS].reduce(
            (acc, item) => {
                if (item.user?.isPendingUser) {
                    acc.pendingUsers.push(item);
                } else {
                    acc.users.push(item);
                }
                return acc;
            },
            { pendingUsers: [], users: [] }
        );

        hideModal();

        if (this.state.create) {
            createAndAddVendors(governmentId, {
                name: rawData[NAME],
                userIds: users.map((item) => item.value || item),
                searchParams: rawData[SEARCH_PARAMS],
            });
        } else {
            addVendors({
                actionType: 'add',
                governmentId,
                opts: { useOpenGovStyle, useOpenSearch },
                pendingUserIds: pendingUsers.map((item) => item.value || item),
                searchParams: rawData[SEARCH_PARAMS],
                userIds: users.map((item) => item.value || item),
                vendorListId: rawData[VENDOR_LIST_ID],
            });
        }
    };

    renderButtons() {
        const { addingVendorsToList, hideModal, invalid, useOpenGovStyle } = this.props;

        if (useOpenGovStyle) {
            return (
                <Box columnGap={1} display="flex" justifyContent="flex-end" mt={3}>
                    <MuiButton
                        color="secondary"
                        disabled={addingVendorsToList}
                        onClick={hideModal}
                        qaTag="addVendorsToListModal-cancel"
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        color="primary"
                        disabled={invalid || addingVendorsToList}
                        qaTag="addVendorsToListModal-addVendors"
                        type="submit"
                        variant="contained"
                    >
                        Add Vendors
                    </MuiButton>
                </Box>
            );
        }

        return (
            <div className="row">
                <div className="col-sm-6 text-left">
                    <Button
                        disabled={addingVendorsToList}
                        onClick={() => {
                            this.props.change(VENDOR_LIST_ID, null);
                            this.setState(
                                (prevState) => ({ create: !prevState.create }),
                                () => this.props.reset()
                            );
                        }}
                        qaTag="addVendorsToListModal-createNewList"
                    >
                        {this.state.create ? 'Select Existing List' : 'Create New List'}
                    </Button>
                </div>
                <div className="col-sm-6 text-right">
                    <Button
                        disabled={addingVendorsToList}
                        onClick={hideModal}
                        qaTag="addVendorsToListModal-cancel"
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        bsStyle="primary"
                        disabled={invalid || addingVendorsToList}
                        qaTag="addVendorsToListModal-addVendors"
                        type="submit"
                    >
                        <i className="fa fa-plus" /> Add Vendors
                    </Button>
                </div>
            </div>
        );
    }

    renderBody() {
        const {
            addingVendorsToList,
            handleSubmit,
            initialValues,
            noUserSelection,
            useOpenGovStyle,
            vendorLists,
        } = this.props;

        const renderListNameOrSelect = () => {
            if (initialValues.vendorListId) {
                if (useOpenGovStyle) {
                    return (
                        <Box mb={2}>
                            <Typography
                                sx={{ fontWeight: 500, color: tokens.colors.colorGray1000 }}
                            >
                                Vendor List
                            </Typography>
                            <Typography>
                                {
                                    vendorLists.find(
                                        (item) => item.id === initialValues.vendorListId
                                    ).name
                                }
                            </Typography>
                        </Box>
                    );
                }
                return (
                    <p>{vendorLists.find((item) => item.id === initialValues.vendorListId).name}</p>
                );
            }

            return (
                <Field
                    component={SearchSelect}
                    disabled={addingVendorsToList}
                    isClearable
                    label="Vendor List"
                    name={VENDOR_LIST_ID}
                    options={vendorLists.map((item) => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })}
                    placeholder="Select a Vendor List"
                    style={{ zIndex: 1000 }}
                    useOpenGovStyle={useOpenGovStyle}
                />
            );
        };

        return (
            <form onSubmit={handleSubmit(this.addVendors)}>
                {this.state.create ? (
                    <Field
                        className={useOpenGovStyle ? this.styles.useOpenGovStyle : ''}
                        component={InputText}
                        disabled={addingVendorsToList}
                        help="Enter a name for the list"
                        label="List Name"
                        name={NAME}
                        placeholder="Vendor List Name"
                        type="text"
                        useOpenGovStyle={useOpenGovStyle}
                    />
                ) : (
                    renderListNameOrSelect()
                )}
                {!noUserSelection && (
                    <Field
                        closeMenuOnSelect={false}
                        component={VendorSearchInput}
                        disabled={addingVendorsToList}
                        filterPendingUsers={!useOpenGovStyle} // Only supported for vendor list management v2
                        helpText="Enter vendor names to add to the list"
                        isClearable
                        isMulti
                        key={this.state.stateKey}
                        label={<Typography sx={{ fontWeight: 500 }}>Vendors</Typography>}
                        menuPortalTarget={document.body}
                        name={USER_IDS}
                        onMenuClose={this.onMenuClose}
                        optionValueField="userId"
                        useOpenGovStyle={useOpenGovStyle}
                    />
                )}
                {this.renderButtons()}
            </form>
        );
    }

    render() {
        const { hideModal, useOpenGovStyle } = this.props;

        if (useOpenGovStyle) {
            return (
                <Dialog
                    allowOverflowedContent
                    className={this.styles.useOpenGovStyle}
                    dialogTitle="Add Vendors to List"
                    fullWidth
                    maxWidth="md"
                    onClose={hideModal}
                    open
                >
                    {this.renderBody()}
                </Dialog>
            );
        }

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Add Vendors to List</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const AddVendorsToListModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedAddVendorsToListModal);
