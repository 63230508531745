import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { tagTypesDict } from '@og-pro/shared-config/tags';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { ContractContactsForm } from './ContractContactsForm';
import { fieldNamesDict } from './constants';
import { getTagsManagementPath, isContractEditor } from '../selectors';
import { getContractJS, isSystemAdminUser } from '../../selectors';
import { updateContractContacts } from '../../../actions/contracts';
import { Main, SectionTitle } from '../../../components';
import { AdminUserListButton } from '../../../components/connected';

const { CONTACTS } = fieldNamesDict;

const { CONTACT } = tagTypesDict;

const { IS_SYSTEM_ADMIN } = userRolePermissions;

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        isEditor: isContractEditor(state),
        isSystemAdmin: isSystemAdminUser(state),
        managingContacts: state.contracts.get('managingContacts'),
        tagsManagementPath: getTagsManagementPath(state),
    };
};

const mapDispatchToProps = {
    updateContractContacts,
};

// @connect
class ConnectedContractContacts extends Component {
    static propTypes = {
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        isEditor: PropTypes.bool,
        isSystemAdmin: PropTypes.bool,
        managingContacts: PropTypes.bool,
        tagsManagementPath: PropTypes.string.isRequired,
        updateContractContacts: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditor: false,
        isSystemAdmin: false,
        managingContacts: false,
    };

    handleSubmit = (data) => {
        const { contract } = this.props;

        if (!data || !data[CONTACTS]) {
            return;
        }

        this.props.updateContractContacts(contract.id, data[CONTACTS]);
    };

    renderInfo() {
        const { isSystemAdmin, tagsManagementPath } = this.props;

        return (
            <>
                Add contract contacts here.
                <br />
                {isSystemAdmin ? (
                    <>
                        You can add more contact types in the&nbsp;
                        <Link to={`${tagsManagementPath}?type=${CONTACT}`}>
                            Tags Management dashboard
                        </Link>
                        .
                    </>
                ) : (
                    <>
                        If a contact type is missing,&nbsp;
                        <AdminUserListButton
                            permissionType={IS_SYSTEM_ADMIN}
                            text="ask a system admin"
                        />
                        &nbsp;to add it in the Tags Management dashboard
                    </>
                )}
            </>
        );
    }

    render() {
        const { isEditor, managingContacts } = this.props;

        return (
            <Main>
                <SectionTitle info={this.renderInfo()} title="Contacts" />
                <div className="row">
                    <div className="col-lg-offset-1 col-lg-10">
                        <ContractContactsForm
                            disabled={managingContacts || !isEditor}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </div>
            </Main>
        );
    }
}

export const ContractContacts = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractContacts);
