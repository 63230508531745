import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { VendorListCategorySearchForm } from '../VendorListCategorySearchForm';
import { loadVendorList, searchVendorList } from '../../../actions/vendorList';

export const VendorListSearch = ({ governmentId, toggleCategorySearch, vendorListId }) => {
    const [searching, setSearching] = useState(false);
    const [searchError, setSearchError] = useState(null);

    const dispatch = useDispatch();

    const handleCategorySubmit = (formData) => {
        const categories = (formData.categories || []).map(({ code }) => code);
        setSearching(true);
        setSearchError(null);

        return dispatch(searchVendorList(governmentId, vendorListId, { categories }))
            .catch((e) => {
                setSearchError(e.message);
            })
            .finally(() => {
                setSearching(false);
            });
    };

    const clearCategorySearch = () => {
        dispatch(loadVendorList(governmentId, vendorListId));
        toggleCategorySearch();
    };

    return (
        <div className="row">
            <div className="col-xs-12 text-left">
                <VendorListCategorySearchForm
                    active
                    clearSearch={clearCategorySearch}
                    disabled={searching}
                    error={searchError}
                    loading={searching}
                    onSubmit={handleCategorySubmit}
                />
            </div>
        </div>
    );
};

VendorListSearch.propTypes = {
    governmentId: PropTypes.number.isRequired,
    toggleCategorySearch: PropTypes.func.isRequired,
    vendorListId: PropTypes.number.isRequired,
};
