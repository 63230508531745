import { QUESTIONNAIRE } from './constants';
import { questionnaireResponseComplete } from '../../../../../Forms/validation';

export const validate = (values) => {
    if (values[QUESTIONNAIRE]) {
        return {
            [QUESTIONNAIRE]: questionnaireResponseComplete(values[QUESTIONNAIRE]),
        };
    }
};
