import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector, getFormSyncErrors } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { BaseProjectSectionsNav } from '../../../../../../components/SDv2/ProjectSectionsNav';
import { form } from '../../../forms/constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../context';
import { getProcessInformationSections } from '../../helpers';
import { fieldNames as formFieldNames } from '../../../forms/TemplateForm/constants';

const selector = formValueSelector(form);

export const TemplateProcessInformationNav = () => {
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));
    const useExternalDocument = useSelector((state) =>
        selector(state, formFieldNames.USE_EXTERNAL_DOCUMENT)
    );
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { showFormValidation, updateTemplateIfDirty, templateProject } = useContext(
        TemplateEditV2FunctionsContext
    );
    const eSignExternalAgreementEnabled = useFlags(FLAGS.ENABLE_E_SIGN_EXTERNAL_AGREEMENT);

    const sections = getProcessInformationSections({
        eSignExternalAgreementEnabled,
        useExternalDocument,
        templateProject,
        activeSectionId,
        setActiveSectionId: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        formErrors,
        showFormValidation,
    });

    return <BaseProjectSectionsNav sections={sections} />;
};
