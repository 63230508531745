const { groupBy } = require('lodash');

const { listToDict } = require('../helpers');

const DRAFT = 100;
const DRAFT_REJECTED = 110;
const REVIEW = 200;
const REVIEW_REJECTED = 210;
const CLOSED_PO_CREATED = 300;
const CLOSED_CANCELLED = 310;
const CLOSED_REJECTED = 320;

exports.statusTypes = {
    DRAFT,
    DRAFT_REJECTED,
    REVIEW,
    REVIEW_REJECTED,
    CLOSED_PO_CREATED,
    CLOSED_CANCELLED,
    CLOSED_REJECTED,
};

exports.isRequisitionDraft = (status) => {
    return status < REVIEW;
};

exports.isRequisitionRejected = (status) => {
    return status === DRAFT_REJECTED || status === REVIEW_REJECTED;
};

exports.isRequisitionSubmitted = (status) => {
    return status >= REVIEW && status < CLOSED_PO_CREATED;
};

exports.isRequisitionClosed = (status) => {
    return status >= CLOSED_PO_CREATED;
};

exports.isRequisitionCancellable = (status) => {
    return status > DRAFT && status < CLOSED_PO_CREATED;
};

exports.isPoCreated = (status) => {
    return status === CLOSED_PO_CREATED;
};

exports.isRequisitionClosedCancelledOrRejected = (status) => {
    return status === CLOSED_CANCELLED || status === CLOSED_REJECTED;
};

exports.approvalStatusTypes = {
    REJECTED: -1,
    PENDING: 0,
    APPROVED: 1,
};

exports.reviewStatusTypes = {
    PAST: -1,
    CURRENT: 0,
    UPCOMING: 1,
};

const requisitionTabs = ['myApprovals', 'myRequests', 'allRequests'];

exports.requisitionTabsNames = listToDict(requisitionTabs);

const requisitionQuickFilters = [
    'inProcess',
    'recentlyClosed',
    'thisFiscalYear',
    'all',
    'actionNeeded',
    'drafts',
];

exports.requisitionQuickFiltersNames = listToDict(requisitionQuickFilters);

const requisitionSearchModes = [
    'basic', // Basic search with no relational requisition data included
    'dashboard', // (default) Search that is purpose built for the requisitions dashboard
];

exports.requisitionSearchModesNames = listToDict(requisitionSearchModes);

exports.vendorSelectionStates = {
    NO_VENDORS: 'noVendors',
    PROPOSED_VENDORS: 'proposedVendors',
    SELECTED_VENDORS: 'selectedVendors',
};

// Purchase details modes. These modes determine how the purchase details are displayed in the
// requisition form. Under the hood, the AMOUNT_ONLY mode will still use (ONE) line item, but just
// display the total amount.
exports.purchaseDetailsModes = {
    AMOUNT_ONLY: 0,
    LINE_ITEMS: 1,
};

exports.purchaseDetailsModeValues = Object.values(exports.purchaseDetailsModes);

// Specifies the options available for configuring the purchase details section on the requisition.
exports.purchaseDetailsModeOptions = {
    ...exports.purchaseDetailsModes,
    ANY: 100,
};

const requisitionFilters = [
    'lastAction',
    'creators',
    'on',
    'before',
    'after',
    'start',
    'end',
    'reviewGroups',
    'exceptionReviewSequences',
    'sourcingStatus',
    'requestTypes',
];

exports.requisitionFiltersNames = listToDict(requisitionFilters);

const purchaseOrderTypes = ['BLANKET', 'REGULAR'];

exports.purchaseOrderTypesDict = listToDict(purchaseOrderTypes);

const requisitionSortingOptions = [
    'creatorLastName',
    'submittedAt',
    'descriptionOfRequest',
    'identifier',
    'activeDate',
    'stepName',
];

exports.requisitionSortingOptionsNames = listToDict(requisitionSortingOptions);

exports.REQUISITIONS_LIMIT = 25;
exports.DEFAULT_NUMBER_OF_REQUESTS_PERIODS = [2, 5, 10, 15, 20]; // In days

/**
 * Groups an array of filter objects by the 'filter' property and creates an array of grouped objects.
 * Each grouped object contains the 'filter' property and an array of 'value' properties.
 *
 * @param {Array} filtersArray - The array of filter objects to be grouped.
 * @returns {Array} An array of grouped objects, each containing 'filter' and 'value' properties.
 */
exports.groupByFilter = (filtersArray) => {
    const groupedResult = groupBy(filtersArray, 'filter');

    return Object.values(groupedResult).map((group) => {
        const value = group.map((item) => item.value);
        return {
            filter: group[0].filter,
            value,
        };
    });
};

// These values are copied from a FIN API result
const EXPENDITURE = 'EXPENDITURE';
const ASSET = 'ASSET';
const LIABILITY = 'LIABILITY';
const REVENUE = 'REVENUE';
const EQUITY = 'EQUITY';

const expenseAccountTypes = [EXPENDITURE];
const nonExpenseAccountTypes = [ASSET, LIABILITY, REVENUE, EQUITY];

exports.allAccountTypes = [...expenseAccountTypes, ...nonExpenseAccountTypes];

exports.expenseAccountTypesDict = listToDict(expenseAccountTypes);
exports.nonExpenseAccountTypesDict = listToDict(nonExpenseAccountTypes);

exports.getRequisitionStatusData = (status) => {
    switch (status) {
        case exports.statusTypes.DRAFT:
            return {
                bsStyle: 'muted',
                longName: 'Draft',
                shortName: 'Draft',
                shortNameBsStyle: 'muted',
                statusIcon: 'fa-pencil',
            };
        case exports.statusTypes.DRAFT_REJECTED:
            return {
                bsStyle: 'danger',
                longName: 'Draft - Rejected',
                shortName: 'Draft',
                shortNameBsStyle: 'muted',
                statusIcon: 'fa-ban',
            };
        case exports.statusTypes.REVIEW:
            return {
                bsStyle: 'royal',
                longName: 'Review',
                shortName: 'Review',
                shortNameBsStyle: 'royal',
                statusIcon: 'fa-user',
            };
        case exports.statusTypes.REVIEW_REJECTED:
            return {
                bsStyle: 'danger',
                longName: 'Review - Rejected',
                shortName: 'Review',
                shortNameBsStyle: 'royal',
                statusIcon: 'fa-ban',
            };
        case exports.statusTypes.CLOSED_PO_CREATED:
            return {
                bsStyle: 'success',
                longName: 'Closed - PO Created',
                shortName: 'Closed',
                shortNameBsStyle: 'primary',
                statusIcon: 'fa-usd',
            };
        case exports.statusTypes.CLOSED_CANCELLED:
            return {
                bsStyle: 'danger',
                longName: 'Closed - Cancelled',
                shortName: 'Closed',
                shortNameBsStyle: 'primary',
                statusIcon: 'fa-ban',
            };
        case exports.statusTypes.CLOSED_REJECTED:
            return {
                bsStyle: 'danger',
                longName: 'Closed - Rejected',
                shortName: 'Closed',
                shortNameBsStyle: 'primary',
                statusIcon: 'fa-ban',
            };
        default:
            return {
                bsStyle: 'muted',
                longName: 'Unknown',
                shortName: 'Unknown',
                shortNameBsStyle: 'muted',
                statusIcon: 'fa-question-circle',
            };
    }
};

exports.findPeriodByDate = (periods, date) => {
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('en-US', { month: 'long' }); // get month name
    return periods.find(
        (period) =>
            period.calendarYear === year &&
            period.calendar_month.toLowerCase() === month.toLowerCase()
    );
};

exports.isDateInOpenPeriods = (enteredDate, fiscalPeriodsWithCalendarYear = []) => {
    if (!enteredDate) {
        return false;
    }

    if (!fiscalPeriodsWithCalendarYear || fiscalPeriodsWithCalendarYear.length === 0) {
        return false;
    }

    // Verify that all periods have a `calendarYear` and a `calendar_month` property
    if (
        !fiscalPeriodsWithCalendarYear.every(
            (period) => period.calendarYear && period.calendar_month
        )
    ) {
        throw new Error(
            'All fiscal periods must have a `calendarYear` and a `calendar_month` property'
        );
    }

    if (!exports.findPeriodByDate(fiscalPeriodsWithCalendarYear, new Date(enteredDate))) {
        return false;
    }

    return true;
};

exports.showAccountInformationOptionsValues = {
    HIDDEN: 0,
    OPTIONAL: 1,
    REQUIRED: 2,
};

exports.desiredDeliveryDateOptionValues = {
    HIDDEN: 0,
    OPTIONAL: 1,
    REQUIRED: 2,
};

exports.getLastActor = (requisition) => {
    if (requisition.status === exports.statusTypes.CLOSED_CANCELLED) {
        return requisition.canceler;
    }

    const lastActor = requisition.lastStep?.stepApproval?.lastActor;
    if (lastActor) {
        return lastActor;
    }

    if (requisition.status === exports.statusTypes.REVIEW) {
        return requisition.submitter;
    }

    return undefined;
};
