import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBlocker } from 'react-router-dom';

export const AutoSaveWrapper = ({ isDirty, saveAction }) => {
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        // for project sections, the path names are the same but the query params are different
        // i.e. https://localhost/governments/1/projects/23/builder/document?section=120 -> https://localhost/governments/1/projects/23/builder/document?section=122
        // search = "?section=120" vs search = "?section=122"
        return (
            (currentLocation.pathname !== nextLocation.pathname ||
                currentLocation.search !== nextLocation.search) &&
            isDirty
        );
    });

    useEffect(() => {
        if (blocker.state === 'blocked') {
            saveAction();

            blocker.proceed();
        }
    }, [blocker, blocker.state, saveAction]);

    return null;
};

AutoSaveWrapper.propTypes = {
    isDirty: PropTypes.bool,
    saveAction: PropTypes.func,
};
