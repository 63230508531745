import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { AwardNote } from './AwardNote';
import { SelectedVendorGovernmentActions } from './SelectedVendorGovernmentActions';
import {
    getGovSelectedProposalsJS,
    getPublicScoredProposalsJS,
    getPublicSelectedProposalsJS,
    getVendorAwardData,
} from './selectors';
import { ReportsModalButton } from '../GovApp';
import {
    getContractModulePath,
    getEvaluationPath,
    getEvaluationProjectJS,
    hasContractingSubscription,
    isContractAdminForProject,
    isEvaluationEditor,
} from '../GovApp/selectors';
import { getShowAddVendorListModal } from '../GovApp/VendorManagement/selectors';
import { AddVendorsToListModal } from '../GovApp/VendorManagement/AddVendorsToList/modal';
import {
    getProjectJS as getPublicProjectJS,
    getProjectPath as getPublicProjectPath,
} from '../PublicApp/selectors';
import { isVendorAdminUser } from '../selectors';
import { menuActionHandler } from '../../actions/govProjects';
import { showAddVendorsToListModal } from '../../actions/vendorList';
import {
    BidTabulationTable,
    EvaluatorScoresTable,
    Main,
    SectionTitle,
    ZeroState,
} from '../../components';
import {
    ProposalQuestionnaireComparisonTable,
    SelectedProposals,
} from '../../components/proposals';
import { VendorLineItemAwardsTable } from '../../components/LineItemAwardTable/VendorLineItemAwardsTable';
import { CREATE_CONTRACT_FROM_PROJECT, FINALIZE_AWARD } from '../../constants/menuActions';
import { getTotalWeight } from '../../helpers';

const { AWARD_PENDING, CLOSED } = projectStatusesDict;

export class SelectedVendor extends PureComponent {
    static propTypes = {
        contractPath: PropTypes.string.isRequired,
        hasContracting: PropTypes.bool,
        isContractAdmin: PropTypes.bool,
        isEditor: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isPublicView: PropTypes.bool,
        menuActionHandler: PropTypes.func,
        omitLineItem: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            bidResults: PropTypes.shape({
                bidTabulations: PropTypes.array.isRequired,
                bidTabulationSortAsc: PropTypes.bool.isRequired,
                isPricingSealed: PropTypes.bool.isRequired,
                proposalsData: PropTypes.array.isRequired,
            }),
            evaluation: PropTypes.shape({
                awardNote: PropTypes.string,
            }),
            government: PropTypes.shape({
                salesTax: PropTypes.number,
            }),
            government_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            isPublicBidTotalOnly: PropTypes.bool.isRequired,
            questionnaireResults: PropTypes.shape({
                proposals: PropTypes.array.isRequired,
                questionnaires: PropTypes.array.isRequired,
            }),
            showBidsWithPricing: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        proposalPath: PropTypes.string,
        proposals: PropTypes.array.isRequired,
        scoredProposals: PropTypes.array,
        vendorAwardData: PropTypes.array,
        isVendorAdmin: PropTypes.bool.isRequired,
        showAddVendorsToListModal: PropTypes.func,
        addVendorsToListModalOpen: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);
        this.state = {
            userIds: [],
        };
    }

    get isAwardPending() {
        const {
            project: { status },
        } = this.props;

        return status === AWARD_PENDING;
    }

    get auctionMaxFractionDigits() {
        const {
            project: {
                auctionMaxFractionDigits,
                template: { isReverseAuction },
            },
        } = this.props;
        return isReverseAuction ? auctionMaxFractionDigits : null;
    }

    createContractHandler = (proposal) => {
        const { project } = this.props;

        const contractPartyData = {
            companyEmail: proposal.contactEmail,
            companyName: proposal.companyName,
        };

        if (proposal.vendor_id) {
            contractPartyData.vendor_id = proposal.vendor_id;
        }

        const contractData = {
            contractParty: contractPartyData,
            proposal_id: proposal.id,
        };

        this.props.menuActionHandler(CREATE_CONTRACT_FROM_PROJECT, project, { contractData });
    };

    finalizeAwardHandler = () => {
        const { project } = this.props;

        this.props.menuActionHandler(FINALIZE_AWARD, project);
    };

    showAddVendorsToListModal = (userId) => {
        const {
            project: { government_id: govId },
        } = this.props;
        this.setState({ userIds: [userId] }, () => {
            this.props.showAddVendorsToListModal(govId);
        });
    };

    renderSelectedVendor() {
        const {
            contractPath,
            hasContracting,
            isContractAdmin,
            isEditor,
            isGovernmentView,
            project,
            proposalPath,
            proposals,
            isVendorAdmin,
            addVendorsToListModalOpen,
        } = this.props;

        if (proposals.length === 0) {
            return <ZeroState title="No vendor has been selected yet" />;
        }

        const canContract = isGovernmentView && hasContracting;
        const awardNote = project.evaluation?.awardNote;

        return (
            <>
                <div className="row">
                    <div className="col-md-offset-2 col-md-8">
                        <div className="row">
                            <div className="col-md-offset-2 col-md-8">
                                <AwardNote
                                    awardNote={awardNote}
                                    isEditable={
                                        isGovernmentView && isEditor && project.status !== CLOSED
                                    }
                                    projectId={project.id}
                                />
                            </div>
                        </div>
                        {isGovernmentView && (
                            <div className={this.styles.awardLetter}>
                                <ReportsModalButton
                                    bsSize="sm"
                                    text="Intent to Award Letter"
                                    type="awardReport"
                                />
                            </div>
                        )}
                        <SelectedProposals
                            canContract={canContract}
                            contractPath={contractPath}
                            createContractHandler={this.createContractHandler}
                            isContractAdmin={isContractAdmin}
                            isGovernmentView={isGovernmentView}
                            isVendorAdmin={isVendorAdmin}
                            proposalPath={proposalPath}
                            proposals={proposals}
                            showVendorListModal={this.showAddVendorsToListModal}
                        />
                        {addVendorsToListModalOpen && (
                            <AddVendorsToListModal
                                governmentId={project.government_id}
                                initialValues={{
                                    userIds: this.state.userIds,
                                }}
                                noUserSelection
                            />
                        )}
                    </div>
                </div>
                {isGovernmentView && (
                    <SelectedVendorGovernmentActions
                        finalizeAwardHandler={this.finalizeAwardHandler}
                        isAwardPending={this.isAwardPending}
                        isEditor={isEditor}
                        project={project}
                    />
                )}
            </>
        );
    }

    renderConsensusScorecard() {
        const { scoredProposals } = this.props;

        if (!scoredProposals) {
            return null;
        }

        const scoringCriteria = get(scoredProposals, [0, 'scoringCriteria']);
        return (
            <div className={this.styles.sectionContainer}>
                <SectionTitle title="Consensus Scorecard" />
                {scoredProposals.length === 0 ? (
                    <ZeroState title="No consensus scorecard submitted" />
                ) : (
                    <EvaluatorScoresTable
                        isConsensusView
                        proposals={scoredProposals}
                        scoringCriteria={scoringCriteria}
                        totalWeight={getTotalWeight({ scoringCriteria })}
                    />
                )}
            </div>
        );
    }

    renderPublicQuestionnaireResponses() {
        const {
            project: { questionnaireResults, showBidsWithPricing },
        } = this.props;

        const questionnaires = get(questionnaireResults, 'questionnaires');
        const proposals = get(questionnaireResults, 'proposals');

        if (!questionnaires || questionnaires.length === 0) {
            return null;
        }

        return (
            <div className={this.styles.sectionContainer}>
                <SectionTitle title="Public Vendor Responses" />
                <ProposalQuestionnaireComparisonTable
                    isPricingSealed={!showBidsWithPricing}
                    proposals={proposals}
                    questionnaires={questionnaires}
                />
            </div>
        );
    }

    renderLineItemAwards() {
        const {
            isPublicView,
            omitLineItem,
            project: {
                bidResults,
                government: { salesTax },
            },
            vendorAwardData,
        } = this.props;

        if (
            get(bidResults, 'isPricingSealed') ||
            !vendorAwardData ||
            vendorAwardData.length === 0
        ) {
            return null;
        }

        return (
            <div className={this.styles.sectionContainer}>
                <SectionTitle title="Line Item Awards by Vendor" />
                {vendorAwardData.map((vendorDataItem, i) => (
                    <React.Fragment key={i}>
                        <h4 className={this.styles.vendorName}>{vendorDataItem.vendorName}</h4>
                        {vendorDataItem.priceTables.map((priceTable) => {
                            return (
                                <VendorLineItemAwardsTable
                                    auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                                    isPublicView={isPublicView}
                                    key={priceTable.id}
                                    omitLineItem={omitLineItem}
                                    priceTable={priceTable}
                                    salesTax={salesTax}
                                    vendorAwardData={vendorDataItem}
                                />
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    renderBidResults() {
        const {
            isPublicView,
            project: {
                bidResults,
                government: { salesTax },
                isPublicBidTotalOnly,
            },
        } = this.props;

        if (!bidResults) {
            return null;
        }

        const { bidTabulations, bidTabulationSortAsc, isPricingSealed, proposalsData } = bidResults;

        const renderPricingInfo = () => {
            if (isPricingSealed) {
                return <ZeroState title="Pricing information is sealed" />;
            }

            if (bidTabulations.length === 0) {
                return <ZeroState title="Pricing information is sealed" />;
            }

            return bidTabulations.map((bidTabulation, index) => (
                <BidTabulationTable
                    auctionMaxFractionDigits={this.auctionMaxFractionDigits}
                    bidTabulation={bidTabulation}
                    defaultTitle={`Table ${index + 1}`}
                    isPublicView={isPublicView}
                    isViewOnly
                    key={index}
                    proposalsData={proposalsData}
                    salesTax={salesTax}
                    showTotalOnly={isPublicBidTotalOnly}
                    sortAscending={bidTabulationSortAsc}
                    totalAll
                />
            ));
        };

        return (
            <div className={this.styles.sectionContainer}>
                <SectionTitle title="Pricing Results" />
                {renderPricingInfo()}
            </div>
        );
    }

    render() {
        const { isGovernmentView, proposals } = this.props;

        const vendorText = proposals.length > 1 ? 'Vendors' : 'Vendor';
        const awardType = this.isAwardPending ? 'recommended' : 'awarded';

        return (
            <Main>
                <SectionTitle
                    info={`${vendorText} ${awardType} by the evaluation process`}
                    title={`Selected ${vendorText}`}
                />
                {this.renderSelectedVendor()}
                {!isGovernmentView && (
                    <>
                        {this.renderLineItemAwards()}
                        {this.renderConsensusScorecard()}
                        {this.renderBidResults()}
                        {this.renderPublicQuestionnaireResponses()}
                    </>
                )}
            </Main>
        );
    }
}

/**
 * NOTE: we can't use decorators below because they don't seem to play nice with not being after the
 * previously defined component, so we use the standard HOC style instead.
 */

// Government container
const SelectedVendorGovernment = (props) => {
    return <SelectedVendor isGovernmentView {...props} />;
};
SelectedVendor.Government = compose(
    withRouter,
    connect(
        (state, props) => {
            const project = getEvaluationProjectJS(state);
            return {
                contractPath: getContractModulePath(state, props),
                hasContracting: hasContractingSubscription(state),
                isContractAdmin: isContractAdminForProject(state),
                isEditor: isEvaluationEditor(state),
                omitLineItem:
                    (project.priceTables.length > 0 && project.priceTables[0].omitLineItem) ||
                    false,
                project,
                proposalPath: `${getEvaluationPath(state, props)}/proposals`,
                proposals: getGovSelectedProposalsJS(state),
                isVendorAdmin: isVendorAdminUser(state),
                addVendorsToListModalOpen: getShowAddVendorListModal(state),
            };
        },
        {
            menuActionHandler,
            showAddVendorsToListModal,
        }
    )
)(SelectedVendorGovernment);

// Public container
const SelectedVendorPublic = (props) => {
    return <SelectedVendor isPublicView {...props} />;
};
SelectedVendor.Public = compose(
    withRouter,
    connect((state, props) => {
        const project = getPublicProjectJS(state);

        const hasVendorId = !!props.params.vendorId;
        const modifiedProps = hasVendorId
            ? {
                  ...props,
                  params: {
                      ...props.params,
                      governmentCode: get(project, 'government.code'),
                  },
              }
            : props;

        const projectPath = getPublicProjectPath(state, modifiedProps);

        return {
            addVendorsToListModalOpen: false,
            contractPath: `${projectPath}/contracts`,
            omitLineItem:
                (project.priceTables.length > 0 && project.priceTables[0].omitLineItem) || false,
            project,
            proposals: getPublicSelectedProposalsJS(state),
            scoredProposals: getPublicScoredProposalsJS(state),
            vendorAwardData: getVendorAwardData(state),
            isVendorAdmin: false,
        };
    })
)(SelectedVendorPublic);
