export const form = 'contractFromProjectForm';

export const UNLINK_BUDGET = 'unlinkBudget';

export const budgetSetupOptions = [
    {
        label: 'Share Budget With Project (contract amount and spend management updates)',
        tooltip:
            "If you select this option, you'll share the contract amount and spend management updates for all contract records with this option. This means if you change one contract record's contract amount, the other contract records will also change. In addition, all purchases will show up in all spend management tabs.",
        value: false,
    },
    {
        label: 'Use Independent Budget',
        tooltip:
            'This contract record will not update based on changes to other contract records associated with this project.',
        value: true,
    },
];
