import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

export const NavScrollContainer = forwardRef(({ children, ...rest }, externalRef) => {
    const styles = require('./index.scss');

    const containerRef = React.useRef();

    // Expose the containerRef methods to the external ref
    useImperativeHandle(externalRef, () => containerRef.current);

    const resizeLeftMenuContainer = () => {
        if (containerRef.current) {
            const position = containerRef.current.getBoundingClientRect().top;
            containerRef.current.style.maxHeight = `calc(100vh - ${position}px)`;
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', resizeLeftMenuContainer);
        window.addEventListener('resize', resizeLeftMenuContainer);

        return () => {
            window.removeEventListener('scroll', resizeLeftMenuContainer);
            window.addEventListener('resize', resizeLeftMenuContainer);
        };
    }, [resizeLeftMenuContainer]);

    React.useEffect(() => {
        resizeLeftMenuContainer();
    }, []);

    return (
        <div className={styles.navScrollContainer} ref={containerRef} {...rest}>
            {children}
        </div>
    );
});

NavScrollContainer.propTypes = {
    children: PropTypes.node,
};

// Add a display name for better debugging
NavScrollContainer.displayName = 'NavScrollContainer';
