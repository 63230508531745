import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

import { CDSButton } from '../../CDSButtons';

export const BaseSectionsNavBackButton = ({ children, onClick }) => {
    const styles = require('./index.scss');

    return (
        <CDSButton
            className={styles.navButton}
            onClick={() => (onClick ? onClick() : null)}
            qaTag="navButtons-back"
            variant="text"
        >
            <ChevronLeftIcon className={styles.chevron} />
            <span>Back &ndash;&nbsp;</span>
            <span className={styles.sectionName}>{children}</span>
        </CDSButton>
    );
};

BaseSectionsNavBackButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};
