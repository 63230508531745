import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Box } from '@og-pro/ui';

import { Button } from '../../../Button';
import { InputText } from '../../../InputText';

const REJECTION_REASON = 'rejectionReason';

const formConfig = {
    form: 'pendingDocumentRejectionForm',
    validate: (values) => {
        const errors = {};
        if (!values[REJECTION_REASON]) {
            errors[REJECTION_REASON] = 'Rejection reason is required';
        }
        return errors;
    },
};

const ConnectedRejectionReasonModal = ({ handleSubmit, onHide }) => {
    return (
        <Modal onHide={onHide} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-left">Rejection Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <Field
                        autoFocus
                        component={InputText}
                        hasFeedback={false}
                        help="Please provide a reason for rejecting this document. The reason will be sent to the vendor."
                        label="Rejection Reason (required)"
                        minRows={2}
                        name={REJECTION_REASON}
                        placeholder="Enter text"
                        qaTag="pendingDocumentModal-rejectReason"
                        type="textarea"
                    />
                    <Box textAlign="right">
                        <Button bsSize="lg" onClick={onHide} qaTag="pendingDocumentModal-cancel">
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            bsSize="lg"
                            bsStyle="danger"
                            qaTag="pendingDocumentModal-reject"
                            type="submit"
                        >
                            <i className="fa fa-ban" /> Reject
                        </Button>
                    </Box>
                </form>
            </Modal.Body>
        </Modal>
    );
};

ConnectedRejectionReasonModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
};

export const RejectionReasonModal = reduxForm(formConfig)(ConnectedRejectionReasonModal);
