import { useSelector } from 'react-redux';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

export const useContractingDocument = () => {
    const restrictionsEnabled = useFlags(FLAGS.ENABLE_CONTRACTING_DOCUMENTS_GOV_RESTRICTIONS);

    const hasContractingDocument = useSelector((state) =>
        state.auth.getIn(['user', 'government', 'hasContractingDocument'])
    );

    // The new setting needs to only restrict stuff if the flag is on
    // otherwise it should act as if it's off
    if (!restrictionsEnabled) {
        return true;
    }

    return hasContractingDocument;
};
