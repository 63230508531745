import { buildQaTag, Typography } from '@og-pro/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPriceItemsSummary } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { getLastActionDisplayData } from '@og-pro/shared-config/requisitions/lastActions';

import { getRequisitionStatusData, purchaseDetailsModes } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../constants';
import { fieldNames } from '../../RequisitionsCreate/RequisitionsCreateForms/constants';

import { currencyFormatter } from '../../../../helpers';
import {
    getGovernmentSalesTax,
    getRequisitionEndsInPurchaseOrder,
} from '../../../../selectors/govApp';
import { requisitionsCreateFormValueSelector } from '../../RequisitionsCreate/RequisitionsCreateForms/selectors';

const { PURCHASE_DETAILS_MODE } = fieldNames;
const { AMOUNT_ONLY } = purchaseDetailsModes;

export const StatusBar = ({
    requisition,
    requisition: {
        status,
        priceTable: { priceItems },
    },
}) => {
    const styles = require('./StatusBar.scss');
    const { shortName, shortNameBsStyle } = getRequisitionStatusData(status);
    const {
        text: lastActionText,
        muiIcon: MuiLastActionIcon,
        color: lastActionColor,
    } = getLastActionDisplayData(requisition);

    const taxRate = useSelector(getGovernmentSalesTax);
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);
    const purchaseDetailsMode = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PURCHASE_DETAILS_MODE)
    );

    const { total } = useMemo(
        () => getPriceItemsSummary(priceItems, taxRate),
        [priceItems, taxRate]
    );

    const items = [
        {
            label: 'Status',
            Component: () => (
                <div
                    className={classNames(
                        `text-${shortNameBsStyle}`,
                        styles.statusBarItemBoldValue
                    )}
                >
                    {shortName}
                </div>
            ),
        },
        {
            label: 'Budget Check',
            Component: () => (
                <div
                    className={classNames(
                        requisition.isOverBudget ? 'text-danger' : 'text-success',
                        styles.statusBarItemBoldValue
                    )}
                >
                    {requisition.isOverBudget ? 'Fail' : 'Pass'}
                </div>
            ),
            hidden: !endsInPurchaseOrder,
        },
        {
            label: 'Amount',
            Component: () => <div>{currencyFormatter({ value: total })}</div>,
        },
        {
            label: 'Items',
            Component: () => <div>{priceItems.length}</div>,
            hidden: purchaseDetailsMode === AMOUNT_ONLY || !purchaseDetailsMode,
        },
        {
            label: 'Last Action',
            Component: () => (
                <Typography
                    alignItems="center"
                    color={lastActionColor}
                    display="flex"
                    fontWeight={600}
                    gap={0.5}
                    variant="h5"
                >
                    <MuiLastActionIcon fontSize="10px" />
                    <span>{lastActionText}</span>
                </Typography>
            ),
        },
    ];

    return (
        <div className={styles.statusBarContainer}>
            {items
                .filter(({ hidden }) => !hidden)
                .map(({ Component, label }, index) => (
                    <div className={styles.statusBarItem} key={index}>
                        <div className={styles.statusBarItemLabel}>{label}:</div>
                        <div
                            className={styles.statusBarItemValue}
                            data-qa={buildQaTag(`${qaTagPageName}-${label}`, 'statusItemValue')}
                        >
                            <Component />
                        </div>
                    </div>
                ))}
        </div>
    );
};

StatusBar.propTypes = {
    requisition: PropTypes.shape({
        isOverBudget: PropTypes.bool.isRequired,
        priceTable: PropTypes.shape({
            priceItems: PropTypes.array.isRequired,
        }).isRequired,
        status: PropTypes.number.isRequired,
    }).isRequired,
};
