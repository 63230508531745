import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requisitionGroupForm';

export const fields = [
    'abbreviation',
    'allowOverBudget',
    'creatorIds',
    'icon',
    'leaderIds',
    'name',
    'requestTypeGroupSequences',
    'requireOverBudgetNote',
    'reviewGroupPositions',
    'reviewSequence',
    'user_id',
];

const reviewGroupPositionFields = ['reviewGroupPositionUsers', 'userIds'];

const reviewGroupPositionUsersFields = ['isPrimary', 'review_group_position_id', 'user', 'user_id'];

const requestTypeGroupSequenceFields = ['request_type_id', 'review_sequence_id'];

export const fieldNames = listToDict(fields);

export const reviewGroupPositionFieldNames = listToDict(reviewGroupPositionFields);

export const reviewGroupPositionUsersFieldNames = listToDict(reviewGroupPositionUsersFields);

export const requestTypeGroupSequenceFieldNames = listToDict(requestTypeGroupSequenceFields);

export const formSections = ['info', 'creators', 'sequence'];

export const formSectionNames = listToDict(formSections);

// Fake field used for verifying if the specified section is valid
export const FORM_SECTIONS_KEY = 'sections';

// Fake field used for verifying if entire form is valid
export const ALL_SECTIONS = 'allSections';

export const incompleteSectionInstructions = {
    [formSectionNames.INFO]: 'Finish naming your group',
    [formSectionNames.CREATORS]: 'Your assigned creators are invalid',
    [formSectionNames.SEQUENCE]: 'Your request type configuration is invalid',
};
