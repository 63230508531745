import React from 'react';
import { useSelector } from 'react-redux';

import { DisplaySection } from '../../../DisplaySection';
import { StarsDisplay } from '../../../StarsDisplay';
import {
    getContractAggregateRating,
    getContractComplaintsCounts,
    getContractReviewsJS,
} from '../../../../containers/GovApp/selectors';

export const ContractPerformance = () => {
    const aggregateRating = useSelector(getContractAggregateRating);
    const contractReviews = useSelector(getContractReviewsJS);
    const { contractComplaintsTotal, contractComplaintsUnresolved } = useSelector(
        getContractComplaintsCounts
    );

    return (
        <DisplaySection
            header="VENDOR PERFORMANCE"
            items={[
                {
                    icon: 'star',
                    label: 'Overall Rating',
                    value: (
                        <>
                            <StarsDisplay value={Math.round(aggregateRating || 0)} />
                            &nbsp;
                            {aggregateRating
                                ? `${aggregateRating} out of 5 (${contractReviews.length} Reviews)`
                                : '0 Reviews'}
                        </>
                    ),
                },
                {
                    icon: 'exclamation-triangle',
                    label: 'Complaints Filed',
                    value:
                        contractComplaintsTotal === 0
                            ? 'None'
                            : `${contractComplaintsTotal} (${contractComplaintsUnresolved} Unresolved)`,
                },
            ]}
        />
    );
};
