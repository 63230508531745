import PropTypes from 'prop-types';
import React from 'react';

import { positionTypes } from '@og-pro/shared-config/positions';

import { MultiPositionApproval } from './MultiPositionApproval';
import { SinglePositionApproval } from './SinglePositionApproval';
import { TriagedStepWithOnlyEntityPositionsApproval } from './TriagedStepWithOnlyEntityPositionsApproval';

export const StepApproval = ({ minPositionReviews, reviewType, stepApproval }) => {
    const { stepPositionApprovals } = stepApproval;

    if (stepPositionApprovals.length === 1) {
        return <SinglePositionApproval stepPositionApproval={stepPositionApprovals[0]} />;
    }

    const isAllEntityPositions = stepPositionApprovals.every(
        ({ position }) => position.type === positionTypes.ENTITY
    );
    if (isAllEntityPositions) {
        const approvalsWithPrimaryApprover = stepPositionApprovals.filter(
            // since all approvals are entity positions, there is only one approver per approval
            ({ stepPositionApprovers }) => stepPositionApprovers[0].isPrimary
        );
        if (approvalsWithPrimaryApprover.length === 1) {
            // This is a triaged step.
            return (
                <TriagedStepWithOnlyEntityPositionsApproval
                    stepPositionApprovals={stepPositionApprovals}
                />
            );
        }
    }

    return (
        <MultiPositionApproval
            minPositionReviews={minPositionReviews}
            reviewType={reviewType}
            stepPositionApprovals={stepPositionApprovals}
        />
    );
};

StepApproval.propTypes = {
    minPositionReviews: PropTypes.number.isRequired,
    reviewType: PropTypes.number.isRequired,
    stepApproval: PropTypes.shape({
        stepPositionApprovals: PropTypes.array.isRequired,
    }).isRequired,
};
