export const COLUMNS = [
    {
        flex: 0.5,
        headerName: 'User',
        field: 'user',
        filter: true,
        sortable: true,
    },
    {
        headerName: 'Date updated',
        field: 'createdAt',
        filter: 'agTextColumnFilter',
        sort: 'desc',
        sortable: true,
    },
    {
        headerName: 'Category',
        field: 'namespace',
        filter: 'agTextColumnFilter',
        sortable: true,
    },
    {
        headerName: 'Change',
        field: 'action',
        filter: 'agTextColumnFilter',
        sortable: true,
    },
    {
        flex: 1,
        headerName: 'Contract information',
        field: 'information',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
        },
    },
    {
        cellRenderer: (params) => params.value,
        cellStyle: { whiteSpace: 'pre-line' },
        flex: 1,
        headerName: 'Original',
        field: 'previousValue',
        filter: false,
        wrapText: true,
        autoHeight: true,
    },
    {
        cellRenderer: (params) => params.value,
        cellStyle: { whiteSpace: 'pre-line' },
        flex: 1,
        headerName: 'New',
        field: 'value',
        filter: false,
        wrapText: true,
        autoHeight: true,
    },
];
