import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { form } from '../constants';
import { UserRoleForm } from '../UserRoleForm';
import { CDSButton } from '../../../../components';

export class UserRoleListItem extends PureComponent {
    static propTypes = {
        deleteConfirmation: PropTypes.func.isRequired,
        deleteHandler: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
        updateHandler: PropTypes.func.isRequired,
        userRole: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            deleteError: null,
            deleting: false,
            showForm: false,
            updateError: null,
            updating: false,
        };
    }

    deleteHandler = () => {
        const { deleteConfirmation, deleteHandler, userRole } = this.props;

        const deleteConfirmationHandler = () => {
            this.setState({ deleteError: null, deleting: true });
            return deleteHandler(userRole).catch((error) => {
                this.setState({ deleteError: error.message, deleting: false });
            });
        };

        return deleteConfirmation(deleteConfirmationHandler);
    };

    updateHandler = (formData) => {
        const { updateHandler, userRole } = this.props;

        this.setState({ updateError: null, updating: true });
        return updateHandler(userRole, formData)
            .then(() => {
                this.setState({ showForm: false, updating: false });
            })
            .catch((error) => {
                this.setState({ updateError: error.message, updating: false });
            });
    };

    toggleFormHandler = () => this.setState((prevState) => ({ showForm: !prevState.showForm }));

    renderForm() {
        const { options, userRole } = this.props;

        const { updateError, updating } = this.state;

        return (
            <UserRoleForm
                closeForm={this.toggleFormHandler}
                disabled={updating}
                form={`${form}${userRole.id}`}
                initialValues={userRole}
                onSubmit={this.updateHandler}
                options={options}
                serverError={updateError}
            />
        );
    }

    renderItem() {
        const { userRole } = this.props;

        const { deleteError, deleting } = this.state;

        return (
            <>
                <div className="pull-right">
                    <CDSButton
                        aria-label="Edit User Role"
                        disabled={deleting}
                        noPadding
                        onClick={this.toggleFormHandler}
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-pencil" />
                    </CDSButton>
                    &nbsp;&nbsp;&nbsp;
                    <CDSButton
                        aria-label="Delete User Role"
                        disabled={deleting}
                        noPadding
                        onClick={this.deleteHandler}
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-trash text-danger" />
                    </CDSButton>
                </div>
                {userRole.name}
                {deleteError && <div className="error-block text-right">{deleteError}</div>}
            </>
        );
    }

    render() {
        const { showForm } = this.state;

        return <ListGroupItem>{showForm ? this.renderForm() : this.renderItem()}</ListGroupItem>;
    }
}
