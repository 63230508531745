import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ConfirmationSimpleDialog } from '@og-pro/ui';

import { getDataJS } from './selectors';
import { hideConfirmationSimpleModal } from '../../actions/confirmation';
import { Button } from '../../components';

const mapStateToProps = (state) => {
    return {
        data: getDataJS(state),
        onConfirm: state.confirmation.get('simpleOnConfirm'),
        showModal: state.confirmation.get('showSimple'),
    };
};

const mapDispatchToProps = {
    hideModal: hideConfirmationSimpleModal,
};

// @connect
class ConnectedConfirmationSimpleModal extends Component {
    static propTypes = {
        data: PropTypes.shape({
            bsSize: PropTypes.string,
            bsStyle: PropTypes.string,
            btnText: PropTypes.string,
            cancelText: PropTypes.string,
            chainModals: PropTypes.bool,
            dialogProps: PropTypes.object,
            dialogStyle: PropTypes.string,
            hideCancelButton: PropTypes.bool,
            icon: PropTypes.string,
            iconSize: PropTypes.string,
            onCancel: PropTypes.func,
            onHide: PropTypes.func,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            title: PropTypes.string,
            useOpenGovStyle: PropTypes.bool,
        }).isRequired,
        hideModal: PropTypes.func.isRequired,
        onConfirm: PropTypes.func,
        showModal: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./ConfirmationSimpleModal.scss');
    }

    cancelHandler = () => {
        const {
            data: { onCancel },
            hideModal,
        } = this.props;

        hideModal();
        if (onCancel) {
            return onCancel();
        }
    };

    confirmHandler = () => {
        const {
            data: { chainModals },
        } = this.props;

        this.props.onConfirm();

        if (!chainModals) {
            this.props.hideModal();
        }
    };

    hideHandler = () => {
        const {
            data: { onHide },
            hideModal,
        } = this.props;

        if (onHide) {
            hideModal();
            return onHide();
        }

        this.cancelHandler();
    };

    renderBody() {
        const {
            bsStyle = 'danger',
            btnText = 'Confirm',
            cancelText = 'Cancel',
            hideCancelButton = false,
            icon = 'trash-o',
            iconSize = 'fa-lg',
            text = 'Are you sure?',
        } = this.props.data;

        return (
            <div>
                <div className={`text-muted ${this.styles.warningText}`}>{text}</div>
                {!hideCancelButton && (
                    <Button
                        bsSize="lg"
                        className={this.styles.cancelBtn}
                        onClick={this.cancelHandler}
                        qaTag={`simpleConfirmationModal-${cancelText}`}
                    >
                        {cancelText}
                    </Button>
                )}
                <Button
                    bsSize="lg"
                    bsStyle={bsStyle}
                    onClick={this.confirmHandler}
                    qaTag={`simpleConfirmationModal-${btnText}`}
                >
                    {icon && <i className={`fa ${iconSize} fa-${icon} ${this.styles.btnIcon}`} />}
                    {btnText}
                </Button>
            </div>
        );
    }

    render() {
        const { showModal, data } = this.props;

        const { bsSize, title = 'Confirmation Required', useOpenGovStyle } = data;

        if (useOpenGovStyle) {
            return (
                <ConfirmationSimpleDialog
                    btnText={data.btnText}
                    cancelHandler={this.cancelHandler}
                    cancelText={data.cancelText}
                    confirmHandler={this.confirmHandler}
                    dialogProps={data.dialogProps}
                    dialogStyle={data.dialogStyle}
                    hideCancelButton={data.hideCancelButton}
                    hideDialog={this.hideHandler}
                    open={showModal}
                    text={data.text}
                    titleText={<div className={this.styles.modalTitle}>{title}</div>}
                />
            );
        }

        return (
            <Modal bsSize={bsSize} onHide={this.hideHandler} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">{showModal && this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ConfirmationSimpleModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedConfirmationSimpleModal);
