import { percentageAdjustmentTypesDict } from '@og-pro/shared-config/priceTables';

const { DISCOUNT, MARKUP, NONE } = percentageAdjustmentTypesDict;

export const searchSelectOptions = [
    { label: 'You', value: true },
    { label: 'Vendor', value: false },
];

export const discountSelectOptions = [
    { label: 'Yes', value: DISCOUNT },
    { label: 'No', value: NONE },
];

export const percentageAdjustmentTypeSelectOptions = [
    { label: 'None', value: NONE },
    { label: 'Discount', value: DISCOUNT },
    { label: 'Markup', value: MARKUP },
];

export const searchSelectStyles = {
    control: {
        height: 28,
        minHeight: 28,
    },
};
