import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { form } from './constants';
import { validate } from './validate';
import * as securityAccountActions from '../../../actions/account/security';
import { Button, InputText, Main, PageTitle } from '../../../components';
import { PasswordFormSection } from '../../../components/forms';

const mapStateToProps = (state) => {
    return {
        saving: state.accountSecurity.get('saving'),
    };
};

const mapDispatchToProps = securityAccountActions;

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedSecurity extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        save: PropTypes.func.isRequired,
        saving: PropTypes.bool,
    };

    handleSubmit = (data) => {
        this.props.save(data);
    };

    render() {
        const styles = require('./Security.scss');

        const { autoFocus, handleSubmit, invalid, saving } = this.props;

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <PageTitle title="Security" />
                <Main className="col-xs-12 col-sm-offset-1 col-sm-10">
                    <h2 className={styles.titleText}>Update Password</h2>
                    <Field
                        autoFocus={autoFocus}
                        component={InputText}
                        disabled={saving}
                        label="Current Password"
                        name="password"
                        placeholder="Enter current password"
                        type="password"
                    />
                    <PasswordFormSection disabled={saving} />
                    <Button
                        bsSize="large"
                        bsStyle="primary"
                        className="center-block"
                        disabled={invalid || saving}
                        type="submit"
                    >
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Main>
            </form>
        );
    }
}

export const Security = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedSecurity);
