import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { StepSectionLayout } from './StepSectionLayout';
import { MAX_NAME_LENGTH, reviewSequenceStepFieldNames } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { InputText } from '../../../../../../../../components';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';

const { NAME } = reviewSequenceStepFieldNames;

const normalizeName = limitTextLength(MAX_NAME_LENGTH);

export const StepNameSection = ({ disabled, fieldName, showFormValidation }) => {
    return (
        <StepSectionLayout title="Step Name">
            <Field
                component={InputText}
                disabled={disabled}
                hasFeedback={false}
                help="Enter a name for identifying this step"
                name={`${fieldName}.${NAME}`}
                normalize={normalizeName}
                placeholder="Enter name"
                qaTag={`${qaTagPageName}-${NAME}`}
                showValidation={showFormValidation}
                type="text"
            />
        </StepSectionLayout>
    );
};

StepNameSection.propTypes = {
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    showFormValidation: PropTypes.bool,
};
