import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, Typography } from '@og-pro/ui';
import { Upload as UploadIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Dropzone } from './DocxTemplateForm/Dropzone';

export const UploadBox = ({ labelId, updating, dropHandler, success }) => {
    // NOTE: application/vnd.openxmlformats-officedocument.wordprocessingml.document does not seem to work on Windows machines
    return (
        <Box
            border={`1px solid ${capitalDesignTokens.foundations.colors.gray200}`}
            borderRadius={0.5}
            paddingX={1}
        >
            <Dropzone dropHandler={dropHandler} labelId={labelId} updating={updating}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Box alignItems="center" display="flex">
                        <Box mr={2}>
                            <Button
                                onClick={(e) => e.preventDefault()}
                                qaTag="wordTemplates-upload"
                                size="medium"
                                startIcon={<UploadIcon fontSize="small" />}
                                sx={{ marginRight: 0.5 }}
                                variant="contained"
                            >
                                Upload Word Doc
                            </Button>
                        </Box>
                        <Typography
                            color={capitalDesignTokens.foundations.colors.gray700}
                            fontSize="small"
                        >
                            Max File Size: 1 MB
                        </Typography>
                    </Box>
                    {success && (
                        <Typography
                            fontSize="medium"
                            fontWeight={500}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: capitalDesignTokens.foundations.colors.pear700,
                            }}
                        >
                            Upload Successful <CheckCircleIcon fontSize="small" />
                        </Typography>
                    )}
                </Box>
            </Dropzone>
        </Box>
    );
};

UploadBox.propTypes = {
    dropHandler: PropTypes.func.isRequired,
    labelId: PropTypes.string,
    success: PropTypes.bool,
    updating: PropTypes.bool,
};
