import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from '../../../Tooltip';
import {
    checklistQuestionnaireReviewStatusClass,
    checklistQuestionnaireReviewStatusIcon,
    checklistQuestionnaireReviewTooltip,
} from '../../../helpers/statusHelper';

export const QuestionnaireReviewStatusIcon = ({ className, isActionable, isComplete, status }) => {
    const bsStyle = checklistQuestionnaireReviewStatusClass(status, isActionable);
    const icon = checklistQuestionnaireReviewStatusIcon(status, isActionable);
    const tooltip = checklistQuestionnaireReviewTooltip(status, isActionable, isComplete);

    return (
        <Tooltip placement="top" tooltip={tooltip}>
            <span className={`fa-stack ${className}`}>
                <i className={`fa fa-circle fa-stack-2x text-${bsStyle}`} />
                <i className={`fa fa-${icon} fa-stack-1x fa-inverse`} />
            </span>
        </Tooltip>
    );
};

QuestionnaireReviewStatusIcon.propTypes = {
    className: PropTypes.string,
    isActionable: PropTypes.bool,
    isComplete: PropTypes.bool,
    status: PropTypes.string.isRequired,
};
