import { Box } from '@og-pro/ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { mapContactFields } from '@og-pro/shared-config/contacts';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { contractPartyFieldNames, fieldNames, form, noTagsMessage } from './constants';
import { ContractAttachments } from './ContractAttachments';
import { ContractBudgetFormModal } from './ContractBudgetFormModal';
import { ContractMilestones } from './ContractMilestones';
import { ContractTermAndRenewals } from './ContractTermAndRenewals';
import { getContractWithDeserializedDates, hasManuallyEnteredContractParty } from './selectors';
import { validate } from './validate';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox/Checkbox';
import { ContactModal } from '../../ContactModal';
import { ContractRequisitionsDisplay } from '../../RequisitionsDisplay';
import { DepartmentModal } from '../../connected/DepartmentModal/DepartmentModal';
import { InputText } from '../../InputText';
import { Label } from '../../InputText/Label';
import { ProjectSearchInput } from '../../ProjectSearchInput';
import { RichTextInput } from '../../RichTextInput';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { SearchSelectUserOption } from '../../SearchSelectUserOption';
import { VendorSearchInput } from '../../vendors';
import { Well } from '../../Well/Well';
import { changeContactForm, showContactModal } from '../../../actions/contacts';
import {
    changeDepartmentForm,
    getContractAutoNumber,
    hideDepartmentModal,
    incrementContractAutoNumber,
    showDepartmentModal,
} from '../../../actions/contracts';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { CategorySelectInput } from '../../../containers/CategorySelect/CategorySelectInput';
import { hasRequisitionSubscription } from '../../../containers/GovApp/selectors';
import {
    getActiveUsersJS,
    getActiveUsersSelectOptions,
    getContractJS,
    getContractTagsJS,
    getDepartmentsJS,
    getDepartmentsSelectOptions,
    getFundingSourceTagsJS,
    getProcurementClassificationSelectOptions,
    getUserOrganizationTimezone,
} from '../../../containers/selectors';
import { limitTextLength } from '../../../Forms/normalizers';
import { currencyFormatter } from '../../../helpers';
import { MaskedInputText } from '../../../hocs';

const {
    CATEGORIES,
    CONTACT_ID,
    CONTRACT_ID,
    CONTRACT_PARTY,
    DEPARTMENT_ID,
    FUNDING_SOURCE_TAGS,
    HAS_CLAIM,
    HAS_PROTEST,
    IS_COOPERATIVE,
    IS_EMERGENCY,
    IS_PIGGYBACK,
    NOTE,
    PROCUREMENT_CONTACT_ID,
    PROCUREMENT_TAG_ID,
    PROJECT_ID,
    PURCHASE_ORDER_NO,
    REBID,
    REQUISITION_IDENTIFIER,
    SUMMARY,
    TAGS,
    TITLE,
    VENDOR_ASSIGNED_NO,
} = fieldNames;

const { COMPANY_EMAIL, COMPANY_NAME, VENDOR, VENDOR_ORGANIZATION_ID } = contractPartyFieldNames;

const selector = formValueSelector(form);

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        contractPartyVendorField: selector(state, `${CONTRACT_PARTY}.${VENDOR}`),
        contractTags: getContractTagsJS(state),
        departments: getDepartmentsJS(state),
        departmentsSelectOptions: getDepartmentsSelectOptions(state),
        fundingSourceTags: getFundingSourceTagsJS(state),
        hasContractIdValue: !!selector(state, CONTRACT_ID),
        hasRequisitions: hasRequisitionSubscription(state),
        initialValues: getContractWithDeserializedDates(state),
        procurementClassificationSelectOptions: getProcurementClassificationSelectOptions(state),
        shouldShowDepartmentModal: state.contracts.get('showDepartmentModal'),
        showContractPartyFormOnLoad: hasManuallyEnteredContractParty(state),
        timezone: getUserOrganizationTimezone(state),
        users: getActiveUsersJS(state),
        usersSelectOptions: getActiveUsersSelectOptions(state),
    };
};

const mapDispatchToProps = {
    changeContactForm,
    changeDepartmentForm,
    getContractAutoNumber,
    hideDepartmentModal,
    incrementContractAutoNumber,
    showConfirmationSimpleModal,
    showContactModal,
    showDepartmentModal,
};

const formConfig = {
    form,
    validate,
};

// @withRouter
// @connect
// @reduxForm
class ConnectedContractForm extends Component {
    static propTypes = {
        buttonIcon: PropTypes.string.isRequired,
        buttonStyle: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        changeContactForm: PropTypes.func.isRequired,
        changeDepartmentForm: PropTypes.func.isRequired,
        contract: PropTypes.shape({
            budget: PropTypes.shape({
                amount: PropTypes.number,
            }).isRequired,
            contractParty: PropTypes.shape({
                vendor_id: PropTypes.number,
                vendor: PropTypes.shape({
                    organization_id: PropTypes.number,
                }),
            }).isRequired,
            government: PropTypes.shape({
                hasPublicContractDocuments: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        contractId: PropTypes.number,
        contractProject: PropTypes.object,
        contractPartyVendorField: PropTypes.shape({
            id: PropTypes.number.isRequired,
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            organization_id: PropTypes.number.isRequired,
        }),
        contractTags: PropTypes.array.isRequired,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        departmentsSelectOptions: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        fundingSourceTags: PropTypes.array.isRequired,
        getContractAutoNumber: PropTypes.func.isRequired,
        getFlag: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasContractIdValue: PropTypes.bool.isRequired,
        hasRequisitions: PropTypes.bool.isRequired,
        hideDepartmentModal: PropTypes.func.isRequired,
        incrementContractAutoNumber: PropTypes.func.isRequired,
        procurementClassificationSelectOptions: PropTypes.array.isRequired,
        shouldShowDepartmentModal: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showContactModal: PropTypes.func.isRequired,
        showContractPartyFormOnLoad: PropTypes.bool.isRequired,
        showDepartmentModal: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        usersSelectOptions: PropTypes.array.isRequired,
    };

    static defaultProps = {
        disabled: false,
        shouldShowDepartmentModal: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            showContractBudgetModal: false,
            showContractPartyForm: props.showContractPartyFormOnLoad,
        };
    }

    MaskedBudgetInput = MaskedInputText(InputText);

    normalizeContractId = limitTextLength(128);

    get styles() {
        return require('./index.scss');
    }

    changeContractPartyEmailHandler = (data) => {
        const { change } = this.props;

        // Data can be null when form is cleared
        const vendorCompanyEmail = get(data, 'user.email') || null;
        change(`${CONTRACT_PARTY}.${COMPANY_EMAIL}`, vendorCompanyEmail);
    };

    getContractAutoNumber = () => {
        const { change, contractId } = this.props;

        const incrementAutoNumber = () => {
            return this.props.incrementContractAutoNumber(contractId).then((autoNumber) => {
                change(CONTRACT_ID, autoNumber.nextNumber);
            });
        };

        return this.props.getContractAutoNumber(contractId).then((autoNumber) => {
            this.props.showConfirmationSimpleModal(incrementAutoNumber, {
                bsStyle: 'primary',
                btnText: 'Assign Auto Number',
                icon: 'chevron-circle-down',
                text: (
                    <>
                        Next auto number is:
                        <br key="break1" />
                        <strong key="key">{autoNumber.nextNumber}</strong>
                        <br key="break2" />
                        <br key="break3" />
                        Are you sure you want to assign this number to this contract?
                    </>
                ),
            });
        });
    };

    showContractBudgetModal = () => {
        this.setState({ showContractBudgetModal: true });
    };

    hideContractBudgetModal = () => {
        this.setState({ showContractBudgetModal: false });
    };

    handleEditContactDisplayInfoClick = (data) => {
        const { disabled, submitFailed } = this.props;

        const { fieldPrefix, optional } = data;

        if (!disabled) {
            this.props.showContactModal({
                disabled,
                fieldPrefix,
                optional,
                showValidation: submitFailed,
            });
        }
    };

    showContractPartyForm = () => {
        const { change } = this.props;

        change(`${CONTRACT_PARTY}.${VENDOR}`, null);
        change(`${CONTRACT_PARTY}.${VENDOR_ORGANIZATION_ID}`, null);

        this.setState({
            showContractPartyForm: true,
        });
    };

    hideContractPartyForm = () => {
        const { change, contract } = this.props;

        change(`${CONTRACT_PARTY}.${COMPANY_NAME}`, null);
        change(`${CONTRACT_PARTY}.${COMPANY_EMAIL}`, null);

        const vendorOrgId = contract.contractParty?.vendor?.organization_id;

        // if they go back to wanting to search the database
        // restore the vendor_id value to what it originally was
        if (vendorOrgId) {
            change(`${CONTRACT_PARTY}.${VENDOR_ORGANIZATION_ID}`, vendorOrgId);
        }

        this.setState({
            showContractPartyForm: false,
        });
    };

    updateContactInfo = (data) => (contact) => {
        const { users } = this.props;

        const { fieldPrefix } = data;

        const contactValue = users.find((user) => user.id === contact);
        if (contactValue) {
            this.props.changeContactForm(contactValue, form, mapContactFields(fieldPrefix));
        }
    };

    updateDepartmentInfo = (department) => {
        const { departments } = this.props;

        const deptValue = departments.find((dept) => dept.id === department);
        if (deptValue) {
            this.props.changeDepartmentForm(deptValue, form);
        }
    };

    renderContactInput(data) {
        const { disabled, submitFailed, usersSelectOptions } = this.props;

        const { fieldName, label, optional = false, placeholder } = data;

        return (
            <>
                <Field
                    backspaceRemovesValue={optional}
                    component={SearchSelect}
                    components={{ Option: SearchSelectUserOption }}
                    disabled={disabled}
                    isClearable={optional}
                    label={label}
                    name={fieldName}
                    onChange={this.updateContactInfo(data)}
                    options={usersSelectOptions}
                    placeholder={placeholder}
                    showValidation={submitFailed}
                />
                <div className={this.styles.contactEdit}>
                    <Button
                        bsStyle="link"
                        onClick={() => this.handleEditContactDisplayInfoClick(data)}
                        qaTag="contractForm-editContactDisplayInformation"
                        zeroPadding
                    >
                        Edit Contact Display Information
                    </Button>
                </div>
            </>
        );
    }

    renderContractPartyFormSection() {
        const { contractPartyVendorField, disabled } = this.props;

        const { showContractPartyForm } = this.state;

        if (showContractPartyForm) {
            return (
                <Well>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        label="Vendor Name"
                        name={COMPANY_NAME}
                        placeholder="Enter Text"
                        qaTag="connectedContractForm-searchYourVendorDatabase"
                        type="text"
                    />
                    <div className={this.styles.contactEdit}>
                        <Button
                            bsStyle="link"
                            onClick={this.hideContractPartyForm}
                            qaTag="contractForm-searchVendorDatabase"
                            zeroPadding
                        >
                            Search your vendor database
                        </Button>
                    </div>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        label="Vendor Email"
                        name={COMPANY_EMAIL}
                        placeholder="Enter Text"
                        qaTag="connectedContractForm-vendorEmailEnterText"
                        type="text"
                    />
                </Well>
            );
        }

        return (
            <>
                <Field
                    backspaceRemovesValue={false}
                    component={VendorSearchInput}
                    disabled={disabled}
                    filterPendingUsersWithoutOrganization
                    isClearable
                    label="Associated Vendor"
                    name={VENDOR_ORGANIZATION_ID}
                    onChangeWithOption={this.changeContractPartyEmailHandler}
                    optionValueField="organizationId"
                    selectedVendorOption={
                        contractPartyVendorField && {
                            label: contractPartyVendorField.organization.name,
                            user: {
                                displayName: contractPartyVendorField.organization.name,
                            },
                            value: contractPartyVendorField.organization_id,
                        }
                    }
                />
                <div className={this.styles.contactEdit}>
                    <Button
                        bsStyle="link"
                        onClick={this.showContractPartyForm}
                        qaTag="contractForm-enterInformation"
                        zeroPadding
                    >
                        Can&#39;t find vendor? Enter information
                    </Button>
                </div>
            </>
        );
    }

    render() {
        const {
            buttonIcon,
            buttonStyle,
            buttonText,
            change,
            contractId,
            contract: {
                budget: { amount },
                government: { hasPublicContractDocuments },
            },
            contractProject,
            contractTags,
            departmentsSelectOptions,
            disabled,
            fundingSourceTags,
            getFlag,
            handleSubmit,
            hasContractIdValue,
            hasRequisitions,
            procurementClassificationSelectOptions,
            shouldShowDepartmentModal,
            timezone,
        } = this.props;

        const { showContractBudgetModal } = this.state;
        const documentsTab = getFlag(FLAGS.ENABLE_CONTRACT_DOCUMENTS_TAB);

        return (
            <form>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            label="Contract Record Title"
                            name={TITLE}
                            placeholder="Enter Text"
                            qaTag="connectedContractForm-contractTitleEnterText"
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            help="Identification number or code for the contract record"
                            label={
                                <>
                                    Contract Record ID
                                    <Button
                                        bsStyle="link"
                                        className={this.styles.autoNumberButton}
                                        disabled={hasContractIdValue}
                                        onClick={this.getContractAutoNumber}
                                        qaTag="connectedContractForm-getAutoNumber"
                                        tooltip={
                                            hasContractIdValue
                                                ? 'Contract Record ID already in use. Delete Contract Record ID to get an auto number.'
                                                : undefined
                                        }
                                        zeroPadding
                                    >
                                        <i className="fa fa-magic" /> Get Auto Number
                                    </Button>
                                </>
                            }
                            name={CONTRACT_ID}
                            normalize={this.normalizeContractId}
                            placeholder="Enter Contract Record ID"
                            qaTag="connectedContractForm-getAutoNumber"
                            type="text"
                        />
                        {hasRequisitions ? (
                            <Box mb={2}>
                                <ContractRequisitionsDisplay
                                    contractId={contractId}
                                    disabled={disabled}
                                    isEditor
                                    label={<Label label="Request(s)" />}
                                />
                            </Box>
                        ) : (
                            <Field
                                component={InputText}
                                disabled={disabled}
                                label="Request ID"
                                name={REQUISITION_IDENTIFIER}
                                normalize={this.normalizeContractId}
                                placeholder="Enter Request ID"
                                qaTag="connectedContractForm-requisitionIdentifier"
                                type="text"
                            />
                        )}
                        <Field
                            component={InputText}
                            disabled={disabled}
                            label="Purchase Order Number (PO)"
                            name={PURCHASE_ORDER_NO}
                            placeholder="Enter Text"
                            qaTag="connectedContractForm-purchaseOrderEnterText"
                            type="text"
                        />
                        <div className="form-group">
                            <div>
                                <b>Contract Amount</b>
                            </div>
                            <div>
                                {!amount && amount !== 0 ? (
                                    <em>None specified</em>
                                ) : (
                                    currencyFormatter({ value: amount })
                                )}
                                &nbsp;&nbsp;
                                <Button
                                    bsSize="sm"
                                    bsStyle="link"
                                    onClick={this.showContractBudgetModal}
                                    qaTag="connectedContractForm-edit"
                                    style={{ marginTop: -3 }}
                                    zeroPadding
                                >
                                    <i className="fa fa-pencil" /> edit
                                </Button>
                            </div>
                        </div>
                        <FormSection name={CONTRACT_PARTY}>
                            {this.renderContractPartyFormSection()}
                        </FormSection>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            label="Vendor Assigned Number"
                            name={VENDOR_ASSIGNED_NO}
                            placeholder="Enter Text"
                            qaTag="connectedContractForm-vendorAssignedNumberEnterText"
                            type="text"
                        />
                        {this.renderContactInput({
                            fieldName: CONTACT_ID,
                            fieldPrefix: 'contact',
                            label: 'Project Contact',
                            placeholder: 'Select User...',
                        })}
                        {this.renderContactInput({
                            fieldName: PROCUREMENT_CONTACT_ID,
                            fieldPrefix: 'procurement',
                            label: 'Procurement Contact',
                            placeholder: 'Select User...',
                        })}
                        <Field
                            backspaceRemovesValue={false}
                            component={SearchSelect}
                            disabled={disabled}
                            label="Department"
                            name={DEPARTMENT_ID}
                            onChange={this.updateDepartmentInfo}
                            options={departmentsSelectOptions}
                            placeholder="Select department..."
                        />
                        <div className={this.styles.contactEdit}>
                            <Button
                                bsStyle="link"
                                onClick={this.props.showDepartmentModal}
                                qaTag="contractForm-editDepartmentDisplayInformation"
                                zeroPadding
                            >
                                Edit Department Display Information
                            </Button>
                        </div>
                        <Field
                            component={ProjectSearchInput}
                            disabled={disabled}
                            label="Associated Project"
                            name={PROJECT_ID}
                            selectedProject={contractProject}
                        />
                        <Field
                            component={CategorySelectInput}
                            disabled={disabled}
                            label="Category Codes"
                            name={CATEGORIES}
                            useSingleCodeSet
                        />
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            isClearable
                            label="Procurement Classification"
                            name={PROCUREMENT_TAG_ID}
                            noOptionsMessage={noTagsMessage}
                            options={procurementClassificationSelectOptions}
                            placeholder="Select Procurement Type..."
                        />
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isMulti
                            label="Contract Type"
                            name={TAGS}
                            noOptionsMessage={noTagsMessage}
                            options={contractTags}
                            placeholder="Select Contract Type..."
                        />
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isMulti
                            label="Funding Source"
                            name={FUNDING_SOURCE_TAGS}
                            noOptionsMessage={noTagsMessage}
                            options={fundingSourceTags}
                            placeholder="Select Funding Source..."
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            helpIcon
                            helpIconNode="Contains cooperative language, which can sometimes be called “piggyback” language."
                            inline
                            label="Contains Cooperative Language"
                            name={IS_PIGGYBACK}
                            qaTag="connectedContractForm-isPiggyback"
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            helpIcon
                            helpIconNode="Select if this contract was procured via cooperative purchasing"
                            inline
                            label="Acquired via Cooperative"
                            name={IS_COOPERATIVE}
                            qaTag="connectedContractForm-isCooperative"
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            helpIcon
                            helpIconNode="Select if this contract had a bid protest"
                            inline
                            label="Bid Protest"
                            name={HAS_PROTEST}
                            qaTag="connectedContractForm-hasProtest"
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            helpIcon
                            helpIconNode="Select if this contract had a claim against it"
                            inline
                            label="Contract Claim"
                            name={HAS_CLAIM}
                            qaTag="connectedContractForm-hasClaim"
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            helpIcon
                            helpIconNode="Will you need to renew this contract through a competitive process when it ends? If so, leave this box checked. This will help tailor our notifications."
                            inline
                            label="Will be Re-Bid"
                            name={REBID}
                            qaTag="connectedContractForm-rebid"
                        />
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            formGroupClass={this.styles.rebidCheckbox}
                            inline
                            label="Contract for emergency good or service?"
                            name={IS_EMERGENCY}
                            qaTag="connectedContractForm-isEmergency"
                        />
                    </div>
                    <div className="col-md-6">
                        <div>
                            <b>Contract Record Duration and Renewals</b>
                        </div>
                        <ContractTermAndRenewals
                            change={change}
                            disabled={disabled}
                            timezone={timezone}
                        />
                        {!documentsTab && (
                            <>
                                <Label label="Contract Documents" />
                                <ContractAttachments
                                    contractId={contractId}
                                    disabled={disabled}
                                    hasPublicContractDocuments={hasPublicContractDocuments}
                                />
                            </>
                        )}

                        <Label label="Contract Milestones" />
                        <ContractMilestones disabled={disabled} timezone={timezone} />
                        <Field
                            component={RichTextInput}
                            disabled={disabled}
                            help="Provide an optional summary of the contract"
                            label="Summary"
                            name={SUMMARY}
                            placeholder="Enter Text"
                        />
                        <Field
                            component={RichTextInput}
                            disabled={disabled}
                            help="Notes are for internal use only. They will not be made public."
                            label="Notes"
                            name={NOTE}
                            placeholder="Enter Text"
                        />
                    </div>
                </div>
                <div className={this.styles.submitButtonContainer}>
                    <Button
                        bsSize="lg"
                        bsStyle={buttonStyle}
                        disabled={disabled}
                        onClick={handleSubmit}
                        qaTag="connectedContractForm-submit"
                    >
                        <i className={`fa fa-fw ${buttonIcon}`} />
                        &nbsp;
                        {buttonText}
                    </Button>
                </div>
                <ContactModal formName={form} />
                <DepartmentModal
                    disabled={disabled}
                    hideModal={this.props.hideDepartmentModal}
                    showModal={shouldShowDepartmentModal}
                />
                {showContractBudgetModal && (
                    <ContractBudgetFormModal
                        contractId={contractId}
                        hideModal={this.hideContractBudgetModal}
                    />
                )}
            </form>
        );
    }
}

export const ContractForm = compose(
    withRouter,
    withFlags(),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedContractForm);
