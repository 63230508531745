import { createSelector } from 'reselect';
import { capitalize } from 'lodash';

import {
    projectClosedSubstatusesDict,
    projectStatusFilterTypesDict,
    projectStatusesDict,
} from '@og-pro/shared-config/projects';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import {
    hasDocBuilderSubscription,
    hasEvaluationSubscription,
    hasSourcingSubscription,
} from '../../containers/GovApp/selectors';

const { ALL, FINAL_DOCUMENT } = projectStatusFilterTypesDict;

const {
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    DRAFT,
    REVIEW,
    FINAL,
    POST_PENDING,
    OPEN,
    AUCTION_PENDING,
    REVERSE_AUCTION,
    PENDING,
    AWARD_PENDING,
    EVALUATION,
    CLOSED,
} = projectStatusesDict;

const { AWARDED, CANCELED, NO_RESPONSES, NO_VENDOR_SELECTED, OTHER } = projectClosedSubstatusesDict;

export const getProjectStatusOptions = createSelector(
    [
        (state, props) => props.isDocBuilder,
        (state, props) => props.isIntake,
        hasDocBuilderSubscription,
        hasSourcingSubscription,
        hasEvaluationSubscription,
    ],
    (isDocBuilder, isIntake, hasDocBuilder, hasSourcing, hasEvaluation) => {
        const enableClosedSubstatuses = useFlags(FLAGS.ENABLE_CLOSED_SUBSTATUSES);

        if (isDocBuilder) {
            return [
                { label: 'All Statuses', value: ALL },
                { label: 'Draft', value: DRAFT },
                { label: 'Review', value: REVIEW },
                { label: 'Final', value: FINAL_DOCUMENT },
                { label: 'Closed', value: CLOSED },
            ];
        }
        if (isIntake) {
            return [
                { label: 'All Statuses', value: ALL },
                { label: 'Draft', value: REQUEST_DRAFT },
                { label: 'Review', value: REQUEST_REVIEW },
                { label: 'Closed', value: CLOSED },
            ];
        }

        const projectNavItems = [];
        if (hasDocBuilder) {
            projectNavItems.push(
                { label: 'Draft', value: DRAFT },
                { label: 'Review', value: REVIEW },
                { label: 'Final', value: FINAL }
            );
        }
        if (hasSourcing) {
            projectNavItems.push(
                { label: 'Post Pending', value: POST_PENDING },
                { label: 'Open', value: OPEN },
                { label: 'Auction Pending', value: AUCTION_PENDING },
                { label: 'Reverse Auction', value: REVERSE_AUCTION },
                { label: 'Pending', value: PENDING }
            );
        }
        if (hasEvaluation) {
            projectNavItems.push(
                { label: 'Evaluation', value: EVALUATION },
                { label: 'Award Pending', value: AWARD_PENDING }
            );
        }

        const closedSubstatuses = [
            { label: 'All Closed', value: CLOSED }, // this option replaces the "Closed" option
            { label: capitalize(AWARDED), value: AWARDED },
            { label: capitalize(CANCELED), value: CANCELED },
            { label: capitalize(NO_RESPONSES), value: NO_RESPONSES },
            { label: capitalize(NO_VENDOR_SELECTED), value: NO_VENDOR_SELECTED },
            { label: capitalize(OTHER), value: OTHER },
        ];
        let closeStatus = { label: 'Closed', value: CLOSED };
        if (enableClosedSubstatuses) {
            closeStatus = { label: 'Closed', options: closedSubstatuses };
        }

        return [{ label: 'All Statuses', value: ALL }, ...projectNavItems, closeStatus];
    }
);
