import React from 'react';

import { Button } from '@og-pro/ui';
import AddIcon from '@mui/icons-material/Add';

import { Box } from '@mui/material';

import { RootCustomField } from './RootCustomField';
import { SelectCustomFieldForm } from './SelectCustomFieldForm';
import { useCustomFormEditor } from './CustomFormEditorContext';
import { LoadingSpinner } from '../../../../../../components';

export const CustomFieldsEditor = () => {
    const {
        isCustomFormLoading,
        rootCustomFields,
        addField,
        showAddFieldForm,
        setShowAddFieldForm,
        focusedCustomFieldId,
        setFocusedCustomFieldId,
    } = useCustomFormEditor();

    if (isCustomFormLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    const handleSelectedField = (selectedFieldId) => {
        setFocusedCustomFieldId(selectedFieldId);
        addField(selectedFieldId);
        setShowAddFieldForm(false);
    };
    return (
        <Box
            onClick={(e) => {
                setFocusedCustomFieldId(null);
                e.stopPropagation();
            }}
        >
            <Box>
                {rootCustomFields.map((rootCustomField) => {
                    return (
                        <RootCustomField
                            key={rootCustomField.customFieldId}
                            onFieldClick={(e) => {
                                e.stopPropagation();
                                setShowAddFieldForm(false);
                                setFocusedCustomFieldId(rootCustomField.customFieldId);
                            }}
                            rootCustomField={rootCustomField}
                            showEditingControls={
                                focusedCustomFieldId === rootCustomField.customFieldId
                            }
                        />
                    );
                })}
                {showAddFieldForm && <SelectCustomFieldForm selectFn={handleSelectedField} />}
            </Box>
            <Box sx={{ px: 4, py: 3 }}>
                <Button
                    color="primary"
                    disabled={showAddFieldForm}
                    onClick={(e) => {
                        setShowAddFieldForm(true);
                        setFocusedCustomFieldId(null);
                        e.stopPropagation();
                    }}
                    qaTag="customFieldOption-addCustomField"
                    startIcon={<AddIcon />}
                    variant="outlined"
                >
                    {showAddFieldForm || rootCustomFields.length > 0
                        ? 'Add Another Field'
                        : 'Add a Field'}
                </Button>
            </Box>
        </Box>
    );
};
