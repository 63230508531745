export const LOAD_ALL = 'vend/proposals/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'vend/proposals/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'vend/proposals/LOAD_ALL_FAIL';
export const LOAD_FOLLOWED_PROJECTS_SUCCESS = 'vend/proposals/LOAD_FOLLOWED_PROJECTS_SUCCESS';

export const LOAD_AWARDED_CONTRACTS = 'vendor/awards/LOAD_AWARDED_CONTRACTS';
export const LOAD_AWARDED_CONTRACTS_SUCCESS = 'vendor/awards/LOAD_AWARDED_CONTRACTS_SUCCESS';
export const LOAD_AWARDED_CONTRACTS_FAIL = 'vendor/awards/LOAD_AWARDED_CONTRACTS_FAIL';

export const LOAD_AWARDED_PROJECTS = 'vendor/awards/LOAD_AWARDED_PROJECTS';
export const LOAD_AWARDED_PROJECTS_SUCCESS = 'vendor/awards/LOAD_AWARDED_PROJECTS_SUCCESS';
export const LOAD_AWARDED_PROJECTS_FAIL = 'vendor/awards/LOAD_AWARDED_PROJECTS_FAIL';

export const UPDATE_QUESTIONNAIRE_RESPONSE = 'vend/proposals/UPDATE_QUESTIONNAIRE_RESPONSE';

export const LOAD = 'vend/proposals/LOAD';
export const LOAD_SUCCESS = 'vend/proposals/LOAD_SUCCESS';
export const LOAD_FAIL = 'vend/proposals/LOAD_FAIL';

export const DELETE_PROPOSAL = 'vend/proposals/DELETE_PROPOSAL';
export const DELETE_PROPOSAL_SUCCESS = 'vend/proposals/DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAIL = 'vend/proposals/DELETE_PROPOSAL_FAIL';

export const UPDATE_PROPOSAL = 'vend/proposals/UPDATE_PROPOSAL';
export const UPDATE_PROPOSAL_SUCCESS = 'vend/proposals/UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_FAIL = 'vend/proposals/UPDATE_PROPOSAL_FAIL';

export const RESET_PROPOSAL = 'vend/proposals/RESET_PROPOSAL';

export const CONFIRM_ADDENDUM_SUCCESS = 'vend/proposals/CONFIRM_ADDENDUM_SUCCESS';

export const START_UPLOAD = 'vend/proposals/START_UPLOAD';
export const UPLOAD_PROPOSAL = 'vend/proposals/UPLOAD_PROPOSAL';
export const UPLOAD_PROPOSAL_PROGRESS = 'vend/proposals/UPLOAD_PROPOSAL_PROGRESS';
export const UPLOAD_PROPOSAL_SUCCESS = 'vend/proposals/UPLOAD_PROPOSAL_SUCCESS';
export const UPLOAD_PROPOSAL_FAIL = 'vend/proposals/UPLOAD_PROPOSAL_FAIL';
export const COMPLETE_UPLOAD = 'vend/proposals/COMPLETE_UPLOAD';

export const CREATE_ATTACHMENT = 'vend/proposals/CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_SUCCESS = 'vend/proposals/CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'vend/proposals/CREATE_ATTACHMENT_FAIL';

export const DELETE_ATTACHMENT = 'vend/proposals/DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'vend/proposals/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'vend/proposals/DELETE_ATTACHMENT_FAIL';

export const TOGGLE_FULL_WIDTH_VIEW = 'vend/proposals/TOGGLE_FULL_WIDTH_VIEW';
