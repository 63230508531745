import React from 'react';

import PropTypes from 'prop-types';

import { RequisitionAttachment } from './RequisitionAttachment';
import { MAX_S3_FILE_SIZE_IN_MB } from './UploadDropzone';

export const Attachments = (props) => {
    return (
        <RequisitionAttachment
            renderAsDropPanel
            title="Upload"
            {...props}
            maxFileSize={MAX_S3_FILE_SIZE_IN_MB}
        />
    );
};

Attachments.propTypes = {
    fieldNamePrefix: PropTypes.string,
};
