import { createSelector } from 'reselect';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { hasContractingSubscription } from '../App/selectors';
import { generateProjectSectionTagOptions } from '../../App/selectors';
import {
    buildCriteriaMap,
    buildSectionDescriptionMap,
    buildSignatureMap,
} from '../../../reducers/helpers/projectCreateHelper';

const { CONTRACT, PURCHASE } = projectTypesDict;

const getTemplate = (state) => state.templatesAdmin.get('template');
const getTemplates = (state) => state.templatesAdmin.get('templates');
const getDocxTemplates = (state) => state.templatesAdmin.get('docxTemplates');
const getRawAvailableTemplateSections = (state) =>
    state.templatesAdmin.get('availableTemplateSections');

export const getTemplatesAdminPath = (_state, props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/templates-admin`;
};

export const getTemplateSectionsPath = (props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/templates-admin/shared-sections`;
};

export const getTemplateJS = createSelector([getTemplate], (rawTemplate) => {
    if (rawTemplate) {
        const template = rawTemplate.toJS();
        return buildSignatureMap(buildCriteriaMap(buildSectionDescriptionMap(template)));
    }
});

export const getTemplatesJS = createSelector([getTemplates], (rawTemplates) => {
    return rawTemplates.toJS();
});

export const getProjectSectionTagOptions = createSelector(
    [getTemplateJS],
    generateProjectSectionTagOptions
);

export const getDocxTemplatesJS = createSelector([getDocxTemplates], (rawDocxTemplates) => {
    return rawDocxTemplates.toJS();
});

export const ALL = 'all';

export const getProjectTypeSelectOptions = createSelector(
    [hasContractingSubscription],
    (hasContracting) => {
        return [
            {
                label: 'All',
                value: ALL,
            },
            {
                label: 'Purchase',
                value: PURCHASE,
            },
            hasContracting && {
                label: 'Contract',
                value: CONTRACT,
            },
        ].filter((item) => !!item);
    }
);

export const getAvailableTemplateSections = createSelector(
    [getRawAvailableTemplateSections],
    (rawTemplateSections) => {
        return rawTemplateSections ? rawTemplateSections.toJS() : [];
    }
);
