import { createSelector } from 'reselect';

import { evaluationStatuses } from '@og-pro/shared-config/evaluations';

import { generateVendorAwardData } from '@og-pro/shared-config/priceTables/utils';

import { getProjectJS, isEvaluationEditor } from '../../selectors';

const { COMPLETE } = evaluationStatuses;

export const getIsViewOnly = createSelector(
    [getProjectJS, isEvaluationEditor],
    (project, isEditor) => {
        if (!project) {
            return true;
        }

        return project.evaluation.status === COMPLETE || !isEditor;
    }
);

const getLineItemAwards = (state) => state.proposalEvaluations.get('lineItemAwards');
const getLineItemAwardProposalsData = (state) =>
    state.proposalEvaluations.get('lineItemAwardProposalsData');

export const getLineItemAwardsJS = createSelector([getLineItemAwards], (rawLineItemAwards) => {
    if (rawLineItemAwards) {
        return rawLineItemAwards.toJS();
    }
});

export const getLineItemAwardProposalsDataJS = createSelector(
    [getLineItemAwardProposalsData],
    (rawLineItemAwardProposalsData) => {
        if (rawLineItemAwardProposalsData) {
            return rawLineItemAwardProposalsData.toJS();
        }
    }
);

export const generateVendorLineItemAwards = createSelector(
    [getLineItemAwardProposalsData, getLineItemAwards],
    (rawLineItemAwardProposalsData, rawGetLineItemAwards) => {
        if (!rawLineItemAwardProposalsData || !rawGetLineItemAwards) {
            return [];
        }
        const proposals = rawLineItemAwardProposalsData.toJS();
        const lineItemAwardsData = rawGetLineItemAwards.toJS();
        return generateVendorAwardData(proposals, lineItemAwardsData);
    }
);
