import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, Typography, Link } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { ActionButtons } from './ActionButtons';
import { ActionError } from './ActionError';
import { StatusBar } from './StatusBar';

import { getRequestsPath } from '../selectors';

export const RequisitionsApprovalHeader = ({
    disabled,
    requisition,
    requisition: { identifier, descriptionOfRequest },
}) => {
    const requestPath = useSelector(getRequestsPath);
    return (
        <Box
            sx={{
                padding: '8px 9px 24px 9px',
                gap: 1,
            }}
        >
            <Box>
                <Link
                    component={RouterLink}
                    size="small"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            marginLeft: '0px',
                        },
                    }}
                    to={requestPath}
                    underline="hover"
                >
                    <ArrowBackIcon fontSize="small" />
                    Back to All Requests
                </Link>
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: 4,
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        flexWrap: 'wrap',
                        gap: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    {descriptionOfRequest ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: '100%',
                            }}
                        >
                            <Typography maxWidth="100%" noWrap variant="h2">
                                {descriptionOfRequest}
                            </Typography>
                            <Typography
                                color={capitalDesignTokens.foundations.colors.gray800}
                                variant="h4"
                            >
                                {identifier}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="h2">{identifier}</Typography>
                    )}
                    <StatusBar requisition={requisition} />
                </Box>
                <Box>
                    <ActionButtons disabled={disabled} />
                </Box>
            </Box>
            <ActionError />
        </Box>
    );
};

RequisitionsApprovalHeader.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        descriptionOfRequest: PropTypes.string,
    }).isRequired,
};
