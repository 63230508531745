import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reset, submit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import {
    projectClosedSubstatusesDict,
    projectDeliveryMethods,
    projectStatusesDict,
    projectTypesDict,
} from '@og-pro/shared-config/projects';
import { proposalStatusesDict } from '@og-pro/shared-config/proposals';
import { questionStatusTypes } from '@og-pro/shared-config/questions';
import { userRolePermissions } from '@og-pro/shared-config/userRoles';
import {
    isNextStatusAuctionPending,
    isNextStatusReverseAuction,
} from '@og-pro/shared-config/reverseAuctions/util';
import { Alert, AlertTitle, Typography, Box } from '@og-pro/ui';

import { ContractFromProjectForm } from './ContractFromProjectForm';
import { form as contractFromProjectForm } from './ContractFromProjectForm/constants';
import { ContractPublicAuditForm } from './ContractPublicAuditForm';
import { form as contractPublicAuditForm } from './ContractPublicAuditForm/constants';
import { DisqualifyProposalForm } from './DisqualifyProposalForm';
import { form as disqualifyProposalForm } from './DisqualifyProposalForm/constants';
import { EvaluationTypeForm } from './EvaluationTypeForm';
import { form as evaluationTypeForm } from './EvaluationTypeForm/constants';
import { FinalizeAwardForm } from './FinalizeAwardForm';
import { form as finalizeAwardForm } from './FinalizeAwardForm/constants';
import { IntakeIncompleteForm } from './IntakeIncompleteForm';
import { form as intakeIncompleteForm } from './IntakeIncompleteForm/constants';
import { CloseOutProjectForm, ExcludeQuestionForm, NoBidForm } from './MultiChoiceWithOtherForm';
import {
    closeOutProjectForm,
    excludeQuestionForm,
    noBidProposalForm,
} from './MultiChoiceWithOtherForm/constants';
import { ProjectHoldForm } from './ProjectHoldForm';
import { form as projectHoldForm } from './ProjectHoldForm/constants';
import { ProxyBidReverseAuctionForm } from './ProxyBidReverseAuctionForm';
import { form as proxyBidReverseAuctionForm } from './ProxyBidReverseAuctionForm/constants';
import { ReassignTagForm } from './ReassignTagForm';
import { form as reassignTagForm } from './ReassignTagForm/constants';
import { getData } from './selectors';
import { UnsealBidsForm } from './UnsealBidsForm';
import { form as unsealBidsForm } from './UnsealBidsForm/constants';
import { form as updateRecordCloseDateForm } from './UpdateRecordCloseDateForm/constants';
import { form as updateRetentionPolicyForm } from './UpdateRetentionPolicyForm/constants';
import { VendorFollowTypeForm } from './VendorFollowTypeForm';
import { form as vendorFollowForm } from './VendorFollowTypeForm/constants';
import { getUserJS } from '../selectors';
import * as adminActions from '../../actions/admin';
import { loadApprovals, replaceProjectApprovals } from '../../actions/approvals';
import * as confirmationActions from '../../actions/confirmation';
import {
    copyContract,
    createContractPublicAuditLog,
    updateContract,
} from '../../actions/contracts';
import * as evaluationActions from '../../actions/evaluations';
import * as govProjectActions from '../../actions/govProjects';
import {
    disqualifyProposal,
    updateProposal as updateGovProposal,
} from '../../actions/govProposals';
import { postResponse } from '../../actions/questions';
import {
    copyProjectDocument,
    copyProjectDocumentToIntake,
    createContract,
    initiateSave,
    initiateSubmit,
} from '../../actions/project/create/projectCreate';
import * as projectPostActions from '../../actions/projectPost';
import * as propEvalActions from '../../actions/proposalEvaluations';
import { createNoBidProposal } from '../../actions/publicProject';
import { deleteProxyBid, submitReverseAuctionBid } from '../../actions/reverseAuctions';
import { subscribeToProject } from '../../actions/subscriptions';
import { deleteTag } from '../../actions/tags';
import * as vendorActions from '../../actions/vendProposals';
import {
    AdminRequired,
    Button,
    ConfirmationModal as ConfirmationModalComponent,
    LoadingSpinner,
} from '../../components';
import { contractPackagePublishExplanation } from '../../components/GovApp/Approvals/ApprovalsDashboard/constants';
import {
    AWARD_PROJECT_FROM_PENDING,
    CHANGE_PRESET_WORKFLOW,
    CLOSE_PROJECT,
    CONFIRM_BULK_CONTACTS_UPDATE,
    CONSENSUS_EVALUATION,
    COPY_CONTRACT,
    COPY_DOCUMENT,
    CREATE_CONTRACT_FROM_INTAKE,
    CREATE_CONTRACT_FROM_PROJECT,
    CREATE_INTAKE_FROM_PROJECT,
    DELETE,
    DELETE_EVALUATION,
    DELETE_POST,
    DELETE_INTAKE,
    DISQUALIFY_PROPOSAL,
    END_AUCTION_REJECT_RESULTS,
    EVALUATE,
    EXCLUDE_QUESTION,
    FINALIZE,
    FINALIZE_AWARD,
    FINALIZE_CONTRACT_PACKAGE,
    NO_BID_GOV_PROPOSAL,
    PAUSE_PROJECT,
    POST,
    PROXY_BID_REVERSE_AUCTION,
    PUBLISH,
    REASSIGN_TAG,
    RELEASE_EVALUATION,
    REOPEN_BUILDER,
    REOPEN_CLOSED_PROJECT,
    REOPEN_CLOSED_INTAKE,
    REOPEN_DRAFT_STATUS,
    REOPEN_PROJECT_INTAKE,
    INTAKE_INCOMPLETE,
    RESEAL_BIDS,
    RETRACT_POST,
    SKIP_TO_NEXT_STAGE,
    START_REVIEW_PROCESS,
    SUBMIT_INTAKE_FOR_REVIEW,
    TOGGLE_CONTRACT_PUBLIC_PRIVATE,
    UNAWARD_PROJECT,
    UNFINALIZE,
    UNSEAL_BIDS,
    UPDATE_RETENTION_POLICY,
    UPDATE_RECORD_CLOSE_DATE,
    VENDOR_FOLLOW,
} from '../../constants/menuActions';
import {
    DELETE_PROPOSAL,
    NO_BID_EXISTING_PROPOSAL,
    NO_BID_NEW_PROPOSAL,
} from '../../constants/proposalActions';
import { compileDocument } from '../../actions/exportProject';
import { trackEvent } from '../../helpers';

import { getBidPosterAuthorized } from '../GovApp/selectors';
import { UpdateRecordCloseDateForm } from './UpdateRecordCloseDateForm';
import { UpdateRetentionPolicyForm } from './UpdateRetentionPolicyForm';
import { BulkUpdateConfirmationModal } from '../Admin/UserList/BulkUpdateConfirmationModal';
import { form as bulkUpdateContactsForm } from '../Admin/UserList/constants';
import { Unfinalize } from './Unfinalize';

const {
    AUCTION_PENDING,
    CLOSED,
    DRAFT,
    EVALUATION,
    FINAL,
    PENDING,
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    REVERSE_AUCTION,
    REVIEW,
} = projectStatusesDict;

const { CONTRACT_CREATED } = projectClosedSubstatusesDict;

const { NO_BID } = proposalStatusesDict;

const { IS_BID_POSTER } = userRolePermissions;

const mapStateToProps = (state) => {
    return {
        data: getData(state),
        hasBidPostAuthorization: getBidPosterAuthorized(state),
        loadingDocument: state.exportProject.get('loadingDocument'),
        loadingDocumentError: state.exportProject.get('error'),
        modalError: state.confirmation.get('error'),
        modalType: state.confirmation.get('confirmationType'),
        showModal: state.confirmation.get('show'),
        updating: state.confirmation.get('updating'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    bulkUpdateContacts: adminActions.bulkUpdateContacts,
    copyContract,
    copyProjectDocument,
    copyProjectDocumentToIntake,
    compileDocument,
    createEvaluation: evaluationActions.createEvaluation,
    createConsensusEvaluation: evaluationActions.createConsensusEvaluation,
    createContractFromProject: createContract,
    createContractPublicAuditLog,
    createNoBidProposal,
    createPost: projectPostActions.createPost,
    deleteEvaluation: evaluationActions.deleteEvaluation,
    deletePost: projectPostActions.deletePost,
    deleteProject: govProjectActions.govDeleteProject,
    deleteProposal: vendorActions.deleteProposal,
    deleteProxyBid,
    disableUser: adminActions.disableUser,
    disqualifyProposal,
    finalizeAward: propEvalActions.finalizeAward,
    hideModal: confirmationActions.hideConfirmationModal,
    initiateSave,
    initiateSubmitForReview: initiateSubmit,
    loadApprovals,
    publishProject: projectPostActions.initiateSubmit,
    reassignAndDeleteTag: deleteTag,
    releaseEvaluation: evaluationActions.releaseEvaluation,
    reopenClosedProject: govProjectActions.reopenClosedProject,
    replaceProjectApprovals,
    resetForm: reset,
    retractPost: projectPostActions.retractPost,
    showConfirmationModalError: confirmationActions.showConfirmationModalError,
    showInstructionsModal: govProjectActions.showInstructionsModal,
    showProposalSelect: propEvalActions.showProposalSelect,
    submitForm: submit,
    submitReverseAuctionBid,
    subscribeToProject,
    unawardProject: propEvalActions.unawardProject,
    unsealProposalsPricing: govProjectActions.unsealProposalsPricing,
    updateContract,
    updateGovProposal,
    updateProject: govProjectActions.govUpdateProject,
    updateQuestion: postResponse,
    updateVendorProposal: vendorActions.updateProposal,
    updatingConfirmationModal: confirmationActions.updatingConfirmationModal,
};

// @connect
class ConnectedConfirmationModal extends Component {
    static propTypes = {
        bulkUpdateContacts: PropTypes.func.isRequired,
        compileDocument: PropTypes.func.isRequired,
        copyContract: PropTypes.func.isRequired,
        copyProjectDocumentToIntake: PropTypes.func.isRequired,
        copyProjectDocument: PropTypes.func.isRequired,
        createConsensusEvaluation: PropTypes.func.isRequired,
        createContractFromProject: PropTypes.func.isRequired,
        createContractPublicAuditLog: PropTypes.func.isRequired,
        createEvaluation: PropTypes.func.isRequired,
        createNoBidProposal: PropTypes.func.isRequired,
        createPost: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
        deleteProject: PropTypes.func.isRequired,
        deleteEvaluation: PropTypes.func.isRequired,
        deletePost: PropTypes.func.isRequired,
        deleteProposal: PropTypes.func.isRequired,
        deleteProxyBid: PropTypes.func.isRequired,
        disableUser: PropTypes.func.isRequired,
        disqualifyProposal: PropTypes.func.isRequired,
        finalizeAward: PropTypes.func.isRequired,
        hasBidPostAuthorization: PropTypes.bool,
        hideModal: PropTypes.func.isRequired,
        loadApprovals: PropTypes.func.isRequired,
        loadingDocument: PropTypes.bool,
        loadingDocumentError: PropTypes.string,
        initiateSave: PropTypes.func.isRequired,
        initiateSubmitForReview: PropTypes.func.isRequired,
        modalType: PropTypes.string,
        modalError: PropTypes.string,
        publishProject: PropTypes.func.isRequired,
        reassignAndDeleteTag: PropTypes.func.isRequired,
        releaseEvaluation: PropTypes.func.isRequired,
        reopenClosedProject: PropTypes.func.isRequired,
        replaceProjectApprovals: PropTypes.func.isRequired,
        resetForm: PropTypes.func.isRequired,
        retractPost: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        showInstructionsModal: PropTypes.func.isRequired,
        showConfirmationModalError: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
        submitForm: PropTypes.func.isRequired,
        submitReverseAuctionBid: PropTypes.func.isRequired,
        subscribeToProject: PropTypes.func.isRequired,
        unawardProject: PropTypes.func.isRequired,
        unsealProposalsPricing: PropTypes.func.isRequired,
        updateContract: PropTypes.func.isRequired,
        updateGovProposal: PropTypes.func.isRequired,
        updateQuestion: PropTypes.func.isRequired,
        updateProject: PropTypes.func.isRequired,
        updateVendorProposal: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        updatingConfirmationModal: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    get styles() {
        return require('./index.scss');
    }

    get nextStageData() {
        const {
            data: { project },
        } = this.props;

        let status = PENDING;

        if (isNextStatusAuctionPending(project)) {
            status = AUCTION_PENDING;
        } else if (isNextStatusReverseAuction(project)) {
            status = REVERSE_AUCTION;
        }

        return { status, statusText: startCase(status) };
    }

    getConfirmationProps() {
        const {
            data,
            data: { attachments, project, proposal },
            hasBidPostAuthorization,
            loadingDocument,
            loadingDocumentError,
            modalError,
            modalType,
            updating,
        } = this.props;
        const isContract = project?.type === projectTypesDict.CONTRACT;
        switch (modalType) {
            case CONFIRM_BULK_CONTACTS_UPDATE: {
                return {
                    buttonText: data.buttonText,
                    buttonStyle: data.disable ? 'danger' : 'primary',
                    buttonIcon: data.disable ? 'fa-ban' : 'fa-pencil',
                    buttonUpdatingText: 'Updating...',
                    clickHandler: this.bulkUpdateContacts,
                    onHide: this.resetBulkUpdateContactsForm,
                    body: (
                        <BulkUpdateConfirmationModal
                            disable={data.disable}
                            formValues={data.formValues}
                            oldUser={data.oldUser}
                            users={data.users}
                        />
                    ),
                };
            }
            case CONSENSUS_EVALUATION: {
                const {
                    evaluation: { hasConsensusEvaluation },
                } = project;

                return {
                    buttonText: `${
                        hasConsensusEvaluation ? 'Reset' : 'Create'
                    } Consensus Scorecard`,
                    buttonUpdatingText: hasConsensusEvaluation ? 'Resetting...' : 'Creating...',
                    buttonIcon: hasConsensusEvaluation ? 'fa-refresh' : 'fa-plus',
                    buttonStyle: hasConsensusEvaluation ? 'warning' : 'success',
                    clickHandler: this.createConsensusEvaluation(hasConsensusEvaluation),
                    body: this.getDefaultBody(
                        hasConsensusEvaluation
                            ? 'Are you sure you want to generate a new Consensus Scorecard?\n\n' +
                                  'This will delete your existing consensus scorecard and replace it with ' +
                                  'a new one consisting of the current average scores of all evaluators.'
                            : 'We will generate a Consensus Scorecard for you that consists of the ' +
                                  'average scores submitted by all evaluators.\n\n' +
                                  'You will be able to edit and publicly post the Consensus Scorecard ' +
                                  'after we create the initial version.'
                    ),
                };
            }
            case COPY_CONTRACT:
                return {
                    buttonText: 'Copy Contract',
                    buttonUpdatingText: 'Copying...',
                    buttonIcon: 'fa-copy',
                    buttonStyle: 'primary',
                    clickHandler: () => this.props.copyContract(data.contract.id),
                    body: this.getDefaultBody(
                        'This will create a  copy of this contract record.\n\n' +
                            'Please note: Only the contract record will be copied. Attachments, insurance, notifications, and vendor information will not be copied to the new contract.'
                    ),
                };
            case COPY_DOCUMENT:
                return {
                    buttonText: 'Copy Document',
                    buttonUpdatingText: 'Copying...',
                    buttonIcon: 'fa-copy',
                    clickHandler: this.copyProjectDocument,
                    body: this.getDefaultBody(
                        'This will create an exact copy of this document.\n\n' +
                            'Please note: the copy will not include any new template language that may ' +
                            'have been added since the original document was drafted.'
                    ),
                };
            case CREATE_CONTRACT_FROM_INTAKE:
                return {
                    buttonText: 'Create Contract',
                    buttonUpdatingText: 'Creating...',
                    buttonIcon: 'fa-plus',
                    clickHandler: this.createContractFromIntake,
                    body: this.getDefaultBody(
                        'Are you sure you want to go directly into contract?\n\n' +
                            'This will pre-empt the solicitation process, so only select this option if ' +
                            'you will not be issuing a solicitation.'
                    ),
                };
            case CREATE_INTAKE_FROM_PROJECT: {
                const projectToIntakeDisclaimer =
                    project.isIntake || project.intake_id
                        ? 'Note: The copy will not include any new template language that may have been added since the original intake was drafted.'
                        : 'Note: Sections from the solicitation that are present in the intake request template will be copied, and Set Up Questions will be taken from the intake request template.';
                return {
                    buttonText: 'Create Intake',
                    buttonUpdatingText: 'Creating...',
                    buttonIcon: 'fa-rss',
                    clickHandler: this.copyProjectDocumentToIntake,
                    body: this.getDefaultBody(
                        `This will create a new intake request from this project.\n${projectToIntakeDisclaimer}`
                    ),
                };
            }
            case DELETE:
            case DELETE_EVALUATION:
            case DELETE_POST:
            case DELETE_PROPOSAL:
            case DELETE_INTAKE: {
                const deleteConfirmationText =
                    modalType === DELETE_EVALUATION
                        ? 'By clicking delete, you will delete your entire evaluation.\nThis action is final and cannot be reversed!\n\nPlease reach out to support if you have any questions before deleting.'
                        : 'This action is final and cannot be reversed!';
                return {
                    buttonText: 'Delete',
                    buttonUpdatingText: 'Deleting...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-trash-o',
                    clickHandler: this.deleteHandler,
                    body: this.getDefaultBody(deleteConfirmationText),
                };
            }
            case END_AUCTION_REJECT_RESULTS: {
                return {
                    buttonText: `End Auction`,
                    buttonUpdatingText: 'Ending...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-stop-circle',
                    clickHandler: this.endAuctionRejectResults,
                    body: this.getDefaultBody(
                        'This will end the current reverse auction and reject all bids.\n' +
                            'Note: The original response prices will be used for evaluation.'
                    ),
                };
            }
            case EVALUATE:
                return {
                    buttonText: 'Start Evaluation',
                    buttonUpdatingText: 'Creating Evaluation...',
                    buttonIcon: 'fa-star',
                    clickHandler: this.triggerFormSubmit(evaluationTypeForm),
                    body: (
                        <EvaluationTypeForm
                            disabled={updating}
                            onSubmit={this.createEvaluationFromProject}
                            priceTables={project.priceTables}
                            projectSections={project.projectSections}
                        />
                    ),
                };
            case EXCLUDE_QUESTION:
                return {
                    buttonText: 'Exclude Question',
                    buttonUpdatingText: 'Excluding Question...',
                    buttonIcon: 'fa-ban',
                    buttonStyle: 'danger',
                    clickHandler: this.triggerFormSubmit(excludeQuestionForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <ExcludeQuestionForm disabled={updating} onSubmit={this.excludeQuestion} />
                    ),
                };
            case CREATE_CONTRACT_FROM_PROJECT:
                return {
                    buttonText: 'Add Contract',
                    buttonUpdatingText: 'Adding Contract...',
                    buttonIcon: 'fa-plus',
                    buttonStyle: 'success',
                    clickHandler: this.triggerFormSubmit(contractFromProjectForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <ContractFromProjectForm
                            disabled={updating}
                            onSubmit={this.createContractFromProject}
                        />
                    ),
                };
            case TOGGLE_CONTRACT_PUBLIC_PRIVATE:
                return {
                    buttonText: 'Make Private',
                    buttonUpdatingText: 'Submitting...',
                    buttonIcon: 'fa-lock',
                    buttonStyle: 'danger',
                    clickHandler: this.triggerFormSubmit(contractPublicAuditForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <ContractPublicAuditForm
                            attachments={attachments}
                            disabled={updating}
                            onSubmit={this.toggleContractPublicPrivate}
                        />
                    ),
                };
            case NO_BID_EXISTING_PROPOSAL:
            case NO_BID_GOV_PROPOSAL:
            case NO_BID_NEW_PROPOSAL:
                return {
                    buttonText: 'No Bid Project',
                    buttonUpdatingText: 'No Bidding Project...',
                    buttonIcon: 'fa-ban',
                    buttonStyle: 'danger',
                    clickHandler: this.triggerFormSubmit(noBidProposalForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: <NoBidForm disabled={updating} onSubmit={this.noBidHandler} />,
                };
            case PAUSE_PROJECT:
                return {
                    buttonText: project.isPaused ? 'Take Off Hold' : 'Put On Hold',
                    buttonUpdatingText: project.isPaused
                        ? 'Taking Off Hold...'
                        : 'Putting On Hold...',
                    buttonStyle: project.isPaused ? 'success' : 'warning',
                    buttonIcon: project.isPaused ? 'fa-play-circle' : 'fa-pause-circle',
                    clickHandler: project.isPaused
                        ? this.unpauseProject
                        : this.triggerFormSubmit(projectHoldForm),
                    body: project.isPaused ? (
                        this.getDefaultBody(
                            'Take your project off hold to indicate that it is actively being worked on and progressing.'
                        )
                    ) : (
                        <ProjectHoldForm
                            disabled={updating}
                            onSubmit={this.pauseProject}
                            project={project}
                        >
                            {this.getDefaultBody(
                                'Put your project on hold to indicate that is not actively being worked on or progressed. You will still be able to update the project as needed.'
                            )}
                        </ProjectHoldForm>
                    ),
                };
            case PROXY_BID_REVERSE_AUCTION:
                return {
                    buttonText: 'Confirm Proxy Bid',
                    buttonUpdatingText: 'Submitting...',
                    buttonStyle: 'primary',
                    clickHandler: this.triggerFormSubmit(proxyBidReverseAuctionForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    ...(data.currentVendorProxyBid && {
                        tertiaryButton: (
                            <Button bsStyle="link" onClick={this.clearProxyBid}>
                                Clear Proxy Bid
                            </Button>
                        ),
                    }),
                    title: 'Set Proxy Bid',
                    body: (
                        <ProxyBidReverseAuctionForm
                            auctionMaxFractionDigits={data.auctionMaxFractionDigits}
                            currentLowestBid={data.currentLowestBid}
                            description={data.description}
                            minBidDecrement={data.minBidDecrement}
                            onSubmit={this.setProxyBid}
                            quantity={data.quantity}
                            {...(data.currentVendorProxyBid && {
                                initialValues: { proxyBid: data.currentVendorProxyBid.unitPrice },
                                currentProxyBidObject: data.currentVendorProxyBid,
                            })}
                        />
                    ),
                };
            case REASSIGN_TAG:
                return {
                    buttonText: 'Reassign & Delete Tag',
                    buttonUpdatingText: 'Deleting...',
                    buttonStyle: 'warning',
                    buttonIcon: 'fa-tags',
                    clickHandler: this.triggerFormSubmit(reassignTagForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <ReassignTagForm
                            disabled={updating}
                            onSubmit={this.reassignAndDeleteTag}
                            options={data.replacementTags}
                        />
                    ),
                };
            case REOPEN_CLOSED_PROJECT:
                return {
                    buttonText: 'Reopen Project',
                    buttonUpdatingText: 'Reopening...',
                    buttonStyle: 'warning',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.reopenClosedProject,
                    body: this.getDefaultBody(
                        'Reopening the project will take it back to its previous state, show it ' +
                            'as active, and allow it to be editable again.\n\n' +
                            'Are you sure you want to continue?'
                    ),
                };
            case REOPEN_CLOSED_INTAKE:
                return {
                    buttonText: 'Reopen Project Request',
                    buttonUpdatingText: 'Reopening...',
                    buttonStyle: 'warning',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.reopenClosedIntake,
                    body: this.getDefaultBody(
                        'Reopening the project request will take it back to the "Review" state and ' +
                            'require it be processed again.\n\n' +
                            'Are you sure you want to continue?'
                    ),
                };
            case REOPEN_DRAFT_STATUS:
                return {
                    buttonText: 'Go Back to Draft',
                    buttonUpdatingText: 'Updating...',
                    buttonStyle: 'warning',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.reopenDraftStatus,
                    body: this.getDefaultBody(
                        'Going back to the "Draft" state will put approvals on hold and allow you to ' +
                            'freely edit your document. Once you are done editing, you will need to ' +
                            'submit for review again.\n\n' +
                            'Are you sure you want to continue?'
                    ),
                };
            case REOPEN_PROJECT_INTAKE:
                return {
                    buttonText: 'Reopen Project Request',
                    buttonUpdatingText: 'Reopening...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.deleteProject,
                    body: this.getDefaultBody(
                        'Reopening the project request will delete the document ' +
                            'you are currently drafting. Are you sure you want to continue?'
                    ),
                };
            case REOPEN_BUILDER:
                return {
                    buttonText: 'Reopen Builder',
                    buttonUpdatingText: 'Reopening...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.deletePost,
                    body: this.getDefaultBody(
                        'Going back to the project builder will delete the information in the post ' +
                            'you are currently drafting. Are you sure you want to go back?'
                    ),
                };
            case SKIP_TO_NEXT_STAGE: {
                const {
                    data: {
                        project: { status },
                    },
                } = this.props;

                const bodyText = () => {
                    if (status === AUCTION_PENDING) {
                        return 'the reverse auction has started';
                    }
                    if (status === REVERSE_AUCTION) {
                        return 'the reverse auction has ended';
                    }
                    return 'the due date for response submissions had passed';
                };

                return {
                    buttonText: `Skip to ${this.nextStageData.statusText}`,
                    buttonUpdatingText: 'Skipping...',
                    buttonStyle: 'primary',
                    buttonIcon: 'fa-fast-forward',
                    clickHandler: this.skipToNextStage,
                    body: this.getDefaultBody(
                        `This will move the project to the ${this.nextStageData.statusText} state as if ` +
                            `${bodyText()}. ` +
                            'This is a demo only option.'
                    ),
                };
            }
            case FINALIZE:
                return {
                    buttonText: 'Finalize',
                    buttonUpdatingText: 'Finalizing...',
                    buttonIcon: 'fa-check-square-o',
                    clickHandler: this.finalizeProject,
                    body: this.getDefaultBody(
                        'Finalizing your document will lock it to prevent edits from being made.\n' +
                            'It is typically the final step in the document drafting process.'
                    ),
                };
            case FINALIZE_CONTRACT_PACKAGE:
                return {
                    buttonText: 'Finalize and Lock Contract Packet',
                    buttonUpdatingText: 'Finalizing...',
                    buttonIcon: 'fa-check-square-o',
                    clickHandler: this.finalizeContractPackage,
                    body: loadingDocument ? (
                        <Box py={4}>
                            <LoadingSpinner
                                noPadding
                                size="medium"
                                text={`Please wait while we prepare your ${isContract ? 'packet' : 'document'}...`}
                                useOpenGovStyle
                            />
                        </Box>
                    ) : (
                        <>
                            {!!loadingDocumentError && (
                                <Box mb={3} textAlign="left">
                                    <Alert severity="error" variant="filled">
                                        <AlertTitle>Contract Packet Failed to Compile</AlertTitle>
                                        <Typography>
                                            Please try again. Contact support if the issue
                                            continues.
                                        </Typography>
                                    </Alert>
                                </Box>
                            )}
                            {this.getDefaultBody(contractPackagePublishExplanation())}
                        </>
                    ),
                    hideButtons: loadingDocument,
                };
            case UNFINALIZE:
                return {
                    buttonText: 'Go Back to Review',
                    buttonUpdatingText: 'Updating...',
                    buttonStyle: 'warning',
                    buttonIcon: 'fa-unlock-alt',
                    clickHandler: this.unfinalizeProject,
                    body: isContract ? (
                        <Unfinalize
                            clickHandler={this.unfinalizeProject}
                            copyProjectDocument={this.copyProjectDocument}
                            hideModal={this.hideModal}
                            project={project}
                        />
                    ) : (
                        this.getDefaultBody(
                            'Unlocking the document will take it back to the ' +
                                '"Review" state and allow the content to be edited ' +
                                'again.\n\nPlease only take this action if you need ' +
                                'to edit your document.'
                        )
                    ),
                    hideButtons: isContract,
                };
            case POST:
                return {
                    buttonText: 'Draft Posting',
                    buttonUpdatingText: 'Drafting Posting...',
                    buttonIcon: 'fa-flag',
                    clickHandler: this.createPostFromExistingProject,
                    body: this.getDefaultBody(
                        'Drafting a posting will let you specify how vendors ' +
                            'should respond to your project. Once the post is ' +
                            'complete it will be accessible to the public and vendors.'
                    ),
                    info: hasBidPostAuthorization ? undefined : (
                        <AdminRequired
                            actionText="create postings"
                            contactText="contact a bid poster"
                            permissionType={IS_BID_POSTER}
                        />
                    ),
                    disabled: !hasBidPostAuthorization,
                };
            case PUBLISH:
                return {
                    buttonText: 'Publish Post',
                    buttonUpdatingText: 'Publishing...',
                    buttonIcon: 'fa-flag',
                    clickHandler: this.props.publishProject,
                    body: this.getDefaultBody(
                        'Publishing the project will make it viewable to the ' +
                            'public and to vendors.\nYou will not be able to edit ' +
                            'the project after publishing, but will be able to ' +
                            'issue addenda and respond to questions.'
                    ),
                };
            case RESEAL_BIDS:
                return {
                    buttonText: 'Reseal Bids',
                    buttonUpdatingText: 'Resealing...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-lock',
                    clickHandler: this.resealBids,
                    body: this.getDefaultBody(
                        'Are you sure you want to reseal the bids for this project?' +
                            `${
                                !__DEMO__ && !__DEV__
                                    ? '\n\nWARNING: This is not a recommended course of action and ' +
                                      'should only be used if absolutely necessary.'
                                    : ''
                            }`
                    ),
                };
            case RETRACT_POST: {
                const bodyText = () => {
                    const {
                        status,
                        template: { isReverseAuction },
                    } = project;

                    const isPending = status === PENDING;

                    const pendingStatusText =
                        'WARNING: This post has been publicly posted and the due date has ' +
                        'already passed! Are you sure you want to retract this post? It will no ' +
                        'longer be publicly available once retracted.';

                    const defaultText =
                        'Once retracted, your post will no longer ' +
                        'be publicly available. You can then make ' +
                        'any necessary edits and post it publicly again.' +
                        '\n\nPlease confirm you would like to retract the post.';

                    const reverseAuctionText =
                        'Retracting this post will also delete all reverse auction bids.';

                    return (
                        <>
                            <p>{isPending ? pendingStatusText : defaultText}</p>
                            {isReverseAuction && isPending && (
                                <p className="text-warning">{reverseAuctionText}</p>
                            )}
                        </>
                    );
                };
                return {
                    buttonText: 'Retract',
                    buttonUpdatingText: 'Retracting...',
                    buttonStyle: 'danger',
                    buttonIcon: 'fa-undo',
                    clickHandler: this.retractPost,
                    body: this.getDefaultBody(bodyText()),
                };
            }
            case RELEASE_EVALUATION: {
                const info =
                    'Releasing the evaluation will send emails to all evaluators, make ' +
                    'the responses viewable to them, and request their review and evaluation.';

                return {
                    buttonText: 'Release Evaluation',
                    buttonUpdatingText: 'Releasing Evaluation...',
                    buttonIcon: 'fa-send',
                    clickHandler: project.showBids
                        ? () => this.releaseEvaluation() // Needed so event isn't sent as form data
                        : this.triggerFormSubmit(unsealBidsForm),
                    body: project.showBids ? (
                        this.getDefaultBody(info)
                    ) : (
                        <UnsealBidsForm
                            disabled={updating}
                            isBidUnsealed={project.isBidUnsealed}
                            onSubmit={this.releaseEvaluation}
                            projectId={project.id}
                            text={info}
                        />
                    ),
                };
            }
            case INTAKE_INCOMPLETE:
                return {
                    buttonText: 'Mark Incomplete',
                    buttonUpdatingText: 'Submitting...',
                    buttonIcon: 'fa-undo',
                    buttonStyle: 'danger',
                    clickHandler: this.triggerFormSubmit(intakeIncompleteForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <IntakeIncompleteForm
                            disabled={updating}
                            onSubmit={this.intakeIncomplete}
                        />
                    ),
                };
            case START_REVIEW_PROCESS: {
                return {
                    buttonText: modalError ? 'Check for Errors' : 'Submit For Review',
                    buttonUpdatingText: 'Submitting...',
                    ...(!modalError && {
                        buttonIcon: 'fa-check-square-o',
                    }),
                    buttonStyle: modalError ? 'primary' : 'success',
                    clickHandler: modalError
                        ? this.redirectToReviewConfirmation
                        : this.startReviewProcess,
                    body: this.getDefaultBody(
                        modalError ? (
                            <>
                                <b>
                                    Please check for errors before submitting your approval requests
                                </b>
                                <br />
                                <br />
                                Once your document has been validated, you can submit it for review.
                            </>
                        ) : (
                            <>
                                <b>Ready for your approval requests to be sent?</b>
                                <br />
                                <br />
                                If you need to make changes to the list of approvers, please hit
                                &quot;Cancel&quot;, make the necessary changes to your review steps,
                                and then submit.
                            </>
                        )
                    ),
                };
            }
            case CHANGE_PRESET_WORKFLOW:
                return {
                    buttonText: 'Update Approvals',
                    buttonUpdatingText: 'Updating...',
                    buttonStyle: 'primary',
                    secondaryButtonText: 'Leave Approvals As Is',
                    clickHandler: this.replaceDepartmentReviewSteps,
                    title: 'Approval Set Up Confirmation',
                    body: this.getDefaultBody(
                        <>
                            <b>Do you want to update the approvals for this project?</b>
                            <br />
                            <br />
                            The Department has been updated from{' '}
                            <strong>{this.props.data.currentDepartmentName}</strong> to{' '}
                            <strong>{this.props.data.selectedDepartmentValue.name}</strong>.
                            <br />
                            <br />
                            <strong>{this.props.data.selectedDepartmentValue.name}</strong> has a
                            different pre-set workflow for approvals. Do you want to update the
                            approvers and sequence for this project to match?
                        </>
                    ),
                };
            case SUBMIT_INTAKE_FOR_REVIEW:
                return {
                    buttonText: 'Submit For Review',
                    buttonUpdatingText: 'Submitting...',
                    buttonIcon: 'fa-check-square-o',
                    clickHandler: this.submitIntakeForReview,
                    body: this.getDefaultBody(
                        'Ready to start the review and approval process?\nYou will still be able ' +
                            'to make changes to your document.'
                    ),
                };
            case UNSEAL_BIDS:
                return {
                    buttonText: `Unseal ${project.showBids ? 'Pricing' : 'Bids'}`,
                    buttonUpdatingText: 'Unsealing...',
                    buttonIcon: 'fa-envelope-open',
                    clickHandler: this.triggerFormSubmit(unsealBidsForm),
                    body: (
                        <UnsealBidsForm
                            disabled={updating}
                            isBidUnsealed={project.isBidUnsealed}
                            onSubmit={this.unsealBids}
                            projectId={project.id}
                        />
                    ),
                };
            case AWARD_PROJECT_FROM_PENDING:
                return {
                    buttonText: 'Award Project',
                    buttonUpdatingText: 'Creating Award...',
                    buttonStyle: 'success',
                    buttonIcon: 'fa-trophy',
                    clickHandler: this.awardProjectFromPendingStatus,
                    body: this.getDefaultBody(
                        'Awarding the project from Pending will create an empty evaluation ' +
                            'that will allow you to directly award the project without going ' +
                            'through the evaluation process.'
                    ),
                };
            case UNAWARD_PROJECT:
                return {
                    buttonText: 'Unaward Project',
                    buttonUpdatingText: 'Unawarding...',
                    buttonIcon: 'fa-undo',
                    buttonStyle: 'danger',
                    clickHandler: this.unawardProject,
                    body: this.getDefaultBody(
                        'Unawarding the project will move the project back ' +
                            'to the evaluation phase and reset the awarded ' +
                            'responses. You will then be able to award the project again.'
                    ),
                    info: hasBidPostAuthorization ? undefined : (
                        <AdminRequired
                            actionText="unaward the project"
                            contactText="contact a bid poster"
                            permissionType={IS_BID_POSTER}
                        />
                    ),
                    disabled: !hasBidPostAuthorization,
                };
            case DISQUALIFY_PROPOSAL:
                return {
                    buttonText: 'Disqualify Response',
                    buttonUpdatingText: 'Disqualifying Response...',
                    buttonIcon: 'fa-ban',
                    buttonStyle: 'danger',
                    clickHandler: this.triggerFormSubmit(disqualifyProposalForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <DisqualifyProposalForm
                            disabled={updating}
                            isGovernmentSubmitted={proposal.isGovernmentSubmitted}
                            onSubmit={this.disqualifyProposal}
                        />
                    ),
                };
            case CLOSE_PROJECT:
                return {
                    buttonText: 'Close Out Project',
                    buttonUpdatingText: 'Closing Project...',
                    buttonIcon: 'fa-archive',
                    buttonStyle: 'primary',
                    clickHandler: this.triggerFormSubmit(closeOutProjectForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <CloseOutProjectForm
                            disabled={updating}
                            onSubmit={this.closeOutProject}
                            project={project}
                        />
                    ),
                };
            case FINALIZE_AWARD:
                return {
                    buttonText: 'Finalize Award',
                    buttonUpdatingText: 'Awarding...',
                    buttonIcon: 'fa-check-square-o',
                    buttonStyle: 'success',
                    clickHandler: this.triggerFormSubmit(finalizeAwardForm),
                    body: hasBidPostAuthorization ? (
                        // `onSubmit` must be passed as prop to form for remote submission to work
                        <FinalizeAwardForm
                            disabled={updating}
                            onSubmit={this.finalizeAward}
                            project={project}
                        />
                    ) : (
                        <AdminRequired
                            actionText="finalize awards"
                            contactText="contact a bid poster"
                            permissionType={IS_BID_POSTER}
                        />
                    ),
                    disabled: !hasBidPostAuthorization,
                };
            case VENDOR_FOLLOW:
                return {
                    buttonText: 'Follow Project',
                    buttonUpdatingText: 'Following Project...',
                    buttonIcon: 'fa-rss',
                    buttonStyle: 'success',
                    clickHandler: this.triggerFormSubmit(vendorFollowForm),
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <VendorFollowTypeForm
                            disabled={updating}
                            onSubmit={this.subscribeVendorToProject}
                        />
                    ),
                };
            case UPDATE_RECORD_CLOSE_DATE:
                return {
                    buttonText: 'Save Change',
                    buttonUpdatingText: 'Saving...',
                    buttonIcon: 'fa-save',
                    buttonStyle: 'primary',
                    clickHandler: this.triggerFormSubmit(updateRecordCloseDateForm),
                    title: 'Change Project End Date',
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <UpdateRecordCloseDateForm
                            disabled={updating}
                            initialValues={{ recordCloseDate: data.recordCloseDate }}
                            onSubmit={this.updateRecordCloseDate}
                        />
                    ),
                };
            case UPDATE_RETENTION_POLICY:
                return {
                    buttonText: 'Save Change',
                    buttonUpdatingText: 'Saving...',
                    buttonIcon: 'fa-save',
                    buttonStyle: 'primary',
                    clickHandler: this.triggerFormSubmit(updateRetentionPolicyForm),
                    title: 'Change Retention Policy',
                    // `onSubmit` must be passed as prop to form for remote submission to work
                    body: (
                        <UpdateRetentionPolicyForm
                            closeDate={data.closeDate}
                            disabled={updating}
                            initialValues={{
                                retentionPolicy: data.retentionCodeId,
                            }}
                            onSubmit={
                                data.isProject
                                    ? this.updateProjectRetentionPolicy
                                    : this.updateContractRetentionPolicy
                            }
                        />
                    ),
                };
            default:
                return {};
        }
    }

    get deleteHandler() {
        const { modalType } = this.props;

        switch (modalType) {
            case DELETE:
            case DELETE_INTAKE:
                return this.deleteProject;
            case DELETE_POST:
                return this.deletePost;
            case DELETE_EVALUATION:
                return this.deleteEvaluation;
            case DELETE_PROPOSAL:
                return this.deleteProposal;
            default:
                return () => {};
        }
    }

    getDefaultBody = (text) => {
        return <p className={`text-muted ${this.styles.warningText}`}>{text}</p>;
    };

    closeOutProject = (closeOutFormData) => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Closed Out');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: CLOSED,
        });

        let onComplete;

        // When closing out intakes reload the approvals after updating project
        if (project.status === REQUEST_REVIEW) {
            onComplete = () => this.props.loadApprovals(project.id, { broadcast: true });
        }

        const updateData = { ...closeOutFormData, status: CLOSED };
        return updateProject(project, updateData, { modal: true, onComplete });
    };

    createContractFromIntake = () => {
        const {
            data: { project },
            createContractFromProject,
        } = this.props;

        return this.closeOutProject({
            closedSubstatus: CONTRACT_CREATED,
            closeOutReason: 'Contract Created From Intake',
        }).then(() => createContractFromProject({}, project.id));
    };

    createContractFromProject = (unlinkBudget) => {
        const {
            data: { project, contractData },
            createContractFromProject,
        } = this.props;

        return createContractFromProject({ ...contractData, unlinkBudget }, project.id);
    };

    excludeQuestion = (formData) => {
        const {
            data: { projectId, questionId },
            updateQuestion,
        } = this.props;

        const data = {
            status: questionStatusTypes.EXCLUDED,
            ...formData,
        };

        return updateQuestion(null, projectId, questionId, null, data, {
            message: 'Question Excluded',
            modal: true,
        });
    };

    noBidHandler = (noBidFormData) => {
        const {
            data: { nextRoute, projectId, proposal },
            modalType,
            router,
        } = this.props;

        const noBidData = {
            ...noBidFormData,
            status: NO_BID,
        };

        const updateOptions = {
            modal: true,
            onUpdate: () => router.push(nextRoute),
        };

        if (modalType === NO_BID_NEW_PROPOSAL) {
            return this.props.createNoBidProposal(projectId, noBidData);
        }
        if (modalType === NO_BID_GOV_PROPOSAL) {
            return this.props.updateGovProposal(proposal.id, noBidData, updateOptions);
        }
        return this.props.updateVendorProposal(proposal.id, noBidData, updateOptions);
    };

    unpauseProject = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Off Hold');

        return updateProject(project, { isPaused: false, isPausedReason: null }, { modal: true });
    };

    pauseProject = (formData) => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project On Hold');

        return updateProject(project, { isPaused: true, ...formData }, { modal: true });
    };

    awardProjectFromPendingStatus = () => {
        const {
            data: { project },
            showProposalSelect,
            updateProject,
            router,
        } = this.props;

        return updateProject(
            project,
            {},
            {
                endpoint: `/project/${project.id}/award-from-pending`,
                modal: true,
                onComplete: (result) => {
                    const nextRoute = `/governments/${project.government.id}/projects/${project.id}/evaluation/aggregate-evaluations`;

                    trackEvent('Project Status Update', {
                        projectId: project.id,
                        oldStatus: project.status,
                        newStatus: result.status,
                    });
                    trackEvent('Evaluation Created', {
                        projectId: result.id,
                        evaluationId: result.evaluation_id,
                        isEvaluationOnly: false,
                    });

                    showProposalSelect();
                    router.push(nextRoute);
                },
            }
        );
    };

    createEvaluationFromProject = (evaluationType) => {
        const {
            data: { project },
        } = this.props;

        this.props.createEvaluation(project, evaluationType);
    };

    copyProjectDocument = () => {
        const {
            data: { project },
        } = this.props;

        return this.props.copyProjectDocument(project.id, {
            contractRecordId: project.contractRecords[0]?.id,
        });
    };

    copyProjectDocumentToIntake = () => {
        const {
            data: { project },
        } = this.props;

        return this.props.copyProjectDocumentToIntake(project.id);
    };

    createConsensusEvaluation = (hasConsensusEvaluation) => () => {
        const {
            data: { project },
        } = this.props;

        return this.props.createConsensusEvaluation(project.id, { reload: hasConsensusEvaluation });
    };

    createPostFromExistingProject = () => {
        const {
            createPost,
            data: { project },
        } = this.props;
        return createPost(project);
    };

    deleteProject = () => {
        const {
            data: { project },
            deleteProject,
        } = this.props;
        return deleteProject(project);
    };

    deleteEvaluation = () => {
        const {
            data: { project },
            deleteEvaluation,
        } = this.props;
        return deleteEvaluation(project);
    };

    deletePost = () => {
        const {
            data: { project },
            deletePost,
        } = this.props;
        return deletePost(project);
    };

    deleteProposal = () => {
        const {
            data: { proposal },
            deleteProposal,
        } = this.props;
        return deleteProposal(proposal.id, { modal: true });
    };

    bulkUpdateContacts = () => {
        const {
            data: { disable, formValues, oldUser },
            bulkUpdateContacts,
            disableUser,
        } = this.props;
        if (disable) {
            disableUser(oldUser);
        }

        if (formValues && Object.keys(formValues).length > 0) {
            bulkUpdateContacts({ oldUser, ...formValues });
        }

        return this.hideModal();
    };

    disqualifyProposal = (disqualifyProposalFormData) => {
        const {
            data: { proposal },
        } = this.props;

        const data = { ...disqualifyProposalFormData, isDisqualified: true };
        return this.props.disqualifyProposal(proposal, data, { modal: true });
    };

    finalizeAward = (formData) => {
        const {
            data: { project },
            finalizeAward,
        } = this.props;

        trackEvent('Project Award Finalized');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: CLOSED,
        });

        return finalizeAward(project, formData);
    };

    finalizeProject = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Finalized');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: FINAL,
        });
        return updateProject(
            project,
            { status: FINAL },
            {
                modal: true,
                onComplete: () => this.props.showInstructionsModal('finalized', project),
            }
        );
    };

    finalizeContractPackage = async () => {
        const {
            data: { project },
            router,
            updateProject,
        } = this.props;

        trackEvent('Project Finalized');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: FINAL,
        });

        const result = await this.props.compileDocument(
            project.id,
            false,
            projectDeliveryMethods.SAVE
        );

        if (result && result instanceof Error) {
            return;
        }

        return updateProject(
            project,
            { status: FINAL },
            {
                modal: true,
            }
        ).then(() => {
            router.push(
                `governments/${project.government_id}/contracts/${project.contractRecords[0].id}/documents`
            );
        });
    };

    unfinalizeProject = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Unfinalized');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: REVIEW,
        });

        return updateProject(project, { status: REVIEW }, { modal: true });
    };

    reassignAndDeleteTag = (formData) => {
        const {
            data: { tagId },
            reassignAndDeleteTag,
        } = this.props;

        reassignAndDeleteTag(tagId, formData.replacementId, { modal: true });
    };

    reopenClosedProject = () => {
        const {
            data: { project },
            reopenClosedProject,
        } = this.props;

        return reopenClosedProject(project.id);
    };

    reopenClosedIntake = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Intake Reopened');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: REQUEST_REVIEW,
        });

        return updateProject(
            project,
            {},
            {
                endpoint: `/project/${project.id}/intakes/reopen`,
                modal: true,
                onComplete: () => this.props.loadApprovals(project.id, { broadcast: true }),
            }
        );
    };

    reopenDraftStatus = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Moved Back to Draft');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: DRAFT,
        });

        return updateProject(project, { status: DRAFT }, { modal: true });
    };

    intakeIncomplete = (formData) => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Intake Incomplete');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: REQUEST_DRAFT,
        });

        return updateProject(project, formData, {
            endpoint: `/project/${project.id}/intakes/incomplete`,
            modal: true,
            onComplete: () => this.props.loadApprovals(project.id, { broadcast: true }),
        });
    };

    resealBids = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        return updateProject(
            project,
            {},
            {
                endpoint: `/project/${project.id}/reseal-bids`,
                modal: true,
            }
        );
    };

    resetBulkUpdateContactsForm = () => {
        const { resetForm } = this.props;
        this.hideModal();
        return resetForm(bulkUpdateContactsForm);
    };

    retractPost = () => {
        const {
            data: { project },
            retractPost,
        } = this.props;
        return retractPost(project);
    };

    releaseEvaluation = (formData) => {
        const {
            data: { project },
        } = this.props;

        const data = {
            ...formData,
            isBidUnsealed: true,
        };
        return this.props.releaseEvaluation(project, data);
    };

    endAuctionRejectResults = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        return updateProject(
            project,
            { auctionEndDate: new Date(), status: PENDING, wasAuctionRejected: true },
            { modal: true }
        );
    };

    clearProxyBid = () => {
        const { currentVendorProxyBid, description, proposalId } = this.props.data;

        this.props.deleteProxyBid(proposalId, currentVendorProxyBid.id, description);
    };

    setProxyBid = ({ proxyBid }) => {
        const bidAmount = Number.parseFloat(proxyBid);
        const {
            data: { proposalId, vendorPriceItemId },
        } = this.props;

        this.props.submitReverseAuctionBid(proposalId, {
            bidAmount,
            isProxyBid: true,
            vendorPriceItemId,
        });
    };

    skipToNextStage = async () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        const oldStatus = project.status;
        const newStatus = this.nextStageData.status;
        const endActiveAuction = oldStatus === REVERSE_AUCTION && newStatus === PENDING;

        if (endActiveAuction) {
            // Ending an auction requires the end date to have passed so we update that first
            await updateProject(project, { auctionEndDate: new Date() });
        }

        trackEvent(`Project Forced to ${this.nextStageData.statusText}`);
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus,
            newStatus,
        });

        return updateProject(project, { status: newStatus }, { modal: true });
    };

    replaceDepartmentReviewSteps = () => {
        const {
            data: { departmentId, projectId },
        } = this.props;

        return this.props.replaceProjectApprovals(projectId, departmentId, {
            onComplete: () => {
                this.props.initiateSave(true);
            },
        });
    };

    startReviewProcess = () => {
        const {
            data: { project },
            updateProject,
        } = this.props;

        trackEvent('Project Submitted For Review');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: REVIEW,
        });

        return updateProject(
            project,
            { status: REVIEW },
            { modal: true, onComplete: () => this.props.showInstructionsModal('review') }
        );
    };

    redirectToReviewConfirmation = () => {
        const {
            data: { writingPath },
            router,
        } = this.props;

        router.push(`${writingPath}?section=confirmation`);
    };

    submitIntakeForReview = () => {
        const { initiateSubmitForReview } = this.props;

        trackEvent('Submit for Review');
        initiateSubmitForReview();
    };

    subscribeVendorToProject = (formData) => {
        const {
            data: { onComplete, project },
            user,
        } = this.props;

        this.props.subscribeToProject(project, false, user, formData, {
            modal: true,
            onComplete,
        });
    };

    toggleContractPublicPrivate = (formData) => {
        const {
            data: { attachments, isPublic, contractId },
        } = this.props;

        this.props.updateContract(
            contractId,
            {
                isPublic,
                attachments,
            },
            () => {
                this.props.createContractPublicAuditLog(contractId, {
                    attachmentId: attachments ? attachments[0].id : null,
                    comment: formData.comment,
                });
            }
        );
    };

    /*
     * Trigger a remote redux-form submission event. Used for cases where the modal body is a form.
     * In such cases the confirmation button is used to remotely trigger a submit event of the
     * specified form.
     */
    triggerFormSubmit = (formName) => () => {
        const { submitForm } = this.props;

        submitForm(formName);
    };

    updateRecordCloseDate = (formData) => {
        const {
            data: { project },
        } = this.props;

        const updateData = { recordCloseDate: formData.recordCloseDate };

        this.props.updateProject(project, updateData, {
            endpoint: `/project/${project.id}/record-close-date`,
            modal: true,
            snackbarMessage: 'Close Date Updated',
        });
    };

    updateContractRetentionPolicy = (formData) => {
        const {
            data: { contractId },
        } = this.props;

        this.props.updateContract(
            contractId,
            {
                retention_code_id: formData.retentionPolicy,
            },
            () => {
                this.hideModal();
            }
        );
    };

    updateProjectRetentionPolicy = (formData) => {
        const {
            data: { project },
        } = this.props;

        const updateData = { retention_code_id: formData.retentionPolicy };

        this.props.updateProject(project, updateData, {
            endpoint: `/project/${project.id}/retention-policy`,
            modal: true,
            snackbarMessage: 'Retention Policy Updated',
        });
    };

    unsealBids = (formData) => {
        const {
            data: { project },
            hideModal,
            showConfirmationModalError,
            unsealProposalsPricing,
            updateProject,
            updatingConfirmationModal,
        } = this.props;

        const { proposalIds, ...bidData } = formData;

        trackEvent('Project Unsealed');

        const updateOpts = { rethrowError: true };
        if (proposalIds) {
            updateOpts.onComplete = () => unsealProposalsPricing(project.id, proposalIds);
        }

        updatingConfirmationModal();
        return updateProject(project, bidData, updateOpts)
            .then(() => hideModal())
            .catch((error) => showConfirmationModalError(error.message));
    };

    unawardProject = () => {
        const {
            data: { project },
            unawardProject,
        } = this.props;

        trackEvent('Project Unawarded');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: EVALUATION,
        });

        return unawardProject(project.id);
    };

    hideModal = () => {
        const { hideModal, modalType } = this.props;

        switch (modalType) {
            case VENDOR_FOLLOW:
                this.subscribeVendorToProject({});
                break;
            default:
                break;
        }

        hideModal();
    };

    render() {
        const { modalError, showModal, updating } = this.props;

        const confirmationProps = this.getConfirmationProps();

        return (
            <ConfirmationModalComponent
                error={modalError}
                onHide={this.hideModal}
                show={showModal}
                updating={updating}
                {...confirmationProps}
            />
        );
    }
}

export const ConfirmationModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedConfirmationModal);
