import { Settings as SettingsIcon } from '@mui/icons-material';
import {
    purchaseDetailsModeOptions,
    showAccountInformationOptionsValues,
} from '@og-pro/shared-config/requisitions';
import { Box, ReduxFormSelect } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { purchaseDetailsModeSelectOptions, showAccountInformationOptions } from './constants';
import { sectionDividerLineStyle } from './styles';
import { FormGroup, SectionSettings, SectionTitleFormGroup } from '../components';
import { fieldNames, form } from '../../constants';
import { qaTagName } from '../../../constants';

const {
    ENDS_IN_PURCHASE_ORDER,
    PURCHASE_DETAILS_MODE,
    SECTION_INSTRUCTIONS_PURCHASE,
    SECTION_NAME_PURCHASE,
    SHOW_ACCOUNT_INFORMATION,
} = fieldNames;

export const PurchaseSectionSettings = React.forwardRef(({ disabled, onClose, title }, ref) => {
    const dispatch = useDispatch();
    const purchaseDetailsModeFormValue = useSelector((state) =>
        formValueSelector(form)(state, PURCHASE_DETAILS_MODE)
    );
    const endsInPurchaseOrderFormValue = useSelector((state) =>
        formValueSelector(form)(state, ENDS_IN_PURCHASE_ORDER)
    );

    const isAmountOnlyPurchaseDetailsMode =
        purchaseDetailsModeFormValue === purchaseDetailsModeOptions.AMOUNT_ONLY;

    useEffect(() => {
        if (isAmountOnlyPurchaseDetailsMode) {
            dispatch(
                change(form, SHOW_ACCOUNT_INFORMATION, showAccountInformationOptionsValues.HIDDEN)
            );
        }
    }, [isAmountOnlyPurchaseDetailsMode, dispatch]);

    return (
        <SectionSettings disabled={disabled} onClose={onClose} ref={ref} title={title}>
            <SectionTitleFormGroup
                disabled={disabled}
                sectionInstructionsField={SECTION_INSTRUCTIONS_PURCHASE}
                sectionNameField={SECTION_NAME_PURCHASE}
            />
            <Box sx={sectionDividerLineStyle} />
            {!endsInPurchaseOrderFormValue && (
                <FormGroup Icon={SettingsIcon} title="Settings">
                    <Field
                        MenuProps={{
                            disablePortal: true,
                        }}
                        component={ReduxFormSelect}
                        disabled={disabled}
                        fullWidth
                        label="Collection Requirements"
                        name={PURCHASE_DETAILS_MODE}
                        options={purchaseDetailsModeSelectOptions}
                        qaTag={`${qaTagName}-${PURCHASE_DETAILS_MODE}`}
                    />
                    {!isAmountOnlyPurchaseDetailsMode && (
                        <Field
                            MenuProps={{
                                disablePortal: true,
                            }}
                            component={ReduxFormSelect}
                            disabled={disabled}
                            fullWidth
                            label="Account Information"
                            name={SHOW_ACCOUNT_INFORMATION}
                            options={showAccountInformationOptions}
                            qaTag={`${qaTagName}-${SHOW_ACCOUNT_INFORMATION}`}
                        />
                    )}
                </FormGroup>
            )}
        </SectionSettings>
    );
});

PurchaseSectionSettings.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
