import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import {
    arrayMove,
    formValueSelector,
    getFormSyncErrors,
    getFormMeta,
    reduxForm,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import {
    defaultSectionConfigsMap,
    sectionTypeNames,
    sectionTypes,
} from '@og-pro/shared-config/sections';

import { getIndexToChangeParentDivider } from '../../../../../../../helpers';
import {
    form,
    fieldNames,
    ADD_SECTION_TEMP_FIELDNAME,
    ADD_SECTION_TEMP_FORM,
    IMPORT_SECTION_TEMP_FORM,
} from '../../../../forms/constants';
import {
    fieldNames as formInputFieldNames,
    builderSectionFieldNames,
    PARENT_DIVIDER,
} from '../../../../forms/TemplateProjectSectionsForm/constants';
import { fieldNames as formFieldNames } from '../../../../forms/TemplateForm/constants';
import { validateAddSectionData } from '../../../../forms/validate';
import {
    SectionHeaderCompleteForm,
    SectionHeaderTitleNumberForm,
} from '../../../../../../../components/SDv2';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';
import { SHORT_NAME } from '../../../../TemplateSectionEdit/TemplateSectionForm/constants';
import {
    importProjectSection,
    removeImportedSection,
} from '../../../../../../../actions/templatesAdmin';
import { showSnackbar } from '../../../../../../../actions/notification';

const { PROJECT_SECTIONS } = fieldNames;
const {
    IS_WRITING_FORM,
    BUILDER_SECTION,
    TITLE,
    MANUAL_NUMBER,
    DISABLE_NUMBERING,
    DISABLE_TITLE,
    SECTION_TYPE,
} = formInputFieldNames;
const { INSTRUCTIONS } = builderSectionFieldNames;
const { USE_MANUAL_NUMBERING, USE_SECTION_DIVIDERS } = formFieldNames;

const selector = formValueSelector(form);
const innerFormSelector = formValueSelector(ADD_SECTION_TEMP_FORM);
const errorsSelector = getFormSyncErrors(ADD_SECTION_TEMP_FORM);
const metaSelector = getFormMeta(ADD_SECTION_TEMP_FORM);

const importSectionFormSelector = (state, importedSection) => {
    if (!importedSection) {
        return [];
    }
    const importSectionFormValueSelector = formValueSelector(
        `${IMPORT_SECTION_TEMP_FORM}-${importedSection.uuid}`
    );
    return {
        items: importSectionFormValueSelector(state, 'items'),
        evaluationPhases: importSectionFormValueSelector(state, 'evaluationPhases'),
    };
};

const importSectionFormHasErrorsSelector = (state, importedSection) => {
    if (!importedSection) {
        return false;
    }
    return (
        getFormSyncErrors(`${IMPORT_SECTION_TEMP_FORM}-${importedSection.uuid}`)(state)
            ?.hasErrors || false
    );
};

/*
    This component handles the add section mechanic for the template editor v2.
    It works by initializing a form that's where you complete the title / instructions / permissions
    for the section you are adding.
    This works already inside the bigger form, the one that wraps the whole template editor. While controversial,
    this is perfectly valid since we have no <form> tag and it's just react.
    The reason for this is to prevent the bigger form from being tainted by the stuff that's only relevant for this
    smaller form, avoiding the bigger form from being dirty/invalid when the smaller one is.
    Because of this, be aware of the props below and how they are used. We do changes to the smaller form but also to the
    bigger one (for example check the prop `change: innerFormChange,` which refers to this smaller form change function).
*/
export const TemplateEditV2AddSection = reduxForm({
    form: ADD_SECTION_TEMP_FORM,
    initialValues: {
        [ADD_SECTION_TEMP_FIELDNAME]: null,
    },
    enableReinitialize: true,
    validate: validateAddSectionData,
})(({
    addingSectionData,
    allowParentDividerSelection = true,
    drawerButtonsLayout,
    unsetAddingSection,
    // redux-form stuff from lower level form
    change: innerFormChange,
    importedSection,
}) => {
    const {
        createProjectSection,
        createTemplateSection,
        disabled,
        getParentDividerOptions,
        isSpecial,
        isIntake,
        reloadTemplate,
        sortSections,
        templateProject,
        updateTemplate,
    } = useContext(TemplateEditV2FunctionsContext);
    const { activeSectionId } = useContext(TemplateEditV2NavContext);
    const dispatch = useDispatch();
    // values from the inner form
    const values = useSelector((state) => innerFormSelector(state, ADD_SECTION_TEMP_FIELDNAME));
    const errors = useSelector((state) => get(errorsSelector(state), ADD_SECTION_TEMP_FIELDNAME));
    const meta = useSelector((state) => get(metaSelector(state), ADD_SECTION_TEMP_FIELDNAME));

    // values from the outer form, the template big one
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const useSectionDividers = useSelector((state) => selector(state, USE_SECTION_DIVIDERS));

    // values from the import section form
    const importSectionValues = useSelector((state) =>
        importSectionFormSelector(state, importedSection)
    );
    const importSectionFormHasErrors = useSelector((state) =>
        importSectionFormHasErrorsSelector(state, importedSection)
    );

    const formHasErrors = useMemo(() => {
        return errors?.hasErrors || importSectionFormHasErrors;
    }, [errors?.hasErrors, importSectionFormHasErrors]);

    const { index, isTemplate, sectionType } = addingSectionData;
    let initialSelectedSection = isTemplate ? sectionType.projectSection : sectionType;

    if (importedSection) {
        initialSelectedSection = defaultSectionConfigsMap[importedSection.section_type];
    }

    // The section type will only change if the user is importing a section. We do need to account
    // for this change though, so the form can properly render when the section type changes.
    const sectionTypeToUse = values?.[SECTION_TYPE] || initialSelectedSection?.section_type;
    const selectedSectionConfig = defaultSectionConfigsMap[sectionTypeToUse];

    // This hook should run only once, on mount. It sets the initial values for the form.
    useEffect(() => {
        // If the section is imported, we set the initial values for the form based on the imported
        // section.
        if (importedSection) {
            const defaultSection = defaultSectionConfigsMap[initialSelectedSection.section_type];

            innerFormChange(ADD_SECTION_TEMP_FIELDNAME, {
                ...initialSelectedSection,
                [TITLE]: importedSection[TITLE],
                [SHORT_NAME]: '',
                [MANUAL_NUMBER]: '',
                [IS_WRITING_FORM]:
                    (!defaultSection?.isGeneral && !defaultSection?.isWritingFormEditable) || null,
                [BUILDER_SECTION]: {
                    [INSTRUCTIONS]: null,
                },
            });
            return;
        }

        // The selected section comes from what the user selected in ./Menu
        // which is received by this component in the form of props
        if (initialSelectedSection) {
            const defaultSection = defaultSectionConfigsMap[initialSelectedSection.section_type];

            innerFormChange(ADD_SECTION_TEMP_FIELDNAME, {
                ...initialSelectedSection,
                [TITLE]: isTemplate ? initialSelectedSection[TITLE] : '',
                [SHORT_NAME]: '',
                // if the section allows to select who can edit it
                // we leave it empty so the user has to select (null)
                // if not, we respect whatever the initialSelectedSection says
                [IS_WRITING_FORM]:
                    (!defaultSection?.isGeneral && !defaultSection?.isWritingFormEditable) ||
                    defaultSection?.section_type === sectionTypeNames.DIVIDER
                        ? initialSelectedSection[IS_WRITING_FORM]
                        : null,
                [MANUAL_NUMBER]: isTemplate ? initialSelectedSection[MANUAL_NUMBER] : '',
                [BUILDER_SECTION]: {
                    [INSTRUCTIONS]: null,
                },
            });
        }
    }, [initialSelectedSection, importedSection]);

    // When importing a section, the section type can change. We need to watch for changes and
    // set the writing form field to true if the section is not editable.
    useEffect(() => {
        const isWritingFormFieldDisabled =
            !selectedSectionConfig?.isGeneral && !selectedSectionConfig?.isWritingFormEditable;
        if (isWritingFormFieldDisabled) {
            innerFormChange(`${ADD_SECTION_TEMP_FIELDNAME}.${IS_WRITING_FORM}`, true);
        }
    }, [selectedSectionConfig?.[SECTION_TYPE]]);

    const onCancel = () => {
        if (importedSection) {
            dispatch(removeImportedSection(importedSection.uuid));
            unsetAddingSection(activeSectionId);
            dispatch(showSnackbar('Section removed'));
            return;
        }
        unsetAddingSection();
    };

    const createSection = async (submittedValues) => {
        const allowedValues = pick(submittedValues, [
            TITLE,
            IS_WRITING_FORM,
            MANUAL_NUMBER,
            SHORT_NAME,
            DISABLE_NUMBERING,
            DISABLE_TITLE,
        ]);

        // When importing a section, we need to use the project section data from ADD_SECTION_TEMP_FORM
        // and the imported section data from IMPORT_SECTION_TEMP_FORM. Unlike standard section
        // creation, we are both creating a project section and project section content at the same
        // time. This is why we also need the imported section data from IMPORT_SECTION_TEMP_FORM.
        if (importedSection) {
            const data = {
                ...allowedValues,
                [SECTION_TYPE]: submittedValues[SECTION_TYPE], // Include section type data for imported sections
                [BUILDER_SECTION]: {
                    [INSTRUCTIONS]: submittedValues[BUILDER_SECTION]?.[INSTRUCTIONS],
                },
            };

            const projectSectionData = {
                ...data, // from the add section form
                items: importSectionValues.items, // from the imported section form
                evaluationPhases: importSectionValues.evaluationPhases, // from the imported section form
            };

            const result = await dispatch(
                importProjectSection(templateProject.id, projectSectionData)
            );
            if (result instanceof Error) {
                dispatch(
                    showSnackbar(`Error creating project section: ${result.message}`, {
                        isError: true,
                    })
                );
                return;
            }
            dispatch(removeImportedSection(importedSection.uuid));
            dispatch(showSnackbar('Section imported!'));
            unsetAddingSection(activeSectionId + 1);
            return result;
        }

        const data = {
            ...allowedValues,
            [BUILDER_SECTION]: {
                [INSTRUCTIONS]: submittedValues[BUILDER_SECTION]?.[INSTRUCTIONS],
            },
        };

        // Template and Project sections are not created in the same way, hence the
        // two distinct functions.
        return isTemplate
            ? createTemplateSection(sectionType.id, data)
            : createProjectSection(sectionType.section_type, data);
    };

    const onSave = async () => {
        const submittedValues = { ...values };
        const newlyCreatedSection = await createSection(submittedValues);

        // After the new section is created, the current form does not have that information
        // inside `projectSections`. We could just push the newly created section into the array,
        // but TemplateSections don't play well with this (doing so causes an error when later
        // on trying to update the template).
        // In the legacy functionality TemplateSections did a re-fetch of the whole template.
        // To unify the behavior between Template/Project sections we re-fetch the
        // whole template for both of the scenarios
        const updatedTemplate = await reloadTemplate();

        // Since the component needs to support creating a section in a particular position
        // we will find the newly created section in the updated template's `projectSections` array...
        const newlyCreatedSectionIndex = updatedTemplate[PROJECT_SECTIONS].findIndex(
            (section) => section.sharedId === newlyCreatedSection.sharedId
        );

        // when it's manual numbered and we have divisions
        // the form is going to have a field to select the parent divider
        // that determines the position where we want to insert the item
        let indexToBeInsertedInto = index;
        const submittedParentDivider = submittedValues[PARENT_DIVIDER];

        if (
            allowParentDividerSelection &&
            useManualNumbering &&
            useSectionDividers &&
            !Number.isNaN(parseInt(submittedParentDivider, 10))
        ) {
            indexToBeInsertedInto = getIndexToChangeParentDivider(
                submittedParentDivider,
                updatedTemplate[PROJECT_SECTIONS].length
            );
        }

        if (newlyCreatedSectionIndex !== indexToBeInsertedInto) {
            // If the section is not in the position where it's supposed to be (defaults to be at the bottom of the array)
            // We will have to move the section to the proper position
            dispatch(
                arrayMove(
                    form,
                    fieldNames.PROJECT_SECTIONS,
                    newlyCreatedSectionIndex,
                    indexToBeInsertedInto
                )
            );
            // after it's moved we will call the sort function to properly set the orderById fields of each section
            setTimeout(sortSections, 0);
        } else if (useManualNumbering) {
            // if using manual numbering we still have to re-sort because we
            // don't know what they used as number
            setTimeout(sortSections, 0);
        }

        // setTimeout prevents race conditions within redux-form, ensuring the code is run
        // on the next tic of the event loop
        setTimeout(() => {
            unsetAddingSection(indexToBeInsertedInto);
            // update the template is required because of the changes we did when re-ordering the sections
            updateTemplate();
        }, 1);
    };

    const clearField = (name) => {
        innerFormChange(name, null);
    };

    if (!selectedSectionConfig) {
        return null;
    }

    if (selectedSectionConfig.section_type === sectionTypeNames.DIVIDER) {
        return (
            <SectionHeaderTitleNumberForm
                defaultSection={selectedSectionConfig}
                disabled={false}
                invalid={formHasErrors}
                isAdding
                member={ADD_SECTION_TEMP_FIELDNAME}
                onCancel={onCancel}
                onSave={onSave}
                showActionsOnFooter={drawerButtonsLayout}
                showValidation={!!meta?.title?.touched}
                useManualNumbering={useManualNumbering}
            />
        );
    }

    const canHaveInstructions = (!isSpecial || isIntake) && values?.[IS_WRITING_FORM];
    const parentDividerOptions = allowParentDividerSelection ? getParentDividerOptions() : null;

    return (
        <SectionHeaderCompleteForm
            canHaveInstructions={canHaveInstructions}
            defaultSection={selectedSectionConfig}
            disabled={disabled}
            formName={ADD_SECTION_TEMP_FORM}
            invalid={formHasErrors}
            isAdding
            isImported={!!importedSection}
            member={ADD_SECTION_TEMP_FIELDNAME}
            onCancel={onCancel}
            onDeleteInstructions={clearField}
            onSave={onSave}
            parentDividerOptions={parentDividerOptions}
            showActionsOnFooter={drawerButtonsLayout}
            showShortnameInput
            showValidation={!!meta?.title?.touched}
            templateProject={templateProject}
            useManualNumbering={useManualNumbering}
            values={values}
        />
    );
});

TemplateEditV2AddSection.propTypes = {
    addingSectionData: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        isTemplate: PropTypes.bool,
        sectionType: PropTypes.shape({
            id: PropTypes.number,
            projectSection: PropTypes.shape({}),
            section_type: PropTypes.oneOf(sectionTypes),
        }),
    }),
    allowParentDividerSelection: PropTypes.bool,
    // makes the form have the buttons at the bottom
    drawerButtonsLayout: PropTypes.bool,
    importedSection: PropTypes.shape({
        section_type: PropTypes.string,
        title: PropTypes.string,
    }),
    unsetAddingSection: PropTypes.func.isRequired,
};
