import { sortBy, pick } from 'lodash';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { timelineDates, projectTimelineConfigurations } from '@og-pro/shared-config/timelines';

/**
 * Determines if a project is of type purchase
 * @param {string} type
 * @returns {boolean}
 */
export const isPurchase = (type) => {
    return type === projectTypesDict.PURCHASE;
};

export const getProjectTimelinesParser = (projectTimelineFormValues) => {
    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);

    let lastDate = TODAY;
    return projectTimelineConfigurations.map((configuration) => {
        // Keep track of the last date so it can be added
        const previousLastDate = lastDate;
        const timelineConfig = projectTimelineFormValues.timelineConfig || {};
        const date = projectTimelineFormValues[configuration.projectField];

        if (date) {
            lastDate = date;
        }

        return {
            ...configuration,
            date,
            hasProposalDeadlineLocation:
                timelineConfig[configuration.hasProposalDeadlineLocationField],
            hasAuctionExtension: timelineConfig[configuration.hasAuctionExtensionField],
            isIncluded: timelineConfig[configuration.isIncludedField],
            lastDate: previousLastDate,
            preProposalAgendaUrl: timelineConfig[configuration.preProposalAgendaUrlField],
            orderById: timelineConfig[configuration.orderByIdField],
            title: timelineConfig[configuration.titleField] || configuration.title,
            uuid: configuration.projectField,
        };
    });
};

export const getDynamicTimelinesParser = (dynamicTimelines) => {
    if (dynamicTimelines) {
        return dynamicTimelines.map((timeline, index) => ({
            ...timeline,
            index,
            isCustomDate: true,
        }));
    }
    return [];
};

/**
 * Returns a sorted array of project timelines, both static and dynamic
 * @param {object} project a full project containing timelines array and timelineConfig
 * @returns {array<object>}
 */
export const getProjectTimelinesArray = (project) => {
    const projectTimelineValues = {
        ...pick(project, timelineDates),
        timelineConfig: project.timelineConfig || {},
    };
    const dynamicTimelineValues = project.timelines || [];

    return sortBy(
        [
            ...getProjectTimelinesParser(projectTimelineValues),
            ...getDynamicTimelinesParser(dynamicTimelineValues),
        ],
        'orderById'
    );
};
