import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { ReduxFormTextField } from '@og-pro/ui';

import { fieldNames, form, qaTagPageName } from './constants';
import { validate } from './validate';

const { OVER_BUDGET_NOTE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedOverBudgetNoteForm = ({ disabled, requireOverBudgetNote }) => {
    return (
        <form>
            <Field
                autoFocus={requireOverBudgetNote}
                characterLimit={1000}
                component={ReduxFormTextField}
                disabled={disabled}
                fullWidth
                label={`Reason for Overriding Budget Check Failure${
                    requireOverBudgetNote ? ' *' : ''
                }`}
                multiline
                name={OVER_BUDGET_NOTE}
                qaTag={`${qaTagPageName}-overBudgetNote`}
            />
        </form>
    );
};

ConnectedOverBudgetNoteForm.propTypes = {
    disabled: PropTypes.bool,
    requireOverBudgetNote: PropTypes.bool,
};

export const OverBudgetNoteForm = reduxForm(formConfig)(ConnectedOverBudgetNoteForm);
