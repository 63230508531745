import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { QuestionLogicIcon } from '../../../../components';
import { ProjectAttachmentBlock } from '../../../../../../../components/forms';
import { useOGTheme } from '../../../../../ogThemeProvider';
import { TemplateEditV2FunctionsContext } from '../../../../V2/context';
import { ATTACHMENTS_TITLES, EXHIBITS_TITLES } from '../../../../V2/constants';

const { OTHER, INTERNAL } = attachmentTypesDict;

export const TemplateAttachmentsForm = (props) => {
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const { isOGThemeEnabledForComponents } = useOGTheme();
    const editContext = useContext(TemplateEditV2FunctionsContext);

    const {
        hideQuestionLogic,
        projectSection,
        questionLogicLinkable,
        usingFakeSection,
        disabled,
        form,
        hideSupportingAttachments,
        projectId,
        standalone,
    } = props;
    const styles = require('./index.scss');

    const isExhibit =
        editContext?.templateProject?.type === projectTypesDict.CONTRACT &&
        contractPackageCompilerEnabled;
    const { title, description } = isExhibit ? EXHIBITS_TITLES[0] : ATTACHMENTS_TITLES[0];

    const renderQuestionLogicIcon = useCallback((attachment) => {
        return (
            <QuestionLogicIcon
                hidden={hideQuestionLogic}
                includeText={isOGThemeEnabledForComponents}
                linkable={questionLogicLinkable}
                linkableItem={attachment}
                projectSection={projectSection}
                usingFakeSection={usingFakeSection}
            />
        );
    }, []);

    const singularTitle = title.replace(/s$/, '');

    return (
        <>
            {!hideSupportingAttachments && (
                <div
                    className={classnames({
                        row: !isOGThemeEnabledForComponents,
                        [styles.attachments]: isOGThemeEnabledForComponents,
                    })}
                >
                    <div
                        className={classnames({
                            'col-md-offset-2 col-md-8': !isOGThemeEnabledForComponents,
                        })}
                    >
                        {isOGThemeEnabledForComponents && !standalone && (
                            <div className={styles.heading}>
                                <Typography variant="h3">
                                    {isExhibit ? title : `Supporting ${title}`}
                                </Typography>
                                <Typography>{description}</Typography>
                            </div>
                        )}
                        <ProjectAttachmentBlock
                            attachmentListLabel={`Uploaded ${title}`}
                            disabled={disabled}
                            emptyListSubtitle={`Add an ${singularTitle.toLowerCase()} above to see it here.`}
                            emptyListTitle={`No ${title.toLowerCase()} Included`}
                            form={form}
                            formKey={OTHER}
                            hideNoAttachments
                            isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                            label="standard attachments that should be included with the project"
                            listAttachmentExcludeType={[INTERNAL]}
                            projectId={projectId}
                            renderQuestionLogicIcon={renderQuestionLogicIcon}
                            showNotification
                            uploadAttachmentType={OTHER}
                        />
                    </div>
                </div>
            )}

            {isOGThemeEnabledForComponents && !standalone && (
                <Box mt={2}>
                    <div className={styles.attachments}>
                        <div className={styles.heading}>
                            <Typography variant="h3">Internal Documents</Typography>
                            <Typography>
                                These documents are for internal use only. The vendor will not see
                                them.
                            </Typography>
                        </div>
                        <ProjectAttachmentBlock
                            disabled={disabled}
                            form={form}
                            formKey={INTERNAL}
                            hideAppendixLetter
                            hideNoAttachments
                            isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                            label="standard attachments that should be included with the project"
                            listAttachmentExcludeType={[OTHER]}
                            projectId={projectId}
                            renderQuestionLogicIcon={renderQuestionLogicIcon}
                            showNotification
                            uploadAttachmentType={INTERNAL}
                        />
                    </div>
                </Box>
            )}
        </>
    );
};

TemplateAttachmentsForm.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    hideSupportingAttachments: PropTypes.bool,
    hideQuestionLogic: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
    projectSection: PropTypes.object.isRequired,
    questionLogicLinkable: PropTypes.string.isRequired,
    standalone: PropTypes.bool,
    usingFakeSection: PropTypes.bool,
};
