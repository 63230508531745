import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { getQuestionLogicDisplayText } from './helper';
import { SequenceToggleDisplay } from './SequenceToggleDisplay';
import { SequenceStepDisplayHeader } from './SequenceStepDisplayHeader';
import { getRejectionPolicyOptions } from '../../../selectors';
import { UserProfileList } from '../../../../../../../components';
import { getFlags, getIsGovernmentUsingFMS } from '../../../../../../../selectors/govApp';

export const ReviewSequenceStepDisplay = ({
    fieldName,
    onClick,
    reviewSequenceStep: {
        allowCustomFormEdit,
        allowGeneralEdit,
        allowOverBudget,
        allowPriceEdit,
        allowVendorEdit,
        allowAdHocSteps,
        name: stepName,
        questionLogic,
        rejectionPolicies,
        requireVendor,
        reviewSequenceStepPositions,
        reviewType,
    },
    showFormValidation,
    stepNumber,
}) => {
    const governmentHasFMS = useSelector(getIsGovernmentUsingFMS);
    const rejectionPolicyOptions = useSelector(getRejectionPolicyOptions);
    const flags = useSelector(getFlags);
    const isCustomizingRequestWorkflowEnabled = useFlags(
        FLAGS.ENABLE_CUSTOMIZING_REQUEST_WORKFLOW_MID_REQUEST
    );
    const styles = require('./index.scss');
    const users = reviewSequenceStepPositions.map(({ position: { name, user } }, index) => {
        return {
            ...user,
            displayName: name,
            id: user?.id || index * -1, // Handles case where position is not filled by a user
        };
    });

    const isConfirmReviewType = reviewType === reviewTypes.CONFIRM;
    const isNotifyReviewType = reviewType === reviewTypes.NOTIFY;
    const hasRejectionPolicy = !isNotifyReviewType && !isConfirmReviewType;

    const renderReviewerPermissions = () => {
        if (isNotifyReviewType) {
            return 'N/A';
        }
        return (
            <>
                {!isNotifyReviewType && (
                    <>
                        <SequenceToggleDisplay
                            enabled={allowGeneralEdit}
                            label="Allows general info editing"
                        />
                        <SequenceToggleDisplay
                            enabled={allowPriceEdit}
                            label="Allows account number editing"
                        />
                        <SequenceToggleDisplay
                            enabled={allowVendorEdit}
                            label={
                                allowVendorEdit && requireVendor
                                    ? 'Requires vendor selection'
                                    : 'Allows vendor selection'
                            }
                        />
                        <SequenceToggleDisplay
                            enabled={allowCustomFormEdit}
                            label="Allows custom form editing"
                        />
                        {governmentHasFMS && (
                            <SequenceToggleDisplay
                                enabled={allowOverBudget}
                                label="Allows over budget"
                            />
                        )}
                    </>
                )}
                {isCustomizingRequestWorkflowEnabled && (
                    <SequenceToggleDisplay
                        enabled={allowAdHocSteps}
                        label="Allow Ability to Add Step"
                    />
                )}
            </>
        );
    };

    const renderRejectionPolicies = () => {
        if (!hasRejectionPolicy) {
            return 'N/A';
        }
        return (
            <>
                {rejectionPolicies.length === 0 && '- None'}
                {rejectionPolicies.map((rejectionPolicyKey) => {
                    const { label } = rejectionPolicyOptions.find(
                        (opt) => opt.value === rejectionPolicyKey
                    );
                    return <div key={rejectionPolicyKey}>- {label}</div>;
                })}
            </>
        );
    };

    return (
        <ListGroupItem onClick={onClick}>
            <Field
                component={SequenceStepDisplayHeader}
                name={`${fieldName}.hasError`}
                showFormValidation={showFormValidation}
                stepName={stepName}
                stepNumber={stepNumber}
            />
            <div className={styles.conditionalSection}>
                <em>
                    {questionLogic
                        ? getQuestionLogicDisplayText(questionLogic, flags)
                        : 'Always occurs'}
                </em>
            </div>
            <div className="row">
                <div className={`col-sm-4 ${styles.infoColumn}`}>
                    Review Positions ({reviewSequenceStepPositions.length}):
                    <br />
                    <UserProfileList maxDisplayableUsers={5} users={users} />
                </div>
                <div className={`col-sm-4 ${styles.infoColumn}`}>
                    Reviewer Permissions:
                    <br />
                    {renderReviewerPermissions()}
                </div>
                <div className={`col-sm-4 ${styles.infoColumn}`}>
                    Rejection Policies{hasRejectionPolicy && ` (${rejectionPolicies.length})`}:
                    <br />
                    {renderRejectionPolicies()}
                </div>
            </div>
        </ListGroupItem>
    );
};

ReviewSequenceStepDisplay.propTypes = {
    fieldName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    reviewSequenceStep: PropTypes.shape({
        allowCustomFormEdit: PropTypes.bool,
        allowGeneralEdit: PropTypes.bool,
        allowOverBudget: PropTypes.bool,
        allowPriceEdit: PropTypes.bool,
        allowVendorEdit: PropTypes.bool,
        allowAdHocSteps: PropTypes.bool,
        name: PropTypes.string,
        questionLogic: PropTypes.object,
        rejectionPolicies: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
        ).isRequired,
        requireVendor: PropTypes.bool,
        reviewSequenceStepPositions: PropTypes.array.isRequired,
        reviewType: PropTypes.number.isRequired,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    stepNumber: PropTypes.number.isRequired,
};
