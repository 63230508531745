import { createSelector } from 'reselect';

import { getContractsJS } from '../../../containers/selectors';

const getContractLabel = (contract) => {
    const companyName = contract?.contractParty?.companyName;
    const contractId = contract.contractId ? `[${contract.contractId}] ` : '';
    const vendorName = companyName ? ` - ${companyName}` : '';
    const contractTitle = contract.title || 'Untitled Contract';

    return `${contractId}${contractTitle}${vendorName}`;
};

export const getContractSelectOptions = createSelector([getContractsJS], (contracts) => {
    return contracts.map((contract) => {
        return {
            contract,
            label: getContractLabel(contract),
            value: contract.id,
        };
    });
});
