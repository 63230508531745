import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { ImportSectionForm } from './ImportSectionForm';
import { fieldNames, form, IMPORT_SECTION_TEMP_FORM } from '../../../../forms/constants';
import {
    fieldNames as formFieldNames,
    templateFieldNames,
} from '../../../../forms/TemplateForm/constants';
import { ProjectContentSection } from '../../../../forms/TemplateProjectContentForm/components/index';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';
import { TemplateEditNoSections } from '../../components/NoSections';

const { PROJECT_SECTIONS, USE_SECTION_DIVIDERS, TEMPLATE } = fieldNames;
const { USE_MANUAL_NUMBERING } = formFieldNames;
const { IS_REVERSE_AUCTION } = templateFieldNames;

const selector = formValueSelector(form);

export const TemplateEditV2DocumentBuilderSDv2SectionsAdapter = () => {
    const editContext = useContext(TemplateEditV2FunctionsContext);
    const { importedSections, templateProject } = editContext;
    const { activeSectionId } = useContext(TemplateEditV2NavContext);
    const useSectionDividers = useSelector((state) => selector(state, USE_SECTION_DIVIDERS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));
    const isReverseAuction = useSelector((state) =>
        selector(state, `${TEMPLATE}.${IS_REVERSE_AUCTION}`)
    );
    const projectSections = useSelector((state) => selector(state, PROJECT_SECTIONS));

    const allSections = [...projectSections, ...importedSections];

    if (allSections.length === 0) {
        return <TemplateEditNoSections />;
    }

    const projectSection = allSections[activeSectionId];

    if (!projectSection) {
        return null;
    }

    const sharedProjectContentSectionProps = {
        addConditionalLogicHandler: editContext.addConditionalLogicHandler,
        disabled: editContext.disabled,
        isIntake: editContext.isIntake,
        isReverseAuction,
        projectId: editContext.projectId,
        showForm: true,
        showFormHandler: () => {},
        showFormValidation: editContext.showFormValidation,
        tagOptions: editContext.tagOptions,
        templateVariableOptions: editContext.templateVariableOptions,
        useManualNumbering,
        useSectionDividers,
    };

    if (projectSection.isImported) {
        const formName = `${IMPORT_SECTION_TEMP_FORM}-${projectSection.uuid}`;
        return (
            <ImportSectionForm
                form={formName}
                initialValues={{ items: projectSection.items }}
                isPublished={templateProject.template.isPublished}
                key={projectSection.uuid}
                projectContentSectionProps={sharedProjectContentSectionProps}
                projectSection={projectSection}
                useManualNumbering={useManualNumbering}
            />
        );
    }

    return (
        <ProjectContentSection
            {...sharedProjectContentSectionProps}
            array={editContext.array}
            change={editContext.change}
            key={projectSection.id}
            projectSection={projectSection}
        />
    );
};
