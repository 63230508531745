import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { mapContactFields } from '@og-pro/shared-config/contacts';

import { ContactInfo } from './ContactInfo/ContactInfo';
import { ContactName } from './ContactName/ContactName';
import { getModalData } from './selectors';
import { AddressFormSection } from '../forms/sections';
import { Nav, NavItem } from '../Nav';
import { Button } from '../Button';
import { hideContactModal, setContactModalTab } from '../../actions/contacts';

const mapStateToProps = (state) => {
    const modalData = pick(getModalData(state), [
        'disabled',
        'fieldAccessorPrefix',
        'fieldPrefix',
        'optional',
        'showValidation',
    ]);

    return {
        ...modalData,
        currentTab: state.contacts.get('contactModalTab'),
        fieldNames: mapContactFields(modalData.fieldPrefix, modalData.fieldAccessorPrefix),
        showModal: state.contacts.get('showContactModal'),
    };
};

const mapDispatchToProps = {
    changeTab: setContactModalTab,
    hideModal: hideContactModal,
};

// @connect
class ConnectedContactModal extends PureComponent {
    static propTypes = {
        changeTab: PropTypes.func.isRequired,
        currentTab: PropTypes.string,
        disabled: PropTypes.bool,
        fieldNames: PropTypes.object.isRequired,
        formName: PropTypes.string.isRequired,
        hideModal: PropTypes.func.isRequired,
        optional: PropTypes.bool,
        showModal: PropTypes.bool.isRequired,
        showValidation: PropTypes.bool,
        singlePage: PropTypes.bool,
    };

    static defaultProps = {
        currentTab: undefined,
        disabled: false,
        optional: false,
        showValidation: false,
        singlePage: false,
    };

    get styles() {
        return require('./ContactModal.scss');
    }

    renderContent() {
        const { currentTab, disabled, fieldNames, formName, optional, showValidation, singlePage } =
            this.props;

        const sharedProps = {
            disabled,
            fieldNames,
            optional,
            showValidation,
        };

        if (singlePage) {
            return (
                <>
                    <ContactName {...sharedProps} />
                    <ContactInfo {...sharedProps} formName={formName} />
                    <AddressFormSection
                        {...sharedProps}
                        form={formName}
                        hasOptionalHelpText
                        hasOptionalStar
                    />
                </>
            );
        }

        switch (currentTab) {
            case 'Name':
                return <ContactName {...sharedProps} />;
            case 'Contact':
                return <ContactInfo {...sharedProps} formName={formName} />;
            case 'Address':
                return (
                    <AddressFormSection
                        {...sharedProps}
                        form={formName}
                        hasOptionalHelpText
                        hasOptionalStar
                    />
                );
            default:
                return null;
        }
    }

    renderNavItems() {
        const { currentTab, changeTab } = this.props;

        const sections = ['Name', 'Contact', 'Address'];

        return sections.map((item, index) => {
            return (
                <NavItem
                    active={item === currentTab}
                    key={index}
                    onClick={() => changeTab(item)}
                    qaTag={`contactModal-${item}`}
                >
                    {item}
                </NavItem>
            );
        });
    }

    render() {
        const { hideModal, showModal, singlePage } = this.props;

        if (!showModal) {
            return null;
        }

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Body>
                    {!singlePage && (
                        <Nav bsStyle="tabs" justified>
                            {this.renderNavItems()}
                        </Nav>
                    )}
                    <div className={`${this.styles.contactSection} row`}>
                        <div className="col-sm-offset-1 col-sm-10">{this.renderContent()}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const ContactModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedContactModal);
