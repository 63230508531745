import { createSelector } from 'reselect';

import { getContractJS, getContractAttachmentTagsJS } from '../../../containers/selectors';

const groupAttachments = (attachments = [], contractAttachmentTags) => {
    const groupedAttachments = attachments.reduce(
        (map, attachment) => {
            if (attachment.tags.length > 0) {
                // We assume currently that each attachment has a single tag only
                if (!map[attachment.tags[0].id]) {
                    map[attachment.tags[0].id] = [];
                }

                map[attachment.tags[0].id].push(attachment);
            } else {
                map.uncategorized.push({ ...attachment, tags: [{ name: 'Uncategorized' }] });
            }

            return map;
        },
        { uncategorized: [] }
    );

    const groupedAndSortedAttachments = contractAttachmentTags
        .map((contractAttachmentTag) => {
            return groupedAttachments[contractAttachmentTag.id]
                ? groupedAttachments[contractAttachmentTag.id]
                : null;
        })
        .filter((tags) => !!tags);

    if (groupedAttachments.uncategorized.length > 0) {
        groupedAndSortedAttachments.push(groupedAttachments.uncategorized);
    }

    return groupedAndSortedAttachments;
};

export const getGroupedContractAttachments = createSelector(
    [getContractJS, getContractAttachmentTagsJS],
    (contract, contractAttachmentTags) => {
        if (!contract || !contractAttachmentTags) {
            return [];
        }

        return groupAttachments(contract.attachments, contractAttachmentTags);
    }
);

export const getPendingContractAttachments = createSelector(
    [getContractJS, getContractAttachmentTagsJS],
    (contract, contractAttachmentTags) => {
        if (!contract || !contractAttachmentTags) {
            return [];
        }

        return groupAttachments(contract.pendingAttachments, contractAttachmentTags);
    }
);
