import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@og-pro/ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const GroupLabelWithTooltip = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
            }}
        >
            {props.label}
            {props.tooltipText && (
                <Tooltip title={props.tooltipText}>
                    <Box>
                        <InfoOutlinedIcon aria-label="Help Text" fontSize="small" />
                    </Box>
                </Tooltip>
            )}
        </Box>
    );
};

GroupLabelWithTooltip.propTypes = {
    label: PropTypes.node.isRequired,
    tooltipText: PropTypes.string,
};
