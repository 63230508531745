import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { mapContactFields } from '@og-pro/shared-config/contacts';

import { form, fieldNames, organizationFieldNames } from './constants';
import { validate } from './validate';
import { InputText } from '../../../components';
import { PhoneNumberInput } from '../../../components/forms';

const { FIRST_NAME, LAST_NAME, ORGANIZATION } = fieldNames;

const { NAME } = organizationFieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedVendorActivateForm extends Component {
    static propTypes = {
        disabledCompanyName: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        includePhone: PropTypes.bool,
        saving: PropTypes.bool,
        showErrorFields: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { disabledCompanyName, handleSubmit, includePhone, saving, showErrorFields } =
            this.props;

        return (
            <form className="col-xs-12" onSubmit={handleSubmit}>
                <div className={this.styles.requiredNotice}>
                    All fields marked with * are required
                </div>
                <Field
                    autoFocus
                    component={InputText}
                    disabled={saving}
                    label="First Name *"
                    name={FIRST_NAME}
                    placeholder="Enter your first name"
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={saving}
                    label="Last Name *"
                    name={LAST_NAME}
                    placeholder="Enter your last name"
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabledCompanyName || saving}
                    label="Company Name *"
                    name={`${ORGANIZATION}.${NAME}`}
                    placeholder="Enter your company name"
                    type="text"
                />
                {includePhone && (
                    <PhoneNumberInput
                        disabled={saving}
                        fieldNames={mapContactFields(null, `${ORGANIZATION}.`)}
                        formName={form}
                        phoneFieldLabel="Company Phone Number *"
                        placeholder="Enter your company phone number"
                        showRequiredFieldsText={false}
                    />
                )}
                {showErrorFields && (
                    <div className={`help-block ${this.styles.formError}`} role="alert">
                        Your form has errors. Please fix them and submit again.
                    </div>
                )}
            </form>
        );
    }
}

export const VendorActivateForm = reduxForm(formConfig)(ConnectedVendorActivateForm);
