import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { getTermsReviewModalData } from './selectors';
import { fieldNames } from '../constants';
import { TermsReview } from '../TermsReview';
import { hideTermsReviewModal } from '../../../../actions/project/create/projectCreate';
import { Dialog } from '../../../Dialog';

const { DESCRIPTION, RAW_DESCRIPTION } = fieldNames;

const mapStateToProps = (state) => {
    return {
        ...getTermsReviewModalData(state),
        showModal: state.projectCreate.get('showTermsReviewModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hideTermsReviewModal,
};

// @connect
class ConnectedCriteriaNeedsReviewModal extends Component {
    static propTypes = {
        arrayName: PropTypes.string,
        change: PropTypes.func.isRequired,
        criteria: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }),
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        hideModal: PropTypes.func.isRequired,
        isTextArea: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.object.isRequired,
        showModal: PropTypes.bool.isRequired,
        showValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawDescription: PropTypes.bool,
    };

    hideModal = () => {
        const { hideModal } = this.props;

        hideModal();
    };

    render() {
        // Need to prevent further loading as criteria won't be defined unless showModal is true
        if (!this.props.showModal) return null;

        const {
            arrayName,
            change,
            criteria,
            disabled,
            form,
            isTextArea,
            projectId,
            projectSection,
            showValidation,
            tagOptions,
            templateVariableOptions,
            useRawDescription,
        } = this.props;

        const title = isTextArea ? projectSection.title : criteria.title;

        return (
            <Dialog onClose={this.hideModal} title={`${title} Review`}>
                <Field
                    {...criteria}
                    arrayName={arrayName}
                    change={change}
                    completeHandler={this.hideModal}
                    component={TermsReview}
                    disabled={disabled}
                    formName={form}
                    name={`${arrayName}.${useRawDescription ? RAW_DESCRIPTION : DESCRIPTION}`}
                    projectId={projectId}
                    showValidation={showValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
            </Dialog>
        );
    }
}

export const CriteriaNeedsReviewModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedCriteriaNeedsReviewModal);
