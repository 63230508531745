import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { getMappedProposalJS, getProposalReviewPath, isProposalEditable } from '../selectors';
import { getPublicProjectJS } from '../../selectors';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { unsubmitProposal } from '../../../actions/vendProposals';
import { Button, HoverButton } from '../../../components';
import { ProposalDetails } from '../../../components/proposals';
import { hasTimePassed } from '../../../helpers';

const { OPEN } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        isEditable: isProposalEditable(state),
        project: getPublicProjectJS(state),
        proposal: getMappedProposalJS(state),
        proposalReviewPath: getProposalReviewPath(state, props),
        updateError: state.vendProposals.get('updateProposalError'),
        updating: state.vendProposals.get('updatingProposal'),
    };
};

const mapDispatchToProps = {
    showConfirmationModal: showConfirmationSimpleModal,
    unsubmitProposal,
};

// @connect
class ConnectedProposalReview extends Component {
    static propTypes = {
        isEditable: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            vendorId: PropTypes.string.isRequired,
        }).isRequired,
        project: PropTypes.shape({
            proposalDeadline: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        }),
        proposal: PropTypes.shape({
            submittedAt: PropTypes.instanceOf(Date),
        }),
        proposalReviewPath: PropTypes.string.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        unsubmitProposal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./ProposalReview.scss');
    }

    unsubmitProposal = () => {
        const { proposal, showConfirmationModal } = this.props;

        showConfirmationModal(() => this.props.unsubmitProposal(proposal), {
            text:
                'Unsubmitting your response will make it no longer ' +
                'accessible to the requesting organization. You will need ' +
                'to resubmit the response in order for it to be received. ' +
                'Are you sure you would like to continue?',
            btnText: 'Unsubmit Response',
            icon: 'undo',
        });
    };

    renderEditProposalButton() {
        const { isEditable, proposalReviewPath } = this.props;

        if (!isEditable) {
            return null;
        }

        return (
            <div className={this.styles.editBtn}>
                <Button bsSize="sm" to={`${proposalReviewPath}/edit`}>
                    <i className="fa fa-pencil" /> Edit Response
                </Button>
            </div>
        );
    }

    renderReviseSection() {
        const {
            isEditable,
            project: { proposalDeadline, status },
            updating,
            updateError,
        } = this.props;

        if (isEditable) {
            return null;
        }

        const icon = updating ? 'fa-spin fa-spinner' : 'fa-undo';
        const hasDeadlinePassed = hasTimePassed(proposalDeadline);
        const unsubmittableProjectState = status !== OPEN;
        const isUnrevisable = hasDeadlinePassed || unsubmittableProjectState;

        return (
            <div className={`${this.styles.section} no-print`}>
                <div className={this.styles.sectionHeader}>REVISE RESPONSE</div>
                <div className={this.styles.sectionItem}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>
                            To revise the response, use the Unsubmit button. After editing, submit
                            the response again, so it can be reviewed.
                        </div>
                        <div className="col-md-7">
                            <HoverButton
                                disabled={updating || isUnrevisable}
                                hoverStyle="danger"
                                onClick={this.unsubmitProposal}
                            >
                                <i className={`fa ${icon}`} /> Unsubmit Response
                            </HoverButton>
                            {isUnrevisable && (
                                <div className={this.styles.unrevisableMessage}>
                                    Response Submission Deadline Has Passed
                                </div>
                            )}
                            {updateError && <div className="error-block">{updateError}</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { project, proposal, params } = this.props;

        if (!proposal) {
            return null;
        }

        const vendorUrl = `/vendors/${params.vendorId}/profile`;

        return (
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    {this.renderEditProposalButton()}
                    <ProposalDetails
                        displayVendorConfirmationWell
                        isVendorView
                        project={project}
                        proposal={proposal}
                        vendorUrl={vendorUrl}
                    />
                    {this.renderReviseSection()}
                </div>
            </div>
        );
    }
}

export const ProposalReview = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalReview);
