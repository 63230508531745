import { listToDict } from '@og-pro/shared-config/helpers';

import { purchaseOrderTypesDict } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../constants';

const { REGULAR, BLANKET } = purchaseOrderTypesDict;

export const generalInformationFields = [
    'descriptionOfRequest',
    'summary',
    'background',
    'categories',
    'desiredDeliveryDate',
    'exception_sequence_id',
    'exceptionNote',
    'expectedPurchaseOrderDate',
    'fiscalPeriod',
    'fiscalPeriodObject',
    'fiscal_period_tag_id',
    'reviewGroup',
    'requestor_id',
    'year',
    'created_at',
];

export const purchaseDetailsFields = [
    'priceItems',
    'priceTable',
    'priceItemsSummary',
    'purchaseDetailsMode',
];

export const vendorSelectionFields = [
    'address1',
    'address2',
    'additionalVendorNotes',
    'associatedLineItems',
    'city',
    'commentToVendor',
    'contract_id',
    'contractIdToUpdate',
    'deliveryCodeText',
    'paymentTermsText',
    'pro_user_id',
    'pro_vendor_id',
    'purchaseOrderType',
    'attachments',
    'title',
    'state',
    'vendorAddress',
    'vendorAssignedNo',
    'vendorEmail',
    'vendorName',
    'vendorId',
    'vendors',
    'vendorSelectionState',
    'zipCode',
];

export const customFormSectionFields = ['customFormData'];
export const additionalInformationFields = ['flagResponses', 'flagResponsesSummary'];

export const attachmentsFields = ['attachments'];

const fields = [
    ...generalInformationFields,
    ...purchaseDetailsFields,
    ...vendorSelectionFields,
    ...customFormSectionFields,
    ...additionalInformationFields,
    ...attachmentsFields,
];

export const fieldNames = listToDict(fields);

// Display fields are only used by the form. These values do not get saved or persisted to the DB.
const displayFields = [];

export const displayFieldNames = listToDict(displayFields);

export const flagResponseFields = ['isEnabled'];

export const flagResponseFieldNames = listToDict(flagResponseFields);

export const radioOptions = [
    {
        name: 'Yes',
        label: 'Yes',
        qaTag: `${qaTagPageName}-yes`,
        value: true,
    },
    {
        // Get rid of `name` after switching to ReduxFormRadioGroup
        name: 'No',
        label: 'No',
        qaTag: `${qaTagPageName}-no`,
        value: false,
    },
];

export const getPurchaseOrderTypeOptions = (hasFMS) => [
    { label: 'Regular', value: REGULAR },
    ...(hasFMS ? [{ label: 'Blanket', value: BLANKET }] : []),
];
