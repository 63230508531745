import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ContractListItem } from '../../ContractListItem';
import { LinkContainer } from '../../../LinkContainer/LinkContainer';

export const ListView = (props) => {
    const { governmentCode, governmentId, projectId } = useParams();

    const renderContracts = () => {
        const { contracts, isPublicView } = props;

        const projectSection = projectId ? `projects/${projectId}/` : '';

        return (
            <ListGroup>
                {contracts.map((contract) => {
                    const contractUrl = isPublicView
                        ? `/portal/${governmentCode}/${projectSection}contracts/${contract.id}`
                        : `/governments/${governmentId}/contracts/${contract.id}`;

                    return (
                        <LinkContainer key={contract.id} to={contractUrl}>
                            <ListGroupItem>
                                <ContractListItem contract={contract} />
                            </ListGroupItem>
                        </LinkContainer>
                    );
                })}
            </ListGroup>
        );
    };

    const { fullWidth } = props;

    if (fullWidth) {
        return <div>{renderContracts()}</div>;
    }

    return (
        <div className="row">
            <div className="col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8">
                {renderContracts()}
            </div>
        </div>
    );
};

ListView.propTypes = {
    contracts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    timezone: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            project_id: PropTypes.number,
        })
    ).isRequired,
    fullWidth: PropTypes.bool,
    isPublicView: PropTypes.bool,
};
