import PropTypes from 'prop-types';
import React from 'react';

import { ReportDocxTable } from '../../../../../../components';
import { getLineItemTimelineRowData, lineItemTimelineColumnDefs } from '../helpers';
import { PriceItemDescriptionHeader } from './PriceItemDescriptionHeader';

export const LineItemTimelineList = ({ reverseAuctionItems }) => {
    return reverseAuctionItems.map((itemTable) =>
        itemTable.priceItems.map((priceItem) => (
            <div key={priceItem.id}>
                <PriceItemDescriptionHeader priceItem={priceItem} priceTable={itemTable} />
                <ReportDocxTable
                    columns={lineItemTimelineColumnDefs}
                    data={getLineItemTimelineRowData(priceItem, itemTable)}
                    useLandscape={false}
                />
            </div>
        ))
    );
};

LineItemTimelineList.propTypes = {
    reverseAuctionItems: PropTypes.array.isRequired,
};
