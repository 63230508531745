import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Dialog, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import WarningIcon from '@mui/icons-material/Warning';

import { BudgetChangedModalContent } from './BudgetChangedModalContent';
import { hideRequisitionBudgetCheckAlertModal } from '../../../actions/requisitions';
import { LoadingError, LoadingSpinner } from '../../../components';
import {
    getRequisitionBudgetCheckAlertModal,
    getRequisitionBudgetCheckError,
    getRequisitionBudgetCheckResults,
    getRequisitionEndsInPurchaseOrder,
    getRequisitionIsBudgetChecking,
} from '../../../selectors/govApp';

export const BudgetCheckAlertModal = ({ open }) => {
    const dispatch = useDispatch();
    const { onContinue, onCancel, data } = useSelector(getRequisitionBudgetCheckAlertModal);

    const { actionLabel } = data;

    const isLoading = useSelector(getRequisitionIsBudgetChecking);
    const loadError = useSelector(getRequisitionBudgetCheckError);
    const budgetCheckResults = useSelector(getRequisitionBudgetCheckResults);
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    const { changedAccountSplitPriceItems, hasIsOverBudgetChanged } = budgetCheckResults;
    const hasAnyBudgetInfoChanged =
        changedAccountSplitPriceItems?.length > 0 || hasIsOverBudgetChanged;

    const handleCancel = () => {
        onCancel();
        dispatch(hideRequisitionBudgetCheckAlertModal());
    };
    const handleContinue = () => {
        onContinue();
        dispatch(hideRequisitionBudgetCheckAlertModal());
    };

    useEffect(() => {
        // Checks are in place to ensure the budget check has completed loading without errors and
        // returned results that had no changes to the current budget status or line item amounts.
        if (
            !isLoading &&
            !loadError &&
            !hasIsOverBudgetChanged &&
            changedAccountSplitPriceItems &&
            changedAccountSplitPriceItems.length === 0
        ) {
            handleContinue();
        }
    }, [isLoading, loadError, hasIsOverBudgetChanged, changedAccountSplitPriceItems]);

    let modalTitle;
    let modalBody;
    let modalFooter;
    let modalContentProps = {};
    const actionText = endsInPurchaseOrder ? 'Performing Budget Check' : 'Verifying Data';
    if (isLoading) {
        modalTitle = 'Checking For Changes';
        modalBody = <LoadingSpinner noPadding text={`${actionText}...`} useOpenGovStyle />;
    } else if (loadError) {
        modalTitle = `Error ${actionText}`;
        modalBody = <LoadingError error={loadError} useOpenGovStyle />;
    } else if (hasAnyBudgetInfoChanged) {
        modalTitle = (
            <Typography color={capitalDesignTokens.foundations.colors.terracotta600} variant="h3">
                <WarningIcon color={capitalDesignTokens.foundations.colors.terracotta600} />
                Something Has Changed
            </Typography>
        );
        modalBody = <BudgetChangedModalContent {...budgetCheckResults} />;
        modalFooter = true;
        modalContentProps = {
            sx: {
                pl: 8,
            },
        };
    }

    return (
        <Dialog
            closeButton
            contentProps={{ ...modalContentProps }}
            dialogActions={
                modalFooter
                    ? [
                          <Button
                              color="primary"
                              key="cancel"
                              onClick={handleCancel}
                              qaTag="budgetCheckAlarmDialog-seeWhatChanged"
                              variant="outlined"
                          >
                              See What&apos;s Changed
                          </Button>,
                          <Button
                              color="primary"
                              key="continue"
                              onClick={handleContinue}
                              qaTag="budgetCheckAlarmDialog-continue"
                              variant="contained"
                          >
                              {capitalize(actionLabel)} & Continue
                          </Button>,
                      ]
                    : null
            }
            dialogTitle={modalTitle}
            fullWidth
            onClose={handleCancel}
            open={open}
            qaTag="budgetCheckAlarmDialog"
        >
            {modalBody}
        </Dialog>
    );
};

BudgetCheckAlertModal.propTypes = {
    open: PropTypes.bool,
};
