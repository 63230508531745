import { createSelector } from 'reselect';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { getContractPath } from '../selectors';
import { getUserJS } from '../../selectors';

export const getNavItems = createSelector([getContractPath, getUserJS], (contractPath, user) => {
    const {
        government: { hideContractReview, hideContractSpend },
    } = user;

    const enabledContractDocuments = useFlags(FLAGS.ENABLE_CONTRACT_DOCUMENTS_TAB);

    return [
        {
            label: 'Contract Details',
            route: `${contractPath}`,
            indexRoute: true,
        },
        ...(enabledContractDocuments
            ? [
                  {
                      label: 'Documents',
                      route: `${contractPath}/documents`,
                  },
              ]
            : []),
        {
            label: 'Checklists',
            route: `${contractPath}/checklists`,
        },
        {
            label: 'Insurance',
            route: `${contractPath}/insurance`,
        },
        {
            label: 'Contacts',
            route: `${contractPath}/contacts`,
        },
        {
            label: 'Notifications',
            route: `${contractPath}/notifications`,
        },
        ...(hideContractSpend
            ? []
            : [
                  {
                      label: 'Spend Management',
                      route: `${contractPath}/spend-management`,
                  },
              ]),
        ...(hideContractReview
            ? []
            : [
                  {
                      label: 'Vendor Performance',
                      route: `${contractPath}/reviews`,
                  },
              ]),
        {
            label: 'Audit Log',
            route: `${contractPath}/audit-log`,
        },
    ];
});
