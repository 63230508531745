import { Settings as SettingsIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, ReduxFormSelect } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { desiredDeliveryDateOptions } from './constants';
import { FormGroup, SectionSettings, SectionTitleFormGroup, SwitchWithLabel } from '../components';
import { fieldNames } from '../../constants';
import { qaTagName } from '../../../constants';

const {
    DESIRED_DELIVERY_DATE,
    REQUIRE_DESCRIPTION_OF_REQUEST,
    REQUIRE_SUMMARY,
    SECTION_INSTRUCTIONS_GENERAL,
    SECTION_NAME_GENERAL,
    SHOW_BACKGROUND,
    SHOW_CATEGORIES,
} = fieldNames;

const lineStyle = {
    borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
};

export const GeneralSectionSettings = React.forwardRef(({ disabled, onClose, title }, ref) => {
    return (
        <SectionSettings disabled={disabled} onClose={onClose} ref={ref} title={title}>
            <SectionTitleFormGroup
                disabled={disabled}
                sectionInstructionsField={SECTION_INSTRUCTIONS_GENERAL}
                sectionNameField={SECTION_NAME_GENERAL}
            />
            <Box sx={lineStyle} />
            <FormGroup Icon={SettingsIcon} title="Settings">
                <Box>
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        label="Require Name of Request"
                        name={REQUIRE_DESCRIPTION_OF_REQUEST}
                    />
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        label="Require Summary"
                        name={REQUIRE_SUMMARY}
                    />
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        label="Include Background"
                        name={SHOW_BACKGROUND}
                    />
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        label="Include Categories"
                        name={SHOW_CATEGORIES}
                    />
                </Box>
                <Field
                    MenuProps={{
                        disablePortal: true,
                    }}
                    component={ReduxFormSelect}
                    disabled={disabled}
                    fullWidth
                    label="Desired Delivery Date"
                    name={DESIRED_DELIVERY_DATE}
                    options={desiredDeliveryDateOptions}
                    qaTag={`${qaTagName}-${DESIRED_DELIVERY_DATE}`}
                />
            </FormGroup>
        </SectionSettings>
    );
});

GeneralSectionSettings.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
