import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { fieldNames, HAS_ERRORS } from './constants';
import {
    arrayError,
    manualNumber,
    mutateArrayError,
    REQUIRED_TEXT,
} from '../../../../../Forms/validation';

const { EVALUATION_CRITERIA, EVALUATION_PHASE, PRICING } = sectionTypeNames;

const { IS_WRITING_FORM, MANUAL_NUMBER, PROJECT_SECTIONS, SHORT_NAME, TITLE } = fieldNames;

/**
 *
 * @param {object} projectSection The project section to validate
 * @param {object} opts Options for validation
 * @param {boolean} opts.isSharedSection Whether it's a shared section
 * @param {boolean} opts.useManualNumbering Whether manual numbering is used
 * @returns {object} The errors for the project section
 */
export function basicProjectSectionValidate(projectSection, opts = {}) {
    const { isSharedSection, useManualNumbering } = opts;
    const projectSectionErrors = {};

    if (useManualNumbering) {
        const manualNumbering = projectSection[MANUAL_NUMBER];
        projectSectionErrors[MANUAL_NUMBER] = manualNumber(manualNumbering, { isSharedSection });
    }

    if (!projectSection[TITLE]) {
        projectSectionErrors[TITLE] = REQUIRED_TEXT;
    } else if (projectSection[TITLE].length > 100) {
        projectSectionErrors[TITLE] = 'Title is too long (100 character max)';
    }

    if (projectSection[SHORT_NAME] && projectSection[SHORT_NAME].length > 25) {
        projectSectionErrors[SHORT_NAME] = 'Short name is too long (25 character max)';
    }

    return projectSectionErrors;
}

export function validateProjectSection(projectSection, useManualNumbering) {
    let evaluationSectionsCount = 0;
    let pricingProposalSectionExists = false;

    const projectSectionErrors = basicProjectSectionValidate(projectSection, {
        useManualNumbering,
    });

    if (typeof projectSection[IS_WRITING_FORM] !== 'boolean') {
        projectSectionErrors[IS_WRITING_FORM] = 'Please select who can edit the section';
    }

    // Counts number of times `EVALUATION_CRITERIA` or `EVALUATION_PHASE` sections are used for
    // further validation below
    if (
        projectSection.section_type === EVALUATION_CRITERIA ||
        projectSection.section_type === EVALUATION_PHASE
    ) {
        evaluationSectionsCount++;
    }

    if (projectSection.section_type === PRICING) {
        pricingProposalSectionExists = true;
    }

    if (Object.values(projectSectionErrors).some((value) => !!value)) {
        projectSectionErrors[HAS_ERRORS] = true;
    }

    return { projectSectionErrors, evaluationSectionsCount, pricingProposalSectionExists };
}

export function validate(values) {
    const projectSections = values[PROJECT_SECTIONS];
    const { useManualNumbering, useExternalDocument } = values;

    if (useExternalDocument) {
        return { [PROJECT_SECTIONS]: {} };
    }

    // At least one project section must be included
    if (!projectSections || projectSections.length === 0) {
        return { [PROJECT_SECTIONS]: arrayError('Please include at least one section.') };
    }

    let evaluationSectionsCount = 0;
    let pricingProposalSectionExists = false;

    const errors = projectSections.map((projectSection) => {
        const validationResult = validateProjectSection(projectSection, useManualNumbering);

        if (typeof projectSection[IS_WRITING_FORM] !== 'boolean') {
            validationResult.projectSectionErrors[IS_WRITING_FORM] =
                'Please select who can edit the section.';
        }

        evaluationSectionsCount += validationResult.evaluationSectionsCount;
        pricingProposalSectionExists =
            pricingProposalSectionExists || validationResult.pricingProposalSectionExists;

        return validationResult.projectSectionErrors;
    });

    if (values.template?.isReverseAuction && !pricingProposalSectionExists) {
        mutateArrayError(errors, 'Pricing Proposal is required for reverse auction templates.');
    }

    // We can only select one of section type `EVALUATION_CRITERIA` or `EVALUATION_PHASE`
    // They both manipulate the same data but control whether data displays flat or multi-phase
    if (evaluationSectionsCount > 1) {
        projectSections.forEach((projectSection, index) => {
            if (
                projectSection.section_type === EVALUATION_CRITERIA ||
                projectSection.section_type === EVALUATION_PHASE
            ) {
                errors[index][TITLE] =
                    'There can be only one evaluation section per template. Please remove one of your evaluation sections before continuing.';
                errors[index][HAS_ERRORS] = true;
            }
        });
    }

    return {
        [PROJECT_SECTIONS]: errors,
    };
}
