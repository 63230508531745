import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { TemplateCreateButton } from './TemplateCreateButton';
import {
    getNavItems,
    getProjectTypeMenuItems,
    getSelectedNavItem,
    getSelectedProjectType,
    getSelectedTemplates,
} from './selectors';
import { TemplateListSelectForm } from '../components/TemplateListSelectForm';
import { getProjectTypeSelectOptions, getTemplatesAdminPath, getTemplatesJS } from '../selectors';
import connectData from '../../../ConnectData';
import {
    createTemplate,
    loadTemplatesList,
    resetTemplateList,
} from '../../../../actions/templatesAdmin';
import {
    LoadingError,
    LoadingSpinner,
    PageTitle,
    TemplateList as TemplateListComponent,
} from '../../../../components';
import { useContractingDocument } from '../../../../hooks';

const SelectFilter = ({
    hasMultipleProjectTypes,
    location,
    projectTypeSelectOptions,
    selectedProjectType,
}) => {
    const hasContractingDocument = useContractingDocument();

    if (!hasContractingDocument || !hasMultipleProjectTypes) {
        return null;
    }

    return (
        <TemplateListSelectForm
            location={location}
            projectTypeSelectOptions={projectTypeSelectOptions}
            selectedProjectType={selectedProjectType}
        />
    );
};

SelectFilter.propTypes = {
    hasMultipleProjectTypes: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object.isRequired,
        search: PropTypes.string,
    }).isRequired,
    projectTypeSelectOptions: PropTypes.array.isRequired,
    selectedProjectType: PropTypes.string.isRequired,
};

function fetchData(getState, dispatch) {
    if (!getState().templatesAdmin.get('loadedList')) {
        return dispatch(loadTemplatesList());
    }
    return Promise.resolve();
}

const mapStateToProps = (state, props) => {
    const projectTypeMenuItems = getProjectTypeMenuItems(state);

    return {
        createError: state.templatesAdmin.get('createError'),
        creating: state.templatesAdmin.get('creating'),
        loadError: state.templatesAdmin.get('loadListError'),
        loading: state.templatesAdmin.get('loadingList'),
        hasMultipleProjectTypes: projectTypeMenuItems.length > 1,
        hasTemplates: getTemplatesJS(state).length > 0,
        navItems: getNavItems(state, props),
        projectTypeMenuItems: getProjectTypeMenuItems(state),
        projectTypeSelectOptions: getProjectTypeSelectOptions(state),
        selectedNavItem: getSelectedNavItem(state, props),
        selectedProjectType: getSelectedProjectType(state, props),
        templates: getSelectedTemplates(state, props),
        templatesAdminPath: getTemplatesAdminPath(state, props),
    };
};

const mapDispatchToProps = {
    createTemplate,
    resetTemplateList,
};

// @connectData
// @connect
class ConnectedTemplateList extends Component {
    static propTypes = {
        createError: PropTypes.string,
        createTemplate: PropTypes.func.isRequired,
        creating: PropTypes.bool.isRequired,
        hasMultipleProjectTypes: PropTypes.bool.isRequired,
        hasTemplates: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.object.isRequired,
            search: PropTypes.string,
        }).isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
        projectTypeMenuItems: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            })
        ).isRequired,
        projectTypeSelectOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            })
        ).isRequired,
        resetTemplateList: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        selectedNavItem: PropTypes.object,
        selectedProjectType: PropTypes.string.isRequired,
        templates: PropTypes.arrayOf(
            PropTypes.shape({
                user: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                }),
            })
        ).isRequired,
        templatesAdminPath: PropTypes.string.isRequired,
    };

    componentWillUnmount() {
        this.props.resetTemplateList();
    }

    createTemplate = (type, routeToAi = false) => {
        const { router, templatesAdminPath } = this.props;
        this.props.createTemplate({ type }, (templateProject) => {
            if (routeToAi === true) {
                return router.push(`${templatesAdminPath}/${templateProject.id}/document-builder`);
            }
            this.routeToTemplate(templateProject.id)();
        });
    };

    routeToTemplate = (templateId) => () => {
        const { templatesAdminPath, location, router } = this.props;
        router.push({
            pathname: `${templatesAdminPath}/${templateId}/process-information`,
            search: location.search,
        });
    };

    render() {
        const {
            createError,
            creating,
            hasMultipleProjectTypes,
            hasTemplates,
            location,
            loadError,
            loading,
            navItems,
            projectTypeMenuItems,
            projectTypeSelectOptions,
            selectedNavItem,
            selectedProjectType,
            templates,
        } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        const createTemplateHandler = this.createTemplate;

        // eslint-disable-next-line react/prop-types
        const TemplateCreateButtonFn = ({ bsSize }) => (
            <TemplateCreateButton
                bsSize={bsSize}
                createTemplate={createTemplateHandler}
                creating={creating}
                projectTypeMenuItems={projectTypeMenuItems}
            />
        );

        return (
            <>
                <PageTitle title="Template List" />
                <TemplateListComponent
                    SelectFilter={
                        <SelectFilter
                            hasMultipleProjectTypes={hasMultipleProjectTypes}
                            location={location}
                            projectTypeSelectOptions={projectTypeSelectOptions}
                            selectedProjectType={selectedProjectType}
                        />
                    }
                    TemplateCreateButton={TemplateCreateButtonFn}
                    createError={createError}
                    hasMultipleTypes={hasMultipleProjectTypes}
                    hasTemplates={hasTemplates}
                    location={location}
                    navItems={navItems}
                    routingHandler={this.routeToTemplate}
                    selectedNavItem={selectedNavItem}
                    templateItems={templates}
                    title="Templates"
                />
            </>
        );
    }
}

export const TemplateList = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedTemplateList);
