import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { Button, Box } from '@og-pro/ui';

import { AiImporterDialog } from './AiImporterDialog';
import { TemplateEditV2ManageSectionsDrawerContent } from './DrawerContent';
import { SDv2Drawer, CDSButton } from '../../../../../../../components';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../../../context';

export const TemplateEditV2ManageSectionsActionHeader = ({ disabled }) => {
    const isTemplateIngestionAiEnabled = useFlags(FLAGS.ENABLE_TEMPLATE_INGESTION_AI);
    const { manageSectionsDrawerOpened, toggleManageSectionsDrawerOpened } = useContext(
        TemplateEditV2FunctionsContext
    );
    const { setShowAiImporterDialog, showAiImporterDialog } = useContext(TemplateEditV2NavContext);

    const styles = require('./index.scss');

    return (
        <>
            {createPortal(
                <SDv2Drawer
                    onBackdropClick={() => toggleManageSectionsDrawerOpened()}
                    open={manageSectionsDrawerOpened}
                >
                    <TemplateEditV2ManageSectionsDrawerContent />
                </SDv2Drawer>,

                document.body
            )}
            <div className={classnames(styles.container)}>
                <div className={styles.col}>
                    <h4>Document Outline</h4>
                </div>
                <div className={classnames(styles.col, styles.textRight)}>
                    <CDSButton
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation();

                            toggleManageSectionsDrawerOpened();
                        }}
                        qaTag="templateEditor-manageSections"
                        size="small"
                        variant="secondary"
                    >
                        Manage Sections
                    </CDSButton>
                </div>
            </div>
            {isTemplateIngestionAiEnabled && (
                <Box sx={{ marginBottom: 2 }}>
                    <Button
                        color="primary"
                        disabled={disabled}
                        fullWidth
                        onClick={() => {
                            setShowAiImporterDialog(true);
                        }}
                        qaTag="templateEditor-importTemplate"
                        variant="outlined"
                    >
                        Import Template
                    </Button>
                </Box>
            )}
            {showAiImporterDialog && (
                <AiImporterDialog onClose={() => setShowAiImporterDialog(false)} />
            )}
        </>
    );
};

TemplateEditV2ManageSectionsActionHeader.propTypes = {
    disabled: PropTypes.bool,
};
