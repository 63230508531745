export const DEFAULT_PAGE_SIZE = 25;
export const CACHE_BLOCK_SIZE = 250;

export const defaultColDef = {
    editable: false,
    sortable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    resizable: false,
};
