import { useDispatch } from 'react-redux';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { showConfirmationModal } from '../../actions/confirmation';
import { UPDATE_RECORD_CLOSE_DATE } from '../../constants/menuActions';

export const CloseDateButton = ({ canEdit, closeDate, project }) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const showRecordCloseDateModal = () => {
        const recordCloseDate = closeDate ? new Date(closeDate) : new Date();
        return dispatch(
            showConfirmationModal(UPDATE_RECORD_CLOSE_DATE, {
                project,
                recordCloseDate,
            })
        );
    };

    const clickableStyle = canEdit ? styles.clickableText : undefined;
    const clickHandler = canEdit ? showRecordCloseDateModal : undefined;

    const renderCloseDate = () => {
        if (closeDate) {
            return <span>{moment(closeDate).format('l')}</span>;
        }

        return <span className={styles.muted}>No Close Date</span>;
    };
    return (
        <span className={clickableStyle} onClick={clickHandler}>
            {renderCloseDate()}
            {canEdit && <i aria-label="edit" className={`fa fa-edit ${styles.icon}`} />}
        </span>
    );
};

CloseDateButton.propTypes = {
    canEdit: PropTypes.bool,
    closeDate: PropTypes.string,
    project: PropTypes.object.isRequired,
};
