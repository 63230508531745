import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Box, Button, ReduxFormTextField, Typography } from '@og-pro/ui';
import { Upload as UploadIcon } from '@mui/icons-material';

import { limitTextLength } from '../../../../../Forms/normalizers';
import { TITLE } from './constants';

export const DocxTemplateFormFieldset = ({
    disabled,
    initialValues,
    cancelHandler,
    uploadError,
}) => {
    return (
        <>
            <div className="col-xs-12 col-sm-8">
                <Field
                    component={ReduxFormTextField}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Export Document Title"
                    name={TITLE}
                    normalize={limitTextLength(128)}
                    qaTag={`docxTemplates-${TITLE}`}
                    sx={{ width: '100%', marginBottom: 2 }}
                    type="text"
                    useOpenGovStyle
                />
            </div>
            <div className="col-xs-12">
                <Typography fontSize="medium" fontWeight={500}>
                    File Name
                </Typography>
                <Typography fontSize="medium">{initialValues[TITLE]}</Typography>
            </div>
            <Box className="col-sm-12" mt={2}>
                <Button
                    disabled={disabled}
                    qaTag="docxTemplate-upload"
                    startIcon={<UploadIcon fontSize="small" />}
                    type="submit"
                    variant="contained"
                >
                    Upload
                </Button>
                <Button
                    onClick={cancelHandler}
                    qaTag="docxTemplate-uploadCancel"
                    variant="secondary"
                >
                    Cancel
                </Button>
            </Box>
            {uploadError && (
                <div className="col-xs-12 text-center">
                    <Typography color="error" fontSize="medium">
                        {uploadError}
                    </Typography>
                </div>
            )}
        </>
    );
};

DocxTemplateFormFieldset.propTypes = {
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    cancelHandler: PropTypes.func.isRequired,
    uploadError: PropTypes.string,
};
