import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSearchParams, Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { destroy } from 'redux-form';

import { EvaluationPhaseSelectForm, EvaluationProfile } from './components';
import { EvaluationNotesModal } from './EvaluationNotesModal';
import { EvaluationScorecardModal } from './EvaluationScorecardModal';
import { mapStateToProps as evaluationNavMapStateToProps, actionCreators } from './mapProps';
import { ProposalSelectModal } from './ProposalSelectModal';
import { getEvaluationPhaseSelectOptions, getSelectedEvaluationPhase } from './selectors';
import {
    getDashboardPath,
    getEvaluationAuditData,
    getQueryPhase,
    isEvaluationViewer,
    isGlobalEditorForProject,
} from '../selectors';
import { FixedFooter, LoadingError, LoadingSpinner, Nav, NavItem } from '../../../components';
import { ConnectedClients } from '../../../components/connected';

const mapStateToProps = (state, props) => {
    return {
        ...evaluationNavMapStateToProps(state, props),
        dashboardPath: getDashboardPath(state, props),
        evaluationAudit: getEvaluationAuditData(state, props),
        evaluationPhaseSelectOptions: getEvaluationPhaseSelectOptions(state),
        isGlobalEditor: isGlobalEditorForProject(state),
        isViewer: isEvaluationViewer(state),
        queryPhase: getQueryPhase(state, props),
        selectedEvaluationPhase: getSelectedEvaluationPhase(state, props),
        showProposalSelectModal: state.proposalEvaluations.get('showProposalSelectModal'),
    };
};

const mapDispatchToProps = {
    ...actionCreators,
    destroyForm: destroy,
};

// @connect
class ConnectedEvaluationNav extends Component {
    static propTypes = {
        dashboardPath: PropTypes.string.isRequired,
        destroyForm: PropTypes.func.isRequired,
        evaluationAudit: PropTypes.shape({
            evaluation: PropTypes.shape({
                phaseNumber: PropTypes.number.isRequired,
            }).isRequired,
        }),
        evaluationPhaseSelectOptions: PropTypes.array.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isGlobalEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isSubscribed: PropTypes.bool.isRequired,
        isViewer: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                phase: PropTypes.string,
            }).isRequired,
        }).isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        navItems: PropTypes.array.isRequired,
        project: PropTypes.object,
        router: PropTypes.object.isRequired,
        queryPhase: PropTypes.number,
        selectedEvaluationPhase: PropTypes.number,
        showProposalSelectModal: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    componentWillUnmount() {
        // Manually destroy display options form as it does not destroy on unmount
        this.props.destroyForm('evaluationsTableDisplayOptions');
    }

    get styles() {
        return require('./EvaluationNav.scss');
    }

    menuActionHandler = (type) => {
        return this.props.menuActionHandler(type, this.props.project);
    };

    selectEvaluationPhase = ({ phase }) => {
        const {
            location: { pathname, query },
            project: { evaluation },
            queryPhase,
            router,
        } = this.props;

        // Since the form is not reinitialized between lists it gets passed `undefined` on URL
        // changes which causes it to always update on page changes, which is undesirable.
        // Additionally, we set a default value to always populate the selector, but as a result
        // a search is attempted when there is no query phase and form is initialized to current.
        // To prevent this we don't execute the search on this condition.
        const alreadyDefaultedToCurrent = phase === evaluation.phaseNumber && !queryPhase;
        if (phase && !alreadyDefaultedToCurrent && phase !== queryPhase) {
            router.push({
                pathname,
                search: createSearchParams({ ...query, phase }).toString(),
            });
        }
    };

    renderEvaluationPhaseSelector() {
        const {
            evaluationPhaseSelectOptions,
            project: { evaluationAudits },
            selectedEvaluationPhase,
        } = this.props;

        if (evaluationAudits.length === 0) {
            return null;
        }

        return (
            <div className="text-right">
                <div className={this.styles.phaseSelectContainer}>
                    <label>Viewing</label>&nbsp;
                    <EvaluationPhaseSelectForm
                        formClassName={this.styles.phaseSelectForm}
                        initialValues={{ phase: selectedEvaluationPhase }}
                        onChange={this.selectEvaluationPhase}
                        options={evaluationPhaseSelectOptions}
                    />
                </div>
            </div>
        );
    }

    renderNavItems() {
        const { navItems } = this.props;

        const NavItems = navItems.map((item) => {
            return (
                <NavItem
                    key={item.title}
                    overrideIsActive={item.active}
                    qaTag={`evaluation-${item.title}`}
                    to={item.link}
                >
                    {item.title}
                </NavItem>
            );
        });

        return (
            <Nav
                bsStyle="tabs"
                className={`${this.styles.navItems} no-print`}
                justified={navItems.length < 7}
            >
                {NavItems}
            </Nav>
        );
    }

    renderFooter() {
        const {
            evaluationAudit: {
                evaluation: { phaseNumber },
            },
            location: { pathname },
            router,
        } = this.props;

        return (
            <FixedFooter bsStyle="warning" className={this.styles.footer} offset={70}>
                <div className={this.styles.footerWarning}>
                    <strong>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;You are viewing past Evaluation Phase {phaseNumber}
                    </strong>
                </div>
                <span
                    className="pseudoLink"
                    onClick={() => {
                        window.scroll(0, 0);
                        router.push(pathname);
                    }}
                >
                    Click here to go back to the current Evaluation Phase
                </span>
            </FixedFooter>
        );
    }

    render() {
        const {
            dashboardPath,
            evaluationAudit,
            isEditor,
            isGlobalEditor,
            isOwner,
            isSubscribed,
            isViewer,
            project,
            showProposalSelectModal,
            timezone,
        } = this.props;

        if (!project) {
            return <LoadingSpinner />;
        }

        return (
            <>
                <ConnectedClients block />
                <EvaluationProfile
                    actionHandler={this.menuActionHandler}
                    dashboardPath={dashboardPath}
                    isEditor={isEditor}
                    isGlobalEditor={isGlobalEditor}
                    isOwner={isOwner}
                    project={project}
                    subscribed={isSubscribed}
                    timezone={timezone}
                />
                <Panel className="print-panel">
                    <Panel.Body className={this.styles.panelBody}>
                        {this.renderEvaluationPhaseSelector()}
                        {this.renderNavItems()}
                        {isViewer ? (
                            <Outlet />
                        ) : (
                            <LoadingError error="You have not received permission to access this evaluation" />
                        )}
                    </Panel.Body>
                </Panel>
                {evaluationAudit && this.renderFooter()}
                <EvaluationScorecardModal />
                <EvaluationNotesModal />
                {showProposalSelectModal && <ProposalSelectModal project={project} />}
            </>
        );
    }
}

export const EvaluationNav = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedEvaluationNav);
