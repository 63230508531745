import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { Box, Button, Dialog } from '@og-pro/ui';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { fieldNames, form, OTHER } from './constants';
import { RejectionNoteForm } from './RejectionNoteForm';
import { getRejectionNoteModalData } from './selectors';
import { qaTagPageName } from '../../../constants';
import {
    hideRejectionNoteModal,
    submitApproval,
} from '../../../../../../actions/requisitionsApprovals';

const { REJECTION_NOTE_EXTRA, REJECTION_NOTE, REJECTION_POLICY } = fieldNames;

export const RejectionNoteModal = ({ requireRejectionNote }) => {
    const [{ loading, error }, setLoadingState] = useState({ loading: false, error: null });
    const [showValidation, setShowValidation] = useState(false);
    const dispatch = useDispatch();

    const { showModal, stepPositionApprover } = useSelector(getRejectionNoteModalData);
    const hideModal = () => {
        setShowValidation(false);
        dispatch(hideRejectionNoteModal());
    };

    const onSubmit = (rawData) => {
        setShowValidation(true);

        // If `OTHER` is selected, take the value from `REJECTION_NOTE_EXTRA` instead
        const rejectionNote =
            rawData[REJECTION_NOTE] === OTHER
                ? rawData[REJECTION_NOTE_EXTRA]
                : rawData[REJECTION_NOTE];
        const rejectionPolicy = JSON.parse(rawData[REJECTION_POLICY]);

        setLoadingState({ loading: true, error: null });

        // Call the submit handler with the formatted data
        return dispatch(
            submitApproval(
                stepPositionApprover.id,
                {
                    rejectionNote,
                    rejectionPolicy,
                    status: approvalStatusTypes.REJECTED,
                },
                { onSuccess: hideModal }
            )
        ).then((resultOrError) => {
            // Reset loading state and include any error message
            setLoadingState({
                loading: false,
                error: resultOrError instanceof Error ? resultOrError?.message : null,
            });
        });
    };

    const dialogActions = (
        <>
            <Button
                color="secondary"
                disabled={loading}
                onClick={hideModal}
                qaTag={`${qaTagPageName}-cancel`}
                variant="text"
            >
                Cancel
            </Button>
            <Button
                color="error"
                disabled={loading}
                loading={loading}
                onClick={() => {
                    setShowValidation(true);
                    dispatch(submit(form));
                }}
                qaTag={`${qaTagPageName}-reject`}
                startIcon={<DoNotDisturbIcon />}
                variant="contained"
            >
                Reject
            </Button>
        </>
    );

    return (
        <Dialog
            aria-labelledby="rejection-note-modal"
            closeButton
            contentProps={{
                sx: {
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: '8px !important',
                    paddingBottom: 0,
                },
            }}
            dialogActions={dialogActions}
            dialogTitle="Justification for Rejection"
            fullWidth
            onClose={hideModal}
            open={showModal}
        >
            <RejectionNoteForm
                onSubmit={onSubmit}
                requireRejectionNote={requireRejectionNote}
                showValidation={showValidation}
            />
            <Box textAlign="right">{error && <div className="error-block">{error}</div>}</Box>
        </Dialog>
    );
};

RejectionNoteModal.propTypes = {
    requireRejectionNote: PropTypes.bool,
};
