import classnames from 'classnames';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import React, { Component } from 'react';
import { Alert, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { NoSsr } from '@mui/material';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { Alert as MuiAlert, AlertTitle, Typography, IconButton } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Edit as EditIcon } from '@mui/icons-material';

import { projectClosedSubstatusesDict, projectStatusesDict } from '@og-pro/shared-config/projects';

import { AssociatedProjects, DisplayItems } from '../components';
import { ReportsModalButton } from '../../ReportsModal/ReportsModalButton';
import {
    getBaseProjectPath,
    getBidPosterAuthorized,
    getProjectJS,
    hasRequisitionSubscription,
    isDocumentEditor,
} from '../../selectors';
import {
    Button,
    CloseDateButton,
    LoadingError,
    Main,
    ProjectRequisitionsDisplay,
    ProjectRetentionPolicyButton,
    ProjectStatusLabel,
    SubscribeButton,
} from '../../../../components';
import { ConnectedClients } from '../../../../components/connected';
import { PreInviteModal } from '../PreInviteModal';
import { getProjectContracts } from '../selectors';
import { isRetentionAdminUser } from '../../../selectors';
import { ProjectHoldStatus } from '../../../../components/ProjectHoldStatus';
import ProjectCloseOutModal from '../../ProjectCloseOutModal';

const { CLOSED } = projectStatusesDict;
const { AWARDED } = projectClosedSubstatusesDict;

const mapStateToProps = (state, props) => {
    return {
        contracts: getProjectContracts(state),
        hasBidPostAuthorization: getBidPosterAuthorized(state),
        hasRequisitions: hasRequisitionSubscription(state),
        isRetentionAdmin: isRetentionAdminUser(state),
        isUserDocumentEditor: isDocumentEditor(state),
        project: getProjectJS(state),
        projectPath: getBaseProjectPath(state, props),
    };
};

// @connect
class ConnectedProjectDashboardContainer extends Component {
    static propTypes = {
        contracts: PropTypes.array,
        getFlag: PropTypes.func.isRequired,
        hasBidPostAuthorization: PropTypes.bool.isRequired,
        hasRequisitions: PropTypes.bool.isRequired,
        isRetentionAdmin: PropTypes.bool.isRequired,
        isUserDocumentEditor: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            closedSubstatus: PropTypes.string,
            closeOutReason: PropTypes.string,
            departmentName: PropTypes.string,
            financialId: PropTypes.string,
            id: PropTypes.number,
            isPaused: PropTypes.bool.isRequired,
            isPausedReason: PropTypes.string,
            isPrivate: PropTypes.bool.isRequired,
            project_association_id: PropTypes.number,
            recordCloseDate: PropTypes.string,
            retention_code_id: PropTypes.number,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                icon: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string,
        }),
        projectPath: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        this.state = {
            showCloseOutModal: false,
        };
    }

    toggleCloseOutModal = () => {
        this.setState((prevState) => ({ showCloseOutModal: !prevState.showCloseOutModal }));
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        const {
            contracts,
            getFlag,
            hasBidPostAuthorization,
            hasRequisitions,
            isRetentionAdmin,
            isUserDocumentEditor,
            project,
            project: {
                closedSubstatus,
                closeOutReason,
                departmentName,
                financialId,
                isPaused,
                isPausedReason,
                isPrivate,
                recordCloseDate,
                retention_code_id: retentionCodeId,
                status,
                template,
            },
            projectPath,
        } = this.props;

        if (!project) {
            return <LoadingError />;
        }

        const enableClosedSubstatus = getFlag(FLAGS.ENABLE_CLOSED_SUBSTATUSES);
        const hasContracts = contracts && contracts.length > 0;
        const projectClosed = status === CLOSED;
        const showCloseDateButton = projectClosed && hasBidPostAuthorization;
        const showRetentionPolicyButton = projectClosed && isRetentionAdmin;

        const displayItems = [
            { label: 'Template', value: template.title },
            { label: 'Department', value: departmentName },
            { label: 'Project ID', value: financialId },
            {
                label: 'Associated Projects',
                value: project.project_association_id && <AssociatedProjects />,
            },
            {
                label: 'Request(s)',
                value: hasRequisitions && (
                    <ProjectRequisitionsDisplay
                        isEditor={isUserDocumentEditor}
                        projectId={project.id}
                    />
                ),
            },
            {
                label: 'Project Close Date',
                value: showCloseDateButton && (
                    <CloseDateButton
                        canEdit={!hasContracts}
                        closeDate={recordCloseDate}
                        project={project}
                    />
                ),
            },
            {
                label: 'Retention Policy',
                value: showRetentionPolicyButton && (
                    <ProjectRetentionPolicyButton
                        canEdit={!hasContracts}
                        retentionCodeId={retentionCodeId}
                    />
                ),
            },
        ];

        // if the new closed substatuses are enabled, we show the close out reason in the alert
        if (!enableClosedSubstatus) {
            displayItems.push({ label: 'Closed Out', value: closeOutReason });
        }

        const closureReasonSection = (
            <div className={this.styles.closeOutReason}>
                <MuiAlert
                    action={
                        <IconButton
                            aria-label="Edit Closure Reason"
                            onClick={this.toggleCloseOutModal}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    }
                    icon={false}
                    severity="info"
                    sx={{
                        borderRadius: '4px',
                        boxShadow: 'none',
                        borderColor:
                            capitalDesignTokens.semanticColors.callToAction.primaryActionLight,
                    }}
                    variant="condensed"
                >
                    <AlertTitle>
                        <Typography
                            color={capitalDesignTokens.semanticColors.foreground.primary}
                            fontSize="14px"
                            fontWeight="600"
                        >
                            Closure Comment:
                        </Typography>
                    </AlertTitle>
                    <Typography variant="bodySmall">
                        {closeOutReason ?? 'No reason provided'}
                    </Typography>
                </MuiAlert>
            </div>
        );

        return (
            <Main className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <ConnectedClients block />
                    <NoSsr>
                        <PreInviteModal />
                    </NoSsr>
                    <Panel>
                        <Panel.Body>
                            {isPrivate && (
                                <Alert bsStyle="info" className={this.styles.alert}>
                                    <i className="fa fa-lock" /> Private Bid (not displayed on
                                    public portal)
                                </Alert>
                            )}
                            <ProjectHoldStatus project={{ isPaused, isPausedReason }} />
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className={this.styles.subscribeButtonContainer}>
                                        <ReportsModalButton
                                            bsSize="sm"
                                            className={this.styles.reportsButton}
                                            text="Reports"
                                        />
                                        &nbsp;&nbsp;
                                        <SubscribeButton isGovernment project={project} />
                                    </div>
                                    <div className={this.styles.projectTitle}>
                                        <h3>{project.title || 'Untitled'}</h3>
                                        <div className={this.styles.projectStatus}>
                                            <ProjectStatusLabel
                                                className={this.styles.statusLabel}
                                                status={project.status}
                                            />
                                            {enableClosedSubstatus && closedSubstatus ? (
                                                <div
                                                    className={classnames(
                                                        this.styles.closedSubstatus,
                                                        closedSubstatus === AWARDED
                                                            ? this.styles.closedSubstatusAwarded
                                                            : this.styles.closedSubstatusOther
                                                    )}
                                                >
                                                    {startCase(closedSubstatus)}
                                                </div>
                                            ) : null}
                                            <Button
                                                bsStyle="link"
                                                qaTag="connectedProjectDashboardContainer-statusHistory"
                                                to={`${projectPath}/status-history`}
                                                zeroPadding
                                            >
                                                <i className="fa fa-history" /> Status History
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {enableClosedSubstatus &&
                            status === CLOSED &&
                            closedSubstatus !== AWARDED
                                ? closureReasonSection
                                : null}
                            <div className={classnames('row', this.styles.details)}>
                                <div className="col-xs-12">
                                    <DisplayItems items={displayItems} />
                                </div>
                            </div>
                            <Outlet />
                        </Panel.Body>
                    </Panel>
                </div>
                <ProjectCloseOutModal
                    hideDialog={this.toggleCloseOutModal}
                    initialValues={{ closedSubstatus, closeOutReason }}
                    isUpdate
                    open={this.state.showCloseOutModal}
                    project={project}
                />
            </Main>
        );
    }
}

export const ProjectDashboardContainer = compose(
    withRouter,
    withFlags(),
    connect(mapStateToProps)
)(ConnectedProjectDashboardContainer);
