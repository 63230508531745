import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reviewTypeOptions, reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { addAdHocStep, loadRequisitionUsers } from '../../../../../actions/requisitions';
import { getRequisitionJS } from '../../../../../selectors/govApp';
import {
    getAddAdHocStepError,
    getAddingAdHocStep,
    getRejectionPolicyOptions,
    getReviewGroupAndEntityUsers,
} from '../../selectors';
import { SearchSelect, SearchSelectUserOption } from '../../../../../components';

export const AddStepButton = ({ currentStep, currentStepIndex }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedReviewType, setSelectedReviewType] = useState(null);
    const [stepName, setStepName] = useState('');
    const [selectedRejectionPolicies, setSelectedRejectionPolicies] = useState([]);

    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);
    const users = useSelector(getReviewGroupAndEntityUsers);
    const isAdding = useSelector(getAddingAdHocStep);
    const error = useSelector(getAddAdHocStepError);
    const allRejectionPolicyOptions = useSelector(getRejectionPolicyOptions);
    const rejectionPolicyOptions = allRejectionPolicyOptions.filter((policyOption) => {
        return policyOption.index === undefined || policyOption.index <= currentStepIndex;
    });
    const handleCancel = () => {
        setIsEditing(false);
        setSelectedUser(null);
        setSelectedReviewType(null);
        setStepName('');
        setSelectedRejectionPolicies([]);
    };

    // (Re-)Load users for the users' dropdown menu when editing
    useEffect(() => {
        if (isEditing) {
            dispatch(loadRequisitionUsers(requisition.review_group_id));
        }
    }, [dispatch, isEditing, requisition.review_group_id]);

    // Clean form when step is added successfully
    useEffect(() => {
        if (!isAdding && !error) {
            handleCancel();
        }
    }, [isAdding, error]);

    const handleStartEditing = () => setIsEditing(true);

    const handleUserChange = (selected) => {
        if (selected) setSelectedUser(selected);
        else {
            setSelectedUser(null);
        }
    };

    const handleReviewTypeChange = (event) => {
        setSelectedReviewType(event.target.value);
        if (event.target.value !== reviewTypes.APPROVE) {
            setSelectedRejectionPolicies([]);
        }
    };

    const handleStepNameChange = (event) => {
        setStepName(event.target.value);
    };

    const handleRejectionPolicyChange = (selected) => {
        setSelectedRejectionPolicies(selected || []);
    };

    const handleAddStep = () => {
        const stepData = {
            userId: selectedUser?.userId, // User to assign the step to. In the future we may want to allow for multiple users
            reviewType: selectedReviewType,
            name: stepName,
            positionId: selectedUser?.positionId,
            rejectionPolicies:
                selectedReviewType === reviewTypes.APPROVE && selectedRejectionPolicies.length > 0
                    ? selectedRejectionPolicies.map((value) => ({ value }))
                    : [],
        };

        dispatch(addAdHocStep(requisition.id, currentStep.id, stepData));
    };

    if (!isEditing) {
        return (
            <Box>
                <Button
                    color="primary"
                    onClick={handleStartEditing}
                    qaTag="addStepButton-button-add"
                    startIcon={<AddIcon fontSize="small" />}
                    sx={{
                        p: 0,
                    }}
                    variant="text"
                >
                    Add Step
                </Button>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '4px',
                padding: '1.5rem',
                margin: 0,
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
                '& .MuiTypography-h6': {
                    fontSize: '1rem',
                    fontWeight: 500,
                    color: 'grey.900',
                    marginBottom: '1.5rem',
                },
                '& .MuiFormControl-root': {
                    padding: 0,
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.875rem',
                    color: 'grey.700',
                },
                '& .MuiSelect-select, & .MuiInputBase-input': {
                    fontSize: '0.875rem',
                },
                '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            {error && (
                <Typography color="error" gutterBottom variant="bodyDefault">
                    {error}
                </Typography>
            )}
            <Typography gutterBottom sx={{ mb: 2 }} variant="h3">
                Add Step
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography color="text.primary" sx={{ mb: 0.5, ml: 0 }} variant="h5">
                    Type of Review to Perform
                </Typography>
                <Select
                    fullWidth
                    onChange={handleReviewTypeChange}
                    placeholder="Select"
                    qaTag="addStepButton-select-reviewType"
                    sx={{ m: 0 }}
                    value={selectedReviewType}
                    variant="outlined"
                >
                    {reviewTypeOptions.map((option) => (
                        <MenuItem
                            key={option.value}
                            qaTag={`addStepButton-option-${option.value}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography color="text.primary" sx={{ mb: 0.5, ml: 0 }} variant="h5">
                    Step Name
                </Typography>
                <TextField
                    fullWidth
                    id="step-name"
                    onChange={handleStepNameChange}
                    qaTag="addStepButton-input-stepName"
                    sx={{
                        m: 0,
                        '& .MuiOutlinedInput-root': {
                            m: 0,
                        },
                    }}
                    value={stepName}
                />
            </FormControl>
            <FormControl fullWidth>
                <Typography color="text.primary" sx={{ mb: 0.5, ml: 0 }} variant="h5">
                    User Assigned to Step
                </Typography>
                <SearchSelect
                    components={{ Option: SearchSelectUserOption }}
                    fullWidth
                    input={{
                        onChange: handleUserChange,
                        onBlur: () => {},
                        value: selectedUser,
                    }}
                    isClearable
                    meta={{
                        touched: false,
                        error: null,
                    }}
                    options={users}
                    placeholder="Select"
                    qaTag="addStepButton-select-user"
                    sx={{ m: 0 }}
                />
            </FormControl>
            {selectedReviewType === reviewTypes.APPROVE && (
                <FormControl fullWidth>
                    <Typography color="text.primary" sx={{ mb: 0.5, ml: 0 }} variant="h5">
                        Decision Re-Routing
                    </Typography>
                    <SearchSelect
                        backspaceRemovesValue={false}
                        closeMenuOnSelect={false}
                        input={{
                            onChange: handleRejectionPolicyChange,
                            onBlur: () => {},
                            value: selectedRejectionPolicies,
                        }}
                        isMulti
                        isMultiSimpleValue
                        isSearchable={false}
                        meta={{
                            touched: false,
                            error: null,
                        }}
                        options={rejectionPolicyOptions}
                        placeholder="Select rejection options"
                        qaTag="addStepButton-select-rejectionPolicy"
                        sx={{ m: 0 }}
                    />
                </FormControl>
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <Button
                    color="secondary"
                    onClick={handleCancel}
                    qaTag="addStepButton-button-cancel"
                    variant="text"
                >
                    Cancel
                </Button>
                <Button
                    disabled={!selectedUser || !stepName || isAdding}
                    onClick={handleAddStep}
                    qaTag="addStepButton-button-confirm"
                    variant="contained"
                >
                    Add Step
                </Button>
            </Box>
        </Box>
    );
};

AddStepButton.propTypes = {
    currentStep: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        orderById: PropTypes.number.isRequired,
    }).isRequired,
    currentStepIndex: PropTypes.number.isRequired,
};
