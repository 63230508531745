import { CONTRACT_ASSOCIATIONS } from '@og-pro/shared-config/audits/record-audit';

import { ASSOCIATIONS } from '../constants';
import { formatKey } from './format';

export const getContextualInformation = ({ namespace, log }) => {
    switch (namespace) {
        case CONTRACT_ASSOCIATIONS.MILESTONE:
            return log.context?.milestone?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.MILESTONE];
        case CONTRACT_ASSOCIATIONS.INSURANCE:
            return log.context?.tag?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.INSURANCE];
        case CONTRACT_ASSOCIATIONS.CONTACT:
            return log.context?.tag?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CONTACT];
        case CONTRACT_ASSOCIATIONS.ATTACHMENT:
            return log.context?.filename || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.ATTACHMENT];
        case CONTRACT_ASSOCIATIONS.CHECKLIST:
            return log.context?.template?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST];
        case CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE:
            return `${log.context?.checklist?.template?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST]} / ${log.context?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE]}`;
        case CONTRACT_ASSOCIATIONS.NOTIFICATION:
            return log.context?.milestone?.name || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.MILESTONE];
        case CONTRACT_ASSOCIATIONS.REVIEW:
            return 'Vendor Review';
        case CONTRACT_ASSOCIATIONS.REVIEW_COMPLAINT:
            return `Vendor Complaint / ${log.context?.complaint?.title || ASSOCIATIONS[CONTRACT_ASSOCIATIONS.REVIEW]}`;
        case CONTRACT_ASSOCIATIONS.PURCHASE_ORDER:
            return `Purchase Order ${log.context?.purchaseOrder?.number || ''}`;
        case CONTRACT_ASSOCIATIONS.RENEWAL:
            return `Renewal option`;
        case CONTRACT_ASSOCIATIONS.BUDGET_ALLOCATION:
            return log.context?.budgetAllocation?.year;
        default:
            return namespace;
    }
};

export const getInformation = ({ namespace, key, log }) => {
    const formattedKey = formatKey(namespace, key);

    if (!namespace) {
        return formattedKey;
    }

    if (namespace === CONTRACT_ASSOCIATIONS.INSURANCE && key === 'tag_id') {
        return 'Insurance type';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.CONTACT && key === 'tag_id') {
        return 'Contact type';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.ATTACHMENT && key === 'filename') {
        return 'File name';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.CHECKLIST_QUESTIONNAIRE && key === 'title') {
        return `${log.context?.checklist?.template?.title} / Question title`;
    }

    if (namespace === CONTRACT_ASSOCIATIONS.SUBSCRIBER) {
        return 'Who to notify';
    }

    if (namespace === CONTRACT_ASSOCIATIONS.REVIEW) {
        return `Vendor Review / ${formattedKey}`;
    }

    if (namespace === CONTRACT_ASSOCIATIONS.BUDGET) {
        return formattedKey;
    }

    return `${getContextualInformation({ namespace, log })} / ${formattedKey}`;
};
