import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { ProposalMenuDropdown } from '../../../proposals';
import { UserProfilePicture } from '../../../UserProfilePicture/UserProfilePicture';
import { timeClass, isUpcomingDate } from '../../../helpers/listUtils';
import { statusClass, statusIcon } from '../../../helpers/statusHelper';
import { ReverseAuctionWell } from '../../../proposals/ProposalDetails/components';
import { onKeyDown } from '../../../../helpers/accessibilityHelper';

const { DRAFT, PUBLISHED } = proposalStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        isEditor: !!props.isSystemAdmin || !!props.isOwner,
    };
};

// @connect
class ConnectedProposalsListItem extends Component {
    static propTypes = {
        created_at: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isSystemAdmin: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
        isDisqualified: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        project: PropTypes.shape({
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    timezone: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            isPrivate: PropTypes.bool.isRequired,
            proposalDeadline: PropTypes.string,
            releaseProjectDate: PropTypes.string,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }),
            title: PropTypes.string.isRequired,
        }).isRequired,
        proposal: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired,
        status: PropTypes.string.isRequired,
        submittedAt: PropTypes.string,
        user: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./ProposalsListItem.scss');
    }

    get cleansedPathname() {
        return this.props.pathname.replace(/\/$/, '');
    }

    get statusDisplayName() {
        const { status } = this.props;

        if (status === PUBLISHED) {
            return 'submitted';
        }
        return status;
    }

    routeClick = () => {
        const { id, status, isOwner, router } = this.props;

        // Route isOwners of draft project to writing flow
        if (status === DRAFT && isOwner) {
            return router.push(`${this.cleansedPathname}/${id}/edit`);
        }
        // Route all others to review flow
        router.push(`${this.cleansedPathname}/${id}`);
    };

    menuActionHandler = (type) => {
        return this.props.menuActionHandler(type, this.props);
    };

    renderDateIcon(date) {
        const hasUpcomingDate = isUpcomingDate(date);
        if (!hasUpcomingDate) return null;

        return <i className={`fa fa-exclamation-triangle ${this.styles.dateIcon}`} />;
    }

    renderProjectDates() {
        const {
            project: {
                government: {
                    organization: { timezone },
                },
                proposalDeadline,
                releaseProjectDate,
                template: { isReverseAuction },
            },
        } = this.props;

        const dates = [];
        if (releaseProjectDate) {
            dates.push(
                <Box className={timeClass(releaseProjectDate)} component="dl" key="1" mb={0}>
                    <dt>{this.renderDateIcon(releaseProjectDate)}Release Date:</dt>
                    <dd>{moment.tz(releaseProjectDate, timezone).format('ll')}</dd>
                </Box>
            );
        }
        if (proposalDeadline) {
            dates.push(
                <Box className={timeClass(proposalDeadline)} component="dl" key="2" mb={0}>
                    <dt>
                        {this.renderDateIcon(proposalDeadline)}
                        {isReverseAuction ? 'Initial Bid Due' : 'Responses Due'}:
                    </dt>
                    <dd>
                        {moment.tz(proposalDeadline, timezone).format('ll')}{' '}
                        {moment.tz(proposalDeadline, timezone).format('h:mma')}
                    </dd>
                </Box>
            );
        }
        return dates;
    }

    render() {
        const {
            created_at: createdAt,
            isDisqualified,
            isEditor,
            isOwner,
            project,
            project: {
                government: { organization },
                isPrivate,
                template: { isReverseAuction },
            },
            proposal,
            status,
            submittedAt,
            user,
        } = this.props;

        const styles = this.styles;
        const submissionDate = moment(submittedAt);

        const projectTitle = project.title || 'Untitled';

        return (
            <div className={styles.container}>
                <div
                    className={`row ${styles.proposalListRow}`}
                    onClick={this.routeClick}
                    onKeyDown={(event) => onKeyDown(event, this.routeClick)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="text-center col-xs-12 col-sm-3 col-md-2">
                        <span className={`fa-stack fa-2x ${styles[statusClass(status)]}`}>
                            <i className="fa fa-stack-2x fa-square" />
                            <i className={`fa fa-stack-1x fa-inverse fa-${statusIcon(status)}`} />
                        </span>
                        <div className={`text-muted ${styles.statusText}`}>
                            {upperCase(this.statusDisplayName)}
                        </div>
                        {submittedAt && (
                            <div className="text-muted">
                                <div>{submissionDate.format('ll')}</div>
                                <div>{submissionDate.format('LT')}</div>
                            </div>
                        )}
                    </div>
                    <div className={`col-xs-12 col-sm-9 col-md-10 ${styles.detail}`}>
                        <div className={`row ${styles.titleRow}`}>
                            <div className="col-xs-12 col-sm-10">
                                <h2 className={styles.title}>{projectTitle}</h2>
                            </div>
                        </div>
                        {isReverseAuction && (
                            <ReverseAuctionWell project={project} proposal={proposal} />
                        )}
                        <div className="row">
                            <div className="col-xs-12 col-md-6 col-lg-7">
                                <div className={styles.gov}>
                                    <i className="fa fa-fw fa-institution" /> {organization.name}
                                </div>
                                <div className={styles.tags}>
                                    <strong>Status:</strong>
                                    &nbsp;
                                    <i className={`fa fa-${statusIcon(project.status)}`} />{' '}
                                    {upperCase(project.status)}
                                </div>
                                {isPrivate && (
                                    <div className={styles.tags}>
                                        <i className="fa fa-lock" /> Private Bid
                                    </div>
                                )}
                                <div className={styles.tags}>
                                    <strong className="text-info">
                                        <UserProfilePicture horizontal user={user} />{' '}
                                        {user.displayName}
                                    </strong>
                                    &nbsp;
                                    <em>Created {moment(createdAt).format('lll')}</em>
                                </div>
                                {isDisqualified && (
                                    <div className={`text-danger ${styles.tags}`}>
                                        <i className="fa fa-ban" /> Response Disqualified
                                    </div>
                                )}
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-5">
                                <div className={styles.dates}>{this.renderProjectDates()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.menuDropdown}`}>
                    <ProposalMenuDropdown
                        actionHandler={this.menuActionHandler}
                        className={styles.menuDropdownBtn}
                        icon="ellipsis-h"
                        isEditor={isEditor}
                        isOwner={isOwner}
                        noCaret
                        projectTitle={projectTitle}
                        status={status}
                    />
                </div>
            </div>
        );
    }
}

export const ProposalsListItem = withRouter(connect(mapStateToProps)(ConnectedProposalsListItem));
