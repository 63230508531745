import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, IconButton, Typography } from '@og-pro/ui';
import {
    Close as CloseIcon,
    Delete as DeleteIcon,
    FileUpload as FileUploadIcon,
} from '@mui/icons-material';

import { CapitalIcons } from '@opengov/react-capital-assets';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { change } from 'redux-form';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { SvgIcon } from '@mui/material';

import { DynamsoftDocumentScanner, ProgressBar } from '../../../../../../components';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { uploadAttachment } from '../../../../../../actions/requisitions';
import { qaTagPageName } from '../../../constants';
import { UploadDropzone } from './UploadDropzone';
import { fieldNames } from '../../constants';
import { formConfig } from '../../form';

const { Table, FilePdfBox, FileOutline, FileDocumentOutline, ImageOutline } = CapitalIcons;

const { ATTACHMENTS } = fieldNames;

const getFileIcon = (extension) => {
    let IconComponent;

    switch (extension.toLowerCase()) {
        case 'pdf':
            IconComponent = FilePdfBox;
            break;
        case 'bmp':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            IconComponent = ImageOutline;
            break;
        case 'doc':
        case 'docx':
            IconComponent = FileDocumentOutline;
            break;
        case 'xls':
        case 'xlsx':
            IconComponent = Table;
            break;
        default:
            IconComponent = FileOutline;
            break;
    }

    return (
        <SvgIcon
            aria-label={`${extension.toUpperCase()} file`}
            component={IconComponent}
            fontSize="inherit"
            inheritViewBox
        />
    );
};

export const RequisitionAttachment = ({
    disabled,
    fieldNamePrefix,
    title,
    canRemove,
    removeFn,
    renderAsDropPanel,
    maxFileSize,
}) => {
    const styles = require('./index.scss');

    const fieldName = fieldNamePrefix ? `${fieldNamePrefix}.${ATTACHMENTS}` : ATTACHMENTS;

    const [fileToUpload, setFileToUpload] = useState();
    const [uploadError, setUploadError] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const enableScanning = useFlags(FLAGS.ATTACHMENTS_SCANNING);
    const labelId = 'Upload Attachment Label';
    const { vendorAssignmentUuid } = useSelector(
        (state) => requisitionsCreateFormValueSelector(state, fieldName) || {}
    );
    const requisitionId = useSelector((state) => requisitionsCreateFormValueSelector(state, 'id'));
    const attachments = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldName)
    );

    const handleUpload = (files) => {
        const file = files[0];
        setFileToUpload(file);
        setUploadProgress(0);
        setUploading(true);
        setUploadError(null);
        dispatch(
            uploadAttachment(
                file,
                {
                    title: file.name,
                    requisitionId,
                    vendorAssignmentUuid,
                    fieldName,
                    attachments,
                },
                setUploadProgress,
                () => {
                    setUploadProgress(0);
                    setFileToUpload(null);
                    setUploading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (uploadProgress === 100) {
            setFileToUpload(null);
            setTimeout(() => {
                setUploading(false);
            }, 500);
        }
    }, [uploadProgress]);

    const [openedScanDialog, setOpenedScanDialog] = useState(false);

    const handleDelete = (attachment) => {
        const updatedAttachments = attachments.filter(
            (eachAttachment) => attachment.path !== eachAttachment.path
        );
        dispatch(change(formConfig.form, fieldName, updatedAttachments));
    };
    return (
        <Box
            className={classnames(styles.uploadBox, {
                [styles.disabled]: disabled,
            })}
            sx={{
                pl: 0,
                ...(disabled && {
                    '&::before, &::after': { display: 'none !important' },
                    '& hr': { display: 'none !important' },
                    '& > div + div': { borderTop: 'none !important' },
                }),
            }}
        >
            {disabled ? (
                <div className={styles.uploadLabel}>{title}</div>
            ) : (
                <div className={styles.uploadLabel}>
                    {title}&nbsp;
                    {canRemove && (
                        <IconButton
                            aria-label="Remove Attachment"
                            onClick={removeFn}
                            qaTag={`requisitionCreate-${fieldName}-remove`}
                            size="small"
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </div>
            )}
            {!disabled && (
                <Box
                    className={
                        renderAsDropPanel ? styles.dropPanelContainer : styles.buttonContainer
                    }
                >
                    <Dialog
                        dialogTitle="Scan Documents"
                        fullWidth
                        maxWidth="xl"
                        onClose={() => setOpenedScanDialog(false)}
                        open={openedScanDialog}
                    >
                        <DynamsoftDocumentScanner
                            onClose={() => setOpenedScanDialog(false)}
                            onUpload={(files) => {
                                setOpenedScanDialog(false);
                                handleUpload(files);
                            }}
                        />
                    </Dialog>
                    {!uploading && (
                        <UploadDropzone
                            dropHandler={handleUpload}
                            labelId={labelId}
                            maxFileSize={maxFileSize}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: renderAsDropPanel ? 'center' : 'flex-start',
                                    p: renderAsDropPanel ? 1 : 0,
                                    ...(renderAsDropPanel
                                        ? {}
                                        : { flexDirection: 'column', alignItems: 'flex-start' }),
                                }}
                            >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        ...(disabled && {
                                            color: `${capitalDesignTokens.semanticColors.foreground.secondary} !important`,
                                            '& .MuiButton-root': {
                                                color: `${capitalDesignTokens.semanticColors.foreground.secondary} !important`,
                                                background: 'none !important',
                                                border: 'none !important',
                                            },
                                        }),
                                    }}
                                >
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: renderAsDropPanel
                                                ? 'center'
                                                : 'flex-start',
                                        }}
                                    >
                                        {renderAsDropPanel && (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    gap: 0.5,
                                                }}
                                            >
                                                {' '}
                                                <FileUploadIcon fontSize="inherit" />
                                                <Typography>
                                                    Drag a file here{enableScanning ? ', ' : ' or '}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Button
                                            className={
                                                renderAsDropPanel ? styles.uploadButtonAsLink : ''
                                            }
                                            color="primary"
                                            disabled={disabled}
                                            onClick={(e) => e.preventDefault()}
                                            qaTag={`${qaTagPageName}-upload`}
                                            variant={renderAsDropPanel ? 'text' : 'outlined'}
                                        >
                                            {renderAsDropPanel
                                                ? 'select a file to upload'
                                                : 'Upload'}
                                        </Button>
                                    </Box>
                                    {enableScanning && (
                                        <Box
                                            sx={{
                                                alignItems: 'baseline',
                                                display: 'flex',
                                                ...(renderAsDropPanel
                                                    ? {}
                                                    : {
                                                          justifyContent: 'flex-start',
                                                          alignSelf: 'flex-start',
                                                      }),
                                            }}
                                        >
                                            <Typography sx={{ ml: renderAsDropPanel ? 0 : 1 }}>
                                                {renderAsDropPanel && ','} or{' '}
                                            </Typography>
                                            <Button
                                                className={
                                                    renderAsDropPanel
                                                        ? styles.uploadButtonAsLink
                                                        : styles.scanLinkButton
                                                }
                                                disabled={disabled}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setOpenedScanDialog(true);
                                                }}
                                                qaTag={`${qaTagPageName}-scan`}
                                                variant="text"
                                            >
                                                scan documents
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </UploadDropzone>
                    )}
                </Box>
            )}
            {uploading && (
                <Box className={styles.dropPanelContainer}>
                    <Box sx={{ p: 1 }}>
                        <Box>Uploading {fileToUpload?.name}</Box>
                        <Box sx={{ width: '100%' }}>
                            <ProgressBar
                                className={styles.customProgressBar}
                                now={uploadProgress}
                                useOpenGovStyle
                            />
                        </Box>
                    </Box>
                </Box>
            )}
            {uploadError && <div className="col-xs-12 text-center text-danger">{uploadError}</div>}
            {attachments && attachments.length > 0 && (
                <div
                    className={classnames('col-xs-12', styles.attachmentList, {
                        [styles.disabled]: disabled,
                    })}
                >
                    {renderAsDropPanel && !disabled && <label>Documents</label>}
                    {attachments.map((attachment) => {
                        const fileExtension = attachment.filename.split('.').pop();
                        return (
                            <div className={styles.attachmentItem} key={attachment.url}>
                                <a href={attachment.url} rel="noopener noreferrer" target="_blank">
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                    >
                                        {getFileIcon(fileExtension)}
                                        {attachment.title || attachment.filename}
                                    </Typography>
                                </a>
                                {!disabled && (
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDelete(attachment)}
                                        qaTag={`${qaTagPageName}-deleteAttachment`}
                                        size="small"
                                    >
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </Box>
    );
};

RequisitionAttachment.defaultProps = {
    disabled: false,
    title: 'Attachments',
    canRemove: false,
    renderAsDropPanel: false,
    removeFn: () => {},
};

RequisitionAttachment.propTypes = {
    disabled: PropTypes.bool,
    fieldNamePrefix: PropTypes.string,
    title: PropTypes.string,
    canRemove: PropTypes.bool,
    removeFn: PropTypes.func,
    renderAsDropPanel: PropTypes.bool,
    maxFileSize: PropTypes.number,
};
