import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Field, reduxForm } from 'redux-form';
import { createSearchParams } from 'react-router-dom';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    governmentSubmissionInformationMessage,
    governmentValidFormMessage,
    invalidFormErrorMessage,
    invalidFormHelpMessage,
    optionalQuestionnaireBlankMessage,
    projectUnsubmittableMessage,
    proposalDeadlinePassedMessage,
    vendorSubmissionInformationMessage,
    vendorValidFormMessage,
    zeroAmountsInPriceTableMessage,
} from './constants';
import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { Button } from '../../../Button';
import { PRICING, QUESTIONNAIRE, SUBMIT } from '../../../proposals/ProposalCreateNav/constants';
import { hasTimePassed } from '../../../../helpers';

const { OPEN } = projectStatusesDict;

// @reduxForm
class ConnectedProposalSubmit extends Component {
    static propTypes = {
        allowSubmissionAfterDeadline: PropTypes.bool,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        form: PropTypes.string.isRequired, // Used by @reduxForm above
        handleSubmit: PropTypes.func.isRequired, // From @reduxForm, calls `onSubmit`
        invalid: PropTypes.bool.isRequired, // From @reduxForm
        isClientLoaded: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        onSave: PropTypes.func.isRequired,
        project: PropTypes.shape({
            proposalDeadline: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
        router: PropTypes.object.isRequired,
        sections: PropTypes.array.isRequired,
        skipCompanyProfile: PropTypes.bool,
        showFormValidationHandler: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        warning: PropTypes.object, // From @reduxForm
    };

    componentDidMount() {
        // Touch all form fields so they will display error messages
        this.props.showFormValidationHandler();
    }

    get styles() {
        return require('./index.scss');
    }

    get submissionInformationMessage() {
        return this.props.skipCompanyProfile
            ? governmentSubmissionInformationMessage
            : vendorSubmissionInformationMessage;
    }

    get validFormMessage() {
        return this.props.skipCompanyProfile ? governmentValidFormMessage : vendorValidFormMessage;
    }

    renderSectionStatus = ({ meta, section }) => {
        const {
            location: { pathname },
            router,
        } = this.props;
        const { valid, warning } = meta;
        const listStyle = valid ? 'success' : undefined;

        return (
            <ListGroupItem
                bsStyle={listStyle}
                onClick={() =>
                    router.push({
                        pathname,
                        search: createSearchParams({ section: section.query }).toString(),
                    })
                }
            >
                <div className={this.styles.sectionTitle}>
                    {section.title}
                    {(!valid || warning) && (
                        <i
                            className={`fa fa-exclamation-${!valid ? 'triangle' : 'circle'} ${
                                this.styles.warningIcon
                            }`}
                            style={{ color: !valid ? '#A94442' : '#F0AD4E' }}
                        />
                    )}
                </div>
                <span className={`fa-stack ${this.styles.checkContainer}`}>
                    <i
                        className={`fa fa-circle fa-stack-2x ${
                            valid ? '' : this.styles.iconCircle
                        }`}
                    />
                    <i className="fa fa-check fa-stack-1x fa-inverse" />
                </span>
            </ListGroupItem>
        );
    };

    renderSections() {
        const { sections } = this.props;

        return sections
            .filter((section) => section.query !== SUBMIT)
            .map((section) => (
                <Field
                    component={this.renderSectionStatus}
                    key={section.query}
                    name={`sections.${section.query}`}
                    section={section}
                />
            ));
    }

    render() {
        const {
            allowSubmissionAfterDeadline,
            dirty,
            disabled,
            handleSubmit,
            invalid,
            isClientLoaded,
            onSave,
            project: { proposalDeadline, status },
            sections,
            updateError,
            updating,
            warning,
        } = this.props;

        // Validation doesn't properly load on the server
        if (!isClientLoaded) {
            return null;
        }

        const { error, help, submitButton, warningMsg } = this.styles;

        const hasDeadlinePassed = !allowSubmissionAfterDeadline && hasTimePassed(proposalDeadline);
        const unsubmittableProjectState = !allowSubmissionAfterDeadline && status !== OPEN;
        const hasPricingWarning = get(warning, ['sections', PRICING]);
        const hasQuestionnaireWarning = get(warning, ['sections', QUESTIONNAIRE]);

        let helpMessage;
        let errorMessage;
        if (updateError) {
            errorMessage = updateError;
        } else if (hasDeadlinePassed) {
            helpMessage = proposalDeadlinePassedMessage;
        } else if (unsubmittableProjectState) {
            helpMessage = projectUnsubmittableMessage;
        } else if (invalid) {
            errorMessage = invalidFormErrorMessage;
            helpMessage = invalidFormHelpMessage;
        } else {
            helpMessage = this.validFormMessage;
        }

        const icon = updating ? 'spinner fa-spin' : 'send';

        return (
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-12 text-center">
                    <p className="text-muted">{this.submissionInformationMessage}</p>
                    <ListGroup className={this.styles.sectionsStatus}>
                        {this.renderSections()}
                    </ListGroup>
                    <div className={submitButton}>
                        <Button
                            bsSize="lg"
                            bsStyle="success"
                            disabled={
                                disabled ||
                                invalid ||
                                hasDeadlinePassed ||
                                unsubmittableProjectState
                            }
                            type="submit"
                        >
                            <i className={`fa fa-fw fa-${icon}`} /> Submit Response
                        </Button>
                    </div>
                    {errorMessage && (
                        <p className={error}>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;
                            {errorMessage}
                        </p>
                    )}
                    <p className={help}>{helpMessage}</p>
                    {(hasPricingWarning || hasQuestionnaireWarning) && (
                        <p className={warningMsg}>
                            <i className="fa fa-exclamation-circle" style={{ color: '#F0AD4E' }} />
                            &nbsp;
                            <strong>Warning</strong>
                            {hasQuestionnaireWarning ? optionalQuestionnaireBlankMessage : null}
                            {hasPricingWarning ? zeroAmountsInPriceTableMessage : null}
                        </p>
                    )}
                    <ProposalSectionButtons
                        disabled={disabled}
                        hideSaveButton
                        isFormDirty={dirty}
                        onSave={onSave}
                        section={SUBMIT}
                        sections={sections}
                    />
                </div>
            </form>
        );
    }
}

export const ProposalSubmit = compose(reduxForm(formConfig), withRouter)(ConnectedProposalSubmit);
