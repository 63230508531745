import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { projectDeliveryMethods, projectTypesDict } from '@og-pro/shared-config/projects';
import { Alert, AlertTitle, Typography, Box } from '@og-pro/ui';

import { LoadingSpinner } from '../../../components';
import { DocumentViewer } from '../../../components/GovApp';
import { formatS3SignedURLForIframePreview } from '../../../helpers';

export class ConnectedProjectExportModalBody extends PureComponent {
    static propTypes = {
        compileDocument: PropTypes.func.isRequired,
        error: PropTypes.string,
        errorsDetail: PropTypes.arrayOf(PropTypes.string),
        exportProject: PropTypes.func.isRequired,
        exportProjectDocument: PropTypes.func.isRequired,
        filename: PropTypes.string,
        getFlag: PropTypes.func.isRequired,
        googleUrl: PropTypes.string,
        isCompilePreview: PropTypes.bool,
        isPdfPreview: PropTypes.bool,
        isPreview: PropTypes.bool,
        loaded: PropTypes.bool,
        loadedDocument: PropTypes.bool,
        loading: PropTypes.bool,
        loadingDocument: PropTypes.bool,
        pdfUrl: PropTypes.string,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
        }).isRequired,
        setExportData: PropTypes.func.isRequired,
        wordUrl: PropTypes.string,
        zipUrl: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPdf: false,
        };
    }

    componentDidMount() {
        const {
            compileDocument,
            exportProjectDocument,
            isCompilePreview,
            isPreview,
            loading,
            loadingDocument,
            project,
        } = this.props;

        if (loading || loadingDocument) {
            return;
        }

        if (isPreview) {
            exportProjectDocument();
        }

        if (isCompilePreview) {
            compileDocument(project.id, false, projectDeliveryMethods.URL);
        }
    }

    get styles() {
        return require('./ProjectExportModalBody.scss');
    }

    exportDocument = ({ isPdf } = {}) => {
        const { exportProjectDocument, setExportData } = this.props;
        setExportData({ isPdf });
        exportProjectDocument();
    };

    exportContractPackage = () => {
        const { compileDocument, project } = this.props;
        compileDocument(project.id, false, projectDeliveryMethods.URL);
    };

    selectPdf = () => {
        this.setState({ isPdf: true });
        this.exportDocument({ isPdf: true });
    };

    renderIntro() {
        const {
            getFlag,
            exportProject,
            project: { type },
        } = this.props;

        const useExportButtonV2 = getFlag(FLAGS.EXPORT_BUTTON_V2);

        return (
            <>
                <div className={`row ${this.styles.introRow}`}>
                    <div className="col-xs-6">
                        <span className="pseudoLink" onClick={this.exportDocument}>
                            <i className={`fa fa-file-word-o fa-4x ${this.styles.introFile}`} />
                            <div className={this.styles.downloadText}>Full Document (Word)</div>
                        </span>
                    </div>
                    <div className="col-xs-6">
                        <span className="pseudoLink" onClick={this.selectPdf}>
                            <i className={`fa fa-file-pdf-o fa-4x ${this.styles.introFile}`} />
                            <div className={this.styles.downloadText}>Full Document (PDF)</div>
                        </span>
                    </div>
                </div>
                <div className="row">
                    {type === projectTypesDict.CONTRACT && useExportButtonV2 ? (
                        <div className="col-xs-6">
                            <span className="pseudoLink" onClick={this.exportContractPackage}>
                                <i className={`fa fa-file-pdf-o fa-4x ${this.styles.introFile}`} />
                                <div className={this.styles.downloadText}>
                                    Contract Packet (PDF)
                                </div>
                            </span>
                        </div>
                    ) : (
                        <div className="col-xs-6">
                            <span className="pseudoLink" onClick={exportProject}>
                                <i className={`fa fa-folder-open fa-4x ${this.styles.introFile}`} />
                                <div className={this.styles.downloadText}>
                                    Full Packet With Attachments
                                </div>
                                <div className={this.styles.disclaimer}>
                                    *May take a few moments to download
                                </div>
                            </span>
                        </div>
                    )}
                </div>
            </>
        );
    }

    renderPreview() {
        const { getFlag, googleUrl, pdfUrl, isPdfPreview } = this.props;

        return (
            <div>
                {getFlag(FLAGS.EXPORT_BUTTON_V2) && isPdfPreview ? (
                    <DocumentViewer url={pdfUrl} />
                ) : (
                    <iframe
                        className={this.styles.preview}
                        frameBorder="0"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${formatS3SignedURLForIframePreview(
                            googleUrl
                        )}`}
                        title="Document Preview Embed"
                    />
                )}
            </div>
        );
    }

    renderWordDocument() {
        const { filename, wordUrl } = this.props;

        return (
            <div>
                <a download={filename} href={wordUrl}>
                    <i className="fa fa-file-word-o fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download Word Document</div>
                </a>
            </div>
        );
    }

    renderPackage() {
        const { zipUrl } = this.props;

        return (
            <div>
                <a href={zipUrl}>
                    <i className="fa fa-folder-open fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download Packet</div>
                </a>
            </div>
        );
    }

    renderPdf() {
        const { filename, pdfUrl } = this.props;

        return (
            <div>
                <a download={filename} href={pdfUrl}>
                    <i className="fa fa-file-pdf-o fa-5x" />
                    <div className={this.styles.postDownload}>Click To Download PDF Document</div>
                </a>
            </div>
        );
    }

    renderContent() {
        const { loaded, loadedDocument, pdfUrl } = this.props;

        if (loadedDocument) {
            if (pdfUrl) {
                return this.renderPdf();
            }
            return this.renderWordDocument();
        }

        if (loaded) {
            return this.renderPackage();
        }

        return this.renderIntro();
    }

    render() {
        const {
            error,
            errorsDetail,
            googleUrl,
            isCompilePreview,
            isPreview,
            loading,
            loadingDocument,
            pdfUrl,
        } = this.props;

        const { isPdf } = this.state;

        if (error) {
            return (
                <Box sx={{ my: 2 }}>
                    <Alert severity="error" variant="filled">
                        <AlertTitle>Document generation failed</AlertTitle>
                        <Typography>{error}</Typography>
                        {errorsDetail?.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                                <Typography>
                                    The following errors occurred during document generation:
                                </Typography>
                                {errorsDetail.map((errorDetail, i) => (
                                    <Typography key={i} sx={{ pl: 2 }} variant="body2">
                                        - {errorDetail}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Alert>
                </Box>
            );
        }

        if (loading || loadingDocument || (isPreview && !googleUrl && !pdfUrl)) {
            return (
                <Box sx={{ my: 2 }}>
                    <LoadingSpinner
                        noPadding
                        text={loading || isPdf ? 'This may take a few moments...' : undefined}
                        useOpenGovStyle
                    />
                </Box>
            );
        }

        if (isPreview || isCompilePreview) {
            return this.renderPreview();
        }

        return <div className={this.styles.exportBody}>{this.renderContent()}</div>;
    }
}

export const ProjectExportModalBody = compose(withFlags())(ConnectedProjectExportModalBody);
