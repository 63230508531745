import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';

import { projectFormProps, fieldNames } from '../constants';
import { GuidedSectionButton } from '../components';
import {
    createQuestionnaire,
    showQuestionnaireCreateModal,
} from '../../../../actions/questionnaire';
import { FormError, HelpToolTip, Main } from '../../../../components';
import { QuestionnairesForm } from '../../../../components/forms';
import { CDSButton, CDSButtonGroup } from '../../../../components/SDv2';
import { ListError } from '../../../../components/GovApp';
import { VendorQuestionnaireRenderLogicIcon } from '../../ConditionalLogicIcon/VendorQuestionnaireRenderLogicIcon';
import { getShowConditionalLogicModal } from '../../ConditionalLogicModal/selectors';
import { ConditionalLogicModal } from '../../ConditionalLogicModal';
import { getQuestionnaires } from '../selectors';
import {
    hasSourcingSubscription,
    questionnaireConditionalEnabled as getQuestionnaireConditionalEnabled,
} from '../../selectors';

const { QUESTIONNAIRES } = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        disabled: props.projectSubmitting || props.updating,
        formErrors: getFormSyncErrors(props.form)(state),
        hasSourcing: hasSourcingSubscription(state),
        shouldShowConditionalLogicModal: getShowConditionalLogicModal(state),
        questionnaires: getQuestionnaires(state),
        questionnaireConditionalEnabled: getQuestionnaireConditionalEnabled(state),
    };
};

const mapDispatchToProps = {
    showCreateModal: showQuestionnaireCreateModal,
    createQuestionnaire,
};

// @connect
class ConnectedQuestionnaireBuilder extends Component {
    static propTypes = {
        ...projectFormProps,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        formErrors: PropTypes.object.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        questionnaires: PropTypes.array.isRequired,
        showCreateModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { viewedInstructions: false };
    }

    componentDidMount() {
        const { questionnaires, markBuilderSectionVisited } = this.props;

        if (questionnaires.length > 0) {
            markBuilderSectionVisited({
                hasIncludedItems: true,
                onClose: this.hideHelpModal,
            });
        }
    }

    formKey = QUESTIONNAIRES;

    get styles() {
        return require('./index.scss');
    }

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal({
            onClose: this.hideHelpModal,
        });
    };

    hideHelpModal = () => {
        const { markBuilderSectionVisited, questionnaires, showCreateModal } = this.props;
        const { viewedInstructions } = this.state;

        this.setState({ viewedInstructions: true });

        // Prompt to add questionnaire if the initial showing of the help modal
        if (!viewedInstructions && questionnaires.length === 0) {
            markBuilderSectionVisited();
            showCreateModal();
        }
    };

    createQuestionnaire = async (data) => {
        return this.props.createQuestionnaire(this.props.project.id, data);
    };

    addConditionalLogicHandler = (questionLogic) => {
        const { array, initiateSneakyUpdate } = this.props;

        array.push('questionLogics', questionLogic);

        setTimeout(() => initiateSneakyUpdate());
    };

    getFormErrors = () => {
        const { formErrors } = this.props;

        // `isArray` check needed as `formErrors.questionnaires` can be both an
        // object with a single `_error` key or an array
        const hasBidBondError =
            Array.isArray(formErrors && formErrors.questionnaires) &&
            formErrors.questionnaires.some(
                (questionnaire) =>
                    questionnaire.data &&
                    (questionnaire.data.bidBondProjectId ||
                        questionnaire.data.bidBondSecurity ||
                        questionnaire.data.bidBondSecurityIsPercentage)
            );

        if (hasBidBondError) {
            return 'Please fill in missing questionnaire data';
        }

        return null;
    };

    renderInitialQuestionnaire() {
        const { disabled, isOGThemeEnabledForComponents } = this.props;

        if (isOGThemeEnabledForComponents) {
            return (
                <CDSButtonGroup>
                    <CDSButton
                        disabled={disabled}
                        onClick={this.showHelpModal}
                        qaTag="projectEditor-startQuestionnaire"
                        variant="secondary"
                    >
                        Start Vendor Questionnaire
                    </CDSButton>
                </CDSButtonGroup>
            );
        }

        return (
            <GuidedSectionButton
                className={this.styles.initButton}
                disabled={disabled}
                onClick={this.showHelpModal}
                text="Start Vendor Questionnaire"
                type="required"
            />
        );
    }

    renderQuestionnaire() {
        const {
            array,
            change,
            disabled,
            formErrors,
            hasSourcing,
            questionnaires,
            questionnaireConditionalEnabled,
            showFormErrors,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const tooltipComponent = (
            <HelpToolTip onClick={this.showHelpModal} text="Click to see instructions" />
        );

        return (
            <QuestionnairesForm
                array={array}
                change={change}
                createBeforeAddingHandler={this.createQuestionnaire}
                disabled={disabled}
                formErrors={formErrors}
                formKey={this.formKey}
                includeContainsPricingOption={hasSourcing}
                questionnaires={questionnaires}
                renderQuestionLogicIcon={VendorQuestionnaireRenderLogicIcon(
                    this.addConditionalLogicHandler,
                    questionnaires,
                    questionnaireConditionalEnabled
                )}
                showFormErrors={showFormErrors}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                tooltipComponent={tooltipComponent}
                useRawPrompt
            />
        );
    }

    render() {
        const {
            questionnaires,
            shouldShowConditionalLogicModal,
            showFormErrors,
            updateError,
            isOGThemeEnabledForComponents,
        } = this.props;

        const showQuestionnaireList =
            questionnaires.length > 0 ||
            this.state.viewedInstructions ||
            isOGThemeEnabledForComponents;

        return (
            <Main className={this.styles.container}>
                <FormError
                    error={updateError || this.getFormErrors()}
                    useOpenGovStyle={isOGThemeEnabledForComponents}
                />
                <FieldArray
                    component={ListError}
                    displayError={
                        'Please include at least one Vendor Question. ' +
                        'Please click the button below to begin.'
                    }
                    name={this.formKey}
                    showError={!!showFormErrors}
                    useOpenGovStyle={isOGThemeEnabledForComponents}
                />
                {showQuestionnaireList
                    ? this.renderQuestionnaire()
                    : this.renderInitialQuestionnaire()}
                {shouldShowConditionalLogicModal && <ConditionalLogicModal />}
            </Main>
        );
    }
}

export const QuestionnaireBuilder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedQuestionnaireBuilder);
