const { listToDict } = require('../helpers');

const SUBTYPES = listToDict(['create', 'update', 'delete']);
const CONTRACT_ASSOCIATIONS = listToDict([
    'contact',
    'insurance',
    'subscriber',
    'notification',
    'milestone',
    'purchaseOrder',
    'attachment',
    'checklist',
    'checklist.questionnaire',
    'review',
    'review.complaint',
    'renewal',
    'budget',
    'budgetAllocation',
]);

module.exports = { CONTRACT_ASSOCIATIONS, SUBTYPES };
