import React, { useContext } from 'react';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import {
    SDv2EditorLayout,
    SectionHeaderViewTitleOnly,
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavNextButton,
    NavScrollContainer,
} from '../../../../../../components/SDv2';
import { ProjectCreateV2ProjectPropertiesNav } from '../../components';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../../context';
import { getProjectPropertiesNav } from '../../helpers';
import { PROJECT_PROPERTIES_NAV, PROJECT_PROPERTIES_NAV_CONTRACTS } from '../../constants';
import {
    PostingOptions,
    ProjectInformation,
    SummaryAndBackground,
    Timeline,
} from '../../components/forms';
import { SDv2UpfrontQuestionsFormAdapter } from './SDv2UpfrontQuestionsFormAdapter';

export const ProjectCreateV2ProjectProperties = () => {
    const { buildRouteFunction, initiateSneakyUpdate, project } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const sections = getProjectPropertiesNav({
        project,
        setActiveSectionId,
        activeSectionId,
        initiateSneakyUpdate,
    });

    // if there is an error on the page, scroll to it
    const element = document.getElementsByClassName('has-error')[0]?.parentElement;
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const projectPropertiesNav =
        project?.type === projectTypesDict.CONTRACT
            ? PROJECT_PROPERTIES_NAV_CONTRACTS
            : PROJECT_PROPERTIES_NAV;

    return (
        <SDv2EditorLayout
            content={
                <div>
                    {activeSectionId === 0 && <ProjectInformation />}
                    {activeSectionId === 1 && <SDv2UpfrontQuestionsFormAdapter />}
                    {activeSectionId === 2 && <SummaryAndBackground />}
                    {activeSectionId === 3 && <Timeline />}
                    {activeSectionId === 4 && <PostingOptions />}
                </div>
            }
            footer={
                <div>
                    <BaseSectionsNavBackNextButtons
                        lastSectionComponent={
                            <BaseSectionsNavNextButton
                                onClick={() => {
                                    initiateSneakyUpdate(
                                        buildRouteFunction(
                                            project?.useExternalDocument
                                                ? 'upload-document'
                                                : 'document-editor'
                                        )
                                    );
                                }}
                            >
                                {project?.useExternalDocument
                                    ? 'Upload Document'
                                    : 'Document Editor'}
                            </BaseSectionsNavNextButton>
                        }
                        sections={sections}
                    />
                </div>
            }
            header={
                <div>
                    <SectionHeaderViewTitleOnly
                        description={projectPropertiesNav[activeSectionId]?.description}
                        title={projectPropertiesNav[activeSectionId]?.title}
                    />
                </div>
            }
            navigation={
                <NavScrollContainer>
                    <ProjectCreateV2ProjectPropertiesNav />
                </NavScrollContainer>
            }
            showSharedTextareaToolbar={activeSectionId === 2}
        />
    );
};
