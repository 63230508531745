import PropTypes from 'prop-types';
import React from 'react';

import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import { fieldNames, form } from '../../constants';
import { SectionSettings, SectionTitleFormGroup } from '../components';

const {
    SECTION_INSTRUCTIONS_CUSTOM_FORM,
    SECTION_NAME_CUSTOM_FORM,
    CREATE_NEW_CUSTOM_FORM,
    DELETE_CUSTOM_FORM,
} = fieldNames;

export const CustomFormSectionSettings = React.forwardRef(({ disabled, onClose, title }, ref) => {
    const dispatch = useDispatch();

    const handleDeleteSectionClick = () => {
        dispatch(change(form, DELETE_CUSTOM_FORM, true));
        dispatch(change(form, CREATE_NEW_CUSTOM_FORM, false));
        onClose();
    };

    return (
        <SectionSettings disabled={disabled} onClose={onClose} ref={ref} title={title}>
            <SectionTitleFormGroup
                disabled={disabled}
                handleDeleteSectionClick={handleDeleteSectionClick}
                sectionInstructionsField={SECTION_INSTRUCTIONS_CUSTOM_FORM}
                sectionNameField={SECTION_NAME_CUSTOM_FORM}
            />
        </SectionSettings>
    );
});

CustomFormSectionSettings.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
