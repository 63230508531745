import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { GovernmentSubscriptionNotificationForm } from '../../../../components/forms';

export class GovSubscriptionConfirmationModal extends PureComponent {
    static propTypes = {
        government: PropTypes.shape({
            organization: PropTypes.shape({
                logo: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        governmentSubscription: PropTypes.object,
        hideModal: PropTypes.func.isRequired,
        updateGovSubscription: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            government: {
                organization: { logo, name },
            },
            governmentSubscription,
            hideModal,
            updateGovSubscription,
        } = this.props;

        return (
            <Modal bsSize="sm" onHide={hideModal} show>
                <Modal.Body className="text-center">
                    <div className={this.styles.closeButton}>
                        <button
                            aria-label="Close"
                            className="close"
                            onClick={hideModal}
                            type="button"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        <img
                            alt={`${name} logo`}
                            className={`img-responsive img-rounded ${this.styles.logo}`}
                            src={logo}
                        />
                    </div>
                    <div className={this.styles.checkmark}>
                        <i className="fa fa-4x fa-check-circle" />
                    </div>
                    <h3 className="text-success">
                        You&#39;re Subscribed to
                        <br />
                        {name}!
                    </h3>
                    <div className={this.styles.notificationSettings}>
                        <GovernmentSubscriptionNotificationForm
                            governmentSubscription={governmentSubscription}
                            label="You will be notified when"
                            updateGovSubscription={updateGovSubscription}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
