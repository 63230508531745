import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { form, QUESTIONNAIRE } from './constants';
import { validate } from './validate';
import { QuestionnaireResponseForm as QuestionnaireResponseFormComponent } from '../../../../Questionnaire/QuestionnaireResponseForm';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedQuestionnaireResponseForm = ({ checklistId, questionnaire, showValidation }) => {
    return (
        <div className={classnames('questionnaire-item')}>
            <QuestionnaireResponseFormComponent
                {...questionnaire}
                checklistId={checklistId}
                fieldName={QUESTIONNAIRE}
                showValidation={showValidation}
            />
        </div>
    );
};

ConnectedQuestionnaireResponseForm.propTypes = {
    checklistId: PropTypes.number.isRequired,
    questionnaire: PropTypes.object.isRequired,
    showValidation: PropTypes.bool.isRequired,
};

export const QuestionnaireResponseForm = reduxForm(formConfig)(ConnectedQuestionnaireResponseForm);
