import PropTypes from 'prop-types';
import React from 'react';

import { TemplateQuestionEditModal } from './TemplateQuestionEditModal';
import { TemplateQuestionsListDisplay } from './TemplateQuestionsListDisplay';
import { TemplateListTimestampHeaders, ZeroState } from '../../../../components';

export const TemplateQuestionsList = ({
    hasNoCreatedQuestions,
    TemplateCreateButton,
    templateQuestions,
    templatesAdminPath,
    showModal,
    setTemplateQuestionId,
    isModalOpen,
    selectedQuestionId,
}) => {
    const styles = require('./index.scss');

    if (templateQuestions.length === 0) {
        const props = {
            button: hasNoCreatedQuestions ? <TemplateCreateButton bsSize="lg" /> : undefined,
            title: hasNoCreatedQuestions
                ? 'No shared questions created yet'
                : 'No shared questions here!',
        };
        return <ZeroState {...props} />;
    }

    return (
        <>
            <div className={`row ${styles.listHeader} hidden-xs hidden-sm`}>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-5">Shared Question</div>
                        <div className="col-md-3">Owner</div>
                        <div className="col-md-4">Templates In Use</div>
                    </div>
                </div>
                <div className="col-md-3">
                    <TemplateListTimestampHeaders />
                </div>
            </div>
            <TemplateQuestionsListDisplay
                onQuestionClick={(questionId) => {
                    setTemplateQuestionId(questionId);
                    showModal(true);
                }}
                templateQuestions={templateQuestions}
                templatesAdminPath={templatesAdminPath}
            />
            {isModalOpen && selectedQuestionId && (
                <TemplateQuestionEditModal
                    hideModal={() => showModal(false)}
                    templateQuestionId={selectedQuestionId}
                />
            )}
        </>
    );
};

TemplateQuestionsList.propTypes = {
    hasNoCreatedQuestions: PropTypes.bool,
    TemplateCreateButton: PropTypes.func.isRequired,
    templateQuestions: PropTypes.array.isRequired,
    templatesAdminPath: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
    setTemplateQuestionId: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    selectedQuestionId: PropTypes.number,
};
