import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { LoadingButton } from '../../LoadingButton';
import { CategorySearchInput } from '../../../containers/CategorySelect';

const { CATEGORIES } = fieldNames;

const formConfig = {
    form,
};

class ConnectedVendorListCategorySearchForm extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        clearSearch: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        error: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    render() {
        const { active, disabled, error, handleSubmit, loading } = this.props;

        return (
            <form
                onSubmit={handleSubmit}
                style={{
                    padding: '10px',
                }}
            >
                <div className="row">
                    <div className="col-sm-8 col-md-9">
                        <Field
                            component={CategorySearchInput}
                            disabled={disabled}
                            disallowCustomCategories
                            name={CATEGORIES}
                            useSingleCodeSet
                        />
                    </div>
                    <div className="col-sm-4 col-md-3">
                        <LoadingButton
                            block
                            bsStyle="primary"
                            disabled={disabled}
                            icon="fa-search"
                            loading={loading}
                            qaTag="vendorListCategorySearch-search"
                            text="Search Categories"
                            type="submit"
                        />
                        {active && (
                            <Button
                                block
                                bsSize="small"
                                bsStyle="link"
                                disabled={disabled}
                                onClick={this.props.clearSearch}
                                qaTag="vendorListCategorySearch-cancel"
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
                {error && <div className="error-block">{error}</div>}
            </form>
        );
    }
}

export const VendorListCategorySearchForm = reduxForm(formConfig)(
    ConnectedVendorListCategorySearchForm
);
