import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { findPeriodByDate } from '@og-pro/shared-config/requisitions';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    useOpenFiscalPeriodsRange,
    useOpenFiscalPeriodsWithCalendarYear,
} from './useOpenFiscalPeriods';
import { getRequisitionEndsInPurchaseOrder } from '../../selectors/govApp';
import { getFiscalYearSelectOptions } from '../../containers/App/selectors';

export function useFiscalPeriodFormDisplay(
    formName,
    fullObjectFieldName,
    nonFmsFiscalPeriodFieldName,
    initialFiscalPeriod
) {
    const enableMUIComponents = useFlags(FLAGS.ENABLE_MUI_DATE_PICKERS_IN_REQ);
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);
    const dispatch = useDispatch();
    const fiscalPeriod = useSelector((state) =>
        formValueSelector(formName)(state, fullObjectFieldName)
    );

    const {
        data: openFiscalPeriodsRange,
        error: fiscalPeriodsRangeError,
        isError: isFiscalPeriodsRangeError,
        isLoading: isLoadingFiscalPeriodsRange,
    } = useOpenFiscalPeriodsRange({ enabled: endsInPurchaseOrder });

    const {
        data: fiscalPeriods,
        error: fiscalPeriodsError,
        isError: isFiscalPeriodsError,
        isLoading: isLoadingFiscalPeriods,
    } = useOpenFiscalPeriodsWithCalendarYear({ enabled: endsInPurchaseOrder });

    const fmsFiscalPeriod = useMemo(() => {
        return (
            (fiscalPeriod && fiscalPeriod.description) ||
            (initialFiscalPeriod && initialFiscalPeriod.description) ||
            'Select an Expected Purchase Order Date first'
        );
    }, [fiscalPeriod, initialFiscalPeriod]);

    /**
     * @param {Date | string | null} dateOrDateString If using MUI components, this will be a string. Otherwise, it will be a Date object. In either case, it could be null.
     */
    const handleExpectedPurchaseOrderDateChange = (dateOrDateString) => {
        if (!dateOrDateString) {
            return;
        }

        let date;
        if (enableMUIComponents) {
            date = new Date(dateOrDateString);
        } else {
            date = dateOrDateString;
        }

        const newFiscalPeriod = findPeriodByDate(fiscalPeriods, date);
        dispatch(change(formName, fullObjectFieldName, newFiscalPeriod));
        dispatch(change(formName, nonFmsFiscalPeriodFieldName, newFiscalPeriod?.description || ''));
    };

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    return {
        error: fiscalPeriodsRangeError || fiscalPeriodsError,
        fiscalYearSelectOptions,
        endsInPurchaseOrder,
        openFiscalPeriods: fiscalPeriods?.map((period) => period.date) || [],
        openFiscalPeriodsRange,
        isError: isFiscalPeriodsRangeError || isFiscalPeriodsError,
        isLoadingFiscalPeriodsRange,
        isLoadingFiscalPeriods,
        fmsFiscalPeriod,
        handleExpectedPurchaseOrderDateChange,
    };
}
