import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';

import { ReduxFormDatePicker } from '@og-pro/ui';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { HelpBlock } from '../../HelpBlock/HelpBlock';
import { DateTimePicker } from '../../DateTime/DateTimePicker';

export const ExpectedPurchaseOrderDateField = ({
    className,
    disabled,
    readOnly,
    hasLoadingError,
    isLoading,
    onChange,
    name,
    openFiscalPeriods,
    openFiscalPeriodsRange,
    showErrorIcon,
    ...props
}) => {
    const styles = require('./index.scss');
    const isInputDisabled = isLoading || hasLoadingError;
    const enableMUIComponents = useFlags(FLAGS.ENABLE_MUI_DATE_PICKERS_IN_REQ);

    const helpText = isLoading ? 'Loading fiscal periods...' : 'Error loading fiscal periods';

    return (
        <FormGroup
            controlId={`form-group-${name}`}
            validationState={hasLoadingError ? 'error' : undefined}
        >
            {enableMUIComponents ? (
                <Field
                    className={classNames(
                        styles.datePicker,
                        isInputDisabled && styles.withHelpBlock,
                        className
                    )}
                    component={ReduxFormDatePicker}
                    disabled={isInputDisabled || disabled}
                    fullWidth
                    maxDate={openFiscalPeriodsRange?.end || undefined}
                    minDate={openFiscalPeriodsRange?.start || undefined}
                    name={name}
                    onChangeSideEffect={onChange}
                    readOnly={readOnly}
                    showValidation={!isInputDisabled}
                    validMonths={openFiscalPeriods}
                    {...props}
                />
            ) : (
                <Field
                    className={classNames(
                        styles.datePicker,
                        isInputDisabled && styles.withHelpBlock,
                        className
                    )}
                    component={DateTimePicker}
                    disabled={isInputDisabled || disabled}
                    name={name}
                    onChange={onChange}
                    props={{
                        min: openFiscalPeriodsRange && openFiscalPeriodsRange.start,
                        max: openFiscalPeriodsRange && openFiscalPeriodsRange.end,
                    }}
                    readOnly={readOnly}
                    showValidation={!isInputDisabled}
                    time={false}
                    useOpenGovStyle
                    {...props}
                />
            )}
            {isInputDisabled && (
                <HelpBlock>
                    <span className={classNames(hasLoadingError && 'text-danger')}>
                        {hasLoadingError && showErrorIcon && (
                            <>
                                <i className="fa fa-chain-broken" />
                                &nbsp;
                            </>
                        )}
                        {helpText}
                    </span>
                </HelpBlock>
            )}
        </FormGroup>
    );
};

ExpectedPurchaseOrderDateField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    hasLoadingError: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    openFiscalPeriodsRange: PropTypes.shape({
        end: PropTypes.instanceOf(Date).isRequired,
        start: PropTypes.instanceOf(Date).isRequired,
    }),
    openFiscalPeriods: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    showErrorIcon: PropTypes.bool,
};
