import moment from 'moment';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { WordTemplatesListForm } from './DocxTemplateForm/ListForm';
import { HEADING_FORMATTERS, TITLE } from './DocxTemplateForm/constants';
import { WORD_TEMPLATES_LIST_PROP_TYPES } from './types';

export const SDv2WordTemplatesList = ({
    docxTemplates,
    updating,
    uploading,
    updateDefaultDocxTemplate,
    deleteHandler,
    updateDocxTemplate,
}) => {
    const [activeKey, setActiveKey] = useState(null);
    const styles = require('./shared.scss');
    const { colors, typography } = capitalDesignTokens.foundations;

    useEffect(() => {
        setActiveKey(0);
    }, [docxTemplates.length]);

    const onSelect = (value) => {
        setActiveKey(value);
    };

    return (
        <PanelGroup
            accordion
            activeKey={activeKey}
            className={styles.panelGroup}
            id="docxList"
            onSelect={onSelect}
        >
            {docxTemplates.map((docxTemplate, index) => {
                const {
                    updated_at: updatedAt,
                    governmentDocxTemplates: { isDefault, headingFormatters },
                    id,
                    title,
                    url,
                } = docxTemplate;

                return (
                    <Panel eventKey={index} key={index} role="tab">
                        <Panel.Heading
                            className={classnames(styles.panelHeading, {
                                [styles.panelHeadingOpen]: activeKey === index,
                            })}
                            role="presentation"
                        >
                            <Panel.Title toggle>
                                <Box alignItems="center" display="flex">
                                    <Box alignItems="bottom" display="flex" flex={5}>
                                        <Box color={colors.gray800} mr={1}>
                                            {activeKey === index ? (
                                                <KeyboardArrowDownIcon />
                                            ) : (
                                                <KeyboardArrowRightIcon />
                                            )}
                                        </Box>

                                        <Typography
                                            color={colors.brand900}
                                            fontSize="medium"
                                            fontWeight={500}
                                        >
                                            {title}
                                        </Typography>
                                        {isDefault && (
                                            <Typography
                                                sx={{
                                                    backgroundColor: colors.gray800,
                                                    color: colors.white,
                                                    height: 'fit-content',
                                                    paddingY: 0.5,
                                                    paddingX: 1,
                                                    marginLeft: 2,
                                                    borderRadius: 0.5,
                                                    fontSize: typography.fontSize.h6,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                DEFAULT
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        flex={1}
                                        textAlign="right"
                                        title={moment(updatedAt).format('lll')}
                                    >
                                        <Typography variant="bodySmall">
                                            Updated {moment(updatedAt).fromNow()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className={styles.panelBody} collapsible>
                            <WordTemplatesListForm
                                actionsProps={{
                                    deleteHandler,
                                    id,
                                    isDefault,
                                    updateDefaultDocxTemplate,
                                    updating,
                                    uploading,
                                    url,
                                }}
                                docxTemplate={docxTemplate}
                                form={`docxTemplatesHeadings-${index}`}
                                initialValues={{
                                    [TITLE]: docxTemplate.title,
                                    [HEADING_FORMATTERS]: headingFormatters || {},
                                }}
                                onClose={() => setActiveKey(null)}
                                onSubmit={(values) => updateDocxTemplate(docxTemplate.id, values)}
                                showActions
                            />
                        </Panel.Body>
                    </Panel>
                );
            })}
        </PanelGroup>
    );
};

SDv2WordTemplatesList.propTypes = WORD_TEMPLATES_LIST_PROP_TYPES;
