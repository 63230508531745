import React from 'react';
import PropTypes from 'prop-types';

import { BasicSectionSettings } from './BasicSectionSettings';
import { FormSettings } from './FormSettings';
import { GeneralSectionSettings } from './GeneralSectionSettings';
import { PurchaseSectionSettings } from './PurchaseSectionSettings';
import { fieldNames, sectionNames } from '../constants';
import { CustomFormSectionSettings } from './CustomFieldsSectionSettings';
import { useSectionNumbering } from '../useSectionNumbering';
import { VendorSectionSettings } from './VendorSectionSettings';

const {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    FORM_SETTINGS,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} = sectionNames;
const {
    SECTION_INSTRUCTIONS_ADDITIONAL,
    SECTION_INSTRUCTIONS_ATTACHMENT,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
} = fieldNames;

export const SettingsStickySidebar = React.forwardRef(
    ({ disabled, onClose, section, showCustomForm }, ref) => {
        const { getSectionNumber } = useSectionNumbering(showCustomForm);
        const sharedSectionProps = {
            ref,
            disabled,
            onClose,
        };

        const title = `Section ${getSectionNumber(section)}`;

        switch (section) {
            case GENERAL_INFORMATION:
                return <GeneralSectionSettings {...sharedSectionProps} title={title} />;
            case PURCHASE_DETAILS:
                return <PurchaseSectionSettings {...sharedSectionProps} title={title} />;
            case VENDOR_SELECTION:
                return <VendorSectionSettings {...sharedSectionProps} title={title} />;
            case CUSTOM_FORM:
                return <CustomFormSectionSettings {...sharedSectionProps} title={title} />;
            case ADDITIONAL_INFORMATION:
                return (
                    <BasicSectionSettings
                        {...sharedSectionProps}
                        sectionInstructionsField={SECTION_INSTRUCTIONS_ADDITIONAL}
                        sectionNameField={SECTION_NAME_ADDITIONAL}
                        title={title}
                    />
                );
            case ATTACHMENTS:
                return (
                    <BasicSectionSettings
                        {...sharedSectionProps}
                        sectionInstructionsField={SECTION_INSTRUCTIONS_ATTACHMENT}
                        sectionNameField={SECTION_NAME_ATTACHMENT}
                        title={title}
                    />
                );
            case FORM_SETTINGS:
            default:
                return <FormSettings disabled={disabled} ref={ref} />;
        }
    }
);

SettingsStickySidebar.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    section: PropTypes.string,
    showCustomForm: PropTypes.bool,
};
