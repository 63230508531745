import { useDispatch, useSelector } from 'react-redux';
import { Event as EventIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { showProjectCreateModal } from '../../../../actions/project/create/projectCreate';
import { ReactRouterLink, usePathUrl } from './utils';
import { getUserJS } from '../../../../containers/selectors';

export const useUtilityTrayOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getUserJS);
    const hasNewInboxNotification = useSelector((state) =>
        state.inboxNotifications.get('hasNewInboxNotification')
    );

    const {
        government_id: governmentId,
        government: { hasContracting, hasDocBuilder, hasIntake, hasRequisition },
        userPermissions: { isRetentionAdmin, isSystemAdmin, isTemplateAdmin },
        avatarUrl,
    } = user;

    const { getPathUrl } = usePathUrl(governmentId);

    const createNewActionOptions = [
        {
            label: 'Create',
            handleCreate: () => {
                dispatch(showProjectCreateModal());
            },
        },
    ];

    const customUtilityOptions = {
        utilityName: 'Calendar',
        muiIcon: EventIcon,
        iconLinkComponent: ReactRouterLink,
        iconUrl: getPathUrl('calendar'),
    };

    const notificationsOptions = {
        iconLinkComponent: ReactRouterLink,
        iconUrl: getPathUrl('notifications-inbox'),
        badgeVariant: hasNewInboxNotification ? 'dot' : undefined,
    };

    const helpOptions = {
        menuSections: [
            {
                menuItems: [
                    {
                        label: 'Help Center',
                        description:
                            'Visit our online Help Center for answers to common questions.',
                        url: 'https://opengov.my.site.com/support/services/auth/sso/OpenGov_Unified_Login',
                        openInNewTab: true,
                    },
                    {
                        label: 'Email Us',
                        description:
                            'Contact us at procurement-support@opengov.com for help at any time!',
                        url: 'mailto:procurement-support@opengov.com',
                    },
                ],
            },
        ],
    };

    // build setting menu
    const generalMenuSection = {
        label: 'General',
        menuItems: [],
    };
    const requestMenuSection = {
        label: 'Requests',
        menuItems: [],
    };
    const projectsAndContractsMenuSection = {
        label: 'Projects & Contracts',
        menuItems: [],
    };

    if (isSystemAdmin) {
        generalMenuSection.menuItems.push({
            label: 'Application Settings',
            linkComponent: ReactRouterLink,
            url: getPathUrl('admin'),
        });
    }

    if (isSystemAdmin && hasRequisition) {
        requestMenuSection.menuItems.push({
            label: 'Admin Settings',
            linkComponent: ReactRouterLink,
            url: getPathUrl('requisitions-admin/request-types'),
        });
    }

    if (isTemplateAdmin && hasDocBuilder) {
        projectsAndContractsMenuSection.menuItems.push({
            label: 'Templates',
            linkComponent: ReactRouterLink,
            url: getPathUrl('templates-admin'),
        });
    }

    if (isTemplateAdmin && hasContracting) {
        projectsAndContractsMenuSection.menuItems.push({
            label: 'Contract Checklists',
            linkComponent: ReactRouterLink,
            url: getPathUrl('checklists-admin'),
        });
    }

    if (isRetentionAdmin && (hasDocBuilder || hasIntake || hasContracting)) {
        projectsAndContractsMenuSection.menuItems.push({
            label: 'Records Retention',
            linkComponent: ReactRouterLink,
            url: getPathUrl('retention-admin'),
        });
    }

    const settingsOptionsMenuSections = [
        generalMenuSection,
        requestMenuSection,
        projectsAndContractsMenuSection,
    ];

    // filter out empty menu sections
    const settingsOptions = {
        menuSections: settingsOptionsMenuSections.filter((section) => {
            return section.menuItems.length > 0;
        }),
    };

    // user profile
    const profileSettingsOptions = {
        placeholderInitials: 'OG',
        profileImgUrl: avatarUrl,
        updateProfileLinkComponent: ReactRouterLink,
        updateProfileUrl: getPathUrl('account'),
        handleSignOut: () => navigate('/logout'),
    };

    return {
        createNewActionOptions,
        profileSettingsOptions,
        customUtilityOptions,
        helpOptions,
        notificationsOptions,
        ...(settingsOptions.menuSections.length > 0 ? { settingsOptions } : null),
    };
};
