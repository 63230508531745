import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@og-pro/ui';

import { ContractPublicAuditLogTable } from '../../../../../components/contracts';
import { loadContractPublicAuditLogs } from '../../../../../actions/contracts';
import { LoadingError } from '../../../../../components/LoadingError/LoadingError';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';

export const ContractPublicAuditLog = () => {
    const dispatch = useDispatch();
    const { contractId } = useParams();
    const loading = useSelector((state) => state.contracts.get('loadingPublicAuditLogs'));
    const loadError = useSelector((state) => state.contracts.get('loadPublicAuditLogsError'));
    const publicAuditLogs = useSelector((state) => {
        const logs = state.contracts.get('publicAuditLogs');

        return logs ? logs.toJS() : [];
    });

    useEffect(() => {
        if (!loading) {
            dispatch(loadContractPublicAuditLogs(contractId));
        }
    }, [contractId, dispatch]);

    if (loading) {
        return <LoadingSpinner text="Loading..." useOpenGovStyle />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h2">Public Audit Log</Typography>
                <Typography variant="body1">
                    View logs for when public visibility is enabled or disabled at the contract
                    record or associated files level
                </Typography>
            </Box>
            <ContractPublicAuditLogTable publicAuditLogs={publicAuditLogs} />
        </Box>
    );
};
