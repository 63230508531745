import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { rejectionPolicyTypes } from '@og-pro/shared-config/reviewSequences';

import { form } from './ReviewSequenceForm/constants';
import { getReviewSequences } from '../../../../selectors/govApp';
import { TRIAGE_BASED_MIN_POSITION_REVIEWS } from './constants';

const getRawReviewSequence = (state) => state.reviewSequences.get('reviewSequence');
const reviewSequenceFormValues = (state) => getFormValues(form)(state);

const getReviewSequenceJS = createSelector([getRawReviewSequence], (rawReviewSequence) => {
    if (rawReviewSequence) {
        return rawReviewSequence.toJS();
    }
});

export const getDeserializedReviewSequence = createSelector(
    [getReviewSequenceJS],
    (reviewSequence) => {
        if (reviewSequence) {
            const reviewSequenceSteps = reviewSequence.reviewSequenceSteps.map((step) => {
                const { questionLogics, ...reviewSequenceStep } = step;

                // Deserialize question logics from array and add form only display key
                let questionLogic = questionLogics[0];
                if (questionLogic) {
                    questionLogic = {
                        ...questionLogic,
                        logicableField: `${questionLogic.logicable}#${questionLogic.logicable_id}`,
                    };
                }

                return {
                    ...reviewSequenceStep,
                    hasQuestionLogic: !!questionLogic, // Add form only display key
                    positionIds: reviewSequenceStep.reviewSequenceStepPositions.map(
                        (reviewSequenceStepPosition) => reviewSequenceStepPosition.position_id
                    ),
                    questionLogic,
                    // Deserializes rejection policies to format used by form
                    rejectionPolicies: reviewSequenceStep.rejectionPolicies.map(
                        (rejectionPolicy) => {
                            const { reviewSequenceStepId, value } = rejectionPolicy;
                            if (reviewSequenceStepId) {
                                return `${value}.${reviewSequenceStepId}`;
                            }
                            return value;
                        }
                    ),
                    // If triage is required, set min position reviews to 0
                    minPositionReviews: reviewSequenceStep.requireTriage
                        ? TRIAGE_BASED_MIN_POSITION_REVIEWS
                        : reviewSequenceStep.minPositionReviews,
                };
            });

            return {
                ...reviewSequence,
                reviewSequenceSteps,
            };
        }
    }
);

export const getReviewSequenceFormValues = createSelector(
    [reviewSequenceFormValues],
    (formValues) => {
        if (formValues) {
            return formValues;
        }
        return {};
    }
);

export const getRejectionPolicyOptions = createSelector(
    [getReviewSequenceFormValues, getReviewSequenceJS],
    (formValues, reviewSequence) => {
        const { reviewSequenceSteps = [] } = formValues;
        return [
            {
                label: 'Closed - Rejected',
                value: rejectionPolicyTypes.CLOSED_REJECTED,
            },
            ...(reviewSequence?.isException
                ? [
                      {
                          label: 'Reject Exception Request',
                          value: rejectionPolicyTypes.EXCEPTION_REJECTED,
                      },
                  ]
                : []),
            {
                label: 'Go Back to Beginning',
                value: rejectionPolicyTypes.BACK_TO_BEGINNING,
            },
        ].concat(
            reviewSequenceSteps.map(({ id, name, uuid }, index) => {
                const stepName = name ? `: ${name}` : '';
                return {
                    index,
                    label: `Go Back to Step #${index + 1}${stepName}`,
                    value: `${rejectionPolicyTypes.BACK_SPECIFIED_STEP}.${id || uuid}`,
                };
            })
        );
    }
);

export const getExceptionSequenceOptions = createSelector(
    [getReviewSequences, getDeserializedReviewSequence],
    (reviewSequences, reviewSequence) => {
        return reviewSequences
            .filter(({ id, isException }) => isException && id !== reviewSequence.id)
            .map((reviewSeq) => {
                return {
                    icon: reviewSeq.icon || 'question',
                    label: reviewSeq.name || 'Unnamed',
                    value: reviewSeq.id,
                };
            });
    }
);
