import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { arrayMove, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { CDSButton } from '../../../../../../../../components';
import { projectSectionsToNumberedHierarchy } from '../../../../../../../../components/SDv2/helpers';
import { form, fieldNames } from '../../../../../forms/constants';
import { fieldNames as templateFormFieldNames } from '../../../../../forms/TemplateForm/constants';
import {
    TemplateEditV2FunctionsContext,
    TemplateEditV2ManageSectionContext,
} from '../../../../context';
import { getOpenGovDndStyle } from '../../../../../../../../constants/styles';
import { TemplateEditV2DrawerContentSection } from './Section';

const { USE_SECTION_DIVIDERS, USE_MANUAL_NUMBERING } = templateFormFieldNames;

const selector = formValueSelector(form);

const SortableContainer = ({ children, disabled, allowSorting, handleDragEnd }) => {
    if (!allowSorting) {
        return children;
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                droppableId="projectSectionsList"
                isDropDisabled={disabled}
                type="projectSection"
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getOpenGovDndStyle(snapshot)}
                        {...provided.droppableProps}
                    >
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableContainer.propTypes = {
    allowSorting: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    handleDragEnd: PropTypes.func.isRequired,
};

export const TemplateEditV2ManageSectionsDrawerContent = ({ disableSortable }) => {
    const dispatch = useDispatch();
    const [manageSectionData, setManageSectionData] = useState({
        active: false,
        editing: false,
        index: null,
        isTemplate: null,
        sectionType: null,
    });

    const projectSections = useSelector((state) => selector(state, fieldNames.PROJECT_SECTIONS));
    const {
        disabled,
        importedSections,
        toggleManageSectionsDrawerOpened,
        sortSections,
        updateTemplateIfDirty,
    } = useContext(TemplateEditV2FunctionsContext);
    const useSectionDividers = useSelector((state) => selector(state, USE_SECTION_DIVIDERS));
    const useManualNumbering = useSelector((state) => selector(state, USE_MANUAL_NUMBERING));

    const styles = require('../index.scss');
    const sections = projectSectionsToNumberedHierarchy(
        [...projectSections, ...importedSections],
        useManualNumbering
    );

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fieldNames.PROJECT_SECTIONS, originLocation, newLocation));
            setTimeout(sortSections, 0);
        }
    };

    const sortable = !useManualNumbering && !disableSortable;
    const disableDragAndDrop = manageSectionData.active || manageSectionData.editing;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.col}>
                    <h4>Document Outline</h4>
                </div>
                <div className={classnames(styles.col, styles.textRight)}>
                    <CDSButton
                        onClick={() => {
                            updateTemplateIfDirty();
                            toggleManageSectionsDrawerOpened();
                        }}
                        size="small"
                        variant="primary"
                    >
                        Done
                    </CDSButton>
                </div>
            </div>
            <TemplateEditV2ManageSectionContext.Provider
                value={{ data: manageSectionData, setData: setManageSectionData }}
            >
                <SortableContainer
                    allowSorting={sortable}
                    disabled={disabled || disableDragAndDrop}
                    handleDragEnd={handleDragEnd}
                >
                    <div className={styles.sectionsContainer}>
                        {sections.map((section, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <TemplateEditV2DrawerContentSection
                                        allowSorting={sortable}
                                        disableDragAndDrop={disableDragAndDrop}
                                        section={section}
                                        useSectionDividers={useSectionDividers}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </SortableContainer>
            </TemplateEditV2ManageSectionContext.Provider>
        </div>
    );
};

TemplateEditV2ManageSectionsDrawerContent.propTypes = {
    disableSortable: PropTypes.bool,
};
