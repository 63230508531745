import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Typography, Box } from '@mui/material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { contractFilterTypesDict } from '@og-pro/shared-config/contracts';

import { ContractSearchOption } from './ContractSearchOption';
import { fieldNames } from '../../../../constants';
import { VendorSearchInput } from '../../../../../../../../components/vendors';
import { ContractSearchInput } from '../../../../../../../../components/contracts';

const { CONTRACT_ID, VENDOR_ID } = fieldNames;

export const ResourceInput = ({
    disabled,
    isVendorSearch,
    onContractChange,
    onVendorChange,
    showFormValidation,
}) => {
    const sharedProps = {
        autoFocus: true,
        disabled,
        noMarginBottom: true,
        placeholder: '',
        showValidation: showFormValidation,
        useOpenGovStyle: true,
    };

    const formatGroupLabel = (data) => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Typography
                sx={{
                    textTransform: 'capitalize',
                    color: capitalDesignTokens.foundations.colors.gray1000,
                }}
                variant="h5"
            >
                {data.label}
            </Typography>
        </Box>
    );

    if (isVendorSearch) {
        return (
            <Field
                {...sharedProps}
                component={VendorSearchInput}
                filterPendingUsersWithoutOrganization
                formatGroupLabel={formatGroupLabel}
                includeVendorId
                name={VENDOR_ID}
                onOptionChange={onVendorChange}
                optionValueField="vendorId"
            />
        );
    }

    return (
        <Field
            {...sharedProps}
            component={ContractSearchInput}
            components={{
                Option: ContractSearchOption,
            }}
            formatGroupLabel={formatGroupLabel}
            name={CONTRACT_ID}
            onChange={onContractChange}
            rawValue
            searchData={{
                filters: [{ type: contractFilterTypesDict.HAS_VENDOR, value: true }],
            }}
        />
    );
};

ResourceInput.propTypes = {
    disabled: PropTypes.bool,
    isVendorSearch: PropTypes.bool,
    onContractChange: PropTypes.func.isRequired,
    onVendorChange: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
};
