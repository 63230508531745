import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import connectData from '../../ConnectData';
import { LoadingError, LoadingSpinner } from '../../../components';
import { VendorProfile as VendorProfileComponent } from '../../../components/vendors';
import { loadVendor } from '../../../actions/adminVendor';
import { getVendorJS } from './selectors';

function fetchData(getState, dispatch, location, params) {
    const vendorId = +params.vendorId;
    return dispatch(loadVendor(vendorId));
}

const mapStateToProps = (state) => {
    return {
        loading: state.adminVendor.get('loadingVendor'),
        loadError: state.adminVendor.get('loadVendorError'),
        vendor: getVendorJS(state),
    };
};

// @connectData
// @connect
class ConnectedVendorProfile extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        vendor: PropTypes.object,
    };

    render() {
        const { loading, loadError, vendor } = this.props;

        if (loading) return <LoadingSpinner />;
        if (loadError || !vendor) return <LoadingError error={loadError} />;
        return (
            <div className="row">
                <h1 className="visually-hidden">View Profile</h1>
                <div className="col-lg-8 col-lg-offset-2">
                    <Panel>
                        <Panel.Body>
                            <div className="col-sm-10 col-sm-offset-1">
                                <VendorProfileComponent showEdit vendor={vendor} />
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }
}

export const VendorProfile = compose(
    connectData(fetchData),
    connect(mapStateToProps)
)(ConnectedVendorProfile);
