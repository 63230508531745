import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { Lock as LockIcon } from '@mui/icons-material';

import { ProjectAttachmentBlock } from '../../../../../../components/forms/ProjectAttachmentBlock';
import { useReassignment } from './useReassignment';

const { colors } = capitalDesignTokens.foundations;

const { INTERNAL } = attachmentTypesDict;

export const InternalAttachments = ({
    allowedReassignmentTypes = [],
    disabled,
    form,
    project,
    triggerUpdate,
    standalone = false,
}) => {
    const reassigmentType = useReassignment({
        allowedReassignmentTypes,
        project,
    });

    return (
        <Box>
            <Box
                sx={{ backgroundColor: 'white', border: `1px solid ${colors.gray200}`, padding: 3 }}
            >
                <Box sx={{ mb: 2 }}>
                    <Box display="flex" justifyItems="space-between">
                        <Box flex={5}>
                            <Typography variant="h3">
                                {project.type === projectTypesDict.CONTRACT
                                    ? 'Internal Files'
                                    : 'Internal Documents'}
                            </Typography>
                        </Box>
                        <Box flex={1} textAlign="right">
                            <Chip icon={<LockIcon />} label="Internal Only" size="small" />
                        </Box>
                    </Box>

                    <Typography color="text.secondary">
                        {project.type === projectTypesDict.CONTRACT &&
                            'These files will *not* be included in the contract packet.'}
                        {project.type !== projectTypesDict.CONTRACT &&
                            (standalone
                                ? 'Include any documents that will support the creation of the project. These documents are for internal use only.'
                                : 'These documents are for internal use only. The vendor will not see them. (Some may have come over from the Intake Request.)')}
                    </Typography>
                </Box>
                <ProjectAttachmentBlock
                    allowedReassignmentTypes={reassigmentType}
                    attachmentListLabel="Uploaded Documents"
                    disabled={disabled}
                    emptyListTitle="No Attachments"
                    form={form}
                    formKey={INTERNAL}
                    hideAppendixLetter
                    hideNoAttachments
                    isOGThemeEnabledForComponents
                    label="Internal Attachments"
                    listAttachmentType={INTERNAL}
                    projectId={project.id}
                    triggerUpdate={triggerUpdate}
                    uploadAttachmentType={INTERNAL}
                />
            </Box>
        </Box>
    );
};

InternalAttachments.propTypes = {
    allowedReassignmentTypes: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    form: PropTypes.object,
    project: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string.isRequired,
    }),
    triggerUpdate: PropTypes.func,
    standalone: PropTypes.bool,
};
