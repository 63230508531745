import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { EvaluationNotesForm } from './EvaluationNotesForm';

export class EvaluationNotesModal extends PureComponent {
    static propTypes = {
        canEvaluate: PropTypes.bool.isRequired,
        hideModal: PropTypes.func.isRequired,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            proposalEvaluation: PropTypes.shape({
                note: PropTypes.string,
                updateError: PropTypes.string,
                updating: PropTypes.bool,
            }).isRequired,
        }).isRequired,
        submitHandler: PropTypes.func.isRequired,
    };

    render() {
        const {
            canEvaluate,
            hideModal,
            proposal: {
                companyName,
                proposalEvaluation: { note, updateError, updating },
            },
            submitHandler,
        } = this.props;

        return (
            <Modal enforceFocus={false} onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {companyName || 'Unnamed Company'} Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EvaluationNotesForm
                        canEvaluate={canEvaluate}
                        hideModal={hideModal}
                        initialValues={{ note }}
                        onSubmit={submitHandler}
                        updateError={updateError}
                        updating={updating}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}
