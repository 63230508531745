import moment from 'moment';
import { isArray, isObject } from 'lodash';

import { DATES, BOOLEANS, FIELDNAMES } from '../constants';

export const formatKey = (namespace, key) => {
    return FIELDNAMES[`${namespace}.${key}`] || FIELDNAMES[key] || `[${key}]`;
};

export const formatValue = (log, key, valueKey, tags) => {
    const value = log[valueKey][key];

    if (value === null || value === undefined) {
        return '--';
    }

    if (DATES.includes(key)) {
        return moment(value).format('ll');
    }

    if (BOOLEANS.includes(key)) {
        return value ? 'Yes' : 'No';
    }

    if (key === 'project_id') {
        if (valueKey === 'value') {
            return log.context?.project?.title || value;
        }

        if (valueKey === 'previousValue') {
            return log.context?.oldProject?.title || value;
        }
    }

    if (key === 'tag_id' || key === 'procurement_tag_id') {
        return tags[value] || value;
    }

    if (isArray(value)) {
        // category changes are stored as an array with id, title props
        if (key === 'categories') {
            return value.map((category) => category.title).join(', ');
        }

        // attachments changes are stored as an array with filename prop
        if (key === 'attachments') {
            return value.map((attachment) => attachment.filename).join(', ');
        }

        // tag changes are stored with different keys depending what they are
        // some are stored as an array of integers, others are objects with the name included
        if (key === 'tag' || key === 'tags' || key === 'fundingSourceTags') {
            return value
                .map((tag) => {
                    if (isObject(tag)) {
                        return tag.name;
                    }

                    return tags[tag] || tag;
                })
                .join(', ');
        }

        return value.join(', ');
    }

    if (isObject(value)) {
        return JSON.stringify(value);
    }

    return value;
};
