import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import Select from 'react-select';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Link } from 'react-router-dom';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Main } from '../../Main';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { ZeroState } from '../../ZeroState';

class VendorLists extends React.Component {
    static propTypes = {
        vendorLists: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                user: PropTypes.shape({
                    displayName: PropTypes.string.isRequired,
                    id: PropTypes.number.isRequired,
                }).isRequired,
                government_id: PropTypes.number.isRequired,
                updated_at: PropTypes.string.isRequired,
            })
        ),
        governmentId: PropTypes.number.isRequired,
        router: PropTypes.object.isRequired,
        showVendorListModal: PropTypes.func.isRequired,
        deleteVendorList: PropTypes.func.isRequired,
    };

    static defaultProps = {
        vendorLists: [],
    };

    get styles() {
        return require('./styles.scss');
    }

    selectOption = (option) => {
        if (option.action === 'edit') {
            this.props.showVendorListModal(option.value);
        } else if (option.action === 'delete') {
            this.props.deleteVendorList(option.value);
        } else if (option.action === 'view') {
            this.props.router.push(`/governments/${option.govId}/vendors/lists/${option.value}`);
        }
    };

    render() {
        const { vendorLists, governmentId } = this.props;

        if (vendorLists.length === 0) {
            return <ZeroState title="No Vendor Lists Have Been Created" />;
        }

        return (
            <Main>
                <ListGroup role="list">
                    {vendorLists.map((vendorList) => {
                        return (
                            <li className="list-group-item" key={`vendorList-${vendorList.id}`}>
                                <div className="row">
                                    <div
                                        className={classnames(
                                            'col-xs-12 col-sm-4 col-lg-3',
                                            this.styles.listGroupItem
                                        )}
                                    >
                                        <div className={this.styles.listGroupItemTitle}>
                                            <Link
                                                to={`/governments/${governmentId}/vendors/lists/${vendorList.id}`}
                                            >
                                                {vendorList.name}
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className={classnames(
                                            'col-xs-12 col-sm-4 col-lg-3',
                                            this.styles.listGroupItem
                                        )}
                                    >
                                        <UserProfilePicture
                                            className={this.styles.userProfilePicture}
                                            horizontal
                                            user={vendorList.user}
                                        />
                                        <div className={this.styles.user}>
                                            {vendorList.user.displayName}
                                        </div>
                                    </div>
                                    <div
                                        className={classnames(
                                            'col-xs-12 col-sm-4 col-lg-3',
                                            this.styles.listGroupItem
                                        )}
                                    >
                                        Last Updated: <br />
                                        {moment(vendorList.updated_at).format('M/D/YY h:mma')}
                                    </div>
                                    <div
                                        className={classnames(
                                            'col-xs-12 col-sm-4 col-lg-3',
                                            this.styles.listGroupItem
                                        )}
                                    >
                                        <Select
                                            aria-label="Select Action"
                                            className={this.styles.actions}
                                            onChange={this.selectOption}
                                            options={[
                                                {
                                                    label: 'View Vendors',
                                                    action: 'view',
                                                    value: vendorList.id,
                                                    govId: vendorList.government_id,
                                                },
                                                {
                                                    label: 'Edit Name',
                                                    value: vendorList.id,
                                                    action: 'edit',
                                                },
                                                {
                                                    label: 'Add or Remove Vendors',
                                                    action: 'view',
                                                    value: vendorList.id,
                                                    govId: vendorList.government_id,
                                                },
                                                {
                                                    label: 'Delete',
                                                    value: vendorList.id,
                                                    action: 'delete',
                                                },
                                            ]}
                                            placeholder="Actions"
                                            styles={{
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: capitalDesignTokens.foundations.colors
                                                        .gray700,
                                                }),
                                            }}
                                            value={null}
                                        />
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ListGroup>
            </Main>
        );
    }
}

export default withRouter(VendorLists);
