import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';
import { listToDict } from '@og-pro/shared-config/helpers';

import { contactFields } from '../../../../../../../constants/contactFields';

export const fields = [
    ...contactFields,
    ...Object.values(mappedProcurementContactFields),
    'allowsProxyBidding',
    'allowSubstitutionRequests',
    'auctionMaxFractionDigits',
    'budget',
    'categories',
    'contact_id',
    'department_id',
    'departmentName',
    'departmentHead',
    'departmentHeadTitle',
    'financialId',
    'hasSealedBid',
    'isAutoBidUnsealed',
    'isEmergency',
    'isPublicBidPricingResult',
    'isPublicBidResult',
    'isPrivate',
    'notifyVendorsOnPosting',
    'procurement_contact_id',
    'qaEnabled',
    'rawBackground',
    'rawSummary',
    'requiresInvitation',
    'requisitionIdentifier',
    'showPlanholders',
    'title',
    'internalNotes',
    'tagId',
];
export const fieldNames = listToDict(fields);

export const budgetFields = ['amount', 'description', 'identifier'];
export const budgetFieldNames = listToDict(budgetFields);

export const auctionMaxFractionDigitsOptions = [
    { label: '$1.00', value: 0 },
    { label: '$0.01', value: 2 },
    { label: '$0.001', value: 3 },
    { label: '$0.0001', value: 4 },
];

export const emergencyOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
];
