import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { hasFormErrors } from '../../../../../../../helpers';
import {
    arrayError,
    evaluationCriteriaItem,
    manualNumber,
} from '../../../../../../../Forms/validation';

export const validate = (values, props) => {
    const errors = {};
    const { isPublished, projectSection, useManualNumbering } = props;

    const isTextArea = projectSection.section_type === sectionTypeNames.TEXT_AREA;
    const isEvaluationPhase = projectSection.section_type === sectionTypeNames.EVALUATION_PHASE;

    errors.items = values.items.map((item) => {
        const itemError = {};

        if (isEvaluationPhase || !isPublished) {
            return itemError;
        }

        if (!isTextArea) {
            if (!item.title?.trim()) {
                itemError.title = 'Item title is required';
            } else if (item.title && item.title.length > 255) {
                itemError.title = 'Item title must be less than 255 characters';
            }

            if (useManualNumbering) {
                const manualNumbering = item.manualNumber;
                itemError.manualNumber = manualNumber(manualNumbering);
            }
        }

        if (!item.description?.trim()) {
            itemError.description = 'Item description is required';
        }

        return itemError;
    });

    if (isEvaluationPhase && isPublished) {
        errors.evaluationPhases = values?.evaluationPhases.map((phase) => {
            const evaluationPhaseErrors = {};

            if (!phase.title?.trim()) {
                evaluationPhaseErrors.title = 'Title is required';
            } else if (phase.title.length > 250) {
                evaluationPhaseErrors.title = 'This field is too long';
            }

            if (!phase.scoringCriteria || phase.scoringCriteria.length === 0) {
                evaluationPhaseErrors.scoringCriteria = arrayError(
                    'Please add at least one evaluation criteria'
                );
                return evaluationPhaseErrors;
            }

            evaluationPhaseErrors.scoringCriteria = phase.scoringCriteria.map((criteriaItem) =>
                evaluationCriteriaItem(criteriaItem)
            );

            return evaluationPhaseErrors;
        });
    }

    errors.hasErrors = hasFormErrors(errors);

    return errors;
};
