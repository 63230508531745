import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getProposalsListJS, getQuestionnairesListJS } from './selectors';
import { NoItems } from '../components';
import {
    getProjectJS,
    getProjectStandardDocJS,
    mustAcceptProposalViewerAgreement,
} from '../../selectors';
import connectData from '../../../ConnectData';
import {
    loadProposalCompare,
    showEvaluationScorecard,
} from '../../../../actions/proposalEvaluations';
import { getProposalViewerAgreementData } from '../../../../actions/govProjects';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import {
    ProposalViewerAgreementAccepted,
    ProposalQuestionnaireComparisonTable,
    ProposalViewerAgreement,
} from '../../../../components/proposals';
import { getUserJS } from '../../../selectors';
import { getProposalViewerAcceptanceDate } from '../../ProposalsList/selectors';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(loadProposalCompare(projectId)),
        dispatch(getProposalViewerAgreementData(projectId)),
    ]);
}

const mapStateToProps = (state, props) => {
    return {
        acceptedAt: getProposalViewerAcceptanceDate(state, props),
        error: state.proposalEvaluations.get('loadCompareError'),
        loadAgreementsError: state.govProjects.get('loadAgreementsError'),
        loading: state.proposalEvaluations.get('loadingCompare'),
        loadingAgreements: state.govProjects.get('loadingAgreements'),
        mustAcceptAgreement: mustAcceptProposalViewerAgreement(state, props),
        project: getProjectJS(state),
        proposals: getProposalsListJS(state),
        questionnaires: getQuestionnairesListJS(state),
        standardDocument: getProjectStandardDocJS(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    showEvaluationScorecard,
};

// @connectData
// @connect
class ConnectedProposalEvaluationsCompare extends Component {
    static propTypes = {
        acceptedAt: PropTypes.string,
        error: PropTypes.string,
        loadAgreementsError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadingAgreements: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        mustAcceptAgreement: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            evaluatorId: PropTypes.string,
        }).isRequired,
        project: PropTypes.shape({
            showBids: PropTypes.bool.isRequired,
            showBidsWithPricing: PropTypes.bool.isRequired,
            standard_document_id: PropTypes.number,
        }).isRequired,
        proposals: PropTypes.array.isRequired,
        questionnaires: PropTypes.array.isRequired,
        showEvaluationScorecard: PropTypes.func.isRequired,
        standardDocument: PropTypes.object,
        user: PropTypes.shape({
            id: PropTypes.number,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderTable() {
        const {
            acceptedAt,
            location: { pathname },
            params: { evaluatorId },
            project: { showBidsWithPricing },
            proposals,
            questionnaires,
            standardDocument,
            user: { id: userId },
        } = this.props;

        const hasEvaluatorId = pathname.includes('proposal-evaluations');

        return (
            <>
                {acceptedAt && (
                    <ProposalViewerAgreementAccepted
                        acceptedAt={acceptedAt}
                        standardDocument={standardDocument}
                    />
                )}
                <ProposalQuestionnaireComparisonTable
                    evaluatorId={hasEvaluatorId ? parseInt(evaluatorId, 10) || userId : null}
                    isPricingSealed={!showBidsWithPricing}
                    proposals={proposals}
                    questionnaires={questionnaires}
                    showEvaluationScorecard={this.props.showEvaluationScorecard}
                />
            </>
        );
    }

    renderProposalCompareTable() {
        const {
            project: { showBids },
            proposals,
        } = this.props;

        if (!showBids) {
            return <ZeroState title="Responses are sealed" />;
        }

        if (proposals.length === 0) {
            return <NoItems text="There are no responses to compare yet." />;
        }

        return this.renderTable();
    }

    render() {
        const {
            error,
            loadAgreementsError,
            loading,
            loadingAgreements,
            mustAcceptAgreement,
            project,
            standardDocument,
        } = this.props;

        if (loading || loadingAgreements) {
            return <LoadingSpinner />;
        }

        if (error || loadAgreementsError) {
            return <LoadingError error={error || loadAgreementsError} />;
        }

        if (standardDocument && mustAcceptAgreement) {
            return (
                <>
                    <ProposalViewerAgreement
                        project={project}
                        standardDocument={standardDocument}
                    />
                    <p className={this.styles.proposalViewerAgreementInfo}>
                        You will be able to view responses after submitting the agreement
                    </p>
                </>
            );
        }

        return (
            <Main>
                <SectionTitle
                    info="Side-by-side comparison of vendor questionnaire responses"
                    title="Response Comparison"
                />
                {this.renderProposalCompareTable()}
            </Main>
        );
    }
}

export const ProposalEvaluationsCompare = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProposalEvaluationsCompare);
