import { isNil } from 'lodash';
import { validate as validateUuid } from 'uuid';

import { TRIAGE_BASED_MIN_POSITION_REVIEWS } from './constants';

const isQuestionLogicValue = (questionLogic) => {
    return (
        questionLogic &&
        !!questionLogic.logicable &&
        !!questionLogic.logicable_id &&
        !!questionLogic.operator &&
        !isNil(questionLogic.value)
    );
};

export const serializeReviewSequenceData = (reviewSequenceData) => {
    return {
        ...reviewSequenceData,
        reviewSequenceSteps: reviewSequenceData.reviewSequenceSteps.map(
            ({ questionLogic, rejectionPolicies: rawRejectionPolicies, ...reviewSequenceStep }) => {
                // Serializes rejection policies to format used by API server
                const rejectionPolicies = rawRejectionPolicies.map((rejectionPolicyFormValue) => {
                    // For non-encoded form keys simply set as the rejection policy value
                    if (typeof rejectionPolicyFormValue !== 'string') {
                        return { value: rejectionPolicyFormValue };
                    }

                    // For encoded keys, need to determine if policy references an already created
                    // review sequence step (ID key) or newly created step (UUID key)
                    // UUID case is complex since we need to reference an ID that does not exist yet
                    const [value, idOrUuid] = rejectionPolicyFormValue.split('.');
                    const isUuid = validateUuid(idOrUuid);
                    const reviewSequenceStepIdKey = isUuid
                        ? { uuid: idOrUuid }
                        : { reviewSequenceStepId: Number.parseInt(idOrUuid, 10) };

                    return {
                        reviewSequenceStepId: -1,
                        ...reviewSequenceStepIdKey,
                        value: Number.parseInt(value, 10),
                    };
                });

                // Question logic data must be sent as an array
                let questionLogics = [];
                if (isQuestionLogicValue(questionLogic)) {
                    // Question logic data must be complete in order to save, so we need to be sure to only send complete entries
                    questionLogics = [questionLogic];
                }

                return {
                    ...reviewSequenceStep,
                    questionLogics,
                    rejectionPolicies,
                    minPositionReviews:
                        reviewSequenceStep.minPositionReviews === TRIAGE_BASED_MIN_POSITION_REVIEWS
                            ? 1
                            : reviewSequenceStep.minPositionReviews,
                    requireTriage:
                        reviewSequenceStep.minPositionReviews === TRIAGE_BASED_MIN_POSITION_REVIEWS,
                };
            }
        ),
    };
};
