import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { form } from './constants';
import { personalValidate as validate } from './validate';
import * as personalAccountActions from '../../../actions/account/personal';
import { Main } from '../../../components';
import { PhoneNumberInput } from '../../../components/forms';

const mapStateToProps = (state) => {
    return {
        saving: state.accountPersonal.get('saving'),
    };
};

const mapDispatchToProps = personalAccountActions;

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
export class ConnectedPersonalContact extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        form: PropTypes.string.isRequired, // Comes from @reduxForm
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        parentSubmit: PropTypes.bool,
        save: PropTypes.func.isRequired,
        saving: PropTypes.bool,
        showErrorFields: PropTypes.bool,
    };

    handleSubmit = (data) => {
        if (!this.props.parentSubmit) {
            this.props.save(data);
        }
    };

    renderSaveButton() {
        const { invalid, saving, parentSubmit } = this.props;

        if (!parentSubmit) {
            return (
                <Button
                    bsSize="large"
                    bsStyle="primary"
                    className="center-block"
                    disabled={invalid || saving}
                    type="submit"
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            );
        }
    }

    render() {
        const { autoFocus, form: formName, handleSubmit, saving, showErrorFields } = this.props;

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <Main className="col-xs-12">
                    <p>
                        Your contact information will be publicly listed on all documents where you
                        are listed as the primary contact.
                    </p>
                    <PhoneNumberInput
                        autoFocus={autoFocus}
                        disabled={saving}
                        formName={formName}
                        placeholder="Enter your phone number"
                        showErrorFields={showErrorFields}
                    />
                    {this.renderSaveButton()}
                </Main>
            </form>
        );
    }
}

export const PersonalContact = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPersonalContact);
