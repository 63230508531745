import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Box, DropdownMenu, MenuItem } from '@og-pro/ui';
import { Check as CheckIcon } from '@mui/icons-material';

import { loadAllCertifications } from '../../actions/adminVendor';
import { InputText } from '../InputText';

const DefaultLabel = ({ count, text }) => {
    const styles = require('./index.scss');

    return (
        <>
            {text || 'Certifications'}
            <Box className={styles.filtersCount} component="span" ml={1}>
                {count}
            </Box>
        </>
    );
};
DefaultLabel.propTypes = { count: PropTypes.number.isRequired, text: PropTypes.string };

export const CertificationsDropdownSelect = ({
    className,
    dropdownButtonProps = {},
    getMenuItemLabel = null,
    label,
    onChange,
    selected,
    showFilterInput = false,
}) => {
    const [filteringString, setFilteringString] = useState('');
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.adminVendor.get('loadingCertifications'));
    const certifications = useSelector((state) =>
        state.adminVendor.get('vendorCertifications')?.toJS()
    );

    useEffect(() => {
        if (!loading && !certifications?.length) {
            dispatch(loadAllCertifications());
        }
    }, [loading, certifications, dispatch]);

    const onClick = (certification) => {
        const certificationIds = selected.includes(certification.id)
            ? selected.filter((id) => id !== certification.id)
            : [...selected, certification.id];
        onChange(certificationIds);
    };

    const styles = require('./index.scss');

    const filteredCertifications = filteringString
        ? certifications.filter((cert) => new RegExp(filteringString, 'i').test(cert.title))
        : certifications;
    return (
        <Box className={classnames(styles.container, className)}>
            <Box className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
                <DropdownMenu
                    color="secondary"
                    label={<DefaultLabel count={selected.length} text={label} />}
                    qaTag="certificationsDropdownSelect"
                    variant="outlined"
                    {...dropdownButtonProps}
                >
                    {showFilterInput && (
                        <MenuItem>
                            <Box className={styles.filterInput} py={1}>
                                <InputText
                                    input={{
                                        value: filteringString,
                                        onChange: (e) => {
                                            setFilteringString(e.target.value);
                                        },
                                        onBlur: () => {},
                                    }}
                                    meta={{}}
                                    placeholder="Search certifications"
                                    qaTag="certificationsDropdownSelect-filter"
                                    size="small"
                                    useOpenGovStyle
                                />
                            </Box>
                        </MenuItem>
                    )}

                    {filteredCertifications.map((certification) => {
                        return (
                            <MenuItem
                                className="certification-dropdown-menuitem"
                                key={certification.id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    onClick(certification);
                                }}
                                qaTag={`certificationsDropdownSelect-certification${certification.id}`}
                            >
                                <Box
                                    alignItems="center"
                                    className="certification-dropdown-menuitem-container"
                                    display="flex"
                                    py={1}
                                >
                                    <Box
                                        className="certification-dropdown-menuitem-icon"
                                        sx={{ pr: 1, pl: 1 }}
                                    >
                                        <CheckIcon
                                            fontSize="small"
                                            sx={{
                                                opacity: selected.includes(certification.id)
                                                    ? 1
                                                    : 0,
                                            }}
                                        />
                                    </Box>
                                    <Box className="certification-dropdown-menuitem-label">
                                        {getMenuItemLabel
                                            ? getMenuItemLabel(certification)
                                            : certification.title}
                                    </Box>
                                </Box>
                            </MenuItem>
                        );
                    })}
                </DropdownMenu>
            </Box>
        </Box>
    );
};

CertificationsDropdownSelect.propTypes = {
    className: PropTypes.string,
    dropdownButtonProps: PropTypes.object,
    getMenuItemLabel: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
    showFilterInput: PropTypes.bool,
};
