import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';

import { InternalAttachments } from '../../components/Attachments/Internal';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const IntakeAttachments = () => {
    const { disabled, form, initiateSneakyUpdate, project } = useContext(
        ProjectCreateV2FunctionsContext
    );

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <InternalAttachments
                    allowedReassignmentTypes={[]}
                    disabled={disabled}
                    form={form}
                    project={project}
                    standalone
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
        </Box>
    );
};
