import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';
import classnames from 'classnames';

import { ListImportModal } from './ListImportModal';
import { criteriaFieldNames, fieldNames } from '../../constants';
import { QuestionLogicIcon } from '../../../../components';
import { Button, CDSButton, CDSButtonGroup, OutlineButton } from '../../../../../../../components';
import { CriteriaForm } from '../../../../../../../components/forms';
import { getMaxNumberFromList } from '../../../../../../../utils';
import { OGThemeContext } from '../../../../../ogThemeProvider';
import { withListItemRefs } from '../../../../../../../hocs/withListItemRefs';

const { CRITERIA } = fieldNames;

const { ORDER_BY_ID } = criteriaFieldNames;

const mapStateToProps = (state, props) => {
    const {
        projectSection: { id, projectSubsections },
    } = props;

    const projectSubsection = projectSubsections[0];
    const formKey = props.formKey || `${CRITERIA}.${id}_${projectSubsection.id}`;
    const formSelector = formValueSelector(props.form);

    return {
        formKey,
        listItems: formSelector(state, formKey) || [],
        projectSubsection,
    };
};

// @connect
class ConnectedTemplateListForm extends PureComponent {
    static propTypes = {
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
            splice: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        formKey: PropTypes.string.isRequired,
        hideQuestionLogic: PropTypes.bool,
        isSharedSectionForm: PropTypes.bool,
        listItems: PropTypes.array.isRequired,
        // props from the HOC for list item refs
        listItemRefs: PropTypes.array.isRequired,
        updateListItemRefs: PropTypes.func.isRequired,
        focusListItem: PropTypes.func.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isTemplate: PropTypes.bool,
            projectSubsections: PropTypes.array.isRequired,
            section_type: PropTypes.string.isRequired,
        }).isRequired,
        projectSubsection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            subsection_type: PropTypes.string.isRequired,
        }).isRequired,
        questionLogicLinkable: PropTypes.string.isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showListImportModal: false,
        };
    }

    static contextType = OGThemeContext;

    addListItem = (listItem) => {
        const {
            array,
            focusListItem,
            listItems,
            formKey,
            projectSection,
            projectSubsection,
            updateListItemRefs,
        } = this.props;

        updateListItemRefs(null, () => {
            focusListItem(listItems.length);
        });

        array.push(formKey, {
            orderById: getMaxNumberFromList(listItems, ORDER_BY_ID) + 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsection.id,
            section_type: projectSection.section_type,
            sharedId: UUIDv4(),
            subsection_type: projectSubsection.subsection_type,
            ...listItem,
        });
    };

    importListItems = (importedListItems) => {
        const { listItems } = this.props;

        const startOrderById = getMaxNumberFromList(listItems, ORDER_BY_ID) + 1;

        importedListItems.forEach((listItem, index) => {
            this.addListItem({ ...listItem, orderById: startOrderById + index });
        });

        this.hideListImportModal();
    };

    insertListItem = (insertAfterOrderById) => {
        const {
            array,
            change,
            focusListItem,
            listItems,
            formKey,
            projectSection,
            projectSubsection,
            updateListItemRefs,
        } = this.props;

        let insertAfterIndex;
        listItems.forEach((item, index) => {
            const oldOrderById = item.orderById;
            if (oldOrderById === insertAfterOrderById) {
                insertAfterIndex = index + 1;
            }
            if (oldOrderById > insertAfterOrderById) {
                change(`${formKey}[${index}].${ORDER_BY_ID}`, oldOrderById + 1);
            }
        });

        updateListItemRefs(null, () => {
            focusListItem(insertAfterIndex);
        });

        array.splice(formKey, insertAfterIndex, 0, {
            orderById: insertAfterOrderById + 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsection.id,
            section_type: projectSection.section_type,
            sharedId: UUIDv4(),
            subsection_type: projectSubsection.subsection_type,
        });
    };

    renderQuestionLogicIcon = (criterium) => {
        const { hideQuestionLogic, projectSection, questionLogicLinkable } = this.props;

        return (
            <QuestionLogicIcon
                hidden={hideQuestionLogic}
                linkable={questionLogicLinkable}
                linkableItem={criterium}
                projectSection={projectSection}
            />
        );
    };

    hideListImportModal = () => this.setState({ showListImportModal: false });

    showListImportModal = () => this.setState({ showListImportModal: true });

    render() {
        const {
            change,
            disabled,
            form,
            formKey,
            listItems,
            listItemRefs,
            isSharedSectionForm,
            projectSection,
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            updateListItemRefs,
        } = this.props;

        const { showListImportModal } = this.state;

        const itemRefsProps = {
            listItemRefs,
            updateListItemRefs,
        };

        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <div
                className={classnames({
                    row: !isOGThemeEnabledForComponents,
                })}
            >
                <div
                    className={classnames({
                        'col-md-offset-2 col-md-8': !isOGThemeEnabledForComponents,
                    })}
                >
                    <FieldArray
                        {...itemRefsProps}
                        change={change}
                        component={CriteriaForm}
                        disabled={disabled}
                        form={form}
                        insertItem={this.insertListItem}
                        isCriteriaBuilder
                        isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                        isSharedSectionForm={isSharedSectionForm}
                        name={formKey}
                        projectSection={projectSection}
                        renderQuestionLogicIcon={this.renderQuestionLogicIcon}
                        showArrayError
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useManualNumbering={useManualNumbering}
                    />
                    <div className="text-center">
                        {!isOGThemeEnabledForComponents && (
                            <>
                                <OutlineButton
                                    aria-label="Add List Item"
                                    bsSize="sm"
                                    bsStyle="info"
                                    disabled={disabled}
                                    onClick={() => this.addListItem()}
                                    qaTag="templateListForm-addListItem"
                                >
                                    <i className="fa fa-plus" /> Add List Item
                                </OutlineButton>
                                <br />

                                {!useManualNumbering && (
                                    <Button
                                        aria-label="Bulk Import"
                                        bsSize="sm"
                                        bsStyle="link"
                                        disabled={disabled}
                                        onClick={this.showListImportModal}
                                        qaTag="templateListForm-bulkImport"
                                    >
                                        <i className="fa fa-download" /> Bulk Import
                                    </Button>
                                )}
                            </>
                        )}

                        {isOGThemeEnabledForComponents && !projectSection.isTemplate && (
                            <>
                                {listItems.length === 0 && (
                                    <CDSButtonGroup>
                                        <CDSButton
                                            aria-label="Add List Item"
                                            disabled={disabled}
                                            onClick={() => this.addListItem()}
                                            qaTag="templateListForm-addListItem"
                                            variant="secondary-alt"
                                        >
                                            <i className="fa fa-plus" /> Add a List Item
                                        </CDSButton>
                                    </CDSButtonGroup>
                                )}

                                {!useManualNumbering && (
                                    <div>
                                        <br />
                                        <CDSButton
                                            aria-label="Bulk Import"
                                            disabled={disabled}
                                            onClick={this.showListImportModal}
                                            qaTag="templateListForm-bulkImport"
                                            size="small"
                                            variant="secondary-alt"
                                        >
                                            <i className="fa fa-download" /> Bulk Import
                                        </CDSButton>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {showListImportModal && (
                        <ListImportModal
                            onHide={this.hideListImportModal}
                            onImport={this.importListItems}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export const TemplateListForm = connect(mapStateToProps)(
    withListItemRefs(ConnectedTemplateListForm, { propMapKey: 'listItems' })
);
