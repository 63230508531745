import { useSelector } from 'react-redux';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

export const useHasElectronicSignature = () => {
    const restrictionsEnabled = useFlags(FLAGS.ENABLE_ESIGNATURES_GOV_RESTRICTIONS);

    const hasElectronicSignatures = useSelector((state) =>
        state.auth.getIn(['user', 'government', 'hasElectronicSignatures'])
    );

    // The new setting needs to only restrict stuff if the flag is on
    // otherwise it should act as if it's off
    if (!restrictionsEnabled) {
        return true;
    }

    return hasElectronicSignatures;
};
