import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

// Used to add user profile pictures to the `@og-pro/Autocomplete` component options
export const AutocompleteUserOption = ({ optionProps, option }) => {
    return (
        <Box
            {...optionProps}
            component="li"
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{ mr: 1 }}>
                <UserProfilePicture horizontal user={option.user} />{' '}
            </Box>
            {option.label}
        </Box>
    );
};

AutocompleteUserOption.propTypes = {
    optionProps: PropTypes.object,
    option: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        user: PropTypes.object,
    }).isRequired,
};
