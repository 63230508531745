const upperFirst = require('lodash/upperFirst');

const { UNITED_STATES_PHONE_COUNTRY_CODE } = require('../global');

const ADDRESS_1 = 'address1';
const ADDRESS_2 = 'address2';
const CITY = 'city';
const COUNTRY = 'country';
const COUNTRY_CODE = 'countryCode';
const EMAIL = 'email';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PHONE = 'phone';
const PHONE_COMPLETE = 'phoneComplete';
const PHONE_COUNTRY = 'phoneCountry';
const PHONE_EXT = 'phoneExt';
const STATE = 'state';
const TITLE = 'title';
const ZIP_CODE = 'zipCode';

exports.contactFieldNames = {
    ADDRESS_1,
    ADDRESS_2,
    CITY,
    COUNTRY,
    COUNTRY_CODE,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    PHONE_COMPLETE,
    PHONE_COUNTRY,
    PHONE_EXT,
    STATE,
    TITLE,
    ZIP_CODE,
};

exports.mapContactFields = (prefix, fieldAccessorPrefix = '') => {
    return {
        [ADDRESS_1]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(ADDRESS_1)}` : ADDRESS_1
        }`,
        [ADDRESS_2]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(ADDRESS_2)}` : ADDRESS_2
        }`,
        [CITY]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(CITY)}` : CITY}`,
        [COUNTRY]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(COUNTRY)}` : COUNTRY}`,
        [COUNTRY_CODE]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(COUNTRY_CODE)}` : COUNTRY_CODE
        }`,
        [EMAIL]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(EMAIL)}` : EMAIL}`,
        [FIRST_NAME]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(FIRST_NAME)}` : FIRST_NAME
        }`,
        [LAST_NAME]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(LAST_NAME)}` : LAST_NAME
        }`,
        [PHONE]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(PHONE)}` : PHONE}`,
        [PHONE_COMPLETE]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(PHONE_COMPLETE)}` : PHONE_COMPLETE
        }`,
        [PHONE_COUNTRY]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(PHONE_COUNTRY)}` : PHONE_COUNTRY
        }`,
        [PHONE_EXT]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(PHONE_EXT)}` : PHONE_EXT
        }`,
        [STATE]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(STATE)}` : STATE}`,
        [TITLE]: `${fieldAccessorPrefix}${prefix ? `${prefix}${upperFirst(TITLE)}` : TITLE}`,
        [ZIP_CODE]: `${fieldAccessorPrefix}${
            prefix ? `${prefix}${upperFirst(ZIP_CODE)}` : ZIP_CODE
        }`,
    };
};

// Shortcut for commonly used contact field mappings (plain user and contact user)
exports.mappedUserFields = exports.mapContactFields();
exports.mappedContactFields = exports.mapContactFields('contact');
exports.mappedProcurementContactFields = exports.mapContactFields('procurement');

// If needed, inserts a whitespace between the closing ")" and the 4th digit of the phone number
// TODO: this is needed because we store phones in the DB as (XXX)YYYY-ZZZZ. If we were to change
// to only storing digits, we could instead just template format the phone number.
exports.formatPhoneNumber = (phone) => {
    if (!phone || phone[0] !== '(' || phone[4] !== ')') {
        return phone;
    }

    return phone.replace(/\)/, ') ');
};

/**
 * @param {string} [phone] The contact's phone number
 * @param {string} [phoneExt] The contact's phone number extension
 * @param {string} [phoneCountry] The contact's phone number country code
 * @return {string} The complete phone number
 */
exports.getContactPhoneComplete = (phone, phoneExt, phoneCountry) => {
    if (!phone) {
        return null;
    }

    let countryCode = '';
    if (phoneCountry && phoneCountry !== UNITED_STATES_PHONE_COUNTRY_CODE) {
        countryCode = `+${phoneCountry}`;
    }

    let extString = '';
    if (phoneExt) {
        extString = ` Ext: ${phoneExt}`;
    }

    return `${countryCode}${exports.formatPhoneNumber(phone)}${extString}`;
};
