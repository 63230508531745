import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React from 'react';

import { DisplaySection } from '../../../DisplaySection';

export const ContractMilestones = ({ contract: { contractMilestones }, timezone }) => {
    const standardMilestones = contractMilestones.filter((milestone) => {
        return !milestone.isEndDate && !milestone.isStartDate && !milestone.isInsuranceExpiration;
    });

    return (
        <DisplaySection
            header="MILESTONES (Internal Use Only)"
            items={standardMilestones.map((milestone) => {
                return {
                    icon: 'calendar',
                    label: milestone.name,
                    value: moment(milestone.date).tz(timezone).format('ll'),
                };
            })}
            zeroState="No Milestones Created"
        />
    );
};

ContractMilestones.propTypes = {
    contract: PropTypes.shape({
        contractMilestones: PropTypes.arrayOf(
            PropTypes.shape({
                isEndDate: PropTypes.bool,
                isInsuranceExpiration: PropTypes.bool,
                isStartDate: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
    timezone: PropTypes.string.isRequired,
};
