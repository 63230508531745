import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getProposalSyncEvent } from './selectors';
import * as vendProposalsActionTypes from '../../../actions/vendProposals/types';

const { CONFIRM_ADDENDUM_SUCCESS, UPDATE_PROPOSAL_SUCCESS, UPDATE_QUESTIONNAIRE_RESPONSE } =
    vendProposalsActionTypes;

export const ProposalCreateNavSync = ({ change, initialize, proposal }) => {
    const syncEvent = useSelector(getProposalSyncEvent);

    // Sync the form with the event received. This happens when the form received a broadcasted
    // event
    useEffect(() => {
        if (syncEvent) {
            const { data, type } = syncEvent;
            if (type === UPDATE_PROPOSAL_SUCCESS) {
                initialize(proposal);
            } else if (type === CONFIRM_ADDENDUM_SUCCESS) {
                const addendum = data;
                const index = proposal.addendums.findIndex((add) => add.id === addendum.id);
                if (index !== -1) {
                    change(`addendums[${index}].addendumConfirms`, addendum.addendumConfirms);
                }
            } else if (type === UPDATE_QUESTIONNAIRE_RESPONSE) {
                const questionnaireResponse = data;
                const questionnaireId = questionnaireResponse.questionnaire_id;
                const index = proposal.questionnaires.findIndex((q) => q.id === questionnaireId);
                if (index !== -1) {
                    change(`questionnaires[${index}].questionnaireResponse`, questionnaireResponse);
                }
            }
        }
    }, [syncEvent?.key]); // eslint-disable-line react-hooks/exhaustive-deps

    // This component doesn't render anything
    return null;
};

ProposalCreateNavSync.propTypes = {
    change: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    proposal: PropTypes.object.isRequired,
};
