import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { change } from 'redux-form';

import { statusTypes } from '@og-pro/shared-config/requisitions';

import { getRequisitionFormValues } from './selectors';
import { getIsDeleting, getRequisitionsBasePath } from '../selectors';
import { RequisitionsCreateHeader } from '../RequisitionsCreateHeader';
import { RequisitionsCreateForms } from '../RequisitionsCreateForms';
import RequisitionsCreateNavButtons from '../RequisitionsCreateNavButtons';
import { BudgetCheckAlertModal } from '../../BudgetCheckAlertModal';
import { useRequisitionBudgetCheckOnLoad, useRequisitionSocket } from '../../hooks';
import { RequisitionApprovalModal } from '../../RequisitionApprovalModal';
import connectData from '../../../ConnectData';
import {
    loadRequisition,
    resetRequisition,
    shouldLoadRequisition,
    submitRequisition as submitRequisitionAction,
    updateRequisition,
} from '../../../../actions/requisitions';
import { LoadingError, LoadingSpinner, Main, PageTitle } from '../../../../components';
import {
    getRequisitionIsCopying,
    getRequisitionIsLoading,
    getRequisitionIsUpdating,
    getRequisitionJS,
    getRequisitionLoadError,
} from '../../../../selectors/govApp';
import { loadRequisitionExceptionSequences } from '../../../../actions/requisitionsCreate';
import {
    getRequisitionApprovalModalData,
    getRequisitionBudgetCheckAlertModal,
} from '../../../../selectors/govApp/requisitions';
import { formConfig } from '../RequisitionsCreateForms/form';
import { getUserJS } from '../../../selectors';

const { DRAFT } = statusTypes;

const fetchData = (getState, dispatch, location, params) => {
    const promises = [];
    const requisitionId = Number.parseInt(params.requisitionId, 10);
    if (shouldLoadRequisition(getState(), requisitionId)) {
        // Fetch requisition from API when it has already been created
        // Since the form has different URLs we only want to load it once
        promises.push(
            dispatch(loadRequisition(requisitionId)),
            dispatch(loadRequisitionExceptionSequences(requisitionId))
        );
    }
    return Promise.all(promises);
};

export const ConnectedRequisitionsCreate = (props) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const requisitionId = Number.parseInt(props.params.requisitionId, 10);

    const [showFormValidation, setShowFormValidation] = useState(false);
    const showGlobalFormValidation = useCallback(() => setShowFormValidation(true), []);
    const hideGlobalFormValidation = useCallback(() => setShowFormValidation(false), []);

    const requisition = useSelector(getRequisitionJS);
    const user = useSelector(getUserJS);

    const { show: showBudgetCheckModal } = useSelector(getRequisitionBudgetCheckAlertModal);
    const { show: showApprovalModal } = useSelector(getRequisitionApprovalModalData);

    useRequisitionSocket(requisitionId);
    useRequisitionBudgetCheckOnLoad(requisition);

    useEffect(() => {
        // this runs when component unmounts
        return () => {
            dispatch(resetRequisition());
        };
    }, []);

    const isLoading = useSelector(getRequisitionIsLoading);
    const loadError = useSelector(getRequisitionLoadError);

    const isUpdating = useSelector(getRequisitionIsUpdating);
    const isDeleting = useSelector(getIsDeleting);
    const isCopying = useSelector(getRequisitionIsCopying);

    const formValues = useSelector(getRequisitionFormValues);
    const requisitionsPath = useSelector(() => getRequisitionsBasePath(props));

    const submitHandler = (submitData) => {
        return dispatch(
            submitRequisitionAction(requisition.id, submitData, {
                onSuccess: () => props.router.push(`${requisitionsPath}/${requisition.id}`),
            })
        );
    };

    const updateHandler = (isSubmitting) => (submitData) => {
        return dispatch(
            updateRequisition(requisition.id, formValues, {
                budgetCheckAfterSave: !isSubmitting,
                onSuccess: () => {
                    // Hack: On save, the stored form values change and as a result we need to
                    // re-validate the form data. `redux-form` does not run the validation function
                    // when the props passed in to it change. So in order to force the validation to
                    // run, we do this hack which changes a token value in the form data that is not
                    // actually used in order to trigger a validation.
                    // Specifically this is needed when there's an update to the
                    // `exception_sequence_id` field that causes an auto-save and can introduce new
                    // form fields to the Additional Information section that need to be completed.
                    // Reference: https://stackoverflow.com/a/51065315
                    dispatch(change(formConfig.form, '_validationHack', Date.now()));
                    if (isSubmitting) {
                        return submitHandler(submitData);
                    }
                },
                successMessage: isSubmitting ? null : 'Request saved',
            })
        );
    };

    const saveRequisition = useCallback(updateHandler(false), [requisition?.id, formValues]);
    const submitRequisition = useCallback(updateHandler(true), [requisition?.id, formValues]);

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadError || !requisition) {
        return <LoadingError error={loadError} useOpenGovStyle />;
    }

    // Prevent form from being entered when requisition is no longer in draft
    if (requisition.status !== DRAFT) {
        return <LoadingError error="Request is no longer in draft" useOpenGovStyle />;
    }

    const isCurrentUserCreator = requisition.creator.id === user.id;

    const isDisabled = isUpdating || isDeleting || isCopying;

    const renderRequisitionsCreate = () => {
        return (
            <Main className={styles.pageContainer}>
                <RequisitionsCreateForms
                    disabled={isDisabled}
                    isCurrentUserCreator={isCurrentUserCreator}
                    onSubmit={submitRequisition}
                    saveRequisition={saveRequisition}
                    showFormValidation={showFormValidation}
                />
                <RequisitionsCreateNavButtons
                    disabled={isDisabled || !isCurrentUserCreator}
                    hideGlobalFormValidation={hideGlobalFormValidation}
                    saveRequisition={saveRequisition}
                    showGlobalFormValidation={showGlobalFormValidation}
                    submitRequisition={submitRequisition}
                />
                {showBudgetCheckModal && <BudgetCheckAlertModal open={showBudgetCheckModal} />}
                {showApprovalModal && <RequisitionApprovalModal open={showApprovalModal} />}
            </Main>
        );
    };

    return (
        <>
            <PageTitle title="Create Request" />
            <div className={styles.page}>
                <RequisitionsCreateHeader
                    disabled={isDisabled || !isCurrentUserCreator}
                    requestId={requisition.identifier}
                    saveRequisition={saveRequisition}
                    showFormValidation={showFormValidation}
                    showGlobalFormValidation={showGlobalFormValidation}
                    status={requisition.status}
                />
                {renderRequisitionsCreate()}
            </div>
        </>
    );
};

ConnectedRequisitionsCreate.propTypes = {
    params: PropTypes.shape({
        requisitionId: PropTypes.string.isRequired,
    }).isRequired,
    router: PropTypes.object.isRequired,
};

export const RequisitionsCreate = compose(
    connectData(fetchData),
    withRouter
)(ConnectedRequisitionsCreate);
