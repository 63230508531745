import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { currencyFormatter } from '../../../helpers';
import { removeDuplicateAccounts } from '../helpers/budgetCheck';

export const BudgetChangedModalContent = ({
    changedAccountSplitPriceItems,
    isOverBudget,
    hasIsOverBudgetChanged,
}) => {
    const styles = require('./index.scss');
    const changeText = hasIsOverBudgetChanged ? 'now' : 'still';
    const statusText = isOverBudget ? 'Over Budget' : 'Available';

    const uniqueChangedAccountSplitPriceItems = removeDuplicateAccounts(
        changedAccountSplitPriceItems
    );

    return (
        <>
            <p>
                This Request is {changeText} {statusText}.
            </p>
            {uniqueChangedAccountSplitPriceItems.length > 0 && (
                <>
                    <p>
                        Since last budget check, the available amount for the following accounts has
                        changed:
                    </p>
                    <ul>
                        {uniqueChangedAccountSplitPriceItems.map((account) => (
                            <li key={account.accountNumber}>
                                Account #{account.accountNumber}:{' '}
                                {currencyFormatter({ value: account.oldAvailableBudgetAmount })}{' '}
                                <i className="fa fa-long-arrow-right" />{' '}
                                {currencyFormatter({ value: account.availableBudgetAmount })}{' '}
                                <i
                                    className={classNames(
                                        'fa',
                                        account.oldAvailableBudgetAmount <
                                            account.availableBudgetAmount && [
                                            'fa-chevron-circle-up',
                                            styles.available,
                                        ],
                                        account.oldAvailableBudgetAmount >
                                            account.availableBudgetAmount && [
                                            'fa-chevron-circle-down',
                                            styles.overBudget,
                                        ]
                                    )}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

BudgetChangedModalContent.propTypes = {
    changedAccountSplitPriceItems: PropTypes.arrayOf(
        PropTypes.shape({
            accountNumber: PropTypes.string.isRequired,
            availableBudgetAmount: PropTypes.number.isRequired,
            oldAvailableBudgetAmount: PropTypes.number.isRequired,
        })
    ).isRequired,
    hasIsOverBudgetChanged: PropTypes.bool.isRequired,
    isOverBudget: PropTypes.bool.isRequired,
};
