import { listToDict } from '@og-pro/shared-config/helpers';

const fields = ['note', 'tags', 'title'];

export const fieldNames = listToDict(fields);

const validate = (values) => {
    const errors = {};

    if (!values[fieldNames.TITLE]?.trim()) {
        errors[fieldNames.TITLE] = 'Required';
    }

    if (!values[fieldNames.TAGS]) {
        errors[fieldNames.TAGS] = 'Required';
    }

    return errors;
};

export const formConfig = {
    validate,
};
