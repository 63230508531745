import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    BaseSectionsNavNextButton,
    BaseSectionsNavBackNextButtons,
} from '../../../../../../../components/SDv2/ProjectSectionsNav';
import {
    NavScrollContainer,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateProcessInformationNav } from '../../components/ProcessInformationNav';
import { SDv2EditorLayout } from '../../../../../../../components/SDv2/Layout';
import { PROCESS_INFORMATION_SECTIONS_TITLES } from '../../../constants';
import { TemplateEditV2NavContext, TemplateEditV2FunctionsContext } from '../../../context';
import { TemplateEditV2DocumentExportSettingsForm } from '../../components/forms/DocumentExportSettings';
import { TemplateEditV2GeneralPropertiesForm } from '../../components/forms/GeneralProperties';
import { TemplateEditV2SealedBidSettingsForm } from '../../components/forms/SealedBidSettings';
import { TemplateEditV2SummaryAndBackgroundForm } from '../../components/forms/SummaryAndBackground';
import { TemplateEditV2TimelineForm } from '../../components/forms/Timeline';
import { TemplateEditV2DocumentBuilderSettingsForm } from '../../components/forms/DocumentBuilderSettings';
import { getProcessInformationSections } from '../../../helpers';
import { form } from '../../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../../forms/TemplateForm/constants';

const selector = formValueSelector(form);

export const TemplateEditV2ProcessInformation = () => {
    const { activeSectionId, setActiveSectionId } = useContext(TemplateEditV2NavContext);
    const { templateProject, updateTemplateIfDirty, buildRouteFunction, router } = useContext(
        TemplateEditV2FunctionsContext
    );
    const useExternalDocument = useSelector((state) =>
        selector(state, formFieldNames.USE_EXTERNAL_DOCUMENT)
    );
    const eSignExternalAgreementEnabled = useFlags(FLAGS.ENABLE_E_SIGN_EXTERNAL_AGREEMENT);

    const sections = getProcessInformationSections({
        eSignExternalAgreementEnabled,
        useExternalDocument,
        templateProject,
        setActiveSectionId: (i) => {
            updateTemplateIfDirty();
            setActiveSectionId(i);
        },
        activeSectionId,
    });
    const showUploadDocument = useExternalDocument && eSignExternalAgreementEnabled;

    const headerTitles = PROCESS_INFORMATION_SECTIONS_TITLES(
        eSignExternalAgreementEnabled,
        useExternalDocument
    );
    return (
        <SDv2EditorLayout
            content={
                [
                    <TemplateEditV2GeneralPropertiesForm />,
                    <TemplateEditV2SummaryAndBackgroundForm />,
                    <TemplateEditV2TimelineForm />,
                    ...(eSignExternalAgreementEnabled
                        ? [<TemplateEditV2DocumentBuilderSettingsForm />]
                        : []),
                    <TemplateEditV2DocumentExportSettingsForm />,
                    <TemplateEditV2SealedBidSettingsForm />,
                ][activeSectionId]
            }
            footer={
                <BaseSectionsNavBackNextButtons
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(
                                    buildRouteFunction(
                                        showUploadDocument ? 'upload-document' : 'document-builder'
                                    )
                                );
                            }}
                        >
                            {showUploadDocument ? 'Upload Document' : 'Document Builder'}
                        </BaseSectionsNavNextButton>
                    }
                    sections={sections}
                />
            }
            header={
                <SectionHeaderViewTitleOnly
                    description={headerTitles[activeSectionId]?.description}
                    title={headerTitles[activeSectionId]?.title}
                />
            }
            navigation={
                <NavScrollContainer>
                    <TemplateProcessInformationNav />
                </NavScrollContainer>
            }
            showSharedTextareaToolbar={activeSectionId === 1}
        />
    );
};
