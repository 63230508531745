import { listToDict } from '@og-pro/shared-config/helpers';

const actionLabels = [
    'save',
    'submit',
    'approve',
    'onHold',
    'takeOffOnHold',
    'copyRequest',
    'deleteRequest',
    'cancelRequest',
    'sendReminder',
];
export const actionLabelNames = listToDict(actionLabels);
