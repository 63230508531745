/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Box, Button, Grid, ReduxFormTextField, Typography } from '@og-pro/ui';
import {
    Download as DownloadIcon,
    Upload as UploadIcon,
    DeleteOutline as DeleteOutlineIcon,
    BookmarkBorder as BookmarkBorderIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { usesDocxHeadingFormatters } from '../../../selectors';
import { ProgressBar } from '../../../../../components';
import { limitTextLength } from '../../../../../Forms/normalizers';
import { Dropzone } from './Dropzone';
import { DocxTemplateFormFieldset } from './Fieldset';
import { HEADING_FORMATTERS, HEADING_FORMATTERS_KEYS, TITLE } from './constants';
import { validate } from './validate';
import { parseHeadingFormatter } from '../../../../../helpers/docxHeadingFormatters';
import { ALLOWED_VARIABLES } from '../../../../../constants/docxHeaderFormatters';
import { updateDocxTemplateFile } from '../../../../../actions/templatesAdmin';
import { HeadingFormatterAllowedVariables } from './HeadingFormatterAllowedVariables';

const PLACEHOLDER =
    `${ALLOWED_VARIABLES.NUMBER}.${ALLOWED_VARIABLES.TAB}${ALLOWED_VARIABLES.TITLE}`.toUpperCase();

const initialUploadState = {
    uploading: false,
    progress: 0,
    uploadError: null,
    uploadFileName: null,
    file: null,
};

// this is a dynamic form rendered in a list
// the 'form' prop comes as a prop specified when calling this component
export const WordTemplatesListForm = reduxForm({
    enableReinitialize: true,
    validate,
})(({
    actionsProps,
    showActions,
    disabled,
    docxTemplate,
    form,
    handleSubmit,
    submitting,
    pristine,
    onClose,
}) => {
    const dispatch = useDispatch();
    const [uploadState, setUploadState] = useState(initialUploadState);
    const headingFormattersValues = useSelector((state) =>
        formValueSelector(form)(state, HEADING_FORMATTERS)
    );
    const usesFormatters = useSelector(usesDocxHeadingFormatters);
    const [show, setShow] = useState(false);
    const styles = require('../shared.scss');

    const onFileDrop = ([file]) => {
        if (file) {
            setUploadState((s) => ({
                ...s,
                file,
            }));
        }
    };

    const onUploadCancel = () => {
        setUploadState(initialUploadState);
    };

    const handleSubmitUploadReplaceSubmit = (values) => {
        setUploadState((s) => ({
            ...s,
            uploading: true,
            progress: 0,
            uploadFileName: values[TITLE],
        }));

        dispatch(
            updateDocxTemplateFile(
                docxTemplate.id,
                uploadState.file,
                { [TITLE]: values[TITLE] },
                (progress) => setUploadState((s) => ({ ...s, progress }))
            )
        )
            .then(() => setUploadState(initialUploadState))
            .catch((error) =>
                setUploadState((s) => ({ ...s, uploading: false, uploadError: error.message }))
            );
    };

    if (uploadState.file) {
        if (uploadState.uploading) {
            return (
                <>
                    <div>{uploadState.uploadFileName}</div>
                    <ProgressBar now={uploadState.progress} />
                </>
            );
        }

        return (
            <form onSubmit={handleSubmit(handleSubmitUploadReplaceSubmit)}>
                <DocxTemplateFormFieldset
                    cancelHandler={onUploadCancel}
                    disabled={disabled}
                    initialValues={{
                        [TITLE]: uploadState.file.name,
                    }}
                    uploadError={uploadState.uploadError}
                />
            </form>
        );
    }
    const { colors } = capitalDesignTokens.foundations;

    return (
        <form onSubmit={handleSubmit}>
            <Grid sm={8} xs={12}>
                {showActions && (
                    <Box display="flex" justifyContent="space-between" mb={3}>
                        <Box display="flex" gap={1}>
                            {!actionsProps.isDefault && (
                                <Button
                                    disabled={actionsProps.uploading || actionsProps.updating}
                                    onClick={() =>
                                        actionsProps.updateDefaultDocxTemplate(actionsProps.id)
                                    }
                                    qaTag="docxTemplates-makeDefault"
                                    size="medium"
                                    startIcon={<BookmarkBorderIcon fontSize="small" />}
                                    sx={{
                                        color: colors.gray800,
                                        borderColor: colors.gray800,
                                        padding: 1,
                                    }}
                                    tooltip="Make default template"
                                    variant="outlined"
                                >
                                    Set As Default
                                </Button>
                            )}
                            <a href={actionsProps.url}>
                                <Button
                                    aria-label="Download Button"
                                    disabled={actionsProps.uploading || actionsProps.updating}
                                    qaTag="docxTemplates-download"
                                    size="medium"
                                    startIcon={<DownloadIcon fontSize="small" />}
                                    sx={{
                                        color: colors.gray800,
                                        padding: 1,
                                    }}
                                    tooltip="Download"
                                >
                                    Download
                                </Button>
                            </a>
                            <Button
                                aria-label="Delete Button"
                                color="error"
                                disabled={
                                    actionsProps.uploading ||
                                    actionsProps.updating ||
                                    actionsProps.isDefault
                                }
                                onClick={() => actionsProps.deleteHandler(actionsProps.id)}
                                qaTag="docxTemplates-delete"
                                size="medium"
                                startIcon={
                                    <DeleteOutlineIcon
                                        fontSize="small"
                                        sx={{ marginTop: '-4px' }}
                                    />
                                }
                                sx={{ padding: 1 }}
                                tooltip={
                                    actionsProps.isDefault
                                        ? 'Please designate a different default template before deleting'
                                        : 'Delete template'
                                }
                            >
                                Delete
                            </Button>
                        </Box>
                        <Box display="flex" gap={1} textAlign="right">
                            <Button
                                onClick={() => onClose()}
                                qaTag="docxTemplates-closeExpandable"
                                sx={{
                                    color: colors.gray800,
                                    padding: 1,
                                }}
                                variant="secondary"
                            >
                                Close
                            </Button>
                            <Button
                                disabled={disabled || submitting || pristine}
                                qaTag="docxTemplates-submitForm"
                                sx={{
                                    padding: 1,
                                }}
                                type="submit"
                                variant="contained"
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                )}
                <Field
                    component={ReduxFormTextField}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Export Document Title"
                    name={TITLE}
                    normalize={limitTextLength(128)}
                    qaTag={`docxTemplates-${TITLE}`}
                    showValidation
                    sx={{ width: '100%', marginBottom: 2 }}
                    type="text"
                    useOpenGovStyle
                />
                <Box>
                    <Typography fontWeight={500}>Primary Export Document File Name</Typography>
                    <Box>
                        <Typography>{docxTemplate.filename}</Typography>
                        <Box>
                            <Dropzone
                                dropHandler={onFileDrop}
                                dropzoneClassName={styles.dropzoneUploadReplacement}
                                updating={disabled}
                            >
                                <Button
                                    color="primary"
                                    qaTag="docxTemplates-uploadReplacement"
                                    size="medium"
                                    sx={{ padding: 1, gap: 0.5 }}
                                >
                                    <UploadIcon fontSize="small" /> Upload Replacement
                                </Button>
                            </Dropzone>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            {usesFormatters && (
                <Box borderTop={`1px solid ${colors.gray200}`} marginTop={3} paddingTop={2}>
                    {!show && (
                        <Box>
                            <Button
                                color="secondary"
                                onClick={() => setShow(true)}
                                qaTag="docxTemplates-showAdvancedSettings"
                                size="medium"
                                startIcon={<KeyboardArrowRightIcon />}
                                sx={{ paddingLeft: 0 }}
                            >
                                Show Advanced Formatting Settings
                            </Button>
                        </Box>
                    )}
                    {show && (
                        <Box>
                            <Box mb={3}>
                                <Button
                                    color="secondary"
                                    onClick={() => setShow(false)}
                                    qaTag="docxTemplates-hideAdvancedSettings"
                                    size="medium"
                                    startIcon={<KeyboardArrowDownIcon />}
                                    sx={{ paddingLeft: 0 }}
                                >
                                    Hide Advanced Formatting Settings
                                </Button>
                            </Box>
                            <Typography fontWeight={500}>Numbered Heading Separators</Typography>
                            <Typography color={colors.gray700} component="p">
                                By default when documents are exported, the section number is
                                separated from the section title by a tab (i.e. Part 1 Project
                                Summary). For projects using auto numbering, there is a period after
                                the number (i.e. 1. Project Summary). If the default approach does
                                not work for this document, you can make changes to how section
                                numbers display next to their corresponding titles by editing the
                                headings below. If the fields are left blank, the default format
                                will be used in the export for all rendered documents using this
                                template.
                            </Typography>
                            <HeadingFormatterAllowedVariables
                                title="Available Formatting Variables:"
                                variables={[
                                    {
                                        variable: ALLOWED_VARIABLES.NUMBER,
                                        subtitle: 'Section number.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.TITLE,
                                        subtitle: 'Section title.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.TAB,
                                        subtitle: 'Inserts one tab character.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.SPACE,
                                        subtitle: 'Inserts one space character.',
                                    },
                                    {
                                        variable: ALLOWED_VARIABLES.NEWLINE,
                                        subtitle: 'Starts a new line.',
                                    },
                                    {
                                        variable: null,
                                        subtitle: 'Punctuation can be used as normal.',
                                    },
                                ]}
                            />
                            <Box>
                                {[1, 2, 3, 4, 5].map((value, index) => (
                                    <Box display="flex" key={index}>
                                        <Box flex={4} mr={8}>
                                            <Field
                                                component={ReduxFormTextField}
                                                disabled={disabled}
                                                hasFeedback={false}
                                                label={`Heading ${value}`}
                                                name={`${HEADING_FORMATTERS}.${HEADING_FORMATTERS_KEYS[index]}`}
                                                normalize={limitTextLength(128)}
                                                placeholder={PLACEHOLDER}
                                                qaTag={`docxTemplates-heading${value}`}
                                                showValidation={false}
                                                sx={{ width: '100%', marginBottom: 2 }}
                                                type="text"
                                                useOpenGovStyle
                                            />
                                        </Box>
                                        <Box flex={2}>
                                            <Typography
                                                color={colors.gray700}
                                                fontSize="small"
                                                fontWeight={500}
                                            >
                                                {index === 0 ? 'Preview' : <>&nbsp;</>}
                                            </Typography>
                                            <Typography color="black" fontSize="small" mt={1}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseHeadingFormatter(
                                                            (headingFormattersValues &&
                                                                headingFormattersValues[
                                                                    HEADING_FORMATTERS_KEYS[index]
                                                                ]) ||
                                                                PLACEHOLDER,
                                                            '1.'.repeat(value),
                                                            `Heading ${value} Title`,
                                                            true
                                                        ),
                                                    }}
                                                />
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </form>
    );
});

WordTemplatesListForm.propTypes = {
    actionsProps: PropTypes.shape({
        deleteHandler: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        isDefault: PropTypes.bool.isRequired,
        updateDefaultDocxTemplate: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        uploading: PropTypes.bool.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    showActions: PropTypes.bool,
    disabled: PropTypes.bool,
    docxTemplate: PropTypes.shape({
        filename: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
};
