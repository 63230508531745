import React from 'react';

import { ReduxFormRadioGroup } from '@og-pro/ui';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';

const { ENUM_OPTIONS, NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const SingleChoicePreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];

    const singleChoiceOptions = customFieldDefinition[ENUM_OPTIONS] || [];

    const singleChoiceOptionsArray = singleChoiceOptions.map((option, index) => ({
        value: option || `Option ${index + 1}`,
        label: option || `Option ${index + 1}`,
    }));

    return (
        <Field
            component={ReduxFormRadioGroup}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="singleChoice_PREVIEW"
            options={singleChoiceOptionsArray}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

SingleChoicePreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
