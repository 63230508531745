import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, Typography } from '@og-pro/ui';
import { Edit as EditIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import { ExceptionStep } from './ExceptionStep';
import { PurchaseOrderStep } from './PurchaseOrderStep';
import { ReviewSequenceStep } from './ReviewSequenceStep';
import { SubmittedStep } from './SubmittedStep';
import { AddStepButton } from './AddStepButton';

export const RequisitionsApprovalHistorySteps = ({ requisition, reviewSequenceSteps }) => {
    const firstStep = reviewSequenceSteps[0];
    const [isEditing, setIsEditing] = useState(false);

    const handleStartEditing = () => setIsEditing(true);
    const handleCancelEditing = () => setIsEditing(false);

    const currentStepIndex = reviewSequenceSteps.findIndex(
        (step) => step.id === requisition.current_step_id
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 2,
                gap: 1,
            }}
        >
            {!isEditing ? (
                firstStep?.allowAdHocSteps &&
                requisition.current_step_id === firstStep.id && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={handleStartEditing}
                            qaTag="edit-requisition"
                            size="small"
                            startIcon={<EditIcon fontSize="small" />}
                            variant="text"
                        >
                            Edit
                        </Button>
                    </Box>
                )
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h3">Edit Workflow</Typography>
                        <Button color="secondary" onClick={handleCancelEditing} variant="text">
                            <CloseIcon fontSize="small" />
                        </Button>
                    </Box>
                    <Typography
                        sx={(theme) => ({ color: theme.palette.text.secondary })}
                        variant="bodySmall"
                    >
                        Add one or multiple steps.
                    </Typography>
                </Box>
            )}
            <ExceptionStep requisition={requisition} />
            <SubmittedStep requisition={requisition} />
            {reviewSequenceSteps.map((reviewSequenceStep, index) => (
                <React.Fragment key={reviewSequenceStep.id}>
                    <ReviewSequenceStep
                        isCurrent={requisition.current_step_id === reviewSequenceStep.id}
                        isEditing={isEditing}
                        reviewSequenceStep={reviewSequenceStep}
                    />
                    {isEditing &&
                        index >= currentStepIndex &&
                        index < reviewSequenceSteps.length - 1 && (
                            <AddStepButton
                                currentStep={reviewSequenceStep}
                                currentStepIndex={index}
                            />
                        )}
                </React.Fragment>
            ))}
            <PurchaseOrderStep requisition={requisition} />
        </Box>
    );
};

RequisitionsApprovalHistorySteps.propTypes = {
    requisition: PropTypes.shape({
        current_step_id: PropTypes.number,
        id: PropTypes.number.isRequired,
    }).isRequired,
    reviewSequenceSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
