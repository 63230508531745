import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { SuggestedVendorContractCard } from './SuggestedVendorContractCard';
import { getEmptyVendorItem, getVendorDataFromContract } from '../../../../utils';

export const SuggestedVendors = ({
    defaultExpanded,
    suggestedVendorHandler,
    suggestedVendorContracts,
}) => {
    const clickHandler = (contract) => {
        const vendor = contract?.contractParty?.vendor;
        if (!vendor) {
            return;
        }

        const vendorData = {
            ...getEmptyVendorItem(),
            ...getVendorDataFromContract(contract),
        };
        return suggestedVendorHandler(vendorData, contract);
    };

    return (
        <Box>
            <Accordion
                defaultExpanded={defaultExpanded}
                sx={{
                    p: 3,
                }}
                variant="arrowAlignmentRight"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: '#ffffff !important',
                        minHeight: 0,
                        p: 0,
                        m: 0,
                        '& .MuiAccordionSummary-content': {
                            padding: 0,
                            margin: 0,
                        },
                        alignItems: 'flex-start',
                    }}
                >
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography
                            color={capitalDesignTokens.semanticColors.foreground.secondary}
                            variant="h4"
                        >
                            Suggested Vendors
                        </Typography>
                        <Typography
                            color={capitalDesignTokens.semanticColors.foreground.secondary}
                            variant="bodySmall"
                        >
                            These vendors are from contracts or solicitations that are related to
                            this request. Add one of these or search to find another.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 1 }}>
                    <Grid container spacing={2}>
                        {suggestedVendorContracts.map((contract) => (
                            <Grid item key={contract.id} lg={4} sm={6} xs={12}>
                                <SuggestedVendorContractCard
                                    contract={contract}
                                    onClick={clickHandler}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

SuggestedVendors.propTypes = {
    defaultExpanded: PropTypes.bool,
    suggestedVendorContracts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    suggestedVendorHandler: PropTypes.func.isRequired,
};
