import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import classnames from 'classnames';
import { arrayMove, Field, formValueSelector } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import {
    fieldTypes,
    fieldTypesValues,
    singleUseFieldTypes,
    requiredEsignatureFieldTypes,
} from '@og-pro/shared-config/signatures';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import { HelpIcon, InputText } from '../../InputText';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { DateTimePicker } from '../../DateTime/DateTimePicker';
import { CDSButton, CDSDropdownButton } from '../../SDv2';
import { MenuItem } from '../../MenuItem/MenuItem';
import {
    fieldNames as signatureFieldnames,
    blockFieldNames,
    fieldsEditableFieldNames,
    stampOptions,
    tooltips,
} from './constants';
import { fieldNames } from '../../../containers/GovApp/ProjectCreate/constants';

const { VALUE, LABEL } = fieldsEditableFieldNames;
const { FIELDS } = blockFieldNames;
const { ESIGNATURE_ENABLED } = signatureFieldnames;

const RenderFieldValue = ({ id, field, fieldValue, disabled, showValidation }) => {
    switch (fieldValue.type) {
        case fieldTypes.DATE: {
            return (
                <Field
                    component={DateTimePicker}
                    disabled={disabled}
                    hasFeedback={false}
                    horizontal
                    id={id}
                    name={`${field}.${VALUE}`}
                    placeholder="MM/D/YYYY"
                    qaTag={`signatureForm-field${fieldValue.type}`}
                    showValidation={showValidation}
                    time={false}
                    timezone={undefined}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.DATE_SIGNED: {
            return (
                <Field
                    component={InputText}
                    disabled // Always disabled because docusign must fill this, not the user.
                    hasFeedback={false}
                    id={id}
                    label={null}
                    name={`${field}.${VALUE}`}
                    placeholder="MM/DD/YYYY"
                    qaTag={`signatureForm-field${fieldValue.type}`}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.ROLE:
        case fieldTypes.NAME:
        case fieldTypes.STRING: {
            return (
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    id={id}
                    label={null}
                    name={`${field}.${VALUE}`}
                    qaTag={`signatureForm-field${fieldValue.type}`}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.STAMP: {
            return (
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    hasFeedback={false}
                    inputId={id}
                    label={null}
                    name={`${field}.${VALUE}`}
                    options={stampOptions}
                    qaTag={`signatureForm-field${fieldValue.type}`}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.SIGNATURE:
        default: {
            return <Box height="30px">&nbsp;</Box>;
        }
    }
};
RenderFieldValue.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    field: PropTypes.string.isRequired,
    fieldValue: PropTypes.object.isRequired,
    showValidation: PropTypes.bool,
};

const FieldsMap = ({ disabled, fields, editLabels, esignature, number, showValidation }) => {
    const styles = require('./index.scss');
    return (
        <>
            {fields.map((field, index) => {
                const fieldValue = fields.get(index);
                const fieldHasNoInput = fieldValue.type === fieldTypes.SIGNATURE;
                const htmlFor = fieldHasNoInput
                    ? undefined
                    : // The + '_input" on type DATE is because the component DateTimePicker from react-widgets adds it to the input ID.
                      `${fieldValue.label}_${index}_${number}${fieldValue.type === fieldTypes.DATE ? '_input' : ''}`;
                return (
                    <Draggable draggableId={field} index={index} key={field}>
                        {(draggableProvided) => (
                            <Box
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                            >
                                <Box className={styles.fieldSet}>
                                    <Box alignItems="center" display="flex">
                                        <Box pr={2}>
                                            <DragIcon
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            sx={{ '& .form-group': { margin: '0px !important' } }}
                                        >
                                            {editLabels && (
                                                <Box>
                                                    <Typography
                                                        className={styles.customInputLabel}
                                                        component="label"
                                                        fontWeight={500}
                                                        htmlFor={`${field}.${LABEL}.${number}`}
                                                    >
                                                        {fieldTypesValues[fieldValue.type].label}{' '}
                                                        Label
                                                        {tooltips[fieldValue.type] && (
                                                            <HelpIcon
                                                                className={styles.helpIcon}
                                                                placement="right"
                                                                tooltip={tooltips[fieldValue.type]}
                                                                useOpenGovStyle
                                                            />
                                                        )}
                                                    </Typography>
                                                    <Field
                                                        component={InputText}
                                                        disabled={disabled}
                                                        hasFeedback={false}
                                                        id={`${field}.${LABEL}.${number}`}
                                                        label={null}
                                                        name={`${field}.${LABEL}`}
                                                        qaTag={`signatureForm-fieldLabel${fieldValue.type}`}
                                                        showValidation={showValidation}
                                                        useOpenGovStyle
                                                    />
                                                </Box>
                                            )}
                                            {!editLabels && (
                                                <Box>
                                                    <Box
                                                        className={classnames(
                                                            styles.fieldTooltip,
                                                            styles.customInputLabel
                                                        )}
                                                    >
                                                        <Typography
                                                            component={
                                                                fieldHasNoInput ? 'div' : 'label'
                                                            }
                                                            fontWeight={500}
                                                            htmlFor={htmlFor}
                                                        >
                                                            {fieldValue.label}
                                                        </Typography>
                                                        {tooltips[fieldValue.type] && (
                                                            <HelpIcon
                                                                className={styles.helpIcon}
                                                                placement="right"
                                                                tooltip={tooltips[fieldValue.type]}
                                                                useOpenGovStyle
                                                            />
                                                        )}
                                                    </Box>
                                                    <RenderFieldValue
                                                        disabled={disabled}
                                                        field={field}
                                                        fieldValue={fieldValue}
                                                        id={`${fieldValue.label}_${index}_${number}`}
                                                        showValidation={showValidation}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        {!(
                                            esignature &&
                                            requiredEsignatureFieldTypes.includes(fieldValue.type)
                                        ) && (
                                            <Box alignSelf="flex-start" ml={2} textAlign="center">
                                                <CDSButton
                                                    onClick={() => fields.remove(index)}
                                                    qaTag="signatureForm-removeField"
                                                    size="small"
                                                    variant="text"
                                                >
                                                    <CloseIcon sx={{ fontSize: 'inherit' }} />
                                                    <span className="visually-hidden">
                                                        Remove Field
                                                    </span>
                                                </CDSButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};
FieldsMap.propTypes = {
    disabled: PropTypes.bool,
    esignature: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    editLabels: PropTypes.bool,
    number: PropTypes.number,
    showValidation: PropTypes.bool,
};

const AddFieldDropdown = ({ availableOptions, disabled, onAddField }) => {
    const styles = require('./index.scss');

    return (
        <CDSDropdownButton
            className={styles.addField}
            disabled={disabled}
            qaTag="signatureForm-addField"
            size="small"
            title={
                <>
                    <i className="fa fa-plus" /> Add Field
                </>
            }
            variant="text"
        >
            {availableOptions.map(({ type, label }) => (
                <MenuItem
                    key={type}
                    onClick={() => onAddField(type)}
                    qaTag={`signatureForm-addField${type}`}
                >
                    {label}
                </MenuItem>
            ))}
        </CDSDropdownButton>
    );
};
AddFieldDropdown.propTypes = {
    availableOptions: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    onAddField: PropTypes.func.isRequired,
};

const EditBlockButtons = ({ onCancel, onDone }) => {
    const styles = require('./index.scss');
    return (
        <>
            <CDSButton
                className={styles.textSmall}
                noPadding
                onClick={onCancel}
                qaTag="signatureForm-editBlock-cancel"
                size="small"
                variant="text"
            >
                Cancel
            </CDSButton>
            <CDSButton
                className={classnames(styles.doneAction, styles.textSmall)}
                noPadding
                onClick={onDone}
                qaTag="signatureForm-editBlock-done"
                size="small"
                variant="text"
            >
                Done
            </CDSButton>
        </>
    );
};
EditBlockButtons.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
};

export const SignatureField = ({
    blockFieldName,
    disabled,
    fields,
    form,
    number,
    showValidation,
}) => {
    const [editBlock, setEditBlock] = useState(false);
    const [showLabelError, setShowLabelError] = useState(false);
    const [fieldsSnapshot, setFieldsSnapshot] = useState(null);
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const { SIGNATURES } = fieldNames;
    const { EMAIL, SIGNING_ORDER } = blockFieldNames;

    const signatures = useSelector((state) => formValueSelector(form)(state, SIGNATURES));
    const blocks = signatures?.length === 1 ? signatures[0].signaturesBlocks : [];
    const totalAmountOfBlocks = blocks.flat().filter((obj) => obj.styling === 'default').length;
    const esignatureEnabled = signatures[0][ESIGNATURE_ENABLED];

    // This useEffect is for the scenarios where the user edits stuff while in wet signature mode
    // and then switches to e-signature mode.
    useEffect(() => {
        if (esignatureEnabled) {
            const fieldsData = fields.getAll();

            // If the signature block has e-signature enabled, it must not have a STAMP field.
            fieldsData.forEach((field, index) => {
                if (field.type === fieldTypes.STAMP) {
                    fields.remove(index);
                }
            });

            // If the signature block has e-signature enabled, it must have a name, date signed, and signature field.
            const hasNameField = fieldsData.some((field) => field.type === fieldTypes.NAME);
            const hasDateSignedField = fieldsData.some(
                (field) => field.type === fieldTypes.DATE_SIGNED
            );
            const hasSignatureField = fieldsData.some(
                (field) => field.type === fieldTypes.SIGNATURE
            );
            // unshift => adds the element at the first position of the array
            if (!hasDateSignedField) {
                fields.unshift(fieldTypesValues[fieldTypes.DATE_SIGNED]);
            }
            if (!hasNameField) {
                fields.unshift(fieldTypesValues[fieldTypes.NAME]);
            }
            if (!hasSignatureField) {
                fields.unshift(fieldTypesValues[fieldTypes.SIGNATURE]);
            }
        }
    }, [esignatureEnabled]);

    // if the users leaves without setting a label for a field, we set the label to the default value
    useEffect(() => {
        const fieldsData = fields.getAll();
        const updatedFields = fieldsData.map((field) => {
            if (!field.label) {
                return {
                    ...field,
                    label: fieldTypesValues[field.type].label, // Set the default label
                };
            }
            return field; // Keep the field unchanged if it has a label
        });
        fields.removeAll();
        updatedFields.forEach((updatedField) => fields.push(updatedField));
    }, []);

    const availableOptions = useMemo(() => {
        const fieldsData = fields.getAll();
        const usedTypes = fieldsData.map((field) => field.type);

        return Object.values(fieldTypesValues).filter((fieldType) => {
            return (
                fieldType.type !== fieldTypes.HEADER &&
                (!usedTypes.includes(fieldType.type) ||
                    // If the signature block has e-signature enabled, STAMP fields can't be added
                    !singleUseFieldTypes.includes(fieldType.type)) &&
                !(esignatureEnabled && fieldType.type === fieldTypes.STAMP)
            );
        });
    }, [fields.length, esignatureEnabled]);

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleAddField = (fieldType) => {
        let newField = fieldTypesValues[fieldType];
        if ([fieldTypes.DATE, fieldTypes.STRING].includes(newField.type)) {
            newField = { ...newField, label: '' };
        }
        fields.push(newField);
        setEditBlock(true);
    };

    const handleCancel = () => {
        if (fieldsSnapshot) {
            fields.removeAll();
            fieldsSnapshot.forEach((field) => fields.push(field));
        }
        setFieldsSnapshot(null);
        setShowLabelError(false);
        setEditBlock(false);
    };

    const handleDone = () => {
        // verify if any field has the label missing
        const fieldsData = fields.getAll();
        const emptyLabel = fieldsData.some((field) => !field.label);
        if (emptyLabel) {
            setShowLabelError(true);
            return;
        }
        setFieldsSnapshot(null);
        setShowLabelError(false);
        setEditBlock(false);
    };

    return (
        <Box>
            <Box alignItems="center" display="flex" mb={2}>
                <Box flex={1}>
                    <Typography fontWeight={500}>
                        {editBlock && 'Edit '}Signature Block {number}
                    </Typography>
                </Box>
            </Box>
            <Typography
                className={classnames(
                    styles.description,
                    styles.signatureDescriptionMargin,
                    styles.descriptionMarginBottom
                )}
            >
                This signature block corresponds to one person&apos;s signature.
            </Typography>
            {esignatureEnabled && !editBlock && (
                <Box display="flex" gap={2}>
                    <Box flex={2} width="90%">
                        <Box alignItems="center" display="flex">
                            <Typography
                                className={styles.customInputLabel}
                                component="label"
                                fontWeight={500}
                                htmlFor={`email-block-${number}`}
                            >
                                Email Address
                            </Typography>
                            <HelpIcon
                                className={styles.tooltipIcon}
                                placement="top"
                                tooltip="This is required in order to implement e-signatures. It will not display in the
                                document export, but will be used to route the final contract packet for signature."
                                useOpenGovStyle
                            />
                        </Box>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            id={`email-block-${number}`}
                            label={null}
                            name={`${blockFieldName}.${EMAIL}`}
                            qaTag="signatureForm-fieldEmail"
                            showValidation={showValidation}
                            useOpenGovStyle
                        />
                    </Box>
                    <Box flex={1} width="10%">
                        <Box alignItems="center" display="flex">
                            <Typography
                                className={styles.customInputLabel}
                                component="label"
                                fontWeight={500}
                                htmlFor={`signing-order-${number}`}
                            >
                                Signing Order
                            </Typography>
                            <HelpIcon
                                className={styles.tooltipIcon}
                                placement="top"
                                tooltip='Multiple signatories can be notified at the same time. For example, you may set "2"
                                    for several signatories to notify them all second.'
                                useOpenGovStyle
                            />
                        </Box>
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            hasFeedback={false}
                            inputId={`signing-order-${number}`}
                            label={null}
                            name={`${blockFieldName}.${SIGNING_ORDER}`}
                            options={Array.from({ length: totalAmountOfBlocks }, (v, i) => ({
                                label: i + 1,
                                value: i + 1,
                            }))}
                            qaTag="signatureForm-fieldSigningOrder"
                            showValidation={showValidation}
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockFieldsList"
                    isDropDisabled={disabled}
                    type={FIELDS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap
                                {...{
                                    disabled,
                                    editLabels: editBlock,
                                    esignature: esignatureEnabled,
                                    showValidation: editBlock ? showLabelError : showValidation,
                                    fields,
                                    number,
                                }}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Box display="flex" justifyContent="space-between">
                <Box>
                    {!editBlock ? (
                        <CDSButton
                            className={classnames(styles.action, styles.textSmall, styles.margin)}
                            noPadding
                            onClick={() => {
                                setFieldsSnapshot(fields.getAll());
                                setEditBlock(true);
                            }}
                            qaTag="signatureForm-editBlock"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-pencil" /> Edit Block
                        </CDSButton>
                    ) : (
                        <AddFieldDropdown
                            availableOptions={availableOptions}
                            disabled={disabled}
                            onAddField={handleAddField}
                        />
                    )}
                </Box>

                <Box display="flex" gap={1}>
                    {editBlock && <EditBlockButtons onCancel={handleCancel} onDone={handleDone} />}
                </Box>
            </Box>
        </Box>
    );
};

SignatureField.propTypes = {
    blockFieldName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    number: PropTypes.number,
    showValidation: PropTypes.bool,
};
