import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@og-pro/ui';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { Button } from '../../Button';
import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';
import { useContractingDocument } from '../../../hooks';

const { CONTRACT } = projectTypesDict;

export const CreateDocumentButton = ({ contract, deletingContract, updatingContract }) => {
    const dispatch = useDispatch();
    const hasContractingDocument = useContractingDocument();

    const handleCreateContractDocumentClick = useCallback(() => {
        dispatch(
            showProjectCreateModal({
                createData: {
                    contact_id: contract.contact_id,
                    contractId: contract.id,
                    department_id: contract.department_id,
                    procurement_contact_id: contract.procurement_contact_id,
                },
                isDocBuilder: true,
                steps: [5],
                type: CONTRACT,
            })
        );
    }, []);

    if (!hasContractingDocument) {
        return null;
    }

    return (
        <Box flex={1} textAlign="right">
            <Button
                bsSize="sm"
                bsStyle="primary"
                disabled={deletingContract || updatingContract}
                onClick={handleCreateContractDocumentClick}
                qaTag="connectedContract-createContractDocument"
            >
                <i className="fa fa-plus" /> Create Document
            </Button>
        </Box>
    );
};

CreateDocumentButton.propTypes = {
    contract: PropTypes.shape({
        contact_id: PropTypes.number,
        department_id: PropTypes.number,
        id: PropTypes.number.isRequired,
        procurement_contact_id: PropTypes.number,
        project: PropTypes.object,
        status: PropTypes.string.isRequired,
    }).isRequired,
    deletingContract: PropTypes.bool,
    updatingContract: PropTypes.bool,
};
