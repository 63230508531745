import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { useDispatch } from 'react-redux';

import { TemplateEditV2NavContext } from '../../../context';
import { clearImportedSections } from '../../../../../../../actions/templatesAdmin';

export const ImportSectionMenuFooter = ({ disabled }) => {
    const { setShowAiImporterDialog } = useContext(TemplateEditV2NavContext);
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor: capitalDesignTokens.semanticColors.background.primary,
                borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                bottom: 0,
                display: 'flex',
                gap: 1,
                height: '87px',
                justifyContent: 'flex-end',
                left: 0,
                marginBottom: -3,
                marginX: -3,
                padding: 3,
                position: 'sticky',
                right: 0,
                zIndex: 1,
            }}
        >
            <Button
                color="error"
                disabled={disabled}
                onClick={() => {
                    dispatch(clearImportedSections());
                }}
                qaTag="importSectionMenu-clearSections"
                variant="text"
            >
                Clear Sections
            </Button>
            <Button
                disabled={disabled}
                onClick={() => setShowAiImporterDialog(true)}
                qaTag="importSectionMenu-importTemplate"
                variant="outlined"
            >
                Import Template
            </Button>
        </Box>
    );
};

ImportSectionMenuFooter.propTypes = {
    disabled: PropTypes.bool,
};
