import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { AgGridReact } from '@og-pro/ui';

import { CACHE_BLOCK_SIZE, DEFAULT_PAGE_SIZE, defaultColDef } from './constants';

export const VendorsTable = ({ columns, onGridReady }) => {
    const getRowId = useMemo(() => (row) => row.data.id, []);

    return (
        <AgGridReact
            cacheBlockSize={CACHE_BLOCK_SIZE}
            columnDefs={columns.map((column) =>
                omit(column, ['isPublic', 'subscriberViewOnly', 'defaultHidden'])
            )}
            defaultColDef={defaultColDef}
            getRowHeight={() => 52}
            getRowId={getRowId}
            onGridReady={onGridReady}
            pagination
            paginationPageSize={DEFAULT_PAGE_SIZE}
            paginationPageSizeSelector={false}
            rowModelType="serverSide"
        />
    );
};

VendorsTable.propTypes = {
    columns: PropTypes.array.isRequired,
    onGridReady: PropTypes.func.isRequired,
};
