import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import { fieldNames } from './constants';
import { RequestTypesSection } from './RequestTypesSection';

const { REQUEST_TYPE_GROUP_SEQUENCES } = fieldNames;

export const RequisitionGroupRequestTypesSection = ({ change, disabled, showFormValidation }) => {
    return (
        <FieldArray
            change={change}
            component={RequestTypesSection}
            disabled={disabled}
            name={REQUEST_TYPE_GROUP_SEQUENCES}
            showFormValidation={showFormValidation}
        />
    );
};

RequisitionGroupRequestTypesSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
