import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { approvalStatusesDict } from '@og-pro/shared-config/approval';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { BaseApprovalsModal } from './BaseApprovalsModal';
import {
    ApproveModalBody,
    EditDecisionModalBody,
    EditNoteModalBody,
    MarkApprovedModalBody,
    RejectModalBody,
    ResendApprovalModalBody,
    ReviewerReminderEmailModalBody,
} from './ModalBody';
import { getProjectJS } from '../../../../containers/GovApp/App/sharedSelectors';

const { APPROVED, PENDING, REJECTED } = approvalStatusesDict;

const mapStateToProps = (state) => {
    return {
        project: getProjectJS(state),
    };
};

export class ConnectedApprovalsModal extends PureComponent {
    static propTypes = {
        approval: PropTypes.object.isRequired,
        approveHandler: PropTypes.func.isRequired,
        getFlag: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        rejectHandler: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        showModal: PropTypes.func.isRequired,
        submitError: PropTypes.string,
        type: PropTypes.string.isRequired,
        updateHandler: PropTypes.func.isRequired,
    };

    getModalTitle() {
        const isContractPackageEnabled = this.props.getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
        const isContractProject = this.props.project.type === projectTypesDict.CONTRACT;
        const title = isContractProject && isContractPackageEnabled ? 'Contract Packet' : 'Project';
        switch (this.props.type) {
            case 'approve':
                return `Approve ${title}`;
            case 'reject':
                return `Reject ${title}`;
            case 'edit':
                return `Revise ${title} Approval`;
            case 'comment':
                return 'Edit Approval Note';
            case 'markApproved':
                return `Mark ${title} Approved`;
            case 'reviewerReminder':
                return this.props.approval.reReview
                    ? 'Send Request Another Review Email'
                    : 'Send Reminder Email';
            case 'resend':
                return 'Resend Approval';
            default:
                return null;
        }
    }

    renderModalBody() {
        const {
            approval: {
                comment,
                id,
                reReview,
                reminderHandler,
                reviewStepId,
                status,
                user: { displayName },
            },

            approveHandler,
            hideModal,
            rejectHandler,
            showModal,
            submitError,
            type,
            updateHandler,
            getFlag,
        } = this.props;

        const defaultProps = {
            hideModal,
            note: comment,
            submitError,
        };

        const isContractPackageEnabled = getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
        const isContractProject = this.props.project.type === projectTypesDict.CONTRACT;
        const title = isContractProject && isContractPackageEnabled ? 'Contract Packet' : 'Project';

        const pending = status === PENDING;
        const approved = status === APPROVED;
        const rejected = status === REJECTED;

        switch (type) {
            case 'approve':
                return (
                    <ApproveModalBody
                        {...defaultProps}
                        approveHandler={approveHandler}
                        approved={approved}
                        title={title}
                    />
                );
            case 'reject':
                return (
                    <RejectModalBody
                        {...defaultProps}
                        rejectHandler={rejectHandler}
                        rejected={rejected}
                    />
                );
            case 'edit':
                return (
                    <EditDecisionModalBody
                        {...defaultProps}
                        approved={approved}
                        showModal={showModal}
                    />
                );
            case 'comment':
                return <EditNoteModalBody {...defaultProps} submitHandler={updateHandler} />;
            case 'markApproved':
                return (
                    <MarkApprovedModalBody
                        {...defaultProps}
                        approveHandler={approveHandler}
                        approved={approved}
                        name={displayName}
                        title={title}
                    />
                );
            case 'reviewerReminder':
                return (
                    <ReviewerReminderEmailModalBody
                        approvalId={id}
                        hideModal={hideModal}
                        reReview={reReview}
                        reminderHandler={reminderHandler}
                        reviewStepId={reviewStepId}
                    />
                );
            case 'resend':
                return (
                    <ResendApprovalModalBody
                        {...defaultProps}
                        approvalId={id}
                        name={displayName}
                        pending={pending}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { show, hideModal } = this.props;

        return (
            <BaseApprovalsModal hideModal={hideModal} showModal={show} title={this.getModalTitle()}>
                {this.renderModalBody()}
            </BaseApprovalsModal>
        );
    }
}

export const ApprovalsModal = compose(
    connect(mapStateToProps),
    withFlags()
)(ConnectedApprovalsModal);
