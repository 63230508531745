import React from 'react';
import { Box, Divider, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const ImportedSectionsDivider = () => {
    return (
        <Box
            sx={{
                paddingY: 2,
            }}
        >
            <Divider
                sx={{
                    '&.MuiDivider-root.MuiDivider-withChildren .MuiDivider-wrapper': {
                        paddingX: 1,
                    },
                    '&.MuiDivider-root': {
                        '&::before, &::after': {
                            borderColor: capitalDesignTokens.componentColors.primaryIdle,
                        },
                    },
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        color: capitalDesignTokens.semanticColors.foreground.tertiary,
                        fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                    }}
                >
                    New sections added
                </Typography>
            </Divider>
        </Box>
    );
};
