import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { projectClosedSubstatusesDict } from '@og-pro/shared-config/projects';

import { OTHER, OTHER_TEXT } from '../constants';
import { validate } from '../validate';
import { InputText, SearchSelect } from '../../../../components';

const {
    CANCELED,
    NO_RESPONSES,
    NO_VENDOR_SELECTED,
    OTHER: OTHER_SUBSTATUS,
    HANDLED_EXTERNALLY,
} = projectClosedSubstatusesDict;

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        const { fieldName, isIntake } = props;

        // If `OTHER` is selected, take the value from `CLOSE_OUT_REASON_EXTRA` instead
        const fieldValueToStore =
            rawData[fieldName] === OTHER ? rawData[OTHER_TEXT] : rawData[fieldName];

        // If the form is being used for the close out project modal, we need to update the project's closed substatus as well
        if (fieldName === 'closeOutReason') {
            // For intakes, "Project canceled", "No bids", and "No vendor selected" should be mapped to "Other" substatus
            // The new version of this modal will only have "Remainder of project handled externally of OpenGov Procurement" and "Other" as options
            const substatusMapping = {
                'Project canceled': isIntake ? OTHER_SUBSTATUS : CANCELED,
                'No bids': isIntake ? OTHER_SUBSTATUS : NO_RESPONSES,
                'No vendor selected': isIntake ? OTHER_SUBSTATUS : NO_VENDOR_SELECTED,
                'Remainder of project handled externally of OpenGov Procurement':
                    HANDLED_EXTERNALLY,
                [OTHER]: OTHER_SUBSTATUS,
            };

            const substatus = substatusMapping[rawData[fieldName]] || OTHER_SUBSTATUS;
            props.onSubmit({ [fieldName]: fieldValueToStore, closedSubstatus: substatus });
        } else {
            // Call the submit handler with the formatted data
            props.onSubmit({ [fieldName]: fieldValueToStore });
        }
    };

    return {
        fieldValue: formValueSelector(props.form)(state, props.fieldName),
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    validate,
};

// @connect
// @reduxForm
class ConnectedMultiChoiceWithOtherForm extends Component {
    static propTypes = {
        characterLimit: PropTypes.number,
        disabled: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldValue: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        info: PropTypes.string.isRequired,
        note: PropTypes.node,
        options: PropTypes.array.isRequired,
        otherLabel: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            characterLimit,
            disabled,
            fieldName,
            fieldValue,
            handleSubmit,
            info,
            note,
            options,
            otherLabel,
        } = this.props;

        return (
            <form className={this.styles.container} onSubmit={handleSubmit}>
                <p className={`${this.styles.text} text-muted`}>{info}</p>
                <Field
                    aria-label="Select an option"
                    component={SearchSelect}
                    disabled={disabled}
                    name={fieldName}
                    options={options}
                    placeholder="Select an option"
                    qaTag="confirmationModal-options"
                />
                {fieldValue === OTHER && (
                    <Field
                        characterLimit={characterLimit}
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label={otherLabel || 'Other'}
                        name={OTHER_TEXT}
                        placeholder="Enter text"
                        type="text"
                    />
                )}
                {note && (
                    <p className="text-center">
                        <strong>Please Note:</strong> {note}
                    </p>
                )}
            </form>
        );
    }
}

export const MultiChoiceWithOtherForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedMultiChoiceWithOtherForm);
