import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { Box } from '@og-pro/ui';
import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';
import { tokens } from '@opengov/capital-style';

import { VendorLists } from '../../../../components/vendors';
import { getVendorLists, getVendorListsLoading } from '../selectors';
import VendorListModal from '../VendorListModal';
import { Button, LoadingSpinner } from '../../../../components';
import { deleteVendorList, loadVendorLists } from '../../../../actions/vendorList';
import { VendorManagementHeader } from '../VendorManagementHeader';
import connectData from '../../../ConnectData';
import { getPortalUrl } from '../../App/selectors';
import { VendorListsManagerV2 } from '../VendorListsManagerV2';

function fetchData(getState, dispatch, location, params) {
    const promises = [];
    const governmentId = Number.parseInt(params.governmentId, 10);

    promises.push(dispatch(loadVendorLists(governmentId)));

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const governmentId = Number.parseInt(props.params.governmentId, 10);
    return {
        vendorLists: getVendorLists(state),
        portalUrl: getPortalUrl(state),
        governmentId,
        loading: getVendorListsLoading(state),
    };
};

const mapDispatchToProps = {
    deleteVendorList,
};

class ConnectedVendorList extends React.Component {
    static propTypes = {
        vendorLists: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
            })
        ),
        deleteVendorList: PropTypes.func.isRequired,
        getFlag: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        portalUrl: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            vendorListId: null,
            showVendorListModal: false,
        };
    }

    showVendorListModal = (vendorListId) => {
        this.setState({ showVendorListModal: true, vendorListId });
    };

    deleteVendorList = (vendorListId) => {
        this.props.deleteVendorList(this.props.governmentId, vendorListId);
    };

    static defaultProps = {
        vendorLists: [],
    };

    get styles() {
        return require('./styles.scss');
    }

    render() {
        const { getFlag, governmentId, loading, portalUrl, vendorLists } = this.props;
        const { showVendorListModal } = this.state;

        const enableVendorListsUpdate = getFlag(FLAGS.ENABLE_VENDOR_LISTS_UPDATE);

        if (enableVendorListsUpdate) {
            return <VendorListsManagerV2 />;
        }

        return (
            <div className={this.styles.container}>
                <VendorManagementHeader governmentId={governmentId} portalUrl={portalUrl} />
                <Box
                    pt={3}
                    px={7}
                    sx={{
                        backgroundColor: tokens.colors.colorWhite,
                        height: '100%',
                    }}
                >
                    <div className={this.styles.newVendorButton}>
                        <Button
                            bsSize="small"
                            bsStyle="default"
                            onClick={this.showVendorListModal}
                            qaTag="connectedVendorList-newVendorList"
                        >
                            <i className="fa fa-plus" /> New Vendor List
                        </Button>
                    </div>
                    {loading && <LoadingSpinner text="Loading Vendor Lists" />}
                    {!loading && (
                        <VendorLists
                            deleteVendorList={this.deleteVendorList}
                            governmentId={governmentId}
                            showVendorListModal={this.showVendorListModal}
                            vendorLists={vendorLists}
                        />
                    )}
                </Box>
                {showVendorListModal && (
                    <VendorListModal
                        governmentId={governmentId}
                        hideModal={() =>
                            this.setState({
                                showVendorListModal: false,
                                vendorListId: null,
                            })
                        }
                        id={this.state.vendorListId}
                    />
                )}
            </div>
        );
    }
}

export const VendorListsManager = compose(
    connectData(fetchData),
    withRouter,
    withFlags(),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedVendorList);
