import { createSelector } from 'reselect';

export const getTriageApprovalModalData = createSelector(
    [(state) => state.requisitions.get('triageApprovalModalData')],
    (triageApprovalModalData) => {
        if (triageApprovalModalData) {
            const data = triageApprovalModalData.toJS();
            return {
                showModal: data.show,
                ...data,
            };
        }
        return { showModal: false };
    }
);
