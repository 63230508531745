import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AgGridReact, Box, Button } from '@og-pro/ui';
import { Download as DownloadIcon, Close as CloseIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { CertificationsDropdownSelect } from '../../CertificationsDropdownSelect';
import { VerifiedIcon } from '../../VerifiedIcon';
import { WebsiteCellRenderer } from '../../AgGridReactLegacy/renderers/WebsiteCellRenderer';
import { ProcuratedVendorCell } from '../../Procurated/ProcuratedVendorCell';
import { CACHE_BLOCK_SIZE } from './constants';
import { retrieveVendors } from './helpers';
import {
    loadProcuratedRatingsClient,
    loadSubscribedVendorCertifications,
} from '../../../actions/vendorList';
import { showVendorProfileModal } from '../../../actions/vendorProfile';
import { exportVendorCertifications } from '../../../actions/vendorSearch';

const VendorNameCell = (params) => <ProcuratedVendorCell hideLogo params={params} />;

const VerifiedCellRenderer = (params) =>
    params?.value ? (
        <Box alignItems="center" display="flex" height="100%" justifyContent="center">
            <VerifiedIcon />
        </Box>
    ) : null;

export const SubscribedVendorCertificationTable = ({ governmentId }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [gridApi, setGridApi] = useState(null);
    const [searchParams] = useSearchParams();

    const loadVendors = (api) => {
        const certificationIds = searchParams.getAll('certificationId').map(Number);
        const dataSource = retrieveVendors(
            loadSubscribedVendorCertifications,
            governmentId,
            certificationIds?.length > 0 && {
                certificationIds,
            },
            loadProcuratedRatingsClient,
            dispatch
        );

        api?.setGridOption('serverSideDatasource', dataSource);
    };

    useEffect(() => {
        loadVendors(gridApi);
    }, [searchParams]);

    const showVendorProfileModalWrapper = (params) => {
        if (params?.data?.vendor_id) {
            dispatch(showVendorProfileModal(params.data.vendor_id));
        }
    };

    const columns = [
        {
            field: 'organization_name',
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            width: 310,
            onCellClicked: showVendorProfileModalWrapper,
            cellRenderer: VendorNameCell,
        },
        {
            field: 'doing_business_as',
            headerName: 'Doing Business As (DBA) Name',
            width: 245,
        },
        {
            autoHeight: true,
            cellClass: ['wrapText'], // Used exclusively for Excel export styles
            field: 'title',
            headerName: 'Certification Type',
            width: 280,
        },
        {
            field: 'is_verified',
            headerName: 'Verified',
            width: 90,
            cellRenderer: VerifiedCellRenderer,
        },
        {
            field: 'certifying_agency',
            headerName: 'Certifying Agency',
            width: 250,
        },
        {
            field: 'certificate_number',
            headerName: 'Certificate Number',
            width: 250,
        },
        {
            field: 'issued_date',
            headerName: 'Issued Date',
            width: 115,
            cellRenderer: 'dateCellRenderer',
        },
        {
            field: 'expiration_date',
            headerName: 'Expiration Date',
            width: 135,
            cellRenderer: 'dateCellRenderer',
        },
        {
            field: 'ethnicity',
            headerName: 'Ethnicity',
            width: 120,
        },
        {
            field: 'website',
            headerName: 'Website',
            width: 300,
            cellRenderer: WebsiteCellRenderer,
        },
        {
            field: 'phoneComplete',
            headerName: 'Phone Number',
            width: 145,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 300,
        },
        {
            field: 'state_of_incorporation',
            headerName: 'State of Incorporation',
            width: 190,
        },
    ];

    const defaultColDef = {
        resizable: false,
        suppressHeaderMenuButton: true,
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    const onGridReady = (params) => {
        loadVendors(params.api);
        setGridApi(params.api);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" pb={1} pt={2}>
                <Box display="flex" gap={0.5}>
                    <CertificationsDropdownSelect
                        label="Quick Filter by Certification Type"
                        onChange={(certificationIds) => {
                            navigate({
                                pathname: `${location.pathname}`,
                                search: createSearchParams({
                                    certificationId: certificationIds,
                                }).toString(),
                            });
                        }}
                        selected={searchParams.getAll('certificationId').map(Number)}
                    />
                    {searchParams.getAll('certificationId').length > 0 && (
                        <Button
                            color="secondary"
                            onClick={() =>
                                navigate({
                                    pathname: `${location.pathname}`,
                                    search: createSearchParams().toString(),
                                })
                            }
                            qaTag="subscribedVendorCertificationTable-clearAllFilters"
                            variant="text"
                        >
                            <Box alignItems="center" display="flex" gap={0.5}>
                                <CloseIcon fontSize="inherit" />
                                Clear All Filters
                            </Box>
                        </Button>
                    )}
                </Box>
                <Button
                    color="secondary"
                    onClick={() => dispatch(exportVendorCertifications(governmentId, searchParams))}
                    variant="outlined"
                >
                    <Box alignItems="center" display="flex" gap={0.5}>
                        <DownloadIcon
                            fontSize={
                                capitalDesignTokens.foundations.typography.fontSize.bodyDefault
                            }
                        />
                        Export to CSV
                    </Box>
                </Button>
            </Box>
            <AgGridReact
                cacheBlockSize={CACHE_BLOCK_SIZE}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                onGridReady={onGridReady}
                pagination
                paginationPageSize={15}
                paginationPageSizeSelector={false}
                rowModelType="serverSide"
            />
        </>
    );
};

SubscribedVendorCertificationTable.propTypes = {
    governmentId: PropTypes.number.isRequired,
};
