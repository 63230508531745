import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, formValueSelector, Field, getFormValues } from 'redux-form';
import { Outlet } from 'react-router-dom';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    form,
    formConfig as projectPostFormConfig,
    progressStepsWithProposalDocuments,
    progressStepsWithQuestionnaire,
} from '../constants';
import { mapStateToProps as projectPostCreateMapStateToProps } from '../mapProps';
import { PostConfirmationModal } from '../PostConfirmationModal';
import { validate } from '../validate';
import {
    getDashboardPath,
    getFilteredTimelines,
    getBaseProjectPath,
    isBudgetRequired,
    isBudgetUsed,
    isProjectIdRequired,
} from '../../selectors';
import { isInitialClientLoaded } from '../../../selectors';
import {
    CreateFormNotEditable,
    LoadingError,
    ProgressIconField,
    RouteLeaveWarning,
} from '../../../../components';
import { ConnectedClients } from '../../../../components/connected';
import { FormControls, UpdateError } from '../../../../components/GovApp';
import { menuActionHandler } from '../../../../actions/govProjects';
import { showAutomatedAddendumModal } from '../../../../actions/project/create/projectCreate';
import * as projectPostActions from '../../../../actions/projectPost';
import { PUBLISH } from '../../../../constants/menuActions';

const { OPEN, POST_PENDING } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    const mappedProps = projectPostCreateMapStateToProps(state, props);
    const { project } = mappedProps;

    const isClientLoaded = isInitialClientLoaded(state);

    return {
        ...mappedProps,
        dashboardPath: getDashboardPath(state, props),
        formValues: getFormValues(form)(state) || {},
        initialValues: isClientLoaded ? project : undefined,
        isBudgetRequired: isBudgetRequired(state), // Used by form `validate` function
        isBudgetUsed: isBudgetUsed(state), // Used by form `validate` function
        isClientLoaded,
        isEditableStatus: [POST_PENDING, OPEN].includes(get(project, 'status')),
        isProjectIdRequired: isProjectIdRequired(state), // Used by form `validate` function
        title: formValueSelector(form)(state, 'title') || 'Untitled',
        timelineData: getFilteredTimelines(state, { ...props, form }), // Used by form `validate` function
        baseProjectPath: getBaseProjectPath(state, props),
    };
};

const mapDispatchToProps = {
    ...projectPostActions,
    menuActionHandler,
    showAutomatedAddendumModal,
};

const formConfig = {
    ...projectPostFormConfig,
    validate,
};

// @withRouter
// @connect
// @reduxForm
class ConnectedProjectPostCreate extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        dashboardPath: PropTypes.string.isRequired,
        destroy: PropTypes.func.isRequired,
        dirty: PropTypes.bool,
        formValues: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasQuestionnaire: PropTypes.bool,
        initialize: PropTypes.func.isRequired,
        initializeProjectPostForm: PropTypes.func.isRequired,
        initiateUpdate: PropTypes.func.isRequired,
        isClientLoaded: PropTypes.bool,
        isEditableStatus: PropTypes.bool.isRequired,
        loaded: PropTypes.bool,
        loading: PropTypes.bool,
        loadError: PropTypes.string,
        location: PropTypes.object.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired,
        postPath: PropTypes.string.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isPostOnly: PropTypes.bool,
            projectSections: PropTypes.array.isRequired,
            postScheduledAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
            status: PropTypes.string.isRequired,
            wasPosted: PropTypes.bool,
        }),
        reviewPath: PropTypes.string.isRequired,
        route: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired,
        shouldSubmit: PropTypes.bool,
        shouldUpdate: PropTypes.bool,
        showAutomatedAddendumModal: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool,
        showFormValidation: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool,
        submitPost: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        update: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        baseProjectPath: PropTypes.string.isRequired,
    };

    static defaultProps = {
        dirty: false,
        hasQuestionnaire: false,
        isClientLoaded: false,
        loaded: false,
        loading: false,
        shouldSubmit: false,
        shouldUpdate: false,
        showFormErrors: false,
        submitFailed: false,
    };

    componentDidMount() {
        /**
         * Sets the internal state of the form component before mounting (unrelated to redux-form)
         */
        this.props.initializeProjectPostForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isClientLoaded, loaded, project } = this.props;

        const { project: nextProject } = nextProps;

        /**
         * (DGW 2/24/19):
         * IMPORTANT: This form includes dates that need to be displayed in the user's timezone.
         * As such we cannot initialize the form with dates from the server as the dates from the
         * server will be adjusted to be in the server's local time. What we need are the dates in
         * the user's browser local time, so we need to wait for the client to load before
         * initializing the form data which includes these timezone dependent dates.
         *
         * The form can get initialized from:
         * 1. Server (via a page refresh or direct link)
         * 2. Front-end (via clicking into the form form another page)
         *
         * When initialized from the client (#2) the dates come from the browser and are properly
         * deserialized. This is handled in the connected `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered. Doing the initialization this way allows the form to have
         * the correct dates that are localized for the browser's timezone.
         */
        if (nextProject && loaded && !isClientLoaded && nextProps.isClientLoaded) {
            this.initializeForm(nextProject);
        }

        /**
         * Whenever the project changes we want to reinitialize the form
         * to use the new project values.
         */
        if (nextProject !== project) {
            this.initializeForm(nextProject);
        }
        if (nextProps.shouldUpdate && !this.props.shouldUpdate) {
            this.saveProject();
        }
        if (nextProps.shouldSubmit && !this.props.shouldSubmit) {
            this.submitPost();
        }
    }

    componentWillUnmount() {
        /**
         * We have to manually destroy the form when the parent container
         * is unmounted because we use the `destroyOnUnmount: false` option
         */
        this.props.destroy();
    }

    handleTitleClick = () => {
        const { dashboardPath, router } = this.props;

        router.push(dashboardPath);
    };

    initializeForm = (project) => {
        this.props.initialize(project);
        this.props.initializeProjectPostForm();
    };

    saveProject = () => {
        const {
            formValues,
            project: { id, status, wasPosted },
            update,
        } = this.props;

        return update(formValues, id, status, {
            onComplete: wasPosted ? () => this.props.showAutomatedAddendumModal() : undefined,
            snackbar: true,
        });
    };

    submitPost = () => {
        const { project } = this.props;
        return this.props.handleSubmit((data) => {
            return this.props.submitPost(project.id, data);
        })();
    };

    showConfirmationModal = () => {
        return this.props.menuActionHandler(PUBLISH, this.props.project);
    };

    get styles() {
        return require('./ProjectPostCreate.scss');
    }

    get progressSteps() {
        const { hasQuestionnaire, project } = this.props;

        const progressSteps = hasQuestionnaire
            ? progressStepsWithQuestionnaire
            : progressStepsWithProposalDocuments;

        if (get(project, 'isPostOnly')) {
            return progressSteps;
        }
        return progressSteps.slice(1);
    }

    get selectedSectionIndex() {
        const { pathname } = this.props.location;
        // Find the section or the use the first step if at index route
        // Matches if route ends with trailing "/" or not
        return this.progressSteps.findIndex((step, idx) => {
            return (
                pathname.match(new RegExp(`${step.section}/?$`, 'i')) ||
                (pathname.match(/\/sourcing\/create\/?$/i) && idx === 0)
            );
        });
    }

    get selectedSection() {
        return this.progressSteps[this.selectedSectionIndex] || {};
    }

    renderProgressIcons() {
        const { loading, postPath, router } = this.props;

        if (loading) {
            return (
                <div className={this.styles.loadingHeader}>
                    <i className="fa fa-gear fa-spin text-muted" /> Loading Project Data...
                </div>
            );
        }
        const selectedSection = this.selectedSection;
        return this.progressSteps.map((step, idx) => {
            const route = `${postPath}/${step.section}`;
            return (
                <Field
                    component={ProgressIconField}
                    key={step.icon}
                    name={`sections.${step.section}`}
                    {...step}
                    hasPreviousIcon={idx !== 0}
                    isSelected={selectedSection.section === step.section}
                    onClick={() => router.push(route)}
                />
            );
        });
    }

    renderScheduledAt() {
        const { project } = this.props;
        if (project.wasPosted || !project.postScheduledAt) return null;

        // Time has already been adjusted to account for the timezone, so
        // display does not need to do this
        const postDate = moment(project.postScheduledAt).format('MMM D YYYY [at] h:mma');

        return (
            <div className={`text-primary ${this.styles.scheduledAtDate}`}>
                <em>
                    <i className="fa fa-calendar" /> Scheduled to post on {postDate}
                </em>
            </div>
        );
    }

    renderControlButtons() {
        const {
            initiateUpdate,
            loading,
            params,
            postPath,
            project,
            showFormErrors,
            showFormValidation,
            updating,
        } = this.props;

        if (loading || !project) return null;

        const curPage = this.selectedSectionIndex;
        if (!curPage && curPage !== 0) return null;

        const hasPrevPage = curPage !== 0;
        const hasNextPage = curPage !== this.progressSteps.length - 1;

        const prevRoute = hasPrevPage && `${postPath}/${this.progressSteps[curPage - 1].section}`;
        const nextRoute = hasNextPage && `${postPath}/${this.progressSteps[curPage + 1].section}`;

        return (
            <Field
                component={FormControls}
                disabled={updating}
                isDraft={project.status === POST_PENDING}
                name="allSections"
                nextRoute={nextRoute || undefined}
                params={params}
                prevRoute={prevRoute || undefined}
                reviewPath={this.props.reviewPath}
                saveHandler={initiateUpdate}
                showFormErrors={showFormErrors}
                showFormValidation={showFormValidation}
                submitButtonText="Schedule Post"
                submitHandler={this.showConfirmationModal}
            />
        );
    }

    render() {
        const {
            change,
            isEditableStatus,
            loadError,
            loading,
            project,
            submitFailed,
            title,
            timezone,
            updateError,
            updating,
            baseProjectPath,
        } = this.props;

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (project && !isEditableStatus) {
            return (
                <CreateFormNotEditable text="Post has been completed and is no longer editable" />
            );
        }

        return (
            <div className={`row ${this.styles.container}`}>
                <RouteLeaveWarning blockingValue={this.props.dirty} />
                <div className={this.styles.titleContainer}>
                    <h2 className="text-primary">
                        {!loading && (
                            <span className={this.styles.title} onClick={this.handleTitleClick}>
                                {title}
                            </span>
                        )}
                    </h2>
                    {this.renderScheduledAt()}
                </div>
                <div className={this.styles.progressContainer}>{this.renderProgressIcons()}</div>
                <UpdateError error={updateError} submitFailed={submitFailed} />
                <div className="col-sm-offset-1 col-sm-10">
                    <ConnectedClients block />
                    <Panel>
                        <Panel.Body>
                            <div className={this.styles.panelBody}>
                                <Outlet />
                            </div>
                            {this.renderControlButtons()}
                        </Panel.Body>
                    </Panel>
                </div>
                <PostConfirmationModal
                    baseProjectPath={baseProjectPath}
                    change={change}
                    project={project}
                    timezone={timezone}
                    updateError={updateError}
                    updating={updating}
                />
            </div>
        );
    }
}

export const ProjectPostCreate = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedProjectPostCreate);
