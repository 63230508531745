import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { MenuButtons } from './MenuButtons';
import { ContractChecklistTitleMenu } from '../ContractChecklistTitleMenu';
import { deleteChecklist, updateChecklist } from '../../../../actions/checklists';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { ContractChecklist } from '../../../../components/contracts';

const { COMPLETE, RELEASED } = checklistStatusTypes;

export const ContractChecklistReview = ({
    checklist,
    checklist: { id, status },
    contractChecklistsPath,
    isEditor,
}) => {
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const dispatch = useDispatch();

    const isReleased = status === RELEASED;
    const disabled = updating || deleting;

    const completeChecklistHandler = () => {
        const updateHandler = () => {
            const data = {
                status: COMPLETE,
            };

            setUpdating(true);
            setUpdateError(null);
            return dispatch(updateChecklist(id, data, 'Checklist completed!'))
                .then(() => {
                    setUpdating(false);
                })
                .catch((error) => {
                    setUpdating(false);
                    setUpdateError(error.message);
                });
        };

        dispatch(
            showConfirmationSimpleModal(updateHandler, {
                btnText: 'Complete Checklist',
                bsStyle: 'success',
                icon: 'check',
                text:
                    'Are you sure you want to complete this checklist?\n' +
                    'Vendor will be notified is complete\n' +
                    'You will not be able to make any updates once complete',
            })
        );
    };

    const deleteChecklistHandler = () => {
        const deleteHandler = () => {
            setDeleting(true);
            setDeleteError(null);
            // Delete template and then route back to checklist list page
            return dispatch(deleteChecklist(id))
                .then(() => {
                    navigate(contractChecklistsPath, { replace: true });
                })
                .catch((error) => {
                    setDeleting(false);
                    setDeleteError(error.message);
                });
        };

        dispatch(
            showConfirmationSimpleModal(deleteHandler, {
                btnText: 'Delete Checklist',
                text:
                    'Are you sure you want to delete this checklist?\n' +
                    'The checklist and any responses entered by the vendor will be deleted.\n' +
                    'This action cannot be undone.',
            })
        );
    };

    const reopenChecklistHandler = () => {
        const updateHandler = () => {
            const data = {
                status: RELEASED,
            };

            setUpdating(true);
            setUpdateError(null);
            return dispatch(updateChecklist(id, data, 'Checklist re-opened!'))
                .then(() => {
                    setUpdating(false);
                })
                .catch((error) => {
                    setUpdating(false);
                    setUpdateError(error.message);
                });
        };

        dispatch(
            showConfirmationSimpleModal(updateHandler, {
                btnText: 'Re-Open Checklist',
                bsStyle: 'warning',
                icon: 'undo',
                text:
                    'Are you sure you want to re-open this checklist?\n' +
                    'All checklist items will become editable once re-opened',
            })
        );
    };

    const menuButtons = (
        <MenuButtons
            completeChecklist={completeChecklistHandler}
            deleteChecklist={deleteChecklistHandler}
            deleting={deleting}
            disabled={disabled}
            isEditor={isEditor}
            isReleased={isReleased}
            reopenChecklist={reopenChecklistHandler}
            updating={updating}
        />
    );

    return (
        <>
            <ContractChecklistTitleMenu
                checklist={checklist}
                contractChecklistsPath={contractChecklistsPath}
                errorMessage={updateError || deleteError}
                menuButtons={menuButtons}
            />
            <ContractChecklist checklist={checklist} isGovernmentView />
        </>
    );
};

ContractChecklistReview.propTypes = {
    checklist: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    contractChecklistsPath: PropTypes.string.isRequired,
    isEditor: PropTypes.bool.isRequired,
};
