import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FieldArray, reduxForm } from 'redux-form';

import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { Button } from '../../../Button';
import { PriceTable } from '../../../PriceTable';
import { PRICING } from '../../../proposals/ProposalCreateNav/constants';

// @reduxForm
class ConnectedProposalPricing extends Component {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number.isRequired,
        change: PropTypes.func.isRequired,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        isReverseAuction: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        sections: PropTypes.array.isRequired,
        salesTax: PropTypes.number,
        showValidation: PropTypes.bool,
        toggleFullWidthView: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        useFullWidthView: PropTypes.bool,
    };

    componentWillUnmount() {
        const { toggleFullWidthView } = this.props;

        toggleFullWidthView(false);
    }

    get styles() {
        return require('./index.scss');
    }

    toggleFullWidthView = () => {
        const { toggleFullWidthView, useFullWidthView } = this.props;

        toggleFullWidthView(!useFullWidthView);
    };

    renderPriceTables = ({ disabled, fields, showValidation }) => {
        const { auctionMaxFractionDigits, change, isReverseAuction, salesTax } = this.props;

        return fields.map((name, index) => {
            const priceTable = fields.get(index);
            return (
                <FieldArray
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    change={change}
                    component={PriceTable}
                    isReverseAuction={isReverseAuction}
                    key={priceTable.id}
                    name={`${name}.priceItems`}
                    priceTable={priceTable}
                    readOnly={disabled}
                    salesTax={salesTax}
                    showValidation={showValidation}
                />
            );
        });
    };

    render() {
        const { dirty, disabled, onSave, sections, showValidation, updateError, useFullWidthView } =
            this.props;

        return (
            <div>
                <div className="text-right">
                    <Button
                        bsSize="sm"
                        className={this.styles.viewButton}
                        onClick={this.toggleFullWidthView}
                    >
                        <i className={`fa fa-${useFullWidthView ? 'list-ul' : 'expand'}`} />
                        &nbsp;
                        {useFullWidthView ? 'Show Navigation Menu' : 'Expand to Full Width'}
                    </Button>
                </div>
                <FieldArray
                    component={this.renderPriceTables}
                    disabled={disabled}
                    name="priceTables"
                    showValidation={showValidation}
                />
                <ProposalSectionButtons
                    disabled={disabled}
                    isFormDirty={dirty}
                    onSave={onSave}
                    section={PRICING}
                    sections={sections}
                    updateError={updateError}
                />
            </div>
        );
    }
}

export const ProposalPricing = reduxForm(formConfig)(ConnectedProposalPricing);
