import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { Box, Button, InputAdornment, TextField } from '@og-pro/ui';

import { Add as AddIcon } from '@mui/icons-material';

import Icon from '@mdi/react';
import { mdiArchive } from '@mdi/js';

import { fieldStatusesDict } from '@og-pro/shared-config/customFormService/customField';

import { LoadingError, LoadingSpinner } from '../../../../components';

import { CustomFieldsTable } from './CustomFieldsTable';

import CustomFieldsModal from './CustomFieldsModal';
import { useListCustomFields } from '../../../../lib/customFormService/useListCustomFields';

import { getRequisitionsAdminPath } from '../selectors';
import { useCustomFieldsFilters } from './hooks';

import EmptyCustomFieldState from './EmptyCustomFieldState';

const { ARCHIVED } = fieldStatusesDict;

export const CustomFieldsLibrary = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const requisitionsAdminPath = useSelector((state) =>
        getRequisitionsAdminPath(state, { params })
    );

    const { isLoading, isError, error, data } = useListCustomFields();

    const nonArchivedFields = (data?.getAllCustomFields || []).filter(
        (field) => field.status !== ARCHIVED
    );

    const { filteredCustomFields, setSearchValue } = useCustomFieldsFilters({
        customFields: nonArchivedFields,
    });

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    function handleViewArchive() {
        navigate(`${requisitionsAdminPath}/custom-fields/archive`);
    }

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (isError) {
        return <LoadingError error={error.message} useOpenGovStyle />;
    }

    if (nonArchivedFields.length === 0) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography component="h1" variant="h2">
                    Library of Custom Fields
                </Typography>
                <EmptyCustomFieldState />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography component="h1" variant="h2">
                Library of Custom Fields
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: {
                        xs: 'flex-start',
                        sm: 'center',
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    gap: {
                        xs: 1,
                        sm: 0,
                    },
                }}
            >
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment aria-label="search icon" position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    aria-label="Search Custom Fields"
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search by Title"
                    sx={{
                        margin: 0,
                        padding: 0,
                        '& .MuiInputBase-root': {
                            margin: 0,
                            height: '34px',
                        },
                        minWidth: '40%',
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}
                    type="text"
                />
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleViewArchive}
                        startIcon={<Icon path={mdiArchive} size={1} />}
                        sx={{ mr: 1 }}
                        variant="text"
                    >
                        View Archive
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleOpenModal}
                        startIcon={<AddIcon />}
                        variant="contained"
                    >
                        New Custom Field
                    </Button>
                </Box>
            </Box>
            {showModal && <CustomFieldsModal onClose={handleCloseModal} open={showModal} />}
            <CustomFieldsTable rows={filteredCustomFields} />
        </Box>
    );
};
