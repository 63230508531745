import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { TemplateEditV2NavContext } from '../context';

export const TemplateEditV2NavContextProvider = ({
    activeSectionId,
    children,
    setActiveSectionId,
    showAiImporterDialog,
    setShowAiImporterDialog,
}) => {
    const memoizedContextValues = useMemo(() => {
        return {
            activeSectionId,
            setActiveSectionId,
            showAiImporterDialog,
            setShowAiImporterDialog,
        };
    }, [activeSectionId, setActiveSectionId, showAiImporterDialog, setShowAiImporterDialog]);

    return (
        <TemplateEditV2NavContext.Provider value={memoizedContextValues}>
            {children}
        </TemplateEditV2NavContext.Provider>
    );
};

TemplateEditV2NavContextProvider.propTypes = {
    activeSectionId: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    setActiveSectionId: PropTypes.func.isRequired,
    showAiImporterDialog: PropTypes.bool.isRequired,
    setShowAiImporterDialog: PropTypes.func.isRequired,
};
