import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Box, Grid, ReduxFormSelect, Typography } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { SignatureBlock } from './Block';
import { CDSButtonGroup, CDSButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { RichTextInput } from '../../RichTextInput';
import { HelpIcon } from '../../InputText/HelpIcon';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { fieldNames, stampOptions } from './constants';
import ActionSideBarForTextBlocks from './ActionSideBarForTextBlocks';
import { getDeserializedTemplateProject } from '../../../containers/GovApp/TemplateAdmin/TemplateEdit/selectors';
import { useHasElectronicSignature } from '../../../hooks';

const { BLANK_BLOCKS, FOOTER, RAW_FOOTER, INTRODUCTION, RAW_INTRODUCTION, ESIGNATURE_ENABLED } =
    fieldNames;

export const SignatureForm = ({
    change,
    disabled,
    enableQuestionLogics = false,
    form,
    formKey,
    projectSection,
    showValidation,
    questionLogicsConfig = null,
    tagOptions,
    templateVariableOptions,
}) => {
    const hasElectronicSignature = useHasElectronicSignature();
    const documentsTabEnabled = useFlags(FLAGS.ENABLE_CONTRACT_DOCUMENTS_TAB);
    const templateProject = useSelector(getDeserializedTemplateProject);

    const footerInput = templateProject ? `${formKey}.${FOOTER}` : `${formKey}.${RAW_FOOTER}`;
    const introductionInput = templateProject
        ? `${formKey}.${INTRODUCTION}`
        : `${formKey}.${RAW_INTRODUCTION}`;
    // used for counting the blocks and showing the number
    const leftSideBlocksLength = useSelector((state) => {
        const blocks = formValueSelector(form)(state, `${formKey}.signaturesBlocks[0]`);

        return (blocks || []).filter((b) => b.styling === 'default' && !b.isHiddenByLogic).length;
    });
    const blankBlocks = useSelector((state) =>
        formValueSelector(form)(state, `${formKey}.${BLANK_BLOCKS}`)
    );

    const [{ showIntroduction, showFooter }, setShowFields] = useState({
        showIntroduction: false,
        showFooter: false,
    });
    const { introduction, footer } = useSelector((state) => {
        const selector = formValueSelector(form);

        return {
            introduction: selector(state, introductionInput),
            footer: selector(state, footerInput),
        };
    });
    const styles = require('./index.scss');

    const toggleIntroduction = () => {
        change(introductionInput, '');
        setShowFields((s) => ({ ...s, showIntroduction: !s.showIntroduction }));
    };
    const toggleFooter = () => {
        change(footerInput, '');
        setShowFields((s) => ({ ...s, showFooter: !s.showFooter }));
    };
    const addNotaryBlock = () => {
        change(`${formKey}.${BLANK_BLOCKS}`, [{ value: '5in' }]);
    };
    const removeNotaryBlock = () => {
        change(`${formKey}.${BLANK_BLOCKS}`, []);
    };

    useEffect(() => {
        setShowFields({
            showIntroduction: !!introduction,
            showFooter: !!footer,
        });
    }, []);

    return (
        <Box className={styles.container}>
            {documentsTabEnabled && hasElectronicSignature && (
                <Box alignItems="center" display="flex" sx={{ mb: 1 }}>
                    <Box mr={0.5}>
                        <Typography fontWeight={500} variant="body2">
                            Signature Type:
                        </Typography>
                    </Box>
                    <Box flex={1}>
                        <Field
                            aria-label="E-Signature Enabled"
                            component={ReduxFormSelect}
                            disabled={disabled}
                            form={form}
                            label={null}
                            name={`${formKey}.${ESIGNATURE_ENABLED}`}
                            options={[
                                {
                                    label: 'eSignature',
                                    value: true,
                                },
                                {
                                    label: 'Wet Signature',
                                    value: false,
                                },
                            ]}
                            qaTag="projectSectionForm-esignatureEnabled"
                            size="small"
                        />
                    </Box>
                </Box>
            )}

            {!showIntroduction && (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={toggleIntroduction}
                        qaTag="signatureForm-addIntroduction"
                        size="small"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add Introductory Text
                    </CDSButton>
                </CDSButtonGroup>
            )}
            {showIntroduction && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>Introductory Text</Box>
                        <Box className={classnames(styles.description)}>
                            This can be used for a predefined clause, label, or text snippet as an
                            introduction to all signatures.
                        </Box>
                        <Box>
                            <Field
                                borderless
                                component={RichTextInput}
                                disabled={disabled}
                                label={null}
                                minRows={3}
                                name={introductionInput}
                                placeholder="Enter Text"
                                showValidation={showValidation}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                useOpenGovStyle
                                useSharedTextareaToolbar
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={toggleIntroduction} />
                </ContentBlock>
            )}

            <Grid container spacing={6}>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            disabled={disabled}
                            enableQuestionLogics={enableQuestionLogics}
                            form={form}
                            name={`${formKey}.signaturesBlocks[0]`}
                            projectSection={projectSection}
                            questionLogicsConfig={questionLogicsConfig}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            countOffset={leftSideBlocksLength}
                            disabled={disabled}
                            enableQuestionLogics={enableQuestionLogics}
                            form={form}
                            name={`${formKey}.signaturesBlocks[1]`}
                            projectSection={projectSection}
                            questionLogicsConfig={questionLogicsConfig}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
            </Grid>
            {!blankBlocks?.length && (
                <Box>
                    <CDSButtonGroup>
                        <CDSButton
                            onClick={() => addNotaryBlock()}
                            qaTag="signatureForm-addNotaryBlock"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add Notary
                        </CDSButton>
                    </CDSButtonGroup>
                </Box>
            )}
            {!!blankBlocks?.length && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>
                            <Typography component="label" fontWeight={500} htmlFor="notaryBlock">
                                Notary Block{' '}
                            </Typography>
                            <HelpIcon
                                className={styles.helpIcon}
                                placement="right"
                                tooltip={
                                    "This can be used as a placeholder for notary. You can also create any text customization within this space, if you want to insert something into the signature page that is outside of OpenGov's formatting."
                                }
                                useOpenGovStyle
                            />
                        </Box>
                        <Box height={`calc(${blankBlocks[0].value} / 2)`} width="200px">
                            <Field
                                aria-label="Select Notary Block Size"
                                component={SearchSelect}
                                disabled={disabled}
                                hasFeedback={false}
                                inputId="notaryBlock"
                                label={null}
                                name={`${formKey}.${BLANK_BLOCKS}[0].value`}
                                options={stampOptions}
                                showValidation={showValidation}
                                useOpenGovStyle
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={removeNotaryBlock} />
                </ContentBlock>
            )}
            {!showFooter && (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={toggleFooter}
                        qaTag="signatureForm-addFooter"
                        size="small"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add Concluding Text
                    </CDSButton>
                </CDSButtonGroup>
            )}
            {showFooter && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>Concluding Text</Box>
                        <Box
                            className={classnames(
                                styles.description,
                                styles.descriptionMarginBottom
                            )}
                        >
                            The concluding text can be used for a predefined clause, label, or text
                            snippet as an conclusion to all signatures.
                        </Box>
                        <Box>
                            <Field
                                borderless
                                component={RichTextInput}
                                disabled={disabled}
                                label={null}
                                minRows={3}
                                name={footerInput}
                                placeholder="Enter Text"
                                showValidation={showValidation}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                useOpenGovStyle
                                useSharedTextareaToolbar
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={toggleFooter} />
                </ContentBlock>
            )}
        </Box>
    );
};

SignatureForm.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    form: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    projectSection: PropTypes.object.isRequired,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};
