import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { createQuestionnaire } from '../../../../actions/questionnaire';
import { initiateUpdate } from '../../../../actions/projectPost';
import { formConfig, fieldNames } from '../constants';
import { mapStateToProps as projectPostCreateMapStateToProps } from '../mapProps';
import { getQuestionnaireFormValues } from '../selectors';
import {
    hasSourcingSubscription,
    questionnaireConditionalEnabled as getQuestionnaireConditionalEnabled,
} from '../../selectors';
import { SectionTitle, Main } from '../../../../components';
import { QuestionnairesForm } from '../../../../components/forms';
import { VendorQuestionnaireRenderLogicIcon } from '../../ConditionalLogicIcon/VendorQuestionnaireRenderLogicIcon';
import { ConditionalLogicModal } from '../../ConditionalLogicModal';
import { extendSimpleWizardForm } from '../../../../hocs';
import { instructions, helpPopover } from './constants';

const { QUESTIONNAIRES } = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        ...projectPostCreateMapStateToProps(state, props),
        hasSourcing: hasSourcingSubscription(state),
        questionnaires: getQuestionnaireFormValues(state),
        questionnaireConditionalEnabled: getQuestionnaireConditionalEnabled(state),
    };
};

const mapDispatchToProps = {
    createQuestionnaire,
    initiateProjectUpdate: initiateUpdate,
};

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedPostQuestionnaire extends Component {
    static propTypes = {
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        createQuestionnaire: PropTypes.func.isRequired,
        hasSourcing: PropTypes.bool,
        initiateProjectUpdate: PropTypes.func.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        questionnaires: PropTypes.array.isRequired,
        questionnaireConditionalEnabled: PropTypes.bool,
        showFormErrors: PropTypes.bool.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updating: PropTypes.bool,
    };

    static defaultProps = {
        updating: false,
    };

    createQuestionnaire = async (data) => {
        return this.props.createQuestionnaire(this.props.project.id, data);
    };

    addConditionalLogicHandler = (questionLogic) => {
        const { array, initiateProjectUpdate } = this.props;

        array.push('questionLogics', questionLogic);

        setTimeout(() => initiateProjectUpdate());
    };

    render() {
        const {
            array,
            change,
            hasSourcing,
            questionnaires,
            questionnaireConditionalEnabled,
            showFormErrors,
            tagOptions,
            templateVariableOptions,
            updating,
        } = this.props;

        return (
            <Main className="row">
                <div className="col-xs-12">
                    <SectionTitle
                        help={helpPopover}
                        info={instructions}
                        title="Vendor Questionnaire"
                    />
                    <QuestionnairesForm
                        array={array}
                        change={change}
                        createBeforeAddingHandler={this.createQuestionnaire}
                        disabled={updating}
                        formKey={QUESTIONNAIRES}
                        includeContainsPricingOption={hasSourcing}
                        isResponsive
                        questionnaires={questionnaires}
                        renderQuestionLogicIcon={VendorQuestionnaireRenderLogicIcon(
                            this.addConditionalLogicHandler,
                            questionnaires,
                            questionnaireConditionalEnabled
                        )}
                        showFormErrors={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useRawPrompt
                    />
                    <ConditionalLogicModal />
                </div>
            </Main>
        );
    }
}

export const PostQuestionnaire = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm()
)(ConnectedPostQuestionnaire);
