import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { ReduxTriageApprovalModalForm } from './TriageApprovalForm';
import { getTriageApprovalModalData } from './selectors';

import { hideRequisitionTriageApprovalModal } from '../../../../../../actions/requisitions';

import { getRequisitionNextStep } from '../../../../../../selectors/govApp';

export const TriageApprovalModal = () => {
    const dispatch = useDispatch();
    const { onConfirm, showModal } = useSelector(getTriageApprovalModalData);
    const nextStep = useSelector(getRequisitionNextStep);

    const nextApprovers =
        nextStep?.stepApproval?.stepPositionApprovals?.flatMap((stepPositionApproval) =>
            stepPositionApproval.stepPositionApprovers.map((approver) => ({
                label: approver.user.fullName,
                value: approver.id,
            }))
        ) ?? [];

    const handleClose = () => {
        dispatch(hideRequisitionTriageApprovalModal());
    };

    const handleSubmit = (formValues) => {
        // Todo: Add Budget Check

        const data = {
            triageSelection: {
                stepPositionApproverId: formValues.selectedApprover,
            },
        };

        onConfirm(data);
        handleClose();
    };

    return (
        <Dialog onClose={handleClose} open={showModal}>
            <IconButton
                aria-label="close"
                className="MuiDialog__close-button"
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>Assign Next Reviewer</DialogTitle>
            <ReduxTriageApprovalModalForm
                handleClose={handleClose}
                nextApprovers={nextApprovers}
                onSubmit={handleSubmit}
            />
        </Dialog>
    );
};
