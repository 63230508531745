import PropTypes from 'prop-types';
import React from 'react';

import { ContractListFiltersForm } from '../../forms/ContractListFiltersForm';
import { formatFilterFormData } from '../../../helpers';

const BaseContractListFilters = (props) => {
    const { executeSearch, queryParams } = props;

    return (
        <ContractListFiltersForm
            executeSearch={(data) => {
                executeSearch(
                    formatFilterFormData({ ...data, quickSearch: queryParams.quickSearch }),
                    { resetPage: true }
                );
            }}
            initialValues={queryParams?.filters || []}
        />
    );
};

BaseContractListFilters.propTypes = {
    executeSearch: PropTypes.func.isRequired,
    queryParams: PropTypes.object,
};

const MemoizedContractListFilters = React.memo(BaseContractListFilters);

export const ContractListFilters = MemoizedContractListFilters;
