import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { questionnaireReviewStatusTypes } from '@og-pro/shared-config/checklists';

import { QUESTIONNAIRE } from './QuestionnaireResponseForm/constants';
import { getQuestionnaireResponseFormValues } from './selectors';
import { SwitchActionModal } from './SwitchActionModal';
import { Button } from '../../../Button';
import { LoadingButton } from '../../../LoadingButton';
import { OutlineButton } from '../../../OutlineButton';
import {
    updateQuestionnaireResponse,
    updateQuestionnaireReview,
} from '../../../../actions/checklists';
import { questionnaireResponseComplete } from '../../../../Forms/validation';

const { APPROVED, PENDING } = questionnaireReviewStatusTypes;

export const ActionMenu = ({
    checklistId,
    isApprovalRequired,
    isGovernmentView,
    isMyAction,
    isResponseValid,
    hideModal,
    questionnaire,
    questionnaire: {
        id: questionnaireId,
        questionnaireReview: { isComplete },
        updateResponseError,
        updateReviewError,
        updatingResponse,
        updatingReview,
    },
    setIsResponseValid,
    showForm,
    toggleEditHandler,
}) => {
    const [showActionSwitchModal, setShowActionSwitchModal] = useState(false);

    const dispatch = useDispatch();
    const formValues = useSelector(getQuestionnaireResponseFormValues);

    const otherSide = isGovernmentView ? 'Vendor' : 'Agency';

    const questionnaireReviewHandler = (data, msg, msgOptions) => {
        return dispatch(
            updateQuestionnaireReview(checklistId, questionnaireId, data, msg, msgOptions)
        );
    };

    const saveHandler = () => {
        const { questionnaireResponse } = formValues[QUESTIONNAIRE];
        return dispatch(
            updateQuestionnaireResponse(checklistId, questionnaireId, questionnaireResponse)
        );
    };

    const undoHandler = () =>
        questionnaireReviewHandler({ undoCompletion: true }, 'Approval removed');
    const actionSwitchHandler = (status, note) => {
        const questionnaireReviewData = {
            isActionSwitch: true,
            note,
            status,
        };
        return questionnaireReviewHandler(
            questionnaireReviewData,
            `Item sent to ${otherSide}`
        ).then(hideModal);
    };
    const reviewHandler = (status) => () => {
        const msg = status === APPROVED ? 'Item Complete!' : 'Changes needed';
        const msgOptions = status === APPROVED ? { isSuccess: true } : {};
        return questionnaireReviewHandler({ status }, msg, msgOptions).then(() => {
            if (status === APPROVED) {
                hideModal();
            }
        });
    };

    const styles = require('./index.scss');

    // Complete items can always be revised
    if (isComplete) {
        return (
            <div className={styles.actionMenu}>
                <OutlineButton
                    bsSize="sm"
                    bsStyle="warning"
                    disabled={updatingReview}
                    onClick={undoHandler}
                    qaTag="checklistQuestionnaireResponseModal-undo"
                    tooltip="Will move item back into review"
                >
                    <i className={`fa fa-fw fa-${updatingReview ? 'spinner fa-spin' : 'undo'}`} />
                    &nbsp;Undo Approval
                </OutlineButton>
                {updateReviewError && <div className="error-block">{updateReviewError}</div>}
            </div>
        );
    }

    // No action can be taken when action is to other party
    if (!isMyAction) {
        return null;
    }

    if (isApprovalRequired) {
        return (
            <div className={styles.actionMenu}>
                <div className="row">
                    <div className="col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                        <Panel bsStyle="primary" className="text-center">
                            <Panel.Heading>
                                <Panel.Title componentClass="h4">Action Required!</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <p className={styles.actionInstructions}>
                                    Review and approve the response below
                                </p>
                                <div className={styles.switchActionButtons}>
                                    <Button
                                        bsStyle="success"
                                        className={styles.decisionButton}
                                        disabled={updatingReview}
                                        onClick={reviewHandler(APPROVED)}
                                        qaTag="checklistQuestionnaireResponseModal-approve"
                                        tooltip="Approving will mark the item as complete"
                                    >
                                        <i className="fa fa-check-square-o" /> Approve
                                    </Button>
                                    &nbsp;&nbsp;
                                    <OutlineButton
                                        bsStyle="primary"
                                        className={styles.decisionButton}
                                        disabled={updatingReview}
                                        onClick={reviewHandler(PENDING)}
                                        qaTag="checklistQuestionnaireResponseModal-changesNeeded"
                                        tooltip="Rejects the current response and allows you to make or request edits to the response"
                                    >
                                        <i className="fa fa-refresh" /> Changes Needed
                                    </OutlineButton>
                                </div>
                                <div className={styles.actionButtonsInfo}>
                                    Select &quot;Approve&quot; if item is approved as-is. This will
                                    complete the checklist item.
                                    <br />
                                    Select &quot;Changes Needed&quot; to edit the response or
                                    request the {otherSide} make edits.
                                </div>
                                {updateReviewError && (
                                    <div className="error-block">{updateReviewError}</div>
                                )}
                            </Panel.Body>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }

    // Must be my action in `REJECTED` status -> this is the editable state
    return (
        <div className={styles.actionMenu}>
            <Button
                bsSize="sm"
                className={styles.editButton}
                disabled={updatingResponse}
                onClick={toggleEditHandler}
                qaTag="checklistQuestionnaireResponseModal-edit"
            >
                <i className={`fa fa-${showForm ? 'ban' : 'pencil'}`} />{' '}
                {showForm ? 'Cancel' : 'Edit'}
            </Button>
            {showForm && (
                <LoadingButton
                    bsSize="sm"
                    bsStyle="primary"
                    className={styles.buttonRight}
                    disabled={updatingResponse}
                    icon="fa-check"
                    loading={updatingResponse}
                    onClick={saveHandler}
                    qaTag="checklistQuestionnaireResponseModal-save"
                    text="Save"
                />
            )}
            <LoadingButton
                bsSize="sm"
                bsStyle="success"
                className={styles.buttonRight}
                disabled={updatingResponse}
                icon="fa-send"
                loading={updatingResponse}
                onClick={() => {
                    const showModal = (questionnaireResponseData) => {
                        const updatedQuestionnaire = questionnaireResponseData
                            ? {
                                  ...questionnaire,
                                  questionnaireResponse: questionnaireResponseData,
                              }
                            : questionnaire;

                        const isValid = !questionnaireResponseComplete(updatedQuestionnaire);
                        setIsResponseValid(isValid);

                        // Show switch action modal if form is valid
                        if (isValid) {
                            setShowActionSwitchModal(true);
                        }
                    };
                    if (showForm) {
                        return saveHandler().then((questionnaireResponse) =>
                            showModal(questionnaireResponse)
                        );
                    }
                    return showModal();
                }}
                qaTag="checklistQuestionnaireResponseModal-sendForReview"
                text={`${showForm ? 'Save & ' : ''}Send to ${otherSide}`}
            />
            {!isResponseValid && <div className="error-block">Please complete response</div>}
            {updateResponseError && <div className="error-block">{updateResponseError}</div>}
            {showActionSwitchModal && (
                <SwitchActionModal
                    actionSwitchHandler={actionSwitchHandler}
                    hideModal={() => setShowActionSwitchModal(false)}
                    isGovernmentView={isGovernmentView}
                    updateReviewError={updateReviewError}
                    updatingReview={updatingReview}
                />
            )}
        </div>
    );
};

ActionMenu.propTypes = {
    checklistId: PropTypes.number.isRequired,
    hideModal: PropTypes.func.isRequired,
    isApprovalRequired: PropTypes.bool,
    isMyAction: PropTypes.bool,
    isGovernmentView: PropTypes.bool,
    isResponseValid: PropTypes.bool.isRequired,
    questionnaire: PropTypes.shape({
        id: PropTypes.number.isRequired,
        questionnaireReview: PropTypes.shape({
            isComplete: PropTypes.bool.isRequired,
        }).isRequired,
        updateResponseError: PropTypes.string,
        updateReviewError: PropTypes.string,
        updatingResponse: PropTypes.bool,
        updatingReview: PropTypes.bool,
    }).isRequired,
    setIsResponseValid: PropTypes.func.isRequired,
    showForm: PropTypes.bool.isRequired,
    toggleEditHandler: PropTypes.func.isRequired,
};
