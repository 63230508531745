import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@og-pro/ui';

export const ActionPanelItem = ({ children, label }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
            }}
        >
            <Typography variant="h5">{label}</Typography>
            <Typography>{children}</Typography>
        </Box>
    );
};

ActionPanelItem.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
};
