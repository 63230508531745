import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { fieldNames } from '../../../../../forms/TemplateForm/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import { Toggle } from '../../../../../../../../components/Toggle/Toggle';
import { showConfirmationSimpleModal } from '../../../../../../../../actions/confirmation';

const { USE_EXTERNAL_DOCUMENT } = fieldNames;

export function TemplateEditV2DocumentBuilderSettingsForm() {
    const dispatch = useDispatch();
    const { disabled, change, templateProject } = useContext(TemplateEditV2FunctionsContext);
    const styles = require('../index.scss');

    return (
        <Box className={styles.container}>
            <Box className={classNames(styles.oldFont, styles.section)}>
                <Typography variant="h3">Upload Settings</Typography>
                <Typography fontWeight={500}>Do you want to upload your own document?</Typography>
                <Typography color="secondary" mb={1} mt={0.5} variant="bodySmall">
                    If you choose to upload your own document, assembling with the Document Builder
                    will no longer be available.
                </Typography>
                <Field
                    aria-label="Upload your own document Switch"
                    component={Toggle}
                    disabled={disabled}
                    leftLabel="NO"
                    name={USE_EXTERNAL_DOCUMENT}
                    onChange={(value) => {
                        const newValue = value.target.checked;
                        const { projectSections, upfrontQuestions } = templateProject;
                        if (
                            newValue &&
                            (projectSections.length > 0 || upfrontQuestions.length > 0)
                        ) {
                            dispatch(
                                showConfirmationSimpleModal(() => {}, {
                                    text: (
                                        <>
                                            <Typography variant="bodyDefault">
                                                Any sections in the document editor, or
                                                corresponding automations, will be deleted when you
                                                make this change. If you would like to retain them,
                                                copy this template and make changes to the copy.
                                            </Typography>
                                            <br />
                                            <Typography variant="bodyDefault">
                                                <Typography component="span" fontWeight={600}>
                                                    This action cannot be undone.
                                                </Typography>{' '}
                                                Are you sure you want to update this switch from No
                                                to Yes?
                                            </Typography>
                                        </>
                                    ),
                                    title: 'Warning: Document Builder Work Will Be Lost',
                                    onCancel: () => {
                                        change(USE_EXTERNAL_DOCUMENT, false);
                                    },
                                    btnText: 'Continue',
                                    useOpenGovStyle: true,
                                })
                            );
                        }
                    }}
                    qaTag="templateEditor-toggleUseExternalDocument"
                    rightLabel="YES"
                    useOpenGovStyle
                />
            </Box>
        </Box>
    );
}
