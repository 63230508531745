import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requisitionsSettingsForm';

export const fields = ['useBudgetGroup', 'usePreEncumbrance'];

export const fieldNames = listToDict(fields);

export const usePreEncumbranceOptions = [
    { label: 'Exclude', value: false },
    { label: 'Include', value: true },
];

export const useBudgetGroupOptions = [
    { label: 'Account', value: false },
    { label: 'Group', value: true },
];
