import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProposalSectionTitle } from '../ProposalSectionTitle';
import { QuestionnaireDisplayList } from '../../../../Questionnaire/QuestionnaireDisplayList';
import { DocxHeadingFormattersContext } from '../../../../contexts';

export class QuestionnaireSection extends PureComponent {
    static propTypes = {
        canGovUserEdit: PropTypes.bool,
        isDocx: PropTypes.bool,
        isPricingSealed: PropTypes.bool,
        printButton: PropTypes.node,
        proposal: PropTypes.shape({
            id: PropTypes.number.isRequired,
            project_id: PropTypes.number.isRequired,
            questionnaires: PropTypes.array.isRequired,
        }).isRequired,
        updateQuestionnaireResponse: PropTypes.func,
    };

    static defaultProps = {
        printButton: undefined,
    };

    get styles() {
        return require('../../index.scss');
    }

    render() {
        const {
            canGovUserEdit,
            isDocx,
            isPricingSealed,
            printButton,
            proposal: { id, project_id: projectId, questionnaires },
            updateQuestionnaireResponse,
        } = this.props;

        if (questionnaires.length === 0) {
            return null;
        }

        return (
            <div className={this.styles.section}>
                <ProposalSectionTitle
                    isDocx={isDocx}
                    printButton={printButton}
                    title="QUESTIONNAIRE"
                />
                <div className={this.styles.sectionItem}>
                    {/* This context definition is here so that questions don't show up having double numbers
                        The undefined value makes src/app/components/DocxHeading/index.js return the legacy rendering for the questionnaire titles
                    */}
                    <DocxHeadingFormattersContext.Provider value={{}}>
                        <QuestionnaireDisplayList
                            canGovUserEdit={canGovUserEdit}
                            isDocx={isDocx}
                            isPricingSealed={isPricingSealed}
                            isResponseDisplay
                            projectId={projectId}
                            proposalId={id}
                            questionnaires={questionnaires}
                            updateQuestionnaireResponse={updateQuestionnaireResponse}
                        />
                    </DocxHeadingFormattersContext.Provider>
                </div>
            </div>
        );
    }
}
