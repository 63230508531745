import moment from 'moment';
import { SUBTYPES, CONTRACT_ASSOCIATIONS } from '@og-pro/shared-config/audits/record-audit';

import { formatValue, formatKey } from './format';
import { getContextualInformation, getInformation } from './information';
import { ACTIONS, IGNORED_FIELDS, ASSOCIATIONS } from '../constants';

export const formatAuditLogs = (rawLogs, tags, governmentId, config = { csv: false }) => {
    const JOIN_CHAR = config.csv ? ' | ' : '\n';

    return rawLogs.reduce((acc, log) => {
        const subtypeParts = log.subtype.split('.');
        const action = subtypeParts.pop();
        const namespace = subtypeParts.join('.');

        const commonProps = {
            user: `${log.user.firstName} ${log.user.lastName}`,
            action: ACTIONS[action],
            namespace: ASSOCIATIONS[namespace] || 'Contract Record Details',
            createdAt: moment.unix(log.createdAt / 1000).format('MM/DD/YYYY HH:mm:ss'),
            originalLog: log,
        };

        if (namespace === CONTRACT_ASSOCIATIONS.SUBSCRIBER) {
            const value = `${log.value.user?.firstName} ${log.value.user?.lastName}`;

            return acc.concat({
                ...commonProps,
                information: getInformation({ namespace, key: '', log }),
                value: action === SUBTYPES.CREATE ? value : '--',
                previousValue: action === SUBTYPES.DELETE ? value : '--',
            });
        }

        if (namespace === CONTRACT_ASSOCIATIONS.NOTIFICATION) {
            const rawValue = `${log.value.offset} ${log.value.offsetUnit} before (${log.context.milestone.name} on ${moment(log.value.dateOfEvent).format('MM/DD/YYYY')})`;
            let value = action === SUBTYPES.CREATE ? rawValue : null;
            let previousValue = action === SUBTYPES.DELETE ? rawValue : null;

            if (action === SUBTYPES.UPDATE) {
                value = `${log.context.notification?.offset} ${log.context.notification?.offsetUnit} before (${log.context.milestone.name} on ${moment(log.context.notification?.dateOfEvent).format('MM/DD/YYYY')})`;
                previousValue = `${log.context.previousNotification?.offset} ${log.context.previousNotification?.offsetUnit} before (${log.context.milestone.name} on ${moment(log.context.previousNotification?.dateOfEvent).format('MM/DD/YYYY')})`;
            }

            return acc.concat({
                ...commonProps,
                information: getContextualInformation({ namespace, log }),
                value,
                previousValue,
            });
        }

        if (action === SUBTYPES.DELETE || action === SUBTYPES.CREATE) {
            const stringifiedValues = Object.keys(log.value || {})
                .reduce((values, key) => {
                    if (
                        IGNORED_FIELDS.includes(key) ||
                        IGNORED_FIELDS.includes(`${namespace}.${key}`)
                    ) {
                        return values;
                    }

                    return values.concat([
                        `${formatKey(namespace, key)}: ${formatValue(log, key, 'value', tags)}`,
                    ]);
                }, [])
                .join(JOIN_CHAR);

            return acc.concat({
                ...commonProps,
                information: getContextualInformation({ namespace, log }),
                value: action === SUBTYPES.CREATE ? stringifiedValues : '--',
                previousValue: action === SUBTYPES.DELETE ? stringifiedValues : '--',
            });
        }

        return acc.concat(
            Object.keys(log.value || {}).reduce((parsedLogs, key) => {
                if (
                    IGNORED_FIELDS.includes(key) ||
                    IGNORED_FIELDS.includes(`${namespace}.${key}`)
                ) {
                    return parsedLogs;
                }

                return parsedLogs.concat([
                    {
                        ...commonProps,
                        information: getInformation({ namespace, key, log }),
                        value: formatValue(log, key, 'value', tags),
                        previousValue: log.previousValue
                            ? formatValue(log, key, 'previousValue', tags)
                            : '--',
                    },
                ]);
            }, [])
        );
    }, []);
};
