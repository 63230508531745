import { createSelector } from 'reselect';

const getRawRequestTypes = (state) => state.requestTypes.get('list');

export const getRequestTypes = createSelector(
    [getRawRequestTypes],
    (rawRequestTypes) => rawRequestTypes?.toJS() || []
);

const getRawRequestType = (state) => state.requestTypes.get('requestType');

export const getRequestType = createSelector([getRawRequestType], (rawRequestType) =>
    rawRequestType?.toJS()
);
