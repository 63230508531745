export const IGNORED_FIELDS = [
    'id',
    'contact_id',
    'updated_at',
    'created_at',
    'contact.contractContact',
    'contact.user_id',
    'procurement_contact_id',
    'checklist.lastUpdatedAt',
    'contactPhoneComplete',
    'procurementPhoneComplete',
    'department_id',
    'procurementInitials',
    'contactInitials',
    'procurementDisplayName',
    'contactDisplayName',
    'renewal.contract_id',
    'insurance.contract_id',
    'insurance.tag',
    'milestone.contractInsuranceMilestones',
    'milestone.isAnnualEvaluation',
    'milestone.isEndDate',
    'milestone.isInsuranceExpiration',
    'milestone.isStartDate',
    'milestone.contract_id',
    'purchaseOrder.budget_id',
    'purchaseOrder.contract_party_id',
    'purchaseOrder.status',
    'purchaseOrder.user_id',
    'review.user_id',
    'review.contract_id',
    'review.complaint.complaint',
    'review.complaint.contract_review_id',
    'checklist.template',
    'checklist.template_id',
    'checklist.updater_id',
    'checklist.government_id',
    'checklist.isTemplate',
    'checklist.user_id',
    'checklist.contract_id',
    'checklist.questionnaire.sharedId',
    'checklist.questionnaire.isTitle',
    'checklist.questionnaire.isPublic',
    'checklist.questionnaire.questionnaireResponses',
    'checklist.questionnaire.checklistQuestions',
    'checklist.questionnaire.containsPricing',
    'checklist.questionnaire.data',
    'attachment.fileExtension',
    'attachment.path',
    'attachment.sharedId',
    'attachment.bucket',
    'attachment.type',
    'budgetAllocation.budget_id',
    'budgetAllocation.streamingRefreshDate',
];

export const FIELDNAMES = {
    title: 'Contract Record Title',
    contractId: 'Contract Record ID',
    requisitionIdentifier: 'Request ID',
    purchaseOrderNo: 'Purchase Order Number (PO)',
    startDate: 'Start Date',
    endDate: 'End Date',
    durationType: 'Duration Type',
    status: 'Status',
    initialTermLength: 'Initial Term Length',
    initialTermLengthUnit: 'Initial Term Length Unit',
    vendorAssignedNo: 'Vendor Assigned Number',
    contactPhone: 'Project Contact Phone',
    contactEmail: 'Project Contact Email',
    contactFirstName: 'Project Contact First Name',
    contactLastName: 'Project Contact Last Name',
    contactFullName: 'Project Contact Full Name',
    contactTitle: 'Project Contact Title',
    contactAddress: 'Project Contact Address',
    contactCity: 'Project Contact City',
    procurementPhone: 'Procurement Contact Phone',
    procurementEmail: 'Procurement Contact Email',
    procurementFirstName: 'Procurement Contact First Name',
    procurementLastName: 'Procurement Contact Last Name',
    procurementFullName: 'Procurement Contact Full Name',
    procurementTitle: 'Procurement Contact Title',
    procurementAddress: 'Procurement Contact Address',
    procurementCity: 'Procurement Contact City',
    departmentName: 'Department',
    departmentHead: 'Department Head',
    departmentHeadTitle: 'Department Head Title',
    project_id: 'Associated Project',
    hasProtest: 'Bid Protest',
    isEmergency: 'Emergency good or service?',
    hasClaim: 'Contract Claim',
    isCooperative: 'Acquired via Cooperative',
    isPiggyback: 'Contains Cooperative Language',
    summary: 'Summary',
    note: 'Notes',
    categories: 'Categories',
    procurement_tag_id: 'Procurement Classification',
    tags: 'Contract Type',
    fundingSourceTags: 'Funding Source',
    'milestone.name': 'Name',
    'milestone.date': 'Date',
    'renewal.length': 'Length',
    'renewal.lengthUnit': 'Length Unit',
    'renewal.selected': 'Selected',
    'insurance.approvedDate': 'Approved Date',
    'insurance.expirationDate': 'Expiration Date',
    'insurance.requestedDate': 'Requested Date',
    'insurance.tag_id': 'Insurance Type',
    'contact.country': 'Country',
    'contact.zipCode': 'Zip Code',
    'contact.phoneExt': 'Phone Extension',
    'contact.lastName': 'Last Name',
    'contact.address2': 'Address 2',
    'contact.city': 'City',
    'contact.displayName': 'Display Name',
    'contact.initials': 'Initials',
    'contact.address1': 'Address 1',
    'contact.fullName': 'Full Name',
    'contact.phoneCountry': 'Phone Country',
    'contact.firstName': 'First Name',
    'contact.phoneComplete': 'Phone Complete',
    'contact.phone': 'Phone',
    'contact.countryCode': 'Country Code',
    'contact.tag_id': 'Tag ID',
    'contact.state': 'State',
    'contact.email': 'Email',
    'purchaseOrder.date': 'Date',
    'purchaseOrder.amount': 'Amount',
    'purchaseOrder.notes': 'Notes',
    'purchaseOrder.description': 'Description',
    'purchaseOrder.isPaid': 'Paid',
    'purchaseOrder.isIssued': 'Issued',
    'purchaseOrder.number': 'Purchase Order Number',
    'purchaseOrder.statusText': 'Status Text',
    'purchaseOrder.tag_id': 'Tag',
    'purchaseOrder.paidAmount': 'Paid Amount',
    'review.isPublished': 'Published',
    'review.type': 'Type',
    'review.status': 'Contract Status',
    'review.overallRating': 'Rating',
    'review.comment': 'Comment',
    'review.complaint.request': 'Request',
    'review.complaint.notes': 'Notes',
    'review.complaint.resolvedAt': 'Resolved At',
    'review.complaint.complaint': 'Complaint',
    'review.complaint.contract_review_id': 'Contract Review ID',
    'review.complaint.contract': 'Contract',
    'review.complaint.submittedAt': 'Submitted At',
    'review.complaint.isResolved': 'Resolved',
    'checklist.status': 'Status',
    'checklist.releasedAt': 'Released At',
    'checklist.questionnaire.attachments': 'Attachments',
    'checklist.questionnaire.data': 'Data',
    'checklist.questionnaire.orderById': 'Order',
    'checklist.questionnaire.isRequired': 'Required',
    'checklist.questionnaire.type': 'Type',
    'checklist.questionnaire.title': 'Title',
    'checklist.questionnaire.prompt': 'Prompt',
    'attachment.filename': 'Filename',
    'attachment.notes': 'Notes',
    'attachment.tags': 'Document Type',
    'attachment.isPublic': 'Public',
    'budget.amount': 'Amount',
    'budgetAllocation.amount': 'Amount',
    'budgetAllocation.tag_id': 'Fiscal Year',
};
