import { useCallback, useRef } from 'react';

import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';

/**
 * Custom hook to detect clicks inside and outside the RequestTypeForm container.
 * NOTE: all components that use popovers (e.g. MUI Select) should be rendered with the `disablePortal` prop set to true
 *
 * @param {Function} closeSidebarCallback Callback to close the sidebar when a click is detected outside the form container
 * @returns {Object} An object containing:
 * - `sectionRefs` (Record<string, RefObject>): a mapping of request type sections to refs, to be passed along to the respective section component
 * - `stickySidebarRef` (RefObject): ref to be passed along to the StickySidebar component
 * - `handleClickInsideFormContainer` (function): click handler to be passed into the RequestTypeForm container where clicks are detected
 */
export const useDeselectOnClick = (closeSidebarCallback) => {
    const generalInfoRef = useRef(null);
    const purchaseDetailsRef = useRef(null);
    const vendorSelectionRef = useRef(null);
    const customFormRef = useRef(null);
    const additionalInfoRef = useRef(null);
    const attachmentsRef = useRef(null);
    const stickySidebarRef = useRef(null);
    const sectionRefs = {
        [GENERAL_INFORMATION]: generalInfoRef,
        [PURCHASE_DETAILS]: purchaseDetailsRef,
        [VENDOR_SELECTION]: vendorSelectionRef,
        [CUSTOM_FORM]: customFormRef,
        [ADDITIONAL_INFORMATION]: additionalInfoRef,
        [ATTACHMENTS]: attachmentsRef,
    };

    const handleClickInsideFormContainer = useCallback(
        (event) => {
            const refs = [
                generalInfoRef,
                purchaseDetailsRef,
                vendorSelectionRef,
                customFormRef,
                additionalInfoRef,
                attachmentsRef,
                stickySidebarRef,
            ];

            if (refs.some((ref) => ref.current?.contains(event.target))) {
                // clicked in an interactive element, do nothing
            } else {
                // clicked inside the form but not on an interactive element, close the sidebar
                closeSidebarCallback();
            }
        },
        [
            generalInfoRef,
            purchaseDetailsRef,
            vendorSelectionRef,
            customFormRef,
            additionalInfoRef,
            attachmentsRef,
        ]
    );

    return {
        sectionRefs,
        stickySidebarRef,
        handleClickInsideFormContainer,
    };
};
