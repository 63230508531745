import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getContractJS } from '../../selectors';
import connectData from '../../ConnectData';
import { LoadingError, LoadingSpinner } from '../../../components';
import { ContractHeader } from '../../../components/contracts';
import { loadContract } from '../../../actions/contracts';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);

    // Only load the contract if it is not the current contract
    const shouldLoadContract = getState().contracts.getIn(['contract', 'id']) !== contractId;

    if (shouldLoadContract) {
        return dispatch(loadContract(contractId, true));
    }
}

const mapStateToProps = (state, props) => {
    return {
        contract: getContractJS(state),
        isContainedByProject: !!props.params.projectId,
        loadContractError: state.contracts.get('loadContractError'),
        loadingContract: !!state.contracts.get('loadingContract'),
    };
};

// @connectData
// @connect
export class ConnectedContractNav extends Component {
    static propTypes = {
        contract: PropTypes.object,
        isContainedByProject: PropTypes.bool.isRequired,
        loadContractError: PropTypes.string,
        loadingContract: PropTypes.bool.isRequired,
    };

    render() {
        const { contract, isContainedByProject, loadContractError, loadingContract } = this.props;

        if (loadingContract) {
            return <LoadingSpinner />;
        }

        if (loadContractError || !contract) {
            return <LoadingError error={loadContractError} />;
        }

        const contractComponent = (
            <>
                <ContractHeader
                    contract={contract}
                    isContainedByProject={isContainedByProject}
                    isPublicView
                />
                <Outlet />
            </>
        );

        if (isContainedByProject) {
            return contractComponent;
        }

        return (
            <div className="row">
                <div className="col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                    <Panel>
                        <Panel.Body>{contractComponent}</Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }
}

export const ContractNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps)
)(ConnectedContractNav);
