import React from 'react';
import { Box } from '@og-pro/ui';
import { NavBar } from '@opengov/components-nav-bar';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { useGlobalNavbarConfig } from './config';

export const GlobalNavbar = () => {
    require('./styles.scss');

    // gov user
    const globalNavbarConfig = useGlobalNavbarConfig();

    const navBarConfig = {
        appName: 'Procurement',
        ...globalNavbarConfig,
    };

    return (
        <Box
            marginBottom={2.5}
            sx={{
                /**
                 * Condensed font for the app name.
                 * Due to the hybrid state of design in the app, there are a lot of conflicting font families.
                 * This is defined in the Global Nav library, but was being overriden by other styles in the app.
                 */
                '[data-test="nav_app_name_container"] h2.MuiTypography-root': {
                    fontFamily: `${capitalDesignTokens.foundations.typography.baseFontCondensed} !important`,
                },
            }}
        >
            <NavBar {...navBarConfig} />
        </Box>
    );
};
