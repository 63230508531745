import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { StepSectionLayout } from './StepSectionLayout';
import { reviewSequenceStepFieldNames } from '../../../constants';
import { qaTagPageName } from '../../../../constants';
import { Toggle } from '../../../../../../../../components';
import { getIsGovernmentUsingFMS } from '../../../../../../../../selectors/govApp';

const {
    ALLOW_CUSTOM_FORM_EDIT,
    ALLOW_GENERAL_EDIT,
    ALLOW_OVER_BUDGET,
    ALLOW_PRICE_EDIT,
    ALLOW_VENDOR_EDIT,
    ALLOW_AD_HOC_STEPS,
    REQUIRE_OVER_BUDGET_NOTE,
    REQUIRE_VENDOR,
} = reviewSequenceStepFieldNames;

export const ReviewerConfigSection = ({
    allowOverBudget,
    allowVendorEdit,
    change,
    disabled,
    fieldName,
    reviewType,
}) => {
    const governmentHasFMS = useSelector(getIsGovernmentUsingFMS);
    const isCustomizingRequestWorkflowEnabled = useFlags(
        FLAGS.ENABLE_CUSTOMIZING_REQUEST_WORKFLOW_MID_REQUEST
    );

    const onAllowVendorEditChange = (e, value) => {
        if (!value) {
            change(`${fieldName}.${REQUIRE_VENDOR}`, false);
        }
    };

    const isApproveReviewType = reviewType === reviewTypes.APPROVE;

    return (
        <StepSectionLayout title="Reviewer Configuration">
            {isApproveReviewType && (
                <>
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        label="Allow General Information Editing"
                        labelInline
                        name={`${fieldName}.${ALLOW_GENERAL_EDIT}`}
                        qaTag={`${qaTagPageName}-${ALLOW_GENERAL_EDIT}`}
                        tooltip="Allow reviewers of this step to edit general information on the request"
                    />
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        label="Allow Custom Form Editing"
                        labelInline
                        name={`${fieldName}.${ALLOW_CUSTOM_FORM_EDIT}`}
                        qaTag={`${qaTagPageName}-${ALLOW_CUSTOM_FORM_EDIT}`}
                        tooltip="Allow reviewers of this step to edit responses to the custom form questions"
                    />
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        label="Allow Purchase Details Editing"
                        labelInline
                        name={`${fieldName}.${ALLOW_PRICE_EDIT}`}
                        qaTag={`${qaTagPageName}-${ALLOW_PRICE_EDIT}`}
                        tooltip="Allow reviewers of this step to edit descriptions, notes, and account numbers on line items of the request. Dollar amounts and quantities cannot be edited."
                    />
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        label="Allow Vendor Selection"
                        labelInline
                        name={`${fieldName}.${ALLOW_VENDOR_EDIT}`}
                        onChange={onAllowVendorEditChange}
                        qaTag={`${qaTagPageName}-${ALLOW_VENDOR_EDIT}`}
                        tooltip="Allow reviewers of this step to select and edit vendors for the request"
                    />
                    <Field
                        component={Toggle}
                        disabled={!allowVendorEdit || disabled}
                        help={
                            allowVendorEdit
                                ? undefined
                                : 'Allow Vendor Selection must be enabled to select this option'
                        }
                        label="Require Vendor Selection"
                        labelInline
                        name={`${fieldName}.${REQUIRE_VENDOR}`}
                        qaTag={`${qaTagPageName}-${REQUIRE_VENDOR}`}
                        tooltip={`${
                            allowVendorEdit
                                ? ''
                                : 'IMPORTANT: Reviewer must be given Vendor Selection permission to enable this option. '
                        }Requires at least one vendor be selected before completing this step.`}
                    />
                    {governmentHasFMS && (
                        <>
                            <Field
                                component={Toggle}
                                disabled={disabled}
                                label="Allow Over Budget Approvals"
                                labelInline
                                name={`${fieldName}.${ALLOW_OVER_BUDGET}`}
                                qaTag={`${qaTagPageName}-${ALLOW_OVER_BUDGET}`}
                                tooltip="Allow reviewers of this step to send the request to the next step without correcting a budget check failure"
                            />
                            {allowOverBudget && (
                                <Field
                                    component={Toggle}
                                    disabled={disabled}
                                    label="Require Over Budget Justification"
                                    labelInline
                                    name={`${fieldName}.${REQUIRE_OVER_BUDGET_NOTE}`}
                                    qaTag={`${qaTagPageName}-${REQUIRE_OVER_BUDGET_NOTE}`}
                                    tooltip="Require reviewers to provide justification for overriding a budget check failure (optional otherwise)"
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {isCustomizingRequestWorkflowEnabled && (
                <Field
                    component={Toggle}
                    disabled={disabled}
                    label="Allow Ability to Add Step"
                    labelInline
                    name={`${fieldName}.${ALLOW_AD_HOC_STEPS}`}
                    qaTag={`${qaTagPageName}-${ALLOW_AD_HOC_STEPS}`}
                    tooltip="Allow reviewers of this step to add additional step(s) for input."
                />
            )}
        </StepSectionLayout>
    );
};

ReviewerConfigSection.propTypes = {
    allowOverBudget: PropTypes.bool,
    allowVendorEdit: PropTypes.bool,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    reviewType: PropTypes.number,
};
