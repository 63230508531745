import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import { Typography, Box } from '@og-pro/ui';

import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

// Used to add user profile pictures to the `SearchSelect` component options
export const SearchSelectUserOption = (props) => {
    const {
        data: { user, label, description },
        userKey,
    } = props;

    const userData = userKey ? props.data[userKey] : user;

    return (
        <components.Option {...props}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UserProfilePicture horizontal user={userData} />
                <Box sx={{ ml: 1 }}>
                    <Typography variant="bodyDefault">{label}</Typography>
                    {description && (
                        <Typography color="text.secondary" variant="bodySmall">
                            {description}
                        </Typography>
                    )}
                </Box>
            </Box>
        </components.Option>
    );
};

SearchSelectUserOption.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string,
        user: PropTypes.object,
    }).isRequired,
    userKey: PropTypes.string,
};
