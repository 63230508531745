import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { AgGridReact } from '@og-pro/ui';

import { CACHE_BLOCK_SIZE, DEFAULT_PAGE_SIZE, defaultColDef } from './constants';

export const SubscribersTable = ({ columns, onGridReady, handleSelectionChanged, isPublic }) => {
    const getRowId = useMemo(() => (row) => row.data.subscriberEmail, []);

    const selectionColumnDef = useMemo(() => {
        return {
            pinned: 'left',
            width: 55,
        };
    }, []);

    const rowSelection = useMemo(() => {
        return {
            headerCheckbox: false,
            mode: 'multiRow',
        };
    }, []);

    return (
        <AgGridReact
            cacheBlockSize={CACHE_BLOCK_SIZE}
            columnDefs={columns.map((column) =>
                omit(column, ['isPublic', 'subscriberViewOnly', 'defaultHidden'])
            )}
            defaultColDef={defaultColDef}
            getRowHeight={() => 52}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onSelectionChanged={handleSelectionChanged}
            pagination
            paginationPageSize={DEFAULT_PAGE_SIZE}
            paginationPageSizeSelector={false}
            rowModelType="serverSide"
            rowSelection={isPublic ? null : rowSelection}
            selectionColumnDef={isPublic ? null : selectionColumnDef}
        />
    );
};

SubscribersTable.propTypes = {
    columns: PropTypes.array.isRequired,
    onGridReady: PropTypes.func.isRequired,
    handleSelectionChanged: PropTypes.func.isRequired,
    isPublic: PropTypes.bool,
};
