import { get } from 'lodash';

import {
    checklistStatusTypes,
    questionnaireReviewStatusTypes,
} from '@og-pro/shared-config/checklists';
import { contractStatusesDict } from '@og-pro/shared-config/contracts';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

const {
    AUCTION_PENDING,
    AWARD_PENDING,
    CLOSED,
    DRAFT,
    EVALUATION,
    FINAL,
    OPEN,
    PENDING,
    POST_PENDING,
    REQUEST_DRAFT,
    REQUEST_REVIEW,
    REVERSE_AUCTION,
    REVIEW,
} = projectStatusesDict;

const { NO_BID, PUBLISHED } = proposalStatusesDict;

const { ACTIVE, ENDED, UPCOMING } = contractStatusesDict;

const { DRAFT: CHECKLIST_DRAFT, RELEASED, COMPLETE } = checklistStatusTypes;

const {
    APPROVED: CHECKLIST_APPROVED,
    PENDING: CHECKLIST_PENDING,
    REVIEW: CHECKLIST_REVIEW,
} = questionnaireReviewStatusTypes;

const statusMap = {
    [REQUEST_DRAFT]: {
        class: 'muted',
        icon: 'pencil',
    },
    [REQUEST_REVIEW]: {
        class: 'danger',
        icon: 'user',
    },
    [DRAFT]: {
        class: 'muted',
        icon: 'pencil',
    },
    [REVIEW]: {
        class: 'royal',
        icon: 'user',
    },
    [POST_PENDING]: {
        class: 'warning',
        icon: 'flag-checkered',
    },
    [OPEN]: {
        class: 'success',
        icon: 'flag',
    },
    [AUCTION_PENDING]: {
        class: 'warning',
        icon: 'clock-o',
    },
    [REVERSE_AUCTION]: {
        class: 'success',
        icon: 'gavel',
    },
    [PENDING]: {
        class: 'warning',
        icon: 'clock-o',
    },
    [EVALUATION]: {
        class: 'royal',
        icon: 'star',
    },
    [AWARD_PENDING]: {
        class: 'warning',
        icon: 'trophy',
    },
    [CLOSED]: {
        class: 'primary',
        icon: 'check',
    },

    // Non-marketplace government statuses
    [FINAL]: {
        class: 'primary',
        icon: 'file-text',
    },

    // Vendor specific statuses
    [NO_BID]: {
        class: 'warning',
        icon: 'ban',
    },
    [PUBLISHED]: {
        class: 'success',
        icon: 'check',
    },
};

export function statusClass(status) {
    const defaultClass = 'primary';
    return get(statusMap, [status, 'class'], defaultClass);
}

export function statusIcon(status) {
    const defaultIcon = 'edit';
    return get(statusMap, [status, 'icon'], defaultIcon);
}

export function getStatusColor(status) {
    switch (status) {
        case OPEN:
        case REVERSE_AUCTION:
            return 'semanticColors.foreground.successSmall';
        case PENDING:
        case AWARD_PENDING:
            return 'semanticColors.foreground.warningSmall';
        case EVALUATION:
            return 'semanticColors.foreground.inProgressSmall';
        case CLOSED:
        case FINAL:
            return 'semanticColors.callToAction.primaryActionDark';
        default:
            return 'semanticColors.foreground.primary';
    }
}

const contractStatusMap = {
    [ACTIVE]: {
        class: 'success',
        icon: 'check',
        text: 'text-success',
    },
    [ENDED]: {
        class: 'muted',
        icon: 'ban',
    },
    [UPCOMING]: {
        class: 'primary',
        icon: 'clock-o',
        text: 'text-info',
    },
};

export function contractStatusClass(status) {
    const defaultClass = 'primary';
    return get(contractStatusMap, [status, 'class'], defaultClass);
}

export function contractStatusIcon(status) {
    const defaultIcon = 'edit';
    return get(contractStatusMap, [status, 'icon'], defaultIcon);
}

export function contractStatusText(status) {
    return get(contractStatusMap, [status, 'text']);
}

const checklistStatusMap = {
    [CHECKLIST_DRAFT]: {
        class: 'muted',
        icon: 'pencil',
    },
    [RELEASED]: {
        class: 'success',
        icon: 'flag',
    },
    [COMPLETE]: {
        class: 'primary',
        icon: 'check',
    },
};

export function checklistStatusClass(status) {
    const defaultClass = 'primary';
    return get(checklistStatusMap, [status, 'class'], defaultClass);
}

export function checklistStatusIcon(status) {
    const defaultIcon = 'edit';
    return get(checklistStatusMap, [status, 'icon'], defaultIcon);
}

const CHECKLIST_PENDING_ACTION = 'pendingAction';

const checklistQuestionnaireReviewStatusMap = {
    [CHECKLIST_REVIEW]: {
        class: 'primary',
        icon: 'user',
    },
    [CHECKLIST_PENDING]: {
        class: 'muted',
        icon: 'clock-o',
    },
    [CHECKLIST_PENDING_ACTION]: {
        class: 'primary',
        icon: 'pencil',
    },
    [CHECKLIST_APPROVED]: {
        class: 'success',
        icon: 'check',
    },
};

export function checklistQuestionnaireReviewStatusClass(status, isActionable) {
    const defaultClass = 'primary';

    let statusToUse = status;
    if (isActionable && status === CHECKLIST_PENDING) {
        statusToUse = CHECKLIST_PENDING_ACTION;
    }

    return get(checklistQuestionnaireReviewStatusMap, [statusToUse, 'class'], defaultClass);
}

export function checklistQuestionnaireReviewStatusIcon(status, isActionable) {
    const defaultIcon = 'check';

    let statusToUse = status;
    if (isActionable && status === CHECKLIST_PENDING) {
        statusToUse = CHECKLIST_PENDING_ACTION;
    }

    return get(checklistQuestionnaireReviewStatusMap, [statusToUse, 'icon'], defaultIcon);
}

export function checklistQuestionnaireReviewTooltip(status, isActionable, isComplete) {
    if (isComplete) {
        return 'Item complete';
    }
    if (status === CHECKLIST_APPROVED) {
        return 'Item has been approved';
    }
    if (status === CHECKLIST_PENDING) {
        return isActionable ? 'Editing response' : 'Awaiting changes';
    }
    if (status === CHECKLIST_REVIEW) {
        return 'Reviewing item';
    }
}
