import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { Well } from '../../../../Well/Well';

export const DisqualifiedWell = ({
    proposal: { disqualifiedAt, disqualifiedReason, disqualifier },
}) => {
    const styles = require('./shared.scss');
    const disqualifierName =
        disqualifier && disqualifier.displayName
            ? `${disqualifier.displayName} disqualified this response`
            : 'The response was disqualified';
    const info = disqualifiedReason
        ? `${disqualifierName} on ${moment(disqualifiedAt).format(
              'lll'
          )} for the following reason:\n`
        : `${disqualifierName} on ${moment(disqualifiedAt).format('lll')}`;

    return (
        <Well>
            <h4 className="text-center text-danger">
                <i className="fa fa-ban" /> Response Disqualified
            </h4>
            <p className={styles.proposalSubmittedInfo}>
                {info}
                {disqualifiedReason && <em>{disqualifiedReason}</em>}
            </p>
        </Well>
    );
};

DisqualifiedWell.propTypes = {
    proposal: PropTypes.shape({
        disqualifiedAt: PropTypes.string.isRequired,
        disqualifiedReason: PropTypes.string.isRequired,
        disqualifier: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }).isRequired,
        isDisqualified: PropTypes.bool.isRequired,
    }).isRequired,
};
