import { useQuery } from '@tanstack/react-query';

import { useEffect, useState } from 'react';

import ApiClient from '../../api/apiClientRequestWrapper';
import { useDebounce } from '../../hooks';

export const PAVILION_SEARCH_QUERY_PREFIX = 'pavilion-search';

const client = new ApiClient();

/**
 * Custom hook to search for contracts on pavilion based on arbitrary search string
 * @param {object} options
 * @param {string | null | undefined} options.searchString
 * @param {boolean} options.enablePavilionSearch
 * @returns {object} React Query useQuery return value
 */
export const usePavilionSearch = ({ searchString, enablePavilionSearch }) => {
    const [debouncedSearchString, setDebouncedSearchString] = useState(searchString);
    const updateDebouncedSearchString = useDebounce((value) => {
        setDebouncedSearchString(value);
    });
    useEffect(() => {
        updateDebouncedSearchString(searchString);
    }, [updateDebouncedSearchString, searchString]);

    const isNonEmpty = !!debouncedSearchString && debouncedSearchString.trim().length > 0;

    const queryOptions = {
        queryKey: [PAVILION_SEARCH_QUERY_PREFIX, debouncedSearchString],
        queryFn: async () => {
            return client.get(
                `/contracts/pavilion/summarized-search?query=${debouncedSearchString}`
            );
        },
        retry: false,
        enabled: enablePavilionSearch && isNonEmpty,
        refetchOnWindowFocus: false,
    };
    return useQuery(queryOptions);
};
