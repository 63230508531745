import { isEmpty } from 'lodash';

import { PROCESS_INFORMATION_SECTIONS_TITLES } from './constants';

export const getProcessInformationSections = ({
    eSignExternalAgreementEnabled,
    useExternalDocument,
    templateProject,
    setActiveSectionId,
    activeSectionId,
    formErrors = {},
    showFormValidation = false,
}) => {
    const sectionsArray = PROCESS_INFORMATION_SECTIONS_TITLES(
        eSignExternalAgreementEnabled,
        useExternalDocument
    );
    const sections = sectionsArray.reduce((acc, section, index) => {
        if (section.shouldHide && section.shouldHide(templateProject)) {
            return acc;
        }

        const errorKeys = Array.isArray(section.errorsKey)
            ? section.errorsKey
            : [section.errorsKey];
        const error = errorKeys.some((errorKey) => {
            if (Array.isArray(formErrors[errorKey])) {
                return formErrors[errorKey].some((e) => !isEmpty(e));
            }

            return !isEmpty(formErrors[errorKey]);
        });

        const data = {
            title: section.title,
            index,
            overrideForTypeContract: section.overrideForTypeContract,
            showValidationError: error && showFormValidation,
            active: activeSectionId === index,
            onClick: () => {
                setActiveSectionId(index);
            },
        };

        return acc.concat([data]);
    }, []);

    return sections;
};
