import { v4 as UUIDv4 } from 'uuid';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { usePrevious } from '../../../../../../../hooks';

// This hook is used to handle changes to the project section type by reshaping the form data
// based on the new section type.
export const useImportSectionHook = ({ projectSection, form, change }) => {
    const projectSectionTypeFromForm = projectSection.section_type;
    const previousProjectSectionType = usePrevious(projectSectionTypeFromForm);
    const formItems = useSelector((state) => formValueSelector(form)(state, 'items')) || [];

    useEffect(() => {
        if (
            projectSectionTypeFromForm &&
            previousProjectSectionType &&
            projectSectionTypeFromForm !== previousProjectSectionType
        ) {
            if (
                previousProjectSectionType !== sectionTypeNames.TEXT_AREA &&
                projectSectionTypeFromForm === sectionTypeNames.TEXT_AREA
            ) {
                const updatedDescription = formItems.map((item) => item.description).join('');
                change('items', [{ ...formItems[0], description: updatedDescription }]);
            }
            if (
                previousProjectSectionType !== sectionTypeNames.EVALUATION_PHASE &&
                projectSectionTypeFromForm === sectionTypeNames.EVALUATION_PHASE
            ) {
                // Reshape data to match the evaluation phase schema
                const evaluationPhase = [
                    {
                        orderById: 1,
                        title: 'Phase 1',
                        uuid: UUIDv4(),
                        scoringCriteria: formItems,
                    },
                ];
                change('evaluationPhases', evaluationPhase);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousProjectSectionType, projectSectionTypeFromForm]);
};

// Scrolls imported sections into view when the number of imported sections changes.
export const useScrollImportedSectionsIntoView = ({ importedSectionsCount }) => {
    // Add refs for scrolling
    const scrollContainerRef = useRef(null);
    const navItemRef = useRef(null);

    const previousImportedSectionsCount = usePrevious(importedSectionsCount);

    // Function to scroll imported sections into view
    const scrollSectionIntoView = useCallback((behavior) => {
        const navItem = navItemRef.current;
        const container = scrollContainerRef.current;

        if (navItem && container) {
            const scrollTop = navItem.offsetTop - container.offsetTop;
            container.scrollTo({
                top: scrollTop,
                behavior,
            });
        }
    }, []);

    useEffect(() => {
        if (previousImportedSectionsCount !== importedSectionsCount && importedSectionsCount > 0) {
            const behavior = previousImportedSectionsCount === 0 ? 'smooth' : 'auto';
            scrollSectionIntoView(behavior);
        }
    }, [importedSectionsCount, previousImportedSectionsCount, scrollSectionIntoView]);

    return {
        scrollContainerRef,
        navItemRef,
    };
};
