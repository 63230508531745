import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';

import { RequisitionAttachment } from '../../FormComponents/Attachments/RequisitionAttachment';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { ATTACHMENTS } from '../../../../../../constants/requisitionsCreate';

export const ReviewAttachments = (props) => {
    const fieldName = props.fieldNamePrefix
        ? `${props.fieldNamePrefix}.${ATTACHMENTS}`
        : ATTACHMENTS;

    const attachments = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldName)
    );

    const title = attachments && attachments.length > 0 ? 'Uploaded Documents' : '';

    return (
        <Box
            sx={{
                border: 'none !important',
            }}
        >
            <RequisitionAttachment renderAsDropPanel title={title} {...props} disabled />
            {(!attachments || attachments.length === 0) && (
                <Typography
                    sx={{
                        color: 'text.secondary',
                        mb: 2,
                    }}
                >
                    No documents attached.
                </Typography>
            )}
        </Box>
    );
};

ReviewAttachments.propTypes = {
    fieldNamePrefix: PropTypes.string,
};
