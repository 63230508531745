import { createSelector } from 'reselect';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';
import { rejectionPolicyTypes } from '@og-pro/shared-config/reviewSequences';

import { getUserJS, isRequisitionGlobalEditorUser } from '../../selectors';
import {
    getRequisitionNextStep,
    getRequisitionCurrentStep,
    getReviewSequenceSteps,
} from '../../../selectors/govApp';

export const getIsLoadingSteps = (state) => state.requisitions.get('loadingReviewSequenceSteps');
export const getLoadStepsError = (state) => state.requisitions.get('loadReviewSequenceStepsError');
export const getIsBackgroundRefreshing = (state) =>
    state.requisitionsApprovals.get('backgroundRefresh');
export const getShowMissingVendorBanner = (state) =>
    state.requisitionsApprovals.get('showMissingVendorBanner');

export const getShowMissingVendorAddressBanner = (state) =>
    state.requisitionsApprovals.get('showMissingVendorAddressBanner');

const getRawRequisitionLogs = (state) => state.requisitionsApprovals.get('requisitionLogs');

export const getCurrentStepUserApprovalsData = createSelector(
    [getUserJS, isRequisitionGlobalEditorUser, getRequisitionCurrentStep],
    (user, isRequisitionGlobalEditor, currentStep) => {
        if (currentStep) {
            const getStepPositionApproverFromStepPositionApproval = (stepPositionApproval) => {
                const { stepPositionApprovers } = stepPositionApproval;

                // Request global editors will always be an approver of the position. Match as follows:
                // 1. If global editor is an approver use their own stepPositionApprover
                // 2. Use the stepPositionApprover for the primary approver
                // 3. Use any stepPositionApprover (this should never be necessary)
                if (isRequisitionGlobalEditor) {
                    return (
                        stepPositionApprovers.find((approver) => approver.user_id === user.id) ||
                        stepPositionApprovers.find((approver) => approver.isPrimary) ||
                        stepPositionApprovers[0]
                    );
                }

                // For regular users, only return a stepPositionApprover if it belongs to the user
                return stepPositionApprovers.find((approver) => approver.user_id === user.id);
            };

            const userApprovals = [];

            currentStep.stepApproval.stepPositionApprovals.forEach((stepPositionApproval) => {
                const stepPositionApprover =
                    getStepPositionApproverFromStepPositionApproval(stepPositionApproval);

                if (stepPositionApprover) {
                    // Include all data associated with the approval
                    userApprovals.push({
                        stepApproval: currentStep.stepApproval,
                        stepPositionApproval,
                        stepPositionApprover,
                    });
                }
            });

            return userApprovals;
        }
        return [];
    }
);

export const getRejectedStep = createSelector([getReviewSequenceSteps], (reviewSequenceSteps) => {
    return reviewSequenceSteps.find(
        ({ stepApproval }) => stepApproval.status === approvalStatusTypes.REJECTED
    );
});

export const getAllRequisitionLogs = createSelector(
    [getRawRequisitionLogs],
    (rawRequisitionLogs) => {
        return rawRequisitionLogs.toJS().reverse();
    }
);

export const getRequestsPath = createSelector([getUserJS], (user) => {
    return `/governments/${user.government_id}/requisitions`;
});

export const getCurrentStepTriageCheck = createSelector([getRequisitionNextStep], (nextStep) => {
    if (nextStep) {
        return nextStep.requireTriage;
    }
});

export const getAddingAdHocStep = (state) => state.requisitions.get('addingAdHocStep');
export const getAddAdHocStepError = (state) => state.requisitions.get('addAdHocStepError');

export const getRequisitionPositionUsers = (state) => state.requisitions.get('users')?.toJS();
export const getLoadingPositionUsers = (state) => state.requisitions.get('loadingUsers');
export const getPositionUsersError = (state) => state.requisitions.get('usersError');

export const getReviewGroupAndEntityUsers = createSelector(
    [getRequisitionPositionUsers],
    (users) => {
        if (!users) return [];

        const formatUsers = (userList) =>
            userList.map((user) => ({
                value: { userId: user.user.id, positionId: user.position.id },
                label: user.label,
                description: user.description,
                displayName: user.displayName,
                user: user.user,
            }));

        return [
            {
                label: 'Entity Users',
                options: formatUsers(users.entityUsers),
            },
            {
                label: 'Review Group Users',
                options: formatUsers(users.reviewGroupUsers),
            },
        ];
    }
);

export const getRejectionPolicyOptions = createSelector(
    [getReviewSequenceSteps],
    (reviewSequenceSteps) => {
        return [
            {
                label: 'Closed - Rejected',
                value: rejectionPolicyTypes.CLOSED_REJECTED,
            },
            {
                label: 'Go Back to Beginning',
                value: rejectionPolicyTypes.BACK_TO_BEGINNING,
            },
        ].concat(
            reviewSequenceSteps.map(({ id, name }, index) => {
                const stepName = name ? `: ${name}` : '';
                return {
                    index,
                    label: `Go Back to Step #${index + 1}${stepName}`,
                    value: `${rejectionPolicyTypes.BACK_SPECIFIED_STEP}.${id}`,
                };
            })
        );
    }
);
