import { createSelector } from 'reselect';

import { getProjectJS } from './sharedSelectors';
import { attachmentObject, mapProposalDocuments, uploadObject } from '../../helpers';
import { generateCompleteProposal } from '../../selectors';

const getProposal = (state) => state.govProposals.get('proposal');

const getBasicProposalJS = createSelector([getProposal], (rawProposal) => {
    if (rawProposal) {
        return rawProposal.toJS();
    }
});

const getProposalUploadObject = createSelector([getBasicProposalJS], (proposal) => {
    if (proposal) {
        return (proposal.uploads || []).reduce(uploadObject, {});
    }
    return {};
});

const getProposalAttachmentObject = createSelector([getBasicProposalJS], (proposal) => {
    if (proposal) {
        return proposal.attachments.reduce(attachmentObject, {});
    }
    return {};
});

export const getProposalDocuments = createSelector(
    [getProjectJS, getProposalAttachmentObject, getProposalUploadObject],
    (project, attachmentObj, uploadObj) => {
        if (project && project.proposalDocuments) {
            return mapProposalDocuments(project.proposalDocuments, attachmentObj, uploadObj);
        }
        return [];
    }
);

export const getProposalJS = createSelector(
    [getBasicProposalJS, getProjectJS, getProposalDocuments],
    generateCompleteProposal
);

// Similar selectors for proposal evaluation reducer
const getProposalForEvaluation = (state) => state.proposalEvaluations.get('proposal');

const getBasicProposalForEvaluationJS = createSelector(
    [getProposalForEvaluation],
    (rawProposalForEvaluation) => {
        if (rawProposalForEvaluation) {
            return rawProposalForEvaluation.toJS();
        }
    }
);

const getProposalForEvaluationAttachmentObject = createSelector(
    [getBasicProposalForEvaluationJS],
    (proposalForEvaluation) => {
        if (proposalForEvaluation) {
            return proposalForEvaluation.attachments.reduce(attachmentObject, {});
        }
        return {};
    }
);

const getProposalForEvaluationDocuments = createSelector(
    [getProjectJS, getProposalForEvaluationAttachmentObject],
    (project, attachmentObj) => {
        if (project && project.proposalDocuments) {
            return mapProposalDocuments(project.proposalDocuments, attachmentObj);
        }
        return [];
    }
);

export const getProposalForEvaluationJS = createSelector(
    [getBasicProposalForEvaluationJS, getProjectJS, getProposalForEvaluationDocuments],
    generateCompleteProposal
);
