import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../Button';
import { prependUrl } from '../../../../utils';

export class SelectedProposal extends PureComponent {
    static propTypes = {
        canContract: PropTypes.bool,
        contractPath: PropTypes.string.isRequired,
        createContractHandler: PropTypes.func,
        isContractAdmin: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        proposal: PropTypes.shape({
            companyName: PropTypes.string.isRequired,
            contactWebsite: PropTypes.string,
            isGovernmentSubmitted: PropTypes.bool,
            user_id: PropTypes.number,
            contract: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }),
            id: PropTypes.number.isRequired,
            vendor: PropTypes.shape({
                organization: PropTypes.shape({
                    logo: PropTypes.string.isRequired,
                    website: PropTypes.string,
                }).isRequired,
            }),
        }).isRequired,
        proposalPath: PropTypes.string,
        isVendorAdmin: PropTypes.bool.isRequired,
        showVendorListModal: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    createContract = () => {
        const { createContractHandler, proposal } = this.props;

        createContractHandler(proposal);
    };

    renderLogo() {
        const {
            proposal: { companyName, vendor },
        } = this.props;

        const logoUrl =
            get(vendor, 'organization.logo') ||
            'https://assets.procurement.opengov.com/assets/no-logo.png';

        return (
            <img
                alt={`${companyName} logo`}
                className={`img-rounded ${this.styles.logo}`}
                src={logoUrl}
            />
        );
    }

    renderProposalButton() {
        const { isGovernmentView, proposal, proposalPath } = this.props;

        if (!isGovernmentView || !proposalPath) {
            return null;
        }

        return (
            <div>
                <Button
                    block
                    bsSize="sm"
                    className={this.styles.proposalButton}
                    to={`${proposalPath}/${proposal.id}`}
                >
                    View Response
                </Button>
            </div>
        );
    }

    renderContractButton() {
        const {
            canContract,
            contractPath,
            isContractAdmin,
            proposal: { contract },
        } = this.props;

        if (contract) {
            return (
                <Button block bsSize="sm" to={`${contractPath}/${contract.id}`}>
                    View Contract
                </Button>
            );
        }

        if (canContract) {
            return (
                <Button
                    block
                    bsSize="sm"
                    bsStyle="success"
                    disabled={!isContractAdmin}
                    onClick={this.createContract}
                    tooltip={isContractAdmin ? undefined : 'Only contract admins can add contracts'}
                >
                    <i className="fa fa-plus" /> Add Contract
                </Button>
            );
        }
    }

    renderAddVendorToListButton() {
        const {
            isVendorAdmin,
            proposal: { isGovernmentSubmitted, user_id: userId },
            showVendorListModal,
        } = this.props;

        if (isVendorAdmin && !isGovernmentSubmitted && userId) {
            return (
                <Button
                    block
                    bsSize="sm"
                    onClick={() => showVendorListModal(userId)}
                    tooltip="Add this vendor to a list"
                >
                    <i className="fa" /> Add Vendor to List
                </Button>
            );
        }
    }

    render() {
        const { isGovernmentView, proposal, proposalPath } = this.props;

        const hasAdditionalInfo = isGovernmentView || !!proposal.contract;
        const website = proposal.contactWebsite || get(proposal, 'vendor.organization.website');

        return (
            <div className={`row ${this.styles.vendor}`}>
                <div className={`col-sm-${hasAdditionalInfo ? 9 : 12}`}>
                    {this.renderLogo()}
                    <div className={this.styles.vendorInfo}>
                        <h4>{proposal.companyName}</h4>
                        {website && (
                            <div className={this.styles.vendorWebsite}>
                                <a
                                    href={prependUrl(website)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {website}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                {hasAdditionalInfo && (
                    <div className={`col-sm-3 ${this.styles.contractButton}`}>
                        {this.renderContractButton()}
                        {isGovernmentView && proposalPath && (
                            <Button
                                block
                                bsSize="sm"
                                className={this.styles.proposalButton}
                                to={`${proposalPath}/${proposal.id}`}
                            >
                                View Response
                            </Button>
                        )}
                        {this.renderAddVendorToListButton()}
                    </div>
                )}
            </div>
        );
    }
}
