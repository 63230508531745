import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import {
    SubscribedVendorCertificationChart,
    SubscribedVendorCertificationTable,
} from '../../../../components/vendors';
import { VendorManagementHeader } from '../VendorManagementHeader';
import { getPortalUrl } from '../../App/selectors';

export const VendorCertificationReport = () => {
    const params = useParams();

    const governmentId = Number.parseInt(params.governmentId, 10);
    const portalUrl = useSelector(getPortalUrl);

    return (
        <>
            <VendorManagementHeader governmentId={governmentId} portalUrl={portalUrl} />
            <Box
                pt={3}
                px={7}
                sx={{
                    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
                    pt: 3,
                    pb: 7,
                    px: 7,
                }}
            >
                <SubscribedVendorCertificationChart />
                <SubscribedVendorCertificationTable governmentId={governmentId} />
            </Box>
        </>
    );
};
