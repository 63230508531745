import React, { useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact, Box, Button, Typography } from '@og-pro/ui';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Download as DownloadIcon } from '@mui/icons-material';

import { loadContractAuditLogs } from '../../../../../actions/contracts';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { formatAuditLogs } from './helpers';
import { COLUMNS } from './constants';
import { exportArrayToCSV } from '../../../../../utils';

export const ContractAudit = () => {
    const dispatch = useDispatch();
    const tags = useSelector((state) => {
        const rawTags = state.tags.get('tags');

        if (!rawTags) {
            return {};
        }

        return rawTags.toJS().reduce((acc, tag) => {
            acc[tag.id] = tag.name;

            return acc;
        }, {});
    });
    const { contractId, governmentId } = useParams();
    const { data, isFetching } = useQuery({
        queryKey: ['contractAuditLogs', contractId],
        queryFn: () => dispatch(loadContractAuditLogs(contractId)),
        refetchOnWindowFocus: false,
    });

    const onExport = useCallback(() => {
        if (!data?.records) {
            return;
        }

        const formatted = orderBy(
            formatAuditLogs(data.records, tags, governmentId, { csv: true }),
            'originalLog.createdAt',
            'asc'
        );
        const headers = COLUMNS.map((c) => c.headerName);
        const rows = formatted.map((audit) => {
            return COLUMNS.map((c) => audit[c.field]);
        });

        exportArrayToCSV([headers].concat(rows), { fileName: 'contract-audit-log' });
    }, [data, governmentId, tags]);

    const formattedLogs = useMemo(() => {
        if (data) {
            return formatAuditLogs(data.records, tags, governmentId);
        }

        return data;
    }, [data, governmentId, tags]);

    if (isFetching) {
        return <LoadingSpinner text="Loading..." useOpenGovStyle />;
    }

    return (
        <Box>
            <Box display="flex" sx={{ mb: 3 }}>
                <Box flex={1}>
                    <Typography variant="h2">Contract Audit Log</Typography>
                </Box>
                <Box alignSelf="flex-end" sx={{ textAlign: 'right' }}>
                    <Button
                        color="secondary"
                        disabled={!data?.records?.length}
                        onClick={onExport}
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                    >
                        Export CSV
                    </Button>
                </Box>
            </Box>

            {!!data && (
                <Box>
                    <AgGridReact
                        columnDefs={COLUMNS}
                        defaultColDef={{
                            cellStyle: {
                                lineHeight: 1.5,
                                paddingBlock: '12px',
                            },
                            resizable: true,
                            sortable: false,
                            filter: 'agTextColumnFilter',
                            suppressHeaderMenuButton: true,
                            suppressHeaderContextMenu: true,
                            wrapText: false,
                        }}
                        domLayout="autoHeight"
                        pagination
                        paginationPageSize={100}
                        paginationPageSizeSelector={false}
                        rowData={formattedLogs}
                    />
                </Box>
            )}
        </Box>
    );
};
