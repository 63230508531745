import React from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    ReduxFormSelect,
    ReduxFormTextField,
    Typography,
} from '@og-pro/ui';
import { Warning as WarningIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { useDispatch } from 'react-redux';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    alertStyle,
    buttonsStyle,
    formSectionStyle,
    modalContentStyle,
    modalFooterStyle,
} from './styles';
import {
    CLOSED_SUBSTATUS_FIELD,
    CLOSE_OUT_REASON_FIELD,
    closeIntakeOptions,
    closeProjectOptions,
} from './constants';
import { validate } from './validate';
import { govUpdateProject, updateClosedSubstatus } from '../../../actions/govProjects';
import { trackEvent } from '../../../helpers';

const { CLOSED } = projectStatusesDict;

const ProjectCloseOutModalFooter = () => (
    <>
        <WarningIcon fontSize="small" />
        <Typography variant="bodySmall">
            <strong>Please Note:</strong> The closure comment will be <strong>publicly</strong>{' '}
            visible.
        </Typography>
    </>
);

const formConfig = {
    form: 'projectCloseOutForm',
    validate,
};

const ConnectedProjectCloseOutModal = ({
    handleSubmit,
    hideDialog,
    isUpdate = false,
    open,
    project,
    submitFailed,
}) => {
    const dispatch = useDispatch();

    const onSubmit = (rawData) => {
        trackEvent('Project Closed Out');
        trackEvent('Project Status Update', {
            projectId: project.id,
            oldStatus: project.status,
            newStatus: CLOSED,
        });

        // trim whitespace and check if reason is empty
        // if empty, we can set the reason to null instead of an empty string
        const trimmedReason = rawData[CLOSE_OUT_REASON_FIELD]?.trim();
        const closeOutReason = trimmedReason || null;

        if (isUpdate) {
            dispatch(
                updateClosedSubstatus(project.id, {
                    closedSubstatus: rawData[CLOSED_SUBSTATUS_FIELD],
                    closeOutReason,
                })
            );
        } else {
            dispatch(
                govUpdateProject(
                    project,
                    {
                        status: CLOSED,
                        closedSubstatus: rawData[CLOSED_SUBSTATUS_FIELD],
                        closeOutReason,
                    },
                    { snackbarMessage: 'Project closed' }
                )
            );
        }

        hideDialog();
    };

    return (
        <Dialog
            actionsProps={{ sx: modalFooterStyle }}
            dialogActions={<ProjectCloseOutModalFooter />}
            dialogTitle="Close Project"
            fullWidth
            maxWidth="sm"
            onClose={hideDialog}
            open={open}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={modalContentStyle}>
                    <Box sx={alertStyle}>
                        <Alert
                            severity="info"
                            sx={{ borderRadius: 1, boxShadow: 'none' }}
                            variant="condensed"
                        >
                            <AlertTitle>Important:</AlertTitle>
                            <Typography variant="bodySmall">
                                Closing the project will remove it from the list of active projects
                            </Typography>
                        </Alert>
                    </Box>
                    <Box sx={formSectionStyle}>
                        <Field
                            aria-label="Reason for Closing"
                            component={ReduxFormSelect}
                            fullWidth
                            label="Why are you closing this project?"
                            name="closedSubstatus"
                            options={project.isIntake ? closeIntakeOptions : closeProjectOptions}
                            placeholder="Select a reason"
                            showValidation={submitFailed}
                        />
                        <Field
                            aria-label="Closure Comment"
                            characterLimit={100}
                            component={ReduxFormTextField}
                            fullWidth
                            label="Closure Comment"
                            minRows={3}
                            multiline
                            name="closeOutReason"
                            placeholder="Add project closure details"
                            rows={null}
                            showValidation={submitFailed}
                        />
                    </Box>
                    <Box sx={buttonsStyle}>
                        <Button color="secondary" onClick={hideDialog}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" variant="contained">
                            {isUpdate ? 'Update' : 'Close Project'}
                        </Button>
                    </Box>
                </Box>
            </form>
        </Dialog>
    );
};

ConnectedProjectCloseOutModal.propTypes = {
    handleSubmit: PropTypes.func,
    hideDialog: PropTypes.func,
    isUpdate: PropTypes.bool,
    open: PropTypes.bool,
    project: PropTypes.object,
    submitFailed: PropTypes.bool,
};

const ProjectCloseOutModal = reduxForm(formConfig)(ConnectedProjectCloseOutModal);

export default ProjectCloseOutModal;
