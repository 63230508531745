import PropTypes from 'prop-types';
import React from 'react';

import { getPrimaryAndAlternateApprovers } from './helpers';
import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const SinglePositionApproval = ({ stepPositionApproval }) => {
    const { position, stepPositionApprovers } = stepPositionApproval;

    const { primaryApprovers, alternateApprovers } =
        getPrimaryAndAlternateApprovers(stepPositionApprovers);

    return (
        <>
            <StepApprovalGroup
                approvers={primaryApprovers.map(({ user }) => user)}
                name={position.name}
            />
            <StepApprovalGroup
                approvers={alternateApprovers.map(({ user }) => user)}
                name="Alternate Approvers"
            />
        </>
    );
};

SinglePositionApproval.propTypes = {
    stepPositionApproval: PropTypes.shape({
        position: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        stepPositionApprovers: PropTypes.array.isRequired,
    }).isRequired,
};
