import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { ProposalSectionTitle } from '../ProposalSectionTitle';
import { ContactAddress } from '../../../../ContactAddress';
import { ProcuratedBadge } from '../../../../Procurated/ProcuratedBadge';
import { timezoneAbbreviations } from '../../../../../constants';
import { prependUrl } from '../../../../../utils';

const { EVALUATION } = projectStatusesDict;

class ConnectedCompanySection extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        printButton: PropTypes.node,
        project: PropTypes.shape({
            status: PropTypes.string.isRequired,
        }).isRequired,
        proposal: PropTypes.shape({
            companyName: PropTypes.string,
            contactAddress1: PropTypes.string,
            contactAddress2: PropTypes.string,
            contactCity: PropTypes.string,
            contactCountry: PropTypes.string,
            contactCountryCode: PropTypes.string,
            contactEmail: PropTypes.string,
            contactFullName: PropTypes.string,
            contactPhoneComplete: PropTypes.string,
            contactState: PropTypes.string,
            contactWebsite: PropTypes.string,
            contactZipCode: PropTypes.string,
            isGovernmentSubmitted: PropTypes.bool,
            submittedAt: PropTypes.instanceOf(Date),
            vendor_id: PropTypes.number,
        }).isRequired,
        router: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        vendorUrl: PropTypes.string.isRequired,
    };

    static defaultProps = {
        printButton: undefined,
    };

    get styles() {
        if (this.props.isDocx) {
            return {};
        }
        return require('../../index.scss');
    }

    handleCompanyNameClick = () => {
        const { vendorUrl, router } = this.props;

        router.push(vendorUrl);
    };

    renderCompany() {
        const {
            isDocx,
            project: { status },
            proposal: { companyName, isGovernmentSubmitted, vendor_id: vendorId },
        } = this.props;

        const name = companyName || 'Unnamed Company';

        if (isDocx) {
            return name;
        }

        const isRatingEnabled = status !== EVALUATION;
        if (isGovernmentSubmitted) {
            return (
                <div className={this.styles.companyNameCell}>
                    <span>{name}</span>
                    {isRatingEnabled && <ProcuratedBadge supplierId={vendorId} />}
                </div>
            );
        }

        return (
            <>
                <span className="pseudoLink" onClick={this.handleCompanyNameClick}>
                    {name}
                </span>
                {isRatingEnabled && <ProcuratedBadge supplierId={vendorId} />}
            </>
        );
    }

    render() {
        const {
            isDocx,
            printButton,
            proposal: {
                contactAddress1,
                contactAddress2,
                contactCity,
                contactCountry,
                contactCountryCode,
                contactEmail,
                contactFullName,
                contactPhoneComplete,
                contactState,
                contactWebsite,
                contactZipCode,
                submittedAt,
            },
            timezone,
        } = this.props;

        const contactInfo = [
            {
                label: 'Company',
                value: this.renderCompany(),
            },
            {
                label: 'Email',
                value: contactEmail || 'N/A',
            },
            {
                label: 'Contact',
                value: contactFullName || 'N/A',
            },
            {
                label: 'Address',
                value:
                    contactAddress1 || contactCity || contactState || contactZipCode ? (
                        <ContactAddress
                            address1={contactAddress1}
                            address2={contactAddress2}
                            city={contactCity}
                            country={contactCountry}
                            countryCode={contactCountryCode}
                            excludeTrailingLineBreak
                            state={contactState}
                            zipCode={contactZipCode}
                        />
                    ) : (
                        'N/A'
                    ),
            },
            {
                label: 'Phone',
                value: contactPhoneComplete || 'N/A',
            },
            {
                label: 'Website',
                value: contactWebsite ? (
                    <a href={prependUrl(contactWebsite)} rel="noreferrer noopener" target="_blank">
                        {contactWebsite}
                    </a>
                ) : (
                    'N/A'
                ),
            },
            {
                label: 'Submission Date',
                value: submittedAt
                    ? `${moment.tz(submittedAt, timezone).format('lll')} (${timezoneAbbreviations[timezone]})`
                    : 'N/A',
            },
        ].map(({ label, value }, index) => {
            if (isDocx) {
                return (
                    <div key={index} style={{ marginBottom: 8 }}>
                        <strong>{label}:</strong>
                        <br />
                        {value}
                    </div>
                );
            }
            return (
                <div className={this.styles.sectionItem} key={index}>
                    <div className="row">
                        <div className={`col-md-5 ${this.styles.label}`}>{label}</div>
                        <div className="col-md-7">{value}</div>
                    </div>
                </div>
            );
        });

        return (
            <div className={this.styles.section} ref={this.setCompanySectionRef}>
                <ProposalSectionTitle
                    isDocx={isDocx}
                    printButton={printButton}
                    title="CONTACT INFORMATION"
                />
                {contactInfo}
            </div>
        );
    }
}

export const CompanySection = withRouter(ConnectedCompanySection);
