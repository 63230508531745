import { projectTypesDict } from '@og-pro/shared-config/projects';

import { REQUIRED_TEXT } from './constants';

export function tag(values, props) {
    const errors = {};
    if (values.type === projectTypesDict.CONTRACT && props.isDocumentsTabEnabled && !values.tagId) {
        errors.tagId = REQUIRED_TEXT;
    }

    return errors;
}
