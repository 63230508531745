import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isInvalid } from 'redux-form';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';
import ErrorIcon from '@mui/icons-material/Error';

import { Button, Dialog, Typography, Box } from '@og-pro/ui';

import { fieldNames, form, qaTagPageName } from './constants';
import { OverBudgetNoteForm } from './OverBudgetNoteForm';
import { getRequisitionBudgetOverrideData } from './selectors';
import { hideRequisitionApprovalModal } from '../../../actions/requisitions';
import { actionLabelNames } from '../../../lib/ogFinancials/constants';

import {
    getRequisitionApprovalModalData,
    getRequisitionCurrentStep,
    getRequisitionJS,
} from '../../../selectors/govApp';

const { OVER_BUDGET_NOTE } = fieldNames;
const { SAVE, SUBMIT, APPROVE } = actionLabelNames;

const pastParticiple = {
    [SAVE]: 'saved',
    [SUBMIT]: 'submitted',
    [APPROVE]: 'approved',
};

export const RequisitionApprovalModal = ({ open }) => {
    const dispatch = useDispatch();

    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [overBudgetNote, setOverBudgetNote] = useState(undefined);

    const { isOverBudget } = useSelector(getRequisitionJS);
    const currentStep = useSelector(getRequisitionCurrentStep);
    const { allowOverBudget, requireOverBudgetNote } = useSelector(
        getRequisitionBudgetOverrideData
    );
    const { onConfirm, data } = useSelector(getRequisitionApprovalModalData);
    const isOverBudgetNoteFormInvalid = useSelector(isInvalid(form));

    const { actionLabel = APPROVE, btnText = 'Confirm', text = 'Are you sure?' } = data;

    const cancelHandler = () => dispatch(hideRequisitionApprovalModal());
    const submitHandler = () => {
        // Prevent submission if there is a form error (will be no errors when form is not present)
        if (isOverBudgetNoteFormInvalid) {
            setUpdateError('Please fix form errors before submitting');
            return;
        }

        const additionalData = {};
        if (isOverBudget) {
            additionalData.allowOverBudget = true;
        }
        if (overBudgetNote && overBudgetNote.trim()) {
            additionalData.overBudgetNote = overBudgetNote.trim();
        }

        setIsUpdating(true);
        setUpdateError(null);
        return onConfirm(additionalData)
            .then((resultOrError) => {
                if (resultOrError instanceof Error) {
                    throw resultOrError;
                }
                dispatch(hideRequisitionApprovalModal());
            })
            .catch((e) => {
                setIsUpdating(false);
                setUpdateError(e?.message);
            });
    };

    // Close modal on unmount
    useEffect(() => cancelHandler, []);

    let modalTitle;
    let modalBody;
    let cancelButtonText = 'Cancel';
    let showApproveButton = true;
    let cancelButtonProps = {};
    let dialogContentTextProps = {};
    const skipOverBudgetNotes = currentStep?.reviewType === reviewTypes.CONFIRM;

    if (isOverBudget && !allowOverBudget) {
        modalTitle = (
            <Typography color={capitalDesignTokens.foundations.colors.ruby500} variant="h3">
                <ErrorIcon color={capitalDesignTokens.foundations.colors.ruby500} />
                Cannot {capitalize(actionLabel)} an Over Budget Request
            </Typography>
        );
        modalBody = (
            <span>
                Requests that are over budget are not allowed to be {pastParticiple[actionLabel]}.
                Please update the request{' '}
                {actionLabel === APPROVE ? 'or notify someone who can ' : ''}
                before moving forward.
            </span>
        );
        dialogContentTextProps = { pl: 5 };
        cancelButtonProps = { color: 'secondary', variant: 'outlined' };
        cancelButtonText = 'Return to Request';
        showApproveButton = false;
    } else if (isOverBudget && !skipOverBudgetNotes) {
        modalTitle = (
            <Typography color={capitalDesignTokens.foundations.colors.ruby500} variant="h3">
                <ErrorIcon color={capitalDesignTokens.foundations.colors.ruby500} />
                This Request is Over Budget
            </Typography>
        );
        modalBody = (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography>{text}</Typography>
                <OverBudgetNoteForm
                    onChange={(values) => setOverBudgetNote(values[OVER_BUDGET_NOTE])}
                    requireOverBudgetNote={requireOverBudgetNote}
                />
            </Box>
        );
        dialogContentTextProps = { pl: 5 };
    } else {
        modalTitle = `${capitalize(actionLabel)} Request`;
        modalBody = <p>{text}</p>;
    }

    const dialogActions = (
        <>
            <Button
                color="secondary"
                disabled={isUpdating}
                onClick={cancelHandler}
                qaTag={`${qaTagPageName}-cancel`}
                variant="text"
                {...cancelButtonProps}
            >
                {cancelButtonText}
            </Button>
            {showApproveButton && (
                <Button
                    disabled={isUpdating}
                    loading={isUpdating}
                    onClick={submitHandler}
                    qaTag={`${qaTagPageName}-submit`}
                    variant="contained"
                >
                    {btnText}
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            actionsProps={{ sx: { p: 3 } }}
            closeButton
            contentProps={{
                sx: {
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: '8px !important',
                    paddingBottom: 1.5,
                },
            }}
            dialogActions={dialogActions}
            dialogTitle={modalTitle}
            fullWidth
            onClose={cancelHandler}
            open={open}
        >
            <Typography
                sx={{ p: 0, lineHeight: '20px', whiteSpace: 'pre-wrap', ...dialogContentTextProps }}
            >
                {modalBody}
            </Typography>
            {updateError && <div className="error-block">{updateError}</div>}
        </Dialog>
    );
};

RequisitionApprovalModal.propTypes = {
    open: PropTypes.bool,
};
