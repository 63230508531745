import React from 'react';

import PropTypes from 'prop-types';

import { StyledDropzone } from '../../../../../../components';

export const MAX_S3_FILE_SIZE_IN_MB = 5000; // max size of a single PUT operation in S3 https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
export const MAX_FIN_FILE_SIZE_IN_MB = 50; // max size of a single file in the financial system

export const UploadDropzone = ({ children, dropHandler, labelId, isUpdating, maxFileSize }) => {
    const styles = require('./index.scss');
    const BYTES_PER_MEGABYTE = 1048576;
    const MAX_FILE_SIZE = maxFileSize * BYTES_PER_MEGABYTE;
    const accept = {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/bmp': ['.bmp'],
        'text/plain': ['.txt'],
        'text/html': ['.html', '.htm'],
        'video/x-msvideo': ['.avi'],
        'image/tiff': ['.tif', '.tiff'],
        'application/vnd.accpac.simply.imp': ['.imp'],
        'application/vnd.accpac.simply.exp': ['.exp'],
    };

    return (
        <StyledDropzone
            accept={accept}
            defaultRejectMessage={`File could not be uploaded. Please ensure the file is less than ${maxFileSize} MBs.`}
            disabled={isUpdating}
            dropzoneClassName={styles.dropzone}
            dropzoneContainerClassName={styles.listFormDropzoneContainer}
            icons={['file-word-o']}
            isDragActiveClassName={styles.dragActive}
            label=""
            labelId={labelId}
            maxSize={MAX_FILE_SIZE}
            multiple={false}
            onDropAccepted={dropHandler}
            text={`Drop a document here or click to select file (max file size: ${maxFileSize} MBs)`}
        >
            {children}
        </StyledDropzone>
    );
};

UploadDropzone.propTypes = {
    children: PropTypes.node.isRequired,
    dropHandler: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool,
    labelId: PropTypes.string,
    maxFileSize: PropTypes.number,
};

UploadDropzone.defaultProps = {
    maxFileSize: MAX_FIN_FILE_SIZE_IN_MB,
};
