import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { stepStatusHelper } from './helpers';
import { getStepRequisitionLogsDisplay } from './selectors';
import { StepApproval } from './StepApproval';
import { SequenceStep } from '../SequenceStep';

export const ReviewSequenceStep = ({ isCurrent, reviewSequenceStep, isEditing }) => {
    const { minPositionReviews, name, reviewType, stepApproval, stepNumber, id, adHocStepCreator } =
        reviewSequenceStep;

    const stepStatus = reviewSequenceStep.stepApproval.status;

    const styles = require('./index.scss');
    const { className, icon, statusText } = stepStatusHelper(reviewSequenceStep, isCurrent);
    const requisitionLogs = useSelector((state) =>
        getStepRequisitionLogsDisplay(state, reviewSequenceStep)
    );

    const isAdHocStep = reviewSequenceStep.isAdHocStep;

    return (
        <SequenceStep
            adHocStepCreator={adHocStepCreator}
            isAdHocStep={isAdHocStep}
            isEditing={isEditing}
            requisitionLogs={requisitionLogs}
            stepId={id}
            stepName={name}
            stepStatus={stepStatus}
            stepStatusIcon={icon}
            stepStatusIconClassName={styles[className]}
            stepStatusIconText={`${stepNumber}`}
            stepStatusText={statusText}
        >
            <StepApproval
                minPositionReviews={minPositionReviews}
                reviewType={reviewType}
                stepApproval={stepApproval}
            />
        </SequenceStep>
    );
};

ReviewSequenceStep.propTypes = {
    isCurrent: PropTypes.bool.isRequired,
    reviewSequenceStep: PropTypes.shape({
        id: PropTypes.number.isRequired,
        minPositionReviews: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        reviewType: PropTypes.number.isRequired,
        stepApproval: PropTypes.object.isRequired,
        stepNumber: PropTypes.number.isRequired,
        isAdHocStep: PropTypes.bool.isRequired,
        adHocStepCreator: PropTypes.number,
    }).isRequired,
    isEditing: PropTypes.bool.isRequired,
};
