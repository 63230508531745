import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import { Account } from '../../containers';
import * as Gov from '../../containers/GovApp';
import VendorsRoutes from './Vendors';
import AdminRoutes from './Admin';
import TemplateAdminRoutes from './TemplateAdmin';
import RetentionRoutes from './Retention';
import ChecklistsRoutes from './Checklists';
import ContractRoutes from './Contract';
import DashboardRoutes from './Dashboard';
import EmergencyPortalRoutes from './EmergencyPortal';
import ProjectRoutes from './Projects';
import RequisitionsRoutes from './Requisitions';
import RequisitionAdminRoutes from './Requisitions/Admin';
import { legacyFetchData, requireGovLogin, requireAnalyticsPermissions } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Gov.App />,
            loader: async (props) => {
                const permissionRedirect = await requireGovLogin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return legacyFetchData(getState, dispatch, Gov.App)(props);
            },
            path: '/governments/:governmentId',
            children: [
                {
                    element: <Outlet />,
                    loader: () => {
                        return redirect('projects');
                    },
                    index: true,
                },
                {
                    element: <Gov.AggregateAnalytics />,
                    loader: requireAnalyticsPermissions(getState),
                    path: 'analytics/:projectTypePath',
                },
                { element: <Account />, path: 'account' },
                // /governments/:governmentId/admin/*
                ...AdminRoutes(getState, dispatch),
                // /governments/:governmentId/templates-admin/*
                ...TemplateAdminRoutes(getState, dispatch),
                // /governments/:governmentId/retention-admin/*
                ...RetentionRoutes(getState, dispatch),
                // /governments/:governmentId/checklists-admin/*
                ...ChecklistsRoutes(getState, dispatch),
                {
                    element: <Gov.NotificationsInbox />,
                    path: 'notifications-inbox',
                },
                {
                    element: <Gov.CalendarNav />,
                    loader: legacyFetchData(getState, dispatch, Gov.CalendarNav),
                    path: 'calendar',
                },
                // /governments/:governmentId/contracts/*
                ...ContractRoutes(getState, dispatch),
                // /governments/:governmentId/dashboard/*
                ...DashboardRoutes(getState, dispatch),
                {
                    element: <Gov.ProjectsList.Purchases />,
                    loader: legacyFetchData(getState, dispatch, Gov.ProjectsList.Purchases),
                    path: 'projects',
                },
                {
                    element: <Gov.ProjectLibrary />,
                    path: 'project-library',
                    children: [
                        {
                            element: <Gov.LibrarySearch />,
                            index: true,
                        },
                        {
                            element: <Gov.ScopeSelect />,
                            loader: legacyFetchData(getState, dispatch, Gov.ScopeSelect),
                            path: ':projectId',
                        },
                    ],
                },
                // /governments/:governmentId/emergency-portal/*
                ...EmergencyPortalRoutes(getState, dispatch),
                // /governments/:governmentId/projects/:projectId/*
                ...ProjectRoutes(getState, dispatch),
                {
                    element: <Gov.ProjectsList.Documents />,
                    loader: legacyFetchData(getState, dispatch, Gov.ProjectsList.Documents),
                    path: 'documents',
                },
                {
                    element: <Gov.ProjectsList.Intakes />,
                    loader: legacyFetchData(getState, dispatch, Gov.ProjectsList.Intakes),
                    path: 'intakes',
                },
                // /governments/:governmentId/vendors/*
                ...VendorsRoutes(getState, dispatch),
                // /governments/:governmentId/requisitions/*
                ...RequisitionsRoutes(getState, dispatch),
                // /governments/:governmentId/requisitions-admin/*
                ...RequisitionAdminRoutes(getState, dispatch),
            ],
        },
    ];
};
