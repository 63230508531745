import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { LoadingError, LoadingSpinner } from '../../../../../components';
import { VendorClassificationTable } from '../../../../../components/vendors';

export class ClassificationReport extends PureComponent {
    static propTypes = {
        govliaClassifications: PropTypes.shape({
            classifications: PropTypes.array.isRequired,
            total: PropTypes.number.isRequired,
        }),
        loadClassificationReport: PropTypes.func.isRequired,
        loaded: PropTypes.bool.isRequired,
        users: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingList: false,
            loadListError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        const { loaded, loadClassificationReport } = this.props;

        if (!loaded) {
            this.setState({ loadingList: true, loadListError: null });
            return loadClassificationReport()
                .then(() => {
                    this.setState({ loadingList: false });
                })
                .catch((error) => {
                    this.setState({ loadingList: false, loadListError: error.message });
                });
        }
    }

    render() {
        const { govliaClassifications, users } = this.props;

        const { loadingList, loadListError } = this.state;

        if (loadingList) {
            return <LoadingSpinner noPadding />;
        }

        if (loadListError) {
            return <LoadingError error={loadListError} />;
        }

        return (
            <>
                <VendorClassificationTable
                    isCentered
                    title={`Vendor Classifications (${users.length} vendors)`}
                    vendors={users}
                />
                {govliaClassifications && govliaClassifications.total > 0 && (
                    <div className={this.styles.govliaTable}>
                        <VendorClassificationTable
                            isCentered
                            title={
                                <>
                                    <img
                                        alt="Govlia text logo"
                                        className={`img-rounded ${this.styles.govliaTextLogo}`}
                                        src="https://assets.procurement.opengov.com/assets/govlia-text-logo.png"
                                    />
                                    Partner Notifications ({govliaClassifications.total})
                                </>
                            }
                            vendorClassificationTotals={govliaClassifications.classifications}
                        />
                        <div className={this.styles.govliaInfo}>
                            <em>
                                Vendor outreach boosted via&nbsp;
                                <a href="" rel="nofollow" target="_blank">
                                    partnership with Govlia
                                </a>
                                <img
                                    alt="Govlia logo"
                                    className={`img-rounded ${this.styles.govliaLogo}`}
                                    src="https://assets.procurement.opengov.com/assets/govlia-logo.png"
                                />
                            </em>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
