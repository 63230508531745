import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { projectTypesDict } from '@og-pro/shared-config/projects';

const { INTERNAL, OTHER, PROJECT_DOCUMENT } = attachmentTypesDict;

const types = [
    { label: 'Main Documents', value: PROJECT_DOCUMENT },
    { label: `Supporting Attachments`, value: OTHER },
    { label: 'Internal Documents', value: INTERNAL },
];

export const useReassignment = ({ allowedReassignmentTypes = [], project }) => {
    let filtered = types
        .filter((t) => allowedReassignmentTypes.includes(t.value))
        .map((t) => {
            if (project.type === projectTypesDict.CONTRACT && t.value === OTHER) {
                return {
                    ...t,
                    label: 'Exhibits',
                };
            }

            return t;
        });

    if (!project.template.omitDocx) {
        filtered = filtered.filter((t) => t.value !== PROJECT_DOCUMENT);
    }

    if (filtered.length === 0) {
        return null;
    }

    return filtered;
};
