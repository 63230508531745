import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';
import Media from 'react-media';
import { v4 as UUIDv4 } from 'uuid';
import classnames from 'classnames';

import {
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
    percentageAdjustmentTypesDict,
} from '@og-pro/shared-config/priceTables';
import { calculatePriceItemTotalCost } from '@og-pro/shared-config/priceTables/utils';

import { SELECTION, VENDOR_NAME } from './constants';
import { currencyFormatter, percentFormatter } from '../../helpers';
import { formatPercent, cleanAgGridUniqueColName } from '../helpers/utils';
import { SCREEN_SM_MAX } from '../../constants/mediaQuery';
import { conditionalPropCheck } from '../../utils';
import {
    dataTypesDict,
    DOCX_TABLE_LANDSCAPE_WIDTH,
    DOCX_TABLE_PORTRAIT_WIDTH,
} from '../../constants';
import { AgGridReactPanelHeading } from '../AgGridReactPanelHeading';
import { AgGridReactLegacy } from '../AgGridReactLegacy';
import { Button } from '../Button';

import { WithStickyHeader } from '../../hocs';

const { NUMBER, STRING } = dataTypesDict;

const { NONE } = percentageAdjustmentTypesDict;

const PANEL_BODY_STYLES = {
    padding: 0,
};

export class BidTabulationTable extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        bidTabulation: PropTypes.shape({
            hasQuantity: PropTypes.bool,
            priceTable: PropTypes.object.isRequired,
            rows: PropTypes.arrayOf(
                PropTypes.shape({
                    description: PropTypes.string.isRequired,
                    isHeaderRow: PropTypes.bool,
                    lineItem: PropTypes.string,
                    percentageAdjustmentType: PropTypes.string,
                    priceItemId: PropTypes.number.isRequired,
                    quantity: PropTypes.number,
                    taxable: PropTypes.bool,
                    unitToMeasure: conditionalPropCheck(STRING, ({ props }) => !props.isHeaderRow),
                    vendorResponses: PropTypes.arrayOf(
                        PropTypes.shape({
                            comment: PropTypes.string,
                            discount: PropTypes.number,
                            discountOnly: PropTypes.bool,
                            isHeaderRow: PropTypes.bool,
                            noBid: PropTypes.bool,
                            quantity: PropTypes.number,
                            unitPrice: conditionalPropCheck(
                                NUMBER,
                                ({ props }) => !props.isHeaderRow && !props.discountOnly
                            ),
                        })
                    ).isRequired,
                })
            ).isRequired,
            title: PropTypes.string,
        }).isRequired,
        defaultTitle: PropTypes.string,
        docxSize: PropTypes.oneOf(['summary', 'landscape', 'portrait']),
        includeSelectedColumn: PropTypes.bool,
        initiallySelectedPriceItemIds: PropTypes.arrayOf(PropTypes.number),
        isDocx: PropTypes.bool,
        isPublicView: PropTypes.bool,
        isViewOnly: PropTypes.bool,
        onSelectionChange: PropTypes.func,
        proposalsData: PropTypes.arrayOf(
            PropTypes.shape({
                proposalId: PropTypes.number.isRequired,
                vendorCity: PropTypes.string,
                vendorName: PropTypes.string.isRequired,
                vendorState: PropTypes.string,
            })
        ),
        salesTax: PropTypes.number,
        showCustomColumns: PropTypes.bool,
        showTotalOnly: PropTypes.bool,
        sortAscending: PropTypes.bool.isRequired,
        totalAll: PropTypes.bool,
        useLandscape: PropTypes.bool,
        stickyHeaderOffset: PropTypes.number,
    };

    static defaultProps = {
        defaultTitle: 'Table',
        includeSelectedColumn: true,
        initiallySelectedPriceItemIds: [],
        isViewOnly: false,
        onSelectionChange: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            hideAllColumns: !this.props.isDocx,
            numInitiallySelected: 0,
            numSelectionHandlersSkipped: 0,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get docxTableWidth() {
        const { docxSize } = this.props;

        if (docxSize === 'summary') {
            return 300;
        }

        if (docxSize === 'landscape') {
            return DOCX_TABLE_LANDSCAPE_WIDTH;
        }

        return DOCX_TABLE_PORTRAIT_WIDTH;
    }

    get currencyFormatterOpts() {
        const { auctionMaxFractionDigits } = this.props;
        if (auctionMaxFractionDigits) {
            return { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true };
        }
        return { use4FractionDigits: true };
    }

    generateColDefs(isMobile) {
        const {
            bidTabulation: { hasQuantity, priceTable, title },
            includeSelectedColumn,
            isDocx,
            isViewOnly,
            proposalsData,
            showCustomColumns,
            showTotalOnly,
        } = this.props;
        const { hideAllColumns } = this.state;

        const baseColumnChildren = [];
        const quantityColumn = {
            headerName: priceTable.headerQuantity,
            cellStyle: this.getCellStyle,
            cellClassRules: {
                // Used exclusively for Excel export styles
                headerRow: (params) => params.data.isHeaderRow,
                summaryRow: this.isTotalsRowCell,
            },
            width: 105,
            hide: !hasQuantity,
            suppressMenu: true,
        };

        if (!isViewOnly && !priceTable.hasPercentage) {
            if (includeSelectedColumn) {
                baseColumnChildren.push({
                    headerName: isDocx ? 'Selected' : undefined, // This is intentional
                    colId: SELECTION,
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                        summaryRow: this.isTotalsRowCell,
                    },
                    cellStyle: this.getCellStyle,
                    checkboxSelection: (params) =>
                        !params.data.isHeaderRow && !params.data.discountOnly,
                    headerCheckboxSelection: true,
                    width: 55,
                    pinned: 'left',
                    suppressMenu: true,
                });
            }
        }

        if (!priceTable.omitLineItem) {
            baseColumnChildren.push({
                headerName: priceTable.headerLineItem,
                field: LINE_ITEM,
                cellStyle: this.getCellStyle,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                    summaryRow: this.isTotalsRowCell,
                },
                width: 100,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
                colSpan: (params) => {
                    if (params.data.isHeaderRow) {
                        return Infinity;
                    }
                    return 1;
                },
            });
        }

        baseColumnChildren.push(
            {
                headerName: priceTable.headerDescription,
                field: DESCRIPTION,
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    bold: this.isTotalsRowCell,
                    headerRow: (params) => params.data.isHeaderRow,
                    summaryRow: this.isTotalsRowCell,
                },
                autoHeight: true,
                cellStyle: this.getCellStyle,
                width: isMobile ? 150 : 300,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            },
            {
                headerName: priceTable.headerUnitToMeasure,
                field: UNIT_TO_MEASURE,
                cellStyle: this.getCellStyle,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                    summaryRow: this.isTotalsRowCell,
                },
                width: 150,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            }
        );

        // Quantity appears on left side of the line when gov defined, and right side when vendor defined
        if (priceTable.specifyQuantity) {
            baseColumnChildren.splice(baseColumnChildren.length - 1, 0, {
                // Insert before UNIT_TO_MEASURE
                ...quantityColumn,
                field: QUANTITY,
                pinned: isMobile ? undefined : 'left',
            });
        }

        const baseColumns = [
            {
                headerName: title || '', // to see the title when column headers are sticky
                marryChildren: true,
                suppressColumnsToolPanel: true,
                suppressMovable: true,
                children: baseColumnChildren,
            },
        ];

        const vendorColumns = proposalsData.map((proposalData) => {
            const { vendorCity, vendorName, vendorState } = proposalData;
            const headerTooltip =
                vendorCity && vendorState
                    ? `${vendorCity}, ${vendorState}`
                    : 'No city or state provided';
            const customColumns = [];
            const children = [];

            if (showCustomColumns) {
                [1, 2, 3, 4, 5].forEach((customColNum) => {
                    if (priceTable[`hasCustom${customColNum}`]) {
                        customColumns.push({
                            headerName: priceTable[`headerCustom${customColNum}`],
                            field: `${proposalData.proposalId}:custom${customColNum}`,
                            cellStyle: this.getCellStyle,
                            cellClassRules: {
                                // Used exclusively for Excel export styles
                                headerRow: (params) => params.data.isHeaderRow,
                                summaryRow: this.isTotalsRowCell,
                            },
                            width: 120,
                            hide: hideAllColumns,
                            suppressMenu: true,
                        });
                    }
                });
            }

            // Order is important for all of the children array pushes below

            if (!priceTable.specifyQuantity) {
                children.push({
                    ...quantityColumn,
                    field: `${proposalData.proposalId}:${QUANTITY}`,
                    hide: !hasQuantity || hideAllColumns,
                });
            }

            children.push({
                headerName: priceTable.headerUnitPrice,
                colId: `${proposalData.proposalId}:${UNIT_PRICE}`,
                field: `${proposalData.proposalId}:${UNIT_PRICE}`,
                noBidField: `${proposalData.proposalId}:${NO_BID}`,
                cellClass: [priceTable.hasPercentage ? 'percent' : '4FractionCurrency'], // Used exclusively for Excel export styles
                valueFormatter: priceTable.hasPercentage
                    ? this.unitPricePercentageFormatter(proposalData.proposalId)
                    : this.unitPriceCurrencyFormatter(proposalData.proposalId),
                cellStyle: this.getCellStyle,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                    summaryRow: this.isTotalsRowCell,
                },
                width: 160,
                hide: !hasQuantity && !priceTable.hasPercentage,
                suppressMenu: true,
                valueGetter: (params) => {
                    if (params.data.discountOnly) {
                        return 'N/A';
                    }
                    return params.data[`${proposalData.proposalId}:${UNIT_PRICE}`];
                },
            });

            if (!priceTable.hasPercentage) {
                if (priceTable.percentageAdjustmentType !== NONE) {
                    children.push({
                        headerName: priceTable.headerDiscount,
                        colId: `${proposalData.proposalId}:${DISCOUNT}`,
                        field: `${proposalData.proposalId}:${DISCOUNT}`,
                        noBidField: `${proposalData.proposalId}:${NO_BID}`,
                        cellClass: ['percent'], // Used exclusively for Excel export styles
                        valueFormatter: this.unitPricePercentageFormatter(proposalData.proposalId),
                        cellStyle: this.getCellStyle,
                        cellClassRules: {
                            // Used exclusively for Excel export styles
                            headerRow: (params) => params.data.isHeaderRow,
                            summaryRow: this.isTotalsRowCell,
                        },
                        width: 110,
                        hide:
                            priceTable.hasPercentage ||
                            priceTable.percentageAdjustmentType === NONE,
                        suppressMenu: true,
                    });
                }
                children.push({
                    headerName: hasQuantity ? priceTable.headerTotal : priceTable.headerUnitPrice,
                    field: `${proposalData.proposalId}:${TOTAL_COST}`,
                    noBidField: `${proposalData.proposalId}:${NO_BID}`,
                    cellClass: ['4FractionCurrency'], // Used exclusively for Excel export styles
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        bold: this.isTotalsRowCell,
                        headerRow: (params) => params.data.isHeaderRow,
                        summaryRow: this.isTotalsRowCell,
                    },
                    valueFormatter: this.totalCostFormatter(proposalData.proposalId),
                    cellStyle: this.getCellStyle,
                    width: 160,
                    suppressMenu: true,
                });
            }

            if (priceTable.hasComment) {
                children.push({
                    headerName: priceTable.headerComment,
                    field: `${proposalData.proposalId}:${COMMENT}`,
                    cellStyle: this.getCellStyle,
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                        summaryRow: this.isTotalsRowCell,
                    },
                    width: 120,
                    hide: true,
                    suppressMenu: true,
                });
            }

            children.push(...customColumns);

            return {
                headerName: vendorName,
                marryChildren: true,
                children,
                headerTooltip,
            };
        });

        if (showTotalOnly) {
            return [
                {
                    headerName: 'Vendor',
                    field: VENDOR_NAME,
                    cellStyle: this.getCellStyle,
                    suppressMenu: true,
                    flex: 1,
                },
                {
                    headerName: 'Total',
                    field: TOTAL_COST,
                    cellClass: ['4FractionCurrency'], // Used exclusively for Excel export styles
                    cellStyle: this.getCellStyle,
                    valueFormatter: (params) =>
                        currencyFormatter(params, this.currencyFormatterOpts),
                    suppressMenu: true,
                    flex: 1,
                },
            ];
        }

        return baseColumns.concat(vendorColumns);
    }

    getCellStyle(params) {
        const { node } = params;

        // Base styles for all cells
        const styles = {
            lineHeight: '18px',
            paddingBottom: '4px',
            paddingTop: '4px',
            whiteSpace: 'normal',
        };

        if (params.colDef.field === DESCRIPTION) {
            styles.whiteSpace = 'pre-wrap';
        }

        if (node.data.isHeaderRow) {
            return {
                ...styles,
                fontWeight: 'bold',
                backgroundColor: '#eee',
            };
        }

        if (node.rowPinned && node.data.description === 'Total') {
            styles.fontWeight = 'bold';
        }

        return styles;
    }

    getRowNodeId(data) {
        if (data.isSummaryRow) {
            return 'summaryRow';
        }

        if (data.showTotalOnly) {
            return data.vendorName;
        }

        return data.priceItemId;
    }

    generateRows() {
        const {
            bidTabulation: { priceTable, rows },
            proposalsData,
            showTotalOnly,
            sortAscending,
        } = this.props;

        if (showTotalOnly) {
            return this.generateSummaryRow(this.getSelectedRows()).sort((a, b) => {
                if (sortAscending ? a.totalCost > b.totalCost : a.totalCost < b.totalCost) {
                    return 1;
                }

                if (sortAscending ? a.totalCost < b.totalCost : a.totalCost > b.totalCost) {
                    return -1;
                }

                return 0;
            });
        }

        return rows.map((row) => {
            if (row.isHeaderRow) {
                return {
                    isHeaderRow: row.isHeaderRow,
                    lineItem: row.description,
                    priceItemId: row.priceItemId,
                };
            }

            const bidTabulationRow = {
                description: row.taxable ? `${row.description} [*]` : row.description,
                discountOnly: row.discountOnly,
                lineItem: row.lineItem,
                priceItemId: row.priceItemId,
                quantity: row.quantity,
                unitToMeasure: row.unitToMeasure,
            };

            row.vendorResponses.forEach((vendorResponse, i) => {
                const proposalId = proposalsData[i].proposalId;
                const totalCost = row.discountOnly
                    ? null
                    : calculatePriceItemTotalCost(
                          { vendorResponse },
                          priceTable.percentageAdjustmentType
                      );
                bidTabulationRow[`${proposalId}:${COMMENT}`] = vendorResponse.comment;
                bidTabulationRow[`${proposalId}:${DISCOUNT}`] = vendorResponse.discount;
                bidTabulationRow[`${proposalId}:${NO_BID}`] = vendorResponse.noBid;
                bidTabulationRow[`${proposalId}:${QUANTITY}`] = vendorResponse.quantity;
                bidTabulationRow[`${proposalId}:${TOTAL_COST}`] = totalCost;
                bidTabulationRow[`${proposalId}:${UNIT_PRICE}`] = vendorResponse.unitPrice;
                bidTabulationRow[`${proposalId}:custom1`] = vendorResponse.custom1;
                bidTabulationRow[`${proposalId}:custom2`] = vendorResponse.custom2;
                bidTabulationRow[`${proposalId}:custom3`] = vendorResponse.custom3;
                bidTabulationRow[`${proposalId}:custom4`] = vendorResponse.custom4;
                bidTabulationRow[`${proposalId}:custom5`] = vendorResponse.custom5;
            });

            return bidTabulationRow;
        });
    }

    generateSummaryRow(selectedRows) {
        const {
            bidTabulation: { priceTable, rows },
            proposalsData,
            salesTax,
            showTotalOnly,
            totalAll,
        } = this.props;

        const rowsToTotal = totalAll ? rows : selectedRows;

        const initialVendorTotals = proposalsData.reduce((totals, proposalData) => {
            totals[`${proposalData.proposalId}:${TOTAL_COST}`] = {
                value: 0,
                noBid: rowsToTotal.length > 0, // if length is 0, no rows are selected so nothing can be "No Bid"
            };
            return totals;
        }, {});

        const vendorTotals = rowsToTotal.reduce((totals, row) => {
            const { discountOnly, isHeaderRow, taxable } = row;

            if (isHeaderRow || discountOnly) {
                return totals;
            }

            row.vendorResponses.forEach((vendorResponse, i) => {
                const proposalId = proposalsData[i].proposalId;
                const colName = `${proposalId}:${TOTAL_COST}`;
                const total = calculatePriceItemTotalCost(
                    { vendorResponse },
                    priceTable.percentageAdjustmentType
                );
                const totalWithTaxes = taxable ? total * (salesTax / 100) + total : total;

                totals[colName] = {
                    value: totals[colName].value + totalWithTaxes,
                    noBid: !vendorResponse.noBid ? false : totals[colName].noBid, // only changes when response is true to preserve state if any value is false
                };
            });

            return totals;
        }, initialVendorTotals);

        // now that all rows have been combined, set vendor total to desired value
        Object.entries(vendorTotals).forEach((vendor) => {
            const colName = vendor[0];
            const total = vendor[1];
            if (total.noBid) {
                vendorTotals[colName] = 'No Bid';
                return;
            }

            vendorTotals[colName] = total.value;
        });

        const totalRow = {
            description: 'Total',
            isSummaryRow: true,
            ...vendorTotals,
        };

        if (salesTax && priceTable.hasSalesTaxRow && !showTotalOnly) {
            const initialVendorSalesTaxTotals = proposalsData.reduce((totals, proposalData) => {
                totals[`${proposalData.proposalId}:${TOTAL_COST}`] = 0;
                return totals;
            }, {});

            const salesTaxTotals = rowsToTotal.reduce((totals, row) => {
                const { taxable } = row;
                row.vendorResponses.forEach((vendorResponse, i) => {
                    const proposalId = proposalsData[i].proposalId;
                    const colName = `${proposalId}:${TOTAL_COST}`;
                    const total = calculatePriceItemTotalCost({ vendorResponse });
                    totals[colName] += taxable ? total * (salesTax / 100) : 0;
                });

                return totals;
            }, initialVendorSalesTaxTotals);

            return [
                {
                    description: `Sales Tax (@ ${salesTax}%)`,
                    isSummaryRow: true,
                    ...salesTaxTotals,
                },
                totalRow,
            ];
        }

        if (showTotalOnly) {
            return proposalsData.map((proposal) => {
                return {
                    vendorName: proposal.vendorName,
                    totalCost: totalRow[`${proposal.proposalId}:${TOTAL_COST}`],
                    showTotalOnly,
                };
            });
        }

        return [totalRow];
    }

    getSelectedRows() {
        const {
            bidTabulation: { rows },
            initiallySelectedPriceItemIds,
            isDocx,
        } = this.props;

        const { gridApi } = this.state;

        if (!gridApi && !isDocx) {
            return [];
        }

        const selectedRows = isDocx ? [] : gridApi.getSelectedRows();

        const allowedRowPriceItemIds = isDocx
            ? initiallySelectedPriceItemIds
            : selectedRows.map((row) => row.priceItemId);

        return rows.filter((row) => {
            return (
                !row.isHeaderRow &&
                !row.discountOnly &&
                allowedRowPriceItemIds.indexOf(row.priceItemId) > -1
            );
        });
    }

    unitPriceCurrencyFormatter = (proposalId) => (params) => {
        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }
        return currencyFormatter(params, this.currencyFormatterOpts);
    };

    unitPricePercentageFormatter = (proposalId) => (params) => {
        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }
        return percentFormatter(params);
    };

    totalCostFormatter = (proposalId) => (params) => {
        const noBid = params.data[`${proposalId}:${NO_BID}`];
        if (noBid) {
            return 'No Bid';
        }
        return currencyFormatter(params, this.currencyFormatterOpts);
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api }, this.selectInitiallySelectedRows);
    };

    // We use this to trigger the parent's recalculation of the VendorBidTabulationTotalsTable.
    // TODO: the parent should handle this initial calculation
    handleInitialSelectionCompleted = () => {
        const { onSelectionChange } = this.props;

        const { gridApi } = this.state;

        if (!onSelectionChange) {
            return;
        }

        const selectedRows = this.getSelectedRows();

        gridApi.setPinnedBottomRowData(this.generateSummaryRow(selectedRows));

        onSelectionChange(selectedRows, true);
    };

    /**
     * Callback for transforming cell data when it is being exported to Excel.
     *
     * @param {object} params The `processCellCallback` callback params
     * @return {any} The value of the cell, transformed if necessary
     */
    handleProcessCell = (params) => {
        // Put an X in the selection column to represent that the row is selected
        if (params.column.colId === SELECTION) {
            if (
                !params.node.data.isHeaderRow &&
                !params.node.data.discountOnly &&
                params.node.selected
            ) {
                return 'X';
            }

            return '';
        }

        // Replace no bid cell values of 0 with "No Bid" text
        const [proposalId, colId] = (params.column.colId || '').split(':');
        const cleanColId = cleanAgGridUniqueColName(colId);

        if (
            (cleanColId === TOTAL_COST || cleanColId === UNIT_PRICE) &&
            params.node.data[`${proposalId}:${NO_BID}`]
        ) {
            return 'No Bid';
        }

        return params.value;
    };

    /**
     * Callback for transforming header data when it is being exported to Excel.
     *
     * @param {object} params The `processCellCallback` callback params
     * @return {any} The value of the header, transformed if necessary
     */
    handleProcessHeader = (params) => {
        // Give the selection column a title
        if (params.column.colId === SELECTION) {
            return 'Selected';
        }

        return params.column.colDef.headerName;
    };

    handleSelectionChanged = () => {
        const { onSelectionChange } = this.props;

        const { gridApi, numInitiallySelected, numSelectionHandlersSkipped } = this.state;

        // ag-Grid has no ability to batch select nodes, and it has no concept of an initial
        // selection. That means that in the `selectInitiallySelectedRows` function, each time a
        // node is selected this callback will get called. However, we don't want to show a toast
        // for the initial selection, let alone for each item in the initial selection. Thus, this
        // check ensures that we don't show the toasts for the initial selection
        //
        // Essentially, we are skipping the first n (numInitiallySelected) executions of this
        // handler since we know that they are the callbacks for the rows that were initially
        // selected when the component first loaded.
        if (numInitiallySelected === numSelectionHandlersSkipped) {
            const selectedRows = this.getSelectedRows();

            gridApi.setPinnedBottomRowData(this.generateSummaryRow(selectedRows));

            if (onSelectionChange) {
                onSelectionChange(selectedRows);
            }
        } else {
            this.setState({ numSelectionHandlersSkipped: numSelectionHandlersSkipped + 1 });
        }
    };

    isTotalsRowCell = (params) => {
        return params.node.rowPinned;
    };

    selectInitiallySelectedRows = () => {
        const { initiallySelectedPriceItemIds } = this.props;

        const { gridApi } = this.state;

        const initiallySelectedMap = initiallySelectedPriceItemIds.reduce((map, priceItemId) => {
            map[priceItemId] = true;
            return map;
        }, {});

        // We need this because this.props.initiallySelectedPriceItemIds has the IDs for all
        // selected price items across all tables, not just this one, so we need to see which ones
        // are actually in this table
        let numInitiallySelected = 0;
        gridApi.forEachNode((node) => {
            if (initiallySelectedMap[node.data.priceItemId]) {
                node.setSelected(true);
                numInitiallySelected++;
            }
        });

        this.setState({ numInitiallySelected }, this.handleInitialSelectionCompleted);
    };

    toggleHideAllColumns = () =>
        this.setState((prevState) => ({
            hideAllColumns: !prevState.hideAllColumns,
        }));

    renderGridButtons() {
        const { hideAllColumns } = this.state;
        return [
            <Button
                id="column-toggle"
                key="column-toggle"
                onClick={this.toggleHideAllColumns}
                tooltip={
                    hideAllColumns
                        ? 'Click to show all available columns'
                        : 'Click to hide all columns except pricing'
                }
            >
                <i className={hideAllColumns ? 'fa fa-plus-square-o' : 'fa fa-minus-square-o'} />
                &nbsp;{hideAllColumns ? 'Show All Columns' : 'Hide All Columns'}
            </Button>,
        ];
    }

    getDocxCellWidths = (columnNum) => {
        const tableWidth = this.docxTableWidth;

        if (columnNum <= 5) {
            return [tableWidth - 100 * (columnNum - 1), 100];
        }
        if (columnNum <= 7) {
            return [tableWidth - 80 * (columnNum - 1), 80];
        }
        if (columnNum <= 9) {
            return [tableWidth - 60 * (columnNum - 1), 60];
        }
        return [tableWidth / columnNum, tableWidth / columnNum];
    };

    renderDocxTableHeader(columnsData, totalCols) {
        const { showTotalOnly } = this.props;
        const thStyle = { backgroundColor: '#003c81' };
        const pStyle = { textAlign: 'center', color: '#FFFFFF' };

        const [descriptionWidth, otherWidth] = this.getDocxCellWidths(columnsData.length);

        const columnHeaders = columnsData.map((columnData, i) => {
            const { field, headerName } = columnData;

            const width = field === DESCRIPTION ? descriptionWidth : otherWidth;
            const colSpan = !showTotalOnly ? columnData.children.length : 1;

            return (
                <th colSpan={colSpan} key={`${field} ${i}`} style={thStyle} width={width}>
                    <p className="no-trailing-space" style={pStyle}>
                        <strong>{headerName}</strong>
                    </p>
                </th>
            );
        });

        const generateDetailsHeader = () => {
            return columnsData.map((columnData) => {
                const [childDescriptionWidth, childOtherWidth] = this.getDocxCellWidths(totalCols);

                return columnData.children.map((child, j) => {
                    const width =
                        child.field === DESCRIPTION ? childDescriptionWidth : childOtherWidth;

                    if (child.hide) {
                        return null;
                    }

                    return (
                        <th key={`${child.field} ${j}`} style={thStyle} width={width}>
                            <p className="no-trailing-space" style={pStyle}>
                                <strong>{child.headerName}</strong>
                            </p>
                        </th>
                    );
                });
            });
        };

        return (
            <>
                <tr className="repeat-header-row" key="headerRow1">
                    {columnHeaders}
                </tr>
                {!showTotalOnly && (
                    <tr className="repeat-header-row" key="headerRow2">
                        {generateDetailsHeader()}
                    </tr>
                )}
            </>
        );
    }

    renderDocxTableRows(columnsData, totalCols) {
        const {
            bidTabulation: {
                priceTable: { hasPercentage },
            },
            initiallySelectedPriceItemIds,
            showTotalOnly,
        } = this.props;

        const tableRows = this.generateRows().map((row, i) => {
            if (showTotalOnly) {
                return (
                    <tr key={i}>
                        {columnsData.map((col) => {
                            const field = col.field;
                            let value = row[field];

                            if (field === TOTAL_COST) {
                                value = currencyFormatter({ value }, this.currencyFormatterOpts);
                            }

                            return (
                                <td key={`${field} ${i}`}>
                                    <p style={{ textAlign: 'center' }}>{value}</p>
                                </td>
                            );
                        })}
                    </tr>
                );
            }

            if (row.isHeaderRow) {
                return (
                    <tr key={row.priceItemId}>
                        <td
                            colSpan={totalCols}
                            style={{ backgroundColor: '#eee', fontWeight: 'bold' }}
                        >
                            <p>{row.lineItem}</p>
                        </td>
                    </tr>
                );
            }

            return (
                <tr key={row.priceItemId}>
                    {columnsData.map((col) => {
                        return col.children.map((child) => {
                            const field = child.field;
                            const noBid = row[child.noBidField];
                            let value = row[field];

                            if (child.hide) {
                                return null;
                            }

                            if (row.discountOnly && field && field.includes(UNIT_PRICE)) {
                                value = 'N/A';
                            }

                            if (child.cellClass) {
                                const name = child.cellClass[0];

                                if (name === '4FractionCurrency') {
                                    value = noBid
                                        ? 'No Bid'
                                        : currencyFormatter({ value }, this.currencyFormatterOpts);
                                }
                                if (name === 'currency') {
                                    value = noBid ? 'No Bid' : currencyFormatter({ value });
                                }
                                if (name === 'percent') {
                                    value = noBid ? 'No Bid' : formatPercent(value);
                                }
                            }

                            if (
                                child.checkboxSelection &&
                                initiallySelectedPriceItemIds.includes(row.priceItemId)
                            ) {
                                value = 'X';
                            }

                            return (
                                <td key={`${row.priceItemId} ${field} ${i}`}>
                                    <p style={{ textAlign: 'center' }}>{value}</p>
                                </td>
                            );
                        });
                    })}
                </tr>
            );
        });

        const renderSummaryRows = () => {
            const summaryRowsData = this.generateSummaryRow(this.getSelectedRows());

            return (
                <React.Fragment key={UUIDv4()}>
                    {summaryRowsData.map((rowData, i) => {
                        return (
                            <tr key={`summaryRow ${i}`}>
                                <td
                                    colSpan={columnsData[0].children.length}
                                    style={{ backgroundColor: '#C0C0C0' }}
                                >
                                    <p style={{ paddingLeft: 10 }}>{rowData.description}</p>
                                </td>
                                {columnsData.map((col, j) => {
                                    if (j !== 0) {
                                        return col.children.map((child) => {
                                            const value = currencyFormatter(
                                                { value: rowData[child.field] },
                                                this.currencyFormatterOpts
                                            );
                                            return (
                                                <td
                                                    key={`${j} ${child.field}`}
                                                    style={{ backgroundColor: '#C0C0C0' }}
                                                >
                                                    <p style={{ textAlign: 'center' }}>{value}</p>
                                                </td>
                                            );
                                        });
                                    }
                                    return null;
                                })}
                            </tr>
                        );
                    })}
                    {summaryRowsData.length > 1 && (
                        <tr key="taxFooter">
                            <td colSpan={totalCols} style={{ border: 'none' }}>
                                <p style={{ fontSize: 12 }}>[*] Denotes item is taxable</p>
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            );
        };

        return [tableRows, !showTotalOnly && !hasPercentage ? renderSummaryRows() : null];
    }

    renderDocxTable() {
        const {
            bidTabulation: {
                priceTable: { description, hasPercentage },
                title,
            },
            defaultTitle,
            showTotalOnly,
            useLandscape,
        } = this.props;

        const columnsData = this.generateColDefs();
        const maxColCount = useLandscape ? 10 : 5;
        let currTableIndex = 0;
        let vendorColCount = 0;

        const tableData = showTotalOnly
            ? [columnsData]
            : columnsData.reduce((tables, colData, i) => {
                  const children = colData.children.filter((child) => !child.hide);

                  if (i !== 0) {
                      if (vendorColCount >= maxColCount) {
                          currTableIndex += 1;
                          vendorColCount = 0;
                      }

                      if (!tables[currTableIndex]) {
                          tables.push([{ ...columnsData[0] }]);
                      }

                      tables[currTableIndex].push({ ...colData, children });

                      vendorColCount += children.length;
                  }

                  return tables;
              }, []);

        return tableData.map((data, i) => {
            const totalCols = showTotalOnly
                ? 2
                : data[0].children.length + data[1].children.length * (data.length - 1);

            const tableCountText =
                tableData.length > 1 ? `(Table ${i + 1} of ${tableData.length})` : '';

            return (
                <div key={`table ${i}`}>
                    {(title || defaultTitle) && (
                        <p>
                            <strong>{(title || defaultTitle).toUpperCase()}</strong>
                            <span style={{ fontSize: 10 }}>&nbsp;&nbsp;{tableCountText}</span>
                        </p>
                    )}
                    {description && <p>{description}</p>}
                    {hasPercentage && showTotalOnly ? (
                        <p style={{ marginBottom: 30 }}>Informational table contains no pricing</p>
                    ) : (
                        <table
                            style={{ fontSize: totalCols >= maxColCount ? '8pt' : '10pt' }}
                            width={this.docxTableWidth}
                        >
                            <thead>{this.renderDocxTableHeader(data, totalCols)}</thead>
                            <tbody>{this.renderDocxTableRows(data, totalCols)}</tbody>
                        </table>
                    )}
                </div>
            );
        });
    }

    render() {
        const {
            bidTabulation: {
                priceTable: { hasPercentage, hasSalesTaxRow },
                title,
            },
            defaultTitle,
            isDocx,
            isPublicView,
            salesTax,
            showTotalOnly,
            stickyHeaderOffset,
        } = this.props;

        const percentageAndTotalOnly = showTotalOnly && hasPercentage;

        const { gridApi } = this.state;

        if (isDocx) {
            return this.renderDocxTable();
        }

        return (
            <div className="col-xs-12">
                <Panel className={this.styles.panel} defaultExpanded>
                    {showTotalOnly ? (
                        <div className={classnames(`${this.styles.totalOnlyTitle} text-center`)}>
                            {title || defaultTitle}
                        </div>
                    ) : (
                        <AgGridReactPanelHeading
                            buttons={this.renderGridButtons()}
                            gridApi={gridApi}
                            hideButtons={isPublicView}
                            processCellCallback={this.handleProcessCell}
                            processHeaderCallback={this.handleProcessHeader}
                            title={title || defaultTitle}
                        />
                    )}
                    {percentageAndTotalOnly ? (
                        <p>Informational table contains no pricing</p>
                    ) : (
                        <Panel.Body style={PANEL_BODY_STYLES}>
                            <Media query={`(max-width: ${SCREEN_SM_MAX}px)`}>
                                {(matches) => (
                                    <div className="row">
                                        <div
                                            className={classnames(
                                                showTotalOnly
                                                    ? 'col-sm-6 col-sm-offset-3 col-xs-12'
                                                    : 'col-xs-12'
                                            )}
                                        >
                                            <WithStickyHeader offset={stickyHeaderOffset}>
                                                <AgGridReactLegacy
                                                    autoHeightMaxRows={30}
                                                    columns={this.generateColDefs(matches)}
                                                    getRowNodeId={this.getRowNodeId}
                                                    hideSideBar={showTotalOnly}
                                                    onGridReady={this.handleGridReady}
                                                    onSelectionChanged={this.handleSelectionChanged}
                                                    pinnedBottomRow={
                                                        showTotalOnly || hasPercentage
                                                            ? undefined
                                                            : this.generateSummaryRow(
                                                                  this.getSelectedRows()
                                                              )
                                                    }
                                                    rows={this.generateRows()}
                                                    showToolPanelColumnExpandAll
                                                    showToolPanelColumnFilter
                                                    showToolPanelColumnSelectAll
                                                />
                                            </WithStickyHeader>
                                        </div>
                                    </div>
                                )}
                            </Media>
                            {!!salesTax && hasSalesTaxRow && !showTotalOnly && (
                                <div className={this.styles.tableFooter}>
                                    <span>[*]</span>
                                    <p> Denotes item is taxable</p>
                                </div>
                            )}
                        </Panel.Body>
                    )}
                </Panel>
            </div>
        );
    }
}
