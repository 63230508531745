import { createSelector } from 'reselect';

import { buildCriteriaMap, buildSignatureMap } from '../../../reducers/helpers/projectCreateHelper';

const getProject = (state) => state.govProjects.get('selectedProject');

export const getProjectJS = createSelector([getProject], (rawProject) => {
    if (rawProject) {
        return rawProject.toJS();
    }
});

export const getWritingProjectJS = createSelector([getProjectJS], (project) => {
    if (project) {
        return buildSignatureMap(buildCriteriaMap(project));
    }
});
