import { Alert, AlertTitle, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProjectContentForm as ProjectContentFormComponent } from '../../../../ProjectContentForm';
import { SharedSectionLabel } from '../../../../../components/SharedSectionLabel';
import { form } from '../../../../constants';
import { OGThemeConsumer } from '../../../../../../ogThemeProvider';

export class ProjectContentForm extends PureComponent {
    static propTypes = {
        addConditionalLogicHandler: PropTypes.func,
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string,
        formKey: PropTypes.string,
        isIntake: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.shape({
            isImported: PropTypes.bool,
            isTemplate: PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        showFormValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        toggleFormHandler: PropTypes.func.isRequired,
        useManualNumbering: PropTypes.bool,
    };

    render() {
        const {
            addConditionalLogicHandler,
            array,
            change,
            disabled,
            form: formProp,
            formKey,
            isIntake,
            isReverseAuction,
            projectId,
            projectSection,
            projectSection: { isImported, isTemplate, title },
            showFormValidation,
            tagOptions,
            templateVariableOptions,
            toggleFormHandler,
            useManualNumbering,
        } = this.props;

        return (
            <OGThemeConsumer>
                {({ isOGThemeEnabledForComponents }) => (
                    <div>
                        {!isOGThemeEnabledForComponents && (
                            <h4 className="text-center">
                                <span className="pseudoLink" onClick={toggleFormHandler}>
                                    {title}
                                </span>
                            </h4>
                        )}
                        {isTemplate && !isOGThemeEnabledForComponents && (
                            <div className="text-center" style={{ marginTop: 8 }}>
                                <SharedSectionLabel
                                    includeLink
                                    projectSection={projectSection}
                                    style={{ marginBottom: 5 }}
                                />
                                <div className="text-warning">
                                    <i className="fa fa-exclamation-triangle" /> Shared Section
                                    Content cannot be edited
                                </div>
                                <i className="fa fa-magic" /> Automation Logic can be added
                            </div>
                        )}
                        {isTemplate && isOGThemeEnabledForComponents && (
                            <SharedSectionLabel
                                includeLink
                                projectSection={projectSection}
                                useOpenGovStyle
                            />
                        )}
                        {isImported && (
                            <Alert severity="info" sx={{ marginBottom: 3 }} variant="condensed">
                                <AlertTitle>Important:</AlertTitle>
                                <Typography variant="bodySmall">
                                    Full editing will be available after section approval.
                                </Typography>
                            </Alert>
                        )}
                        <ProjectContentFormComponent
                            addConditionalLogicHandler={addConditionalLogicHandler}
                            array={array}
                            change={change}
                            disabled={disabled || isTemplate}
                            form={formProp || form}
                            formKey={formKey}
                            hideQuestionLogic={isImported}
                            isIntake={isIntake}
                            isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                            isReverseAuction={isReverseAuction}
                            projectId={projectId}
                            projectSection={projectSection}
                            showFormValidation={showFormValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                            useManualNumbering={useManualNumbering}
                        />
                    </div>
                )}
            </OGThemeConsumer>
        );
    }
}
