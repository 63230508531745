import {
    questionLogicFieldNames,
    questionLogicOperatorNames,
} from '@og-pro/shared-config/questionLogics';

import { REQUIRED_TEXT } from './constants';

const { ACTION, LINKABLE, LINKABLE_ID, OPERATOR, VALUE, LOGICABLE_ID } = questionLogicFieldNames;

const { INCLUDE, NOT_INCLUDE } = questionLogicOperatorNames;

export function questionLogic(questionLogicItem) {
    const questionLogicErrors = {};

    if (!questionLogicItem[ACTION]) {
        questionLogicErrors[ACTION] = REQUIRED_TEXT;
    }
    if (!questionLogicItem[LINKABLE]) {
        questionLogicErrors[LINKABLE] = REQUIRED_TEXT;
    }
    if (!questionLogicItem[LINKABLE_ID]) {
        questionLogicErrors[LINKABLE_ID] = REQUIRED_TEXT;
    }
    if (!questionLogicItem[OPERATOR]) {
        questionLogicErrors[OPERATOR] = REQUIRED_TEXT;
    }
    if (
        !questionLogicItem[VALUE] &&
        questionLogicItem[VALUE] !== 0 &&
        questionLogicItem[VALUE] !== false
    ) {
        questionLogicErrors[VALUE] = REQUIRED_TEXT;
    }
    if (
        [INCLUDE, NOT_INCLUDE].includes(questionLogicItem[OPERATOR]) &&
        (!Array.isArray(questionLogicItem[VALUE]) || questionLogicItem[VALUE].length === 0)
    ) {
        questionLogicErrors[VALUE] = REQUIRED_TEXT;
    }

    if (!questionLogicItem[LOGICABLE_ID]) {
        questionLogicErrors[LOGICABLE_ID] = REQUIRED_TEXT;
    }

    return questionLogicErrors;
}
