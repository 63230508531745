import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import React from 'react';

import { StyledDropzone } from '../../../../../../../../components';

const BYTES_PER_MEGABYTE = 1048576;
const MAX_FILE_SIZE = 10 * BYTES_PER_MEGABYTE;
const accept = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/msword': ['.doc'],
};

export const AiImporterDropzone = ({ disabled, onDropAccepted }) => {
    return (
        <Box
            sx={{
                backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                borderRadius: 1,
                padding: 0.5,
            }}
        >
            <StyledDropzone
                accept={accept}
                defaultRejectMessage="File could not be uploaded. Please ensure the file is less than 10 MBs and in the correct format (.docx or .doc)."
                disabled={disabled}
                icons={['file-word-o']}
                maxSize={MAX_FILE_SIZE}
                multiple={false}
                onDropAccepted={onDropAccepted}
                text="Drop a document here or click to select file (max file size: 10 MBs)"
            />
        </Box>
    );
};

AiImporterDropzone.propTypes = {
    disabled: PropTypes.bool,
    onDropAccepted: PropTypes.func,
};
