import React from 'react';
import { useParams } from 'react-router-dom';

import { VendorSearchTable } from '../../../../components/vendors';
import { searchVendors } from '../../../../actions/vendorSearch';

export const GovVendorList = () => {
    const { governmentCode } = useParams();

    return (
        <VendorSearchTable governmentId={governmentCode} isPublic searchVendors={searchVendors} />
    );
};
