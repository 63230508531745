import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { getNotificationText } from './constants';
import { Button } from '../../../../components';
import { GovernmentSubscriptionNotificationForm } from '../../../../components/forms';
import { onKeyDown } from '../../../../helpers/accessibilityHelper';

class ConnectedGovernmentSubscriptionItem extends PureComponent {
    static propTypes = {
        governmentSubscription: PropTypes.shape({
            government: PropTypes.object.isRequired,
            id: PropTypes.number.isRequired,
            isBlocked: PropTypes.bool.isRequired,
            notificationLevel: PropTypes.number.isRequired,
            updateError: PropTypes.string,
            updating: PropTypes.bool,
        }).isRequired,
        removeGovSubscription: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateGovSubscription: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    removeSubscription = (e) => {
        const {
            governmentSubscription: { id },
            showConfirmationSimpleModal,
            removeGovSubscription,
        } = this.props;

        e.stopPropagation();

        showConfirmationSimpleModal(() => removeGovSubscription(id), {
            text:
                'Are you sure you want to remove this subscription?\n' +
                'You will no longer receive notifications about new opportunities from ' +
                'this organization.',
            btnText: 'Remove Subscription',
        });
    };

    routeClick = () => {
        const {
            governmentSubscription: {
                government: { code },
            },
            router,
        } = this.props;

        router.push(`/portal/${code}`);
    };

    toggleEditForm = (e) => {
        e.stopPropagation();

        this.setState((prevState) => ({ showForm: !prevState.showForm }));
    };

    updateGovSubscription = (id, data) => {
        const { updateGovSubscription } = this.props;

        updateGovSubscription(id, data).then(() => this.setState({ showForm: false }));
    };

    renderNotificationText() {
        const {
            governmentSubscription: { isBlocked, notificationLevel },
        } = this.props;

        if (isBlocked) {
            return (
                <div className="text-danger">
                    <i className="fa fa-fw fa-ban" />
                    &nbsp;
                    <em>
                        This organization has blocked you from receiving notifications about new
                        opportunities
                    </em>
                </div>
            );
        }

        const [icon, notificationText] = getNotificationText(notificationLevel);

        return (
            <div>
                <i className={`fa fa-fw fa-${icon}`} />
                &nbsp;
                <em>{notificationText}</em>
            </div>
        );
    }

    render() {
        const {
            governmentSubscription,
            governmentSubscription: {
                government: {
                    code,
                    organization: { city, logo, name, state },
                },
                isBlocked,
                updating,
                updateError,
            },
        } = this.props;

        const { showForm } = this.state;

        return (
            <li
                className={classnames('list-group-item', this.styles.listItem)}
                onClick={this.routeClick}
                onKeyDown={(event) => onKeyDown(event, this.routeClick)}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
            >
                <div className="row">
                    <div className={`col-xs-12 col-sm-2 ${this.styles.logoContainer}`}>
                        <img alt={name} className={`img-rounded ${this.styles.logo}`} src={logo} />
                    </div>
                    <div className="col-xs-12 col-sm-10">
                        {!isBlocked && (
                            <div className={this.styles.actionButtons}>
                                <Button
                                    bsSize="sm"
                                    bsStyle="link"
                                    className={this.styles.actionButton}
                                    onClick={this.toggleEditForm}
                                    tooltip="Edit notification settings"
                                >
                                    <i className="fa fa-pencil" /> edit
                                </Button>
                                <Button
                                    aria-label="Remove Subscription Button"
                                    bsSize="sm"
                                    bsStyle="link"
                                    className={this.styles.actionButton}
                                    onClick={this.removeSubscription}
                                    onKeyDown={(event) => onKeyDown(event, this.removeSubscription)}
                                    qaTag="governmentSubscriptionItem-removeSubscriptions"
                                    tooltip="Remove subscription"
                                >
                                    <span className="text-danger">
                                        <i className="fa fa-lg fa-times text-danger" />
                                    </span>
                                </Button>
                            </div>
                        )}
                        <div>
                            <h3 className={this.styles.cityName}>
                                <Link to={`/portal/${code}`}>{name}</Link>
                            </h3>
                            <div className={this.styles.govCity}>
                                <em>
                                    {city}, {state}
                                </em>
                            </div>
                            {showForm ? (
                                <div
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => e.stopPropagation()}
                                >
                                    <GovernmentSubscriptionNotificationForm
                                        disabled={updating}
                                        governmentSubscription={governmentSubscription}
                                        updateGovSubscription={this.updateGovSubscription}
                                    />
                                </div>
                            ) : (
                                this.renderNotificationText()
                            )}
                        </div>
                        {updateError && <div className="text-danger">{updateError}</div>}
                    </div>
                </div>
            </li>
        );
    }
}

export const GovernmentSubscriptionItem = withRouter(ConnectedGovernmentSubscriptionItem);
