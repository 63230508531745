import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';

import { Box, Button, Typography } from '@og-pro/ui';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { fieldStatusesDict } from '@og-pro/shared-config/customFormService/customField';

import { LoadingError, LoadingSpinner } from '../../../../components';

import { getCustomFieldsLibraryPath } from '../selectors';

import { useListCustomFields } from '../../../../lib/customFormService/useListCustomFields';

import { CustomFieldsTable } from './CustomFieldsTable';

export const ArchiveCustomFieldTable = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { isLoading, isError, error, data } = useListCustomFields();

    const customFieldsLibraryPath = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );

    function handleBackToLibraryClick() {
        navigate(customFieldsLibraryPath);
    }

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (isError) {
        return <LoadingError error={error.message} useOpenGovStyle />;
    }

    const archivedFields = data.getAllCustomFields.filter(
        (field) => field.status === fieldStatusesDict.ARCHIVED
    );

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    px: 7,
                    py: 2,
                    marginTop: '-20px',
                    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray200}`,
                }}
            >
                <Box>
                    <Button
                        color="secondary"
                        onClick={handleBackToLibraryClick}
                        qaTag="requisitionsAdminCustomFields-back"
                        startIcon={<ArrowBack />}
                        sx={{
                            padding: 0,
                        }}
                        variant="text"
                    >
                        Back to Library of Custom Fields
                    </Button>
                </Box>
                <Box>
                    <Typography variant="h2">Archive of Custom Fields</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: capitalDesignTokens.foundations.colors.white,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                {archivedFields.length === 0 ? (
                    <Box
                        sx={{
                            px: 7,
                            py: 7,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            fontWeight={capitalDesignTokens.foundations.typography.fontWeight.light}
                            variant="d6"
                        >
                            No Fields in the Archive
                        </Typography>
                        <Typography
                            sx={(theme) => ({
                                color: theme.palette.text.secondary,
                            })}
                            variant="bodyDefault"
                        >
                            Once a field has been archived, it will show up here.
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            px: 7,
                            pt: 3,
                            pb: 7,
                        }}
                    >
                        <CustomFieldsTable rows={archivedFields} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
