import PropTypes from 'prop-types';
import React from 'react';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { ReduxFormSelect, Box, ReduxFormTextField } from '@og-pro/ui';

import { fieldNames, form, rejectionNoteOptions, OTHER } from './constants';
import { getRejectionPolicyOptions } from './selectors';
import { validate } from './validate';
import { qaTagPageName } from '../../../constants';

const { REJECTION_NOTE, REJECTION_NOTE_EXTRA, REJECTION_POLICY } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedRejectionNoteForm = ({
    disabled,
    handleSubmit,
    requireRejectionNote,
    showValidation,
}) => {
    const rejectionNote = useSelector((state) => formValueSelector(form)(state, REJECTION_NOTE));
    const rejectionPolicyOptions = useSelector(getRejectionPolicyOptions);

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Field
                    component={ReduxFormSelect}
                    disabled={disabled}
                    fullWidth
                    label="Return To *"
                    name={REJECTION_POLICY}
                    options={rejectionPolicyOptions}
                    placeholder="Select rejection policy to use"
                    qaTag={`${qaTagPageName}-rejectionPolicy`}
                    showValidation={showValidation}
                />
                <Field
                    component={ReduxFormSelect}
                    disabled={disabled}
                    fullWidth
                    label={`Reason for Rejecting This Request${requireRejectionNote ? ' *' : ''}`}
                    name={REJECTION_NOTE}
                    options={rejectionNoteOptions}
                    placeholder="Select an option"
                    qaTag={`${qaTagPageName}-rejectionNote`}
                    showValidation={showValidation}
                />
                {rejectionNote === OTHER && (
                    <Field
                        autoFocus
                        characterLimit={1000}
                        component={ReduxFormTextField}
                        disabled={disabled}
                        fullWidth
                        label="Other Reason *"
                        multiline
                        name={REJECTION_NOTE_EXTRA}
                        qaTag={`${qaTagPageName}-rejectionNote`}
                        showValidation={showValidation}
                    />
                )}
            </Box>
        </form>
    );
};

ConnectedRejectionNoteForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    requireRejectionNote: PropTypes.bool,
    showValidation: PropTypes.bool,
};

export const RejectionNoteForm = reduxForm(formConfig)(ConnectedRejectionNoteForm);
