import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import { getTemplateQuestions } from '../../../../../selectors/govApp';

export const SharedQuestionLabel = ({ includeLink, upfrontQuestion }) => {
    const templateQuestions = useSelector(getTemplateQuestions);
    const templateQuestion = templateQuestions.find(
        (tq) => tq.upfrontQuestion.sharedId === upfrontQuestion.sharedId
    );

    let link;
    if (includeLink && templateQuestion) {
        link = `/governments/${templateQuestion.government_id}/templates-admin/shared-questions?showQuestion=${templateQuestion.id}`;
    }

    return (
        <Box
            sx={(theme) => ({
                color: theme.palette.text.secondary,
                marginTop: 1,
            })}
        >
            {templateQuestion && <> This is a shared question. </>}{' '}
            {link && (
                <Link
                    href={link}
                    onClick={(e) => e.stopPropagation()}
                    rel="noopener"
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        color: 'inherit',
                    }}
                    target="_blank"
                    underline="hover"
                >
                    View{' '}
                    <OpenInNewIcon fontSize="small" sx={{ marginLeft: 0.5, fontSize: '1em' }} />
                </Link>
            )}
        </Box>
    );
};

SharedQuestionLabel.propTypes = {
    includeLink: PropTypes.bool,
    upfrontQuestion: PropTypes.shape({
        sharedId: PropTypes.string.isRequired,
    }).isRequired,
};
